import React from "react";
import LeftSidebar from "../left_sidebar";
import Footer from "../footer";
import Topbar from "../topbar";
import FetchAllApi from "../../api_links/fetch_all_api";
import config from "../../api_links/api_links";
import jQuery from "jquery";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import create_invoice from "./../create_invoice";
// import 'react-style-sheet.css';
import _, { isNaN } from "lodash";
//import Pdf from "react-to-pdf";

import Select from "react-select";
import moment from "moment";
import Comma from "./../comma";
import Swal from "sweetalert2";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const ref = React.createRef();

let column_indexes = {
  date: 1,
  number: 2,
  name: 3,
  user_type: 4,
  desc: 5,
  category: 6,
  frgn_gross_amount: 7,
  tax_rate: 8,
  frgn_tax_amount: 9,
  frgn_total_amount: 10,
  exc_rate: 11,
  home_gross_amount: 12,
  home_tax_amount: 13,
  home_total_amount: 14,

};

let is_user_type_modified = 0;
let selected_user_type = "";
let item_changed_from_drop_down = false;

// class Acc extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       default_category_list: []
//     }

//   }
//   defaultcategorylist_onchange = (x, y) => {
//     let keyy = "";
//     let from_create_invoice = 0;
//     var client_id = 14

//     FetchAllApi.defaultcategorylist_onchange2(
//       keyy,
//       from_create_invoice,
//       client_id,
//       (err, response) => {
//         console.log("defaultcat9999egorylist", response);
//         if (response.status === 1) {
//           this.setState({
//             default_category_list: response.list,
//           })
//           // if (x == "added") {
//           //   this.setState({
//           //     selectNeedIndex: response.list.length - 1,
//           //     nameFilter: y,
//           //   });
//           // }
//           // this.setState(
//           //   {
//           //     default_category_list: response.list,
//           //   },
//           //   () => {
//           //     window.jQuery("#categry_id0").selectpicker("refresh");
//           //   }
//           // );
//         } else {
//           this.setState({
//             default_category_list: [],
//           });
//         }
//       }
//     );
//   };

//   componentDidMount() {
//     this.defaultcategorylist_onchange()
//   }

//   render() {
//     return (

//       <select
//         className="selectpicker form-control add-new cus hello"
//         data-live-search="true"
//         title="Choose"
//         id={`categry_id${1}`}
//         onChange={(e) => {
//           if (e.target.value == "1e") {
//             jQuery(
//               `#categry_id${1} option`
//             )
//               .prop("selected", false)
//               .trigger("change");

//             window
//               .jQuery("#pop-modal")
//               .modal("show");
//           }
//         }}
//       >
//         <option value="1e">
//           Create New{" "}
//         </option>
//         {this.state.default_category_list && this.state.default_category_list.map(
//           (item) => {
//             return (
//               <option
//                 value={item.id}
//                 data-status={item.id}
//               >
//                 {item.name}
//               </option>
//             );
//           }
//         )}
//       </select>
//     )
//   }

// }

class AddBankAccount extends create_invoice {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.updateState = this.updateState.bind(this);
    // this.getExchangeRateForGivenDate = this.getExchangeRateForGivenDate.bind(this);
    //const { history } = this.props;
    const { selections } = this.state;
    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      lock_date: localStorage.getItem("lock_date"),
      country_sortname: localStorage.getItem("country_sortname"),
      language_code: localStorage.getItem("language_code"),
      home_currency_symbol: localStorage.getItem("home_currency_symbol"),
      clientHomeCurrency: localStorage.getItem("home_currency"),
      hasId: localStorage.getItem('hasId'),
      old_transaction_date_array:[],
      previous_transaction_dates_to_update_on_success:[],
      client_date_format: "DD-MM-YYYY",
      role_permissions:
        JSON.parse(localStorage.getItem("role_permissions")) || [],
      totalCreditAmount: 0,
      // currencies: [],
      isFutureDate: true,
      currencies: config.all_currency_list,
      currency_clone: [],
      transaction_type: "",
      currency: "",
      account_type: "",
      SubAccountList: [],
      default_category_list: [],
      gst_list: [],
      search_key_gst: "",
      hometotalamount: "",
      // clientHomeCurrency: "",
      is_voided: 0,
      currency_list: [],
      selectedColumnType: "",
      selectedOption: "option2",
      number_of_columns_list: [],
      // total_number_of_rows:10,
      total_number_of_rows: 20,
      exchange_rate_object: {},
      coulmns: "",
      modal_info_msg: "",
      result_account: "",
      array: [],
      xyz: "",
      xyx: "",
      editrows: [],
      isSelected: false,
      transaction_type_label: "Accounts Receivable",
      banks: [],
      is_add_from_bank_account: false,
      isSuccessful: false,
      isFailed: false,
      errormessage: "Failed to save Batch Transaction",
      is_currency_selected: false,
      is_transaction_type_selected: false,
      set_as_read_only: true,
      salesTax_name_entered: "",
      sales_tax_code: "",
      batchid:
        localStorage.getItem("batchid") &&
        localStorage.getItem("batchid") != undefined
          ? localStorage.getItem("batchid")
          : 0,
      batch_transaction_row_index:
        localStorage.getItem("batch_transaction_row_index") != null &&
        localStorage.getItem("batch_transaction_row_index") != undefined
          ? localStorage.getItem("batch_transaction_row_index")
          : -1,
      ugrid: [
        { readOnly: true, value: "" },
        { a: "Invoice / Credit memo date", readOnly: true },
        { a: "Invoice/ Credit memo  no", readOnly: true },
        { a: "Name ", readOnly: true },
        { a: `Type`, readOnly: true },
        { a: "Description", readOnly: true },
        { a: "Category", readOnly: true },
        // { a: `Gross amount( ${this.state.currency} )`, readOnly: true },
        { a: `Gross amount( )`, readOnly: true },
        { a: "Sales tax rate", readOnly: true },
        // { a: `Sales tax amount (${this.state.currency} )`, readOnly: true },
        { a: `Sales tax amount ( )`, readOnly: true },
        // { a: `Total amount (${this.state.currency} )`, readOnly: true },
        { a: `Total amount ( )`, readOnly: true },
        { a: "Exchange rate", readOnly: true },

        {
          a: `Gross amount(${localStorage.getItem("home_currency")})`,
          readOnly: true,
        },
        {
          a: `Sales tax amount (${localStorage.getItem("home_currency")})`,
          readOnly: true,
        },
        {
          a: `Total amount (${localStorage.getItem("home_currency")})`,
          readOnly: true,
        },
      ],
      // selections: [false, false, false, false, false, false, false, false, false, false, false, false, false, false],
      selections: new Array(21).fill(false),
      grid: [
        [
          { readOnly: true, value: "" },
          { a: "Invoice / Credit memo date", readOnly: true },
          { a: "Invoice/ Credit memo  no", readOnly: true },
          { a: "Name", readOnly: true },
          { a: `Type `, readOnly: true },
          { a: "Description", readOnly: true },
          { a: "Category", readOnly: true },
          // { a: `Gross amount(  )`, readOnly: true },
          { a: `Gross amount()`, readOnly: true },
          { a: "Sales tax rate", readOnly: true },
          { a: `Sales tax amount ()`, readOnly: true },
          { a: `Total amount ( )`, readOnly: true },
          { a: "Exchange rate", readOnly: true },

          {
            a: `Gross amount(${localStorage.getItem("home_currency")})`,
            readOnly: true,
          },
          {
            a: `Sales tax amount (${localStorage.getItem("home_currency")})`,
            readOnly: true,
          },
          {
            a: `Total amount (${localStorage.getItem("home_currency")})`,
            readOnly: true,
          },
        ],
        [
          { readOnly: true, value: "1" },
          { a: this.state.client_date_format, readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },
          { a: ` `, readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },

          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
        ],
        [
          { readOnly: true, value: "2" },
          { a: this.state.client_date_format, readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },
          { a: ` `, readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },

          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
        ],
        [
          { readOnly: true, value: "3" },
          { a: this.state.client_date_format, readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },
          { a: ` `, readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },

          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
        ],
        [
          { readOnly: true, value: "4" },
          { a: this.state.client_date_format, readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },
          { a: ` `, readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },

          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
        ],
        [
          { readOnly: true, value: "5" },
          { a: this.state.client_date_format, readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },
          { a: ` `, readOnly: true },
          { a: ``, readOnly: true },
          { a: "", readOnly: true },

          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
          { a: ``, readOnly: true },
        ],
      ],
      exchangeRate: "",
      drop: "",
      isChecked: false,
      totalcreditgross: "",
      totalcredittax: 0,
      totalcreditnet: 0,
      totalsalesgross: 0,
      totalsalestax: 0,
      totalsalesnet: 0,
      buttondisable: false,
      grossamount: 0,
      salestaxamount: 0,
      totalamount: 0,
      grossamount_foreign: 0,
      salestaxamount_foreign: 0,
      totalamount_foreign: 0,
      show_succes: false,
      selected_rate_type: "%",
      password: "",
      isLockDateOpen: false,
    };
  }

  handleChangeTax(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => this.validation_clean()
    );
  }
  validation_clean = () => {
    console.log("NNNNNNN..338");
    // this.state.company_name != ''
    //   ? this.setState({ isCompany_name: true })
    //   : this.setState({})
    // this.state.invoice_no != ''
    //   ? this.setState({ isInvoice_no: true })
    //   : this.setState({})
    // this.state.balance_sheet_category_id != ''
    //   ? this.setState({ isBalance_sheet_category_name: true })
    //   : this.setState({})
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  handleChange_gst_type = (event) => {
    if (this.state.selected_rate_type != "Fixed price") {
      let entered_value = event.target.value;
      if (isNaN(entered_value)) {
        this.setState({ rate_entered: "" });
      } else {
        this.setState({ rate_entered: entered_value });
      }
    } else {
      let entered_value = event.target.value;
      if (isNaN(entered_value)) {
        this.setState({ rate_entered: "" });
      } else {
        this.setState({ rate_entered: entered_value });
      }
    }
  };

  add_gst_details = (e) => {
    e.preventDefault();
    let sales_tax_code = this.state.sales_tax_code;
    let sales_tax_name = this.state.salesTax_name_entered;
    let show_on_list = 1;
    let tax_type = this.state.selectedOption === "option1" ? 1 : 2;
    let rate = this.state.rate_entered;

    if (
      this.state.selected_rate_type != "Fixed price" &&
      this.state.selected_rate_type === "%"
    ) {
      var rate_type = 1;
    } else {
      var rate_type = 2;
    }

    let country = this.state.country_code;
    let items = {
      sales_tax_code: sales_tax_code,
      sales_tax_name: sales_tax_name,
      show_on_list: show_on_list,
      tax_type: tax_type,
      rate: rate,
      rate_type: rate_type,
      country: country,
      client_id: this.state.logged_client_id,
    };

    FetchAllApi.add_gst_details(items, (err, response) => {
      if (response.status === 1) {
        let tax_id = response.id;
        this.setState({
          show_succes: true,
          sales_tax_code: "",
          salesTax_name_entered: "",
          selectedOption: 1,
          rate_entered: "",
          selected_rate_type: "%",
        });
        this.get_gst_list(1, true, tax_id, sales_tax_name);

        setTimeout(() => {
          this.setState({ show_succes: false });
        }, 4000);
        window.jQuery("#pop-modal-1").modal("hide");
      } else {
        this.setState({ modal_info_msg: response.message });
        jQuery(".mymsg").fadeIn(2000);
        setTimeout(function () {
          jQuery(".mymsg").fadeOut(2000);
        }, 8000);
      }
    });
  };

  openTaxModal = () => {
    window.jQuery("#pop-modal-1").modal("show");
  };

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map((key) => {
      let newKey = key.replace(curr, "");
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };
  // handlingChange=()=>{
  //   debugger
  //   this.setState(
  //     {

  //       isSelected: !this.state.isSelected,
  // //     }
  // //   );
  // // }
  // handleChange=(e)=> {
  //   debugger
  //   this.setState(
  //         {

  //           isSelected: e.target.checked,
  //         }
  //       );
  //   //let isCheisSelectedcked = e.target.checked;
  //   // do whatever you want with isChecked value
  // }

  toggleChange = () => {
    this.setState(
      {
        isChecked: !this.state.isChecked,
      },
      () => {
        this.calculateTaxForSelectedRows(false);
      }
    );
  };

  addNewAcc = () => {
    window.jQuery("#pop-modal").modal("show");
  };

  //old function - commented on 04-07-2022
  // handleChange=(index,selected) => {
  // //handleChange=(index,selected)=> {
  //   //debugger

  //   const selections = [...this.state.selections]

  //   const index1=jQuery(index.nativeEvent.path[2]).find("td:eq(1)").text();
  //   selections[index1] = index.currentTarget.checked;
  //  // indexArray=index1;
  //   this.setState({selections});
  // }

  handleChange = (index, selected) => {
    const selections = [...this.state.selections];
    if (index > 0) {
      if (selections[index] == true) {
        selections[index] = false;
      } else {
        selections[index] = true;
      }
      this.setState({ selections });
    } else if (index == 0) {
      if (selections[index] == false) {
        selections[0] = true;
        let grid_data = this.state.grid;
        let column_count = 0;
        for (let i = 1; i <= this.state.total_number_of_rows; i++) {
          if (i == 1) {
            column_count = grid_data[i].length;
          }
          for (let j = 1; j < column_count; j++) {
            if (
              grid_data[i][j] &&
              grid_data[i][j]["a"] &&
              grid_data[i][j]["a"] != 0 &&
              grid_data[i][j]["a"] != "" &&
              grid_data[i][j]["a"] != this.state.client_date_format
            ) {
              selections[i] = true;
            }
          }
        }
        this.setState({ selections });
      } else {
        let sel = selections.map((x) => false);
        this.setState({ selections: sel });
      }
    }
  };

  handleSubmit(e) {
    e.preventDefault();
  }

  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        console.log("Basio state", response);
        this.get_currencies(response.currency);
        this.setState({
          clientHomeCurrency: response.currency,
        });
      } else {
      }
    });
  };

  get_gst_list = (
    exchange_rate,
    is_new_tax_added = false,
    tax_id,
    sales_tax_name
  ) => {
    let country_code = 196;
    let keyword = this.state.search_key_gst;
    let filter_id = 2;
    FetchAllApi.get_gst_list(
      country_code,
      keyword,
      this.state.logged_client_id,
      filter_id,
      (err, response) => {
        if (response.status === 1) {
          if (is_new_tax_added == false) {
            this.setState(
              {
                gst_list: response.list,
              },
              this.defaultcategorylist_onchange(exchange_rate)
            );
          } else {
            this.setState(
              {
                gst_list: response.list,
              },
              () => {
                this.updateGstDropDownList(tax_id, sales_tax_name);
              }
            );
          }
        } else {
          this.setState({
            gst_list: [],
          });
        }
      }
    );
  };

  updateGstDropDownList = (tax_id, sales_tax_name) => {
    let grid_data = this.state.grid;
    for (let i = 1; i <= this.state.total_number_of_rows; i++) {
      let className = "";
      if (this.state.selections[i - 1] == true) {
      } else {
        let current_value = grid_data[i][column_indexes.tax_rate]["a"];
        if (current_value && current_value != "") {
          let current_tax = _.find(this.state.gst_list, {
            sales_tax_name: current_value,
          });
          let is_tax_exist = false;
          // if(current_tax && current_tax.length > 0) {
          if (
            (current_tax && current_tax.sales_tax_name == current_value) ||
            current_value == "1e"
          ) {
            is_tax_exist = true;
          }
          if (!is_tax_exist) {
            className = "grid-bg-color";
          }
        }
      }

      let tax_row = {
        className: className,
        // a: (grid_data[i][column_indexes.tax_rate] && grid_data[i][column_indexes.tax_rate]["a"])? (grid_data[i][column_indexes.tax_rate]["a"] == '1e')?sales_tax_name:grid_data[i][column_indexes.tax_rate]["a"]:'',
        a:
          grid_data[i][column_indexes.tax_rate] &&
          grid_data[i][column_indexes.tax_rate]["a"]
            ? grid_data[i][column_indexes.tax_rate]["a"] == "1e"
              ? sales_tax_name
              : grid_data[i][column_indexes.tax_rate]["a"]
            : "",
        value:
          grid_data[i][column_indexes.tax_rate] &&
          grid_data[i][column_indexes.tax_rate]["value"]
            ? grid_data[i][column_indexes.tax_rate]["value"] == "1e"
              ? sales_tax_name
              : grid_data[i][column_indexes.tax_rate]["value"]
            : "",
        component: (
          <select
            // onChange={(e) => {
            //   if (e.target.value == '1e') {
            //     this.openTaxModal()
            //   }
            //  let data = this.state.grid;
            //  let selectedIndex = e.target.options.selectedIndex;
            //  let row_index = e.target.options[selectedIndex].getAttribute('data-row-index');
            //  data[row_index][column_indexes.tax_rate]= { ...data[row_index][column_indexes.tax_rate], a: e.target.value,value:e.target.value }
            // item_changed_from_drop_down = true;
            //   for (i=0 ; i <this.state.selections.length-1; i++){
            //       if(this.state.selections[i]==true){
            //         data[i][column_indexes.tax_rate]= { ...data[i][column_indexes.tax_rate], a: e.target.value,value:e.target.value }
            //         item_changed_from_drop_down = true;
            //       }
            //   }
            //   console.log("590.....");
            //   this.calculateTaxForSelectedRows(true,data);
            // }

            onChange={(e) => {
              if (e.target.value === "1e") {
                this.openTaxModal();
              }
              let data = this.state.grid;
              let selectedIndex = e.target.options.selectedIndex;
              let row_index =
                e.target.options[selectedIndex].getAttribute("data-row-index");
              data[row_index][column_indexes.tax_rate] = {
                ...data[row_index][column_indexes.tax_rate],
                a: e.target.value,
                value: e.target.value,
              };
              // for (i=0 ; i <this.state.selections.length-1; i++){
              for (i = 1; i < this.state.selections.length; i++) {
                if (this.state.selections[i] === true) {
                  data[i][column_indexes.tax_rate] = {
                    ...data[i][column_indexes.tax_rate],
                    a: e.target.value,
                    value: e.target.value,
                  };
                }
              }
              item_changed_from_drop_down = true;
            }}
          >
            <option value='1e' data-row-index={i}>
              {/* Choose... */}
              Search and Choose...
            </option>
            <option value='1e' data-row-index={i}>
              Create New{" "}
            </option>
            {this.state.gst_list &&
              this.state.gst_list.map((item) => {
                return (
                  <option
                    data-row-index={i}
                    value={item.sales_tax_name}
                    style={{
                      display: item.show_on_list == 2 ? "none" : "block",
                    }}
                  >
                    {item.sales_tax_name}
                  </option>
                );
              })}
          </select>
        ),
      };
      grid_data[i][column_indexes.tax_rate] = tax_row;
    }
    this.setState({ grid: grid_data }, () => {
      this.calculateTaxForSelectedRows(false);
    });
  };

  defaultcategorylist_onchange = (exchange_rate, x, y) => {
    let keyy = "";
    let from_create_invoice = 0;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    FetchAllApi.defaultcategorylist_onchange2(
      keyy,
      from_create_invoice,
      client_id,
      filter_id,
      (err, response) => {
        if (response.status === 1) {
          // for first time only load
          // if (this.state.default_category_list.length == 0) {
          var my_grid = [this.state.ugrid];
          if (this.state.editrows.length == 0) {
            // for (let i = 1; i <= 50; i++) {
            for (let i = 1; i <= this.state.total_number_of_rows; i++) {
              let row = [
                { readOnly: true, a: i },
                {
                  a: `${this.state.client_date_format}`,
                  readOnly: this.state.set_as_read_only,
                },
                { a: "", readOnly: this.state.set_as_read_only },
                { a: "", readOnly: this.state.set_as_read_only },
                {
                  a: "",
                  readOnly: this.state.set_as_read_only,
                  component: (
                    <select
                      data-live-search='true'
                      onChange={(e, props) => {
                        let selectedIndex = e.target.options.selectedIndex;
                        let row_index =
                          e.target.options[selectedIndex].getAttribute(
                            "data-row-index"
                          );
                        if (e.target.value == "") {
                        }
                        let data = this.state.grid;
                        data[row_index][column_indexes.user_type] = {
                          ...data[row_index][column_indexes.user_type],
                          a: e.target.value,
                          value: e.target.value,
                        };
                        for (i = 0; i < this.state.selections.length; i++) {
                          //  if(this.state.selections[i] == true || (i == row_index)){
                          if (this.state.selections[i] == true) {
                            data[i][column_indexes.user_type] = {
                              ...data[i][column_indexes.user_type],
                              a: e.target.value,
                              value: e.target.value,
                            };
                            // this.setState({ grid: data, drop: 'yes' });
                          }
                        }

                        is_user_type_modified = 1;
                        selected_user_type = e.target.value;
                      }}
                      onCommit={(vn, keydown) => {
                        console.log("gggg...652");
                      }}
                    >
                      {/* <option data-row-index={i} key="0" value=""> Choose... </option> */}
                      <option data-row-index={i} key='0' value=''>
                        {" "}
                        Search and Choose...{" "}
                      </option>
                      <option data-row-index={i} key='1' value='Customer'>
                        {" "}
                        Customer
                      </option>
                      <option data-row-index={i} key='2' value='Vendor'>
                        {" "}
                        Vendor
                      </option>
                      <option data-row-index={i} key='3' value='Employee'>
                        {" "}
                        Employee
                      </option>
                      <option data-row-index={i} key='4' value='Others'>
                        {" "}
                        Others
                      </option>
                    </select>
                  ),
                },
                { a: "", readOnly: this.state.set_as_read_only },
                {
                  a: "",
                  readOnly: this.state.set_as_read_only,
                  component: (
                    // <div className='excel-select'>
                    //   <Select options={options} />
                    // </div>
                    <select
                      data-live-search='true'
                      onChange={(e, props) => {
                        let selectedIndex = e.target.options.selectedIndex;
                        let row_index =
                          e.target.options[selectedIndex].getAttribute(
                            "data-row-index"
                          );
                        let ac_id =
                          e.target.options[selectedIndex].getAttribute(
                            "data-acc-id"
                          );
                        if (e.target.value == "1e") {
                          this.addNewAcc();
                        }

                        // debugger
                        let data = this.state.grid;
                        // data[i][column_indexes.category] = { ...data[i][column_indexes.category], a: e.target.value }
                        //  if(this.state.selections[2] == true ){

                        // To change on clicked row
                        data[row_index][column_indexes.category] = {
                          ...data[row_index][column_indexes.category],
                          a: e.target.value,
                          value: e.target.value,
                          id: ac_id,
                          className: "",
                        };
                        // this.setState({ grid: data, drop: 'yes' })
                        item_changed_from_drop_down = true;

                        // for (i=0 ; i <this.state.selections.length-1; i++){
                        for (i = 1; i < this.state.selections.length; i++) {
                          if (this.state.selections[i] == true) {
                            data[i][column_indexes.category] = {
                              ...data[i][column_indexes.category],
                              a: e.target.value,
                              value: e.target.value,
                              id: ac_id,
                              className: "",
                            };
                            // this.setState({ grid: data, drop: 'yes' })
                            item_changed_from_drop_down = true;
                          }
                        }
                      }}
                    >
                      <option
                        key='a0'
                        value='1e'
                        data-row-index={i}
                        data-acc-id='0'
                      >
                        {/* Choose... */}
                        Search and Choose...
                      </option>

                      <option
                        key='0'
                        data-row-index={i}
                        value='1e'
                        data-acc-id='0'
                      >
                        Create New{" "}
                      </option>
                      {response.list &&
                        response.list.map((item, l) => {
                          return (
                            <option
                              key={l + 1}
                              data-row-index={i}
                              value={item.name}
                              data-acc-id={item.id}
                              disabled={item.status == 2 ? true : false}
                              style={{
                                display: item.status == 2 ? "none" : "block",
                              }}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  ),
                },
                { a: "", readOnly: this.state.set_as_read_only },
                {
                  a: "",
                  readOnly: this.state.set_as_read_only,
                  component: (
                    <select
                      onChange={(e) => {
                        if (e.target.value == "1e") {
                          this.openTaxModal();
                        }
                        let data = this.state.grid;
                        // data[i][column_indexes.tax_rate] = { ...data[i][column_indexes.tax_rate], a: e.target.value }

                        let selectedIndex = e.target.options.selectedIndex;
                        let row_index =
                          e.target.options[selectedIndex].getAttribute(
                            "data-row-index"
                          );
                        data[row_index][column_indexes.tax_rate] = {
                          ...data[row_index][column_indexes.tax_rate],
                          a: e.target.value,
                          value: e.target.value,
                          className: "",
                        };
                        //  this.setState({ grid: data, drop: 'yes' })

                        //  if(this.state.selections[2] == true ){
                        // for (i=0 ; i <this.state.selections.length-1; i++){
                        for (i = 1; i < this.state.selections.length; i++) {
                          if (this.state.selections[i] == true) {
                            data[i][column_indexes.tax_rate] = {
                              ...data[i][column_indexes.tax_rate],
                              a: e.target.value,
                              value: e.target.value,
                              className: "",
                            };
                            // this.setState({ grid: data, drop: 'yes' })
                          }
                        }
                        // this.setState({ grid: data, drop: 'yes' },()=>{
                        //   this.calculateTaxForSelectedRows(true);
                        // });
                        item_changed_from_drop_down = true;
                        // this.calculateTaxForSelectedRows(true,data);
                      }}
                    >
                      <option value='1e' data-row-index={i}>
                        {/* Choose... */}
                        Search and Choose...
                      </option>
                      <option value='1e' data-row-index={i}>
                        Create New{" "}
                      </option>
                      {this.state.gst_list &&
                        this.state.gst_list.map((item) => {
                          return (
                            <option
                              data-row-index={i}
                              value={item.sales_tax_name}
                              style={{
                                display:
                                  item.show_on_list == 2 ? "none" : "block",
                              }}
                            >
                              {item.sales_tax_name}
                            </option>
                          );
                        })}
                    </select>
                  ),
                },
                { a: "", readOnly: true },
                { a: "", readOnly: true },
                { a: exchange_rate, readOnly: this.state.set_as_read_only },
                { a: "", readOnly: true },
                { a: "", readOnly: true },
                { a: "", readOnly: true },
                // {a:''},
              ];
              if (
                this.state.number_of_columns_list &&
                this.state.number_of_columns_list.length
              ) {
                this.state.number_of_columns_list.forEach((cc, i) => {
                  row.push({ a: "", key_name: cc.column_name });
                });
              }
              my_grid.push(row);
            }

            // added here on 12-05-2022
            if (my_grid && my_grid.length > 0) {
              if (my_grid[0][column_indexes.frgn_gross_amount]) {
                my_grid[0][column_indexes.frgn_gross_amount] = {
                  a: `Gross amount(${this.state.currency})`,
                  readOnly: true,
                };
              }
              if (my_grid[0][column_indexes.frgn_tax_amount]) {
                my_grid[0][column_indexes.frgn_tax_amount] = {
                  a: `Sales tax amount(${this.state.currency})`,
                  readOnly: true,
                };
              }
              if (my_grid[0][column_indexes.frgn_total_amount]) {
                my_grid[0][column_indexes.frgn_total_amount] = {
                  a: `Total amount(${this.state.currency})`,
                  readOnly: true,
                };
              }

              if (
                this.state.number_of_columns_list &&
                this.state.number_of_columns_list.length
              ) {
                this.state.number_of_columns_list.forEach((cc, i) => {
                  my_grid[0].push({
                    a: cc.column_name,
                    key_name: cc.column_name,
                    readOnly: true,
                  });
                });
              }
            }
            this.setState({ grid: my_grid });
          } else {
          }

          //Commented on 12-05-2022
          // if(my_grid && my_grid.length > 0) {
          //   if(my_grid[0][column_indexes.frgn_gross_amount]) {
          //     my_grid[0][column_indexes.frgn_gross_amount] = { a: `Gross amount(${this.state.currency})`, readOnly: true }
          //   }
          //   if(my_grid[0][column_indexes.frgn_tax_amount]) {
          //     my_grid[0][column_indexes.frgn_tax_amount] = { a: `Sales tax amount(${this.state.currency})`, readOnly: true }
          //   }
          //   if(my_grid[0][column_indexes.frgn_total_amount]) {
          //     my_grid[0][column_indexes.frgn_total_amount] = { a: `Total amount(${this.state.currency})`, readOnly: true }
          //   }

          //   if(this.state.number_of_columns_list && this.state.number_of_columns_list.length) {
          //     this.state.number_of_columns_list.forEach((cc,i)=>{
          //       my_grid[0].push({ a: cc.name, key_name:cc.column_name,  readOnly: true });
          //     });
          //   }
          // }

          // this.setState({ grid: my_grid });

          // for first time only load
          this.setState(
            {
              // default_category_list: response.list, grid: my_grid
              default_category_list: response.list,
            },
            () => {
              window.jQuery("#categry_id0").selectpicker("refresh");
            }
          );
        } else {
          this.setState({
            default_category_list: [],
          });
        }
      }
    );
  };
  //
  category_list_on_change = (x, y, z, new_currency) => {
    let keyy = "";
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    if (this.state.is_add_from_bank_account == false) {
      FetchAllApi.defaultcategorylist_onchange2(
        keyy,
        from_create_invoice,
        client_id,
        filter_id,
        (err, response) => {
          if (response.status === 1) {
            let grid_data = this.state.grid;
            for (let i = 1; i <= this.state.total_number_of_rows; i++) {
              let className = "";
              if (this.state.selections[i - 1] == true) {
              } else {
                let current_value = grid_data[i][column_indexes.category]["a"];

                if (current_value && current_value != "") {
                  let current_category = _.find(response.list, {
                    name: current_value,
                  });
                  let is_category_exist = false;

                  if (
                    (current_category &&
                      current_category.name == current_value) ||
                    current_value == "1e"
                  ) {
                    is_category_exist = true;
                  }
                  if (!is_category_exist) {
                    className = "grid-bg-color";
                  }
                }
              }

              let account_row = {
                className: className,
                a:
                  grid_data[i][column_indexes.category] &&
                  grid_data[i][column_indexes.category]["a"]
                    ? grid_data[i][column_indexes.category]["a"] == "1e"
                      ? y
                      : grid_data[i][column_indexes.category]["a"]
                    : "",
                component: (
                  <select
                    data-live-search='true'
                    onChange={(e, props) => {
                      let selectedIndex = e.target.options.selectedIndex;
                      let row_index =
                        e.target.options[selectedIndex].getAttribute(
                          "data-row-index"
                        );
                      let ac_id =
                        e.target.options[selectedIndex].getAttribute(
                          "data-acc-id"
                        );
                      if (e.target.value == "1e") {
                        this.addNewAcc();
                      }

                      let data = this.state.grid;
                      // To change on clicked row
                      data[row_index][column_indexes.category] = {
                        ...data[row_index][column_indexes.category],
                        a: e.target.value,
                        id: ac_id,
                        value: e.target.value,
                      };
                      // this.setState({ grid: data, drop: 'yes' })
                      item_changed_from_drop_down = true;

                      // for (i=0 ; i <this.state.selections.length-1; i++){
                      for (i = 1; i < this.state.selections.length; i++) {
                        if (this.state.selections[i] == true) {
                          data[i][column_indexes.category] = {
                            ...data[i][column_indexes.category],
                            a: e.target.value,
                            id: ac_id,
                            value: e.target.value,
                          };
                          // this.setState({ grid: data, drop: 'yes' })
                          item_changed_from_drop_down = true;
                        } else {
                          console.log(
                            "948......",
                            data[i][column_indexes.category]["a"]
                          );
                        }
                      }
                    }}
                  >
                    <option
                      key='a0'
                      value='1e'
                      data-row-index={i}
                      data-acc-id='0'
                    >
                      {/* Choose... */}
                      Search and Choose...
                    </option>
                    <option
                      key='0'
                      data-row-index={i}
                      value='1e'
                      data-acc-id='0'
                    >
                      Create New{" "}
                    </option>
                    {response.list &&
                      response.list.map((item, l) => {
                        return (
                          <option
                            key={l + 1}
                            data-row-index={i}
                            value={item.name}
                            data-acc-id={item.id}
                            disabled={item.status == 2 ? true : false}
                            style={{
                              display: item.status == 2 ? "none" : "block",
                            }}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                ),
              };
              grid_data[i][column_indexes.category] = account_row;
            }
            this.setState({ grid: grid_data });
          } else {
            this.setState({
              default_category_list: [],
            });
          }
        }
      );
    } else {
      //this.getAllbanks();

      FetchAllApi.getBanksByCondition(
        {
          client_id: this.state.logged_client_id,
          currency: this.state.currency,
        },
        (err, response) => {
          if (response.status === 1) {
            this.setState({ banks: response.data }, () => {});
          } else {
            this.setState({ banks: [] });
          }
        }
      );

      if (this.state.currency == new_currency) {
        this.setState({ result_account: z, is_add_from_bank_account: false });
      } else {
        this.setState({ is_add_from_bank_account: false });
      }
    }
  };

  // for data sheet

  isValidDate = (dateString) => {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  // total = _.reduce(_.values(this.state.totalcreditgross), (res, val, key) => {
  //   res += (val && val.value) || 0
  //   return res
  // }, 0)

  valueRenderer = (cell) => {
    // return cell.a
    if (cell.value == "" || cell.value == undefined) {
      return cell.a;
    } else {
      return cell.value;
    }
  };

  getExchangeRateForGivenDate = async (date, row, col) => {
    let nope;
    // var date_formated;
    if (date != undefined && date != null && date != "") {
      var date_formated = moment(date).isAfter(moment()) ? moment().format('YYYY-MM-DD') : moment(date).format("YYYY-MM-DD")
      nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
      let res = nope.concat(this.state.currency);
      await fetch(res)
        .then(async (response) => await response.json())
        .then(async (data) => {
          if (data.success == true) {
            let newObj = this.rename(data.quotes, this.state.currency);
            let currencyAr = [];
            let first = newObj;
            currencyAr.push({
              date: data.start_date,
              // 'rate': isNaN(first[data.start_date][this.state.currency+this.state.clientHomeCurrency])? 0.0000 :(first[data.start_date][this.state.currency+this.state.clientHomeCurrency]).toFixed(4)
              rate:
                first[data.start_date] &&
                first[data.start_date][
                  this.state.currency + this.state.clientHomeCurrency
                ]
                  ? first[data.start_date][
                      this.state.currency + this.state.clientHomeCurrency
                    ].toFixed(4)
                  : parseFloat(1).toFixed(4),
            });
            let value = currencyAr[0].rate;
            if (value) {
              let grid_data = this.state.grid;
              //Here 10 is exchange rate index
              grid_data[row][column_indexes.exc_rate] = {
                ...grid_data[row][column_indexes.exc_rate],
                a: value,
              };

              //newly added
              let gross_amount = Number(
                grid_data[row][column_indexes.frgn_gross_amount].a == ""
                  ? 0
                  : grid_data[row][column_indexes.frgn_gross_amount].a
              );
              let gross_home = Number((gross_amount * value).toFixed(2));

              this.state.gst_list.map((itm) => {
                if (
                  itm.sales_tax_name ==
                  grid_data[row][column_indexes.tax_rate].a
                ) {
                  if (itm.rate_type == 1) {
                    let tax_rate = Number(itm.rate);
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid_data[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid_data[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(
                        (total_amount * (tax_rate / (tax_rate + 100))).toFixed(
                          2
                        )
                      );
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(
                        (gross_amount * (tax_rate / 100)).toFixed(2)
                      );
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * value).toFixed(2);
                    let home_total_amount = (total_amount * value).toFixed(2);
                    // grid_data[row][column_indexes.home_tax_amount] = { ...grid_data[row][column_indexes.home_tax_amount], a: home_tax_amount };
                    // grid_data[row][column_indexes.home_total_amount] = { ...grid_data[row][column_indexes.home_total_amount], a: home_total_amount };

                    grid_data[row][column_indexes.home_tax_amount] = {
                      ...grid_data[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    grid_data[row][column_indexes.home_total_amount] = {
                      ...grid_data[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                  } else if (itm.rate_type == 2) {
                    let tax_rate = itm.rate;
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid_data[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid_data[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(tax_rate);
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(tax_rate);
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * value).toFixed(2);
                    let home_total_amount = (total_amount * value).toFixed(2);
                    // grid_data[row][column_indexes.home_tax_amount] = { ...grid_data[row][column_indexes.home_tax_amount], a: home_tax_amount };
                    // grid_data[row][column_indexes.home_total_amount] = { ...grid_data[row][column_indexes.home_total_amount], a: home_total_amount };

                    grid_data[row][column_indexes.home_tax_amount] = {
                      ...grid_data[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    grid_data[row][column_indexes.home_total_amount] = {
                      ...grid_data[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                  } else {
                    let tax_rate = 0;
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid_data[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid_data[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(
                        (total_amount * (tax_rate / (tax_rate + 100))).toFixed(
                          2
                        )
                      );
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(
                        (gross_amount * (tax_rate / 100)).toFixed(2)
                      );
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * value).toFixed(2);
                    let home_total_amount = (total_amount * value).toFixed(2);

                    grid_data[row][column_indexes.home_tax_amount] = {
                      ...grid_data[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    grid_data[row][column_indexes.home_total_amount] = {
                      ...grid_data[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                  }
                } else if (grid_data[row][column_indexes.tax_rate].a == '') {
                    let tax_rate = 0;
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid_data[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid_data[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(
                        (total_amount * (tax_rate / (tax_rate + 100))).toFixed(
                          2
                        )
                      );
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(
                        (gross_amount * (tax_rate / 100)).toFixed(2)
                      );
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * value).toFixed(2);
                    let home_total_amount = (total_amount * value).toFixed(2);

                    grid_data[row][column_indexes.home_tax_amount] = {
                      ...grid_data[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    grid_data[row][column_indexes.home_total_amount] = {
                      ...grid_data[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                }
              });

              // grid_data[row][column_indexes.home_gross_amount] = { ...grid_data[row][column_indexes.home_gross_amount], a: gross_home };
              grid_data[row][column_indexes.home_gross_amount] = {
                ...grid_data[row][column_indexes.home_gross_amount],
                a: this.getCurrencyFormatedString(gross_home),
              };
              //newly added end here
              this.setState({ grid: grid_data });
              // here need to update home values
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Given date is invalid");
    }
  };

  updateExchangeRateOnCurrencyChange = () => {
    let rows = this.state.selections.length;
    let g_data = this.state.grid;
    for (let row = 1; row <= rows; row++) {
      let value =
        g_data[row] &&
        g_data[row][column_indexes.date] &&
        g_data[row][column_indexes.date]["a"]
          ? g_data[row][column_indexes.date]["a"]
          : "";

      // static format change
      // if (this.state.client_date_format == "DD-MM-YYYY") {
      //   let value_array = value.split("-");
      //   if (value_array.length > 2) {
      //     value = value_array[2] + "-" + value_array[1] + "-" + value_array[0];
      //   }
      // }

      // static format - change
      // if (!isNaN(Date.parse(value))) {
      // let formed_date = moment(Date.parse(value)).format("YYYY/MM/DD");

        let formed_date = value;
        let value_array = value.split("-");
        if (value_array.length > 2) {
          formed_date = value_array[2] + "-" + value_array[1] + "-" + value_array[0];
        }

        if (new Date(formed_date) != "Invalid Date") {
          if (this.state.currency) {
            let date_formated = formed_date;

            // static format - change
            // let date_formated = value;
            // value = moment(formed_date).format(this.state.client_date_format);
            // if (
            //   formed_date != undefined &&
            //   formed_date != null &&
            //   formed_date != ""
            // ) {
            //   var array = formed_date.split("/");
            //   date_formated = array[0] + "-" + array[1] + "-" + array[2];
            // }


            if (
              this.state.exchange_rate_object[this.state.currency] &&
              this.state.exchange_rate_object[this.state.currency][
                date_formated
              ] &&
              this.state.exchange_rate_object[this.state.currency][
                date_formated
              ][this.state.currency + this.state.clientHomeCurrency]
            ) {
              let ex_rate =
                this.state.exchange_rate_object[this.state.currency][
                  date_formated
                ][this.state.currency + this.state.clientHomeCurrency].toFixed(
                  4
                );
              let grid = this.state.grid;
              //Here 10 is exchange rate index
              grid[row][column_indexes.exc_rate] = {
                ...grid[row][column_indexes.exc_rate],
                a: ex_rate,
              };

              //newly added
              let gross_amount = Number(
                grid[row][column_indexes.frgn_gross_amount].a == ""
                  ? 0
                  : grid[row][column_indexes.frgn_gross_amount].a
              );
              let gross_home = Number((gross_amount * ex_rate).toFixed(2));

              this.state.gst_list.map((itm) => {
                if (
                  itm.sales_tax_name == grid[row][column_indexes.tax_rate].a
                ) {
                  if (itm.rate_type == 1) {
                    let tax_rate = Number(itm.rate);
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(
                        (total_amount * (tax_rate / (tax_rate + 100))).toFixed(
                          2
                        )
                      );
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(
                        (gross_amount * (tax_rate / 100)).toFixed(2)
                      );
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * ex_rate).toFixed(2);
                    let home_total_amount = (total_amount * ex_rate).toFixed(2);
                    //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                    grid[row][column_indexes.home_tax_amount] = {
                      ...grid[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    //grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                    grid[row][column_indexes.home_total_amount] = {
                      ...grid[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                  } else if (itm.rate_type == 2) {
                    let tax_rate = itm.rate;
                    let tax_amount;
                    let total_amount;

                    if (this.state.isChecked) {
                      total_amount = Number(
                        grid[row][column_indexes.frgn_gross_amount].a == ""
                          ? 0
                          : grid[row][column_indexes.frgn_gross_amount].a
                      );
                      tax_amount = Number(tax_rate);
                      gross_amount = total_amount - tax_amount;
                    } else {
                      tax_amount = Number(tax_rate);
                      total_amount = Number(gross_amount + tax_amount);
                    }

                    let home_tax_amount = (tax_amount * ex_rate).toFixed(2);
                    let home_total_amount = (total_amount * ex_rate).toFixed(2);
                    //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                    grid[row][column_indexes.home_tax_amount] = {
                      ...grid[row][column_indexes.home_tax_amount],
                      a: this.getCurrencyFormatedString(home_tax_amount),
                    };
                    // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                    grid[row][column_indexes.home_total_amount] = {
                      ...grid[row][column_indexes.home_total_amount],
                      a: this.getCurrencyFormatedString(home_total_amount),
                    };
                  }
                }
              });

              // grid[row][column_indexes.home_gross_amount] = { ...grid[row][column_indexes.home_gross_amount], a: gross_home };
              grid[row][column_indexes.home_gross_amount] = {
                ...grid[row][column_indexes.home_gross_amount],
                a: this.getCurrencyFormatedString(gross_home),
              };
              //newly added end here

              if (grid && grid.length > 0) {
                if (grid[0][column_indexes.frgn_gross_amount]) {
                  grid[0][column_indexes.frgn_gross_amount] = {
                    a: `Gross amount(${this.state.currency})`,
                    readOnly: true,
                  };
                }
                if (grid[0][column_indexes.frgn_tax_amount]) {
                  grid[0][column_indexes.frgn_tax_amount] = {
                    a: `Sales tax amount(${this.state.currency})`,
                    readOnly: true,
                  };
                }
                if (grid[0][column_indexes.frgn_total_amount]) {
                  grid[0][column_indexes.frgn_total_amount] = {
                    a: `Total amount(${this.state.currency})`,
                    readOnly: true,
                  };
                }
              }
              this.setState({ grid: grid });
              // here need to update home values
            } else {
              this.getExchangeRateForGivenDate(
                formed_date,
                row,
                column_indexes.frgn_total_amount
              );
            }
          } else {
            console.log("Please select currency");
          }
        } else {
          console.log("Invalid date");
        }
      // } else {
      //   if (row == 1) {
      //     this.updateCurrencyOnTableHeading();
      //   }
      //   console.log(
      //     "Given date is Invalid. Input date should have format of any of the following  YYYY-MM-DD OR MM/DD/YYYY OR MMM DD YYYY OR DD MMM YYYY"
      //   );
      // }
    }
  };

  updateCurrencyOnTableHeading = () => {
    let grid = this.state.grid;
    if (grid && grid.length > 0) {
      if (grid[0][column_indexes.frgn_gross_amount]) {
        grid[0][column_indexes.frgn_gross_amount] = {
          a: `Gross amount(${this.state.currency})`,
          readOnly: true,
        };
      }
      if (grid[0][column_indexes.frgn_tax_amount]) {
        grid[0][column_indexes.frgn_tax_amount] = {
          a: `Sales tax amount(${this.state.currency})`,
          readOnly: true,
        };
      }
      if (grid[0][column_indexes.frgn_total_amount]) {
        grid[0][column_indexes.frgn_total_amount] = {
          a: `Total amount(${this.state.currency})`,
          readOnly: true,
        };
      }
    }
    this.setState({ grid: grid });

    // let grid = [...this.state.grid];
    // if(grid && grid.length > 0) {
    //   let row = {...grid[0]};
    //   if(row[column_indexes.frgn_gross_amount]) {
    //     row[column_indexes.frgn_gross_amount] = { a: `Gross amount(${this.state.currency})`, readOnly: true }
    //   }
    //   if(row[column_indexes.frgn_tax_amount]) {
    //     row[column_indexes.frgn_tax_amount] = { a: `Sales tax amount(${this.state.currency})`, readOnly: true }
    //   }
    //   if(row[column_indexes.frgn_total_amount]) {
    //     row[column_indexes.frgn_total_amount] = { a: `Total amount(${this.state.currency})`, readOnly: true }
    //   }
    //   grid[0] = row;
    // }
    // this.setState({ grid: grid})
  };

  calculateTaxForSelectedRows = (
    condition_need = false,
    grid = this.state.grid
  ) => {
    let rows = this.state.selections.length;
    // let grid = this.state.grid;
    for (let row = 1; row <= rows; row++) {
      if (condition_need == false || this.state.selections[row] == true) {
        this.state.gst_list.map((itm) => {
          if (
            grid[row] &&
            grid[row][column_indexes.tax_rate] &&
            itm.sales_tax_name == grid[row][column_indexes.tax_rate]["a"]
          ) {
            grid[row][column_indexes.tax_rate] = {
              ...grid[row][column_indexes.tax_rate],
              id: itm.id,
            };
            // tax calculations
            let totalCreditAmount = 0;
            if (itm.rate_type == 1) {
              let tax_rate = itm.rate;
              let gross_amount;
              let tax_amount;
              let total_amount;
              let totalcreditgross;

              if (this.state.isChecked) {
                total_amount = Number(
                  grid[row][column_indexes.frgn_gross_amount].a == ""
                    ? 0
                    : grid[row][column_indexes.frgn_gross_amount].a
                );
                tax_amount = Number(
                  (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                );
                gross_amount = total_amount - tax_amount;
                console.log("gross_amount....", gross_amount);
                console.log("gross_amount....", gross_amount);
                totalcreditgross = gross_amount.toFixed(2);
                totalcreditgross = this.setState.totalcreditgross;
              } else {
                gross_amount = Number(
                  grid[row][column_indexes.frgn_gross_amount].a == ""
                    ? 0
                    : grid[row][column_indexes.frgn_gross_amount].a
                );
                tax_amount = Number(
                  (gross_amount * (tax_rate / 100)).toFixed(2)
                );
                total_amount = gross_amount + tax_amount;
                totalcreditgross = gross_amount.toFixed(2);
                totalcreditgross = this.setState.totalcreditgross;
              }

              let exchange_rate = Number(
                grid[row][column_indexes.exc_rate].a == ""
                  ? 0
                  : grid[row][column_indexes.exc_rate].a
              );
              let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
              let home_total_amount = (total_amount * exchange_rate).toFixed(2);

              //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
              grid[row][column_indexes.home_tax_amount] = {
                ...grid[row][column_indexes.home_tax_amount],
                a: this.getCurrencyFormatedString(home_tax_amount),
              };
              //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
              grid[row][column_indexes.home_total_amount] = {
                ...grid[row][column_indexes.home_total_amount],
                a: this.getCurrencyFormatedString(home_total_amount),
              };
              //  grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_amount };
              grid[row][column_indexes.frgn_tax_amount] = {
                ...grid[row][column_indexes.frgn_tax_amount],
                a: this.getCurrencyFormatedString(tax_amount),
              };
              // grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
              grid[row][column_indexes.frgn_total_amount] = {
                ...grid[row][column_indexes.frgn_total_amount],
                a: this.getCurrencyFormatedString(total_amount),
              };
              totalCreditAmount = tax_amount;
            } else if (itm.rate_type == 2) {
              let tax_rate = itm.rate;
              let gross_amount;
              let tax_amount;
              let total_amount;

              if (this.state.isChecked) {
                total_amount = Number(
                  grid[row][column_indexes.frgn_gross_amount].a == ""
                    ? 0
                    : grid[row][column_indexes.frgn_gross_amount].a
                );
                tax_amount = Number(tax_rate);
                gross_amount = total_amount - tax_amount;
              } else {
                gross_amount = Number(
                  grid[row][column_indexes.frgn_gross_amount].a == ""
                    ? 0
                    : grid[row][column_indexes.frgn_gross_amount].a
                );
                tax_amount = Number(tax_rate);
                total_amount = gross_amount + tax_amount;
              }

              let exchange_rate = Number(
                grid[row][column_indexes.exc_rate].a == ""
                  ? 0
                  : grid[row][column_indexes.exc_rate].a
              );
              let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
              let home_total_amount = (total_amount * exchange_rate).toFixed(2);

              this.setState({ hometotalamount: home_total_amount });

              // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
              grid[row][column_indexes.home_tax_amount] = {
                ...grid[row][column_indexes.home_tax_amount],
                a: this.getCurrencyFormatedString(home_tax_amount),
              };
              // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
              grid[row][column_indexes.home_total_amount] = {
                ...grid[row][column_indexes.home_total_amount],
                a: this.getCurrencyFormatedString(home_total_amount),
              };
              // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
              grid[row][column_indexes.frgn_tax_amount] = {
                ...grid[row][column_indexes.frgn_tax_amount],
                a: this.getCurrencyFormatedString(tax_rate),
              };
              // grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
              grid[row][column_indexes.frgn_total_amount] = {
                ...grid[row][column_indexes.frgn_total_amount],
                a: this.getCurrencyFormatedString(total_amount),
              };
              totalCreditAmount = tax_rate;
            }
            // tax calculations
          }
        });
      }
    }
    this.setState({ grid: grid }, () => {
      this.updateOverallTotal();
    });
  };

  convertStringToNumber = (value) => {
    value = value.replace(/\s/g, "");
    return parseFloat(value.replace(/,/g, ""));
  };

  getCurrencyFormatedString = (number_to_convert) => {
    return new Intl.NumberFormat(
      this.state.language_code + "-" + this.state.country_sortname,
      { style: "currency", currency: this.state.clientHomeCurrency }
    )
      .format(isNaN(number_to_convert) ? "0.00" : number_to_convert)
      .replace(this.state.home_currency_symbol, "");
  };

  onCellsChanged = (changes) => {
    //debugger
    let totalCreditAmount = 0;
    console.log(column_indexes, "cellChanges", changes);
    const grid = this.state.grid;
    changes.forEach(async ({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], className: "" };
      console.log(col,cell, row,changes,  'cellChanges')
      //  finding drop down input data error

      // if (col == 5) {
      //   let error = true
      //   if ((value == '' && (cell.value == undefined || cell.value == '')) || (cell.value != undefined && cell.value != '')) {
      //     error = false
      //   }
      //   this.state.default_category_list.map(itm => {
      //     if (itm.name == value) {
      //       error = false
      //     }
      //   })
      //   if (error) {
      //     jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "2px solid #ff7676", "background": "#ffd1d1" });
      //   } else {
      //     jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "", "background": "" });
      //   }
      // }

      // if (col == 7) {
      //   let error = true
      //   if ((value == '' && (cell.value == undefined || cell.value == '')) || (cell.value != undefined && cell.value != '')) {
      //     error = false
      //   }
      //   this.state.gst_list.map(itm => {
      //     if (itm.rate == value) {
      //       error = false
      //     }
      //   })
      //   if (error) {
      //     jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "2px solid #ff7676", "background": "#ffd1d1" });
      //   } else {
      //     jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "", "background": "" });
      //   }
      // }

      //  finding drop down input data error

      if (this.state.drop == "yes" || item_changed_from_drop_down == true) {
        console.log("1356....yes");
        // to avoid error box
        jQuery(
          `#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${
            col + 1
          })`
        ).css({ border: "", background: "" });
        // to avoid error box

        grid[row][col] = { ...grid[row][col], a: cell.a };
        this.setState({ drop: "" });
        item_changed_from_drop_down = false;

        if (col == column_indexes.category) {
          this.state.default_category_list.map((itm) => {
            // below 3 lines may not need confirm and can comment
            if (itm.name == value) {
              // grid[row][col] = { ...grid[row][col], id: itm.id };
            }
          });
        }

        if (col == column_indexes.tax_rate) {
          this.state.gst_list.map((itm) => {
            if (itm.sales_tax_name == cell.a) {
              // tax calculations
              if (itm.rate_type == 1) {
                let tax_rate = itm.rate;
                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = gross_amount - tax_amount;
                } else {
                  gross_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = gross_amount + tax_amount;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                this.setState({ hometotalamount: home_total_amount });

                //grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_amount };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_amount),
                };
                // grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                //  grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
                totalCreditAmount = tax_amount;
              } else if (itm.rate_type == 2) {
                let tax_rate = itm.rate;
                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(tax_rate);
                  gross_amount = total_amount - tax_amount;
                } else {
                  gross_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(tax_rate);
                  total_amount = gross_amount + tax_amount;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                this.setState({ hometotalamount: home_total_amount });

                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_rate),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };

                totalCreditAmount = tax_rate;
              }
              // tax calculations

              grid[row][col] = { ...grid[row][col], id: itm.id };
            }
          });
          this.calculateTaxForSelectedRows(true);
        }
      } else {
        // for error box handle
        let error = true;
        if (
          value == "" ||
          (col != column_indexes.category && col != column_indexes.tax_rate)
        ) {
          error = false;
        }

        if (value != "") {
          if (col == column_indexes.date) {

            let future_date_check = value;
            if(value) {
              try {
              let d_array = future_date_check.split("-");
              if(d_array.length > 2) {
                future_date_check = d_array[2] + "-"+ d_array[1] +"-"+ d_array[0];
              }
            } catch(ex) {
              console.log(ex);
            }
            }

            // if (moment(value).isAfter(moment())) {
              if (moment(future_date_check).isAfter(moment())) {
              if (this.state.isFutureDate) {
                if (this.state.currency == this.state.clientHomeCurrency) {
                  Swal.fire("You are entering a transaction for Future Date!");
                } else {
                  Swal.fire(
                    "You are entering a transaction for Future Date!",
                    "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction"
                  );
                }
                this.setState({isFutureDate: false})
              }
            }

            //static format - change
            // if (!isNaN(Date.parse(value))) {
              // let formed_date = moment(Date.parse(value)).format("YYYY/MM/DD");

              let formed_date = value;
              let value_array = value.split("-");
              if (value_array.length > 2) {
                formed_date = value_array[2] + "-" + value_array[1] + "-" + value_array[0];
              }

              if (new Date(formed_date) != "Invalid Date") {
                if (this.state.currency) {
                  let date_formated = formed_date;

                  //static format - change
                  // let date_formated = value;
                  // value = moment(formed_date).format(
                  //   this.state.client_date_format
                  // );

                  // if (
                  //   formed_date != undefined &&
                  //   formed_date != null &&
                  //   formed_date != ""
                  // ) {
                  //   var array = formed_date.split("/");
                  //   date_formated = array[0] + "-" + array[1] + "-" + array[2];
                  // }

                  // No need this condition so always check for false
                  if (
                    false &&
                    this.state.exchange_rate_object[this.state.currency] &&
                    this.state.exchange_rate_object[this.state.currency][
                      date_formated
                    ] &&
                    this.state.exchange_rate_object[this.state.currency][
                      date_formated
                    ][this.state.currency + this.state.clientHomeCurrency]
                  ) {
                    let ex_rate =
                      this.state.exchange_rate_object[this.state.currency][
                        date_formated
                      ][
                        this.state.currency + this.state.clientHomeCurrency
                      ].toFixed(4);
                    let grid_data = this.state.grid;
                    //Here 10 is exchange rate index
                    grid_data[row][column_indexes.exc_rate] = {
                      ...grid_data[row][column_indexes.exc_rate],
                      a: ex_rate,
                    };

                    //newly added
                    let gross_amount = Number(
                      grid[row][column_indexes.frgn_gross_amount].a == ""
                        ? 0
                        : grid[row][column_indexes.frgn_gross_amount].a
                    );
                    let gross_home = Number(
                      (gross_amount * ex_rate).toFixed(2)
                    );

                    this.state.gst_list.map((itm) => {
                      if (
                        itm.sales_tax_name ==
                        grid[row][column_indexes.tax_rate].a
                      ) {
                        if (itm.rate_type == 1) {
                          let tax_rate = Number(itm.rate);
                          let tax_amount;
                          let total_amount;

                          if (this.state.isChecked) {
                            total_amount = Number(
                              grid[row][column_indexes.frgn_gross_amount].a ==
                                ""
                                ? 0
                                : grid[row][column_indexes.frgn_gross_amount].a
                            );
                            tax_amount = Number(
                              (
                                total_amount *
                                (tax_rate / (tax_rate + 100))
                              ).toFixed(2)
                            );
                            gross_amount = total_amount - tax_amount;
                          } else {
                            tax_amount = Number(
                              (gross_amount * (tax_rate / 100)).toFixed(2)
                            );
                            total_amount = Number(gross_amount + tax_amount);
                          }

                          let home_tax_amount = (tax_amount * ex_rate).toFixed(
                            2
                          );
                          let home_total_amount = (
                            total_amount * ex_rate
                          ).toFixed(2);
                          //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                          grid[row][column_indexes.home_tax_amount] = {
                            ...grid[row][column_indexes.home_tax_amount],
                            a: this.getCurrencyFormatedString(home_tax_amount),
                          };
                          //grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                          grid[row][column_indexes.home_total_amount] = {
                            ...grid[row][column_indexes.home_total_amount],
                            a: this.getCurrencyFormatedString(
                              home_total_amount
                            ),
                          };
                        } else if (itm.rate_type == 2) {
                          let tax_rate = itm.rate;
                          let tax_amount;
                          let total_amount;

                          if (this.state.isChecked) {
                            total_amount = Number(
                              grid[row][column_indexes.frgn_gross_amount].a ==
                                ""
                                ? 0
                                : grid[row][column_indexes.frgn_gross_amount].a
                            );
                            tax_amount = Number(tax_rate);
                            gross_amount = total_amount - tax_amount;
                          } else {
                            tax_amount = Number(tax_rate);
                            total_amount = Number(gross_amount + tax_amount);
                          }

                          let home_tax_amount = (tax_amount * ex_rate).toFixed(
                            2
                          );
                          let home_total_amount = (
                            total_amount * ex_rate
                          ).toFixed(2);
                          // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                          grid[row][column_indexes.home_tax_amount] = {
                            ...grid[row][column_indexes.home_tax_amount],
                            a: this.getCurrencyFormatedString(home_tax_amount),
                          };
                          // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                          grid[row][column_indexes.home_total_amount] = {
                            ...grid[row][column_indexes.home_total_amount],
                            a: this.getCurrencyFormatedString(
                              home_total_amount
                            ),
                          };
                        }
                      }
                    });

                    // grid[row][column_indexes.home_gross_amount] = { ...grid[row][column_indexes.home_gross_amount], a: gross_home };
                    grid[row][column_indexes.home_gross_amount] = {
                      ...grid[row][column_indexes.home_gross_amount],
                      a: this.getCurrencyFormatedString(gross_home),
                    };
                    //newly added end here

                    this.setState({ grid: grid_data });
                    // here need to update home values
                  } else {
                    this.getExchangeRateForGivenDate(formed_date, row, col);
                  }
                } else {
                  console.log("Please select currency");
                }
              } else {
                console.log("Invalid date");
              }

               //static format - change
            // } else {
            //   console.log(
            //     "Given date is Invalid. Input date should have format of any of the following  YYYY-MM-DD OR MM/DD/YYYY OR MMM DD YYYY OR DD MMM YYYY"
            //   );
            // }

          }
        }
        if (col == column_indexes.category) {
          this.state.default_category_list.map((itm) => {
            if (itm.name == value) {
              error = false;
              grid[row][col] = { ...grid[row][col], id: itm.id };
            }
          });
        }
        if (col == column_indexes.tax_rate) {
          this.state.gst_list.map((itm) => {
            if (itm.sales_tax_name == value) {
              error = false;
              grid[row][col] = { ...grid[row][col], id: itm.id };
              // tax calculations
              if (itm.rate_type == 1) {
                let tax_rate = itm.rate;
                let gross_amount;
                let tax_amount;
                let total_amount;
                let totalcreditgross;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = total_amount - tax_amount;
                  totalcreditgross = gross_amount.toFixed(2);
                  totalcreditgross = this.setState.totalcreditgross;
                } else {
                  gross_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = gross_amount + tax_amount;
                  totalcreditgross = gross_amount.toFixed(2);
                  totalcreditgross = this.setState.totalcreditgross;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_amount };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_amount),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                totalCreditAmount = tax_amount;
              } else if (itm.rate_type == 2) {
                let tax_rate = itm.rate;
                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(tax_rate);
                  gross_amount = total_amount - tax_amount;
                } else {
                  gross_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(tax_rate);
                  total_amount = gross_amount + tax_amount;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                this.setState({ hometotalamount: home_total_amount });

                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_rate),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                totalCreditAmount = tax_rate;
              }
              // tax calculations
            }
          });
        }

        // condition removed on
        // if(col == column_indexes.user_type && (is_user_type_modified == 1)) {
        if (col == column_indexes.user_type) {
          let c_type = selected_user_type ? selected_user_type : value;
          grid[row][col] = { ...grid[row][col], a: c_type, value: c_type };

          for (let i = 0; i < this.state.selections.length - 1; i++) {
            if (this.state.selections[i] == true) {
              grid[row][col] = { ...grid[row][col], a: c_type, value: c_type };
            }
          }

          selected_user_type = "";
          is_user_type_modified = 0;
        }

        if (error) {
          grid[row][col] = { ...grid[row][col], className: "grid-bg-color" };
          //jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "2px solid #ff7676", "background": "#ffd1d1" });
        } else {
          //jQuery(`#sticky-tb-hdr tbody tr:nth-child(${row + 1}) td:nth-child(${col + 1})`).css({ "border": "", "background": "" })
          grid[row][col] = { ...grid[row][col], className: "" };
        }

        // home value calculations based on exchange rate
        if (col == column_indexes.frgn_gross_amount) {
          if (isNaN(Number(value))) {
            // value = parseFloat(value.replace(/,/g, ''));
            value = this.convertStringToNumber(value);
          }

          let gross_amount = value == "" ? 0 : value;
          let exchange_rate =
            grid[row][column_indexes.exc_rate].a == ""
              ? 0
              : grid[row][column_indexes.exc_rate].a;
          let gross_home =
            (gross_amount * exchange_rate).toFixed(2) == 0.0
              ? ""
              : (gross_amount * exchange_rate).toFixed(2);
          // grid[row][column_indexes.home_gross_amount] = { ...grid[row][column_indexes.home_gross_amount], a: gross_home };
          grid[row][column_indexes.home_gross_amount] = {
            ...grid[row][column_indexes.home_gross_amount],
            a: this.getCurrencyFormatedString(gross_home),
          };

          this.state.gst_list.map((itm) => {
            // console.log(itm, 'cell');
            console.log("grid[row][column_indexes.tax_rate].a.....",grid[row][column_indexes.tax_rate].a);
           // console.log("grid[row][column_indexes.tax_rate].a.....",grid[row][column_indexes.tax_rate].a);
           if (itm.sales_tax_name == grid[row][column_indexes.tax_rate].a) {
              // tax calculations
              if (itm.rate_type == 1) {
                let tax_rate = itm.rate;
                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = total_amount - tax_amount;
                } else {
                  gross_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = gross_amount + tax_amount;

                  // this.setState({hometotalamount:home_total_amount});
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );
                this.setState({ hometotalamount: home_total_amount });
                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                // grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };

                //2023-11-22
                grid[row][column_indexes.frgn_gross_amount] = {
                  ...grid[row][column_indexes.frgn_gross_amount],
                  a: this.getCurrencyFormatedString(gross_amount),
                  value: this.getCurrencyFormatedString(gross_amount)
                };

                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_amount };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_amount),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                totalCreditAmount = tax_rate;
              } else if (itm.rate_type == 2) {
                let tax_rate = itm.rate;

                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(tax_rate);
                  gross_amount = total_amount - tax_amount;
                } else {
                  gross_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(tax_rate);
                  total_amount = gross_amount + tax_amount;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                // this.setState({ hometotalamount: home_total_amount });
                this.setState({ hometotalamount: home_total_amount });
                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };

                //2023-11-22
                grid[row][column_indexes.frgn_gross_amount] = {
                  ...grid[row][column_indexes.frgn_gross_amount],
                  a: this.getCurrencyFormatedString(gross_amount),
                  value: this.getCurrencyFormatedString(gross_amount)
                };

                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_amount),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                totalCreditAmount = tax_rate;
              } else {
                let tax_rate = 0;
                let gross_amount;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(tax_rate);
                  gross_amount = total_amount - tax_amount;
                } else {
                  gross_amount = Number(value == "" ? 0 : value);
                  tax_amount = Number(tax_rate);
                  total_amount = gross_amount + tax_amount;
                }

                let exchange_rate = Number(
                  grid[row][column_indexes.exc_rate].a == ""
                    ? 0
                    : grid[row][column_indexes.exc_rate].a
                );
                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );

                // this.setState({ hometotalamount: home_total_amount });
                this.setState({ hometotalamount: home_total_amount });
                // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };

              //2023-11-22
              grid[row][column_indexes.frgn_gross_amount] = {
                ...grid[row][column_indexes.frgn_gross_amount],
                a: this.getCurrencyFormatedString(gross_amount),
                value: this.getCurrencyFormatedString(gross_amount)
              };

                // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
                grid[row][column_indexes.frgn_tax_amount] = {
                  ...grid[row][column_indexes.frgn_tax_amount],
                  a: this.getCurrencyFormatedString(tax_amount),
                };
                //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
                grid[row][column_indexes.frgn_total_amount] = {
                  ...grid[row][column_indexes.frgn_total_amount],
                  a: this.getCurrencyFormatedString(total_amount),
                };
                totalCreditAmount = tax_rate;
              }
              // tax calculations
            }
            else if(grid[row][column_indexes.tax_rate].a == '') {
              let tax_rate = 0;
              let gross_amount;
              let tax_amount;
              let total_amount;

              if (this.state.isChecked) {
                total_amount = Number(value == "" ? 0 : value);
                tax_amount = Number(tax_rate);
                gross_amount = total_amount - tax_amount;
              } else {
                gross_amount = Number(value == "" ? 0 : value);
                tax_amount = Number(tax_rate);
                total_amount = gross_amount + tax_amount;
              }

              let exchange_rate = Number(
                grid[row][column_indexes.exc_rate].a == ""
                  ? 0
                  : grid[row][column_indexes.exc_rate].a
              );
              let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
              let home_total_amount = (total_amount * exchange_rate).toFixed(
                2
              );

              // this.setState({ hometotalamount: home_total_amount });
              this.setState({ hometotalamount: home_total_amount });
              // grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
              grid[row][column_indexes.home_tax_amount] = {
                ...grid[row][column_indexes.home_tax_amount],
                a: this.getCurrencyFormatedString(home_tax_amount),
              };
              //  grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
              grid[row][column_indexes.home_total_amount] = {
                ...grid[row][column_indexes.home_total_amount],
                a: this.getCurrencyFormatedString(home_total_amount),
              };

              //2023-11-22
              grid[row][column_indexes.frgn_gross_amount] = {
                ...grid[row][column_indexes.frgn_gross_amount],
                a: this.getCurrencyFormatedString(gross_amount),
                value: this.getCurrencyFormatedString(gross_amount)
              };

              // grid[row][column_indexes.frgn_tax_amount] = { ...grid[row][column_indexes.frgn_tax_amount], a: tax_rate };
              grid[row][column_indexes.frgn_tax_amount] = {
                ...grid[row][column_indexes.frgn_tax_amount],
                a: this.getCurrencyFormatedString(tax_amount),
              };
              //  grid[row][column_indexes.frgn_total_amount] = { ...grid[row][column_indexes.frgn_total_amount], a: total_amount };
              grid[row][column_indexes.frgn_total_amount] = {
                ...grid[row][column_indexes.frgn_total_amount],
                a: this.getCurrencyFormatedString(total_amount),
              };
              totalCreditAmount = tax_rate;
            }
          });

          //2023-11-22
          // grid[row][column_indexes.frgn_gross_amount] = {
          //   ...grid[row][column_indexes.frgn_gross_amount],
          //   a: this.getCurrencyFormatedString(value),
          //   value: this.getCurrencyFormatedString(value),
          // };
          // grid[row][column_indexes.frgn_total_amount] = {
          //   ...grid[row][column_indexes.frgn_total_amount],
          //   a: this.getCurrencyFormatedString(value),
          // };
        }
        if (col == column_indexes.exc_rate) {
          let exchange_rate = Number(value == "" ? 0 : value);
          console.log(
            "grid[row][column_indexes.frgn_gross_amount].a==========1924",
            grid[row][column_indexes.frgn_gross_amount].a
          );
          let gross_amount = Number(
            grid[row][column_indexes.frgn_gross_amount].a == ""
              ? 0
              : grid[row][column_indexes.frgn_gross_amount].a
          );
          console.log("gross_amount==========1926", gross_amount);
          let gross_home = Number((gross_amount * exchange_rate).toFixed(2));

          this.state.gst_list.map((itm) => {
            if (itm.sales_tax_name == grid[row][column_indexes.tax_rate].a) {
              if (itm.rate_type == 1) {
                let tax_rate = Number(itm.rate);
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = total_amount - tax_amount;
                } else {
                  // tax_amount = Number((gross_amount * tax_rate).toFixed(2))
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = Number(gross_amount + tax_amount);
                }

                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );
                //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
              } else if (itm.rate_type == 2) {
                let tax_rate = itm.rate;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  //total_amount = Number(value == '' ? 0 : value);
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(tax_rate);
                  gross_amount = total_amount - tax_amount;
                } else {
                  tax_amount = Number(tax_rate);
                  total_amount = Number(gross_amount + tax_amount);
                }

                // let tax_amount = Number(tax_rate)
                // let total_amount = Number(gross_amount + tax_amount)

                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );
                //grid[row][column_indexes.home_tax_amount] = { ...grid[row][column_indexes.home_tax_amount], a: home_tax_amount };
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                //grid[row][column_indexes.home_total_amount] = { ...grid[row][column_indexes.home_total_amount], a: home_total_amount };
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
              } else {
                let tax_rate = 0;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = total_amount - tax_amount;
                } else {
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = Number(gross_amount + tax_amount);
                }

                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );
                
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
              }
            } else if(grid[row][column_indexes.tax_rate].a == '') {
                let tax_rate = 0;
                let tax_amount;
                let total_amount;

                if (this.state.isChecked) {
                  total_amount = Number(
                    grid[row][column_indexes.frgn_gross_amount].a == ""
                      ? 0
                      : grid[row][column_indexes.frgn_gross_amount].a
                  );
                  tax_amount = Number(
                    (total_amount * (tax_rate / (tax_rate + 100))).toFixed(2)
                  );
                  gross_amount = total_amount - tax_amount;
                } else {
                  tax_amount = Number(
                    (gross_amount * (tax_rate / 100)).toFixed(2)
                  );
                  total_amount = Number(gross_amount + tax_amount);
                }

                let home_tax_amount = (tax_amount * exchange_rate).toFixed(2);
                let home_total_amount = (total_amount * exchange_rate).toFixed(
                  2
                );
                
                grid[row][column_indexes.home_tax_amount] = {
                  ...grid[row][column_indexes.home_tax_amount],
                  a: this.getCurrencyFormatedString(home_tax_amount),
                };
                
                grid[row][column_indexes.home_total_amount] = {
                  ...grid[row][column_indexes.home_total_amount],
                  a: this.getCurrencyFormatedString(home_total_amount),
                };
            }
          });

          // grid[row][column_indexes.home_gross_amount] = { ...grid[row][column_indexes.home_gross_amount], a: gross_home };
          grid[row][column_indexes.home_gross_amount] = {
            ...grid[row][column_indexes.home_gross_amount],
            a: this.getCurrencyFormatedString(gross_home),
          };
        }

        // home value calculations based on exchange rate

        // modified on 10-09-2022
        // grid[row][col] = { ...grid[row][col], a: value };
        if (col != column_indexes.user_type) {
          grid[row][col] = { ...grid[row][col], a: value };
        }
      }
      // for error box handle
    });

    this.setState(
      {
        grid,
        totalCreditAmount: this.state.totalCreditAmount + totalCreditAmount,
      },
      () => {
        this.updateOverallTotal();
      }
    );
  };

  updateOverallTotal = () => {
    let grossamount_foreign = _.sumBy(this.state.grid, function (row) {
      let v = row[column_indexes.frgn_gross_amount]["a"];
      if (typeof v == "string") {
        v = v.replace(/,/g, "");
        v = v.replace(/\s/g, "");
      }
      v = parseFloat(v);
      if (!isNaN(v)) {
        return v;
      } else {
        return 0;
      }
    });

    let salestaxamount_foreign = _.sumBy(this.state.grid, function (row) {
      let v = row[column_indexes.frgn_tax_amount]["a"];
      if (typeof v == "string") {
        v = v.replace(/,/g, "");
        v = v.replace(/\s/g, "");
      }
      v = parseFloat(v);
      if (!isNaN(v)) {
        return v;
      } else {
        return 0;
      }
    });

    let totalamount_foreign = _.sumBy(this.state.grid, function (row) {
      let v = row[column_indexes.frgn_total_amount]["a"];
      if (typeof v == "string") {
        v = v.replace(/,/g, "");
        v = v.replace(/\s/g, "");
      }
      v = parseFloat(v);
      if (!isNaN(v)) {
        return v;
      } else {
        return 0;
      }
    });

    this.setState(
      {
        grossamount_foreign: grossamount_foreign,
        salestaxamount_foreign: salestaxamount_foreign,
        totalamount_foreign: totalamount_foreign,
      },
      () => {
        if (this.state.isChecked == true) {
          let grossamount_foreign = parseFloat(
            (totalamount_foreign - salestaxamount_foreign).toFixed(2)
          );
          this.setState({ grossamount_foreign: grossamount_foreign });
        }
      }
    );
  };
  onContextMenu = (e, cell, i, j) =>
    cell.readOnly ? e.preventDefault() : null;

  // for data sheet
  modal_cancel = () => {
    jQuery("#sales_tax_code").val("");
    jQuery("#sales_tax_name").val("");
    jQuery("#sales_tax_name").val("");
    jQuery("#tax").val("");
    this.setState({ modal_info_msg: "" });
    window.jQuery("#pop-modal-1").modal("hide");
  };

  selected_account = (curr, transaction_type) => {
    // for exchange rate
    if (curr) {
      let dateTo = moment().format("YYYY-MM-DD");
      let dateFrom = moment().subtract(360, "d").format("YYYY-MM-DD");
      let nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${dateFrom}&end_date=${dateTo}&currencies=${this.state.clientHomeCurrency}&source=`;
      let res = nope.concat(curr);
      fetch(res)
        .then(async (response) => response.json())
        .then(async (data) => {
          if (data.success == true) {
            console.log("data...quotes...2095", data.quotes);
            let newObj = this.rename(data.quotes, curr);
            let currencyAr = [];
            let first = newObj;
            // this.setState({exchange_rate_object:first});
            let obj = {};
            obj[this.state.currency] = first;
            this.setState(
              {
                exchange_rate_object: obj,
              },
              () => {
                console.log(
                  "exchange_rate_object...2104",
                  this.state.exchange_rate_object
                );

                let exchangeRate =
                  this.state.exchange_rate_object[this.state.currency] &&
                  this.state.exchange_rate_object[this.state.currency][
                    dateTo
                  ] &&
                  this.state.exchange_rate_object[this.state.currency][dateTo][
                    this.state.currency + this.state.clientHomeCurrency
                  ]
                    ? parseFloat(
                        this.state.exchange_rate_object[this.state.currency][
                          dateTo
                        ][this.state.currency + this.state.clientHomeCurrency]
                      ).toFixed(4)
                    : 1;
                console.log("exchangeRate...2108", exchangeRate);
                var currency = curr;
                var result = [];
                let is_account_exists = false;
                let account_name = "";
                if (
                  transaction_type == "" ||
                  transaction_type == 1 ||
                  transaction_type == 2
                ) {
                  this.state.SubAccountList.forEach((item, i) => {
                    var fullString = item.name.split("-");
                    var list_curr = fullString[1];

                    var kk = "Accounts Receivable-" + currency;
                    account_name = "Accounts Receivable-" + currency;
                    // if(transaction_type == 2 || transaction_type == 3) {
                    if (transaction_type == 2) {
                      kk = "Accounts Payable-" + currency;
                      account_name = "Accounts Payable-" + currency;
                    }

                    // if (item.name == kk) {
                    if (item.name == kk) {
                      is_account_exists = true;
                      result.push(item);
                      // this.setState({ result_account: item.id, exchangeRate: exchangeRate }, this.get_gst_list(exchangeRate))
                      this.setState(
                        {
                          result_account: item.id,
                          exchangeRate: exchangeRate,
                          account_type: account_name,
                        },
                        this.updateExchangeRateOnCurrencyChange()
                      );
                    }
                  });
                  
                  if(is_account_exists == false) {
                    let acc_type =  (this.state.transaction_type == 1)? 2:5;
                    let custom_ar_account = _.filter(this.state.SubAccountList, { currency:currency, account_type_id:acc_type });
                    if(custom_ar_account && custom_ar_account.length > 0) {
                      is_account_exists = true;
                      result.push(custom_ar_account[0]);
                    
                      this.setState({
                          result_account: custom_ar_account[0].id,
                          exchangeRate: exchangeRate,
                          account_type: account_name
                        },()=>{
                        this.updateExchangeRateOnCurrencyChange()
                        }
                      );
                    }
                  }

                  if (is_account_exists == false) {
                    var coreData = {
                      account_name: account_name,
                      account_type_id:
                        transaction_type == 1 || transaction_type == 4 ? 2 : 5,
                      currency: currency,
                      client_id: this.state.logged_client_id,
                    };

                    FetchAllApi.addNewAccountName(coreData, (err, response) => {
                      if (response.status === 1) {
                        this.getSubAccountList(coreData.account_type_id);
                        // this.setState({ result_account: response.account_id, exchangeRate: exchangeRate }, this.get_gst_list(exchangeRate))
                        this.setState(
                          {
                            result_account: response.account_id,
                            exchangeRate: exchangeRate,
                            account_type: account_name,
                          },
                          this.updateExchangeRateOnCurrencyChange()
                        );
                      } else {
                        alert("Failed to create Account");
                      }
                    });
                  }
                } else if (transaction_type == 3 || transaction_type == 4) {
                  FetchAllApi.getBanksByCondition(
                    {
                      client_id: this.state.logged_client_id,
                      currency: currency,
                    },
                    (err, response) => {
                      if (response.status === 1) {
                        this.setState({ banks: response.data }, () => {});
                      } else {
                        this.setState({ banks: [] });
                      }
                    }
                  );

                  this.setState(
                    { exchangeRate: exchangeRate },
                    this.updateExchangeRateOnCurrencyChange()
                  );
                }
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // var exchangeRate = ''
    // fetch(
    //   `https://api.currencylayer.com/live?access_key=${config.api_key}&source=${curr}`

    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let newObj = this.rename(data.quotes, curr)

    //     const currencyAr = [];
    //     let first = newObj;
    //     for (const key in first) {
    //       currencyAr.push(key);
    //     }
    //    // this.setState({ currencies: currencyAr, currency_clone: currencyAr, currency_list: first });
    //     exchangeRate = first[this.state.clientHomeCurrency];
    //     var currency = curr;
    //     var result = [];
    //     let is_account_exists = false;
    //     let account_name = '';
    //     this.state.SubAccountList.forEach((item, i) => {
    //       var fullString = item.name.split("-");
    //       var list_curr = fullString[1];

    //       var kk = "Accounts Receivable-" + currency;
    //       account_name = "Accounts Receivable-" + currency;
    //       if(transaction_type == 2 || transaction_type == 3) {
    //         kk = "Accounts Payable-" + currency;
    //         account_name = "Accounts Payable-" + currency;
    //       }

    //       if (item.name == kk) {
    //         is_account_exists = true;
    //         result.push(item);
    //         this.setState({ result_account: item.id, exchangeRate: exchangeRate }, this.get_gst_list(exchangeRate))
    //       }
    //     });

    //     if(is_account_exists == false) {
    //       var coreData = {
    //         account_name: account_name,
    //         account_type_id: (transaction_type == 1 || transaction_type == 4)?2:5,
    //         currency: currency,
    //         client_id: this.state.logged_client_id,
    //       };

    //       FetchAllApi.addNewAccountName(coreData, (err, response) => {
    //           if (response.status === 1) {
    //             this.getSubAccountList();
    //             this.setState({ result_account: response.account_id, exchangeRate: exchangeRate }, this.get_gst_list(exchangeRate))
    //           } else {
    //             alert("Failed to create Account");
    //           }
    //         });
    //      }
    //   })
    //   .catch(err => {
    //     alert("Failed to fetch exchange rate");
    //   });
  };

  getSubAccountList = (account_type_id) => {
    var coreData = {
      // account_type_id: 2,
      account_type_id: account_type_id,
      client_id: this.state.logged_client_id,
    };

    // FetchAllApi.getSubAccountList(coreData, (err, response) => {
    FetchAllApi.receivableAndPayableAccountList(coreData, (err, response) => {
      console.log("vendor_nljfskdkdssdkfames", response);

      if (response.status === 1) {
        this.setState({ SubAccountList: response.list });

        // alert('success')
        // this.getItems()
        // window.jQuery('#add_items').modal('hide')
      } else {
      }
    });
  };

  getAllbanks = () => {
    let filter_id = 2;
    FetchAllApi.getAllbanks(
      this.state.logged_client_id,
      filter_id,
      (err, response) => {
        if (response.status === 1) {
          this.setState({ banks: response.data }, () => {
            console.log("1910....", this.state.banks);
          });
        } else {
          this.setState({ banks: [] });
        }
      }
    );
  };

  save_transaction = () => {
    const formattedData = this.state.grid.map((row) => {
      return row.map((cell) => {
        // due to date format change
        // if (cell.a && moment(cell.a, localStorage.getItem('date_format'), true).isValid()) {
        //   cell.a = moment(cell.a, localStorage.getItem('date_format')).format('YYYY-MM-DD');
        // }
        return cell;
      });
    });

    let coreData = {
      client_id: this.state.logged_client_id,
      transaction_type: this.state.transaction_type,
      currency: this.state.currency,
      main_array: JSON.stringify(formattedData),
      account: this.state.result_account,
      including_tax: this.state.isChecked == true ? 1 : 0,
    };

    // console.log(this.state.grid, 'rest')
    
      if (this.state.batchid && this.state.batchid != 0) {
        coreData["batch_transaction_id"] = this.state.batchid;
        FetchAllApi.edit_batch_transaction(coreData, (err, response) => {
          if (response.status === 1) {
            this.setState({ isSuccessful: true, old_transaction_date_array: this.state.previous_transaction_dates_to_update_on_success, hasId: this.state.batchid });
            setTimeout(() => {
              this.setState({ isSuccessful: false });
            }, 3000);
          } else {
            this.setState({
              isFailed: true,
              errormessage: response.message,
            });
            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: "" });
            }, 3000);
          }
        });
      } else {
        FetchAllApi.create_batch_transaction(coreData, (err, response) => {
          if (response.status === 1) {
            if (response.batch_transaction_id) {
              this.setState({
                batchid: response.batch_transaction_id,
                isSuccessful: true,
                old_transaction_date_array: this.state.previous_transaction_dates_to_update_on_success,
                hasId:response.batch_transaction_id
              });
              setTimeout(() => {
                this.setState({ isSuccessful: false }, () => {
                  this.props.history.push("/batch_transaction_list");
                });
              }, 3000);
            }
            // alert('batch transactions - invoices added sucessfully');
          } else {
            //  alert(response.message);
            this.setState({
              isFailed: true,
              errormessage: response.message,
            });
            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: "" });
            }, 3000);
          }
        });
      
    }

  };


  componentWillUnmount = () => {
    localStorage.setItem('hasId', '');
  };


  // // // // // // lock date checks and function
   // to check lock password

   checkLockDate = () => {
    const lockDate = localStorage.getItem('lock_date'); // Retrieve the lock date from local storage

      let previous_transaction_dates_to_update_on_success = [];
      const isDateOlder = this.state.grid.some((nestedArray) =>{
      let d = nestedArray[1].a;
      if(nestedArray[1].a) {
        try {
        let d_array = d.split("-");
        if(d_array.length > 2) {
          d = d_array[2] + "-"+ d_array[1] +"-"+ d_array[0];
        }
        previous_transaction_dates_to_update_on_success.push(d);
      } catch(ex) {
        console.log(ex);
      }
      }

        // return nestedArray[1].a && new Date(nestedArray[1].a) <= new Date(lockDate)
        return d && new Date(d) <= new Date(lockDate)
   });

   let isHaveOldTransactionDate = this.state.old_transaction_date_array.some((old_date) =>{
    let d = old_date;
    if(old_date) {
      try {
      let d_array = d.split("-");
      if(d_array.length > 2) {
        d = d_array[2] + "-"+ d_array[1] +"-"+ d_array[0];
      }
    } catch(ex) {
      console.log(ex);
    }
    }
    return d && new Date(d) <= new Date(lockDate)
 });
 
      // if (this.state.hasId ? !isDateOlder : isDateOlder) {
      if (this.state.hasId ? (isHaveOldTransactionDate || isDateOlder): isDateOlder) {
        // At least one date is older than the lock date
        this.setState({
          previous_transaction_dates_to_update_on_success:previous_transaction_dates_to_update_on_success
        },()=>{
        jQuery("#lock_date").modal("show");
        });
        // alert('At least one date is older than the lock date');
      } else {
        // All dates are either newer or invalid
        // alert('All dates are either newer or invalid.');
        this.setState({
          previous_transaction_dates_to_update_on_success:previous_transaction_dates_to_update_on_success
        },()=>{
          this.save_transaction()
        });
      }
  };

  checkPassword = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      password: this.state.password,
    };

    FetchAllApi.verify_lock_date_password(Input, (err, response) => {
      if (response.status === 1) {
        console.log(response,'grid')
        this.save_transaction()
      } else {
        alert(response.message);
        this.setState({ deleteMsg: true, password: '' });
      }
    });
  };

  clear_data = () => {
    let grid_data = [];
    let my_grid = [
      { readOnly: true, value: "" },
      { a: "Invoice / Credit memo date", readOnly: true },
      { a: "Invoice/ Credit memo  no", readOnly: true },
      { a: "Name ", readOnly: true },
      { a: `Type`, readOnly: true },
      { a: "Description", readOnly: true },
      { a: "Category", readOnly: true },
      { a: `Gross amount( )`, readOnly: true },
      { a: "Sales tax rate", readOnly: true },
      { a: `Sales tax amount ( )`, readOnly: true },
      { a: `Total amount ( )`, readOnly: true },
      { a: "Exchange rate", readOnly: true },
      {
        a: `Gross amount(${localStorage.getItem("home_currency")})`,
        readOnly: true,
      },
      {
        a: `Sales tax amount (${localStorage.getItem("home_currency")})`,
        readOnly: true,
      },
      {
        a: `Total amount (${localStorage.getItem("home_currency")})`,
        readOnly: true,
      },
    ];
    grid_data.push(my_grid);
    if (this.state.editrows.length == 0) {
      for (let i = 1; i <= this.state.total_number_of_rows; i++) {
        let row = [
          { readOnly: true, a: i },
          {
            a: `${this.state.client_date_format}`,
            readOnly: true,
          },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          {
            a: "",
            readOnly: true,
            component: (
              <select
                data-live-search='true'
                onChange={(e, props) => {
                  let selectedIndex = e.target.options.selectedIndex;
                  let row_index =
                    e.target.options[selectedIndex].getAttribute(
                      "data-row-index"
                    );
                  if (e.target.value == "") {
                  }
                  let data = this.state.grid;
                  data[row_index][column_indexes.user_type] = {
                    ...data[row_index][column_indexes.user_type],
                    a: e.target.value,
                    value: e.target.value,
                  };
                  // for (i=0 ; i <this.state.selections.length-1; i++){
                  for (i = 1; i < this.state.selections.length; i++) {
                    //  if(this.state.selections[i] == true || (i == row_index)){
                    if (this.state.selections[i] == true) {
                      data[i][column_indexes.user_type] = {
                        ...data[i][column_indexes.user_type],
                        a: e.target.value,
                        value: e.target.value,
                      };
                      // this.setState({ grid: data, drop: 'yes' });
                    }
                  }

                  is_user_type_modified = 1;
                  selected_user_type = e.target.value;
                }}
                onCommit={(vn, keydown) => {
                  console.log("gggg...652");
                }}
              >
                {/* <option data-row-index={i} key="0" value=""> Choose... </option> */}
                <option data-row-index={i} key='0' value=''>
                  {" "}
                  Search and Choose...{" "}
                </option>
                <option data-row-index={i} key='1' value='Customer'>
                  {" "}
                  Customer
                </option>
                <option data-row-index={i} key='2' value='Vendor'>
                  {" "}
                  Vendor
                </option>
                <option data-row-index={i} key='3' value='Employee'>
                  {" "}
                  Employee
                </option>
                <option data-row-index={i} key='4' value='Others'>
                  {" "}
                  Others
                </option>
              </select>
            ),
          },
          { a: "", readOnly: true },
          {
            a: "",
            readOnly: true,
            component: (
              <select
                data-live-search='true'
                onChange={(e, props) => {
                  let selectedIndex = e.target.options.selectedIndex;
                  let row_index =
                    e.target.options[selectedIndex].getAttribute(
                      "data-row-index"
                    );
                  let ac_id =
                    e.target.options[selectedIndex].getAttribute("data-acc-id");
                  if (e.target.value == "1e") {
                    this.addNewAcc();
                  }

                  // debugger
                  let data = this.state.grid;
                  // data[i][column_indexes.category] = { ...data[i][column_indexes.category], a: e.target.value }
                  //  if(this.state.selections[2] == true ){

                  // To change on clicked row
                  data[row_index][column_indexes.category] = {
                    ...data[row_index][column_indexes.category],
                    a: e.target.value,
                    value: e.target.value,
                    id: ac_id,
                    className: "",
                  };
                  // this.setState({ grid: data, drop: 'yes' })
                  item_changed_from_drop_down = true;

                  // for (i=0 ; i <this.state.selections.length-1; i++){
                  for (i = 1; i < this.state.selections.length; i++) {
                    if (this.state.selections[i] == true) {
                      data[i][column_indexes.category] = {
                        ...data[i][column_indexes.category],
                        a: e.target.value,
                        value: e.target.value,
                        id: ac_id,
                        className: "",
                      };
                      // this.setState({ grid: data, drop: 'yes' })
                      item_changed_from_drop_down = true;
                    }
                  }
                }}
              >
                <option key='a0' value='1e' data-row-index={i} data-acc-id='0'>
                  {/* Choose... */}
                  Search and Choose...
                </option>

                <option key='0' data-row-index={i} value='1e' data-acc-id='0'>
                  Create New{" "}
                </option>
                {this.state.default_category_list &&
                  this.state.default_category_list.map((item, l) => {
                    return (
                      <option
                        key={l + 1}
                        data-row-index={i}
                        value={item.name}
                        data-acc-id={item.id}
                        disabled={item.status == 2 ? true : false}
                        style={{ display: item.status == 2 ? "none" : "block" }}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            ),
          },
          { a: "", readOnly: true },
          {
            a: "",
            readOnly: true,
            component: (
              <select
                onChange={(e) => {
                  if (e.target.value == "1e") {
                    this.openTaxModal();
                  }
                  let data = this.state.grid;
                  // data[i][column_indexes.tax_rate] = { ...data[i][column_indexes.tax_rate], a: e.target.value }

                  let selectedIndex = e.target.options.selectedIndex;
                  let row_index =
                    e.target.options[selectedIndex].getAttribute(
                      "data-row-index"
                    );
                  data[row_index][column_indexes.tax_rate] = {
                    ...data[row_index][column_indexes.tax_rate],
                    a: e.target.value,
                    value: e.target.value,
                    className: "",
                  };
                  //  this.setState({ grid: data, drop: 'yes' })

                  //  if(this.state.selections[2] == true ){
                  // for (i=0 ; i <this.state.selections.length-1; i++){
                  for (i = 1; i < this.state.selections.length; i++) {
                    if (this.state.selections[i] == true) {
                      data[i][column_indexes.tax_rate] = {
                        ...data[i][column_indexes.tax_rate],
                        a: e.target.value,
                        value: e.target.value,
                        className: "",
                      };
                      // this.setState({ grid: data, drop: 'yes' })
                    }
                  }
                  // this.setState({ grid: data, drop: 'yes' },()=>{
                  //   this.calculateTaxForSelectedRows(true);
                  // });
                  item_changed_from_drop_down = true;
                  // this.calculateTaxForSelectedRows(true,data);
                }}
              >
                <option value='1e' data-row-index={i}>
                  {/* Choose... */}
                  Search and Choose...
                </option>
                <option value='1e' data-row-index={i}>
                  Create New{" "}
                </option>
                {this.state.gst_list &&
                  this.state.gst_list.map((item) => {
                    return (
                      <option
                        data-row-index={i}
                        value={item.sales_tax_name}
                        style={{
                          display: item.show_on_list == 2 ? "none" : "block",
                        }}
                      >
                        {item.sales_tax_name}
                      </option>
                    );
                  })}
              </select>
            ),
          },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
          { a: "", readOnly: true },
        ];
        if (
          this.state.number_of_columns_list &&
          this.state.number_of_columns_list.length
        ) {
          this.state.number_of_columns_list.forEach((cc, i) => {
            row.push({ a: "", key_name: cc.column_name });
          });
        }
        grid_data.push(row);
      }
    }

    if (grid_data && grid_data.length > 0) {
      if (grid_data[0][column_indexes.frgn_gross_amount]) {
        grid_data[0][column_indexes.frgn_gross_amount] = {
          a: `Gross amount(${this.state.currency})`,
          readOnly: true,
        };
      }
      if (grid_data[0][column_indexes.frgn_tax_amount]) {
        grid_data[0][column_indexes.frgn_tax_amount] = {
          a: `Sales tax amount(${this.state.currency})`,
          readOnly: true,
        };
      }
      if (grid_data[0][column_indexes.frgn_total_amount]) {
        grid_data[0][column_indexes.frgn_total_amount] = {
          a: `Total amount(${this.state.currency})`,
          readOnly: true,
        };
      }

      if (
        this.state.number_of_columns_list &&
        this.state.number_of_columns_list.length
      ) {
        this.state.number_of_columns_list.forEach((cc, i) => {
          grid_data[0].push({
            a: cc.name,
            key_name: cc.column_name,
            readOnly: true,
          });
        });
      }
    }

    this.setState({
      currency: "",
      transaction_type: "",
      transaction_type_label: "Accounts Receivable",
      isChecked: false,
      result_account: "",
      account_type: "",
      // selections:[false, false, false, false, false, false, false, false, false, false, false, false, false, false],
      selections: new Array(21).fill(false),
      grid: grid_data,
      set_as_read_only: true,
      grossamount_foreign: 0.0,
      salestaxamount_foreign: 0.0,
      totalamount_foreign: 0.0,
    });
  };

  addCustomColumnAndUpdateGrid(column_name) {
    var coulmnData = this.state.number_of_columns_list;
    let type = this.state.selectedColumnType
    var obJ = {
      column_name: column_name,
      type: type === 'dropDownField'? 2 : type === 'textField'? 1 : 1,
      options: [],
      is_visible: 1,
    };
    coulmnData.push(obJ);
    var coreData = {
      client_id: this.state.logged_client_id,
      columns: coulmnData,
      user_id: this.state.logged_user_id,
    };
    var key_name = column_name.replace(/ /g, "_");
    key_name = key_name.toLowerCase();

    // FetchAllApi.add_batch_enter_custom_column(coreData, (err, response) => {
    //   if (response.status === 1) {
    //     this.getColumns();
    //     let grid_data = this.state.grid;
    //     grid_data.forEach((gdata, i) => {
    //       if (i == 0) {
    //         grid_data[i].push({
    //           a: `${this.state.xyz}`,
    //           readOnly: true,
    //           key_name: key_name,
    //         });
    //       } else {
    //         grid_data[i].push({ a: "", key_name: key_name });
    //       }
    //     });

    //     this.setState({ grid: grid_data, xyz: "" }, () => {
    //       console.log("1907", this.state.grid);
    //     });
    //   } else {
    //     alert(response.message);
    //   }


      FetchAllApi.upDateCoulmns(coreData, (err, response) => {
        // console.log("new document", response.message);
        alert(response.message);
        if (response.status === 1) {
          this.getColumns();
          let grid_data = this.state.grid;
          grid_data.forEach((gdata, i) => {
            if (i == 0) {
              grid_data[i].push({
                a: `${this.state.xyz}`,
                readOnly: true,
                // key_name: key_name,
                key_name: `${this.state.xyz}`,
              });
            } else {
              // grid_data[i].push({ a: "", key_name: key_name });
                 grid_data[i].push({ a: "", key_name: `${this.state.xyz}` });
            }
          });
  
          this.setState({ grid: grid_data, xyz: "" }, () => {
            console.log("1907", this.state.grid);
          });
          //   this.setState({ items: response.list[0].columns })
        } else {
          alert(response.message);
        }
    });

    // this.setState({ugrid:
    //   [
    //     { readOnly: true, value: '' },
    //     { a: 'Invoice / Credit memo date', readOnly: true },
    //     { a: 'Invoice/ Credit memo  no', readOnly: true },
    //     { a: 'Name', readOnly: true },
    //     { a: `Type`, readOnly: true },
    //     { a: 'Description', readOnly: true },
    //     { a: 'Category', readOnly: true },
    //     { a: `Gross amount( ${this.state.currency} )`, readOnly: true },
    //     { a: 'Sales tax rate', readOnly: true },
    //     { a: `Sales tax amount (${this.state.currency} )`, readOnly: true },
    //     { a: `Total amount (${this.state.currency} )`, readOnly: true },
    //     { a: 'Exchange rate', readOnly: true },

    //     { a: `Gross amount( ${this.state.clientHomeCurrency} )`, readOnly: true },
    //     { a: `Sales tax amount (${this.state.clientHomeCurrency} )`, readOnly: true },
    //     { a: `Total amount (${this.state.clientHomeCurrency} )`, readOnly: true },
    //     { a: `NewColumn(${this.state.xyz})`, readOnly: false },

    //   ]

    // }

    //   )
  }

  addNewRowsAndUpdateGrid(number_of_new_rows_to_add) {
    var my_grid = [...this.state.grid];
    let pre_total_number_of_rows = this.state.total_number_of_rows;
    let total_number_of_rows =
      Number(this.state.total_number_of_rows) +
      Number(number_of_new_rows_to_add);
    let add_selection = new Array(Number(number_of_new_rows_to_add)).fill(
      false
    );
    let pre_selections = [...this.state.selections];
    pre_selections = pre_selections.concat(add_selection);

    let row_starts_from = pre_total_number_of_rows + 1;

    this.setState(
      {
        total_number_of_rows: total_number_of_rows,
        selections: pre_selections,
      },
      () => {
        console.log("this state selections........", this.state.selections);
        for (let i = row_starts_from; i <= total_number_of_rows; i++) {
          let row = [
            { readOnly: true, a: i },
            {
              a: `${this.state.client_date_format}`,
              readOnly: false,
            },
            { a: "", readOnly: false },
            { a: "", readOnly: false },
            {
              a: "",
              readOnly: false,
              component: (
                <select
                  data-live-search='true'
                  onChange={(e, props) => {
                    let selectedIndex = e.target.options.selectedIndex;
                    let row_index =
                      e.target.options[selectedIndex].getAttribute(
                        "data-row-index"
                      );
                    if (e.target.value == "") {
                    }
                    let data = this.state.grid;
                    data[row_index][column_indexes.user_type] = {
                      ...data[row_index][column_indexes.user_type],
                      a: e.target.value,
                      value: e.target.value,
                    };
                    for (i = 0; i < this.state.selections.length; i++) {
                      //  if(this.state.selections[i] == true || (i == row_index)){
                      if (this.state.selections[i] == true) {
                        data[i][column_indexes.user_type] = {
                          ...data[i][column_indexes.user_type],
                          a: e.target.value,
                          value: e.target.value,
                        };
                      }
                    }

                    is_user_type_modified = 1;
                    selected_user_type = e.target.value;
                  }}
                  onCommit={(vn, keydown) => {
                    console.log("gggg...652");
                  }}
                >
                  {/* <option data-row-index={i} key="0" value=""> Choose... </option> */}
                  <option data-row-index={i} key='0' value=''>
                    {" "}
                    Search and Choose...{" "}
                  </option>
                  <option data-row-index={i} key='1' value='Customer'>
                    {" "}
                    Customer
                  </option>
                  <option data-row-index={i} key='2' value='Vendor'>
                    {" "}
                    Vendor
                  </option>
                  <option data-row-index={i} key='3' value='Employee'>
                    {" "}
                    Employee
                  </option>
                  <option data-row-index={i} key='4' value='Others'>
                    {" "}
                    Others
                  </option>
                </select>
              ),
            },
            { a: "", readOnly: false },
            {
              a: "",
              readOnly: false,
              component: (
                <select
                  data-live-search='true'
                  onChange={(e, props) => {
                    let selectedIndex = e.target.options.selectedIndex;
                    let row_index =
                      e.target.options[selectedIndex].getAttribute(
                        "data-row-index"
                      );
                    let ac_id =
                      e.target.options[selectedIndex].getAttribute(
                        "data-acc-id"
                      );
                    if (e.target.value == "1e") {
                      this.addNewAcc();
                    }

                    // debugger
                    let data = this.state.grid;
                    // data[i][column_indexes.category] = { ...data[i][column_indexes.category], a: e.target.value }
                    //  if(this.state.selections[2] == true ){

                    // To change on clicked row
                    data[row_index][column_indexes.category] = {
                      ...data[row_index][column_indexes.category],
                      a: e.target.value,
                      value: e.target.value,
                      id: ac_id,
                    };
                    // this.setState({ grid: data, drop: 'yes' })
                    item_changed_from_drop_down = true;

                    for (i = 0; i < this.state.selections.length; i++) {
                      if (this.state.selections[i] == true) {
                        data[i][column_indexes.category] = {
                          ...data[i][column_indexes.category],
                          a: e.target.value,
                          value: e.target.value,
                          id: ac_id,
                        };
                        // this.setState({ grid: data, drop: 'yes' })
                        item_changed_from_drop_down = true;
                      }
                    }
                  }}
                >
                  <option
                    key='a0'
                    value='1e'
                    data-row-index={i}
                    data-acc-id='0'
                  >
                    {/* Choose... */}
                    Search and Choose...
                  </option>

                  <option key='0' data-row-index={i} value='1e' data-acc-id='0'>
                    Create New{" "}
                  </option>
                  {this.state.default_category_list &&
                    this.state.default_category_list.map((item, l) => {
                      return (
                        <option
                          key={l + 1}
                          data-row-index={i}
                          value={item.name}
                          data-acc-id={item.id}
                          disabled={item.status == 2 ? true : false}
                          style={{
                            display: item.status == 2 ? "none" : "block",
                          }}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              ),
            },
            { a: "", readOnly: false },
            {
              a: "",
              readOnly: false,
              component: (
                <select
                  onChange={(e) => {
                    if (e.target.value == "1e") {
                      this.openTaxModal();
                    }
                    let data = this.state.grid;
                    // data[i][column_indexes.tax_rate] = { ...data[i][column_indexes.tax_rate], a: e.target.value }

                    let selectedIndex = e.target.options.selectedIndex;
                    let row_index =
                      e.target.options[selectedIndex].getAttribute(
                        "data-row-index"
                      );
                    data[row_index][column_indexes.tax_rate] = {
                      ...data[row_index][column_indexes.tax_rate],
                      a: e.target.value,
                      value: e.target.value,
                    };
                    //  this.setState({ grid: data, drop: 'yes' })

                    //  if(this.state.selections[2] == true ){
                    for (i = 0; i < this.state.selections.length; i++) {
                      if (this.state.selections[i] == true) {
                        data[i][column_indexes.tax_rate] = {
                          ...data[i][column_indexes.tax_rate],
                          a: e.target.value,
                          value: e.target.value,
                        };
                        // this.setState({ grid: data, drop: 'yes' })
                      }
                    }
                    // this.setState({ grid: data, drop: 'yes' },()=>{
                    //   this.calculateTaxForSelectedRows(true);
                    // });
                    item_changed_from_drop_down = true;
                    // this.calculateTaxForSelectedRows(true,data);
                  }}
                >
                  <option value='1e' data-row-index={i}>
                    {/* Choose... */}
                    Search and Choose...
                  </option>
                  <option value='1e' data-row-index={i}>
                    Create New{" "}
                  </option>
                  {this.state.gst_list &&
                    this.state.gst_list.map((item) => {
                      return (
                        <option
                          data-row-index={i}
                          value={item.sales_tax_name}
                          style={{
                            display: item.show_on_list == 2 ? "none" : "block",
                          }}
                        >
                          {item.sales_tax_name}
                        </option>
                      );
                    })}
                </select>
              ),
            },
            { a: "", readOnly: true },
            { a: "", readOnly: true },
            { a: "", readOnly: false },
            { a: "", readOnly: true },
            { a: "", readOnly: true },
            { a: "", readOnly: true },
            // {a:''},
          ];
          if (
            this.state.number_of_columns_list &&
            this.state.number_of_columns_list.length
          ) {
            this.state.number_of_columns_list.forEach((cc, i) => {
              row.push({ a: "", key_name: cc.column_name });
            });
          }
          my_grid.push(row);
        }
        this.setState({ grid: my_grid });
      }
    );
  }
  typeOfColumnTobeModified = changeEvent => {
    this.setState({
      selectedColumnType: changeEvent.target.value
    })
  }
  add_coulmn = (colType) => {
    //debugger
    var user_id = parseFloat(this.state.logged_user_id);
    let type = this.state.selectedColumnType;
    type = type ? type : colType;
    if (type === "textField") {
      var type_ = 1;
    } else {
      var type_ = 2;
    }
    var myVal = type_;
    let coulmn_name = jQuery("#coulmn_name").val();

    var coulmnData = this.state.number_of_columns_list;
    var obJ = {
      column_name: coulmn_name,
      type: myVal,
      options: [],
      is_visible: 1,
    };
    // alert(coulmnData)
    // Binding this keyword

    var coulmnData = [this.state.number_of_columns_list].push(obJ);
    // alert(coulmnData)
    var coreData = {
      name: coulmn_name,
      type: myVal,
      client_id: this.state.logged_client_id,
      options: "",
    };

    FetchAllApi.add_batch_enter_custom_column(coreData, (err, response) => {
      console.log("new document", response.message);
      alert(response.message);
      if (response.status === 1) {
        this.getColumns();

        //   this.setState({items: response.list[0].columns })
      } else {
      }
    });

    window.jQuery("#pop-modal-2").modal("hide");
  };

  get_currencies = (client_home_currency) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/${client_home_currency}`)
      .then((response) => response.json())
      .then((data) => {
        const currencyAr = [];
        let first = data.rates;
        for (const key in first) {
          currencyAr.push(key);
        }
        //  this.setState({ currencies: currencyAr, currency_clone: currencyAr, currency_list: first });
      });
  };

  componentDidUpdate() {
    window.jQuery(".selectpicker").selectpicker("refresh");
    // jQuery("#currency_selected").val(4);

    // console.log(this.state.currency,this.state.grid, 'rest')
  }

  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass("minimize_leftbar");
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery("title").html("Customer | GBSC");

    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === "null" ||
      this.state.logged_user_id === "undefined"
    ) {
      this.props.history.push("/");
    }
  }

  routedChange(parameter) {
    this.props.history.push("/" + parameter);
    window.scrollTo(0, 0);
  }

  getColumns = () => {
    // this.setState({ number_of_columns_list: [] });
    // FetchAllApi.get_batch_enter_custom_column(
    //   this.state.logged_client_id,
    //   (err, response) => {
    //     console.log(response, 'res')
    //     if (response.status === 1) {
    //       // this.setState({ number_of_columns_list: response.list[0].columns },);
    //       this.setState({ number_of_columns_list: response.list }, () => {
    //         console.log(
    //           "this.state.number_of_columns_list....2034",
    //           this.state.number_of_columns_list
    //         );
    //       });
    //     } else {
    //     }
    //   }
    // );

    const data = {
      userid: 898,
      id: 'user',
      role: 'admin',
      columns: [ 
        {
          column_name: 'manager',
          clientid: '123'
        },
        {
          column_name: 'city',
          clientid: '123'
        },
        {
          column_name: 'new',
          clientid: '123'
        },
      ]
    }
    
    var coreData = {
      user_id: this.state.logged_user_id,
      client_id: this.state.logged_client_id
    };

   FetchAllApi.getAllColumns(coreData, (err, response) => {
    // const updatedColumns = response.list[0].columns.map(item => {
    //   const { column_name, ...rest } = item;
    //   return { row_name: column_name, ...rest };
    // });
    // console.log(updatedColumns, 'res1')

      if (response.status === 1) {
        if(response.list && response.list.length > 0 && response.list[0].columns) {
        var visibleList = response.list[0].columns.filter(el=>el.is_visible===1)
        
          this.setState({ number_of_columns_list: visibleList }, () => {
          });
        }
      } else {
      }
  });
  };
  componentDidMount() {
    this.get_client_home_currency();
    this.getSubAccountList(2);
    this.getColumns();
    this.get_country_id();
    this.get_gst_list(0.0);
    this.getAllbanks();
    //tr:nth-child(${2}) td:nth-child(${3})
    // this.defaultcategorylist_onchange()
    document
      .getElementById("sticky-tb-hdr")
      .addEventListener("scroll", function () {
        var translate = "translate(0," + this.scrollTop + "px)";
        if (
          this.querySelector("tbody tr:first-child") != null &&
          this.querySelector("tbody tr:first-child") != undefined &&
          this.querySelector("tbody tr:first-child").style != null
        ) {
          this.querySelector("tbody tr:first-child").style.transform =
            translate;
        }
      });

    jQuery(window).on("load", function () {
      window.jQuery(".mscroll-y").mCustomScrollbar({
        axis: "y",
        scrollEasing: "linear",
        scrollInertia: 600,
        autoHideScrollbar: "true",
        autoExpandScrollbar: "true",
      });

      window.jQuery(".ib-scroll").mCustomScrollbar({
        scrollEasing: "linear",
        scrollInertia: 600,
        scrollbarPosition: "outside",
      });
    });

    jQuery(document).ready(function () {
      jQuery(".left-navmenu .has-sub").click(function () {
        jQuery(".left-navmenu li a").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(".left-navmenu li a:not(.active)")
          .siblings(".sub-menu")
          .slideUp();
        jQuery(this).siblings(".sub-menu").slideToggle();
      });
      jQuery(".left-navmenu .sub-menu li a").click(function () {
        jQuery(".left-navmenu .sub-menu li a").removeClass("active");
        jQuery(this).addClass("active");
      });
      jQuery(".search-btn").click(function () {
        jQuery(".hdr-search").addClass("active");
      });
      jQuery(".hdr-search .close-icon").click(function () {
        jQuery(".hdr-search").removeClass("active");
      });
      window.jQuery(".select-picker").selectpicker();
      jQuery(".label-enclose .label").click(function () {
        jQuery(this).toggleClass("active");
      });
      jQuery(".nav-brand-res").click(function () {
        jQuery(".left-navbar").addClass("active");
      });
      jQuery(".menu-close").click(function () {
        jQuery(".left-navbar").removeClass("active");
      });
    });
    if (this.state.batchid != 0 && this.state.batchid != "") {
      //added to fix custom column data not show due to response delay
     // this.getColumns();

      // Newly added on 03-10-2022 to avoid empty category list on edit batch entries
      let keyy = "";
      let from_create_invoice = 0;
      var client_id = this.state.logged_client_id;
      let filter_id = 2;
      FetchAllApi.defaultcategorylist_onchange2(
        keyy,
        from_create_invoice,
        client_id,
        filter_id,
        (err, category_response) => {
          let category_list =
            category_response.status == 1 &&
            category_response.list &&
            category_response.list.length > 0
              ? category_response.list
              : [];
          if (category_list.length > 0) {
            this.setState({ default_category_list: category_list });
          }
          //end here

          //02-12-2023
          FetchAllApi.getAllColumns({
            user_id: this.state.logged_user_id,
            client_id: this.state.logged_client_id
          }, (err, response) => {
              if (response.status === 1) {
                if(response.list && response.list.length > 0 && response.list[0].columns) {
                var visibleList = response.list[0].columns.filter(el=>el.is_visible===1)
                
                  this.setState({ number_of_columns_list: visibleList }, () => {
                  });
                }
              } else {
              }
              //end- 02-12-2023

          FetchAllApi.batch_transaction_details_to_view(
            this.state.logged_client_id,
            this.state.batchid,
            (err, response) => {
              if (response.status === 1) {
                localStorage.setItem("batchid", 0);
                localStorage.setItem("batch_transaction_row_index", null);
                var result = response.details;
                var my_grid = [this.state.ugrid];
                let {
                  grossamount,
                  salestaxamount,
                  totalamount,
                  grossamount_foreign,
                  salestaxamount_foreign,
                  totalamount_foreign,
                } = this.state;
                this.setState({is_voided: response.is_voided})
                if (result.rows) {
                  let select_array = new Array(result.rows.length).fill(false);
                  this.setState(
                    {
                      total_number_of_rows: result.rows.length,
                      selections: select_array,
                    },
                    () => {
                      console.log("2785....", this.state.selections);
                      console.log("2786....", this.state.total_number_of_rows);
                    }
                  );

                  // added to get old transaction dates 
                  let old_rows = result.rows?result.rows:[];
                  let old_transaction_date_array = _.map(old_rows,'invoice_date');
                  this.setState({ old_transaction_date_array: old_transaction_date_array});
                }

                let class_name_to_highlight = "";
                let index_to_hight_light = -1;
                if (this.state.batch_transaction_row_index >= 0) {
                  index_to_hight_light = Number(
                    this.state.batch_transaction_row_index
                  );
                }
                //  result.rows.map((item,index) => {
                result.rows.map((item, ind) => {
                  let i = ind + 1;

                  if (i == index_to_hight_light) {
                    class_name_to_highlight = "batch-highlight-row";
                  } else {
                    class_name_to_highlight = "";
                  }

                  grossamount = !isNaN(
                    parseFloat(grossamount + item.gross_amount)
                  )
                    ? parseFloat(grossamount + item.gross_amount)
                    : grossamount;
                  salestaxamount = !isNaN(
                    parseFloat(salestaxamount + item.sales_tax_amount)
                  )
                    ? parseFloat(salestaxamount + item.sales_tax_amount)
                    : salestaxamount;
                  totalamount = !isNaN(
                    parseFloat(totalamount + item.total_amount)
                  )
                    ? parseFloat(totalamount + item.total_amount)
                    : totalamount;

                  grossamount_foreign = !isNaN(
                    parseFloat(grossamount_foreign + item.gross_amount_foreign)
                  )
                    ? parseFloat(
                        grossamount_foreign + item.gross_amount_foreign
                      )
                    : grossamount_foreign;
                  salestaxamount_foreign = !isNaN(
                    parseFloat(
                      salestaxamount_foreign + item.sales_tax_amount_foreign
                    )
                  )
                    ? parseFloat(
                        salestaxamount_foreign + item.sales_tax_amount_foreign
                      )
                    : salestaxamount_foreign;
                  totalamount_foreign = !isNaN(
                    parseFloat(totalamount_foreign + item.total_amount_foreign)
                  )
                    ? parseFloat(
                        totalamount_foreign + item.total_amount_foreign
                      )
                    : totalamount_foreign;

                  console.log(
                    "item.gross_amount_foreign........3008",
                    item.gross_amount_foreign
                  );
                  console.log(
                    "this.getCurrencyFormatedString(item.gross_amount_foreign)........3009",
                    this.getCurrencyFormatedString(item.gross_amount_foreign)
                  );

                  //removed - readOnly: true, from all entry
                  //  let row=[
                  //     { readOnly: true, value: index+1 },
                  //     { a: '',value:item.invoice_date },
                  //     { a: '',value:item.invoice_no },
                  //     {a: '' ,value:item.customer_name},
                  //     {a: ``,value:item.type },
                  //     { a: '',value:item.description },
                  //     {a: '',value:item.account },
                  //     {a: ``,value:item.gross_amount_foreign },
                  //     {a: '',value:item.sales_tax_rate },
                  //     {a: ` `, readOnly: true,value:item.sales_tax_amount_foreign },
                  //     {a: ``, readOnly: true,value:item.total_amount_foreign },
                  //     {a: '',value:item.exchange_rate },
                  //     { a: `` ,value:item.gross_amount},
                  //     { a: ``,value:item.sales_tax_amount },
                  //     { a: ``,value:item.total_amount }
                  //   ]

                  let row = [
                    {
                      readOnly: true,
                      a: i,
                      entry_id: item.invoice_id,
                      entry_type: item.entry_type,
                    },
                    {
                      // a: item.invoice_date,className:class_name_to_highlight
                      a: moment(item.invoice_date).format(
                        this.state.client_date_format
                      ),
                      className: class_name_to_highlight,
                    },
                    { a: item.invoice_no, className: class_name_to_highlight },
                    {
                      a: item.customer_name,
                      className: class_name_to_highlight,
                    },
                    {
                      a: item.type,
                      component: (
                        <select
                          data-live-search='true'
                          onChange={(e, props) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            let row_index =
                              e.target.options[selectedIndex].getAttribute(
                                "data-row-index"
                              );
                            if (e.target.value == "") {
                            }
                            let data = this.state.grid;

                            // To change in clicked row
                            data[row_index][column_indexes.user_type] = {
                              ...data[row_index][column_indexes.user_type],
                              a: e.target.value,
                              value: e.target.value,
                            };
                            for (i = 0; i < this.state.selections.length; i++) {
                              // if(this.state.selections[i] == true || (i == row_index)){
                              if (this.state.selections[i] == true) {
                                data[i][column_indexes.user_type] = {
                                  ...data[i][column_indexes.user_type],
                                  a: e.target.value,
                                  value: e.target.value,
                                };
                                //  this.setState({ grid: data, drop: 'yes' });
                              }
                            }

                            is_user_type_modified = 1;
                            selected_user_type = e.target.value;
                          }}
                        >
                          {/* <option data-row-index={i} key="0" value=""> Choose... </option> */}
                          <option data-row-index={i} key='0' value=''>
                            {" "}
                            Search and Choose...{" "}
                          </option>
                          <option data-row-index={i} key='1' value='Customer'>
                            {" "}
                            Customer
                          </option>
                          <option data-row-index={i} key='2' value='Vendor'>
                            {" "}
                            Vendor
                          </option>
                          <option data-row-index={i} key='3' value='Employee'>
                            {" "}
                            Employee
                          </option>
                          <option data-row-index={i} key='4' value='Others'>
                            {" "}
                            Others
                          </option>
                        </select>
                      ),
                      className: class_name_to_highlight,
                    },
                    { a: item.description, className: class_name_to_highlight },
                    {
                      a: item.account,
                      id: item.account_id,
                      component: (
                        <select
                          data-live-search='true'
                          onChange={(e, props) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            let row_index =
                              e.target.options[selectedIndex].getAttribute(
                                "data-row-index"
                              );
                            let ac_id =
                              e.target.options[selectedIndex].getAttribute(
                                "data-acc-id"
                              );
                            if (e.target.value == "1e") {
                              this.addNewAcc();
                            }

                            // debugger
                            let data = this.state.grid;
                            // data[i][column_indexes.category] = { ...data[i][column_indexes.category], a: e.target.value }
                            //  if(this.state.selections[2] == true ){

                            // To change on clicked row
                            data[row_index][column_indexes.category] = {
                              ...data[row_index][column_indexes.category],
                              a: e.target.value,
                              id: ac_id,
                              value: e.target.value,
                              className: "",
                            };
                            // this.setState({ grid: data, drop: 'yes' })
                            item_changed_from_drop_down = true;

                            for (i = 0; i < this.state.selections.length; i++) {
                              if (this.state.selections[i] == true) {
                                data[i][column_indexes.category] = {
                                  ...data[i][column_indexes.category],
                                  a: e.target.value,
                                  id: ac_id,
                                  value: e.target.value,
                                  className: "",
                                };
                                // this.setState({ grid: data, drop: 'yes' })
                                item_changed_from_drop_down = true;
                              }
                            }
                          }}
                        >
                          <option
                            key='a0'
                            value='1e'
                            data-row-index={i}
                            data-acc-id='0'
                          >
                            {/* Choose... */}
                            Search and Choose...
                          </option>

                          <option
                            key='0'
                            data-row-index={i}
                            value='1e'
                            data-acc-id='0'
                          >
                            Create New{" "}
                          </option>
                          {this.state.default_category_list &&
                            this.state.default_category_list.map((item, l) => {
                              return (
                                <option
                                  key={l + 1}
                                  data-row-index={i}
                                  value={item.name}
                                  data-acc-id={item.id}
                                  disabled={item.status == 2 ? true : false}
                                  style={{
                                    display:
                                      item.status == 2 ? "none" : "block",
                                  }}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      ),
                      className: class_name_to_highlight,
                    },
                    // { a:this.getCurrencyFormatedString(item.gross_amount_foreign),className:class_name_to_highlight,value:this.getCurrencyFormatedString(item.gross_amount_foreign)},
                    {
                      a: item.gross_amount_foreign,
                      className: class_name_to_highlight,
                      value: this.getCurrencyFormatedString(
                        item.gross_amount_foreign
                      ),
                    },
                    {
                      a: item.sales_tax_rate,
                      component: (
                        <select
                          onChange={(e) => {
                            if (e.target.value == "1e") {
                              this.openTaxModal();
                            }
                            let data = this.state.grid;
                            // data[i][column_indexes.tax_rate] = { ...data[i][column_indexes.tax_rate], a: e.target.value }

                            let selectedIndex = e.target.options.selectedIndex;
                            let row_index =
                              e.target.options[selectedIndex].getAttribute(
                                "data-row-index"
                              );
                            data[row_index][column_indexes.tax_rate] = {
                              ...data[row_index][column_indexes.tax_rate],
                              a: e.target.value,
                              value: e.target.value,
                              className: "",
                            };
                            //  this.setState({ grid: data, drop: 'yes' })

                            //  if(this.state.selections[2] == true ){
                            for (i = 0; i < this.state.selections.length; i++) {
                              if (this.state.selections[i] == true) {
                                data[i][column_indexes.tax_rate] = {
                                  ...data[i][column_indexes.tax_rate],
                                  a: e.target.value,
                                  value: e.target.value,
                                  className: "",
                                };
                                // this.setState({ grid: data, drop: 'yes' })
                              }
                            }
                            // this.setState({ grid: data, drop: 'yes' },()=>{
                            //   this.calculateTaxForSelectedRows(true);
                            // });
                            item_changed_from_drop_down = true;
                            // this.calculateTaxForSelectedRows(true,data);
                          }}
                        >
                          <option value='1e' data-row-index={i}>
                            {/* Choose... */}
                            Search and Choose...
                          </option>
                          <option value='1e' data-row-index={i}>
                            Create New{" "}
                          </option>
                          {this.state.gst_list &&
                            this.state.gst_list.map((item) => {
                              return (
                                <option
                                  data-row-index={i}
                                  value={item.sales_tax_name}
                                  style={{
                                    display:
                                      item.show_on_list == 2 ? "none" : "block",
                                  }}
                                >
                                  {item.sales_tax_name}
                                </option>
                              );
                            })}
                        </select>
                      ),
                      className: class_name_to_highlight,
                    },
                    {
                      a: this.getCurrencyFormatedString(
                        item.sales_tax_amount_foreign
                      ),
                      readOnly: true,
                      className: class_name_to_highlight,
                    },
                    {
                      a: this.getCurrencyFormatedString(
                        item.total_amount_foreign
                      ),
                      readOnly: true,
                      className: class_name_to_highlight,
                    },
                    {
                      a: item.exchange_rate,
                      className: class_name_to_highlight,
                    },
                    {
                      a: this.getCurrencyFormatedString(item.gross_amount),
                      readOnly: true,
                      className: class_name_to_highlight,
                    },
                    {
                      a: this.getCurrencyFormatedString(item.sales_tax_amount),
                      readOnly: true,
                      className: class_name_to_highlight,
                    },
                    {
                      a: this.getCurrencyFormatedString(item.total_amount),
                      readOnly: true,
                      className: class_name_to_highlight,
                    },
                  ];

                  if (item.custom_columns) {
                    let index_start_for_batch_custom_columns = 15;
                    if (
                      this.state.number_of_columns_list &&
                      this.state.number_of_columns_list.length > 0
                    ) {
                      this.state.number_of_columns_list.forEach((cc, l) => {
                        if (
                          my_grid[0] 
                          &&
                          my_grid[0][index_start_for_batch_custom_columns] &&
                          my_grid[0][index_start_for_batch_custom_columns][
                            "key_name"
                          ]
                        ) {
                          let actual_key_name = (my_grid[0][index_start_for_batch_custom_columns][
                            "key_name"]).replace(/ /g, "_"); 
                            actual_key_name = actual_key_name.toLowerCase();
                            if (
                            // item.custom_columns[
                            //   my_grid[0][index_start_for_batch_custom_columns][
                            //     "key_name"
                            //   ]
                            // ]
                            item.custom_columns[actual_key_name]
                          ) {
                            row.push({
                              // a: item.custom_columns[
                              //   my_grid[0][
                              //     index_start_for_batch_custom_columns
                              //   ]["key_name"]
                              // ],
                              a: item.custom_columns[actual_key_name],
                              key_name:
                                my_grid[0][
                                  index_start_for_batch_custom_columns
                                ]["key_name"],
                            });
                          } else {
                            row.push({
                              a: "",
                              key_name:
                                my_grid[0][
                                  index_start_for_batch_custom_columns
                                ]["key_name"],
                            });
                          }
                        }
                        index_start_for_batch_custom_columns =
                          index_start_for_batch_custom_columns + 1;
                      });
                    }
                  }

                  my_grid.push(row);
                });
                let type;
                let transaction_type_label = "Accounts Receivable";
                if (result.transaction_type == 1) {
                  type = `Accounts Receivable-${result.currency}`;
                } else if (result.transaction_type == 2) {
                  type = `Accounts Payable-${result.currency}`;
                  transaction_type_label = "Accounts Payable";
                } else if (result.transaction_type == 4) {
                  transaction_type_label = "Bank Account";
                } else if (result.transaction_type == 3) {
                  transaction_type_label = "Bank Account";
                } else {
                  type = "";
                }
                this.setState(
                  {
                    editrows: result.rows,
                    grid: my_grid,
                    transaction_type: result.transaction_type,
                    currency: result.currency,
                    account_type: type,
                    buttondisable: false,
                    grossamount,
                    salestaxamount,
                    totalamount,
                    grossamount_foreign,
                    salestaxamount_foreign,
                    totalamount_foreign,
                    result_account: result.account,
                    isChecked: result.including_tax,
                    transaction_type_label: transaction_type_label,
                  },
                  () => {
                    this.updateCurrencyOnTableHeading();
                    if (this.state.isChecked == true) {
                      let grossamount_foreign = parseFloat(
                        (totalamount_foreign - salestaxamount_foreign).toFixed(
                          2
                        )
                      );
                      this.setState({
                        grossamount_foreign: grossamount_foreign,
                      });
                    }
                  }
                );
              } else {
                this.setState({ buttondisable: false });
              }
            }
          );

          //02-12-2023
        });
        // end -02-12-2023

        }
      );
      // commented to fix custom column data not show due to response delay
      // this.getColumns();
    } else {
      // set default currency
      // var defaultCurrency = localStorage.getItem("default_currency");
      // this.setState({ is_currency_selected: true }, () => {
      //   this.enableGridToEdit();
      // });
      // let type;
      // if (
      //   this.state.transaction_type == 1 ||
      //   this.state.transaction_type == 4
      // ) {
      //   type = `Accounts Receivable-${defaultCurrency}`;
      // } else if (
      //   this.state.transaction_type == 2 ||
      //   this.state.transaction_type == 3
      // ) {
      //   type = `Accounts Payable-${defaultCurrency}`;
      // } else {
      //   type = "";
      // }
      // this.setState(
      //   {
      //     currency: defaultCurrency,
      //     account_type: type,
      //   },
      //   this.selected_account(defaultCurrency, this.state.transaction_type)
      // );
    }
  }

  get_country_id = () => {
    let client_Id = this.state.logged_client_id;
    FetchAllApi.get_country_id(client_Id, (err, response) => {
      if (response.status === 1) {
        this.setState({
          country_code: response.country_id,
        });
      }
    });
  };

  enableGridToEdit = () => {
    if (
      this.state.is_transaction_type_selected &&
      this.state.is_currency_selected &&
      this.state.set_as_read_only == true
    ) {
      let grid = this.state.grid;
      for (let row = 1; row <= this.state.total_number_of_rows; row++) {
        for (let column = 1; column <= 11; column++) {
          if (grid[row] && grid[row][column]) {
            if (
              column != column_indexes["frgn_tax_amount"] &&
              column != column_indexes["frgn_total_amount"]
            ) {
              grid[row][column] = { ...grid[row][column], readOnly: false };
            }
          }
        }
      }

      this.setState({ set_as_read_only: false, grid: grid });
    }
  };

  logoutLink() {
    localStorage.clear();
    this.props.history.push("/");
  }

  pageLink(page_slug) {
    this.props.history.push("/" + page_slug);
  }

  render() {
    return (
      <div>
        {this.state.is_voided && (
            <div
              style={{
                position: 'fixed',
                width: ' 100%',
                height: '100%',
                top: '0',
                left: ' 0',
                right: '0',
                bottom: ' 0',
                backgroundColor: 'rgba(0,0,0,0.2)',
                zIndex: '99988',
                cursor: 'pointer',
                display: 'block',
              }}
            >
              <h1 className='watermark'>Voided</h1>
            </div>
          )}
        {/* Main Wrapper Starts here */}
        <div className='container-fluid'>
          <div className='row'>
            {/* left-navbar Starts here */}
            <LeftSidebar
              history={this.props.history}
              pageSubmit={(e) => this.pageLink(e)}
            />
            {/* left-navbar Ends here */}
            {/* MainContent Wrapper Starts here */}
            <div className='main-wrap col-md-12 col-xs-12 pad-r-no'>
              {/* Top bar Starts here */}
              <div className='top-bar col-md-12 col-xs-12 pad-r-no' style={{ zIndex: '99999'}}>
                <form className='hdr-search'>
                  <input
                    type='text'
                    className='form-control'
                    name='search'
                    placeholder='Search...'
                  />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href='javascript:;' className='close-icon'>
                    <img src='images/close-icon-red.svg' alt='Close' />
                  </a>
                </form>
                <div className='nav-brand-res visible-xs'>
                  <img
                    className='img-responsive'
                    src='images/logo-icon.png'
                    alt='LogoIcon'
                  />
                </div>
                <a href='javascript:;' className='back hidden-xs'>
                  <img
                    src='images/back-arrow-blue.svg'
                    onClick={() => this.props.history.goBack()}
                  />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className='list-unstyled breadcrumb page-title hidden-xs'>
                  <li>
                    <a href='javascript:;'>Batch Posting</a>
                  </li>
                  <li>Batch Enter Transaction</li>
                </ul>
                <Topbar
                  history={this.props.history}
                  logoutSubmit={(e) => this.logoutLink()}
                />
              </div>
              {/* <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <button className="btn btn-blue sidebar-toggle">
                  <img className="img-responsive" src="images/genie-icon.png" alt="LogoIcon" />
                  <span />
                  <span />
                  <span />
                </button>
                <form className="hdr-search">
                  <input type="text" className="form-control" name="search" placeholder="Search..." />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
              {/*  <a href="javascript:;" className="close-icon"><img src="images/close-icon-red.svg" alt="Close" /></a>
                </form>
                <div className="nav-brand-res visible-xs"><img className="img-responsive" src="images/logo-icon.png" alt="LogoIcon" /></div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="images/back-arrow-blue.svg" />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
              {/* <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li><a href="javascript:;">Accountant</a></li>
                  <li>Batch Enter Transaction</li>
                </ul>
                <div className="pull-right">
                  <div className="search-wrap">
                    <a className="search-btn" href="javascript:;">
                      <img className="search" src="images/search-icon.svg" alt="Search" />
                    </a>
                  </div>
                  <div className="notify-wrap">
                    <a href="javascript:;"><img src="images/notification-icon.svg" alt="Notification" /></a>
                  </div>
                  <div className="profile-wrap dropdown">
                    <a href="javascript:;" className="avatar dropdown-toggle" data-toggle="dropdown">
                      <span className="avatar-img"><img className="img-responsive" src="images/user-img-1.png" alt="Harvey Dean" /></span>
                      <span className="hidden-xs">Harvey Dean</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="javascript:;"><img src="images/edit-icon.svg" alt="icon" />Edit Profile</a>
                      </li>
                      <li><a href="javascript:;"><img src="images/settings-icon.svg" alt="icon" />Settings</a></li>
                      <li><a href="javascript:;"><img src="images/turn-off-icon.svg" alt="icon" />Logout</a></li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* Top bar Ends here */}
              <div className='col-md-12 col-xs-12 mar-top visible-xs'>
                <a href='javascript:;' className='back'>
                  <img src='images/back-arrow-blue.svg' />
                </a>
                <span className='page-title'>Accountant</span>
              </div>
              {/* content-top Starts here */}
              <div className='content-top col-md-12 col-xs-12 bg-trans' style={{position: 'relative'}}>
                <h4>Batch Enter Transaction</h4>
                <form className='custom-form newForm row'>
                  <div className='form-group col-md-3 col-sm-4'>
                    <label>Transaction Type</label>
                    <select
                      className='selectpicker form-control add-new'
                      data-live-search='true'
                      title='Choose...'
                      disabled={this.state.buttondisable}
                      value={this.state.transaction_type}
                      onChange={(e) => {
                        this.setState(
                          { is_transaction_type_selected: true },
                          () => {
                            this.enableGridToEdit();
                          }
                        );
                        let type;
                        let transaction_type_label = "Accounts Receivable";
                        if (this.state.currency !== "") {
                          if (e.target.value == 1) {
                            type = `Accounts Receivable-${this.state.currency}`;
                            transaction_type_label = "Accounts Receivable";
                          } else if (e.target.value == 4) {
                            type = `Bank`;
                            transaction_type_label = "Bank Account";
                          } else if (e.target.value == 2) {
                            type = `Accounts Payable-${this.state.currency}`;
                            transaction_type_label = "Accounts Payable";
                          } else if (e.target.value == 3) {
                            type = `Bank`;
                            transaction_type_label = "Bank Account";
                          } else {
                            type = "";
                          }
                        } else {
                          if (e.target.value == 1) {
                            transaction_type_label = "Accounts Receivable";
                          } else if (e.target.value == 4) {
                            transaction_type_label = "Bank Account";
                          } else if (e.target.value == 2) {
                            transaction_type_label = "Accounts Payable";
                          } else if (e.target.value == 3) {
                            transaction_type_label = "Bank Account";
                          }
                        }
                        this.setState(
                          {
                            transaction_type: e.target.value,
                            account_type: type,
                            transaction_type_label: transaction_type_label,
                          },
                          () => {
                            this.selected_account(
                              this.state.currency,
                              this.state.transaction_type
                            );
                          }
                        );
                      }}
                    >
                      <option value=''>Choose...</option>
                      <option value='1'>
                        Sales Invoices &amp; Credit Memo
                      </option>
                      <option value='2'>Bills &amp; Bill Credits</option>
                      <option value='3'>Cheque</option>
                      <option value='4'>Deposit</option>
                    </select>
                  </div>
                  <div className='form-group col-md-3 col-sm-4'>
                    <label>Currency</label>
                    <select
                      className='selectpicker form-control add-new'
                      data-live-search='true'
                      title='Choose...'
                      disabled={this.state.buttondisable}
                      value={this.state.currency}
                      onChange={(e) => {
                        this.setState({ is_currency_selected: true }, () => {
                          this.enableGridToEdit();
                        });
                        let type;
                        if (
                          this.state.transaction_type == 1 ||
                          this.state.transaction_type == 4
                        ) {
                          type = `Accounts Receivable-${e.target.value}`;
                        } else if (
                          this.state.transaction_type == 2 ||
                          this.state.transaction_type == 3
                        ) {
                          type = `Accounts Payable-${e.target.value}`;
                        } else {
                          type = "";
                        }

                        this.setState(
                          {
                            currency: e.target.value,
                            account_type: type,
                          },()=>{

                            this.selected_account(
                              this.state.currency,
                              this.state.transaction_type
                            )
                            this.updateCurrencyOnTableHeading()
                          }
                        );
                      }}
                    >
                      <option value=''>Choose...</option>
                      {this.state.currencies.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="form-group col-md-3 col-sm-4 no-edit"> */}
                  <div className='form-group col-md-3 col-sm-4'>
                    <label>{this.state.transaction_type_label}</label>
                    {this.state.transaction_type == "" ||
                    this.state.transaction_type == 1 ||
                    this.state.transaction_type == 2 ? (
                      // <input type="text" className="form-control no-edit" defaultValue={this.state.account_type} name />:
                      // <input
                      //   type='text'
                      //   className='form-control no-edit'
                      //   value={this.state.account_type}
                      //   name
                      // />

                      <div>
                      <select
                        className="selectpicker form-control "
                        id="account_id"
                        data-live-search="true"
                        title={`Choose`}
                        data-width="100%"
                        value={this.state.result_account}
                        onChange={(e) => { 
                          let value = e.target.value;
                          this.setState({
                            result_account: value
                          });
                        }}
                      >
                        { this.state.SubAccountList != undefined &&
                          this.state.SubAccountList.map((item, index) => {
                            if(((this.state.transaction_type == 1 ?(item.account_type_id == 2):item.account_type_id == 5) && (item.currency != '' && item.currency == this.state.currency)) && (item.name != 'Accounts Payable' && item.name != 'Accounts Payable-' && item.name != 'Accounts Receivable' && item.name != 'Accounts Receivable-')) {
                            return (
                              <option value={item.id}>{item.name}</option>
                            );
                          } else {
                            return '';
                          }
                          })}
                      </select>
                    </div>
                      
                    ) : (
                      <div>
                        <select
                          className='selectpicker form-control add-new'
                          // id="bank_account"
                          data-live-search='true'
                          title='Choose...'
                          value={this.state.result_account}
                          id='balanceSheetCategeory'
                          onChange={(e) => {
                            if (e.target.value == "1e") {
                              // jQuery("#balanceSheetCategeory option")
                              //   .prop("selected", false)
                              //   .trigger("change");
                              // jQuery("#balanceSheetCategeory").val("");
                              // jQuery("#pop-modal").modal("show");
                              this.setState({ is_add_from_bank_account: true });
                              this.addNewAcc();
                            } else {
                              this.setState({ result_account: e.target.value });
                              // this.selected_account(this.state.currency,this.state.transaction_type)
                            }
                          }}
                        >
                          <option value=''>choose...</option>
                          <option value='1e'> Add New </option>

                          {this.state.banks.length > 0 &&
                            this.state.banks.map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  disabled={item.status == 2 ? true : false}
                                  style={{
                                    display:
                                      item.status == 2 ? "none" : "block",
                                  }}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                </div>
                <div className='float-right ' style={{display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 'auto', width: 'max-content' }}>
                    {/* <a
                                title="Add new column"
                                href="javascript:;"
                                className="add-col"
                                data-toggle="modal"
                                data-target="#pop-modal-2"
                              >  </a> */}
                    {/* <br /> */}
                    <button
                      type='button'
                      className='btn btn-blue'
                      data-toggle='modal'
                      data-target='#pop-modal-2'
                      disabled={this.state.buttondisable}
                    >
                      Add Column
                    </button>
                    <a
                      title="Edit columns"
                      href="javascript:;"
                      // data-toggle='modal'
                      // data-target='#editCol'
                      onClick={() => {
                          var win = window.open(
                              "/coulmn-rearrange",
                              "_blank"
                          );
                          win.focus();
                          // this.setState({
                          //   isEditCol: !this.state.isEditCol
                          // })
                      }}
                      // className="add-col"
                  >
                      <img
                          className="img-responsive"
                          src="images/pen-blue.svg"
                          alt="icon"
                      />
                  </a>
                                {/* <span>{"  "}</span> */}
                                {/* <Pdf targetRef={ref} filename="code-example.pdf">
                    {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                  </Pdf> */}
                  </div>
                  
                </form>
              </div>

              {/* <div className="th-action-inv">
                <a
                  title="Add new column"
                  href="javascript:;"
                  className="add-col"
                  data-toggle="modal"
                  data-target="#pop-modal-2"
                >
                  <img
                    className="img-responsive"
                    src="../images/plus-icon.svg"
                    alt="icon"
                  />
                </a>
                <a
                  title="Edit columns"
                  href="javascript:;"
                  // data-toggle='modal'
                  // data-target='#editCol'
                  onClick={() => {
                    var win = window.open(
                      "/batch_column_rearrange",
                      "_blank"
                    );
                    win.focus();
                    // this.setState({
                    //   isEditCol: !this.state.isEditCol
                    // })
                  }}
                  className="add-col edit-col"
                >
                  <img
                    className="img-responsive"
                    src="../images/pen-blue.svg"
                    alt="icon"
                  />
                </a>
              </div> */}

              <div className='main-content col-md-12 col-xs-12 pad-t-no'>
                <div className='content-sec col-md-12 col-xs-12 pad-no mar-t-no'>
                  <div className='report-table reconcile-table col-md-12 col-xs-12 mar-t-no mar-b-no pad-no'>
                    {/* for including tax */}
                    <div className=' row '>
                      <div className='form-group col-md-12'>
                        <label className='custom-checkbox small fw-med'>
                          <input
                            id='changeme'
                            type='checkbox'
                            checked={this.state.isChecked}
                            onChange={this.toggleChange}
                          />{" "}
                          &nbsp;Including Tax
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                    {/* for including tax */}

                    <div
                      className='table-responsive excel-table'
                      id='sticky-tb-hdr'
                    >
                      <ReactDataSheet
                        data={this.state.grid}
                        valueRenderer={this.valueRenderer}
                        onContextMenu={this.onContextMenu}
                        onCellsChanged={this.onCellsChanged}
                        rowRenderer={(props) => (
                          <tr>
                            <td className='action-cell'>
                              <input
                                disabled={this.state.buttondisable}
                                //   debugger
                                type='checkbox'
                                checked={this.state.selections[props.row]}
                                // onChange={this.handleChange}
                                onChange={() => {
                                  this.handleChange(props.row);
                                }}

                                //onChange={this.handlingChange}
                              />
                            </td>
                            {props.children}
                          </tr>
                        )}
                      />
                    </div>

                    <div className='batch-btm'>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.transaction_type == 2
                                ? "Total Cost Gross Amount"
                                : this.state.transaction_type == 3
                                ? "Total Cheque Gross Amount"
                                : this.state.transaction_type == 4
                                ? "Total Deposit Gross Amount"
                                : "Total Sales Gross Amount"}
                            </td>
                            <td>
                              <Comma value={this.state.grossamount_foreign} />
                            </td>
                          </tr>
                          <tr>
                            {/* <td>Total Sales Tax Amount</td> */}
                            <td>
                              {this.state.transaction_type == 2
                                ? "Total Cost Tax Amount"
                                : this.state.transaction_type == 3
                                ? "Total Cheque Tax Amount"
                                : this.state.transaction_type == 4
                                ? "Total Deposit Tax Amount"
                                : "Total Sales Tax Amount"}
                            </td>
                            <td>
                              <Comma
                                value={this.state.salestaxamount_foreign}
                              />
                            </td>
                          </tr>
                          <tr>
                            {/* <td>Total Sales Net Amount</td> */}
                            <td>
                              {this.state.transaction_type == 2
                                ? "Total Cost Net Amount"
                                : this.state.transaction_type == 3
                                ? "Total Cheque Net Amount"
                                : this.state.transaction_type == 4
                                ? "Total Deposit Net Amount"
                                : "Total Sales Net Amount"}
                            </td>
                            <td>
                              <Comma value={this.state.totalamount_foreign} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <table>
                        <tbody>
                          <tr>
                            <td>Total Gross Amount</td>
                            <td>{this.state.grossamount}</td>
                          </tr>
                          <tr>
                            <td>Total Sales Tax Amount</td>
                            <td>{this.state.salestaxamount}</td>
                          </tr>
                          <tr>
                            <td>Total Net Amount</td>
                            <td>{this.state.totalamount}</td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* content-top Starts here */}
              {/* Main Content Starts here */}
              {/* <div className="main-content col-md-12 col-xs-12">
                <div className="content-sec col-md-12 col-xs-12 pad-no mar-t-no">
                  <div className="report-table reconcile-table col-md-12 col-xs-12 mar-t-no mar-b-no pad-no">
                    <div className="table-responsive" id='sticky-tb-hdr'>
                      <table className="table detail-report batch-table">
                        <thead>
                          <tr>
                            <th className="text-left">Invoice / Credit Memo <br />Date</th>
                            <th className="text-left">Invoice / Credit Memo <br />No#</th>
                            <th>Customer Name</th>
                            <th>Description</th>
                            <th>Account</th>
                            <th className="text-right">Gross Amount</th>
                            <th className="text-center">Sales Tax Rate</th>
                            <th className="text-right">Sales Tax Amount</th>
                            <th className="text-right">Total Amount</th>
                            <th className="text-right">Exchange Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>12-08-2020</td>
                            <td>390</td>
                            <td>John Doe</td>
                            <td>Lorem ipsum...</td>
                            <td>Lorem ipsum...</td>
                            <td className="text-right">100.00</td>
                            <td className="text-center">7%</td>
                            <td className="text-right">7.00</td>
                            <td className="text-right">107.00</td>
                            <td className="text-right">1.3</td>
                          </tr>
                          <tr>
                            <td>16-08-2020</td>
                            <td>126</td>
                            <td>John Doe</td>
                            <td>Sed ut persp..</td>
                            <td>Lorem ipsum...</td>
                            <td className="text-right">-10.00</td>
                            <td className="text-center">7%</td>
                            <td className="text-right">-0.70</td>
                            <td className="text-right">-10.70</td>
                            <td className="text-right">1.4</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  */}

              {/* </div>
          </div> */}

              <div
                className='modal fade pop-modal'
                id='pop-modal-2'
                role='dialog'
                data-backdrop='static'
              >
                <div className='modal-dialog modal-md custom-modal'>
                  <button
                    type='button'
                    className='close hidden-xs'
                    data-dismiss='modal'
                    onClick={this.cancel_gst_modal}
                  >
                    <img
                      className='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div className='modal-content'>
                    <div className='modal-body text-center'>
                      <h3>Add New Column</h3>
                      <form className='custom-form row'>
                        <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                          <div className='col-md-4 col-sm-4 col-xs-12'></div>
                          <div className='col-md-8 col-sm-8 col-xs-12'></div>
                        </div>
                        <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <label>Type Of the Editable Field</label>
                                  </div>
                                  <div className='col-md-8 col-sm-8 col-xs-12'>
                                    <label className='custom-checkbox radio mar-rgt taxable'>
                                      <input
                                        type='radio'
                                        name='editableField'
                                        value='textField'
                                        checked={
                                          this.state.selectedColumnType ===
                                          'textField'
                                        }
                                        onChange={this.typeOfColumnTobeModified}
                                      />
                                      Text
                                      <span className='checkmark'></span>
                                    </label>
                                    <label className='custom-checkbox radio non-taxable'>
                                      <input
                                        type='radio'
                                        name='editableField'
                                        value='dropDownField'
                                        checked={
                                          this.state.selectedColumnType ===
                                          'dropDownField'
                                        }
                                        onChange={this.typeOfColumnTobeModified}
                                      />{' '}
                                      Drop Down
                                      <span className='checkmark'></span>
                                    </label>
                                  </div>
                                </div>
                        <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <label>Column Name</label>
                          </div>
                          <div
                            className='col-md-4 col-sm-4 col-xs-12'
                            style={{ paddingTop: 8 }}
                          >
                            {/* <input type="text" placeholder="Enter First Column Name" value={this.state.xyz} onChange={(e)=>{this.setState({xyz: e.target.value})}} /> */}
                            <input
                              type='text'
                              placeholder='Enter Column Name'
                              value={this.state.xyz}
                              onChange={(e) => {
                                this.setState({ xyz: e.target.value });
                              }}
                            />
                            <br />
                            {/* <input type="text" placeholder="Enter First Column Name" value={this.state.xyx} onChange={(e)=>{this.setState({xyx: e.target.value})}} /> */}
                          </div>
                        </div>

                        {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}

                        <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                          <button
                            className='btn btn-lightgray btn-align'
                            data-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <button
                            className='btn btn-blue btn-align'
                            type='button'
                            onClick={() => {
                              this.addCustomColumnAndUpdateGrid(
                                this.state.xyz
                              );
                            }}
                            data-dismiss='modal'
                          >
                            Add column
                          </button>{" "}
                          <span>{""}</span>
                          <span>{"   "}</span>
                          {/* <button
                                    className="btn btn-green btn-align"
                                    type="button"
                                    
                                    onClick={this.handleSubmit}
                                    data-dismiss="modal"
                                  >
                                    Save
                                  </button> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='modal fade pop-modal'
                id='pop-modal-3'
                role='dialog'
                data-backdrop='static'
              >
                <div className='modal-dialog modal-md custom-modal'>
                  <button
                    type='button'
                    className='close hidden-xs'
                    data-dismiss='modal'
                    onClick={this.cancel_gst_modal}
                  >
                    <img
                      className='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div className='modal-content'>
                    <div className='modal-body text-center'>
                      <h3>Add New Row</h3>
                      <form className='custom-form row'>
                        <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <label>Number of Rows to Add</label>
                          </div>
                          <div
                            className='col-md-4 col-sm-4 col-xs-12'
                            style={{ paddingTop: 8 }}
                          >
                            <input
                              type='number'
                              placeholder='Enter number of rows to add'
                              value={this.state.number_of_new_rows_to_add}
                              onChange={(e) => {
                                this.setState({
                                  number_of_new_rows_to_add: e.target.value,
                                });
                              }}
                            />
                            <br />
                          </div>
                        </div>

                        <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                          <button
                            className='btn btn-lightgray btn-align'
                            data-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <button
                            className='btn btn-blue btn-align'
                            type='button'
                            onClick={() => {
                              this.addNewRowsAndUpdateGrid(
                                this.state.number_of_new_rows_to_add
                              );
                            }}
                            data-dismiss='modal'
                          >
                            Save
                          </button>{" "}
                          <span>{""}</span>
                          <span>{"   "}</span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='modal fade pop-modal'
                id='pop-modal-1'
                role='dialog'
                data-backdrop='static'
              >
                <div className='modal-dialog modal-md custom-modal'>
                  <button
                    type='button'
                    className='close hidden-xs'
                    data-dismiss='modal'
                    onClick={this.cancel_gst_modal}
                  >
                    <img
                      className='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div className='modal-content'>
                    <div className='modal-body text-center'>
                      <h3>Add New GST</h3>
                      <form className='custom-form row'>
                        <div className='form-group col-md-12 col-xs-12 pad-no'>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <label>
                              Sales Tax Code
                              <span className='astrick'>*</span>
                            </label>
                          </div>
                          <div className='col-md-8 col-sm-8 col-xs-12'>
                            <input
                              type='text'
                              name='sales_tax_code'
                              id='sales_tax_code'
                              autoComplete='off'
                              maxLength='6'
                              className='form-control'
                              value={this.state.sales_tax_code}
                              onChange={(event) => this.handleChangeTax(event)}
                              required
                            />

                            <p className='input-info'>(Maximum 6 characters)</p>
                          </div>
                        </div>
                        <div className='form-group col-md-12 col-xs-12 pad-no'>
                          <div className='col-md-4 col-sm-4 col-md-12'>
                            <label>
                              Sales Tax Name
                              <span className='astrick'>*</span>
                            </label>
                          </div>
                          <div className='col-md-8 col-sm-8 col-xs-12'>
                            <input
                              type='text'
                              name='salesTax_name_entered'
                              autoComplete='off'
                              value={this.state.salesTax_name_entered}
                              id='sales_tax_name'
                              className='form-control'
                              onChange={(event) => this.handleChangeTax(event)}
                              required
                            />
                          </div>
                        </div>
                        <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <label>Tax Type</label>
                          </div>
                          <div className='col-md-8 col-sm-8 col-xs-12'>
                            <label className='custom-checkbox radio mar-rgt taxable'>
                              <input
                                type='radio'
                                name='tax-item'
                                value='option1'
                                checked={
                                  this.state.selectedOption === "option1"
                                }
                                onChange={this.handleOptionChange}
                              />
                              Taxable
                              <span className='checkmark'></span>
                            </label>
                            <label className='custom-checkbox radio non-taxable'>
                              <input
                                type='radio'
                                name='tax-item'
                                value='option2'
                                checked={
                                  this.state.selectedOption === "option2"
                                }
                                onChange={this.handleOptionChange}
                              />{" "}
                              Non-Taxable/Exempt
                              <span className='checkmark'></span>
                            </label>
                            {this.state.selectedOption === "option1" ? (
                              <div className='hidden-field col-md-12 col-xs-12'>
                                <div className='form-group'>
                                  <label className='mar-t-no mar-btm'>
                                    Tax item for purchases & sales
                                  </label>
                                  <div className='col-md-12'>
                                    <div className='row'>
                                      <label
                                        className='mar-rgt'
                                        style={{ marginTop: 8 }}
                                      >
                                        Rate
                                      </label>
                                      {}
                                      <div className='input-group rate-input'>
                                        <input
                                          className='form-control'
                                          type='text'
                                          name='tax'
                                          id='tax'
                                          autoComplete='off'
                                          required
                                          onInput={(event) =>
                                            this.handleChange_gst_type(event)
                                          }
                                        />
                                        <div className='input-group-btn'>
                                          <div className='custom-select-drop dropdown'>
                                            <a
                                              aria-expanded='false'
                                              aria-haspopup='true'
                                              role='button'
                                              data-toggle='dropdown'
                                              className='dropdown-toggle btn'
                                              href='javascript:;'
                                            >
                                              <span id='selected'>
                                                {this.state.selected_rate_type}
                                              </span>
                                              <span className='caret'></span>
                                            </a>
                                            <ul className='dropdown-menu'>
                                              <li className='active'>
                                                <a
                                                  href='javascript:;'
                                                  onClick={
                                                    this.update_rate_type
                                                  }
                                                >
                                                  %
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href='javascript:;'
                                                  onClick={
                                                    this.update_rate_fixed
                                                  }
                                                >
                                                  Fixed price
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}
                        <small style={{ color: "red" }} className='mymsg'>
                          {this.state.modal_info_msg}{" "}
                        </small>

                        <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                          {this.state.show_succes ? (
                            <div className='alert alert-success'>
                              <strong>Success!</strong> Your new GST is added.
                            </div>
                          ) : (
                            ""
                          )}
                          <button
                            className='btn btn-lightgray btn-align'
                            data-dismiss='modal'
                            onClick={this.modal_cancel}
                          >
                            Cancel
                          </button>
                          <span>{"   "}</span>
                          <button
                            className='btn btn-green btn-align'
                            type='submit'
                            onClick={this.add_gst_details}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Content Ends here */}
              <div className='invoice-form'>
                <div className='pf-btm-wrap'>
                  <div className='col-md-12 col-xs-12 text-right pad-no'>
                    <button
                      className='btn btn-lightgray btn-align'
                      disabled={this.state.buttondisable}
                      data-toggle='modal'
                      data-target='#pop-modal-3'
                    >
                      Add Row
                    </button>
                    <button
                      className='btn btn-lightgray btn-align'
                      disabled={this.state.buttondisable}
                      onClick={() => {
                        this.clear_data();
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type='button'
                      className='btn btn-green btn-align'
                      disabled={this.state.buttondisable}
                      onClick={this.state.lock_date ? this.checkLockDate : this.save_transaction}
                    >
                      Save Transactions
                    </button>
                  </div>
                </div>
              </div>

              {this.state.isSuccessful ? (
                <div
                  className='alert alert-card success alert-dismissible fade in'
                  id='closeme1'
                >
                  <a
                    href='#'
                    className='close'
                    data-dismiss='alert'
                    aria-label='close'
                    onClick={() => {
                      this.setState({ isSuccessful: false });
                    }}
                  >
                    &times;
                  </a>
                  <div className='img-wrap'>
                    <img
                      className='img-responsive'
                      src='../../images/alert-success.svg'
                      alt='icon'
                    />
                  </div>
                  <div className='alert-cont'>
                    <strong className='title'>Success!</strong>
                    <strong>Batch Transaction Added Successfully</strong>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.isFailed && (
                <div className='alert alert-card danger alert-dismissible fade in'>
                  <a
                    href='#'
                    className='close'
                    onClick={() => {
                      this.setState({ isFailed: false });
                    }}
                    data-dismiss='alert'
                    aria-label='close'
                  >
                    ×
                  </a>
                  <div className='img-wrap'>
                    <img
                      className='img-responsive'
                      src='images/alert-cross.svg'
                      alt='icon'
                    />
                  </div>
                  <div className='alert-cont'>
                    <strong className='title'>Failed!</strong>
                    {this.state.errormessage}
                  </div>
                </div>
              )}
            </div>
            {/* MainContent Wrapper Ends here */}
          </div>
        </div>
        {/* Main Wrapper Ends here */}

        {/* for addd new tax */}

        {/* for add new tax */}
        {/* for lock date modal */}
        <div>
                          <div
                            class='modal fade in'
                            id='lock_date'
                            role='dialog'
                            style={{ paddingLeft: 15 }}
                          >
                            <div
                              className='modal-dialog modal-md'
                              style={{ width: 440 }}
                            >
                              <button
                                type='button'
                                class='close hidden-xs'
                                data-dismiss='modal'
                              >
                                <img
                                  class='img-responsive'
                                  src='../../images/close-red.svg'
                                  alt='icon'
                                />
                              </button>
                              <div class='modal-content'>
                                <div class='modal-body text-center success-modal'>
                                  <div class='pop-icon img-size'>
                                    {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                                  </div>

                                  <h3>Are you sure?</h3>

                                  <p class='fw-500'>
                                    This date is beyond lock date. To apply this
                                    date , need to verify Lock Date password
                                  </p>
                                  <button
                                    className='btn btn-lightgray btn-align'
                                    data-dismiss='modal'
                                  >
                                    Cancel
                                  </button>
                                  <span>{"   "}</span>
                                  <button
                                    class='btn btn-red btn-align'
                                    type='button'
                                    data-dismiss='modal'
                                    data-toggle='modal'
                                    data-target='#asking_password_for_delete'
                                    // onClick={this.deleteUser}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* lock date password */}
                        <div
                          className='modal fade pop-modal'
                          id='asking_password_for_delete'
                          // data-backdrop='static'
                        >
                          <div className='modal-dialog modal-xs custom-modal'>
                            {/* Modal content onClick={jQuery('.modal-backdrop').removeClass('in')}*/}
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                            >
                              <img
                                className='img-responsive'
                                src='images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Verification</h3>
                                <form className='custom-form row column'>
                                  <div className='form-group col-md-12 col-xs-12'>
                                    <label>
                                      Enter Lock Password
                                      <span className='astrick'>*</span>
                                    </label>
                                    <div className='form-group login-eye'>
                                      <i
                                        className='pass-visible'
                                        toggle='#password-fieldc'
                                        onClick={() =>
                                          this.setState({
                                            show: !this.state.show,
                                          })
                                        }
                                      >
                                        <img
                                          className='off'
                                          src='images/visibility-off.svg'
                                          alt='hide'
                                        />
                                        <img
                                          className='on'
                                          src='images/visibility.svg'
                                          alt='show'
                                        />
                                      </i>
                                      <input
                                        type={
                                          this.state.show ? "text" : "password"
                                        }
                                        value={this.state.password}
                                        className='form-control'
                                        required='required'
                                        autoComplete='off'
                                        onChange={(e) =>
                                          this.setState({
                                            password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    {this.state.password == "" ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          please enter this password field.
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.password != "" &&
                                    this.state.deleteMsg == true ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          *Incorrect password
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no'>
                                    <button
                                      className='btn btn-lightgray mar-rgt-5'
                                      data-dismiss='modal'
                                    >
                                      Cancel
                                    </button>
                                    <input
                                      type='button'
                                      className='btn btn-green mar-rgt-5'
                                      data-dismiss='modal'
                                      value='Submit'
                                      onClick={() => { this.checkPassword() }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

        {/* footer Starts here */}
        <Footer defaultcategorylist_onchange={this.category_list_on_change} />
        {/* footer Ends here */}
      </div>
    );
  }
}
export default AddBankAccount;
