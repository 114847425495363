import React from 'react';
import UserTopbar from './first_user_module/header';
import { Link } from 'react-router-dom';
import FetchAllApi from './../api_links/fetch_all_api';
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default class DataPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),

      first_logged_client_id: localStorage.getItem('first_logged_client_id'),
      first_logged_company_name: localStorage.getItem(
        'first_logged_company_name'
      ),
      first_incorporation_date: localStorage.getItem(
        'first_incorporation_date'
      ),
      incorporation_date: localStorage.getItem('incorporation_date'),

      organization: '',
      subscriber: '',
      country: '',
      plan: '',
      status: '',
      view: 'All',
      layer: localStorage.getItem('layer'),
      isAllClient: 0,

      filterarr: [],
      subscriberslist: [],
      pageList: [],

      client_list: [],
      showPerPage: 10,
      search: '',
      page: 1,
      pages: 0,
      total_count: 0,
      showing: 0,
      pagecountstart: 0,
      pagecountend: 0,
    };
  }
  UNSAFE_componentWillMount() {
    localStorage.setItem('AllClientMail', '');
    localStorage.setItem('logged_client_id', this.state.first_logged_client_id);
    localStorage.setItem(
      'logged_company_name',
      this.state.first_logged_company_name
    );
    localStorage.setItem(
      'incorporation_date',
      this.state.first_incorporation_date
    );
    localStorage.setItem('lock_date', '');
  }

  componentWillMount() {
    // localStorage.setItem("layer",1)
  }

  get_list = () => {
    let subscriber_ids = [];
    let all_subscriber_ids = [];

    let input = {
      user_id: this.state.logged_user_id,
      limit: this.state.showPerPage,
      search: this.state.search,
      page: this.state.page,
    };

    FetchAllApi.get_user_subscriber_list(input, (err, response) => {
      if (response.status === 1) {
        response.list.map((item, i) => {
          subscriber_ids.push(item.id);
        });
        localStorage.setItem('subscriber_ids', JSON.stringify(subscriber_ids));

        all_subscriber_ids = response.all_subscriber_ids
          ? response.all_subscriber_ids
          : [];
        localStorage.setItem('all_subscriber_ids', all_subscriber_ids);

        // to show pages
        let showing;
        if (this.state.page == 1) {
          showing = response.list.length;
        } else if (this.state.page > 1) {
          showing =
            (this.state.page - 1) * this.state.showPerPage +
            response.list.length;
        }
        // let a = response.list.length / this.state.showPerPage
        // let b = response.list.length % this.state.showPerPage > 0 ? 1 : 0
        // let pages = a + b
        // to show pages

        this.setState({
          client_list: response.list,
          isAllClient: response.status,
          pages: response.total_pages,
          total_count: response.total_count,
          showing,
        });
      } else {
        this.setState({
          client_list: [],
          isAllClient: 0,
          pages: response.total_pages,
          total_count: response.total_count,
          showing: 0,
        });
      }
    });
    let pagenumber = this.state.page === '' ? 1 : this.state.page;
    this.setState({
      pagecountstart: pagenumber === 1 ? 1 : this.state.pagecountend + 1,
      pagecountend: this.state.showPerPage * pagenumber,
    });
  };

  componentDidMount = (e) => {
    this.get_list();
    // localStorage.setItem("layer",1)

    //2022-12-13
    if (localStorage.getItem('first_logged_layer') == 1) {
      localStorage.setItem('layer', 1);
    }
  };

  logoutLink() {
    localStorage.clear();
    this.props.history.push('/');
  }

  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }

  handle = (val, cmy_name, obj) => {
    console.log(obj, 'res');
    console.log(val);
    localStorage.setItem('logged_client_id', val);
    localStorage.setItem('logged_company_name', cmy_name);
    localStorage.setItem('AllClientMail', '');

    //2023-03-02
    // this.props.history.push("/landing_page");

    localStorage.setItem('country_sortname', obj.country_sortname);
    localStorage.setItem('language_code', obj.language_code);
    localStorage.setItem('home_currency', obj.home_currency);
    localStorage.setItem('default_currency', obj.default_currency);
    localStorage.setItem('gst_vat_currency', obj.gst_vat_currency);
    localStorage.setItem('incorporation_date', obj.incorporation_date);
    localStorage.setItem('lock_date', obj.lock_date);
    localStorage.setItem('home_currency_symbol', obj.home_currency_symbol);
    localStorage.setItem('date_format', obj.date_format);
    localStorage.setItem('is_ocr_available', obj.is_ocr_available);
    if (obj.is_have_active_subscription == 0) {
      this.props.history.push('/subscription_expired', {
        view_data_expiry_date: obj.view_data_expiry_date,
        entity_number: obj.entity_number,
      });
    } else {
      this.props.history.push('/landing_page');
    }

    // alert(obj.lock_date)
  };

  allClientMail = () => {
    localStorage.setItem('AllClientMail', 'yes');

    this.props.history.push('/landing_page');
  };

  render() {
    return (
      <div>
        <div className='container-fluid'>
          {/* header Starts here */}
          <UserTopbar logoutSubmit={(e) => this.logoutLink()} />
          {/* header Ends here */}
          {/* user-content Starts here */}
          <section className='user-content row'>
            <div className='container'>
              <div className='title-sec col-md-12 col-xs-12'>
                <h3>Data Protection Policy</h3>
              </div>
              {/* data policy Starts here */}
              <div>
                <div>
                  <p>Corporate Compliance on Data Protection Policy</p>
                  <p>Last revised on 26 February 2024</p>
                </div>

                <p>
                  This data protection policy applies to all outlets of GERARD
                  BUSINESS SERVICES CONSULTANCY which are established in
                  Singapore (“GBSC” or “we”).
                </p>
                <p>
                  This policy governs the collection, use and disclosure of
                  personal data submitted, and explains how we collect and
                  handle personal data of individuals and comply with the
                  requirements of the Personal Data Protection Act 2012 of
                  Singapore and its regulation(s) (“PDPA”). In this policy,
                  “personal data” shall have the meaning ascribed to it in the
                  PDPA.
                </p>
                <p>
                  For more information about our recruitment data protection
                  policy or our marketing data protection policy, please refer
                  to the respective sections below.
                </p>

                <h3>Main Data Protection Policy</h3>
                <div>
                  <h4>Summary</h4>
                  <p>
                    We will collect, use or disclose personal data for
                    reasonable business purposes only if there is consent or
                    deemed consent from the individual and information on such
                    purposes have been notified. We may also collect, use or
                    disclose personal data if it is required or authorised under
                    applicable laws.
                  </p>
                </div>
                <div>
                  <h4>Collection of Personal Data</h4>
                  <p>
                    We collect personal data from clients, customers, business
                    contacts, partners, personnel, contractors and other
                    individuals. Such personal data may be provided to us in
                    forms filled out by individuals, face to face meetings,
                    email messages, telephone conversations, through our
                    websites or provided by third parties. If you contact us, we
                    may keep a record of that contact.
                  </p>
                  <p>
                    We collect these personal data when it is necessary for
                    business purposes or to meet the purposes for which you have
                    submitted the information
                  </p>
                  <p>
                    We will only collect, hold, process, use, communicate and/or
                    disclose such personal data, in accordance with this policy.
                    If you are acting as an intermediary or otherwise on behalf
                    of a third party or supply us with information regarding a
                    third party (such as a friend, a colleague, an employee
                    etc), you undertake that you are an authorized
                    representative or agent of such third party and that you
                    have obtained all necessary consents from such third party
                    to the collection, processing, use and disclosure by us of
                    their personal data. Because we are collecting the third
                    party’s data from you, you undertake to make the third party
                    aware of all matters listed in this policy preferably by
                    distributing a copy of this policy to them or by referring
                    them to our website.
                  </p>
                </div>
                <div>
                  <h4>Use of Personal Data</h4>
                  <p>We use personal data for the following purposes:</p>
                  <p></p>
                </div>

                <div>
                  <h4>Disclosure of Personal Data to Third Parties</h4>
                  <p>
                    We do not disclose personal data to third parties except
                    when required by law, when we have your consent or deemed
                    consent or in cases where we have engaged the third party
                    such as data intermediaries or subcontractors specifically
                    to assist with our firm’s activities. Any such third parties
                    whom we engage will be bound contractually to keep all
                    information confidential.
                  </p>
                  <p>
                    We may disclose personal data to other member firms in the
                    GBSC network, where it is necessary (i) to meet the purpose
                    for which you have submitted the information; or (ii) to
                    enable you to be provided with information at a later date
                    which may be of relevance and interest to you based on the
                    nature and purpose of your voluntary requests. We may also
                    transfer all data in our possession to a
                    successor-in-interest to our business or assets.
                  </p>
                </div>
                <div>
                  <h4>Access to and Correction of Personal Data</h4>
                  <p>
                    Upon request, we will provide the individual with access to
                    their personal data or other appropriate information on
                    their personal data in accordance with the requirements of
                    the PDPA.
                  </p>
                  <p>
                    Upon request, we will correct an error or omission in the
                    individual’s personal data that is in our possession or
                    control in accordance with the requirements of the PDPA. We
                    may charge for a request for access in accordance with the
                    requirements of the PDPA.
                  </p>
                </div>
                <div>
                  <h4>Withdrawal of Consent</h4>
                  <p>
                    Upon reasonable notice being given by an individual of his
                    withdrawal of any consent given or deemed to have been given
                    in respect of our collection, use or disclosure of his
                    personal data, we will inform the individual of the likely
                    consequences of withdrawing his consent. We will cease (and
                    cause any of our data intermediaries and agents to cease)
                    collecting, using or disclosing the personal data unless it
                    is required or authorised under applicable laws.
                  </p>
                </div>
                <div>
                  <h4>Accuracy of Personal Data</h4>
                  <p>
                    We will make a reasonable effort to ensure that personal
                    data collected by us or on our behalf is accurate and
                    complete.
                  </p>
                </div>
                <div>
                  <h4>Security and Protection of Personal Data</h4>
                  <p>
                    We have implemented generally accepted standards of
                    technology and operational security to protect the personal
                    data in our possession or under our control and to prevent
                    unauthorised access, collection, use, disclosure, copying,
                    modification, disposal or similar risks. All GBSC personnel
                    follow a network-wide security policy. Only authorised GBSC
                    personnel are provided access to personally identifiable
                    information and these personnel have agreed to ensure
                    confidentiality of this information.
                  </p>
                </div>
                <div>
                  <h4>Retention of Personal Data</h4>
                  <p>
                    We will cease to retain personal data, as soon as it is
                    reasonable to assume that the purpose for collection of such
                    personal data is no longer being served by such retention,
                    and such retention is no longer necessary for legal or
                    business purposes.
                  </p>
                </div>
                <div>
                  <h4>Transfer of Personal Data outside of Singapore</h4>
                  <p>
                    We will ensure that any transfers of personal data to a
                    territory outside of Singapore will be in accordance with
                    the PDPA so as to ensure a standard of protection to
                    personal data so transferred that is comparable to the
                    protection under the PDPA.
                  </p>
                </div>
                <div>
                  <h4>Privacy on Our Web Sites</h4>
                  <p>
                    This Policy also applies to any personal data we collect via
                    our websites. Cookies may be used on some pages of our
                    websites. “Cookies” are small text files placed on your hard
                    drive that assist us in providing a more customised website
                    experience. Cookies are now used as a standard by many
                    websites to improve users’ navigational experience. If you
                    are concerned about cookies, most browsers permit
                    individuals to decline cookies. In most cases, a visitor may
                    refuse a cookie and still fully navigate our websites,
                    however other functionality in the site may be impaired.
                    After termination of the visit to our site, you can always
                    delete the cookie from your system if you wish.
                  </p>
                  <p>
                    Because we want your website experience to be as informative
                    and resourceful as possible, we may provide a number of
                    links to third party websites. We assume no responsibility
                    for the information practices of these third-party websites
                    that you are able to access through ours. When a visitor to
                    our website links to these thirdparty websites, our privacy
                    practices no longer apply. We encourage you to review each
                    website’s privacy policy before disclosing any data.
                  </p>
                </div>
                <div>
                  <h4>Modifications</h4>
                  <p>
                    We reserve the right to modify or amend this Policy at any
                    time. The effective date will be displayed at the beginning
                    of this Policy. To keep you informed, we will notify changes
                    to this Policy by prominently identifying the alteration for
                    a period of not less than two weeks on our home page at
                    genie.com.sg
                  </p>
                </div>
                <div>
                  <h4>Marketing Data Protection Policy</h4>
                  <p>
                    The privacy of information you provide in this form is
                    important to us. The following privacy statement governs the
                    collection and, use and disclosure of personal data
                    submitted to GBSC which is established in Singapore (the
                    “Firm” or “GBSC”). The Firm’s information handling practices
                    are in accordance with current privacy and data protection
                    legislation in Singapore.
                  </p>
                </div>
                <div>
                  <h4>Why do we collect personal data and how do we use it?</h4>
                  <p>
                    The personal data submitted in this form will be used by the
                    Firm for the purposes of any relevant promotional activities
                    organised by the Firm such as, but not limited to, seminars,
                    firm events and alerts on publications. We may also send you
                    communications from time to time, for example, to advise you
                    of technical updates or ask you to participate in surveys
                    which may be of interest to you. By submitting the
                    information in this form, you acknowledge that you have
                    consented to our processing data in accordance with the
                    privacy statement for the purposes described.
                  </p>
                </div>
                <div>
                  <h4>Retention of Personal Data</h4>
                  <p>
                    Personal data that is kept by us will be destroyed after a
                    reasonable period from the time when it becomes outdated or
                    is no longer required for the purpose for which it was
                    collected or when we receive your request to delete it.
                  </p>
                </div>
                <div>
                  <h4>How is this personal data secured?</h4>
                  <p>
                    The personal data submitted to us is stored in our contacts
                    database or similar system and is available to only
                    authorised partners and staff, and contractors. Access to
                    Your Personal Data If you wish to access, delete or correct
                    your personal data provided to us or if you have any
                    concerns about how we handle information about you, please
                    send your request to gerard@gbsc.com.sg
                  </p>
                </div>
                <div>
                  <h4>Disclosure of Data to Third Parties</h4>
                  <p>
                    Personal data collected may be transferred and shared
                    internationally between the member firms of GBSC, in order
                    to meet the purpose for which you have submitted the
                    information and activities directly related to it. By
                    submitting personal data in this form, you are providing
                    explicit consent to trans-border transmission of such data
                    as described above.
                  </p>
                  <p>
                    The personal data submitted will not be disclosed to third
                    parties except when required by law, when we have your
                    explicit consent or in cases where the third party is a
                    contractor or supplier whom we have engaged specifically to
                    assist with the Firm’s activities. All such contractors will
                    be bound contractually to keep all information confidential.
                  </p>
                </div>
                <div>
                  <h4>How do we use personal data?</h4>
                  <p>
                    We use the personal data for the primary purpose of
                    considering your suitability for positions with us. Only
                    that information which is relevant to the purpose of
                    assessing your suitability is collected and use. We will not
                    collect or use personal data in ways other than as described
                    in this policy unless we have obtained your consent. In some
                    cases, we may specifically request a consent form to be
                    signed. In other cases, consent may be implied (for
                    instance, you give us information after being fully advised
                    of how it will be used).
                  </p>
                </div>
                <div>
                  <h4>Cookies and log files</h4>
                  <p>
                    This policy also applies to any personal data we collect via
                    our websites. Cookies may be used on some areas of our
                    website. “Cookies” are small text files placed on your hard
                    drive that assist us in providing a more customised website
                    experience. Cookies are now used as a standard by many
                    websites to improve users’ navigational experience. If you
                    are concerned about cookies, most browsers permit
                    individuals to decline cookies. In most cases, a visitor may
                    refuse a cookie and still fully navigate our websites,
                    however other functionality in the site may be impaired.
                    After termination of the visit to our site, you can always
                    delete the cookie from your system if you wish.
                  </p>
                  <p>
                    In order to properly manage our website and related email
                    services, we may anonymously log information on our
                    operating systems about the number and duration of visits to
                    our website and identify categories of visitors by items
                    such as domains and browser types, and to track document
                    identification number by way of email acknowledgement or
                    return receipt. These statistics are reported in the
                    aggregate to our webmaster and system administrators. In
                    addition, site statistics are compiled by third parties, and
                    therefore your IP address will be passed to third parties
                    for statistical reporting only. This is to ensure that our
                    website presents the best web experience for visitors and is
                    an effective information resource. Whenever a registered
                    member visits our website, they may be required to identify
                    themselves in order to gain entry. This data is logged by
                    GBSC primarily to measure website performance and in
                    assisting to resolve any website technical difficulties.
                  </p>
                </div>
                <div>
                  <h4>Disclosure of personal data</h4>
                  <p>
                    Your personal data will be used only by partners and staff
                    directly involved in the recruitment process. Information
                    will not be disclosed to third parties except when required
                    by law, when we have your explicit consent or in cases where
                    the third party is a contractor or supplier whom we have
                    engaged specifically to assist with our firm’s activities.
                    All such contractors will be bound contractually to keep all
                    information confidential.
                  </p>
                </div>
                <div>
                  <h4>How is this information secured?</h4>
                  <p>
                    We keep your personal data secure and make it accessible to
                    partners and staff on a need-to-know basis. The information
                    you provide is stored into our database. GBSC has
                    implemented generally accepted standards of technology and
                    operational security in order to protect personal data from
                    loss, misuse, alteration or destruction. All GBSC staff
                    follow a firm wide security policy. Only authorized GBSC
                    personnel are provided access to personal data and these
                    employees have agreed to ensure confidentiality of this
                    information.
                  </p>
                </div>
                <div>
                  <h4>How long is information kept?</h4>
                  <p>
                    Generally, in Singapore, we hold information for specific
                    purposes for up to five years. To request us to delete your
                    personal data submitted to GBSC or otherwise, please send
                    your request to gerard@gbsc.com.sg
                  </p>
                </div>
                <div>
                  <h4>Access to your information</h4>
                  <p>
                    Access to your personal data can be made by request to us
                    unless granting access would infringe another person’s
                    privacy. If you wish to gain access to your personal data,
                    please send your request to gerard@gbsc.com.sg
                  </p>
                </div>
                <div>
                  <h4>Correction and Concerns</h4>
                  <p>
                    If you believe that information we hold about you is
                    incorrect, if you have concerns about how we are handling
                    your personal data, or if you changed your mind about our
                    usage of your data, please email us at gerard@gbsc.com.sg
                    and we will try to resolve those concerns. Changes to the
                    GBSC Data Protection Policy GBSC reserves the right to
                    modify or amend this policy at any time and for any reason.
                    However, GBSC commits to ensure that the privacy rights of
                    individuals are maintained.
                  </p>
                  <p>
                    In order to keep visitors informed, GBSC will ensure that we
                    notify users of changes to our Data Protection Policy by
                    identifying the alteration for a period of not less than two
                    weeks on our home page and identifying the effective date at
                    the beginning of this policy.
                  </p>
                </div>
                <div>
                  <h4>Contact us</h4>
                  <p>
                    We welcome your enquiries or comments about our Data
                    Protection Policy and any queries or concerns that you may
                    have about this website.
                  </p>
                </div>
                <br/>
                <div>
                  <h5>GERARD BUSINESS SERVICES CONSULTANCY</h5>
                  <p>Attn: Data Protection Officer</p>
                  <p>33 Ubi Avenue 3 #08-68</p>
                  <p>Vertex Tower A, Passenger lobby</p>
                  <p>Singapore 408868</p>
                  <p>e: gerard@gbsc.com.sg</p>
                </div>
              </div>
              {/* data policy Ends here */}
            </div>
          </section>
          {/* user-content Ends here */}
        </div>
        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <footer className='container-fluid'>
          <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
        </footer>
      </div>
    );
  }
}
