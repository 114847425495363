import React from "react";
import LeftSidebar from "./../left_sidebar";
import Footer from "./../footer";
import Topbar from "./../topbar";
import FetchAllApi from "../../api_links/fetch_all_api";
import jQuery from "jquery";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { jsPDF, specialElementHandlers, margins } from "jspdf";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from "moment";
import autoTable from "jspdf-autotable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Comma from "../comma";

//excel export with formula - starts
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
//import './ag_grid_styles.css';
let row_starts_from = 3;

const containerStyle = { width: '100%', height: '100%',display:'none' };
const gridStyle = { height: '100%', width: '100%' };
//excel export with formula - end


class TrialBalanceReport extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;

    this.gridRef = React.createRef();

    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),


      role_permissions:
        JSON.parse(localStorage.getItem("role_permissions")) || [],

      start_date: localStorage.getItem("incorporation_date"),
      end_date: moment().add(10, 'years').format("YYYY-MM-DD"),
      resData: '',
      tableData: '',
      date_range: 'Custom',
      report_type: "1",
      manualLoading:false,

      //excel export with formula - starts
     // export_formula_row_data:[],
      export_formula_column_defs:[
        { field: 'Account' },
        { field: 'Debit' },
        { field: 'Credit' },
      ],
      defaultColDef:{
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      defaultExcelExportParams:{
          autoConvertFormulas: true,
          processCellCallback: (params) => {
            const rowIndex = params.accumulatedRowIndex;
            const valueGetter = params.column.getColDef().valueGetter;
            return !!valueGetter
              ? `=CONCATENATE(A${rowIndex}, " ", B${rowIndex})`
              : params.value;
          },
          fileName:"Trial-Balance.xls",
          sheetName:"Trial-Balance"
        }
      //excel export with formula - end

    };
  }

  readTableData = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      // format: [4, 2]
    });
    doc.setLineWidth(2);
    doc.text(5, 0.5, "Trial Balance");

    let date_text = this.state.date_range == "ALL" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY");
    if(this.state.date_range == "ALL") {
       doc.text(4.9, 0.8,date_text);
    } else {
       doc.text(4.5, 0.8,date_text);
    }

    autoTable(doc,
      {
        html: "#table",
        includeHiddenHtml: false,
        // startY: 0.6
        startY: 0.9
      });

    doc.save("Trail-balance.pdf");
  };

  //excel export with formula - starts
  onBtExport = () => {
    this.gridRef.current.api.exportDataAsExcel();
  }

  htmlToJson=(html)=>{
    FetchAllApi.html_to_json_converter({html_content:html}, (err, response) => {
      if (response.status === 1) {
        let data = response.data;
        console.log('data.....length ',data.length);
        if(data.length > 1) {
          console.log('data.....length gtr',data.length > 1);
          data.pop();
          data.forEach((d,i)=>{
            d.Debit = this.commaSeperatedValueToNumber(d.Debit);
            d.Credit = this.commaSeperatedValueToNumber(d.Credit);
        });
        data.push( { Account: 'Total', Debit: `=SUM(B${row_starts_from}:B${data.length + 2})`, Credit: `=SUM(C${row_starts_from}:C${data.length + 2})` });
        }

        let header_data = [{ Account: this.state.date_range == "All" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY") }];
        header_data = header_data.concat(data);
    
        this.setState({
          // export_formula_row_data:data
          export_formula_row_data:header_data
        },()=>{
          this.onBtExport();
        });
      } else {
        alert('Failed to export excel');
      }
    });

  }

  commaSeperatedValueToNumber = (val)=> {
    if(typeof val == 'string') {
      val = val.replace(/\s/g, '');
    }
    return parseFloat(val.replace(/,/g, ''));
  }
  //excel export with formula - end

  repeat = (sub_categories, paddingLeft) => {
    console.log("july", paddingLeft);

    if (sub_categories) {
      return (
        <React.Fragment>
          {sub_categories &&
            sub_categories.length > 0 &&
            Object.keys(sub_categories[0]).map((itm, i) => {

              return (
                <React.Fragment key={i}>

                  {
                    ((Number(sub_categories[0][itm].total_debit) != 0) ||
                      Number(sub_categories[0][itm].total_credit) != 0) && (
                      <React.Fragment>
                        <tr className="item-step1">
                          <td style={{
                            paddingLeft: `${paddingLeft}px`,

                          }}><span>{sub_categories[0][itm].category_name}</span></td>
                          {/* <td><span className="text-right">{sub_categories[0][itm].total_debit}</span></td>
                                                    <td><span className="text-right">{sub_categories[0][itm].total_credit}</span></td> */}

                          <td onDoubleClick={() => { this.subCategoryOthers(sub_categories[0][itm].category_id) }}>
                            <span className="text-right">
                            {/* {sub_categories[0][itm].total_others_debit} */}
                            <Comma value={sub_categories[0][itm].total_others_debit} />
                          </span></td>
                          <td onDoubleClick={() => { this.subCategoryOthers(sub_categories[0][itm].category_id) }}>
                            <span className="text-right">
                              {/* {sub_categories[0][itm].total_others_credit} */}
                            <Comma value={sub_categories[0][itm].total_others_credit} />
                          </span></td>
                        </tr>


                        {sub_categories[0][itm].is_child_data_available == 1 &&
                          this.repeat(
                            sub_categories[0][itm].sub_categories,
                            paddingLeft + 45
                          )}


                            {/* Don't delete in any case- To display subcategories total */}
                        {/* 
                        {sub_categories[0][itm].is_child_data_available === 1 &&
                          (sub_categories[0][itm].total_others_debit != 0 || sub_categories[0][itm].total_others_credit != 0)
                          &&
                          <tr className="item-step1">
                            <td style={{
                              paddingLeft: `${paddingLeft}px`,

                            }}><span>Others - {sub_categories[0][itm].category_name}</span></td>
                            <td><span className="text-right">{sub_categories[0][itm].total_others_debit}</span></td>
                            <td><span className="text-right">{sub_categories[0][itm].total_others_credit}</span></td>
                          </tr>
                        } */}

                        {/* Don't delete in any case - to dispay total for each categories */}
                        {/* {sub_categories[0][itm].is_child_data_available === 1 &&
                          (sub_categories[0][itm].total_others_debit != 0 || sub_categories[0][itm].total_others_credit != 0)
                          &&
                          <tr className="item-step1">
                            <td style={{
                              paddingLeft: `${paddingLeft}px`,

                            }}><span>Total - {sub_categories[0][itm].category_name}</span></td>
                            <td><span className="text-right">{sub_categories[0][itm].total_debit}</span></td>
                            <td><span className="text-right">{sub_categories[0][itm].total_credit}</span></td>
                          </tr>
                        } */}



                      </React.Fragment>
                    )}


                </React.Fragment>
              );
            })}
        </React.Fragment>
      );
    }
  };

  trial_balance = () => {
    let input = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      client_id: this.state.logged_client_id,
      // client_id: 226,
      filter_id: [],
      filter_options: {
      },
      sort_by_column_key: this.state.selectedName,
      sort_by: this.state.sort_type,
      report_type: this.state.report_type
    }
    
    this.setState({manualLoading:true});
    FetchAllApi.trial_balance(input, (err, response) => {
      if (response.status === 1) {
        console.log("rty", response);
        this.setState({ resData: response, tableData: Object.values(response.details),manualLoading:false });
      } else {
        this.setState({ resData: '', tableData: '',manualLoading:false });
      }
    });
  };

  changefromDate(fromdate) {
    setTimeout(()=>{
    let date = jQuery("#fromdate").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      this.setState({ start_date: date_formated }, () => {
        this.trial_balance();
      });
    }
  },1000);
  }
  changetoDate(todate) {
    setTimeout(()=>{
    // alert(jQuery('#todate').val())
    let date = jQuery("#todate").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      console.log("ewewew", array);
      this.setState({ end_date: date_formated }, () => {
        this.trial_balance();
      });
    }
  },1000);
  }
  generate = () => {
    debugger
    var doc = new jsPDF('p', 'pt', 'letter');
    var htmlstring = '';
    var tempVarToCheckPageHeight = 0;
    var pageHeight = 0;
    pageHeight = doc.internal.pageSize.height;
    // specialElementHandlers = {
    //     // element with id of "bypass" - jQuery style selector  
    //     '#bypassme': function (element, renderer) {
    //         // true = "handled elsewhere, bypass text extraction"  
    //         return true
    //     }
    // };
    // margins = {
    //     top: 150,
    //     bottom: 60,
    //     left: 40,
    //     right: 40,
    //     width: 600
    // };
    var y = 20;
    doc.setLineWidth(2);
    doc.text(200, y = y + 30, "Trail Balance Report");
    doc.autoTable({
      html: '#mytable',
      startY: 70,
      theme: 'grid',
      columnStyles: {
        0: {
          cellWidth: 180,
        },
        1: {
          cellWidth: 180,
        },
        2: {
          cellWidth: 180,
        }
      },
      styles: {
        minCellHeight: 40
      }
    })
    doc.save('TrailBalanceReport.pdf');
  }

  changedatevalue(seleteddateformat) {
    var dateresult = moment();
    let from_date, to_date;

    if (seleteddateformat === "This Month-to-date") {
      from_date = dateresult.startOf("month");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(new Date()).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = moment(new Date()).format("YYYY-MM-DD");
      this.trial_balance();
    } else if (seleteddateformat === "This Week") {
      from_date = dateresult.startOf("week");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      console.log("startdate", this.state.start_date);
      to_date = dateresult.endOf("week");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.trial_balance();
    } else if (seleteddateformat === "This Month") {
      from_date = dateresult.startOf("month");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = dateresult.endOf("month");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.trial_balance();
    } else if (seleteddateformat === "This Week-to-date") {
      from_date = dateresult.startOf("week");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(new Date()).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = moment(new Date()).format("YYYY-MM-DD");
      this.trial_balance();
    } else if (seleteddateformat === "This Year") {
      from_date = dateresult.startOf("year");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = dateresult.endOf("year");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.trial_balance();
    } else if (seleteddateformat === "This Year-to-date") {
      from_date = dateresult.startOf("year");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = moment(new Date()).format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(to_date).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = to_date;
      this.trial_balance();
    }
    // let startDate = jQuery('#fromdate').val()
    // let end_date = jQuery('#todate').val()
    // this.setState({ start_date: startDate, end_date: end_date }, () => {
    //   this.trial_balance();

    // })
    if (seleteddateformat == "ALL") {
      this.setState(
        {
          start_date: "1970-01-01",
          // end_date: moment().format("YYYY-MM-DD"),
          end_date:moment().add(10, 'years').format("YYYY-MM-DD")
        },
        () => {
          this.trial_balance();
        }
      );
      document.getElementById("fromdate").value = "";
      document.getElementById("todate").value = "";
    }
  }

  componentDidUpdate() {
    window.jQuery(".selectpicker").selectpicker("refresh");
    // jQuery("#currency_selected").val(4);
  }

  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass("minimize_leftbar");
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery("title").html("Customer | GBSC");

    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === "null" ||
      this.state.logged_user_id === "undefined"
    ) {
      this.props.history.push("/");
    }
  }

  routedChange(parameter) {
    this.props.history.push("/" + parameter);
    window.scrollTo(0, 0);
  }

  componentDidMount() {

    // this.trial_balance()
    this.sortByNames()
    document.getElementById("sticky-tb-hdr").addEventListener("scroll", function () {
      var translate = "translate(0," + this.scrollTop + "px)";
      if (this.querySelector("thead") != null && this.querySelector("thead") != undefined && this.querySelector("thead").style != null) {
        this.querySelector("thead").style.transform = translate;
      }
    });

    jQuery(window).on("load", function () {
      window.jQuery(".mscroll-y").mCustomScrollbar({
        axis: "y",
        scrollEasing: "linear",
        scrollInertia: 600,
        autoHideScrollbar: "true",
        autoExpandScrollbar: "true",
      });

      window.jQuery(".ib-scroll").mCustomScrollbar({
        scrollEasing: "linear",
        scrollInertia: 600,
        scrollbarPosition: "outside",
      });
    });
    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy", autoclose: true });

    jQuery('.snippet').mouseenter(function () {
      jQuery('.snippet').removeClass('active')
      jQuery(this).addClass('active')
    })

    jQuery('.filter-btn').click(function () {
      jQuery(this).css('visibility', 'hidden')
      jQuery('.report-filter').slideDown()
    })

    jQuery('.report-filter .close-btn').click(function () {
      jQuery('.filter-btn').css('visibility', 'visible')
      jQuery('.report-filter').slideUp()
    })
    jQuery(document).ready(function () {
      jQuery(".left-navmenu .has-sub").click(function () {
        jQuery(".left-navmenu li a").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(".left-navmenu li a:not(.active)")
          .siblings(".sub-menu")
          .slideUp();
        jQuery(this).siblings(".sub-menu").slideToggle();
      });
      jQuery(".left-navmenu .sub-menu li a").click(function () {
        jQuery(".left-navmenu .sub-menu li a").removeClass("active");
        jQuery(this).addClass("active");
      });
      jQuery(".search-btn").click(function () {
        jQuery(".hdr-search").addClass("active");
      });
      jQuery(".hdr-search .close-icon").click(function () {
        jQuery(".hdr-search").removeClass("active");
      });
      window.jQuery(".select-picker").selectpicker();
      jQuery(".label-enclose .label").click(function () {
        jQuery(this).toggleClass("active");
      });
      jQuery(".nav-brand-res").click(function () {
        jQuery(".left-navbar").addClass("active");
      });
      jQuery(".menu-close").click(function () {
        jQuery(".left-navbar").removeClass("active");
      });
    });
    if (localStorage.getItem('fiscal_start_year') != '' && localStorage.getItem('fiscal_start_year') != null && localStorage.getItem('fiscal_start_year') != undefined) {
      let start = moment(localStorage.getItem('fiscal_start_year')).format("DD-MM-YYYY");
      let end = moment(localStorage.getItem('fiscal_end_year')).format("DD-MM-YYYY");

      document.getElementById("fromdate").value = start;
      document.getElementById("todate").value = end;

      this.setState({ 
          start_date: localStorage.getItem('fiscal_start_year'), 
          end_date: localStorage.getItem('fiscal_end_year') },()=>{
            this.trial_balance();
          });
    } else {  
      this.setState({ date_range: "All" })
      this.changedatevalue("All");
      // this.trial_balance();

      let start = moment('1970-01-01').format("YYYY-MM-DD");
      this.setState({ 
        start_date: start, 
        end_date: moment().format("YYYY-MM-DD")
        },()=>{
          this.trial_balance();
        });
    };
    // if (localStorage.getItem('fiscal_start_year') != '' && localStorage.getItem('fiscal_start_year') != null && localStorage.getItem('fiscal_start_year') != undefined) {
    //   let start = moment(localStorage.getItem('fiscal_start_year')).format("DD-MM-YYYY");
    //   let end = moment(localStorage.getItem('fiscal_end_year')).format("DD-MM-YYYY");

    //   document.getElementById("fromdate").value = start;
    //   document.getElementById("todate").value = end;

    //   this.setState({ 
    //       start_date: localStorage.getItem('fiscal_start_year'), 
    //       end_date: localStorage.getItem('fiscal_end_year') },()=>{
    //         this.trial_balance();
    //       });
    // } else {
    //   this.setState({ date_range: "All" })
    //   this.changedatevalue("All");
    //   this.trial_balance();
    // };
  }

  subCategory(subId) {
    // if(subId===undefined){
    //   window.open("/profit_loss_report?start=" + this.state.start_date + "&end=" + this.state.end_date + "&range=" + this.state.date_range)
    // }
    // else{
    // window.open("/transaction_history?category_id_array=" + subId + "&start=" + this.state.start_date + "&end=" + this.state.end_date + "&range=" + this.state.date_range + "&report_type="+ this.state.report_type)
    window.open("/general_ledger?category_id_array=" + subId + "&start=" + this.state.start_date + "&end=" + this.state.end_date + "&range=" + this.state.date_range + "&report_type="+ this.state.report_type)
  //  }
  }

  subCategoryOthers(subId) {
    // window.open(
    //   "/transaction_history?category_id=" +
    //   subId +
    //   "&start=" +
    //   this.state.start_date +
    //   "&end=" +
    //   this.state.end_date +
    //   "&range=" +
    //   this.state.date_range + "&report_type="+ this.state.report_type
    // );
    window.open(
      "/general_ledger?category_id=" +
      subId +
      "&start=" +
      this.state.start_date +
      "&end=" +
      this.state.end_date +
      "&range=" +
      this.state.date_range + "&report_type="+ this.state.report_type
    );
  }

  logoutLink() {
    localStorage.clear();

    this.props.history.push("/");
  }
  sortByNames = () => {
    let report_id = 18;
    // alert('hjgh')
    FetchAllApi.reportSortbyOptions(report_id, (err, response) => {
      if (response.status === 1) {
        console.log("rty", response);
        this.setState({ sortBynames: response.list });
      } else {
      }
    });
  };

  pageLink(page_slug) {
    this.props.history.push("/" + page_slug);
  }

  render() {
    return (
      <div>
        {/* Main Wrapper Starts here */}
        <div className="container-fluid">
          <div className="row">
            {/* left-navbar Starts here */}
            <LeftSidebar history={this.props.history} pageSubmit={(e) => this.pageLink(e)} />

            {/* left-navbar Ends here */}
            {/* MainContent Wrapper Starts here */}
            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              {/* Top bar Starts here */}
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <form className="hdr-search">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                  />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href="javascript:;" className="close-icon">
                    <img src="images/close-icon-red.svg" alt="Close" />
                  </a>
                </form>
                <div className="nav-brand-res visible-xs">
                  <img
                    className="img-responsive"
                    src="images/logo-icon.png"
                    alt="LogoIcon"
                  />
                </div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="images/back-arrow-blue.svg" onClick={() => this.props.history.goBack()} />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li><a href="javascript:;">Report</a></li>
                  <li>Accountant</li>
                  <li>Trial Balance</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={(e) => this.logoutLink()} />
              </div>

              {/* Top bar Ends here */}
              <div className="col-md-12 col-xs-12 mar-top visible-xs">
                <a href="javascript:;" className="back">
                  <img src="images/back-arrow-blue.svg" />
                </a>
                <span className="page-title">Trial Balance</span>
              </div>
              {/* content-top Starts here */}
              <div className="content-top col-md-12 col-xs-12">
                <h4 className="fw-sbold mar-t-no">Trial Balance</h4>
                {/* <h5 className="fw-sbold">Nov 2020</h5> */}
                <div className="row">
                  <div className="report-setting col-md-12 col-xs-12">
                    <form className="custom-form form-inline col-md-12 col-xs-12 pad-no">
                      <div className="row">
                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                          <label>Date Range</label>
                          <select
                            id="custom"
                            className="form-control"
                            value={this.state.date_range}
                            onChange={(e) =>{
                              this.setState({
                                date_range:e.target.value
                              });
                              this.changedatevalue(e.target.value);
                            }
                            }
                          >
                            <option >ALL</option>
                            <option>Custom</option>

                            <option>This Month-to-date</option>
                            <option>This Week</option>
                            <option>This Month</option>
                            <option>This Week-to-date</option>
                            <option>This Year</option>
                            <option>This Year-to-date</option>
                          </select>
                        </div>

                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                          <label>From</label>
                          <div
                            className="input-group date mar-t-no"
                            data-date-format="dd/mm/yyyy"
                          >
                            <input
                              type="text"
                              id="fromdate"
                              onBlur={(e) => {
                                let value = e.target.value
                                this.setState({ date_range: "Custom" })
                                jQuery("#custom").val("Custom")
                                setTimeout(() => {
                                  this.changefromDate(value)
                                }, 500)
                              }}

                              className="form-control"
                              autoComplete="off"
                            />
                            <div className="input-group-addon" onClick={() => jQuery('#fromdate').focus()}>
                              <img
                                src="images/calendar-icon.svg"
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                          <label>To</label>
                          <div
                            className="input-group date mar-t-no"
                            data-date-format="dd/mm/yyyy"
                          >
                            <input
                              type="text"
                              id="todate"
                              onBlur={(e) => {
                                jQuery("#custom").val("Custom");
                                this.changetoDate(e.target.value);
                              }}
                              className="form-control"
                              autoComplete="off"
                            />
                            <div className="input-group-addon" onClick={() => jQuery('#todate').focus()} >
                              <img
                                src="images/calendar-icon.svg"
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                          <label>Report Basis</label>
                          <div className="col-md-12 col-xs-12 pad-no radio-mar-t">
                            <label className="custom-checkbox small radio mar-t-no mar-rgt">
                              <input type="radio" name="report-basic" defaultChecked="checked" onClick={() => {
                                this.setState({ report_type: '1' }, () => { this.trial_balance() })
                              }} /> Accural
                              <span className="checkmark" />
                            </label>
                            <label className="custom-checkbox small radio mar-t-no mar-rgt">
                              <input type="radio" name="report-basic" onClick={() => {
                                this.setState({ report_type: '2' }, () => { this.trial_balance() })
                              }} /> Cash
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row trial-sortby">
                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                          <label>Sort By</label>
                          <select
                            className="selectpicker form-control"
                            id="customer_type"
                            data-live-search="true"
                            onChange={(e) => {
                              this.setState({ selectedName: e.target.value })
                              setTimeout(() => { this.trial_balance() }, 500)
                            }}
                          >
                            <option selected={true}>Choose</option>
                            {this.state.sortBynames &&
                              this.state.sortBynames.map((item) => {
                                return (
                                  <React.Fragment>
                                    <option value={item.column_key}>
                                      {item.name}
                                    </option>
                                  </React.Fragment>
                                );
                              })}
                          </select>
                        </div>
                        <div className="sort-by-icon-margin">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              let a = this.state.sort_type == "desc" ? "asc" : "desc";
                              this.setState({ sort_type: a });
                              setTimeout(() => {
                                if (this.state.selectedName != "") {
                                  this.trial_balance();
                                }
                              }, 500)
                            }}
                          >
                            {/* {this.state.sort_type == "desc" ? "asc" : "desc"} */}
                          {/*  {this.state.sort_type == "desc" ? <FontAwesomeIcon icon="fas fa-sort-amount-up"/> : <FontAwesomeIcon icon="fas fa-sort-amount-down"/>}
                          </a>
                        </div>
                      </div> */}
                    </form>

                    <div className="pull-right">
                      <div className="dropdown menu-item new-cus">
                        <button
                          className="btn btn-green dropdown-toggle btn-arrow"// btn-arrow
                          type="button"
                          data-toggle="dropdown"
                          aria-expanded="false" //aria-expanded="false"
                        >
                          Export<span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu align-right">
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.readTableData();
                              }}
                            >
                              Export as PDF
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              id="hi"
                              onClick={() => {
                                var elem = document.getElementById("hi");
                                var table = document.getElementById("table");
                                var html = table.outerHTML;
                                var url =
                                  "data:application/vnd.ms-excel," + escape(html);

                                //don't delete in any case- working fine before excel export with formula
                                // elem.setAttribute("href", url);
                                // elem.setAttribute("download", "Trial-Balance.xls");
                                // return false;
                                //end here

                                //excel export with formula - starts
                                this.htmlToJson(html);
                                 //excel export with formula - end
                              }}
                            >
                              Export as Excel
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* <div className="pull-right xs-pull-left">
                                 <div className="dropdown menu-item">
                                    <button className="btn btn-green dropdown-toggle btn-arrow" data-toggle="dropdown" aria-expanded="false">Export<span className="caret" /></button>
                                    <ul className="dropdown-menu align-right">
                                       <li><ReactHTMLTableToExcel
                                          id="test-table-xls-button"
                                          className="download-table-xls-button"
                                          table="mytable"
                                          filename="tablexls"
                                          sheet="tablexls"
                                          buttonText="Export as Excel" /></li>
                                       <li> <input type="button" onClick={this.generate} value="Export as PDF" /></li>
                                    </ul>
                                 </div>
                              </div> */}
                  </div>
                </div>
              </div>

              {this.state.manualLoading ? <div class="loading_spinner">Loading&#8230;</div> : ''}

              {/* content-top Starts here */}
              {/* Main Content Starts here */}
              <div className="main-content col-md-12 col-xs-12">
                <div className="content-sec col-md-12 col-xs-12 pad-no mar-t-no">
                  <div className="report-table trail-bal col-md-12 col-xs-12 pad-no mar-t-no">
                    <div className="table-responsive" id="sticky-tb-hdr">
                      <table className="table" id="table">
                        <thead>

                        <th>
                            {this.state.start_date==='1970-01-01' ? `As of ${moment(this.state.end_date).format("DD MMM YYYY")}` :moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY")}
                          </th>
                            <th style={{width: '100%'}}>
                             </th>
                            <th style={{width: '100%'}}>
                             </th>
                       

                          <tr className="bdr-btm-lc" style={{background:'white'}}>
                            <th />
                            <th colSpan={2} className="text-center">{this.state.end_date == moment().add(10, 'years').format("YYYY-MM-DD")? "All":moment(this.state.end_date).format('ll')}</th>
                          </tr>
                          <tr style={{background:'white'}}>
                            {/* <th>&nbsp;</th> */}
                            <th>Account</th>
                            <th className="text-right">Debit</th>
                            <th className="text-right pad-rgt">Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableData &&
                            this.state.tableData.map((primary) => {
                              if (primary.total_debit != 0 || primary.total_credit != 0) {
                                return (
                                  <>
                                    <tr className="item-step1">
                                      <td><span>{primary.category_name}</span></td>
                                      {/* <td><span className="text-right">{primary.total_debit}</span></td>
                                                                            <td><span className="text-right">{primary.total_credit}</span></td> */}

                                      <td
                                          onDoubleClick={() => { this.subCategoryOthers(primary.category_id) }}
                                        ><span className="text-right">
                                        {/* {primary.total_others_debit} */}
                                        <Comma value={primary.total_others_debit} />
                                        </span></td>
                                      <td
                                      onDoubleClick={() => { this.subCategoryOthers(primary.category_id) }}
                                      ><span className="text-right">
                                        {/* {primary.total_others_credit} */}
                                        <Comma value={primary.total_others_credit} />
                                        </span></td>
                                    </tr>


                                    {primary.is_child_data_available === 1 && this.repeat(primary.sub_categories, 45)}

                                    {/* Don't delete in any case */}
                                    {/* {primary.is_child_data_available === 1 &&
                                      (primary.total_others_debit != 0 || primary.total_others_credit != 0)
                                      &&
                                      <tr className="item-step1">
                                        <td><span>Others - {primary.category_name}</span></td>
                                        <td><span className="text-right">
                                          <Comma value={primary.total_others_debit} />
                                          </span></td>
                                        <td><span className="text-right">
                                          <Comma value={primary.total_others_credit} />
                                        </span></td>
                                      </tr>
                                    } */}

                                    {/* Don't delete in any case - to display total for each categories */}
                                    {/* {primary.is_child_data_available === 1 &&
                                      (primary.total_others_debit != 0 || primary.total_others_credit != 0)
                                      &&
                                      <tr className="item-step1">
                                        <td><span>Total - {primary.category_name}</span></td>
                                        <td><span className="text-right">
                                          <Comma value={primary.total_debit} />
                                          </span>
                                          </td>
                                        <td><span className="text-right">
                                          <Comma value={primary.total_credit} />
                                          </span></td>
                                      </tr>
                                    } */}

                                  </>

                                )
                              }
                            })}



                          <tr className="item-step1 title1 bdr-no">
                            <td><span>Total</span></td>
                            <td><span className="text-right">
                              {/* {this.state.resData.overall_total_home_debit} */}
                              <Comma value={this.state.resData.overall_total_home_debit} />
                              </span></td>
                            <td><span className="text-right">
                              {/* {this.state.resData.overall_total_home_credit}  */}
                              <Comma value={this.state.resData.overall_total_home_credit} />
                               </span></td>
                          </tr>
                        </tbody>
                      </table>

                      {/* excel export with formula - starts */}
                      <div style={containerStyle}>
      <div className="container">
                      <div className="grid-wrapper">
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                        ref={this.gridRef}
                        rowData={this.state.export_formula_row_data}
                        columnDefs={this.state.export_formula_column_defs}
                        defaultColDef={this.state.defaultColDef}
                        defaultExcelExportParams={this.state.defaultExcelExportParams}
                        ></AgGridReact>
                      </div>
                      </div>
                      </div>
                      </div>
                      {/* excel export with formula - end */}


                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Ends here */}
            </div>
            {/* MainContent Wrapper Ends here */}
          </div>
        </div>
        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <footer className="container-fluid">
          <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
        </footer>
        {/* footer Ends here */}
      </div>

    );
  }
}
export default TrialBalanceReport;
