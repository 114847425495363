import React from "react";
import LeftSidebar from "./../left_sidebar";
import Footer from "./../footer";
import Topbar from "./../topbar";
import FetchAllApi from "../../api_links/fetch_all_api";
import jQuery from "jquery";
import config from "./../../api_links/api_links.jsx";
import { ToWords } from "to-words";
import moment from "moment";
import writtenNumber from "written-number";
import Swal from "sweetalert2";

import { PDFtoIMG } from 'react-pdf-to-image'
writtenNumber.defaults.lang = 'en-US';
var _ = require("lodash");
var authorization_key = "O5mGIP3VNia0JvPH2IBiwA==";
//var authorization_key = localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):'';
const $ = require("jquery");
$.DataTable = require("datatables.net");



const toWords = new ToWords();

class TransferFund extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      lock_date: localStorage.getItem("lock_date"),

      selectedLimit: 10,
      role_permissions:
        JSON.parse(localStorage.getItem("role_permissions")) || [],
      transfer_fund_from: '',
      from_currency: '',
      transfer_fund_to: '',
      to_currency: '',
      transaction_date: '',//moment(new Date()).format('DD/MM/YYYY'),
      from_transfer_currency: '',
      to_transfer_currency: '',
      transfer_amount: '',
      amount_words: '',
      memo: '',
      exchange_rate: '',
      clientHomeCurrency: '',
     // currency_list: [],
      currency_list: config.all_currency_list,
      banklist: [],
      currency: {},
      errorMsg: "",
      default_category_list: [],
      from_or_to_option:'',
      transfer_id:0,
      transfer_fund_transaction_number:'',
      is_create_copy_transfer_fund:'',

      saveType:'',
      selectedFile: '',
      succ_msg: '',
      attachment_file: [],
      attachment_file_length: 0,
      attachment_fileName: [],
      imgThumb: '',
      pages: [],
      list_of_attachments: [],
      is_invalid_subject: false,
      is_invalid_message: false,
      is_files_not_selected: false,
      old_transaction_date:''
    };
    this.loadFile = this.loadFile.bind(this)
  }

  removeImage = (e, index) => {
    const updated = this.state.attachment_file.filter(item => item.name !== e);
    this.setState(
      {
        attachment_file: updated
      },
      () => {
        if (this.state.attachment_file.length === 0) {
          // Reset file input value when no files are selected
          document.getElementById('imgInp').value = '';
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            opacity: 1
          });
          jQuery('.upload-thumb').addClass('hide');
        }
      }
    );
  };


  loadFile(e) {
    var files = e.target.files
    this.setState({ attachment_file_length: files.length })
    console.log(files,'res prev')
    if (files.length > 0) {
      // jQuery('.upload-space').addClass('hide')
      jQuery('.upload-space').css({
        "opacity": 0
      });
      jQuery('.upload-thumb').removeClass('hide')
      var fileArra = this.state.attachment_file
      //var fileThumbArra = this.state.imgThumb;
      for (var i = 0; i < files.length; i++) {
        // let fname = e.target.files[i].name.toLowerCase();
        let fname = files[i].name.toLowerCase();
        if (fname.endsWith('.pdf') || fname.endsWith('.png') || fname.endsWith('.jpg') || fname.endsWith('.jpeg') ||  fname.endsWith('.xls') || fname.endsWith('.xlsx')) {

          // let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() == e.target.files[i].name.toLowerCase());
          let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() === fname);
       console.log(this.state.attachment_file,previous_attachments,'res prev', fname)
       
          if (previous_attachments.length === 0) {
            // fileArra.push(e.target.files[i]);
            fileArra.push(files[i]);
            this.setState({
              // selectedFile: URL.createObjectURL(e.target.files[i]),
              selectedFile: URL.createObjectURL(files[i]),
              attachment_file: fileArra,
              is_files_not_selected: false
            }, () => {

            });

            // e.target.files = null;
            // e.target.value = '';
          } else {
            e.target.files = null;
            e.target.value = ''
            e.preventDefault();
            this.setState({
              isFailed: true,
              errormessage: 'Cannot select same file again'
            });

            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: '' });
            }, 3000);
          }

        } else {
          e.target.files = null;
          e.target.value = ''
          e.preventDefault();
          this.setState({
            isFailed: true,
            errormessage: 'Unsupported file Format'
          });

          setTimeout(() => {
            this.setState({ isFailed: false, errormessage: '' });
          }, 3000);
        }

        if (i == files.length - 1) {
          if (this.state.attachment_file.length == 0) {
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              "opacity": 1
            });
            jQuery('.upload-thumb').addClass('hide')
          }
        }
      }
    }
  
  }
save_attachment=()=>{
  const client_id=this.state.logged_client_id;
  const type='transfer_fund';
  const source_attachments=this.state.attachment_file;
  console.log(source_attachments, 'resss')
  FetchAllApi.save_source_attachements(
    client_id,
    type,
    source_attachments,
    (err, response) => {
      if (response.status === 1) {
        this.setState({
          attachment_file: [],
          list_of_attachments: [...this.state.list_of_attachments, ...response.data],
        }, () => {
          jQuery('.previewImg').remove()
          window.jQuery('#upload_doc_modal').modal('hide')
          window.jQuery('#attachment_modal').modal('show')
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            "opacity": 1
          });

          jQuery('.upload-thumb').addClass('hide')
        });
      } else {
        alert(response.message)
      }
    }
  )
}


    // to check lock password

    checkPassword = () => {
      let Input = {
        client_id: this.state.logged_client_id,
        password: this.state.old_password,
      };
  
      FetchAllApi.verify_lock_date_password(Input, (err, response) => {
        if (response.status === 1) {
          let temp_date = this.state.temp_date;
          jQuery("#invoice_date").val(temp_date);
  
          window.jQuery(".modal-backdrop").css("display", "none");
          window.jQuery("#asking_password_for_delete").modal("hide");
          window.jQuery("#lock_date").modal("hide");
  
          // setTimeout(() => {
          //   this.setState({
          //     cus_rate_rate: this.state.exchange_value_ref,
          //   });
          // }, 500);
  
          // setTimeout(() => {
            // this.handleChangeItems(0, this.state.rows.length - 1);
          // }, 500);
          this.saveFunc(this.state.saveType)
          this.loadExchangeRate(true);
        } else {
          alert(response.message);
          this.setState({ deleteMsg: true });
        }
      });
    };


  rename = (obj, curr) => {
    let a = {}
    Object.keys(obj).map((key) => {
      let newKey = key.replace(curr, '')
      Object.assign(a, { [newKey]: obj[key] })
    })
    return a
  }

 
  defaultcategorylist_onchange = (x, y, z, new_currency) => {
    let keyy = "";
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    FetchAllApi.defaultcategorylist_onchange2(
      keyy,
      from_create_invoice,
      client_id,
      filter_id,
      (err, response) => {
        // console.log("defaultcat9999egorylist", response);
        if (response.status === 1) {
          if (x == "added") {
            this.setState({
              selectNeedIndex: response.list.length - 1,
              nameFilter: y,
            });
          }
          this.setState(
            {
              default_category_list: response.list,
            },
            () => {
             // window.jQuery("#balanceSheetCategeory").selectpicker("refresh");
            }
          );
        } else {
          this.setState({
            default_category_list: [],
          });
        }
      }
    );
    this.onChange_filter_balancesheet();
    if(this.state.from_or_to_option == 'from') {
      this.setState({ transfer_fund_from: z,from_transfer_currency:new_currency },()=>{
        this.conditionFunc('from');
      });
    } else if(this.state.from_or_to_option == 'to') {
      this.setState({ transfer_fund_to: z,to_transfer_currency:new_currency,to_currency:new_currency },()=>{
        this.conditionFunc('to');
      });
    }
    
  };
  onChange_filter_balancesheet = () => {
    let search_key = "";
    var client_id = this.state.logged_client_id;

    // FetchAllApi.balancesheetlist_onchange(
    //   search_key,
    //   client_id,
    //   (err, response) => {
    //     if (response.status === 1) {
    //       this.setState({
    //         balancesheetlist: response.list,
    //       });
    //     } else {
    //       this.setState({
    //         balancesheetlist: [],
    //       });
    //     }
    //   }
    // );

    let filter_id = 2;
    FetchAllApi.getAllbanks(client_id,filter_id, (err, response) => {
      if (response.status === 1) {
       this.setState({
        banklist: response.data
       });
     } else {
       this.setState({
        banklist: [],
       });
     }
   }
 );

  };
  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass("minimize_leftbar");
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery("title").html("Customer | GBSC");

    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push("/");
    }
  }

  routedChange(parameter) {
    this.props.history.push("/" + parameter);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.getBanks();
    this.get_client_home_currency();

    if(localStorage.getItem("transfer_fund_transaction_number")){
      let is_create_copy_transfer_fund = localStorage.getItem("is_create_copy_transfer_fund")?localStorage.getItem("is_create_copy_transfer_fund"):'';
      this.setState({
        transfer_fund_transaction_number:localStorage.getItem("transfer_fund_transaction_number"),
        is_create_copy_transfer_fund:is_create_copy_transfer_fund
      },()=>{
        localStorage.setItem("transfer_fund_transaction_number",'');
        localStorage.setItem("is_create_copy_transfer_fund",'');
        this.getTransferFundDetailsByTransactionNumber();
      });
    }


    this.getSpecificPage(1, 10);
    this.defaultcategorylist_onchange();
    jQuery(window).on("load", function () {
      window.jQuery(".mscroll-y").mCustomScrollbar({
        axis: "y",
        scrollEasing: "linear",
        scrollInertia: 600,
        autoHideScrollbar: "true",
        autoExpandScrollbar: "true",
      });

      window.jQuery(".ib-scroll").mCustomScrollbar({
        scrollEasing: "linear",
        scrollInertia: 600,
        scrollbarPosition: "outside",
      });
    });
    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy" , autoclose: true} );
    window.jQuery(".selectpicker").selectpicker("refresh");
    jQuery(document).ready(function () {
      jQuery(".left-navmenu .has-sub").click(function () {
        jQuery(".left-navmenu li a").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(".left-navmenu li a:not(.active)")
          .siblings(".sub-menu")
          .slideUp();
        jQuery(this).siblings(".sub-menu").slideToggle();
      });
      jQuery(".left-navmenu .sub-menu li a").click(function () {
        jQuery(".left-navmenu .sub-menu li a").removeClass("active");
        jQuery(this).addClass("active");
      });
      jQuery(".search-btn").click(function () {
        jQuery(".hdr-search").addClass("active");
      });
      jQuery(".hdr-search .close-icon").click(function () {
        jQuery(".hdr-search").removeClass("active");
      });
      window.jQuery(".select-picker").selectpicker();
      jQuery(".label-enclose .label").click(function () {
        jQuery(this).toggleClass("active");
      });
      jQuery(".nav-brand-res").click(function () {
        jQuery(".left-navbar").addClass("active");
      });
      jQuery(".menu-close").click(function () {
        jQuery(".left-navbar").removeClass("active");
      });
    });
  }

  valueChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  createCopy = () => {
    localStorage.setItem("transfer_fund_transaction_number", this.state.transfer_fund_transaction_number);
    localStorage.setItem("is_create_copy_transfer_fund",1);
    let win = window.open('/transfer_funds', "_blank");
    win.focus();
  }

  getTransferFundDetailsByTransactionNumber = () => {
    fetch(config.get_transfer_funds_details, {
      method: "POST",
      body: JSON.stringify({
        client_id:this.state.logged_client_id,
        transaction_number:this.state.transfer_fund_transaction_number
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):authorization_key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('res 12', data)
        if (data.status === 1) {

          if(this.state.is_create_copy_transfer_fund == 1) {
            this.setState({
              transfer_fund_transaction_number:'',
              is_create_copy_transfer_fund:0,
              transfer_id:0
            });
    }

          let detail = data.details;
          this.setState({ 
            transfer_fund_from:detail.transfer_fund_from,
            transfer_fund_to:detail.transfer_fund_to,
            transaction_date:detail.transaction_date,
            old_transaction_date:detail.transaction_date,
            from_transfer_currency:detail.from_transfer_currency,
            transfer_amount:detail.transfer_amount,
            memo:detail.memo,
            exchange_rate:detail.exchange_rate,
            transfer_id:detail.transfer_id,
            transfer_fund_transaction_number:detail.transaction_number,
            transfer_currency:detail.from_transfer_currency,
            list_of_attachments: detail.source_attachments? detail.source_attachments:[]
           },()=>{
            let d = moment(detail.transaction_date).format("DD/MM/YYYY");
            jQuery("#transaction_date").val(d);
           });
        }
      });
  };

  voidTransferFunds =()=>{
    let input = {
      client_id:this.state.logged_client_id,
      transfer_id:this.state.transfer_id
    }
    FetchAllApi.voidTransferFunds(input, (err, response) => {
      if (response.status === 1) {
        alert(response.message);
        this.setState({
          transfer_fund_transaction_number:response.transaction_number
        },()=>{
          this.getTransferFundDetailsByTransactionNumber();
        });
      } else {
        alert(response.message);
      }
    });
  }

  fromBankChange = (e) => {
    this.state.banklist.map((from) => {
      console.log(from.id,e.target.value,from.id == e.target.value,from.currency)
      if (from.id == e.target.value) {
        this.setState({ transfer_fund_from: e.target.value, from_currency: from.currency,
           from_transfer_currency: from.currency, transfer_currency: from.currency }, () => 
           { this.get_currencies(from.currency)
            this.conditionFunc("from") })
      }
    })

  };

  toBankChange = (e) => {
    this.state.banklist.map((to) => {
      if (to.id == e.target.value) {
        this.setState({ transfer_fund_to: e.target.value, to_currency: to.currency, to_transfer_currency: to.currency, }, () => { 
          this.conditionFunc("to") })
      }
    })
  };

  changeDate = () => { 
    setTimeout(()=>{
    let date = jQuery("#transaction_date").val();
      if (date && date != undefined) {
        var array = date.split("/");
        var date_formated = array[2] + "-" + array[1] + "-" + array[0];
        if(date_formated != this.state.transaction_date) {
        this.setState({ transaction_date: date_formated },()=>{
          this.loadExchangeRate(true);
        });
      }
      }
    },1000);
  };

  // dateChange = (e) => {
  //   let date = e
  //  alert("here");
  //   let date1=jQuery("#transaction_date").val()
  //   if(date&& date!=undefined){
  //   var array = date.split('/')
  //   var date_formated = array[2] + '-' + array[1] + '-' + array[0]
  //   this.setState({ transaction_date: date_formated },()=>{})
  //   }
  // };

  currencyChange = (e) => {
    let rate = this.state.currency[e.target.value]
    // this.setState({ transfer_currency: e.target.value }, this.exchangeRate(''))
    this.setState({ transfer_currency: e.target.value },()=>{this.loadExchangeRate('')});

  };

  exchangeRate = (input) => {
    let rate = this.state.currency[this.state.to_transfer_currency]
    //let rate = input=='to'?this.state.currency[this.state.from_transfer_currency + this.state.to_transfer_currency]:this.state.currency[this.state.to_transfer_currency]
    this.setState({ exchange_rate: Number(rate.toFixed(4)) })
  };

  loadExchangeRate = (is_date_changed = false) => {

    if(this.state.transaction_date && this.state.transaction_date != '') {
    let date_formated = this.state.transaction_date;

      let todayDate = new Date();
      todayDate.setHours(23, 59, 59, 998);
   
     if(new Date(date_formated).getTime() > todayDate.getTime()) {
      if(is_date_changed == true) {
       if(this.state.from_transfer_currency == this.state.clientHomeCurrency) {
        Swal.fire(
          'You are entering a transaction for Future Date!',
        )
       } else {
        Swal.fire(
          'You are entering a transaction for Future Date!',
          "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction"
        )
       }
      }
      date_formated = moment().format("YYYY-MM-DD");
    }

    if((this.state.from_transfer_currency == this.state.clientHomeCurrency) && this.state.to_transfer_currency != '') {

      fetch(
        `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.to_transfer_currency}&source=${this.state.from_transfer_currency}`
      )
        .then((response,err) => response.json())
        .then((data) => {
          if(data.success == true){
          let newObj = this.rename(data.quotes, this.state.clientHomeCurrency)
  
          let currencyAr = [];
          let first = newObj;
          currencyAr.push({
              'date': data.start_date,
              'rate': (first[data.start_date] && first[data.start_date][this.state.from_transfer_currency + this.state.to_transfer_currency]) ? (first[data.start_date][this.state.from_transfer_currency + this.state.to_transfer_currency]).toFixed(4):parseFloat(1).toFixed(4)
          });
          let val = currencyAr[0].rate;
          newObj[this.state.clientHomeCurrency] = parseFloat(val);
  
          this.setState({
            exchange_rate: Number(parseFloat(newObj[this.state.clientHomeCurrency]).toFixed(4))
          });
        } else {
            Swal.fire({
              title: 'Error!',
              text: 'Failed to get exchange rate',
              icon: 'error'
            })
        }
    })
    .catch(err => {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to get exchange rate',
        icon: 'error'
      })
    });

    } else {
    fetch(
      `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=${this.state.from_transfer_currency}`
    )
      .then((response,err) => response.json())
      .then((data) => {
        if(data.success == true){
        let newObj = this.rename(data.quotes, this.state.clientHomeCurrency)

        let currencyAr = [];
        let first = newObj;
        currencyAr.push({
            'date': data.start_date,
            // 'rate': isNaN(first[data.start_date][this.state.from_transfer_currency + this.state.clientHomeCurrency])? 0.0000 :(first[data.start_date][this.state.from_transfer_currency + this.state.clientHomeCurrency]).toFixed(4)
            'rate': (first[data.start_date] && first[data.start_date][this.state.from_transfer_currency + this.state.clientHomeCurrency]) ? (first[data.start_date][this.state.from_transfer_currency + this.state.clientHomeCurrency]).toFixed(4):parseFloat(1).toFixed(4)
        });
        let val = currencyAr[0].rate;
        newObj[this.state.clientHomeCurrency] = parseFloat(val);

        this.setState({
          exchange_rate: Number(parseFloat(newObj[this.state.clientHomeCurrency]).toFixed(4))
        });
      } else {
        //  alert("Failed to get exchange rate");
          Swal.fire({
            title: 'Error!',
            text: 'Failed to get exchange rate',
            icon: 'error'
          })
      }
  })
  .catch(err => {
    Swal.fire({
      title: 'Error!',
      text: 'Failed to get exchange rate',
      icon: 'error'
    })
  });
}
}
  };

  getSpecificPage = (pageNumber, limitvalue, searchkey) => {
    let client_id = this.state.logged_client_id;
    let page = pageNumber;
    let limit = this.state.selectedLimit;

    let search = searchkey;

    // alert(this.state.logged_client_id)
    FetchAllApi.customer_list(
      client_id,
      page,
      limit,
      search,
      '',
      '',
      parseInt(this.state.selected_filter_id),
      (err, response) => {
        console.log("Customer_list", response);
        if (response.status === 1) {
          let customerListArray = response.list;
          //  let totalPagesCount = new Array(parseInt( response.TotalPages))
          var totalPagesCount = [];
          for (var i = 1; i <= response.TotalPages; i++) {
            totalPagesCount.push(i);
          }
          this.setState({
            customerListArray: customerListArray,
            TotalPages: totalPagesCount,
            selectedLimit: this.state.selectedLimit,
            totalPagesCount: response.TotalPages,
          });
        } else {
          this.setState({
            customerListArray: [],
            TotalPages: "",
            pgNo: "",
            totalPagesCount: "",
          });
        }
      }
    );
  };

  componentDidUpdate() {

    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy" , autoclose: true} );
    window.jQuery(".selectpicker").selectpicker("refresh");

  };

  conditionFunc = (input) => {
    // if (input == "to") {
      //this.exchangeRate('to');
    if (input == "from") {
      this.loadExchangeRate();
    }
    let from = this.state.from_currency;
    let to = this.state.to_currency;
    let fromBnk = this.state.transfer_fund_from;
    let toBnk = this.state.transfer_fund_to;
    let homeCurrency = this.state.clientHomeCurrency;
    if (fromBnk !== "" && toBnk !== '') {
      if ((fromBnk !== toBnk) && (from === to)) {
        return null
      } else
        if (from == homeCurrency || to == homeCurrency) {
          return null
        } else if(from == to) {
          return null
        }
        else {
          if (input == "from") {
            Swal.fire("The currency of the account selected must either match the Transfer Currency or be your home Currency");
            this.setState({ transfer_fund_from: "", from_transfer_currency: '' })
          } else {
            Swal.fire("The currency of the account selected must either match the Transfer Currency or be your home Currency");
            this.setState({ transfer_fund_to: "", to_transfer_currency: '' })
          }
        }
    }
  }
  clear=()=>{
    window.location.reload();
  }
  convert_date = date => {
    if (date && date != undefined) {
      var array = date.split('/')
      var date_formated = array[2] + '-' + array[1] + '-' + array[0]
    }
    return date_formated
  }
  saveFunc = (page) => {
    console.log(jQuery("#transaction_date").val())
    const source_attachment_ids = this.state.list_of_attachments.map(obj => obj.source_id);
    let input = {
      client_id: this.state.logged_client_id,
      tagged_user_id:this.state.logged_user_id,
      from_bank_id: this.state.transfer_fund_from,
      to_bank_id: this.state.transfer_fund_to,
      transaction_date: this.convert_date(jQuery("#transaction_date").val()),
      transfer_currency: this.state.transfer_currency,
      amount: Number(this.state.transfer_amount),
      // amount_in_words: toWords.convert(
      //   isNaN(Number(this.state.transfer_amount)) ? 0 : Number(this.state.transfer_amount)
      // ),
      amount_in_words: `${isNaN(Number(this.state.transfer_amount)) ? "":(config.currencies_using_word_lakh.indexOf(this.state.from_transfer_currency) >= 0)?toWords.convert(
        Number(this.state.transfer_amount)):_.startCase(_.toLower(writtenNumber(this.state.transfer_amount))
      )} only`,
      memo: this.state.memo,
      exchange_rate: this.state.exchange_rate,
      transfer_id:this.state.transfer_id,
      source_attachments: source_attachment_ids.join(','),
    }

    this.setState({ old_transaction_date: this.state.transaction_date});

    if (this.state.transfer_fund_from !== "" && this.state.transfer_fund_to !== "" && jQuery("#transaction_date").val() !== "" && this.state.transfer_currency !== "" && this.state.transfer_amount !== "" && this.state.memo ) {
      if(this.state.transfer_id && this.state.transfer_id!=undefined && this.state.transfer_id!=0){   
      FetchAllApi.update_transfer_funds(input, (err, response) => {
          if (response.status === 1) {
            alert(response.message)
            if (page == "page") {
              this.setState({transfer_fund_from:'',transfer_fund_to:'',transfer_currency:'',transfer_amount:'',
              amount_words: '',
              memo: '',
              exchange_rate: '',from_transfer_currency:'',
              transfer_id:'',
              transfer_fund_transaction_number:''
            })
            jQuery("#transaction_date").val('')
            }
            else{
              this.setState({transfer_id:response.transfer_id,transfer_fund_transaction_number:response.transaction_number})
            }
          } else if (response.message) {
            alert(response.message)
          }
        });
      }
      else{
        FetchAllApi.fund_transfer(input, (err, response) => {
          if (response.status === 1) {
            alert(response.message)
            if (page == "page") {
              this.setState({transfer_fund_from:'',transfer_fund_to:'',transfer_currency:'',transfer_amount:'',
              amount_words: '',
              memo: '',
              exchange_rate: '',from_transfer_currency:'',
              transfer_id:'',
              transfer_fund_transaction_number:''
            })
            jQuery("#transaction_date").val('')
            }
            else{
              this.setState({transfer_id:response.transfer_id,transfer_fund_transaction_number:response.transaction_number})
            }
          } else if (response.message) {
            alert(response.message)
          }
        });
      }
    }
     else {
      this.setState({ errorMsg: true })
      setTimeout(() => {
        this.setState({ errorMsg: false })
      }, 4000)
    }
  };

  get_currencies = (currency) => {
    // ${this.state.clientHomeCurrency}
    // alert(this.state.clientHomeCurrency)
    fetch(
      `https://api.currencylayer.com/live?access_key=${config.api_key}&source=${currency}`
      // `https://api.exchangerate-api.com/v4/latest/${this.state.clientHomeCurrency}`
    )
      .then((response) => response.json())
      .then((data) => {
       // let newObj = this.rename(data.quotes, this.state.clientHomeCurrency)
       let newObj = this.rename(data.quotes, currency)
        const currencyAr = []; 
        let first = newObj;
        for (const key in first) {
          currencyAr.push(key);
        }
        // this.setState({ currency_list: currencyAr, currency: first });
      })
      .catch(err => {
        console.log(err)
      });
  };


  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        console.log("Basio state", response);
        this.setState({
          clientHomeCurrency: response.currency,
        }, this.get_currencies(response.currency));
      } else {
      }
    });
  };

  getBanks = () => {
    fetch(config.getAllbanks, {
      method: "POST",
      body: JSON.stringify({
        client_id:this.state.logged_client_id,
        filter_id:2
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):authorization_key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          this.setState({ banklist: data.data });
        }
      });
  };

  logoutLink() {
    localStorage.clear();

    this.props.history.push("/");
  }

  pageLink(page_slug) {
    this.props.history.push("/" + page_slug);
  }

  render() {
    return (
      <div>
        {/* Main Wrapper Starts here */}
        <div className="container-fluid">
          <div className="row">
            {/* left-navbar Starts here */}
            <LeftSidebar history={this.props.history} pageSubmit={(e) => this.pageLink(e)} />
            <div className="menu-close visible-xs">&nbsp;</div>
            {/* left-navbar Ends here */}
            {/* MainContent Wrapper Starts here */}
            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              {/* Top bar Starts here */}
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <form className="hdr-search">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                  />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href="javascript:;" className="close-icon">
                    <img src="images/close-icon-red.svg" alt="Close" />
                  </a>
                </form>
                <div className="nav-brand-res visible-xs">
                  <img
                    className="img-responsive"
                    src="images/logo-icon.png"
                    alt="LogoIcon"
                  />
                </div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="images/back-arrow-blue.svg" onClick={() => this.props.history.goBack()} />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript:;">Banking</a>
                  </li>
                  <li>Transfer Funds</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={(e) => this.logoutLink()} />
              </div>
              {/* Top bar Ends here */}
              <div className="col-md-12 col-xs-12 mar-top visible-xs">
                <a href="javascript:;" className="back">
                  <img src="images/back-arrow-blue.svg" />
                </a>
                <span className="page-title">Transfer Funds</span>
              </div>
              {/* content-top Starts here */}
              <div className="content-top col-md-12 col-xs-12 bg-trans">
                <form className="custom-form">
                  <div className="col-md-6 col-xs-12 transfer-top">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <label>Transfer Funds From<span className="astrick">*</span></label>
                        <div className="custom-select-drop dropdown">
                          <select className="selectpicker form-control hh " data-live-search="true"
                           name="transfer_fund_from" 
                           id="balanceSheetCategeory"
                          value={this.state.transfer_fund_from} onChange={(e) => {

                            this.setState({from_or_to_option:'from'});
                            if (e.target.value == "1e") {
                              jQuery("#balanceSheetCategeory option")
                                .prop("selected", false)
                                .trigger("change");
                              jQuery("#balanceSheetCategeory").val("");
                              window.jQuery("#pop-modal").modal("show");
                            } 
                            else {
                            this.fromBankChange(e)
                            }
                            }}>
                            <option value=''>choose...</option>
                            <option value="1e"> Add New </option>
                            {this.state.banklist.map((bank) => {
                              return (
                                <option 
                                value={bank.id}
                                disabled={bank.status == 2?true:false}
                                style={{display:bank.status == 2?'none':'block'}}
                                >{bank.name}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-12 mar-b-no">
                        <label>Transfer Funds To<span className="astrick">*</span></label>
                        <div className="custom-select-drop dropdown">
                          <select className="selectpicker form-control hh " data-live-search="true" 
                          name="transfer_fund_to" value={this.state.transfer_fund_to} 
                          onChange={(e) => {
                            this.setState({from_or_to_option:'to'});
                            if (e.target.value == "1e") {
                              // jQuery("#balanceSheetCategeory option")
                              //   .prop("selected", false)
                              //   .trigger("change");
                              // jQuery("#balanceSheetCategeory").val("");
                              window.jQuery("#pop-modal").modal("show");
                            } 
                            else {this.toBankChange(e)}
                          }}>
                            <option value="">choose...</option>
                            <option value="1e"> Add New </option>
                            {this.state.banklist.map((bank) => {
                              return (
                                <option value={bank.id}>{bank.name}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
         
              {/* content-top Starts here */}
              {/* Main Content Starts here */}
              <div className="main-content col-md-12 col-xs-12 pad-t-no">
                <div className="content-sec col-md-12 col-xs-12 pad-no">
                  <form className="custom-form invoice-form">
                    <div className="row">
                      {/* <div className="form-group col-md-4">
                        <label>Transaction Date<span className="astrick">*</span></label>
                        <div
                          className="input-group date mar-t-no"
                          //data-date-format="dd/mm/yyyy"
                        >
                          <input type="text" className="form-control"
                           name="transaction_date" id="transaction_date" 
                           value={this.state.transaction_date} 
                           onBlur={(e) => {
                             alert('hi')
                            let value = e.target.value                           
                            this.dateChange(value)
                          }} 
                          />
                          <div className="input-group-addon">
                            <img src="images/calendar-icon.svg" alt="icon" />
                          </div>
                        </div>
                      </div> */}
                       <div className="form-group col-md-4">
                        <label>Transaction Date<span className="astrick">*</span></label>
                        <div
                          className="input-group date mar-t-no"
                          // data-date-format="dd/mm/yyyy"
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="transaction_date" 
                            autoComplete="off"
                            onBlur={() => this.changeDate()}
                          />
                          {/* <div className="input-group-addon"> */}
                          <div className="input-group-addon" onClick={() => jQuery('#transaction_date').focus()}>
                            <img src="images/calendar-icon.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label>Transfer Currency<span className="astrick">*</span></label>
                        <div className="custom-select-drop dropdown"  >
                          <select disabled={true} className="selectpicker form-control hh " 
                          name="transfer_currency" value={this.state.from_transfer_currency} 
                          onChange={this.currencyChange}>
                            <option value="">choose...</option>
                            {this.state.currency_list.map((amm, idx) => {
                              return (
                                <option value={amm}>{amm}</option>
                              )
                            })
                            }

                          </select>

                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label>Transfer Amount<span className="astrick">*</span></label>
                        <input type="text" className="form-control" placeholder="0"
                         name="transfer_amount" value={this.state.transfer_amount} autoComplete="off"
                          onChange={this.valueChange} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>Total Amount in Words<span className="astrick">*</span></label>
                        {/* <textarea className="form-control" name="amount_words" value={toWords.convert(
                          isNaN(Number(this.state.transfer_amount))
                            ? ""
                            : Number(this.state.transfer_amount)
                        )} onChange={this.valueChange} /> */}

                    <textarea className="form-control" name="amount_words" value={`${isNaN(Number(this.state.transfer_amount)) ? "":
                           (config.currencies_using_word_lakh.indexOf(this.state.from_transfer_currency) >= 0)?toWords.convert(Number(this.state.transfer_amount)):_.startCase(_.toLower(writtenNumber(this.state.transfer_amount)))
                        } only`} onChange={this.valueChange} />

                      </div>
                      <div className="form-group col-md-4">
                        <label>Memo</label>
                        <textarea className="form-control" name="memo" value={this.state.memo} 
                        onChange={this.valueChange} />
                      </div>
                      <div className="form-group exchange-col col-md-4">
                        <label className="mar-btm">Exchange Rate 1 {this.state.from_transfer_currency}</label>
                        <div className="w-100">
                          <input
                            autoComplete="off"
                            type="text"
                            name="exchange_rate" value={this.state.exchange_rate} onChange={this.valueChange}
                            className="form-control"
                            defaultValue="1.38"
                          />
                          {this.state.to_transfer_currency &&
                          <span className="label">{this.state.to_transfer_currency}</span>
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Main Content Ends here */}
              <div className="invoice-form">
                <div className="pf-btm-wrap xs-pad-all">
                <button
                              className='btn btn-empty ico'
                              // onClick={this.convertHtmlToPdf.bind(this)}
                              type="button"
                              onClick={() =>    window
                                .jQuery("#attachment_modal")
                                .modal("show")}
                            >
                              <img src='images/attach-icon.svg' alt='icon' />
                              Attachment <span className='blue-txt'>{this.state.list_of_attachments.length}</span>
                            </button>
                  <div className="col-md-12 col-xs-12 text-right pad-no">
                    {this.state.transfer_id?
                    //  <button className="btn btn-blue mar-rgt-5"  onClick={() => { this.voidTransferFunds() }} >Void</button>
                    <>
                    <button className="btn btn-green dropdown-toggle btn useDRP mar-rgt-5" type="button" data-toggle="dropdown">Action
                    <span className="caret" /></button>
                  <ul className="dropdown-menu category">
                    <li><a onClick={() => { this.voidTransferFunds() }}
                    >Void</a></li>
                    <li><a onClick={() => { this.createCopy() }}  >Create a copy</a></li>
                  </ul>
                  </>
                     :""
                     }
                    <button className="btn btn-lightgray mar-rgt-5"  onClick={() => { this.clear() }} >Clear</button>
                    <button className="btn btn-blue mar-rgt-5"
                    onClick={() => {
                      var urlParams = new URLSearchParams(window.location.search);
                      // var checkedIfCreated= urlParams.size > 0 ? this.state.lock_date >= this.state.transaction_date ||  this.state.lock_date <= this.state.transaction_date  : this.state.lock_date >= this.state.transaction_date ;
                      let checkedIfCreated = false;
                      if(this.state.lock_date) {
                          checkedIfCreated = this.state.transfer_id && this.state.old_transaction_date ? (this.state.lock_date >= this.state.transaction_date || (this.state.old_transaction_date <= this.state.lock_date && this.state.transaction_date >= this.state.lock_date)): this.state.lock_date >= this.state.transaction_date;
                      }
                      if (checkedIfCreated) {
                        window
                          .jQuery("#lock_date")
                          .modal("show");
                          this.setState({saveType:'page'})
                      }else{
                        this.saveFunc("page")
                      }
                     }}
                    
                    
                    >Save &amp; New</button>
                    <button className="btn btn-green mar-rgt-5" 
                     onClick={() => {
                      var urlParams = new URLSearchParams(window.location.search);
                      // var checkedIfCreated= urlParams.size > 0 ? this.state.lock_date >= this.state.transaction_date ||  this.state.lock_date <= this.state.transaction_date  : this.state.lock_date >= this.state.transaction_date ;
                      let checkedIfCreated = false;
                      if(this.state.lock_date) {
                          checkedIfCreated = this.state.transfer_id && this.state.old_transaction_date ? (this.state.lock_date >= this.state.transaction_date || (this.state.old_transaction_date <= this.state.lock_date && this.state.transaction_date >= this.state.lock_date)): this.state.lock_date >= this.state.transaction_date;
                      }
                      if (checkedIfCreated) {
                        window
                          .jQuery("#lock_date")
                          .modal("show");
                          this.setState({saveType:''})
                      }else{
                        this.saveFunc("")
                      }
                     }}
                   >Save</button>
                  </div>
                </div>
              </div>
            </div>
            {/* MainContent Wrapper Ends here */}
            {this.state.errorMsg == true ? (
              <div className='alert alert-card warning alert-dismissible fade in '>
                <a
                  href='#'
                  className='close'
                  data-dismiss='alert'
                  aria-label='close'
                  onClick={() => {
                    this.setState({ error: false })
                  }}
                >
                  ×
               </a>
                <div className='img-wrap'>
                  <img
                    className='img-responsive'
                    src='images/alert-warning.svg'
                    alt='icon'
                  />
                </div>
                <div className='alert-cont'>
                  <strong className='title'>
                    Mandatory fields must be filled!
                </strong>
                </div>
              </div>
               ) : null}
               {/* attachment_modal */}
<div
              className='modal fade pop-modal'
              id='attachment_modal'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Attachments <span className='blue-txt'>{this.state.list_of_attachments.length}</span></h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no searchscroll'>
                        {this.state.list_of_attachments.length>0 && this.state.list_of_attachments.map((item)=> <div key={item.source_id} className="attachment-preview ">
                       <a 
                       href='javascript:;'
                       onClick={() => {
                         var win = window.open(
                           `${item.source_path}`,
                           "_blank"
                         );
                         win.focus();
                       }}>{item.source_path.split('/')[5]}</a> 
                       <button type='button' className='attachment-cancel' onClick={()=>{
                         this.setState({
                           list_of_attachments: this.state.list_of_attachments.filter(x=>x.source_id!==item.source_id)
                         })
                       }}>X</button>
                       </div>)}
                      
                      </div>
                      <button
                          data-dismiss='modal'
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={()=> window.jQuery('#upload_doc_modal').modal('show')}
                        >
                          Upload attachment
                        </button>
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                       
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>


{/* upload_doc_modal */}
            <div
              className='modal fade pop-modal'
              id='upload_doc_modal'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Upload Attachments</h3>
                    <form className='custom-form row'>
                      <div className='form-group  pad-no mar-b-no'>
                         <div className=''>
                      <div className='form-group'>
                        {/* <label>Upload Attachments<span className="astrick">*</span></label> */}
                        <div className='upload-wizard'>
                          <div className='upload-thumb hide'>
                            <ul className='list-inline'>
                              {this.state.attachment_file.map(
                                (file_data, index) => {
                                  var file_url = URL.createObjectURL(file_data)
                                  var file_name = file_data.name

                                  if (file_data.type === 'application/pdf') {
                                    var blob = file_url

                                    console.log('blob_url', file_data.name)

                                    //return this.createPdfImg(file_data);

                                    return (
                                      <li className='previewImg'>
                                        <div className='thumb-img'>
                                        <a href='javascript:;' className='del-btn'
                                          onClick={() => {
                                            this.removeImage(file_data.name, index)
                                          }}
                                        >
                                          <img
                                            src='./images/close-icon-white.svg'
                                            alt='delete'
                                          />
                                        </a>
                                          <PDFtoIMG file={blob}>
                                            {({ pages }) => {
                                              console.log('check length', pages)

                                              if (!pages.length)
                                                return 'check Loading...'
                                              return pages.map((page, index) =>
                                                index == 0 ? (
                                                  <img
                                                    key={index}
                                                    src={page}
                                                    title={file_name}
                                                    alt='pdf'
                                                  />
                                                ) : (
                                                  ''
                                                )
                                              )
                                            }}
                                          </PDFtoIMG>
                                        </div>
                                        <span className='file-name'>
                                          {file_name}
                                        </span>
                                       
                                      </li>
                                    )
                                  } else {
                                    return (
                                      <li className='previewImg'>
                                        <div className='thumb-img'>
                                          <a
                                            href='javascript:;'
                                            className='del-btn'
                                            onClick={() => {
                                              this.removeImage(file_data.name)
                                            }}
                                          >
                                            <img
                                              src='./images/close-icon-white.svg'
                                              alt='delete'
                                            />
                                          </a>

                                          <img
                                            src={file_data.type==='application/vnd.ms-excel'? '/images/file-icon.svg':file_data.type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? '/images/file-icon.svg' :file_url}
                                            title={file_data.name}
                                            alt={file_data.name}
                                          />
                                        </div>
                                        <span className='file-name'>
                                          {file_data.name}
                                        </span>

                                      </li>
                                    )
                                  }
                                }
                              )}

                              <li className='addMore'>
                                <input
                                  type='file'
                                  name='imgInp[]'
                                  id='imgInp2'
                                  className='add_img'
                                  multiple
                                  onChange={this.loadFile.bind(this)}
                                  accept='image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                />
                                <a href='javascript:;' className='add-more'>
                                  <div>
                                    <img
                                      src='images/upload-icon.svg'
                                      alt='icon'
                                    />
                                    Add or Drag here
                                  </div>
                                </a>
                              </li>

                              {/* <li className="addMore">
                                <a href="javascript:;"  className="add-more" primary={false} onClick={() => {this.upload.click();}}>
                                  <div >
                                  <input id="myInput" type="file" ref={ref => (this.upload = ref)} style={{ display: "none" }}
                                      onChange={this.onChangeFile.bind(this)}/>
                                    <label htmlFor="file">{file}</label>

                                    {this.state.file ? (
                                      <PDFtoIMG file={this.state.file}>
                                        {({ pages }) => {
                                          console.log("length", pages);

                                          if (!pages.length)
                                            return "Loading...";
                                          return pages.map((page, index) => (
                                            (index==0)?
                                            <img   key={index} src={page} style={{height:104.9,width:105.9,marginTop: 13,}}  />
                                            
                                            :""
                                          ));
                                        }}
                                      </PDFtoIMG>
                                    ) : (
                                      <div>Add more</div>
                                    )}
                                    <span className="file-name">{fileName}</span>                              
                                  </div>                                  
                                </a>
                              </li> */}
                            </ul>
                          </div>
                          <div className='upload-space'>
                            <input
                              type='file'
                              name='imgInp[]'
                              id='imgInp'
                              className='add_img'
                              multiple
                              onChange={this.loadFile.bind(this)}
                              accept='image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            />
                            {/* <img id="output" src="#" alt="your image" width="70" height="50" /> */}
                            <div>
                              <img
                                className='icon'
                                src='images/upload-icon.svg'
                                alt='icon'
                              />
                              <span>.pdf .png .jpg .xls .xlsx</span>
                              <span className='note-txt'>
                                Drag and drop your files here
                                <br />
                                or
                                <br />
                                <a href='javascript:;'>click here</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.is_files_not_selected ? (
                          <div >
                            <small style={{ color: "red" }}>
                              Please select atleast one file
                            </small>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                      </div>
                      
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                        <span>{"   "}</span>
                        <button
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={()=>this.save_attachment()}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>


{/* for lock date modal */}
<div>
                          <div
                            class='modal fade in'
                            id='lock_date'
                            role='dialog'
                            style={{ paddingLeft: 15 }}
                          >
                            <div
                              class='modal-dialog modal-md'
                              style={{ width: 440 }}
                            >
                              <button
                                type='button'
                                class='close hidden-xs'
                                data-dismiss='modal'
                              >
                                <img
                                  class='img-responsive'
                                  src='../../images/close-red.svg'
                                  alt='icon'
                                />
                              </button>
                              <div class='modal-content'>
                                <div class='modal-body text-center success-modal'>
                                  <div class='pop-icon img-size'>
                                    {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                                  </div>

                                  <h3>Are you sure?</h3>

                                  <p class='fw-500'>
                                    This date is beyond lock date. To apply this
                                    date , need to verify Lock Date password
                                  </p>
                                  <button
                                    className='btn btn-lightgray btn-align'
                                    data-dismiss='modal'
                                  >
                                    Cancel
                                  </button>
                                  <span>{"   "}</span>
                                  <button
                                    class='btn btn-red btn-align'
                                    type='button'
                                    data-dismiss='modal'
                                    data-toggle='modal'
                                    data-target='#asking_password_for_delete'
                                    // onClick={this.deleteUser}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className='modal fade pop-modal'
                          id='asking_password_for_delete'
                        >
                          <div className='modal-dialog modal-xs custom-modal'>
                            {/* Modal content*/}
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                            >
                              <img
                                className='img-responsive'
                                src='images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Change Password</h3>
                                <form className='custom-form row column'>
                                  <div className='form-group col-md-12 col-xs-12'>
                                    <label>
                                      Enter Lock Password
                                      <span className='astrick'>*</span>
                                    </label>
                                    <div className='form-group login-eye'>
                                      <i
                                        className='pass-visible'
                                        toggle='#password-fieldc'
                                        onClick={() =>
                                          this.setState({
                                            show: !this.state.show,
                                          })
                                        }
                                      >
                                        <img
                                          className='off'
                                          src='images/visibility-off.svg'
                                          alt='hide'
                                        />
                                        <img
                                          className='on'
                                          src='images/visibility.svg'
                                          alt='show'
                                        />
                                      </i>
                                      <input
                                        type={
                                          this.state.show ? "text" : "password"
                                        }
                                        value={this.state.old_password}
                                        className='form-control'
                                        required='required'
                                        autocomplete='off'
                                        onChange={(e) =>
                                          this.setState({
                                            old_password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    {this.state.old_password == "" ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          please enter this password field.
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.old_password != "" &&
                                    this.state.deleteMsg == true ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          *Incorrect password
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no'>
                                    <button
                                      className='btn btn-lightgray mar-rgt-5'
                                      data-dismiss='modal'
                                    >
                                      Cancel
                                    </button>
                                    <input
                                      type='button'
                                      className='btn btn-green mar-rgt-5'
                                      value='Submit'
                                      onClick={() => {
                                        if (this.state.old_password !== "") {
                                          this.checkPassword();
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* for lock date modal */}

          </div>
        </div>
        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <Footer
            defaultcategorylist_onchange={this.defaultcategorylist_onchange}
            logoutSubmit={(e) => this.logoutLink(e)}
          />
        {/* footer Ends here */}
      </div>
    );
  }
}
export default TransferFund;
