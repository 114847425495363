import React from 'react';

import FetchAllApi from '../../api_links/fetch_all_api';

import jQuery from 'jquery';

class forgot_password extends React.Component {

    constructor(props) {
        super(props);
        //const { history } = this.props;
        this.state = { 
            logged_user_id: localStorage.getItem("logged_user_id"), 
            logged_user_email: localStorage.getItem("logged_user_email"), 
            logged_user_name: localStorage.getItem("logged_user_name"), 
            logged_user_photo: localStorage.getItem("logged_user_photo"), 
            logged_user_firstname: localStorage.getItem("logged_user_firstname"), 
            logged_user_lastname: localStorage.getItem("logged_user_lastname"),

            email_error:false,
            email:''
        };
    }

    onChange(e) {
        this.setState({
            email : e.target.value
        });
        jQuery(".alert-wrap").addClass('hide');
    }

    emailValidation(){
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!this.state.email || regex.test(this.state.email) === false){
            this.setState({
                email_error:true
            });
            return false;
        } else {
            this.setState({
                email_error:false
            });
        }
        return true;
    }

    UNSAFE_componentWillMount() {
        //console.log("logged_user_id", this.state.logged_user_id);
        jQuery('title').html('Forgot Password | GBSC');
    }


    forgotPwdSubmit(e){
        e.preventDefault();
    
        var user_email = this.state.email;
    
        if(this.emailValidation()){
          FetchAllApi.forgotPwd(user_email, (err, response) => {
              if(response.status === 1){
                jQuery(".alert-wrap").removeClass('hide').html('<p>'+response.message+'</p>');
                
                this.setState({
                    email:'',
                    email_error:false
                });

              } else{
                jQuery(".alert-wrap").removeClass('hide').html('<p>'+response.message+'</p>');
              }
          });
          
        } 
    
    }

    loginLink(e){
        e.preventDefault();    
        this.props.history.push('/');
    }



    render() {
       return (

        <div className="container-fluid">
            <div className="row">

                <div className="col-md-5 col-sm-5 login-left hidden-sm hidden-xs">
                    <h1>Welcome to <strong>GENIE</strong></h1>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
                    
                <p>
                    Cloud based accounting software with OCR, AI automation, Instant payment notification of Accounts receivable for all business contacts within genie platform.
                </p>

                 <p>
                    meaning ~NO~ hassle when it comes to Accounting anymore
                </p>
                    
                    <div className="img-wrap"><img className="img-responsive" src="../images/login-img.png" alt="" /></div>
                </div>

                <div className="col-md-7 col-sm-12 login-right">
                    <div className="login-wrap">
                    <div className="nav-brand"><img src="../images/logo-genie.png" alt="Genie"/></div>
                        <p className="lead">Forgot Password</p>
                        <div className="alert-wrap hide"><p>Email-id does not exist!</p></div>
                        
                        <form className="login-form" onSubmit={this.forgotPwdSubmit.bind(this)}>
                            <div className="form-group">
                               <input autoComplete="off" type="text" name="email" value={this.state.email} onChange={(e) => { this.onChange(e)}} placeholder="Please enter your email" className="form-control" />
                                {this.state.email_error == true ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>
                              *Please fill Valid Email id.
                                </small>
                          </div>
                        ) : (
                          ''
                        )}
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn login-btn" >Submit</button>
                                <a href="/" onClick={this.loginLink.bind(this)} className="forgot-pass">Login</a>
                            </div>
                        </form>
                        
                    </div>
                </div>
                
        
            </div>
        </div>
        
       );
    }
 }
 export default forgot_password;
