import React, { Component } from 'react'
import jQuery from 'jquery'
import DatePicker from 'react-datepicker'

import FetchAllApi from '../api_links/fetch_all_api'
import 'react-datepicker/dist/react-datepicker.css'

export default class registerPayment extends React.Component {
  constructor (props) {
    super(props)
    //const { history } = this.props;
    this.state = {
      standardAmount: 0,

      standard: 0,
      Advanced: 0,
      premium: 0,
      itemTotal: '',
      Data: [],
      months: 1,
      PrevAmount: 0,
      plan_list: [],
      selected_plan:'',
      logged_client_id: localStorage.getItem("logged_client_id"),
      sub_plan_id:'',
      days:0
    }
  }
  callMe = () => {
    // let k = this.state.standardAmount + 2.18;
    // let res = k.toFixed(2);
    // this.setState({ itemTotal: res });
  }
  calculate = e => {
    if(this.state.selected_plan) {
      this.setState({
        months: Number(e),
        standardAmount: Number(
          // Number(e.target.value) * this.state.PrevAmount
          Number(e) * this.state.selected_plan.price_per_year).toFixed(2),
          days:Number(e) * 30
      })
    }
  }
  componentDidUpdate () {
    window.jQuery('.selectpicker').selectpicker('refresh')
  }
  componentDidMount () {
    this.getData()
    var THIS = this
    jQuery(document).on('click', '.callthislist', function () {
      THIS.setState({
        standardAmount: Number(THIS.state.months * THIS.state.standard).toFixed(
          2
        ),
        PrevAmount: Number(THIS.state.months * THIS.state.standard).toFixed(2)
      })
      THIS.callMe()
    })

    jQuery(document).on('click', '.callme2', function () {
      THIS.setState({
        standardAmount: Number(THIS.state.months * THIS.state.Advanced).toFixed(
          2
        ),
        PrevAmount: Number(THIS.state.months * THIS.state.Advanced).toFixed(2)
      })
      THIS.callMe()
    })

    jQuery(document).on('click', '.callme3', function () {
      THIS.setState({
        standardAmount: Number(THIS.state.months * THIS.state.premium).toFixed(
          2
        ),
        PrevAmount: Number(THIS.state.months * THIS.state.premium).toFixed(2)
      })
      THIS.callMe()
    })

    jQuery(document).ready(function () {
      // jQuery('button').click(function () {
      //   jQuery('#paynow').addClass('hide')
      //   jQuery('#cart').removeClass('hide')
      // })

      // jQuery('a').click(function () {
      //   jQuery('#cart').addClass('hide')
      //   jQuery('#paynow').removeClass('hide')
      // })

      jQuery('.plan-list > ul > li.active .plan-detail').css('display', 'block')
      jQuery('.plan-list > ul > li').click(function () {
        jQuery(this)
          .siblings()
          .removeClass('active')
          .find('.plan-detail')
          .slideUp()
        jQuery(this)
          .addClass('active')
          .find('.plan-detail')
          .slideDown()
      })
    })
  }

  componentDidUpdate() {
  
    jQuery(document).ready(function () {

      //2022-12-14
      // jQuery('button').click(function () {
      //   jQuery('#paynow').addClass('hide')
      //   jQuery('#cart').removeClass('hide')
      // })

      // jQuery('a').click(function () {
      //   jQuery('#cart').addClass('hide')
      //   jQuery('#paynow').removeClass('hide')
      // })

      jQuery('.plan-list > ul > li.active .plan-detail').css('display', 'block')
      jQuery('.plan-list > ul > li').click(function () {
        jQuery(this)
          .siblings()
          .removeClass('active')
          .find('.plan-detail')
          .slideUp()
        jQuery(this)
          .addClass('active')
          .find('.plan-detail')
          .slideDown()
      })
    })
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  subscribePlan() {

    let today = new Date();
    let end_date = new Date(new Date().setDate(today.getDate() + this.state.days));
    if(this.state.sub_plan_id == 4) {
        end_date = new Date(new Date().setDate(today.getDate() + 365));
    }

    let input = {
      client_id : this.state.logged_client_id,
      plan_id : this.state.selected_plan.id,
      start_date : new Date(),
      end_date : end_date,
      sub_plan_id : this.state.sub_plan_id,
      subscription_amount : this.state.standardAmount,
      payment_reference_number : 'test',
    };
    FetchAllApi.subscribe_plan(input,(err, response) => {
      if (response.status === 1) {
        alert('Plan subscribed successfully');
        localStorage.setItem("is_ocr_available", response.is_ocr_available);
        this.props.history.push('/Subscription_details');
      } else {
        alert('Failed to subscribe plan');
      }
    });
  }
  
  getData = () => {
    FetchAllApi.getData((err, response) => {
      if (response.status === 1) {
        // for (let i = 0; i < response.list.length; i++) {
        //   if (response.list[i].name == 'standard') {
        //     this.setState({ standard: response.list[i].price_per_year ,standardAmount:response.list[i].price_per_year,PrevAmount:response.list[i].price_per_year})
        //   }

        //   if (response.list[i].name == 'advanced') {
        //     this.setState({ Advanced: response.list[i].price_per_year })
        //   }
        //   if (response.list[i].name == 'Premium') {
        //     this.setState({ premium: response.list[i].price_per_year })
        //   }
        // }

        let selected_plan = response.list && response.list.length > 0 ? response.list[0]:'';
        this.setState({ Data: response.subplandata,plan_list: response.list, selected_plan:selected_plan},()=>{
          if(response.subplandata && response.subplandata.length > 0) {
            this.setState({sub_plan_id:response.subplandata[0].id});
            this.calculate(response.subplandata[0].planperoid);
          }
        })
      } else {
      }
    })
  }

  render () {
    
    return (
      <div>
        <div className='container-fluid'>
          <div className='row dflex'>           
            {/* <div className='col-md-7 plan-left'> */}
            <div className='col-md-12 plan-left'>
              <div className='register-head col-md-12 col-xs-12'>
                <a href='javascript:;' className='back hidden-xs' onClick={()=>{
                  this.props.history.push('/Subscription_details')
                }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18.5'
                    height='14.249'
                    viewBox='0 0 18.5 14.249'
                  >
                    <g
                      id='left-arrow_2_'
                      data-name='left-arrow (2)'
                      transform='translate(0 -58.83)'
                    >
                      <g
                        id='Group_25'
                        data-name='Group 25'
                        transform='translate(0 65.207)'
                      >
                        <g
                          id='Group_24'
                          data-name='Group 24'
                          transform='translate(0 0)'
                        >
                          <path
                            id='Path_19'
                            data-name='Path 19'
                            d='M17.753,235.318H.747a.747.747,0,0,0,0,1.495H17.753a.747.747,0,0,0,0-1.495Z'
                            transform='translate(0 -235.318)'
                          />
                        </g>
                      </g>
                      <g
                        id='Group_27'
                        data-name='Group 27'
                        transform='translate(0 58.83)'
                      >
                        <g
                          id='Group_26'
                          data-name='Group 26'
                          transform='translate(0 0)'
                        >
                          <path
                            id='Path_20'
                            data-name='Path 20'
                            d='M1.8,65.954l5.849-5.849A.747.747,0,1,0,6.6,59.049L.219,65.426a.747.747,0,0,0,0,1.057L6.6,72.86A.747.747,0,1,0,7.653,71.8Z'
                            transform='translate(0 -58.83)'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <span>
                  You're Almost Done!
                  <br />
                  <small>Choose your plan & pay</small>
                </span>
                <div className='pull-right nav-brand'>
                  <img
                    className='img-responsive'
                    src='images/logo-genie.png'
                    alt='Logo'
                  />
                </div>
              </div>
              <div className='plan-list col-md-12 col-xs-12'>
                {/* dev-test */}
              {/* <stripe-pricing-table
         //pricing-table-id="prctbl_1MaAivSFOxS3LwZC28ACOFbj"  
         pricing-table-id="prctbl_1MdpGySFOxS3LwZCZyLXIakX"
        publishable-key="pk_test_51MZb6FSFOxS3LwZC0IRpKo68Pizz624nWI5s7WFJgIesXvHKMRKAkhZnWypKUTLw1uHA5th2dhFVJ1yh3QEVWfoa00L93hmnb8"
       client-reference-id={this.state.logged_client_id}
      >
      </stripe-pricing-table> */}

{/* prod-test */}
{/* <stripe-pricing-table 
pricing-table-id="prctbl_1MdsuuBNvCUpJhEwW9XnhxxF"
publishable-key="pk_test_51MdqmzBNvCUpJhEw8ayUB3DkumUrIxqXXUAtIvbRTc7iebyYIURUGt92AGmkAQTqGPY07XfKNvjDDOVH6rREldSO00lcMD20z8"
client-reference-id={this.state.logged_client_id}
>
</stripe-pricing-table> */}

{/* prod */}
<stripe-pricing-table 
pricing-table-id="prctbl_1NaIKOBNvCUpJhEwL86U0sDP"
publishable-key="pk_live_51MdqmzBNvCUpJhEwTfUuK74jw1czzM2RxPNYQreCiSH8txgNp9qyDsp2gVwIWnUJvn0E9fbormEFg081VEdIC3ww00UvpK7pRn"
client-reference-id={this.state.logged_client_id}
>
</stripe-pricing-table>

                {/* <ul className='list-unstyled col-md-12 col-xs-12 pad-no'>
                  {
                    this.state.plan_list && this.state.plan_list.map((item, i) => {
                      return (
                        <li key={i} className={`col-md-12 col-xs-12  ${i == 0 ? "active" : ""}`}
                        onClick={()=>{
                          this.setState({
                            selected_plan:item
                          },()=>{
                            if(this.state.Data && this.state.Data.length > 0) {
                              this.calculate(this.state.months);
                            }
                          });
                        }}>
                    <div className='plan-title'>
                      <span>
                        <img src='images/circle-tick-green.svg' alt='icon' />
                        {item.name}
                      </span>
                      <span className='price'>${item.price_per_year}</span>
                    </div>
                    <div className='plan-detail'>
                      <p>
                       This plan provides you the following features.
                      </p>
                      <ul className='list-unstyled'>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Accounts Receivables
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Accounts Payable
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Bill of Entry
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          GST Filing
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Multi Currency Handling
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Inventory Management
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Predefined User Roles
                        </li>
                        <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                          Import PDF Statement
                        </li>
                        {item.is_ocr_available ?
                          <li>
                          <img src='images/blue-tick.svg' alt='icon' />
                         OCR
                        </li>:''
                         }
                      </ul>
                      <a>
                        {' '}
                        <span className='price-box'>
                          ${item.price_per_year}
                        </span>
                      </a>
                      <span className='note'>
                        *Billed annually or ${item.price_per_year}{' '}
                        month-to-month
                      </span>
                    </div>
                  </li>
                        )
                    })
                  }
                </ul> */}
              </div>
            </div>

  {/* 2023-02-21-don't delete in any case */}
            {/* <div className='col-md-5 col-xs-12 payment-right'>
              <div className='col-md-12 col-xs-12'>

                {this.state.selected_plan ?
                <div
                  id='paynow'
                  className='cart-enclose col-md-12 col-xs-12 pad-no'
                >
                  <div className='text-center cart-head col-md-12 col-xs-12 pad-no'>
                    <img src='images/notepad-icon.svg' alt='Icon' />
                    <span>Your subscription details</span>
                  </div>
                  <table className='table cart-table'>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th className='text-center'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>                        
                          {this.state.selected_plan.name} <small>({this.state.selected_plan.is_ocr_available ?'With OCR':'Without OCR'})</small>
                          <br />
                          <select
                            className='select-dropdown'
                            onChange={(e)=> { 
                              let selectedIndex = e.target.options.selectedIndex;
                              let row_index = e.target.options[selectedIndex].getAttribute('data-row-id');
                              this.setState({sub_plan_id:row_index});
                              this.calculate(e.target.value);
                            }}
                          >
                            {this.state.Data.map(item => {
                              return (
                                <option value={item.planperoid} data-row-id={item.id}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </select>
                        </td>
                        <td className='amount text-center'>                         
                          ${this.state.selected_plan.price_per_year}
                          <small>/mo</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className='table total no-borderd'>
                    <tbody>
                      <tr>
                        <td>Item Total</td>
                        <td>$ {this.state.standardAmount}</td>
                      </tr>
                      <tr>
                        <td>Tax</td>
                        <td>$ 0.00</td>
                      </tr>
                      <tr>
                        <td>Grand Total</td>
                        <td className='grand-total'>
                          $ {this.state.standardAmount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='col-md-12 col-xs-12 text-center'>
                    <button className='btn btn-blue btn-rounded' onClick={()=>{
                      this.subscribePlan();
                    }}>                  
                      Subscribe Now
                    </button>
                  </div>
                </div>:
                  <div>
                      No plan selected
                </div>
    }

                <div id='cart' className='hide col-md-12 col-xs-12 pad-no'>
                  <div className='text-center cart-head col-md-12 col-xs-12 pad-no'>
                    <img src='images/creditcard-icon.svg' alt='Icon' />
                    <span>
                      Payable Amount{' '}
                      <span className='amount'>$ {this.state.itemTotal}</span>
                    </span>
                  </div>
                  <div className='col-md-12 col-xs-12 pad-no'>
                    <form className='custom-form pay-form'>
                      <div className='form-group'>
                        <label>Card Holder's Name</label>
                        <input
                          type='text'
                          name='c-name'
                          placeholder='Eg: John Smith'
                          className='form-control'
                        />
                      </div>
                      <div className='form-group'>
                        <label>Card Number</label>
                        <input
                          type='text'
                          name='c-num'
                          placeholder='XXXX XXXX XXXX XXXX'
                          className='form-control'
                        />
                        <div className='card-type'>
                          <img src='images/visa-icon.svg' alt='icon' />
                        </div>
                      </div>
                      <div className='col-md-12 col-xs-12 pad-no'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <label>Expiration</label>
                              <input
                                type='text'
                                name='c-exp'
                                placeholder='MM/YYYY'
                                className='form-control'
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <label>CVV / CVC</label>
                              <input
                                type='text'
                                name='c-cvv'
                                placeholder=''
                                className='form-control'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12 col-xs-12 pad-no text-center'>
                        <button className='btn btn-blue full-w'>
                          Pay Now $ {this.state.itemTotal}
                        </button>
                        <a href='javascript:;' className='text-link'>
                          <img src='images/back-arrow-small.svg' alt='icon' />
                          Back
                        </a>
                      </div>
                    </form>
                  </div>
                </div> 
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}
