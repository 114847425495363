import React from 'react';
import LeftSidebar from './../left_sidebar';
import Footer from './../footer';
import Topbar from './../topbar';
import FetchAllApi from '../../api_links/fetch_all_api';
import jQuery from 'jquery';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import moment from 'moment';
import config from './../../api_links/api_links.jsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';

var authorization_key = 'O5mGIP3VNia0JvPH2IBiwA==';
//var authorization_key = localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):'';
class SequenceCheckReport extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),

      role_permissions:
        JSON.parse(localStorage.getItem('role_permissions')) || [],

      missing_list: [],
      missed_item: '',
      acc_list: [],
      acc: '',
      table_data: [],
      start_date: '1970-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      banklist: [],
      assetList: [],
      assetTypeData: [{ asset_account: '' }],
      asset_account_list: [],
      sequencechecktype: '',
      sort_by: 'asc',
      sort_by_column_key: '',
      date_range:'Custom',
      disable_bankaccount_missed_item: false,
      bankaccount_missingitem: '',
      date_format: localStorage.getItem("date_format")?localStorage.getItem("date_format"):"YYYY-MM-DD",
      export_file_name_date_range:'All',
    };
  }

  changefromDate() {
    setTimeout(() => {
      let date = jQuery('#fromdate').val();
      if (date != undefined && date != '') {
        var array = date.split('/');
        var date_formated = array[2] + '-' + array[1] + '-' + array[0];
        this.setState({ start_date: date_formated }, () => {
          // this.table_data(this.state.missed_item);
          this.fetch_journal_details_report();
        });
      }
    }, 500);
  }
  resetcheck = () => {
    jQuery('#missingitem').attr('disabled', false);
    jQuery('#missingitem').attr('disabled', false);
  };
  printDocument=()=> {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [11.7,16.5]
    });
     var pageHeight = 0;
     pageHeight = doc.internal.pageSize.height;
    
    var y = 0.1;
    doc.setLineWidth(2);
    doc.text(7, 0.5, "Journal_report");

    let date_text = this.state.date_range == "ALL" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY");
    if(this.state.date_range == "ALL") {
      doc.text(7.0, 0.8,date_text);
    } else {
      doc.text(6.5, 0.8,date_text);
    }

    doc.autoTable({
      html: '#sequence_check_table',
      includeHiddenHtml: false,
      startY: 0.9,
  })
    //  doc.save('Journal_report-' + this.state.date_range +'.pdf');
    doc.save('Journal_report-' + this.state.export_file_name_date_range +'.pdf');
 }



  goto = (item_type, item_id, item_job_id = 0, client_id, elem) => {
    let item = this.state.missed_item;
    if (item_type == 'Sales Invoice') {
      localStorage.setItem('invoice_id', item_id);
      localStorage.setItem('job_id', item_job_id);

      var win = window.open('/create_invoice', '_blank');
      win.focus();
    } else if (item_type == 'Customer credit note') {
      localStorage.setItem('credit_id', item_id);

      window.open('/create_creditmemo?memo_id=' + item_id);
      window.focus();
    } else if (item_type == 'Quotation') {
      let setID = item_id + '=' + client_id;
      localStorage.setItem('invoice_id', setID);
      let win = window.open('/create_estimate', '_blank');
      win.focus();
    } else if (item_type == 'Sales order') {
      let setID = item_id + '=' + client_id;
      localStorage.setItem('invoice_id', setID);
      let win = window.open('/create_salesorder', '_blank');
      win.focus();
    } else if (item_type == 'Purchase order') {
      let setID = item_id + '=' + client_id;
      localStorage.setItem('invoice_id', setID);
      let win = window.open('/create_purchaseorder', '_blank');
      win.focus();
    } else if (item_type == 'Journal entry') {
      localStorage.setItem('journal_id', item_id);
      let win = window.open('/new_journal', '_blank');
      win.focus();
    } else if (item_type == 'Cheque') {
      localStorage.setItem('cheque_id', item_id);
      let win = window.open('/write_cheque', '_blank');
      win.focus();
    } else if (
      item_type == 'Batch - Bill' ||
      item_type == 'Batch - Vendor credit note' ||
      item_type == 'Batch - Cheque' ||
      item_type == 'Batch - Deposit' ||
      item_type == 'Batch - Sales Invoice' ||
      item_type == 'Batch - Customer credit note' ||
      item_type == 'Batch-Cheque' ||
      item_type == 'Batch-Deposit'
    ) {
      localStorage.setItem('batchid', elem.batch_transaction_id);
      localStorage.setItem(
        'batch_transaction_row_index',
        elem.batch_transaction_row_index
      );
      var win = window.open('/enter_batch_transaction', '_blank');
      win.focus();
    }
  };
  show_coulmn_filter = (e) => {
    var names = [];
    var result = [];
    console.log('jskdghjkg', e.target.options);

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        let value = JSON.parse(opt.value);
        result.push(value.b || opt.text);
        names.push(value.a || opt.text);
        // alert(opt.text)
        jQuery(
          'td:nth-child(' + value.b + '),th:nth-child(' + value.b + ')'
        ).show();
      } else {
        let value = JSON.parse(opt.value);
        jQuery(
          'td:nth-child(' + value.b + '),th:nth-child(' + value.b + ')'
        ).hide();
      }
    }
    this.setState({ ColscountArray: result }, this.filterFilters(names));
  };
  filterFilters = (result) => {
    let filter_key_names = [];
    this.state.coulmns_head &&
      this.state.coulmns_head !== undefined &&
      this.state.coulmns_head.map((item, i) => {
        console.log('filter_key_names', item);
        console.log('filter_key_w', result);

        if (result.includes(item.id))
          filter_key_names.push(`'${item.filter_key_name}'`);
      });
    console.log('filter_key_wq', filter_key_names);
    this.setState({ filter_key_names: filter_key_names });
    // this.callAPIDATA()
    FetchAllApi.filter_column(
      this.state.all_report_name_id,
      this.state.logged_client_id,
      filter_key_names,
      (errResponse, filtervalue) => {
        console.log('Fijkjlter Result', filtervalue);
        this.setState({ filtervalue: filtervalue });
      }
    );
  };

  changetoDate() {
    setTimeout(() => {
      let date = jQuery('#todate').val();
      if (date != undefined && date != '') {
        var array = date.split('/');
        var date_formated = array[2] + '-' + array[1] + '-' + array[0];
        this.setState({ end_date: date_formated }, () => {
          // this.table_data(this.state.missed_item);
          this.fetch_journal_details_report();
        });
      }
    }, 500);
  }

  changedatevalue(seleteddateformat) {
    var dateresult = moment();
    let from_date, to_date;

    if (seleteddateformat === 'This Month-to-date') {
      from_date = dateresult.startOf('month');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');
      document.getElementById('todate').value = moment(new Date()).format(
        'DD/MM/YYYY'
      );

      this.changeStartAndEndDate(
        from_date.format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD')
      );
    } else if (seleteddateformat === 'This Week') {
      from_date = dateresult.startOf('week');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');

      to_date = moment().endOf('week');
      document.getElementById('todate').value = to_date.format('DD/MM/YYYY');

      this.changeStartAndEndDate(
        from_date.format('YYYY-MM-DD'),
        to_date.format('YYYY-MM-DD')
      );
    } else if (seleteddateformat === 'This Month') {
      from_date = dateresult.startOf('month');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');

      to_date = moment().endOf('month');
      document.getElementById('todate').value = to_date.format('DD/MM/YYYY');

      this.changeStartAndEndDate(
        from_date.format('YYYY-MM-DD'),
        to_date.format('YYYY-MM-DD')
      );
    } else if (seleteddateformat === 'This Week-to-date') {
      from_date = dateresult.startOf('week');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');
      this.state.start_date = from_date.format('YYYY-MM-DD');
      document.getElementById('todate').value = moment(new Date()).format(
        'DD/MM/YYYY'
      );

      this.changeStartAndEndDate(
        from_date.format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD')
      );
    } else if (seleteddateformat === 'This Year') {
      from_date = dateresult.startOf('year');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');

      to_date = moment().endOf('year');
      document.getElementById('todate').value = to_date.format('DD/MM/YYYY');

      this.changeStartAndEndDate(
        from_date.format('YYYY-MM-DD'),
        to_date.format('YYYY-MM-DD')
      );
    } else if (seleteddateformat === 'This Year-to-date') {
      from_date = dateresult.startOf('year');
      document.getElementById('fromdate').value =
        from_date.format('DD/MM/YYYY');

      to_date = moment(new Date()).format('YYYY-MM-DD');
      document.getElementById('todate').value =
        moment(to_date).format('DD/MM/YYYY');
      this.changeStartAndEndDate(from_date.format('YYYY-MM-DD'), to_date);
    }
    // let startDate = jQuery('#fromdate').val()
    // let end_date = jQuery('#todate').val()
    // this.setState({ start_date: startDate, end_date: end_date }, () => {
    //     this.table_data(this.state.missed_item);

    // })
    if (seleteddateformat == 'ALL') {
      this.setState(
        {
          start_date: '1970-01-01',
          end_date: moment().format('YYYY-MM-DD'),
        },
        () => {
          // this.table_data();
          this.fetch_journal_details_report();
        }
      );
      document.getElementById('fromdate').value = '';
      document.getElementById('todate').value = '';
    }
    this.setState({date_range: seleteddateformat});
  }

  changeStartAndEndDate = (start_date, end_date) => {
    this.setState(
      {
        start_date: start_date,
        end_date: end_date,
      },
      () => {
        // this.table_data();
        this.fetch_journal_details_report();
      }
    );
  };

  missing_list = () => {
    FetchAllApi.sequence_check_option_list((err, response) => {
      if (response.status === 1) {
        this.setState({ missing_list: response.list });
      } else {
        this.setState({ missing_list: [] });
      }
    });
  };
  fetch_journal_details_report = () => {
    const input = {
      client_id: this.state.logged_client_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date
    };

    if(!this.state.date_range || this.state.date_range == 'ALL') {
      this.setState({
        export_file_name_date_range:'All'
      });
    } else if(this.state.start_date && this.state.end_date) {
      let s_date_str = moment(this.state.start_date).format(this.state.date_format);
      let e_date_str = moment(this.state.end_date).format(this.state.date_format);
      let name = s_date_str + "-TO-"+e_date_str;
      this.setState({
        export_file_name_date_range:name
      });
    }
    
    FetchAllApi.journal_details_report(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ table_data: response.details, sort_by_column_key: '' });
      } else {
        this.setState({ table_data: [], sort_by_column_key: '' });
      }
    });
  };

  // table_data = (missed_item,type = this.state.sequencechecktype) => {
  table_data = () => {
    let type = this.state.sequencechecktype;
    let missed_item = this.state.missed_item;
    let account_id = this.state.acc;
    let input = {};
    if (type == 0) {
      if (missed_item !== '') {
        input = {
          client_id: this.state.logged_client_id,
          key_name: missed_item,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          sort_by: this.state.sort_by,
          sort_by_column_key: this.state.sort_by_column_key,
        };
      }
    } else {
      input = {
        client_id: this.state.logged_client_id,
        key_name: 'account',
        // account_id:missed_item,
        account_id: account_id,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        sort_by: this.state.sort_by,
        sort_by_column_key: this.state.sort_by_column_key,
        bankaccount_missingitem: this.state.bankaccount_missingitem,
      };
    }
    FetchAllApi.sequence_check_by_type(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ table_data: response.list, sort_by_column_key: '' });
      } else {
        this.setState({ table_data: [], sort_by_column_key: '' });
      }
    });
  };

  resetAll = () => {
    this.setState(
      {
        table_data: [],
        missed_item: '',
        acc: '',
        start_date: '1970-01-01',
        end_date: moment().format('YYYY-MM-DD'),
        coulmns_head: [],
        column_headings: [],
        bankaccount_missingitem: '',
      },
      () => {
        if (this.state.original_coulmns_head) {
          this.setState(
            {
              coulmns_head: this.state.original_coulmns_head,
              column_headings: this.state.original_coulmns_head,
            },
            () => {
              console.log('coulmns_head.......295', this.state.coulmns_head);
            }
          );
        }
      }
    );
    jQuery('#fromdate').val('');
    jQuery('#todate').val('');
    jQuery('#custom').val('Custom');
  };

  getAsset = () => {
    let client_id = 226;
    FetchAllApi.settings_asset_list(client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ assetList: response.details });
      }
    });
  };

  getAssetType = () => {
    let client_id = 226;
    FetchAllApi.settings_asset_type_list(client_id, (err, response) => {
      if (response.status === 1) {
        // var response=response.list;
        //  response.map((type,idx)=>{

        //       })

        this.setState({ testarr: response.list, assetTypeData: response.list });
      }
    });
  };

  getAssetAccount = () => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.settings_account_list(client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ asset_account_list: response.list });
      }
      console.log('test', response);
    });
  };

  getBanks = () => {
    fetch(config.getAllbanks, {
      method: 'POST',
      body: JSON.stringify({
        client_id: this.state.logged_client_id,
        filter_id: 2,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: localStorage.getItem('user_access_token')
          ? localStorage.getItem('user_access_token')
          : localStorage.getItem('user_access_token')
          ? localStorage.getItem('user_access_token')
          : localStorage.getItem('user_access_token')
          ? localStorage.getItem('user_access_token')
          : authorization_key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          this.setState({ banklist: data.data });
        }
      });
  };
  get_col = () => {
    let report_id = 11;
    FetchAllApi.get_col(
      this.state.logged_client_id,
      report_id,
      (err, response) => {
        if (response.status === 1) {
          var active = [];

          console.log('response.response.....450', response.response);
          if (response.response) {
            response.response = _.filter(response.response, function (o) {
              return (
                o.clsname != 'last_modified_by' &&
                o.clsname != 'job_name' &&
                o.clsname != 'split_breakdown_string' &&
                o.clsname != 'split_breakdown_amount' &&
                o.clsname != 'balance' &&
                o.clsname != 'foreign_balance'
              );
            });
          }
          console.log('response.response.....456', response.response);

          let active_headings = response.response.map((item) => {
            if (
              item.clsname == 'balance' ||
              item.clsname == 'foreign_balance' ||
              item.clsname == 'last_modified_by'
            ) {
              item.sorting_order = 1000;
            }
            if (item.status === 1) {
              active.push(item.heading_name);
            }
          });

          let coulmns_head = response.response;

          let optionList = '';
          if (coulmns_head) {
            var options = coulmns_head.map((item, i) => {
              return <option>{item.heading_name}</option>;
            });
          }

          this.setState(
            {
              selected_vals: active,
              coulmns_head: coulmns_head,
              options: options,
              column_headings: response.response,
              original_coulmns_head: coulmns_head,
            },
            () => {
              setTimeout(() => {
                for (let k = 0; k <= coulmns_head.length; k++) {
                  // if (k == 13) {
                  //   jQuery(
                  //     "td:nth-child(" + 13 + "),th:nth-child(" + 13 + ")"
                  //   ).hide();
                  // }
                  // if (k == 18) {
                  //   jQuery(
                  //     "td:nth-child(" + 18 + "),th:nth-child(" + 18 + ")"
                  //   ).hide();
                  // }
                  // if (k == 26) {
                  //   jQuery(
                  //     "td:nth-child(" + 26 + "),th:nth-child(" + 26 + ")"
                  //   ).hide();
                  // }
                }
              }, 1000);
            }
          );
        } else {
          this.setState({
            gst_list: [],
          });
        }
      }
    );
  };
  componentDidMount() {
    this.getAssetType();
    this.missing_list();
    this.getAsset();
    this.getAssetAccount();
    this.getBanks();
    this.get_col();
    this.fetch_journal_details_report();

    if (localStorage.getItem('fiscal_start_year') != '' && localStorage.getItem('fiscal_start_year') != null && localStorage.getItem('fiscal_start_year') != undefined) {
      let start = moment(localStorage.getItem('fiscal_start_year')).format("DD-MM-YYYY");
      let end = moment(localStorage.getItem('fiscal_end_year')).format("DD-MM-YYYY");
      this.setState({ start_date: localStorage.getItem('fiscal_start_year'), end_date: localStorage.getItem('fiscal_end_year') },()=>{
        this.fetch_journal_details_report();
      });
      document.getElementById("fromdate").value = start;
      document.getElementById("todate").value = end;
      jQuery('#custom').val('Custom');
    }

    document
      .getElementById('sticky-tb-hdr')
      .addEventListener('scroll', function () {
        var translate = 'translate(0,' + this.scrollTop + 'px)';
        if (
          this.querySelector('thead') != null &&
          this.querySelector('thead') != undefined &&
          this.querySelector('thead').style != null
        ) {
          this.querySelector('thead').style.transform = translate;
        }
      });

    window
      .jQuery('.input-group.date')
      .datepicker({ format: 'dd/mm/yyyy', autoclose: true });
    window.jQuery('.selectpicker').selectpicker('refresh');

    jQuery(window).on('load', function () {
      window.jQuery('.mscroll-y').mCustomScrollbar({
        axis: 'y',
        scrollEasing: 'linear',
        scrollInertia: 600,
        autoHideScrollbar: 'true',
        autoExpandScrollbar: 'true',
      });

      window.jQuery('.ib-scroll').mCustomScrollbar({
        scrollEasing: 'linear',
        scrollInertia: 600,
        scrollbarPosition: 'outside',
      });
    });

    jQuery(document).ready(function () {
      jQuery('.left-navmenu .has-sub').click(function () {
        jQuery('.left-navmenu li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.left-navmenu li a:not(.active)')
          .siblings('.sub-menu')
          .slideUp();
        jQuery(this).siblings('.sub-menu').slideToggle();
      });
      jQuery('.left-navmenu .sub-menu li a').click(function () {
        jQuery('.left-navmenu .sub-menu li a').removeClass('active');
        jQuery(this).addClass('active');
      });
      jQuery('.search-btn').click(function () {
        jQuery('.hdr-search').addClass('active');
      });
      jQuery('.hdr-search .close-icon').click(function () {
        jQuery('.hdr-search').removeClass('active');
      });
      window.jQuery('.select-picker').selectpicker();
      jQuery('.label-enclose .label').click(function () {
        jQuery(this).toggleClass('active');
      });
      jQuery('.nav-brand-res').click(function () {
        jQuery('.left-navbar').addClass('active');
      });
      jQuery('.menu-close').click(function () {
        jQuery('.left-navbar').removeClass('active');
      });
      jQuery('.filter-btn').click(function () {
        jQuery(this).css('visibility', 'hidden');
        jQuery('.report-filter').slideDown();
      });

      jQuery('.report-filter .close-btn').click(function () {
        jQuery('.filter-btn').css('visibility', 'visible');
        jQuery('.report-filter').slideUp();
      });
    });
  }
  componentDidUpdate() {
    window.jQuery('.selectpicker').selectpicker('refresh');
    // jQuery("#currency_selected").val(4);
  }

  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass('minimize_leftbar');
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery('title').html('Customer | GBSC');

    if (
      this.state.logged_user_id === '' ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push('/');
    }
  }

  routedChange(parameter) {
    this.props.history.push('/' + parameter);
    window.scrollTo(0, 0);
  }

  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }

  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }

  render() {
    return (
      <div>
        {/* Main Wrapper Starts here */}
        <div className='container-fluid'>
          <div className='row'>
            {/* left-navbar Starts here */}
            <LeftSidebar
              history={this.props.history}
              pageSubmit={(e) => this.pageLink(e)}
            />

            {/* left-navbar Ends here */}
            {/* MainContent Wrapper Starts here */}
            <div className='main-wrap col-md-12 col-xs-12 pad-r-no'>
              {/* Top bar Starts here */}
              <div className='top-bar col-md-12 col-xs-12 pad-r-no'>
                <form className='hdr-search'>
                  <input
                    type='text'
                    className='form-control'
                    name='search'
                    placeholder='Search...'
                  />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href='javascript:;' className='close-icon'>
                    <img src='images/close-icon-red.svg' alt='Close' />
                  </a>
                </form>
                <div className='nav-brand-res visible-xs'>
                  <img
                    className='img-responsive'
                    src='images/logo-icon.png'
                    alt='LogoIcon'
                  />
                </div>
                <a
                  href='javascript:;'
                  className='back hidden-xs'
                  onClick={() => this.props.history.goBack()}
                >
                  <img src='images/back-arrow-blue.svg' />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className='list-unstyled breadcrumb page-title hidden-xs'>
                  <li>
                    <a href='javascript:;'>Accountant</a>
                  </li>
                  <li>Journal Report</li>
                </ul>
                <Topbar
                  history={this.props.history}
                  logoutSubmit={(e) => this.logoutLink()}
                />
              </div>
              {/* Top bar Ends here */}
              <div className='col-md-12 col-xs-12 mar-top visible-xs'>
                <a href='javascript:;' className='back'>
                  <img src='images/back-arrow-blue.svg' />
                </a>
                <span className='page-title'>Journal Report</span>
              </div>
              {/* content-top Starts here */}
              <div className='content-top col-md-12 col-xs-12 pad-b-no hidden'>
                <div className='col-md-12 col-xs-12'>
                  <div className='row'>
                    <form className='custom-form row'>
                      <div className='form-group col-md-4 col-sm-6 col-xs-12'>
                        <label>Choose Missing Items</label>
                        <div className='form-cont'>
                          <select
                            className='selectpicker form-group  add-new'
                            data-live-search='true'
                            title='Choose...'
                            id='missingitem'
                            value={this.state.missed_item}
                            onChange={(e) => {
                              jQuery('#bankaccount').val('');
                              jQuery('#bankaccount').attr('disabled', true);
                              this.setState(
                                {
                                  acc: '',
                                  missed_item: e.target.value,
                                  sequencechecktype: 0,
                                  disable_bankaccount_missed_item: true,
                                  bankaccount_missingitem: '',
                                },
                                () => {
                                  this.table_data();
                                }
                              );
                            }}
                          >
                            <option value=''>Choose...</option>
                            {this.state.missing_list &&
                              this.state.missing_list.map((item, index) => {
                                return (
                                  <option value={item.key_name}>
                                    {item.item}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className='form-group col-md-3 col-sm-6 col-xs-12'>
                        <label>Select Bank Account</label>
                        <div className='form-cont'>
                          <select
                            className='selectpicker form-group  add-new'
                            data-live-search='true'
                            title='Choose...'
                            id='bankaccount'
                            value={this.state.acc}
                            onChange={(e) => {
                              jQuery('#missingitem').val('');
                              jQuery('#missingitem').attr('disabled', true);
                              this.setState(
                                {
                                  missed_item: '',
                                  acc: e.target.value,
                                  sequencechecktype: 1,
                                  bankaccount_missingitem:
                                    this.state.bankaccount_missingitem == ''
                                      ? 'write_cheque'
                                      : this.state.bankaccount_missingitem,
                                },
                                () => {
                                  this.table_data();
                                }
                              );
                              // this.table_data(e.target.value,1)
                            }}
                          >
                            <option value=''>Choose...</option>
                            {this.state.banklist &&
                              this.state.banklist.map((bank, index) => {
                                return (
                                  //   <option value={item.key_name} >{item.item}</option>
                                  <option
                                    value={bank.id}
                                    disabled={bank.status == 2 ? true : false}
                                    style={{
                                      display:
                                        bank.status == 2 ? 'none' : 'block',
                                    }}
                                  >
                                    {bank.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className='form-group col-md-3 col-sm-6 col-xs-12'>
                        <label>Select Bank Transaction Type</label>
                        <div className='form-cont'>
                          <select
                            className='selectpicker form-group  add-new'
                            data-live-search='true'
                            title='Choose...'
                            id='bankaccount_missingitem'
                            value={this.state.bankaccount_missingitem}
                            disabled={
                              this.state.disable_bankaccount_missed_item
                            }
                            onChange={(e) => {
                              jQuery('#missingitem').val('');
                              jQuery('#missingitem').attr('disabled', true);
                              this.setState(
                                {
                                  missed_item: '',
                                  bankaccount_missingitem: e.target.value,
                                  sequencechecktype: 1,
                                },
                                () => {
                                  this.table_data();
                                }
                              );
                            }}
                          >
                            <option value=''>Choose...</option>
                            <option value='write_cheque'>Write Cheque</option>
                            <option value='vendor_single_payment'>
                              Vendor Single Payment
                            </option>
                            <option value='vendor_multi_payment'>
                              Vendor Multi Payment
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className='from-group col-md-1 col-sm-6 col-xs-12 lbl-btn'>
                        <button
                          className='btn btn-warning'
                          type='button'
                          onClick={() => {
                            this.resetAll();
                            jQuery('#missingitem').attr('disabled', false);
                            jQuery('#bankaccount').attr('disabled', false);
                            this.setState({
                              disable_bankaccount_missed_item: false,
                            });
                          }}
                        >
                          Reset
                        </button>
                        {/* <button className="btn btn-blue" type="button" onClick={() => { this.resetcheck }}>Reset</button> */}
                      </div>

                      {/* Generate Button no need as of Now */}
                      {/* <div className="from-group col-md-3 col-sm-6 col-xs-12 lbl-btn">
                        <button className="btn btn-blue" type="button" onClick={() => {
                          this.table_data(this.state.type == 0 ? this.state.missed_item : this.state.acc,
                            this.state.sequencechecktype)
                          jQuery("#missingitem").attr("disabled", false);
                          jQuery("#bankaccount").attr("disabled", false);
                        }}>Generate</button>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
              {/* content-top Starts here */}
              {/* Main Content Starts here */}

              <div className='main-content col-md-12 col-xs-12'>
                <div className='content-sec col-md-12 col-xs-12 pad-no mar-t-no'>
                  <div className='report-setting mar-t-no mar-btm'>
                  <span class="page-title hidden-xs">Journal Report</span>
                    <form className='custom-form form-inline'>
                      <div className='form-group mar-rgt'>
                        <label>Date Range</label>
                        <div className='form-cont'>
                          <select
                            id='custom'
                            className='selectpicker form-control hh '
                            data-live-search='true'
                            onChange={(e) =>
                              this.changedatevalue(e.target.value)
                            }
                          >
                            <option selected={true}>ALL</option>
                            <option>Custom</option>

                            <option>This Month-to-date</option>
                            <option>This Week</option>
                            <option>This Month</option>
                            <option>This Week-to-date</option>
                            <option>This Year</option>
                            <option>This Year-to-date</option>
                          </select>
                        </div>
                      </div>
                      <div className='form-group mar-rgt'>
                        <label>From</label>
                        <div
                          className='input-group date mar-t-no'
                          // data-date-format="dd/mm/yyyy"
                        >
                          <input
                            type='text'
                            id='fromdate'
                            className='form-control'
                            autoComplete='off'
                            onBlur={(e) => {
                              jQuery('#custom').val('Custom');
                              this.changefromDate();
                            }}
                          />
                          <div
                            className='input-group-addon'
                            onClick={() => jQuery('#fromdate').focus()}
                          >
                            <img src='images/calendar-icon.svg' alt='icon' />
                          </div>
                        </div>
                      </div>
                      <div className='form-group mar-rgt'>
                        <label>To</label>
                        <div
                          className='input-group date mar-t-no'
                          data-date-format='dd/mm/yyyy'
                        >
                          <input
                            type='text'
                            id='todate'
                            onBlur={() => {
                              jQuery('#custom').val('Custom');
                              this.changetoDate();
                            }}
                            className='form-control'
                            autoComplete='off'
                          />
                          <div
                            className='input-group-addon'
                            onClick={() => jQuery('#todate').focus()}
                          >
                            <img src='images/calendar-icon.svg' alt='icon' />
                          </div>
                        </div>
                      </div>
                      {/* <a
                        href='javascript:;'
                        className='text-link filter-btn mar-rgt'
                      >
                        Advanced
                      </a> */}
                      <a
                        href='javascript:;'
                        className='fa fa-refresh'
                        onClick={() => {
                          // this.table_data();
                          this.fetch_journal_details_report();
                        }}
                      >
                        <img
                          src='images/refresh.svg'
                          style={{ width: '20px' }}
                        />
                      </a>
                    </form>
                    <div className='pull-right'>
                      <div className='dropdown menu-item new-cus'>
                        <button
                          className='btn btn-green dropdown-toggle btn-arrow'
                          data-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Export
                          <span className='caret' />
                        </button>
                        <ul className='dropdown-menu align-right'>
                          <li>
                            <a
                              href='javascript:;'
                              onClick={() => {
                                this.printDocument(this.state.table_data);
                              }}
                            >
                              Export as PDF
                            </a>
                          </li>
                          <li>
                            {/* <a href="javascript:;">Export as Excel</a> */}
                            <ReactHTMLTableToExcel
                              id='test-table-xls-button'
                              className='excel-button'
                              table='sequence_check_table'
                              filename={`Journal-report-` + this.state.export_file_name_date_range}
                              sheet={`Journal-report-`+ this.state.export_file_name_date_range}
                              buttonText='Export as Excel'
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-md-12 col-xs-12 report-filter'>
                      <a href='javascript:;' className='close-btn'>
                        <img src='images/cross-red.svg' />
                      </a>
                      <form className='custom-form'>
                        <div className='col-md-12 col-xs-12 pad-l-no'>
                          <div className='row'>
                           
                            <div className='form-group col-md-3 col-xs-12 mar-b-no'>
                              <div className='row'>
                                <div className='col-md-12 col-xs-12'>
                                  <label className='fw-sbold'>
                                    Show Columns
                                  </label>
                                </div>
                                <div className='col-md-12 col-xs-12'>
                                  <div className='custom-select-drop dropdown'>
                                    {this.state.coulmns_head &&
                                      this.state.coulmns_head !== undefined && (
                                        <select
                                          className='selectpicker form-control '
                                          id='myselect'
                                          multiple
                                          data-live-search='true'
                                          onChange={(e) =>
                                            this.show_coulmn_filter(e)
                                          }
                                        >
                                          {this.state.coulmns_head &&
                                            this.state.coulmns_head !==
                                              undefined &&
                                            this.state.coulmns_head &&
                                            this.state.coulmns_head.map(
                                              (item, i) => {
                                                let statusSelected = '';
                                                if (
                                                  item.status === 1 &&
                                                  i !== 7
                                                )
                                                  statusSelected = 'selected';
                                                let object = {
                                                  a: item.id,
                                                  b: i + 1,
                                                };

                                                return (
                                                  <option
                                                    value={JSON.stringify(
                                                      object
                                                    )}
                                                    // value={i + 1}
                                                    selected={statusSelected}
                                                  >
                                                    {item.heading_name}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                      )}
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                 
                    <div className='report-table reconcile-table col-md-12 col-xs-12 pad-no mar-top mar-btm'>
                      <div className='table-responsive' id='sticky-tb-hdr'>

                     <div >
                   
                  
                        {/* new table */}
                        <table
                          className='table detail-report'
                          id='sequence_check_table'
                        >
                          <thead>
                          <th>
                          {this.state.date_range == "ALL" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY")}
                        </th>
                            <tr>
                              <th>Trans#</th>
                              <th>Type</th>
                              <th>Transaction Date</th>
                              <th>Num</th>
                              <th>Name</th>
                              <th>Memo</th>
                              <th>Account</th>
                              <th>Currency</th>
                              <th>Exchange Rate</th> 
                              <th>Debit</th>
                              <th>Credit</th>
                              <th>Foreign debit</th>
                              <th>Foreign credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.table_data &&
                              this.state.table_data.map((item) => {
                             
                                  return (
                                    <tr onDoubleClick={() => {
                                      this.goto(
                                        'Journal entry',
                                        item.journal_id,
                                        '',
                                        item.client_id,
                                        item
                                      );
                                    }}>
                                      <td className=''>
                                       {item.transaction_number}
                                      </td>
                                      <td className=''>
                                       Journal
                                      </td>
                                      <td>{moment(item.transaction_date).format(this.state.date_format)}</td>
                                      <td className=''>
                                       {item.num}
                                      </td>
                                      <td className=''>
                                       {item.person_name}
                                      </td>
                                      <td className=''>
                                       {item.descr}
                                      </td>
                                      <td className=''>
                                       {item.account_name}
                                      </td>
                                      <td className=''>
                                       {item.currency}
                                      </td>
                                      <td className=''>
                                       {item.exchange_rate}
                                      </td>
                                     
                                      {/* <td className=''>
                                      </td> */}                                    
                                       <td className=''>
                                       {item.debit}
                                      </td>

                                      <td className=''>
                                       {item.credit}
                                      </td>

                                      <td className=''>
                                       {item.foreign_debit}
                                      </td>

                                      <td className=''>
                                       {item.foreign_credit}
                                      </td>
                                    
                                      
                                    </tr>
                                  );
                                
                              })}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>
                 
                </div>
              </div>
              {/* Main Content Ends here */}
            </div>
            {/* MainContent Wrapper Ends here */}
          </div>
        </div>
        {/* Main Wrapper Ends here */}
        {/* footer Starts here */}
        <footer className='container-fluid'>
          <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
        </footer>
        {/* footer Ends here */}
      </div>
    );
  }
}
export default SequenceCheckReport;
