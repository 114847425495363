import React from "react";
import FetchAllApi from "./../../api_links/fetch_all_api";
import UserTopbar from './userTopbar'
import moment from 'moment'
import Comma from "../comma";

import jQuery from "jquery";

class SubscriptionDetails extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      
      suscriptionList:[],
      currentSubcriptionDetail:''

    };
  }

  componentDidMount(){
    this.get_subscription_list()
    this.get_current_subscription_details()

    

  }
  get_subscription_list = () => {

    let logged_client_id = this.state.logged_client_id;
    let data = {
      client_id:logged_client_id,
      page:1,
      limit:5
    };

    FetchAllApi.get_subscription_list(data, (err, response) => {
      if (response.status === 1) {
        // alert('list fetched successfully')
        this.setState({
          suscriptionList: response.list
        });
      }else{
        this.setState({
          suscriptionList: []
        });
      }
    });
  };

  get_current_subscription_details = () => {

    let logged_client_id = this.state.logged_client_id;

    FetchAllApi.get_current_subscription_details(logged_client_id, (err, response) => {
      if (response.status === 1) {
        // alert('list fetched successfully')
        this.setState({
          currentSubcriptionDetail: response.list[0]
        })
      }else{
        this.setState({
          currentSubcriptionDetail: ''
          })
      }
    });
  };


  logoutLink() {
    localStorage.clear();
    this.props.history.push("/");
  }

  cancel_subscribed_plan() {

    let input = {
      client_id : this.state.logged_client_id,
      subscription_id : this.state.currentSubcriptionDetail.id,
      stripe_subscription_id : this.state.currentSubcriptionDetail.stripe_subscription_id
    };
    FetchAllApi.cancel_subscribed_plan(input,(err, response) => {
      if (response.status === 1) {
        alert('Subscribtion canceld successfully');
        window.jQuery('#modal_cancel_subscription').modal('hide');
      } else {
        alert('Failed to cancel Subscribtion');
        window.jQuery('#modal_cancel_subscription').modal('hide');
      }
    });
  }
  
  render() {
    console.log("ftr", this.state.designationList);
    return (
      <React.Fragment>
<div>
  <div className="container-fluid">
    {/* header Starts here */}
   <UserTopbar logoutSubmit={(e) => this.logoutLink()}/>
    {/* header Ends here */}
    {/* user-content Starts here */}
    <section className="user-content row" >
      <div className="container">
        <div className="title-sec col-md-12 col-xs-12">
          <a href="javascript:;" className="back" onClick={()=>window.history.back()}>
            <img src="images/back-arrow-blue.svg" />
          </a>
          <h3>{this.state.logged_user_name} - {this.state.logged_company_name}</h3>
        </div>
        {/* Member Detail Starts here */}
        {/* <div className="col-md-12 col-xs-12 pad-no">
          <div className="row">
            <div className="col-md-6">
              <div className="alert alert-danger alert-dismissible custom-dismissible">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                <span className="alert-icon">
                  <img src="images/caution-icon.svg" alt="icon" />
                </span>
                <span className="cont">Unfortunately, Your Standard Plan is ending in 4 days <a href="javascript:;">Renew now</a></span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-12 col-xs-12 pad-no">
          <div className="row info-row">
            {/* Plan Information Starts here */}
            <div className="col-md-6 col-xs-12">
              <div className="info-widget">
                <span className="title">Plan Information</span>
                <div className="info-detail ext-pad">
                  <div className="icon-sec">
                    <img src="images/plan-icon.png" alt="icon" />
                  </div>
                  
                  {/* 2022-12-13 */}
                  {/* <div className="icon-cont">
                    <span className="icon-title">Free Trial <span className="badge green">Active</span></span>
                  </div> */}

                {this.state.currentSubcriptionDetail && this.state.currentSubcriptionDetail.plan_sort_description ?
                   <div className="icon-cont">
                     {/* <p>{this.state.currentSubcriptionDetail.plan_sort_description}</p> */}
                     <span className="icon-title">{this.state.currentSubcriptionDetail.plan_sort_description}<span className="badge green">{this.state.currentSubcriptionDetail.subscription_status_text}</span></span>
                     <p className="mar-btm">Expire on {moment(this.state.currentSubcriptionDetail.subscription_end_date).format('MMM DD, YYYY')}</p>
                    {/* <p className="mar-btm">Renews automatically on Jul 28, 2020.</p> */}
                    <button type='button' onClick={()=>this.props.history.push('/register_Payment')} className="btn btn-blue mar-rgt-5">Change Plan</button>
                    <button className="btn btn-empty" disabled={this.state.currentSubcriptionDetail.is_cancelled} 
                    onClick={()=>{ window.jQuery('#modal_cancel_subscription').modal('show'); }}
                    >Cancel Plan</button>
                  </div>:
                  <div className="icon-cont">
                  <p style={{ color: 'red' }}>Currently you have not subscribed any plan</p>
                 <button type='button' onClick={()=>this.props.history.push('/register_Payment')} className="btn btn-blue">Choose Plan</button>
               </div>
                  }

                   {/* <p>{this.state.currentSubcriptionDetail.plan_sort_description}</p>
                    <p className="mar-btm">Renews automatically on Jul 28, 2020.</p>
                    <button type='button' onClick={()=>this.props.history.push('/register_Payment')} className="btn btn-blue">Change Plan</button>
                    <button className="btn btn-empty">Cancel Plan</button> */}
                </div>
              </div>
            </div>
            {/* Plan Information Ends here */}
            {/* Payment Information Starts here */}
            <div className="col-md-6 col-xs-12">
              <div className="info-widget">
                <span className="title">Payment Information</span>
                <div className="info-detail">
                  <div className="icon-sec text-center">
                    <img src="images/credit-card-icon.svg" alt="icon" />
                  </div>
                  <div className="icon-cont">
                  <span className="icon-title" style={{ color: 'red' }}>Currently Unavailable</span>
                    {/* <span className="icon-title">$ 676.00/mo</span> */}
                    {/* <p className="mar-btm">Next payment is scheduled for Jun 28, 2020</p>
                    <button className="btn btn-blue">Manage Payment</button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Payment Information Ends here */}
          </div>
          <div className="row info-row">
            {/* Billing History Starts here */}
           
            <div className="col-md-6 col-xs-12">
<div className="info-widget">
<span className="title">Billing History
<a href="" className="text-link" onClick={()=>{
this.props.history.push("/billhistory",{client_id:this.state.logged_client_id});
}}>View All</a> 
  </span>
  <div className="info-detail">
    <div className="table-responsive col-md-12 col-xs-12 pad-no">
      <table className="billing-table">
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.state.suscriptionList && this.state.suscriptionList.map((item, i) => {
            return (
              <tr>
                <td>{moment(item.subscription_start_date).format('DD-MM-YYYY')}</td>
                <td>{moment(item.subscription_end_date).format('DD-MM-YYYY')}</td>
                <td>{item.type}</td>
                {/* <td>$ {item.subscription_amount}</td> */}
                <td>$<Comma value={item.subscription_amount}/></td>
                <td>
                  <a href={item.invoice_url} target="_blank" className="view-pdf">
                    <img src="images/pdf-outline-icon.svg" alt="icon" />
                  </a>
                </td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>

            {/* Billing History Ends here */}
            {/* Contact Information Starts here */}
            
            {/* Contact Information Ends here */}
          </div>
        </div>
        {/* Member Detail Ends here */}
      </div>
    </section>

    <div>
              <div
                class="modal fade in"
                id="modal_cancel_subscription"
                role="dialog"
                style={{ paddingLeft: 15 }}
              >
                <div class="modal-dialog modal-md" style={{ width: 440 }}>
                  <button
                    type="button"
                    class="close hidden-xs"
                    data-dismiss="modal"
                  >
                    <img
                      class="img-responsive"
                      src="../../images/close-red.svg"
                      alt="icon"
                    />
                  </button>
                  <div class="modal-content">
                    <div class="modal-body text-center success-modal">
                      <div class="pop-icon img-size">
                        {<img src="../../images/delete-icon.svg" alt="icon" />}
                      </div>

                      <h3>Are you sure?</h3>
                      <p class="fw-500">This subscription will be canceled.</p>
                      <button
                        className="btn btn-lightgray btn-align"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                      <span>{"   "}</span>
                      <button
                        class="btn btn-red btn-align"
                        type="button"
                        onClick={() => this.cancel_subscribed_plan()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    {/* user-content Ends here */}
  </div>
  <footer className="container-fluid">
    <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
  </footer>
</div>
      </React.Fragment>
    )}
    }

    export default SubscriptionDetails;
    




