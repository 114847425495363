import React from "react";
import FetchAllApi from "./../../api_links/fetch_all_api";
import UserTopbar from './userTopbar'
import moment from 'moment'

import jQuery from "jquery";

class subscriptionExpiredPage extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      
      suscriptionList:[],
      currentSubcriptionDetail:'',

      entity_number:this.props.location.state && this.props.location.state.entity_number?this.props.location.state.entity_number:"",
      view_data_expiry_date:this.props.location.state && this.props.location.state.view_data_expiry_date?this.props.location.state.view_data_expiry_date:""

    };
  }

  componentDidMount(){
    this.get_subscription_list()
    this.get_current_subscription_details()
  }
  get_subscription_list = () => {

    let logged_client_id = this.state.logged_client_id;

    FetchAllApi.get_subscription_list({client_id:logged_client_id}, (err, response) => {
      if (response.status === 1) {
        // alert('list fetched successfully')
        this.setState({
        suscriptionList: response.list
        })
      }else{
        this.setState({
          suscriptionList: []
          })
      }
    });
  };

  get_current_subscription_details = () => {

    let logged_client_id = this.state.logged_client_id;

    FetchAllApi.get_current_subscription_details(logged_client_id, (err, response) => {
      if (response.status === 1) {
        // alert('list fetched successfully')
        this.setState({
          currentSubcriptionDetail: response.list[0]
        })
      }else{
        this.setState({
          currentSubcriptionDetail: ''
          })
      }
    });
  };


  logoutLink() {
    localStorage.clear();
    this.props.history.push("/");
  }

  
  render() {
    console.log("ftr", this.state.designationList);
    return (
      <React.Fragment>
<div>
  <div className="container-fluid">
    {/* header Starts here */}
   <UserTopbar logoutSubmit={(e) => this.logoutLink()}/>
    {/* header Ends here */}
    {/* user-content Starts here */}
    <section className="user-content row" >
      <div className="container">
        <div className="title-sec col-md-12 col-xs-12">
          <a href="javascript:;" className="back" onClick={()=>window.history.back()}>
            <img src="images/back-arrow-blue.svg" />
          </a>
          <h3>{this.state.logged_user_name} - {this.state.logged_company_name}</h3>
        </div>

        <div className="col-md-12 col-xs-12 pad-no">
        <h4>UEN Number : {this.state.entity_number}</h4>
        </div>
     
        <div className="col-md-12 col-xs-12 pad-no">
          <div className="row info-row">
            {/* Plan Information Starts here */}
            <div className="col-md-6 col-xs-12">
              <div className="info-widget">
                <span className="title">Plan Information</span>
                <div className="info-detail ext-pad">
                  <div className="icon-sec">
                    <img src="images/plan-icon.png" alt="icon" />
                  </div>
                  
                  <div className="icon-cont">
                  <p style={{ color: 'red' }}>Currently you have not subscribed any plan</p>
                  <p style={{ color: 'red' }}>Read only until {this.state.view_data_expiry_date}</p>
                  <p style={{ }}>You have read only access to your data. Resubscribe anytime to pick up where you left off.</p>
                  <button className="btn btn-green mar-rgt-5" 
                    onClick={()=>{ this.props.history.push('/landing_page') }}
                    >Continue</button>
                    <button type='button' onClick={()=>this.props.history.push('/register_Payment')} className="btn btn-blue">Subscribe</button>
               </div>
                  
                </div>
              </div>
            </div>
          </div>
       
        </div>
        {/* Member Detail Ends here */}
      </div>
    </section>

    <div>
              <div
                class="modal fade in"
                id="modal_cancel_subscription"
                role="dialog"
                style={{ paddingLeft: 15 }}
              >
                <div class="modal-dialog modal-md" style={{ width: 440 }}>
                  <button
                    type="button"
                    class="close hidden-xs"
                    data-dismiss="modal"
                  >
                    <img
                      class="img-responsive"
                      src="../../images/close-red.svg"
                      alt="icon"
                    />
                  </button>
                  <div class="modal-content">
                    <div class="modal-body text-center success-modal">
                      <div class="pop-icon img-size">
                        {<img src="../../images/delete-icon.svg" alt="icon" />}
                      </div>

                      <h3>Are you sure?</h3>
                      <p class="fw-500">This subscription will be canceled.</p>
                      <button
                        className="btn btn-lightgray btn-align"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                      <span>{"   "}</span>
                      <button
                        class="btn btn-red btn-align"
                        type="button"
                        onClick={() => this.cancel_subscribed_plan()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    {/* user-content Ends here */}
  </div>
  <footer className="container-fluid">
    <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
  </footer>
</div>
      </React.Fragment>
    )}
    }

    export default subscriptionExpiredPage;
    




