import React from "react";
import LeftSidebar from "./left_sidebar";
import Footer from "./footer.jsx";
import config from "./../api_links/api_links";
import Topbar from "./topbar";
import Comma from "./comma";
import FetchAllApi from "../api_links/fetch_all_api";
// import DatePicker from "react-date-picker";
// import PDFViewer from 'pdf-viewer-reactjs'
// import HTMLReactParser from "html-react-parser";
// import parse from "html-react-parser";
import { PDFtoIMG } from 'react-pdf-to-image'
import data from "./reports/CountryCodes";
import moment from "moment";
import { ToWords } from "to-words";
import jQuery from "jquery";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CoulmnRearrage from "./coulmn_rearrange";
// import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow'
// import Select from 'react-select'
// import Loader from "react-loader-spinner";
import { isNaN } from "lodash";
import parse from "html-react-parser";
import writtenNumber from "written-number";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import {
  EditorState,
  convertToRaw,
  ContentState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

writtenNumber.defaults.lang = "en-US";

var _ = require("lodash");
var authorization_key = "O5mGIP3VNia0JvPH2IBiwA==";
//var authorization_key = localStorage.getItem("user_access_token")?localStorage.getItem("user_access_token"):'';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const toWords_for_lakh_currencies = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: true,
    doNotAddOnly: true,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    },
  },
});

// import 'bootstrap';
// import 'bootstrap-select';
class invoice_template_listing extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;



    this.state = {
      manualLoading: true,

      next_invoice_number_loaded: false,
      customer_list_loaded: false,
      due_date_terms_loaded: false,
      sub_account_list_loaded: false,

      amount_entered: "",
      isClose: false,
      accounts_selected: "",
      accounts_dropdown: [],
      current_val: 0,
      payment_desc: "",
      subTotal: 0.0,
      name: "Still, Jaime",
      rank: "SGT",
      description: "Demonstrate how to export an HTML section to PDF",
      html_content: "",
      dataUrl: "",
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      logged_subscription_start_date: localStorage.getItem(
        "logged_subscription_start_date"
      ),
      logged_subscription_end_date: localStorage.getItem(
        "logged_subscription_end_date"
      ),
      logged_plan_id: localStorage.getItem("logged_plan_id"),
      lock_date: localStorage.getItem("lock_date"),

      country_sortname: localStorage.getItem("country_sortname"),
      language_code: localStorage.getItem("language_code"),
      home_currency_symbol: localStorage.getItem("home_currency_symbol"),
      home_currency: localStorage.getItem("home_currency"),
      hasId: localStorage.getItem('invoice_id'),
      old_transaction_date:'',
      old_payment_date:'',

      dropdown: "",
      cate_id: "",
      inbox_list: [],
      response_stus: 0,
      response_msg: "No data found",
      item_details: "",
      item_file_path: "",
      list_id: "",
      response: [],
      htmlcont: "",
      selected_templateName: "Choose",
      active: 0,
      properties_values: "",
      date: new Date(),
      customer_list: "",
      selected_customer_name: "",
      active_selected: 0,
      currency_customer: "USD",

      /* =================== */
      isChecked: false,
      selected: "",
      selectedindex: "",
      gst_list: [],
      sales_tax_name: "Zero-rated supplies",
      sales_tax_rate: 0,
      sales_tax_type: 1,
      search_key_gst: "",
      selected_rate_type: "%",
      maximum_chr_lngth: 4,
      sales_tax_code: "",
      rate_entered: "",
      salesTax_name_entered: "",
      rows: ["row 1"],
      coulmns: [],
      myarray: [],
      mysalesarray: [],
      myestimatearray: [],
      // currencies: [],
      currencies: config.all_currency_list,
      item_total_home_currency: "",
      tax_amount_foreign_currency: "",
      grand_total_foreign_currency: "",
      grand_total_home_currency: 0.0,
      sub_total_home_currency: 0.0,
      tax_home_currency: 0.0,
      item_total_foreign_currency: "",
      ToCurrency: "USD",
      isTable_notEmpty: false,
      category_id: "",
      specific_id_delete: "",
      initial_value: 0,
      isAdd: false,
      isInvoice_to: false,
      isShippingt_to: false,
      isThanking_msg: false,
      isTerms_cond_msg: false,
      isInvoice_no: false,
      isjob_name: false,
      isTablefilled: false,
      isCustomername: false,
      isTemplate_selected: false,
      isMobileno: false,
      isRejected: false,
      reject_msg: "",

      Basic_info: [],
      billing_address: "",
      shipping_address: "",
      customerjoblist: [],
      selectCustomer: "",

      sales_tax_code: "",
      salesTax_name_entered: "",
      // selectedOption: "option2",
      modal_info_msg: "",
      show_succes: false,
      grandTotal: "",
      job_id: "",
      job_name: "",
      cus_rate_rate: "",
      exchange_value_ref: "",
      selectedColumnType: "",
      selectedOption: "option2",
      coulmns: [],
      payment_method_list: [],
      payment_date: "",
      sales_product_item_list: [],
      opt: "",
      default_category_list: [],
      // currencies: [],

      isEditCol: false,
      items: [],
      // CurrentSelectedCurr: "USD",
      CurrentSelectedCurr: "",
      duplicateVar: "",
      balancesheetlist: [],
      number_of_columns_list: [],
      isInvoiceCurrency: false,
      isTemplate: false,
      isCustomer: false,
      isInvoiceDate: false,
      isDueDate: false,
      isJobName: false,
      isPayable_amount: false,
      isPaymetDate: false,
      isPaymentMethod: false,
      isbalanceSheetcatId: false,
      // currencies: [],
      SubAccountList: [],
      isAccounId: false,
      customerID: localStorage.getItem("customerId"),
      JobId: "",
      isThirdPartyName: false,
      third_party_account_list: [],
      selectNeedIndex: "empty",
      nameFilter: "emp",
      isAlreadyTaken: false,
      ExistMsg: "",
      ItemList: [],
      isInvoiceEditable: false,
      is3partyAccRequired: false,
      currInvoiceId: "",
      currpayment_id: "",
      invoice_id_used: "",
      customer_id:
        localStorage.getItem("customer_id") != undefined
          ? localStorage.getItem("customer_id")
          : "0",
      myCusVarPay: "",
      newlyCreatedID: "",
      paymentGRTR: 0,

      // clientHomeCurrency: "",
      clientHomeCurrency:localStorage.getItem('home_currency'),
      paid_status: "",

      third_party_value: "",
      third_party_type: 0,
      loading: true,
      payement_table: [],
      estimate_id: "",
      sales_order_id: "",
      delete_alert_msg: "",
      total_amt: 0,
      total_avail: 0,
      total_used_credit: 0,
      total_remain_credit: 0,
      selected_due_date: "",
      is_void: false,
      product_item_rate: "",
      // customField: [
      //   { type: 'text', name: 'text field 1', options: [], key: '', value: '10' },
      //   { type: 'dropdown', name: 'dropdown field 1', options: [], key: '', value: '20' },
      //   { type: 'date', name: 'date field 1', options: [], key: '', value: '30' }
      // ],
      customFields: "",
      is_need_to_change_customer: false,
      pmt_applied_foreign_currency: 0,
      pmt_applied_home_currency: 0,
      pmt_exchange_gain_or_loss: 0,
      pmt_balance_due_foreign_currency: 0,
      pmt_balance_due_hom_curency: 0,

      is_date_changed_once: false,
      is_exchange_rate_changed_once: false,

      values: [],
      //       html_contents :      `<div
      //       style="
      //         color: #6a6a6a;
      //         background: #fff;
      //         min-height: 98%;
      //         border: 1px solid #c7c7c7;
      //         width: 95%;
      //         margin: 0 auto;
      //         font-size: 14px;
      //         display: block;
      //         overflow: hidden;
      //       ">
      //       <div style="float: left; width: 100%; padding: 25px; box-sizing: border-box">
      //         <div
      //         id="isLogo"
      //           style="
      //             float: left;
      //             width: 35%;
      //             height: 73px;
      //             display: flex;
      //             align-items: center;
      //           "
      //         >
      //         <img id="image" style="max-width:100%;max-height:100%;" src="" alt="logo"/>
      //         </div>
      //         <div id="mobDiv" style="float: right; text-align: right; width: 42%">
      //            <p id="isEntityName" style="margin-bottom: 0 ;display: block">[Entity name]</p>
      //            <p id="isAddress" style="margin-bottom: 0 ;display: block">[Address]</p>
      //            <p id="isPhoneNumber" style="margin-bottom: 0 ;display: block">[Phone number]</p>
      //            <p id="isEmail" style="margin-bottom: 0 ;display: block">[Email]</p>
      //            <p id="isUenNumber" style="margin-bottom: 0 ;display: block">[UEN number]</p>
      //            <p id="isGstNumber" style="margin-bottom: 0 ;display: block">[GST registration number]</p>
      //         </div>
      //       </div>
      //       <div id="stripeDiv" style="background: #e6e6e6; height: 18px; float: left; width: 100%">
      //         <span
      //         id="template_name"
      //           style="
      //             color: #6a6a6a;
      //             background: #fff;
      //             font-size: 20px;
      //             font-weight: 600;
      //             text-transform: uppercase;
      //             float: right;
      //             margin: -3px 25px 0 0;
      //             padding: 0 18px;
      //           "
      //           >INVOICE</span
      //         >
      //       </div>
      //       <div style="float: left; width: 100%; padding: 25px; box-sizing: border-box">
      //         <div style="float: left">
      //           <div style="float: left; width: 100%">
      //           <div id="headDiv">
      //             <div style="float: left; width: 33%">
      //               <p style="margin-bottom: 8px; font-weight: 600">Invoice to</p>
      //               <p>
      //                 [customer name] <br />
      //                 [company address]
      //               </p>
      //             </div>
      //             <div style="float: left; width: 33%">
      //               <p style="margin-bottom: 8px; font-weight: 600">Shipping to</p>
      //               <p>
      //                 [customer name] <br />
      //                 [shipping address]
      //               </p>
      //             </div>
      //             <div style="float: right; width: 34%; text-align: right">
      //               <p style="margin-bottom: 7px">
      //                 <span style="font-weight: 600">Invoice No:</span>100230
      //               </p>
      //               <p style="margin-bottom: 0">
      //                 <span style="font-weight: 600">Date:</span> 10 May 2021
      //               </p>
      //               <p style="margin-bottom: 0">
      //                 <span style="font-weight: 600">Duedate:</span> 18 May 2021
      //               </p>
      //             </div>
      //             <div style="float: left; width: 100%">
      //               <div style="float: left; width: 20%">
      //                 <p style="margin-bottom: 8px; font-weight: 600">Invoice Currency</p>
      //                 <p>[Your invoice currency]</p>
      //               </div>
      //               <div style="float: left; width: 20%">
      //                 <p style="margin-bottom: 8px; font-weight: 600">Job Name</p>
      //                 <p>[Your job name]</p>
      //               </div>
      //               <div style="float: left; width: 20%">
      //                 <p style="margin-bottom: 8px; font-weight: 600">Memo</p>
      //                 <p>[Your memo]</p>
      //               </div>
      //               <div style="float: left; width: 20%">
      //                 <p style="margin-bottom: 8px; font-weight: 600">Exchange Rate</p>
      //                 <p>00.00</p>
      //               </div>
      //               <div style="float: left; width: 20%; display:none" id="customField0">
      //               <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName0">Exchange Rate</p>
      //               <p>[Field value]</p>
      //             </div>
      //             <div style="float: left; width: 20%; display:none" id="customField1">
      //             <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName1">Exchange Rate</p>
      //             <p>[Field value]</p>
      //           </div>
      //           <div style="float: left; width: 20%; display:none" id="customField2">
      //           <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName2">Exchange Rate</p>
      //           <p>[Field value]</p>
      //         </div>
      //         <div style="float: left; width: 20%; display:none" id="customField3">
      //         <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName3">Exchange Rate</p>
      //         <p>[Field value]</p>
      //       </div>
      //       <div style="float: left; width: 20%; display:none" id="customField4">
      //       <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName4">Exchange Rate</p>
      //       <p>[Field value]</p>
      //     </div>
      //     <div style="float: left; width: 20%; display:none" id="customField5">
      //     <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName5">Exchange Rate</p>
      //     <p>[Field value]</p>
      //   </div>
      //   <div style="float: left; width: 20%; display:none" id="customField6">
      //   <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName6">Exchange Rate</p>
      //   <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none" id="customField7">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName7">Exchange Rate</p>
      // <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none" id="customField8">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName8">Exchange Rate</p>
      // <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none" id="customField9">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName9">Exchange Rate</p>
      // <p>[Field value]</p>
      // </div>
      //             </div>
      //           </div>
      //           <div id="itemDiv">
      //             <div style="width: 100%; float: left; min-height: 58.2%">
      //               <table style="width: 100%; float: left; margin-top: 25px">
      //                 <thead id="thead" style="background: #5e5e5e; border: 1px solid #5e5e5e">
      //                   <tr style="color: #fff; font-weight: 500">
      //                     <td style="padding: 12px 8px; box-sizing: border-box">No</td>
      //                     <td style="padding: 12px 8px; box-sizing: border-box">
      //                       Item & Description
      //                     </td>
      //                     <td style="padding: 12px 8px; box-sizing: border-box">
      //                       Price Each
      //                     </td>
      //                     <td
      //                       style="
      //                         padding: 12px 8px;
      //                         text-align: center;
      //                         box-sizing: border-box;
      //                       "
      //                     >
      //                       Qty
      //                     </td>

      //                     <td
      //                       style="
      //                         padding: 12px 8px;
      //                         text-align: center;
      //                         box-sizing: border-box;
      //                       "
      //                     >
      //                       Tax
      //                     </td>
      //                     <td
      //                       style="
      //                         padding: 12px 8px;
      //                         text-align: center;
      //                         box-sizing: border-box;
      //                       "
      //                     >
      //                       Total
      //                     </td>
      //                   </tr>
      //                 </thead>
      //                 <tbody style="border: 1px solid #bababa">
      //                     <tr>
      //                     <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">01</td>
      //                     <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">Lorem ipsum dolor sit amet</td>
      //                     <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">00.00</td>
      //                     <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00</td>
      //                     <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">0.0%</td>
      //                     <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00.00</td>
      //                     </tr>
      //                     <tr>
      //                         <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">02</td>
      //                         <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">Lorem ipsum dolor sit amet</td>
      //                         <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">00.00</td>
      //                         <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00</td>
      //                         <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">0.0%</td>
      //                         <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00.00</td>
      //                         </tr>
      //                         <tr>
      //                             <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">03</td>
      //                             <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">Lorem ipsum dolor sit amet</td>
      //                             <td style="font-size:13px;padding:12px 8px;box-sizing:border-box;">00.00</td>
      //                             <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00</td>
      //                             <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">0.0%</td>
      //                             <td style="font-size:13px;padding:12px 8px;text-align:center;box-sizing:border-box;">00.00</td>
      //                             </tr>
      //                 </tbody>
      //               </table>
      //             </div>
      //             <div style="float: left; width: 65%; margin-top: 60px">
      //               <p style="margin-bottom: 5px; font-weight: 500">Amount in Words</p>
      //               <div
      //                 style="
      //                   border: 1px solid #bababa;
      //                   float: left;
      //                   width: 100%;
      //                   padding: 13px 10px;
      //                   box-sizing: border-box;
      //                 "
      //               >
      //               Amount in Words
      //               </div>
      //             </div>
      //             <div
      //               style="
      //                 float: right;
      //                 width: 35%;
      //                 padding-left: 20px;
      //                 box-sizing: border-box;
      //               "
      //             >
      //               <div
      //                 style="
      //                   float: left;
      //                   width: 100%;
      //                   padding: 13px 10px;
      //                   margin-top: 10px;
      //                   box-sizing: border-box;
      //                 "
      //               >
      //                 <div
      //                   style="
      //                     float: left;
      //                     width: 50%;
      //                     font-weight: 600;
      //                     text-align: right;
      //                     padding-right: 10px;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                   0.00
      //                 </div>
      //                 <div
      //                   style="
      //                     text-align: right;
      //                     padding: 0 10px;
      //                     float: right;
      //                     width: 50%;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                 0.00
      //                 </div>
      //               </div>
      //               <div
      //                 style="
      //                   float: left;
      //                   width: 100%;
      //                   padding: 13px 10px;
      //                   box-sizing: border-box;
      //                 "
      //               >
      //                 <div
      //                   style="
      //                     float: left;
      //                     width: 50%;
      //                     font-weight: 600;
      //                     text-align: right;
      //                     padding-right: 10px;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                 0.00
      //                 </div>
      //                 <div
      //                   style="
      //                     text-align: right;
      //                     padding: 0 10px;
      //                     float: right;
      //                     width: 50%;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                 0.00
      //                 </div>
      //               </div>
      //               <div
      //                 style="
      //                   float: left;
      //                   width: 100%;
      //                   padding: 15px 10px;
      //                   background: #efefef;
      //                   border-radius: 3px;
      //                   box-sizing: border-box;
      //                 "
      //               >
      //                 <div
      //                   style="
      //                     float: left;
      //                     width: 50%;
      //                     font-weight: 600;
      //                     text-align: right;
      //                     padding-right: 10px;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                   Grand Total
      //                 </div>
      //                 <div
      //                   style="
      //                     text-align: right;
      //                     padding: 0 10px;
      //                     float: right;
      //                     width: 50%;
      //                     box-sizing: border-box;
      //                   "
      //                 >
      //                   0.00
      //                 </div>
      //               </div>
      //             </div>
      //             </div>
      //           </div>
      // <div id="footerCustomDiv">
      //           <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField10">
      //               <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName10">Footer field-1</p>
      //               <p>[Field value]</p>
      //             </div>
      //             <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField11">
      //             <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName11">Footer field-2</p>
      //             <p>[Field value]</p>
      //           </div>
      //           <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField12">
      //           <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName12">Footer field-3</p>
      //           <p>[Field value]</p>
      //         </div>
      //         <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField13">
      //         <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName13">Footer field-3</p>
      //         <p>[Field value]</p>
      //       </div>
      //       <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField14">
      //       <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName14">Footer field-4</p>
      //       <p>[Field value]</p>
      //     </div>
      //     <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField15">
      //     <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName15">Footer field-5</p>
      //     <p>[Field value]</p>
      //   </div>
      //   <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField16">
      //   <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName16">Footer field-6</p>
      //   <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField17">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName17">Footer field-7</p>
      // <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField18">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName18">Footer field-8</p>
      // <p>[Field value]</p>
      // </div>
      // <div style="float: left; width: 20%; display:none ; margin-top: 25px" id="customField19">
      // <p style="margin-bottom: 8px; font-weight: 600" id="customFieldName19">Footer field-9</p>
      // <p>[Field value]</p>
      // </div>
      // </div>
      //           <div
      //           id="footerDiv"
      //             style="
      //               float: left;
      //               width: 100%;
      //               margin-top: 30px;
      //               padding: 0 0 25px 0;
      //               box-sizing: border-box;
      //             "
      //              >
      //             <p style="margin-bottom: 5px; font-weight: bold">Thanking Message</p>
      //             <p style="margin-top: 0; margin-bottom: 15px">Thank you message and Banking details</p>
      //             <div style="float: left; width: 100%">
      //               <p style="margin-bottom: 7px; font-weight: 600">Terms & Conditions</p>
      //               <p style="font-size: 12px; margin-bottom: 0">
      //                 Sed ut perspiciatis unde omnis iste natus error sit voluptatem
      //                 accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
      //                 quae ab illo inventore
      //               </p>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //  `,
      html_contents: "",
      invoice_details: "",
      // invoice_details :
      // {
      //   "status": 1,
      //   "message": "Invoice details fetched successfully",
      //   "invoice_details": {
      //     "id": 677,
      //     "client_id": 152,
      //     "item_total_home_currency": 430.37,
      //     "tax_amount_home_currency": 19.63,
      //     "grand_total_home_currency": 450,
      //     "invoice_date": "2021-04-26",
      //     "company_name": "mano",
      //     "created_on": "2021-07-04 06:08:54",
      //     "type": 2,
      //     "invoice_number": "SI-9",
      //     "foreign_currency": "SGD",
      //     "company_address": "inv to",
      //     "item_total": 0,
      //     "list_id": 1,
      //     "status": 1,
      //     "tagged_user_id": 1,
      //     "item_total_foreign_currency": 430.37,
      //     "tax_amount_foreign_currency": 19.63,
      //     "grand_total_foreign_currency": 450,
      //     "exchange_rate": 1,
      //     "balance_sheet_category": 0,
      //     "shipping_address": "shipp to",
      //     "job_name": "Others",
      //     "memo": "memo line",
      //     "thanking_message": "thank msg",
      //     "terms_and_conditions": "term con",
      //     "customer_id": 18,
      //     "template_id": 112,
      //     "job_id": 247,
      //     "due_date": "2021-04-26",
      //     "terms": null,
      //     "open_balance_home_currency": 379,
      //     "open_balance_foreign_currency": 379,
      //     "vendor_id": null,
      //     "payment_status": 0,
      //     "type_name": null,
      //     "lastmodified": "2021-07-04 06:08:54",
      //     "lastmodifiedby": "",
      //     "account": "16668",
      //     "debit": 450,
      //     "credit": 0,
      //     "ship_date": null,
      //     "purchase_order_no": 0,
      //     "foreign_debit": 450,
      //     "foreign_credit": 0,
      //     "updated_on": "2021-07-04 06:08:54",
      //     "amount_in_words": "Four Hundred Fifty",
      //     "tax_inclusive": 1,
      //     "file_id": "0",
      //     "transaction_number": 58,
      //     "is_unapplied_credit_memo": 0,
      //     "payment_reference_number": null,
      //     "is_reconcile": 0,
      //     "description": "",
      //     "batch_transaction_id": 0,
      //     "default_category": null,
      //     "group_accounting_sales_invoice_id": 0,
      //     "multi_payment_applied_invoices": null,
      //     "due_date_term": 1,
      //     "employee_id": 0,
      //     "other_staff_id": 0,
      //     "batch_transaction_row_index": 0,
      //     "invoice_details": [
      //       {
      //         "item_name": "ee",
      //         "descr": "derf",
      //         "quantity": "10",
      //         "price": 0,
      //         "unit_price": "5",
      //         "category_id": "13585",
      //         "tax_name": "Choose ",
      //         "tax_rate": "",
      //         "tax_type": "",
      //         "item_tax": "0.00",
      //         "item_total": 0,
      //         "home_item_total": "0.00",
      //         "custom_column": [{ "man": "1" }, { "man": "2" }, { "man": "4" }],
      //         "man": "1",
      //         "tax_code": null,
      //         "debit": 0,
      //         "credit": "0.00"
      //       },
      //       {
      //         "item_name": "ee",
      //         "descr": "derf",
      //         "quantity": "20",
      //         "price": 0,
      //         "unit_price": "5",
      //         "category_id": "14168",
      //         "tax_name": "Choose ",
      //         "tax_rate": "",
      //         "tax_type": "",
      //         "item_tax": "0.00",
      //         "item_total": 0,
      //         "home_item_total": "0.00",
      //         "custom_column": [{ "man": "1" }, { "man": "2" }, { "man": "4" }],
      //         "man": "2",
      //         "tax_code": null,
      //         "debit": 0,
      //         "credit": "0.00"
      //       },
      //       {
      //         "item_name": "ee",
      //         "descr": "derf one",
      //         "quantity": "30",
      //         "price": 280.3738317757009,
      //         "unit_price": "10",
      //         "category_id": "13585",
      //         "tax_name": "7% GST",
      //         "tax_rate": "7",
      //         "tax_type": "1",
      //         "item_tax": "19.63",
      //         "item_total": 280.3738317757009,
      //         "home_item_total": "280.37",
      //         "custom_column": [{ "man": "1" }, { "man": "2" }, { "man": "4" }],
      //         "man": "4",
      //         "tax_code": "7%",
      //         "debit": 0,
      //         "credit": "280.37"
      //       }
      //     ],
      //     "payments_applied_foreign_currency": "71.00",
      //     "payments_applied_home_currency": "71.00",
      //     "exchange_gain_or_loss": 0,
      //     "paid_status": "Partially paid",
      //     "payment_id": 0,
      //     "payment_date": "",
      //     "payment_method": "",
      //     "payment_amount": 0,
      //     "payment_exchange_rate": "",
      //     "thrird_party_account_id": "",
      //     "third_party_type": "",
      //     "third_party_account_name": "",
      //     "reference": "",
      //     "descripation": "",
      //     "custom_header_fields": {
      //       "text1": "text1",
      //       "drop1": "custom drop 1",
      //       "date1": "11/12/2021",
      //       "your_extra_data": [
      //         ["text1", "custom drop 1", "11/12/2021"],
      //         ["foo text", "foo drop", "21/11/2011"]
      //       ]
      //     },
      //     "custom_footer_fields": {
      //       "text2": "foo text",
      //       "drop2": "foo drop",
      //       "date": "21/11/2011"
      //     }
      //   },
      //   "paymentDetails": [
      //     {
      //       "id": 58,
      //       "invoice_id": 677,
      //       "created_date": "2021-07-04 06:08:54",
      //       "payment_type": "SinglePayment",
      //       "exchange_rate": 1,
      //       "total_payment_home_currency": 71,
      //       "total_payment_foreign_currency": 71,
      //       "customer_id": 18,
      //       "job_id": 247,
      //       "client_id": 152,
      //       "payment_date": "2021-07-04",
      //       "open_balance_home_currency": "0",
      //       "payor_name": "",
      //       "descripation": "pay desc",
      //       "third_party_account_name": "",
      //       "reference_number": "pay ref",
      //       "ar_account": "13522",
      //       "third_party_account_id": null,
      //       "updated_date": "2021-07-04 06:08:54",
      //       "is_bank_reconcile": 0,
      //       "status": 0,
      //       "bank_id": null,
      //       "third_party_type": null,
      //       "initial_payment": 1,
      //       "exchange_gain_or_loss": 0,
      //       "memo": null,
      //       "type": 1,
      //       "transaction_number": 59,
      //       "is_multipayment": 0,
      //       "multi_payment_applied_invoices": null,
      //       "open_balance_foreign_currency": null,
      //       "unapplied_payment_invoices_account": 0
      //     }
      //   ]
      // }
      is_deleted:0,
      is_credit_limit_accepted: 0,
      old_password: "",
      notification_id: "",
      editorState: EditorState.createEmpty(),
      thankingMessageEditorState: EditorState.createEmpty(),
      invoiceToEditorState: EditorState.createEmpty(),
      gst_vat_currency: localStorage.getItem("gst_vat_currency"),

      selectedFile: '',
      succ_msg: '',
      attachment_file: [],
      attachment_file_length: 0,
      attachment_fileName: [],
      imgThumb: '',
      pages: [],
      list_of_attachments: [],

      is_invalid_subject: false,
      is_invalid_message: false,
      is_files_not_selected: false,
      template_default_fields:[],
      lock_date_error_message:'This date is beyond lock date. To apply this date , need to verify Lock Date password'
    };
    this.loadFile = this.loadFile.bind(this)
  }

  removeImage = (e, index) => {
    const updated = this.state.attachment_file.filter(item => item.name !== e);
    this.setState(
      {
        attachment_file: updated
      },
      () => {
        if (this.state.attachment_file.length === 0) {
          // Reset file input value when no files are selected
          document.getElementById('imgInp').value = '';
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            opacity: 1
          });
          jQuery('.upload-thumb').addClass('hide');
        }
      }
    );
  };


  loadFile(e) {
    var files = e.target.files
    this.setState({ attachment_file_length: files.length })
    console.log(files,'res prev')
    if (files.length > 0) {
      // jQuery('.upload-space').addClass('hide')
      jQuery('.upload-space').css({
        "opacity": 0
      });
      jQuery('.upload-thumb').removeClass('hide')
      var fileArra = this.state.attachment_file
      //var fileThumbArra = this.state.imgThumb;
      for (var i = 0; i < files.length; i++) {
        // let fname = e.target.files[i].name.toLowerCase();
        let fname = files[i].name.toLowerCase();
        if (fname.endsWith('.pdf') || fname.endsWith('.png') || fname.endsWith('.jpg') || fname.endsWith('.jpeg') ||  fname.endsWith('.xls') || fname.endsWith('.xlsx')) {

          // let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() == e.target.files[i].name.toLowerCase());
          let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() === fname);
       console.log(this.state.attachment_file,previous_attachments,'res prev', fname)
       
          if (previous_attachments.length === 0) {
            // fileArra.push(e.target.files[i]);
            fileArra.push(files[i]);
            this.setState({
              // selectedFile: URL.createObjectURL(e.target.files[i]),
              selectedFile: URL.createObjectURL(files[i]),
              attachment_file: fileArra,
              is_files_not_selected: false
            }, () => {

            });

            // e.target.files = null;
            // e.target.value = '';
          } else {
            e.target.files = null;
            e.target.value = ''
            e.preventDefault();
            this.setState({
              isFailed: true,
              errormessage: 'Cannot select same file again'
            });

            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: '' });
            }, 3000);
          }

        } else {
          e.target.files = null;
          e.target.value = ''
          e.preventDefault();
          this.setState({
            isFailed: true,
            errormessage: 'Unsupported file Format'
          });

          setTimeout(() => {
            this.setState({ isFailed: false, errormessage: '' });
          }, 3000);
        }

        if (i == files.length - 1) {
          if (this.state.attachment_file.length == 0) {
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              "opacity": 1
            });
            jQuery('.upload-thumb').addClass('hide')
          }
        }
      }
    }
  
  }
save_attachment=()=>{
  const client_id=this.state.logged_client_id;
  const type='invoice';
  const source_attachments=this.state.attachment_file;
  console.log(source_attachments, 'resss')
  FetchAllApi.save_source_attachements(
    client_id,
    type,
    source_attachments,
    (err, response) => {
      if (response.status === 1) {
        this.setState({
          attachment_file: [],
          list_of_attachments: [...this.state.list_of_attachments, ...response.data],
        }, () => {
          jQuery('.previewImg').remove()
          window.jQuery('#upload_doc_modal').modal('hide')
          window.jQuery('#attachment_modal').modal('show')
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            "opacity": 1
          });

          jQuery('.upload-thumb').addClass('hide')
        });
      } else {
        alert(response.message)
      }
    }
  )
}

  customValuesUpdate = (a, b) => {
    this.setState({ custom_header_fields: a, custom_footer_fields: b });

    a &&
      a.your_extra_data &&
      a.your_extra_data[0].map((item, i) => {
        this.setState({ [`customField${i}`]: item });
      });

    a &&
      a.your_extra_data &&
      a.your_extra_data[1].map((item, i) => {
        this.setState({ [`customFieldB${i}`]: item });
      });
  };

  customValues = () => {
    let custom_header_fields = {};
    let custom_footer_fields = {};
    let customValues = [];
    let customValuesB = [];

    this.state.customField.map((item, i) => {
      let a = this.state[`customField${i}`];

      custom_header_fields = {
        ...custom_header_fields,
        [item.key]: this.state[`customField${i}`],
      };
      customValues.push(a);
    });

    this.state.customFieldB.map((item, i) => {
      let a = this.state[`customFieldB${i}`];

      custom_footer_fields = {
        ...custom_footer_fields,
        [item.key]: this.state[`customFieldB${i}`],
      };
      customValuesB.push(a);
    });

    let values = [customValues, customValuesB];
    custom_header_fields = { ...custom_header_fields, your_extra_data: values };
    this.setState({ custom_header_fields, custom_footer_fields });
  };

  temChanged = async (id) => {
    let obj = this.state?.response?.list?.find((e) => e.id == id);
    let customField =
      obj &&
      obj.properties &&
      obj.properties.header &&
      obj.properties.header.customField &&
      obj.properties.header.customField;
    let customFieldB =
      obj &&
      obj.properties &&
      obj.properties.footer &&
      obj.properties.footer.customField &&
      obj.properties.footer.customField;

    let template_default_fields = obj &&
    obj.properties &&
    obj.properties.defaultFields ? obj.properties.defaultFields:[];

    this.setState(
      { customField, customFieldB, html_contents: obj && obj.html_content,template_default_fields:template_default_fields },
      () => {
        return true;
      }
    );
    // console.log("temchanged", this.state.html_contents);
  };

  customColumn = () => {
    let customField = this.state.customField;
    this.state.customField.map((item, i) => {
      return (
        <div className='form-group col-md-4' id='itemoptioninvoicenostatus'>
          <label>Text</label>
          <input
            type='text'
            name='inv-no'
            className='form-control'
            id='invoice_no'
            required
            autoComplete='off'
            onChange={() => {
              var no = jQuery("#invoice_no").val();
              this.setState({ invoice_number_for_print: no });
            }}
          />{" "}
        </div>
      );
    });

    // console.log('11111')
    // let { customField } = this.state
    // customField.map((item, i) => {
    //   // console.log('22222')
    //   return (
    //     <>
    //       {item.type == 'text' &&
    //         <div
    //           className="form-group col-md-4"
    //           id="itemoptioninvoicenostatus"
    //         >
    //           <label>Text</label>
    //           <input
    //             type="text"
    //             name="inv-no"
    //             className="form-control"
    //             id="invoice_no"
    //             required
    //             autoComplete="off"
    //             onChange={() => {
    //               var no = jQuery('#invoice_no').val()
    //               this.setState({ invoice_number_for_print: no })
    //             }}
    //           />{" "}

    //         </div>
    //       }
    //       {item.type == 'dropdown' &&
    //         <div className="form-group col-md-4">
    //           <label>Date<span className="astrick">*</span></label>
    //           <div className="input-group date mar-t-no">
    //             <input
    //               type="text"
    //               autoComplete="off"
    //               name="incorport_date"
    //               id="due_date"
    //               // onBlur={e => this.changeDate(e.target.value)}
    //               className="form-control"
    //               required
    //             />

    //             <div className="input-group-addon" onClick={() => jQuery('#due_date').focus()}>
    //               <img src="images/calendar-icon.svg" alt="icon" />
    //             </div>
    //           </div>
    //           {this.state.isDueDate ? (
    //             <div >
    //               <small style={{ color: "red" }}>*Required.</small>
    //             </div>
    //           ) : (
    //             ""
    //           )}

    //           <small className="text-red"></small>
    //         </div>
    //       }
    //       {item.type == 'date' &&
    //         <div className="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
    //           <label>Select Box</label>

    //           <select
    //             className="selectpicker form-control add-new"
    //             data-live-search="true"
    //             title="Choose..."
    //             id="jobName"
    //             onChange={(e) => {
    //               this.newJob(e.target.value);
    //             }}
    //           >
    //             <option> Create New </option>
    //             {this.state.customerjoblist &&
    //               this.state.customerjoblist.map((item) => {
    //                 if (
    //                   this.state.JobId != undefined &&
    //                   this.state.JobId == item.id
    //                 ) {
    //                   var selected = true;
    //                 } else {
    //                   var selected = false;
    //                 }
    //                 return (
    //                   <option
    //                     selected={selected}
    //                     value={item.job_name}
    //                     data-status={item.id}
    //                   >
    //                     {item.job_name}
    //                   </option>
    //                 );
    //               })}
    //           </select>

    //         </div>
    //       }
    //     </>
    //   )
    // })
  };

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map((key) => {
      let newKey = key.replace(curr, "");
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

amountToWordsConversionBasedOnCurrency = (amount,currency) =>{
  currency = currency?currency:'';
  amount = amount?parseFloat(amount):0;
  
  console.log("1259.....currency",currency)
  console.log("1262.....amount...BBB",amount)

  let numberWord = '';
  let fractionWord = '';
  let number_word_plural = '';
  let fraction_word_plural = '';

  if(currency) {
    number_word_plural = config.currency_property?config.currency_property[currency]["number_word_plural"]:'';
    numberWord = config.currency_property?config.currency_property[currency]["number_word"]:'';
    fractionWord = config.currency_property?config.currency_property[currency]["fraction_word"]:'';
    fraction_word_plural =  config.currency_property?config.currency_property[currency]["fraction_word_plural"]:'';
  }

  console.log("1261....",numberWord);
  console.log("1262....",fractionWord);

  const toWords_for_all_currencies = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: numberWord,
        plural: number_word_plural,
        symbol: '',
        fractionalUnit: {
          name: fractionWord,
          plural: fraction_word_plural,
          symbol: '',
        },
      },
    },
  });

  console.log("toWords_for_all_currencies.....1294,,,",toWords_for_all_currencies);
  console.log("toWords_for_all_currencies.....1295.....,,,",toWords_for_all_currencies.convert(Number(parseFloat(amount).toFixed(2))));

  if (
    config.currencies_using_word_lakh.indexOf(
      currency
    ) >= 0
  ) {
    console.log("INR.....1302");
    const toWords_for_all_currencies1 = new ToWords({
      localeCode: 'en-IN',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: numberWord,
          plural: number_word_plural,
          symbol: '',
          fractionalUnit: {
            name: fractionWord,
            plural: fraction_word_plural,
            symbol: ''
          },
        },
      },
    });
    return toWords_for_all_currencies1.convert(amount);
} else {
  return toWords_for_all_currencies.convert(amount);
}
}

  changeDueDate = (type, invoice_date) => {
    let days =
      type == 1
        ? 0
        : type == 2
        ? 7
        : type == 3
        ? 30
        : type == 4
        ? 60
        : type == 5
        ? 90
        : type == 6
        ? 14
        : 0;
    jQuery("#due_date").val(
      moment(invoice_date, "DD/MM/YYYY").add(days, "days").format("DD/MM/YYYY")
    );

    // switch (type) {  0 7 30 60 90
    //   case '1':
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) } break
    //   case '2':
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) } break
    //   case '3':
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) } break
    //   case '4':
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) } break
    //   case '5':
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) } break
    //   default:
    //     { jQuery('#due_date').value(moment(invoice_date, 'DD/MM/YYYY').add(0, 'days').format('DD/MM/YYYY')) }

    // }
  };

  update_template_properties = () => {
    let input = {
      client_id: this.state.logged_client_id,
      template_id: this.state.template_id,
      header_array: this.state.customField,
      footer_array: this.state.customFieldB,
    };

    FetchAllApi.update_template_properties(input, (err, response) => {
      if (response.status == 1) {
        // alert(response.message)
      } else {
        // alert(response.message)
      }
    });
  };

  sales_default_due_date_terms = () => {
    FetchAllApi.sales_default_due_date_terms((err, response) => {
      if (response.status == 1) {
        this.setState(
          {
            sales_default_due_date_terms: response.list,
            due_date_terms_loaded: true,
          },
          () => {
            this.checkForInitialDataLoad();
          }
        );
      } else {
        //alert(response.message)
        this.setState({ sales_default_due_date_terms: [] });
      }
    });
  };

  // to apply credit api call

  apply_credit_to_invoice = () => {
    if (this.state.credit_list) {
      let Input = {
        client_id: this.state.logged_client_id,
        credit_list: this.state.credit_list,
        customer_id: this.state.selectCustomer,
        job_id: this.state.job_id
          ? this.state.job_id
          : this.state.JobId
          ? this.state.JobId
          : "",
        invoice_id: this.state.invoice_id_used,
      };

      FetchAllApi.customer_apply_credit(Input, (err, response) => {
        if (response.status === 1) {
          alert(response.message);
          window.jQuery("#apply_credit_modal").modal("hide");

          //2023-01-09
          this.getInvoicePaymentDetails();

          setTimeout(() => {
            // localStorage.setItem('page_select','Invoice')
            // window.location.href = '/all_lists'
          }, 500);
        } else {
          alert(response.message);
        }
      });
    } else {
      alert("please apply atleast one credit to invoice");
    }
  };

  // to apply credit api call

  // apply credit onchange

  credit_onchange = (i, available_credits) => {
    let total = 0;
    let total_used_credit = 0;
    let credit_list = [];

    // for individual row
    total =
      total +
      (this.state[`credit_to_be_use${i}`]
        ? Number(this.state[`credit_to_be_use${i}`])
        : 0);
    // for individual row

    // for table total
    this.state.customer_credit_list &&
      this.state.customer_credit_list.map((item, j) => {
        total_used_credit =
          total_used_credit +
          (this.state[`credit_to_be_use${j}`]
            ? Number(this.state[`credit_to_be_use${j}`])
            : 0);
        if (
          this.state[`credit_to_be_use${j}`] &&
          this.state[`credit_to_be_use${j}`] > 0
        ) {
          let list_obj = {
            credit_id: item.credit_id,
            credit_amount: this.state[`credit_to_be_use${j}`],
          };
          credit_list.push(list_obj);
        }
      });
    // for table total

    this.setState({
      [`usedCreditsRowTotal${i}`]: available_credits - total,
      total_used_credit,
      credit_list,
    });
  };

  // apply credit onchange

  // to check lock password

  checkPassword = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      password: this.state.old_password,
    };

    FetchAllApi.verify_lock_date_password(Input, (err, response) => {
      if (response.status === 1) {
        let temp_date = this.state.temp_date;
        jQuery("#invoice_date").val(temp_date);

        window.jQuery(".modal-backdrop").css("display", "none");
        window.jQuery("#asking_password_for_delete").modal("hide");
        window.jQuery("#lock_date").modal("hide");

        // setTimeout(() => {
        //   this.setState({
        //     cus_rate_rate: this.state.exchange_value_ref,
        //   });
        // }, 500);

        setTimeout(() => {
          let invoice_date = jQuery("#invoice_date").val();
          if (invoice_date != undefined) {
            this.changeDueDate(this.state.selected_due_date, invoice_date);
          }
          // this.handleChangeItems(0, this.state.rows.length - 1);
          this.loadExchangeRate(true);
        }, 500);
      } else {
        alert(response.message);
        this.setState({ deleteMsg: true });
      }
    });
  };

  changefromDate() {
    // let date = jQuery("#invoice_date").val();
    let date = this.state.temp_date;

    if (date && date != undefined) {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      var fomrat = moment(date_formated, "YYYY-MM-DD").format("DD-MM-YYYY");

      // console.log('ppppp', date_formated)
      // console.log('ppppp', fomrat)
      return date_formated;
    }
  }

  checkPassword_1 = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      password: this.state.old_password,
    };

    FetchAllApi.verify_lock_date_password(Input, (err, response) => {
      if (response.status === 1) {
        let temp_date = this.state.temp_date_1;
        jQuery("#payment_date").val(temp_date);

        window.jQuery("#asking_password_for_delete_1").modal("hide");

        setTimeout(() => {
          let nope;

          var date_formated;
          let date = jQuery("#payment_date").val();
          if (date != undefined && date != null && date != "") {
            var array = date.split("/");
            date_formated = array[2] + "-" + array[1] + "-" + array[0];
            //nope = `https://api.currencylayer.com/historical?access_key=${config.api_key}&date=${date_formated}&source=`
            nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
          } else {
            // date_formated = 'latest'
            // nope = `https://api.currencylayer.com/live?access_key=${config.api_key}&source=`
            date_formated = moment().format("YYYY-MM-DD");
            nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
          }

          // let nope = "https://api.exchangeratesapi.io/" + date_formated + "?base="
          let res = nope.concat(this.state.CurrentSelectedCurr);
          fetch(res)
            .then((response) => response.json())
            .then(async (data) => {
              // same func
              // let newObj = await this.rename(data.quotes, this.state.CurrentSelectedCurr)
              // let newObj = {}
              // Object.keys(data.quotes).map((key) => {
              //   let newKey = key.replace(this.state.CurrentSelectedCurr, '')
              //   Object.assign(newObj, { [newKey]: data.quotes[key] })
              // })
              // // same func

              // let value = (newObj[this.state.clientHomeCurrency]).toFixed(4)
              // jQuery("#payment_exchange_rate").val(value)
              if (data.success == true) {
                let newObj = this.rename(
                  data.quotes,
                  this.state.CurrentSelectedCurr
                );
                let currencyAr = [];
                let first = newObj;
                currencyAr.push({
                  date: data.start_date,
                  // 'rate': isNaN(first[data.start_date][this.state.CurrentSelectedCurr + this.state.clientHomeCurrency]) ? 0.0000 : (first[data.start_date][this.state.CurrentSelectedCurr + this.state.clientHomeCurrency]).toFixed(4)
                  rate:
                    first[data.start_date] &&
                    first[data.start_date][
                      this.state.CurrentSelectedCurr +
                        this.state.clientHomeCurrency
                    ]
                      ? first[data.start_date][
                          this.state.CurrentSelectedCurr +
                            this.state.clientHomeCurrency
                        ].toFixed(4)
                      : parseFloat(1).toFixed(4),
                });
                let value = currencyAr[0].rate;
                jQuery("#payment_exchange_rate").val(value);
              }
            });
          // my code
        }, 1000);
      } else {
        alert(response.message);
        this.setState({ deleteMsg: true });
      }
    });
  };

  changefromDate_1() {
    let date = this.state.temp_date_1;

    if (date && date != undefined) {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      var fomrat = moment(date_formated, "YYYY-MM-DD").format("DD-MM-YYYY");

      return date_formated;
    }
  }
  handlePrint = () => {
    // Show the printable content
    const printableContent = document.getElementById('whole_template_invoice');
    printableContent.style.display = 'block';

    // Print the document
    window.print();

    // Hide the printable content again after printing
    printableContent.style.display = 'none';
  };

  // to check lock password

  frontPrint = (type,is_from_get_details = false) => {
    if (this.state.invoice_details && is_from_get_details == true) {
      this.state.customField &&
        this.state.customField.map((itm, i) => {
          let customefiled =
            this.state[`customField${i}`] &&
            this.state[`customField${i}`] != undefined
              ? this.state[`customField${i}`]
              : "-";

          // 2023-01-06
          // let customFieldB = this.state[`customFieldB${i}`] && this.state[`customFieldB${i}`] != undefined ? this.state[`customFieldB${i}`] : '-'
          jQuery("#head-" + i).html(customefiled);

          // 2024-03-20 - need uncomment 
          if(itm.is_need_to_print == false) {
            jQuery("#customField"+ i).css("display", "none");
          }

          // 2023-01-06
          // jQuery("#foot-" + i).html(customFieldB)
        });

      //2023-01-06
      this.state.customFieldB &&
        this.state.customFieldB.map((itm, i) => {
          let customFieldB =
            this.state[`customFieldB${i}`] &&
            this.state[`customFieldB${i}`] != undefined
              ? this.state[`customFieldB${i}`]
              : "-";
          jQuery("#foot-" + i).html(customFieldB);
          // 2024-03-20 - need uncomment
          if(itm.is_need_to_print == false) {
            let v = i + 10; 
            jQuery("#customField"+ v).css("display", "none");
          }
        });

      let cc = this.state.invoice_details.invoice_details.invoice_details;
      let dd = [];
      dd = cc.map((item, index) => {
        return dd.push(index);
      });
      if (dd.length <= 3) {
        dd = [1, 2, 3];
      }
      console.log("cc", cc, this.state.invoice_details, cc.length, dd.length);
      let subDebit = 0;
      let subCredit = 0;
      let subcreditdisp = "0.00";
      let subdebitdisp = "0.00";
      let unitprice = "0.00";
      dd.map((row) => {
        console.log(cc.length >= row);
        if (cc.length >= row) {
          console.log(cc.length >= row);
          jQuery("#row-" + row).css("display", "table-row");
          let rowTotal =
            Number(cc[row - 1].quantity) * Number(cc[row - 1].unit_price);
          // console.log('pppp', row)
          let rowTotaldisplay = new Intl.NumberFormat(
            this.state.language_code + "-" + this.state.country_sortname,
            { style: "currency", currency: this.state.home_currency }
          )
            .format(isNaN(rowTotal) ? "0.00" : rowTotal)
            .replace(this.state.home_currency_symbol, "");

          // newly added
          if (rowTotal < 0) {
            rowTotaldisplay = new Intl.NumberFormat(
              this.state.language_code + "-" + this.state.country_sortname,
              { style: "currency", currency: this.state.home_currency }
            )
              .format(isNaN(rowTotal) ? "0.00" : rowTotal * -1)
              .replace(this.state.home_currency_symbol, "");
          }

          jQuery("#row-" + row + "-" + 1).html(row);
          jQuery("#row-" + row + "-" + 2).html(cc[row - 1].descr);
          unitprice = new Intl.NumberFormat(
            this.state.language_code + "-" + this.state.country_sortname,
            { style: "currency", currency: this.state.home_currency }
          )
            .format(
              isNaN(cc[row - 1].unit_price) ? "0.00" : cc[row - 1].unit_price
            )
            .replace(this.state.home_currency_symbol, "");
          // jQuery("#row-" + row + "-" + 3).html(cc[row - 1].unit_price)

          if (cc[row - 1].unit_price < 0) {
            unitprice = new Intl.NumberFormat(
              this.state.language_code + "-" + this.state.country_sortname,
              { style: "currency", currency: this.state.home_currency }
            )
              .format(
                isNaN(cc[row - 1].unit_price)
                  ? "0.00"
                  : cc[row - 1].unit_price * -1
              )
              .replace(this.state.home_currency_symbol, "");
            unitprice = "(" + unitprice + ")";
          }
          jQuery("#row-" + row + "-" + 3).html(unitprice);
          jQuery("#row-" + row + "-" + 4).html(cc[row - 1].quantity);
          jQuery("#row-" + row + "-" + 5).html(
            cc[row - 1].tax_name == "Choose " ? "--" : cc[row - 1].tax_name
          );

          if (rowTotal < 0) {
            jQuery("#row-" + row + "-" + 6).html("(" + rowTotaldisplay + ")");
          } else {
            jQuery("#row-" + row + "-" + 6).html(rowTotaldisplay);
          }
          if (rowTotal < 0) {
            jQuery("#row-" + row + "-" + 7).html(rowTotaldisplay);
            jQuery("#row-" + row + "-" + 8).html("0.00");
            subDebit = subDebit + rowTotal;
            subdebitdisp = new Intl.NumberFormat(
              this.state.language_code + "-" + this.state.country_sortname,
              { style: "currency", currency: this.state.home_currency }
            )
              .format(isNaN(subDebit) ? "0.00" : subDebit)
              .replace(this.state.home_currency_symbol, "");

            //newly added
            if (subDebit < 0) {
              subdebitdisp = new Intl.NumberFormat(
                this.state.language_code + "-" + this.state.country_sortname,
                { style: "currency", currency: this.state.home_currency }
              )
                .format(isNaN(subDebit) ? "0.00" : subDebit * -1)
                .replace(this.state.home_currency_symbol, "");
            }
          } else {
            jQuery("#row-" + row + "-" + 7).html("0.00");
            jQuery("#row-" + row + "-" + 8).html(rowTotaldisplay);
            subCredit = subCredit + rowTotal;
            subcreditdisp = new Intl.NumberFormat(
              this.state.language_code + "-" + this.state.country_sortname,
              { style: "currency", currency: this.state.home_currency }
            )
              .format(isNaN(subCredit) ? "0.00" : subCredit)
              .replace(this.state.home_currency_symbol, "");
          }
        } else {
          jQuery("#row-" + row).css("display", "none");
        }
      });
      jQuery("#row-total-2").html(subdebitdisp);
      jQuery("#row-total-3").html(subcreditdisp);
      console.log(unitprice, subdebitdisp);

      let bb = this.state.entity_details.data[0];
      jQuery("#isEntityName").html(bb.name);
      jQuery("#isAddress").html(bb.address);
      jQuery("#isPhoneNumber").html(bb.phone);
      jQuery("#isEmail").html(bb.email_id);
      jQuery("#isUenNumber").html(bb.unique_entity_number);

      jQuery("#isEntityName2").html(bb.name);
      jQuery("#isAddress2").html(bb.address);
      jQuery("#isPhoneNumber2").html(bb.phone);
      jQuery("#isEmail2").html(bb.email_id);
      jQuery("#isUenNumber2").html(bb.unique_entity_number);

      let aa = this.state.invoice_details.invoice_details;
      jQuery("#p-company_address").html(aa.company_address);
      jQuery("#p-customername").html(aa.customer_name);
      jQuery("#p-shipping_address").html(aa.shipping_address);
      jQuery("#p-invoice_number").html(aa.invoice_number);
      jQuery("#p-invoice_date").html(
        moment(aa.invoice_date).format("DD/MM/YYYY")
      );
      jQuery("#p-due_date").html(moment(aa.due_date).format("DD/MM/YYYY"));

      jQuery("#p-foreign_currency").html(aa.foreign_currency);
      jQuery("#p-job_name").html(aa.job_name);
      let memo = aa.memo ? aa.memo : "--";
      jQuery("#p-memo").html(memo);

      if (aa.exchange_rate == 1 || (this.state.gst_vat_currency != '' && this.state.gst_vat_currency != this.state.home_currency)) {
        jQuery("#p-exchange_div").css("display", "none");
      } else {
        jQuery("#p-exchange_rate").html(aa.exchange_rate);
      }
      let grandtotal = new Intl.NumberFormat(
        this.state.language_code + "-" + this.state.country_sortname,
        { style: "currency", currency: this.state.home_currency }
      )
        .format(
          isNaN(aa.grand_total_foreign_currency)
            ? "0.00"
            : aa.grand_total_foreign_currency
        )
        .replace(this.state.home_currency_symbol, "");
      let itemtotal = new Intl.NumberFormat(
        this.state.language_code + "-" + this.state.country_sortname,
        { style: "currency", currency: this.state.home_currency }
      )
        .format(
          isNaN(aa.item_total_foreign_currency)
            ? "0.00"
            : aa.item_total_foreign_currency
        )
        .replace(this.state.home_currency_symbol, "");
      let paydet =
        this.state.invoice_details.paymentDetails &&
        this.state.invoice_details.paymentDetails;
      let pd = [];
      if (paydet && paydet.length != 0) {
        pd = paydet.map((item, index) => {
          return pd.push(index);
        });
        if (pd.length <= 1) {
          pd = [1];
        }
        pd.map((row) => {
          let payment_amt_foreign_currency = new Intl.NumberFormat(
            this.state.language_code + "-" + this.state.country_sortname,
            { style: "currency", currency: this.state.home_currency }
          )
            .format(
              isNaN(paydet[row - 1].total_payment_foreign_currency)
                ? "0.00"
                : paydet[row - 1].total_payment_foreign_currency
            )
            .replace(this.state.home_currency_symbol, "");

          if (paydet[row - 1].payment_type == "Credits applied") {
            payment_amt_foreign_currency =
              payment_amt_foreign_currency + " (Credit note)";
          }

          jQuery("#rowp-" + row).css("display", "table-row");
          jQuery("#rowp-" + row + "-" + 1).html(
            moment(paydet[row - 1].payment_date).format("DD/MM/YYYY")
          );
          // jQuery("#rowp-" + row + "-" + 2).html(paydet[row - 1].total_payment_home_currency)
          // jQuery("#rowp-" + row + "-" + 2).html(paydet[row - 1].total_payment_foreign_currency)
          jQuery("#rowp-" + row + "-" + 2).html(payment_amt_foreign_currency);
        });
      } else {
        jQuery("#rowp-1" + "-" + 1).html("--");
        jQuery("#rowp-1" + "-" + 2).html("--");
      }

      let tax_total_to_display = new Intl.NumberFormat(
        this.state.language_code + "-" + this.state.country_sortname,
        { style: "currency", currency: this.state.home_currency }
      )
        .format(
          isNaN(aa.tax_amount_foreign_currency)
            ? "0.00"
            : aa.tax_amount_foreign_currency
        )
        .replace(this.state.home_currency_symbol, "");

      // jQuery("#p-amount_in_words").html(`${aa.amount_in_words} only`);
      jQuery("#p-amount_in_words").html(`${aa.amount_in_words}`);
      jQuery("#p-item_total_foreign_currency").html(itemtotal);
      // jQuery("#p-tax_amount_foreign_currency").html(aa.tax_amount_foreign_currency)
      jQuery("#p-tax_amount_foreign_currency").html(tax_total_to_display);
      jQuery("#p-grand_total_foreign_currency").html(grandtotal);
      // jQuery("#total-5").html(aa.tax_amount_foreign_currency)
      jQuery("#total-5").html(tax_total_to_display);
      // jQuery("#p-thanking_message").html(aa.thanking_message);
      // jQuery("#p-terms_and_conditions").html(aa.terms_and_conditions);
      jQuery("#p-thanking_message").html(draftToHtml(convertToRaw(this.state.thankingMessageEditorState.getCurrentContent())));
      jQuery("#p-terms_and_conditions").html(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));


      // if (this.state.selected_template_type && this.state.selected_template_type == 1) {
      jQuery("#template_name").html("INVOICE");
      jQuery("#p-invoice_label").html("Invoice No:");
      //}

      let foreign_open_balance = new Intl.NumberFormat(
        this.state.language_code + "-" + this.state.country_sortname,
        { style: "currency", currency: this.state.home_currency }
      )
        .format(
          isNaN(aa.open_balance_foreign_currency)
            ? "0.00"
            : aa.open_balance_foreign_currency
        )
        .replace(this.state.home_currency_symbol, "");
      jQuery("#balance_due").html(foreign_open_balance);

      if(this.state.template_default_fields && this.state.template_default_fields.length > 0) {
        this.state.template_default_fields.forEach((default_fields,k)=>{
          if(default_fields.is_need_to_print == false) {
            switch(default_fields.default_field_key) {
              case 'company_name':
                jQuery("#p-label-customername").css("display", "none");
                jQuery("#p-customername").css("display", "none");
                break;

              case 'company_address':
                jQuery("#p-company_address").css("display", "none");
                jQuery("#p-label-company_address").css("display", "none");
                break;

              case 'shipping_address':
                jQuery("#p-shipping_address").css("display", "none");
                jQuery("#p-label-shipping_address").css("display", "none");
                jQuery("#company_address_block").width("60%");
                break;

              case 'invoice_number':
                jQuery("#p-invoice_label").css("display", "none");
                jQuery("#p-div-invnum").css("display", "none");
                  break;
  
              case 'currency':
                jQuery("#p-foreign_currency").css("display", "none");
                jQuery("#p-label-foreign_currency").css("display", "none");
                jQuery("#p-div-fcurrency").css("display", "none");
                  break;
  
              case 'job_name':
                jQuery("#p-job_name").css("display", "none");
                jQuery("#p-label-job_name").css("display", "none");
                jQuery("#p-div-jobname").css("display", "none");
                  break;

              case 'memo':
                jQuery("#p-memo").css("display", "none");
                jQuery("#p-label-memo").css("display", "none");
                jQuery("#p-div-memo").css("display", "none");
                    break;
    
              case 'exchange_rate':
                jQuery("#p-exchange_rate").css("display", "none");
                jQuery("#p-label-exchange_rate").css("display", "none");
                jQuery("#p-div-ex-rate").css("display", "none");
                    break;
    
              case 'date':
                jQuery("#p-invoice_date").css("display", "none");
                jQuery("#p-label-invoice_date").css("display", "none");
                jQuery("#p-div-invdate").css("display", "none");
                    break;

              case 'due_date':
                jQuery("#p-due_date").css("display", "none");
                jQuery("#p-label-due_date").css("display", "none");
                jQuery("#p-div-duedate").css("display", "none");
                    break;

              default:
              break;

            }
          }
        });
      }

      //         let ee = aa.custom_header_fields.keys()
      //         let ff = ee.your_extra_data
      //         let gg = ee.custom_footer_fields
      //         let hh = ff[0].length

      //         dd.map(row => {
      //              if (hh.length >= row){
      //                  jQuery("#row-" + row).css("display", "table-row")

      //                  jQuery("#row-" + row + "-" + 1).html(cc[row-1].item_name)
      //                  jQuery("#row-" + row + "-" + 2).html(cc[row-1].descr)
      //                  jQuery("#row-" + row + "-" + 3).html(cc[row-1].unit_price)
      //                  jQuery("#row-" + row + "-" + 4).html(cc[row-1].quantity)
      //                  jQuery("#row-" + row + "-" + 5).html(cc[row-1].tax_name == "Choose " ? "--" : cc[row-1].tax_name)
      //                  jQuery("#row-" + row + "-" + 6).html(Number(cc[row-1].quantity) * Number(cc[row-1].unit_price) )
      //              }else{
      //                  jQuery("#row-" + row).css("display", "none")
      //              }
      //  })
      let client_id = this.state.logged_client_id;
      let invoice_id = this.state.invoice_id_used;
      let html_content = jQuery("#whole_template").html();

      // newly added to avoid page break inside html div
      if (html_content && html_content != "") {
        html_content = html_content.replaceAll(
          "break-inside: avoid;",
          "page-break-inside: avoid;"
        );
      }
      console.log(html_content);
      if (type === "frontprint") {
        setTimeout(() => {
          // client_id: this.state.logged_client_id,
          // invoice_id: this.state.invoice_id_used,
          // template_id: this.state.template_id

          //fetch("https://api.genie.com.sg/save_sales_invoice_as_pdf_with_html", {
          fetch(config.base_url + "save_sales_invoice_as_pdf_with_html", {
            method: "POST",
            body: JSON.stringify({
              client_id,
              invoice_id,
              html_content,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("user_access_token")
                ? localStorage.getItem("user_access_token")
                : localStorage.getItem("user_access_token")
                ? localStorage.getItem("user_access_token")
                : localStorage.getItem("user_access_token")
                ? localStorage.getItem("user_access_token")
                : authorization_key,
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.status === 1) {
                window.open(data.file_path);
                // callback(null, data);
              } else {
                // callback(null, data);
              }
            });
        }, 1000);
      } else {
        let data = {
          client_id,
          invoice_id,
          html_content,
        };
        FetchAllApi.send_invoice_mail(data, (err, response) => {
          var myArray = response.results;
          if (response.status === 1) {
            alert(response.message);
          } else {
            alert(response.message);
          }
        });
      }
    } else {
      this.getInvoiceDetails(type);
    }
  };

  checkForInitialDataLoad = () => {
    //this.state.customer_list_loaded &&
    if (
      this.state.next_invoice_number_loaded &&
      this.state.due_date_terms_loaded &&
      this.state.sub_account_list_loaded
    ) {
      this.setState({ manualLoading: false });
    }
  };

  getInvoiceDetails = (type) => {
    var coreData = {
      client_id: this.state.logged_client_id,
      invoice_id: this.state.invoice_id_used,
      payment_id: 0,
    };

    FetchAllApi.getInvoiceDetails(coreData, (err, response) => {
      if (response.status === 1) {
        this.setState({
          invoice_details: response,
          list_of_attachments: response.invoice_details.source_attachments?response.invoice_details.source_attachments:[],
          invoice_details2: response,
        });
        let primaryData = response.invoice_details;
        this.customValuesUpdate(
          primaryData.custom_header_fields,
          primaryData.custom_footer_fields
        );
        this.temChanged(primaryData.template_id);

        setTimeout(() => {
          this.frontPrint(type,true);
        }, 2000);
      } else {
        console.log("Failed to get sales invoice details");
      }
    });
  };

  getInvoicePaymentDetails = () => {
    var coreData = {
      client_id: this.state.logged_client_id,
      invoice_id: this.state.invoice_id_used,
      payment_id: this.state.currpayment_id,
    };

    FetchAllApi.getInvoiceDetails(coreData, (err, response) => {
      if (response.status === 1) {
        let primaryData = response.invoice_details;

        //2023-01-09 - to print latest payment on save pdf
        this.setState({
          invoice_details: response,
          invoice_details2: response,
          list_of_attachments: response.invoice_details.source_attachments,
          old_payment_date:primaryData.payment_date?primaryData.payment_date:''
        });

        let paidstaus = primaryData.paid_status
          ? primaryData.paid_status
          : "Unpaid";
        jQuery("#paid_status").val(paidstaus + "--invoice");
        this.setState(
          {
            payement_table: response.paymentDetails,
          },
          () => {
            if (primaryData.payments_applied_home_currency) {
              jQuery("#appliedhom").html(
                Number(primaryData.payments_applied_home_currency).toFixed(2)
              );
              this.setState({
                pmt_applied_home_currency: Number(
                  primaryData.payments_applied_home_currency
                ).toFixed(2),
              });
            } else {
              jQuery("#appliedhom").html(Number(0).toFixed(2));
              this.setState({
                pmt_applied_home_currency: Number(0).toFixed(2),
              });
            }

            if (primaryData.payments_applied_foreign_currency) {
              jQuery("#appliedfor").html(
                Number(primaryData.payments_applied_foreign_currency).toFixed(2)
              );
              this.setState({
                pmt_applied_foreign_currency: Number(
                  primaryData.payments_applied_foreign_currency
                ).toFixed(2),
              });
            } else {
              jQuery("#appliedfor").html(Number(0).toFixed(2));
              this.setState({
                pmt_applied_foreign_currency: Number(0).toFixed(2),
              });
            }

            jQuery("#forbaldue").html(
              primaryData.open_balance_foreign_currency
            );
            this.setState({
              pmt_balance_due_foreign_currency:
                primaryData.open_balance_foreign_currency,
            });

            jQuery("#homebaldue").html(primaryData.open_balance_home_currency);
            this.setState({
              pmt_balance_due_hom_curency:
                primaryData.open_balance_home_currency,
            });

            if (primaryData.exchange_gain_or_loss) {
              jQuery("#exchangeGain").html(primaryData.exchange_gain_or_loss);
              this.setState({
                pmt_exchange_gain_or_loss: primaryData.exchange_gain_or_loss,
              });
            } else {
              jQuery("#exchangeGain").html(0);
              this.setState({
                pmt_exchange_gain_or_loss: 0,
              });
            }
          }
        );
      } else {
        console.log("Failed to get sales invoice details");
      }
    });
  };

  groupAcc = (inv_id, group_accounting_list_id, from_edit_invoice = false) => {
    var coreData = {
      client_id: this.state.logged_client_id,
      invoice_id: inv_id,
      payment_id: 0,
    };

    let client_id = this.state.logged_client_id;
    let filter_id = 2;

    // To make sure sales product item list loaded before the invoice details getting loaded
    FetchAllApi.sales_product_item_list(
      client_id,
      filter_id,
      (err, response) => {
        if (response.status == 1) {
          this.setState({ ItemList: response.list }, () => {
            FetchAllApi.getInvoiceDetails(coreData, async (err, response) => {
              console.log(response.status, "groupacc get invoice");
              if (response.status === 1) {
                var primaryData = response.invoice_details;
                this.setState({
                  invoice_details: response,
                  invoice_details2: response,
                  invoice_id_used: primaryData.id,
                  list_of_attachments: response.invoice_details.source_attachments,
                });
                this.customValuesUpdate(
                  primaryData.custom_header_fields,
                  primaryData.custom_footer_fields
                );
                await this.temChanged(primaryData.template_id);

                this.state.customField &&
                  this.state.customField.map((itm, i) => {
                    let customefiled =
                      this.state[`customField${i}`] &&
                      this.state[`customField${i}`] != undefined
                        ? this.state[`customField${i}`]
                        : "-";
                    // 2023-01-06
                    //  let customFieldB = this.state[`customFieldB${i}`] && this.state[`customFieldB${i}`] != undefined ? this.state[`customFieldB${i}`] : '-'
                    jQuery("#head-" + i).html(customefiled);
                    // 2023-01-06
                    // jQuery("#foot-" + i).html(customFieldB)

                    // 2024-03-20 - need uncomment 
                    if(itm.is_need_to_print == false) {
                      jQuery("#customField"+ i).css("display", "none");
                    }
                  });

                // 2023-01-06
                this.state.customFieldB &&
                  this.state.customFieldB.map((itm, i) => {
                    let customFieldB =
                      this.state[`customFieldB${i}`] &&
                      this.state[`customFieldB${i}`] != undefined
                        ? this.state[`customFieldB${i}`]
                        : "-";
                    jQuery("#foot-" + i).html(customFieldB);

                    // 2024-03-20 - need uncomment
                    if(itm.is_need_to_print == false) {
                      let v = i + 10; 
                      jQuery("#customField"+ v).css("display", "none");
                    }
                  });

                let cc = response.invoice_details.invoice_details;
                console.log(cc);
                let dd = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                let subDebit = 0;
                let subCredit = 0;
                let subcreditdisp = "0.00";
                let subdebitdisp = "0.00";
                let unitprice = "0.00";
                dd.map((row) => {
                  if (cc && cc.length >= row) {
                    jQuery("#row-" + row).css("display", "table-row");
                    let rowTotal =
                      Number(cc[row - 1].quantity) *
                      Number(cc[row - 1].unit_price);
                    // console.log('pppp', row)

                    let rowTotaldisplay = new Intl.NumberFormat(
                      this.state.language_code +
                        "-" +
                        this.state.country_sortname,
                      { style: "currency", currency: this.state.home_currency }
                    )
                      .format(isNaN(rowTotal) ? "0.00" : rowTotal)
                      .replace(this.state.home_currency_symbol, "");

                    // newly added
                    if (rowTotal < 0) {
                      rowTotaldisplay = new Intl.NumberFormat(
                        this.state.language_code +
                          "-" +
                          this.state.country_sortname,
                        {
                          style: "currency",
                          currency: this.state.home_currency,
                        }
                      )
                        .format(isNaN(rowTotal) ? "0.00" : rowTotal * -1)
                        .replace(this.state.home_currency_symbol, "");
                    }

                    unitprice = new Intl.NumberFormat(
                      this.state.language_code +
                        "-" +
                        this.state.country_sortname,
                      { style: "currency", currency: this.state.home_currency }
                    )
                      .format(
                        isNaN(cc[row - 1].unit_price)
                          ? "0.00"
                          : cc[row - 1].unit_price
                      )
                      .replace(this.state.home_currency_symbol, "");

                    if (cc[row - 1].unit_price < 0) {
                      unitprice = new Intl.NumberFormat(
                        this.state.language_code +
                          "-" +
                          this.state.country_sortname,
                        {
                          style: "currency",
                          currency: this.state.home_currency,
                        }
                      )
                        .format(
                          isNaN(cc[row - 1].unit_price)
                            ? "0.00"
                            : cc[row - 1].unit_price * -1
                        )
                        .replace(this.state.home_currency_symbol, "");
                      unitprice = "(" + unitprice + ")";
                    }

                    jQuery("#row-" + row + "-" + 1).html(row);
                    jQuery("#row-" + row + "-" + 2).html(cc[row - 1].descr);
                    // jQuery("#row-" + row + "-" + 3).html(cc[row - 1].unit_price)
                    jQuery("#row-" + row + "-" + 3).html(unitprice);
                    jQuery("#row-" + row + "-" + 4).html(cc[row - 1].quantity);
                    jQuery("#row-" + row + "-" + 5).html(
                      cc[row - 1].tax_name == "Choose "
                        ? "--"
                        : cc[row - 1].tax_name
                    );
                    // jQuery("#row-" + row + "-" + 6).html(rowTotal)

                    if (rowTotal < 0) {
                      jQuery("#row-" + row + "-" + 6).html(
                        "(" + rowTotaldisplay + ")"
                      );
                    } else {
                      jQuery("#row-" + row + "-" + 6).html(rowTotaldisplay);
                    }

                    if (rowTotal < 0) {
                      // jQuery("#row-" + row + "-" + 7).html(rowTotal*-1)
                      // jQuery("#row-" + row + "-" + 8).html('--')

                      jQuery("#row-" + row + "-" + 7).html(rowTotaldisplay);
                      jQuery("#row-" + row + "-" + 8).html("0.00");
                      subDebit = subDebit + rowTotal;
                      subdebitdisp = new Intl.NumberFormat(
                        this.state.language_code +
                          "-" +
                          this.state.country_sortname,
                        {
                          style: "currency",
                          currency: this.state.home_currency,
                        }
                      )
                        .format(isNaN(subDebit) ? "0.00" : subDebit)
                        .replace(this.state.home_currency_symbol, "");
                      //newly added
                      if (subDebit < 0) {
                        subdebitdisp = new Intl.NumberFormat(
                          this.state.language_code +
                            "-" +
                            this.state.country_sortname,
                          {
                            style: "currency",
                            currency: this.state.home_currency,
                          }
                        )
                          .format(isNaN(subDebit) ? "0.00" : subDebit * -1)
                          .replace(this.state.home_currency_symbol, "");
                      }
                    } else {
                      // jQuery("#row-" + row + "-" + 7).html('--')
                      // jQuery("#row-" + row + "-" + 8).html(rowTotal)
                      jQuery("#row-" + row + "-" + 7).html("0.00");
                      jQuery("#row-" + row + "-" + 8).html(rowTotaldisplay);
                      subCredit = subCredit + rowTotal;
                      subcreditdisp = new Intl.NumberFormat(
                        this.state.language_code +
                          "-" +
                          this.state.country_sortname,
                        {
                          style: "currency",
                          currency: this.state.home_currency,
                        }
                      )
                        .format(isNaN(subCredit) ? "0.00" : subCredit)
                        .replace(this.state.home_currency_symbol, "");
                    }
                  } else {
                    jQuery("#row-" + row).css("display", "none");
                  }
                });
                // jQuery("#row-total-2").html(subDebit)
                // jQuery("#row-total-3").html(subCredit)

                jQuery("#row-total-2").html(subdebitdisp);
                jQuery("#row-total-3").html(subcreditdisp);

                let paydet =
                  this.state.invoice_details.paymentDetails &&
                  this.state.invoice_details.paymentDetails;
                let pd = [];
                if (paydet && paydet.length != 0) {
                  pd = paydet.map((item, index) => {
                    return pd.push(index);
                  });
                  if (pd.length <= 1) {
                    pd = [1];
                  }

                  pd.map((row) => {
                    let payment_amt_foreign_currency = new Intl.NumberFormat(
                      this.state.language_code +
                        "-" +
                        this.state.country_sortname,
                      { style: "currency", currency: this.state.home_currency }
                    )
                      .format(
                        isNaN(paydet[row - 1].total_payment_foreign_currency)
                          ? "0.00"
                          : paydet[row - 1].total_payment_foreign_currency
                      )
                      .replace(this.state.home_currency_symbol, "");

                    if (paydet[row - 1].payment_type == "Credits applied") {
                      payment_amt_foreign_currency =
                        payment_amt_foreign_currency + " (Credit note)";
                    }

                    jQuery("#rowp-" + row).css("display", "table-row");
                    jQuery("#rowp-" + row + "-" + 1).html(
                      moment(paydet[row - 1].payment_date).format("DD/MM/YYYY")
                    );
                    // jQuery("#rowp-" + row + "-" + 2).html(paydet[row - 1].total_payment_foreign_currency)
                    jQuery("#rowp-" + row + "-" + 2).html(
                      payment_amt_foreign_currency
                    );
                  });
                } else {
                  jQuery("#rowp-1" + "-" + 1).html("--");
                  jQuery("#rowp-1" + "-" + 2).html("--");
                }

                let bb =
                  this.state.entity_details &&
                  this.state.entity_details.data &&
                  this.state.entity_details.data[0];
                console.log(
                  bb,
                  this.state.entity_details.data[0],
                  this.state.entity_details &&
                    this.state.entity_details.data &&
                    this.state.entity_details.data[0]
                );
                if (bb) {
                  jQuery("#isEntityName").html(bb.name);
                  jQuery("#isAddress").html(bb.address);
                  jQuery("#isPhoneNumber").html(bb.phone);
                  jQuery("#isEmail").html(bb.email_id);
                  jQuery("#isUenNumber").html(bb.unique_entity_number);

                  jQuery("#isEntityName2").html(bb.name);
                  jQuery("#isAddress2").html(bb.address);
                  jQuery("#isPhoneNumber2").html(bb.phone);
                  jQuery("#isEmail2").html(bb.email_id);
                  jQuery("#isUenNumber2").html(bb.unique_entity_number);
                }
                console.log(
                  bb.length,
                  jQuery("#isEntityName").val(),
                  jQuery("#row-total-2").val()
                );
                // let aa = response.invoice_details.invoice_details
                let aa = response.invoice_details;
                console.log(aa);
                if (aa) {
                  jQuery("#p-company_address").html(aa.company_address);
                  jQuery("#p-customername").html(aa.customer_name);
                  jQuery("#p-shipping_address").html(aa.shipping_address);
                  jQuery("#p-invoice_number").html(aa.invoice_number);
                  jQuery("#p-invoice_date").html(
                    moment(aa.invoice_date).format("DD/MM/YYYY")
                  );
                  jQuery("#p-due_date").html(
                    moment(aa.due_date).format("DD/MM/YYYY")
                  );

                  jQuery("#p-foreign_currency").html(aa.foreign_currency);
                  jQuery("#p-job_name").html(aa.job_name);
                  jQuery("#p-memo").html(aa.memo);

                  if (aa.exchange_rate == 1 || (this.state.gst_vat_currency != '' && this.state.gst_vat_currency != this.state.home_currency)) {
                    jQuery("#p-exchange_div").css("display", "none");
                  } else {
                    jQuery("#p-exchange_rate").html(aa.exchange_rate);
                  }
                  //payment information
                  // let paydet=response.paymentDetails;
                  // paydet&& paydet.length!=0 &&paydet.map(row => {
                  //   jQuery("#rowp-" + row).css("display", "table-row")
                  //   jQuery("#rowp-" + row + "-" + 1).html(row)
                  //   jQuery("#rowp-" + row + "-" + 2).html(moment(paydet[row - 1].payment_date).format('DD/MM/YYYY'))
                  //   jQuery("#rowp-" + row + "-" + 3).html(paydet[row - 1].total_payment_foreign_currency)
                  // })

                  let grandtotal = new Intl.NumberFormat(
                    this.state.language_code +
                      "-" +
                      this.state.country_sortname,
                    { style: "currency", currency: this.state.home_currency }
                  )
                    .format(
                      isNaN(aa.grand_total_foreign_currency)
                        ? "0.00"
                        : aa.grand_total_foreign_currency
                    )
                    .replace(this.state.home_currency_symbol, "");

                  let itemtotal = new Intl.NumberFormat(
                    this.state.language_code +
                      "-" +
                      this.state.country_sortname,
                    { style: "currency", currency: this.state.home_currency }
                  )
                    .format(
                      isNaN(aa.item_total_foreign_currency)
                        ? "0.00"
                        : aa.item_total_foreign_currency
                    )
                    .replace(this.state.home_currency_symbol, "");

                  let tax_total_to_display = new Intl.NumberFormat(
                    this.state.language_code +
                      "-" +
                      this.state.country_sortname,
                    { style: "currency", currency: this.state.home_currency }
                  )
                    .format(
                      isNaN(aa.tax_amount_foreign_currency)
                        ? "0.00"
                        : aa.tax_amount_foreign_currency
                    )
                    .replace(this.state.home_currency_symbol, "");

                  // jQuery("#p-amount_in_words").html(`${aa.amount_in_words} only`);
                     jQuery("#p-amount_in_words").html(`${aa.amount_in_words}`);
                  // jQuery("#p-item_total_foreign_currency").html(aa.item_total_foreign_currency)
                  jQuery("#p-item_total_foreign_currency").html(itemtotal);
                  // jQuery("#p-tax_amount_foreign_currency").html(aa.tax_amount_foreign_currency)
                  jQuery("#p-tax_amount_foreign_currency").html(
                    tax_total_to_display
                  );
                  jQuery("#total-5").html(tax_total_to_display);
                  // jQuery("#p-grand_total_foreign_currency").html(aa.grand_total_foreign_currency)
                  jQuery("#p-grand_total_foreign_currency").html(grandtotal);

                  jQuery("#p-thanking_message").html(aa.thanking_message);
                  jQuery("#p-terms_and_conditions").html(
                    aa.terms_and_conditions
                  );

                  jQuery("#p-invoice_label").html("Invoice No:");

                  let foreign_open_balance = new Intl.NumberFormat(
                    this.state.language_code +
                      "-" +
                      this.state.country_sortname,
                    { style: "currency", currency: this.state.home_currency }
                  )
                    .format(
                      isNaN(aa.open_balance_foreign_currency)
                        ? "0.00"
                        : aa.open_balance_foreign_currency
                    )
                    .replace(this.state.home_currency_symbol, "");
                  jQuery("#balance_due").html(foreign_open_balance);
                }

                if(this.state.template_default_fields && this.state.template_default_fields.length > 0) {
                  this.state.template_default_fields.forEach((default_fields,k)=>{
                    if(default_fields.is_need_to_print == false) {
                      switch(default_fields.default_field_key) {
                        case 'company_name':
                          jQuery("#p-label-customername").css("display", "none");
                          jQuery("#p-customername").css("display", "none");
                          break;
          
                        case 'company_address':
                          jQuery("#p-company_address").css("display", "none");
                          jQuery("#p-label-company_address").css("display", "none");
                          break;
          
                        case 'shipping_address':
                          jQuery("#p-shipping_address").css("display", "none");
                          jQuery("#p-label-shipping_address").css("display", "none");
                          jQuery("#company_address_block").width("60%");
                          break;
          
                          case 'invoice_number':
                            jQuery("#p-invoice_label").css("display", "none");
                            jQuery("#p-div-invnum").css("display", "none");
                              break;
              
                          case 'currency':
                            jQuery("#p-foreign_currency").css("display", "none");
                            jQuery("#p-label-foreign_currency").css("display", "none");
                            jQuery("#p-div-fcurrency").css("display", "none");
                              break;
              
                          case 'job_name':
                            jQuery("#p-job_name").css("display", "none");
                            jQuery("#p-label-job_name").css("display", "none");
                            jQuery("#p-div-jobname").css("display", "none");
                              break;
            
                          case 'memo':
                            jQuery("#p-memo").css("display", "none");
                            jQuery("#p-label-memo").css("display", "none");
                            jQuery("#p-div-memo").css("display", "none");
                                break;
                
                          case 'exchange_rate':
                            jQuery("#p-exchange_rate").css("display", "none");
                            jQuery("#p-label-exchange_rate").css("display", "none");
                            jQuery("#p-div-ex-rate").css("display", "none");
                                break;
                
                          case 'date':
                            jQuery("#p-invoice_date").css("display", "none");
                            jQuery("#p-label-invoice_date").css("display", "none");
                            jQuery("#p-div-invdate").css("display", "none");
                                break;
            
                          case 'due_date':
                            jQuery("#p-due_date").css("display", "none");
                            jQuery("#p-label-due_date").css("display", "none");
                            jQuery("#p-div-duedate").css("display", "none");
                                break;
            
                          default:
                          break;
          
                      }
                    }
                  });
                }

                setTimeout(() => {
                  let client_id = this.state.logged_client_id;
                  let invoice_id = this.state.invoice_id_used;
                  let html_content = jQuery("#whole_template").html();

                  // newly added to avoid page break inside html div
                  if (html_content && html_content != "") {
                    html_content = html_content.replaceAll(
                      "break-inside: avoid;",
                      "page-break-inside: avoid;"
                    );
                  }

                  // fetch("https://api.genie.com.sg/save_sales_invoice_as_pdf_with_html", {
                  fetch(
                    config.base_url + "save_sales_invoice_as_pdf_with_html",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        client_id,
                        invoice_id,
                        html_content,
                        group_accounting_list_id,
                        from_edit_invoice,
                      }),
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: localStorage.getItem("user_access_token")
                          ? localStorage.getItem("user_access_token")
                          : localStorage.getItem("user_access_token")
                          ? localStorage.getItem("user_access_token")
                          : localStorage.getItem("user_access_token")
                          ? localStorage.getItem("user_access_token")
                          : authorization_key,
                      },
                    }
                  )
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {
                      this.setState({ manualLoading: false });

                      console.log("objehjhjhjct", data);
                      if (data.status === 1) {
                        window.open(data.file_path);
                        // callback(null, data);
                      } else {
                        // callback(null, data);
                      }
                    });
                }, 1000);
              }
            });
          });
        }
      }
    );
  };

  saveAsPdf = () => {
    let Input = {
      client_id: this.state.logged_client_id,
      invoice_id: this.state.invoice_id_used,
      template_id: this.state.template_id,
    };

    let dummy = {
      status: 1,
      message: "PDF saved successfully",
      file_path:
        "http://13.250.63.251:9002/saved_sales_invoies/invoice-SIC-4.pdf",
    };

    // window.open(dummy.file_path);

    FetchAllApi.print_pdf(Input, "create_invoice", (err, response) => {
      if (response.status === 1) {
        // console.log("Basio state", response);
        alert(response.message);
        window.open(response.file_path);
      } else {
      }
      alert(response.message);
    });
  };

  print = () => {
    var getHTML = jQuery("#printing_template").html();
    fetch("https://v2018.api2pdf.com/chrome/html", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "27662ed5-3115-47b9-b6df-aec6e053edc8", //Get your API key from https://portal.api2pdf.com      11011305-f6cf-4868-b731-74c53dcf9f89
      },
      body: JSON.stringify({
        html: getHTML,
        inlinePdf: true,
        fileName: "test.pdf",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          // console.log("test_pdf", res);
          window.open(res.pdf);
          //window.location.href = res.pdf;
        } else {
          // console.log("test_pdf", res.error);
        }
      });
  };

  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        // console.log("Basio state", response);
        this.setState(
          {
            clientHomeCurrency: response.currency,
          },
          this.get_currencies()
        );
      } else {
      }
    });
  };

  add_gst_details = (e) => {
    e.preventDefault();
    let sales_tax_code = this.state.sales_tax_code;
    let sales_tax_name = this.state.salesTax_name_entered;
    let show_on_list = 1;
    let tax_type = this.state.selectedOption === "option1" ? 1 : 2;
    let rate = this.state.rate_entered;

    if (
      this.state.selected_rate_type != "Fixed price" &&
      this.state.selected_rate_type === "%"
    ) {
      var rate_type = 1;
    } else {
      var rate_type = 2;
    }

    let country = this.state.country_code;
    let items = {
      sales_tax_code: sales_tax_code,
      sales_tax_name: sales_tax_name,
      show_on_list: show_on_list,
      tax_type: tax_type,
      rate: rate,
      rate_type: rate_type,
      country: country,
      client_id: this.state.logged_client_id,
    };
    // console.log("hjagsjkhlkasjh", items);
    FetchAllApi.add_gst_details(items, (err, response) => {
      // console.log("add comment", response.status);

      if (response.status === 1) {
        jQuery("#sales_tax_code").val("");
        jQuery("#sales_tax_name").val("");
        jQuery("#sales_tax_name").val("");
        jQuery("#tax").val("");

        //Newly added on 11-10-2022 to autoselect tax on add new tax
        if (this.state.tax_adding_index >= 0) {
          jQuery("#selectednow" + this.state.tax_adding_index).html(
            response.sales_tax_name
          );
          jQuery("#selectednow_id" + this.state.tax_adding_index).html(
            response.id
          );
          jQuery("#selectedrate_id" + this.state.tax_adding_index).val(
            response.rate
          );
          jQuery("#selectedtype_id" + this.state.tax_adding_index).val(
            response.tax_type
          );
          this.handleChangeItems("", this.state.rows.length - 1);
          setTimeout(() => {
            this.setState({ tax_adding_index: -1 });
          }, 1000);
        }

        this.setState({
          show_succes: true,
          sales_tax_code: "",
          sales_tax_name: "",
          tax_type: "",
          rate: "",
        });
        this.get_gst_list();
        var THIS = this;
        setTimeout(function () {
          THIS.setState({ show_succes: false });
        }, 4000);
        window.jQuery("#pop-modal-1").modal("hide");
      } else {
        this.setState({ modal_info_msg: response.message });
        jQuery(".mymsg").fadeIn(2000);
        setTimeout(function () {
          jQuery(".mymsg").fadeOut(2000);
        }, 8000);
      }
    });
  };

  modal_cancel = () => {
    jQuery("#sales_tax_code").val("");
    jQuery("#sales_tax_name").val("");
    jQuery("#sales_tax_name").val("");
    jQuery("#tax").val("");
    this.setState({ modal_info_msg: "" });
    window.jQuery("#pop-modal-1").modal("hide");
  };
  update_rate_fixed = () => {
    jQuery("#tax").val("");
    this.setState({
      selected_rate_type: "Fixed price",
      maximum_chr_lngth: 100,
    });
  };
  update_rate_type = () => {
    jQuery("#tax").val("");
    this.setState({
      selected_rate_type: "%",
      maximum_chr_lngth: 4,
    });
  };
  handleChange_gst_type = (event) => {
    if (this.state.selected_rate_type != "Fixed price") {
      let entered_value = event.target.value;
      // alert(entered_value)
      if (isNaN(entered_value)) {
        jQuery("#tax").val("");
      } else {
        if (entered_value > 100) {
          jQuery("#tax").val("");
        } else {
          this.setState({ rate_entered: entered_value });
        }
      }
    } else {
      let entered_value = event.target.value;
      if (isNaN(entered_value)) {
        jQuery("#tax").val("");
      } else {
        this.setState({ rate_entered: entered_value });
      }
    }
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };
  handleChangeTax(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => this.validation_clean()
    );
  }
  validation_clean = () => {
    this.state.company_name != ""
      ? this.setState({ isCompany_name: true })
      : this.setState({});
    this.state.invoice_no != ""
      ? this.setState({ isInvoice_no: true })
      : this.setState({});
    this.state.balance_sheet_category_id != ""
      ? this.setState({ isBalance_sheet_category_name: true })
      : this.setState({});
  };

  newJob = (job_name) => {
    if (job_name == "Create New") {
      jQuery("#jobName option").prop("selected", false).trigger("change");
      if (
        this.state.selectCustomer != "" &&
        this.state.selectCustomer != undefined &&
        this.state.selectCustomer != null
      ) {
        // alert(this.state.selectCustomer)
        localStorage.setItem("selected_customer_id", this.state.selectCustomer);
        // this.props.history.push('/add-job', this.state.selectCustomer)

        let win = window.open("/add-job", "_blank");
        win.focus();
      } else {
        jQuery("#jobName option").prop("selected", false).trigger("change");

        alert("Please Choose Customer first...");
      }
    } else {
      // let job_id = jQuery("#jobName option:selected").data("status");
      let jobObject = this.state.customerjoblist.find(
        (item) => item.job_name == job_name
      );
      let job_id = jobObject.id ? jobObject.id : 1;
      this.setState({ job_id, JobId: job_id, job_name });
      // this.setState({ job_name: job_name });
    }
  };

  customerjoblist = (id, x) => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    var customer_id = id;

    FetchAllApi.customerjoblist(client_id, customer_id, (err, response) => {
      // console.log("vendor_names================>>>>>>>>>", response);
      // alert(re)
      var myArray = response.results;
      if (response.status === 1) {
        this.setState({ customerjoblist: response.results }, () => {
          if (x !== undefined && x !== null) {
            myArray.map((item) => {
              if (
                this.state.JobId != undefined &&
                item.id == this.state.JobId
              ) {
                this.setState({ job_name: item.job_name });
                jQuery("#jobName").val(item.job_name);
              }
            });
            localStorage.setItem("job_added_id", "");
            window.jQuery("#jobName").selectpicker("refresh");
          } else {
            // myArray.map((item) => {
            // if(this.state.JobId != undefined &&  item.id==  this.state.JobId)
            // {
            if (this.state.JobId != undefined && this.state.JobId != "") {
              myArray.map((item) => {
                if (item.id == this.state.JobId) {
                  this.setState({ job_name: item.job_name });
                  jQuery("#jobName").val(item.job_name);
                }
              });
            } else {
              let job_data = this.state.customerjoblist.find(
                (e) => e.job_name === "Others"
              );
              if (job_data) {
                let j_id = job_data.id;
                let j_name = job_data.job_name;

                this.setState({ JobId: j_id, job_id: j_id, job_name: j_name });
                jQuery("#jobName").val(j_name);
              }
            }

            // });
            localStorage.setItem("job_added_id", "");
            window.jQuery("#jobName").selectpicker("refresh");
          }
          // }, 3000);
        });
      } else {
        this.setState({ customerjoblist: [] });
      }
    });
  };

  Basic_info = (id) => {
    let client_id = this.state.logged_client_id;
    let customer_id = id;

    FetchAllApi.Basic_info(client_id, customer_id, (err, response) => {
      if (response.status === 1) {
        // console.log("Basicinfo state", response.response);
        this.setState({
          Basic_info: response.response,
         // billing_address: response.response[0].billing_address,
          shipping_address: response.response[0].shipping_address,
          customer_name: response.response[0].customer_name,
          currency_customer: response.response[0].currency,
          ToCurrency: response.response[0].currency,
        },()=>{
          this.setInvoiceTo(response.response[0].billing_address);
        });
      } else {
      }
    });
  };

  handleChange = (selected_option) => {
    if (selected_option == "Create New") {
      jQuery("#variable_pay_type option")
        .prop("selected", false)
        .trigger("change");
      // this.props.history.push('/add-new-customer')

      this.setState({ is_need_to_change_customer: true });

      let win = window.open("/add-new-customer", "_blank");
      win.focus();
    } else {
      jQuery("#jobName").val("");
      this.setState({ JobId: "", job_id: "" });
      let job_id = jQuery("#jobName option:selected").data("status");
      let variable_pay_type = jQuery("#variable_pay_type option:selected").data(
        "status"
      );
      localStorage.setItem(
        "customer",
        selected_option + "=" + variable_pay_type
      );

      this.setState(
        {
          selectCustomer: variable_pay_type,
          vendorName: jQuery("#variable_pay_type").val(),
        },
        () => {
          // alert(this.state.selectCustomer)
        }
      );

      this.Basic_info(variable_pay_type);
      this.setState({ myCusVarPay: variable_pay_type });
      this.customerjoblist(variable_pay_type);
      this.customer_and_job_list(selected_option, true);
    }
  };

  customer_and_job_list = (x, need_to_update_items = false) => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    var from_customer_receive_payment = 0;
    let country_code = this.state.country_code;
    //alert(country_code)
    let keyword = this.state.search_key_gst;
    let filter_id = 2;

    //modifed on 03-10-2022- To fix customer default item name not loading properly on customer selection
    FetchAllApi.sales_product_item_list(
      client_id,
      filter_id,
      (err, productresult) => {
        let common_product_list =
          productresult && productresult.status == 1 ? productresult.list : [];

        FetchAllApi.customer_and_job_list(
          client_id,
          from_customer_receive_payment,
          filter_id,
          (err, response) => {
            if (response.status === 1) {
              let myArray =
                response.list && response.list.filter((item) => item.id == x);
              var rows = this.state.rows;
              if (
                need_to_update_items &&
                myArray[0] &&
                myArray[0].default_sales_product_items.length != 0
              ) {
                var res = myArray[0].default_sales_product_items;
                var useme = myArray[0].default_sales_product_items.length - 1;
                let number_of_rows_to_add = useme - rows.length;
                for (var i = 0; i <= number_of_rows_to_add; i++) {
                  var rows = this.state.rows;
                  rows.push("row" + (this.state.initial_value + 1));
                  this.setState({
                    isAdd: false,
                    initial_value: this.state.initial_value + 1,
                  });
                  this.setState({ rows: rows }, () => {
                    this.state.rows.forEach((item, i) => {
                      this.addSerialNumber();
                      jQuery("#item" + i).val(res[i]);
                    });
                  });
                }
              }
              let filter_id = 2;
              //modified on 03-10-2022
              // FetchAllApi.sales_product_item_list(client_id,filter_id, (err, response) => {
              need_to_update_items &&
                myArray[0] &&
                myArray[0].default_sales_product_items.length != 0 &&
                myArray[0].default_sales_product_items.map((val, myi) => {
                  //modified on 03-10-2022
                  //   if (response.status === 1) {
                  // if (response.list.length > 0) {
                  if (common_product_list.length > 0) {
                    //modified on 03-10-2022
                    // response.list.map((item, i) => {
                    common_product_list.map((item, i) => {
                      if (val.sales_product_id == item.item_id) {
                        // var res = response.list;
                        var res = common_product_list;

                        var makeUse =
                          item.rate +
                          "=" +
                          item.description +
                          "=" +
                          item.account_name_category +
                          "=" +
                          item.item_name +
                          "=" +
                          item.item_id;
                        console.log("makeUse", makeUse);
                        jQuery("#cust_items" + myi).val(makeUse);

                        // richtext-description-20240410
                        //jQuery("#descr" + myi).val(item.description);

                        this.setDescriptionContent(item.description,myi);

                        jQuery("#unit_price" + myi).val(item.rate);

                        jQuery("#categry_id" + myi)
                          .val(item.account_name_category)
                          .trigger("change");

                        window
                          .jQuery("#categry_id" + myi)
                          .selectpicker("refresh");

                        jQuery("#item" + myi).val(item.item_name);

                        jQuery("#itemsid" + myi).val(item.item_id);
                      }
                    });
                  }
                  //modified on 03-10-2022
                  //   }
                });
              //modified on 03-10-2022
              //   })

              let t_filter_id = 2;
              FetchAllApi.get_gst_list(
                country_code,
                keyword,
                this.state.logged_client_id,
                t_filter_id,
                (err, response) => {
                  need_to_update_items &&
                    myArray[0] &&
                    myArray[0].default_sales_product_items.length != 0 &&
                    myArray[0].default_sales_product_items.map((val, myi) => {
                      if (response.status === 1) {
                        response.list.map((tx) => {
                          if (val.sales_tax_id == tx.id) {
                            jQuery("#selectednow" + myi).html(
                              tx.sales_tax_name
                            );
                            jQuery("#selectedrate_id" + myi).val(tx.rate);
                            jQuery("#selectedtype_id" + myi).val(tx.rate_type);
                            jQuery("#selectednow_id" + myi).html(tx.id);
                          }
                        });
                      }
                    });
                }
              );

              this.setState(
                {
                  customer_and_job_list: response.list,
                  customer_list_loaded: true,
                },
                () => {
                  this.checkForInitialDataLoad();
                  setTimeout(() => {
                    if (x !== undefined && x !== null) {
                      myArray.map((item) => {
                        if (item.id == x) {
                          jQuery("#variable_pay_type").val(item.id);
                        }
                      });
                      localStorage.setItem("customer_added_id", "");
                      localStorage.setItem("customerId", "");
                      window
                        .jQuery("#variable_pay_type")
                        .selectpicker("refresh");
                    }
                  }, 2000);
                }
              );
            } else {
            }
          }
        );
      }
    );
  };

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((state) => {
      state[name] = value;
      return state;
    });
  };

  convertPDF = () => {
    alert();
  };
  onChange = (date) => this.setState({ date });

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  setTermsAndConditions = (html = "") => {
    const contentBlock = htmlToDraft(html,(nodeName, node) => {
      if (nodeName === 'img' && node instanceof HTMLImageElement) {
        if (node.parentElement instanceof HTMLDivElement) {    // for image with div element as a parent
          const entityConfig = {};
          const parentElement = node.parentElement;
          if (parentElement.style.float) {      // check float style property
            entityConfig.alignment = parentElement.style.float;
          } else if (parentElement.style.textAlign) {   // check float style property
            entityConfig.alignment = parentElement.style.textAlign;
          }

          entityConfig.src = node.getAttribute
            ? node.getAttribute('src') || node.src
            : node.src;
          entityConfig.alt = node.alt;
          entityConfig.height = node.style.height;
          entityConfig.width = node.style.width;
          return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: entityConfig,
          };
        }
      }
    });
    let editorState = {};
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }
    this.setState({
      editorState: editorState,
    });
  };

  onThankingMessageEditorStateChange = (thankingMessageEditorState) => {
    this.setState({
      thankingMessageEditorState,
    });
  };

  onInvoiceToEditorStateChange = (invoiceToEditorState) => {
    this.setState({
      invoiceToEditorState,
    });
  };

  setInvoiceTo = (html = "") => {
    const contentBlock = htmlToDraft(html, (nodeName, node) => {
      if (nodeName === 'img' && node instanceof HTMLImageElement) {
        if (node.parentElement instanceof HTMLDivElement) {    // for image with div element as a parent
          const entityConfig = {};
          const parentElement = node.parentElement;
          if (parentElement.style.float) {      // check float style property
            entityConfig.alignment = parentElement.style.float;
          } else if (parentElement.style.textAlign) {   // check float style property
            entityConfig.alignment = parentElement.style.textAlign;
          }

          entityConfig.src = node.getAttribute
            ? node.getAttribute('src') || node.src
            : node.src;
          entityConfig.alt = node.alt;
          entityConfig.height = node.style.height;
          entityConfig.width = node.style.width;
          return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: entityConfig,
          };
        }
      }
    });
    let invoiceToEditorState = {};
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      invoiceToEditorState = EditorState.createWithContent(contentState);
    }
    this.setState({
      invoiceToEditorState: invoiceToEditorState,
    });
  };

  setThankingMessage = (html = "") => {
    const contentBlock = htmlToDraft(html, (nodeName, node) => {
      if (nodeName === 'img' && node instanceof HTMLImageElement) {
        if (node.parentElement instanceof HTMLDivElement) {    // for image with div element as a parent
          const entityConfig = {};
          const parentElement = node.parentElement;
          if (parentElement.style.float) {      // check float style property
            entityConfig.alignment = parentElement.style.float;
          } else if (parentElement.style.textAlign) {   // check float style property
            entityConfig.alignment = parentElement.style.textAlign;
          }

          entityConfig.src = node.getAttribute
            ? node.getAttribute('src') || node.src
            : node.src;
          entityConfig.alt = node.alt;
          entityConfig.height = node.style.height;
          entityConfig.width = node.style.width;
          return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: entityConfig,
          };
        }
      }
    });
    let thankingMessageEditorState = {};
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      thankingMessageEditorState = EditorState.createWithContent(contentState);
    }
    this.setState({
      thankingMessageEditorState: thankingMessageEditorState,
    });
  };

  setDescriptionContent = (html = "",index,need_to_trigger_handle_items = false) => {
    const contentBlock = htmlToDraft(html);
    let descriptionEditorState = {};
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      descriptionEditorState = EditorState.createWithContent(contentState);
    }
    this.setState({
        [`descriptionEditorState${index}`]:descriptionEditorState
    },()=>{
      console.log("3265.......",draftToHtml(
        convertToRaw(this.state[`descriptionEditorState${index}`].getCurrentContent())
      ));
      if(need_to_trigger_handle_items == true) {
        this.handleChangeItems(
          0,
          this.state.rows.length - 1
        );
      }
    });
  };

  UNSAFE_componentWillMount() {
    // this.get_customerlist()

    this.get_invoice_list();
    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push("/");
    }

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var today_date = yyyy + "-" + mm + "-" + dd;

    // console.log("logged_user_id", this.state.logged_user_id);
    // console.log(
    //   "logged_subscription_end_date",
    //   this.state.logged_subscription_end_date
    // );

    if (this.state.logged_subscription_end_date < today_date) {
      //this.props.history.push('/register_Payment');
    }

    /* =================== */
    let client_Id = this.state.logged_client_id;
    FetchAllApi.get_country_id(client_Id, (err, response) => {
      // alert(response.country_id)
      if (response.status === 1) {
        this.setState(
          {
            country_code: response.country_id,
          },
          () => this.get_gst_list()
        );
      }
    });
    /* =================== */
  }

  /* =================== */
  Chk_table_validation = () => {
    var item_check = jQuery(
      `#coulmn${this.state.rows.length - 1}${this.state.coulmns.length - 1}`
    ).val();
    if (
      this.state.myarray?.length > this.state.rows?.length - 1 &&
      this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      this.state.myarray[this.state.myarray.length - 1].category_id.length >
        0 &&
      item_check != ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  val_me = () => {
    if (this.Chk_table_validation()) {
      this.setState({ isTable_notEmpty: false });
    } else {
    }
  };
  val_me_Check = () => {
    if (this.Chk_table_validation()) {
    } else {
      this.setState({ isTable_notEmpty: true });
    }
  };

  toggleChange = () => {
    this.setState(
      {
        isChecked: !this.state.isChecked,
      },
      () => {
        this.handleChangeItems("", this.state.rows.length - 1);
      }
    );
  };

  update_search_keyword = (event) => {
    this.setState({ search_key_gst: event.target.value }, () => {
      this.get_gst_list();
    });
  };

  get_gst_list = () => {
    let country_code = this.state.country_code;
    //alert(country_code)
    let keyword = this.state.search_key_gst;
    let filter_id = 2;

    FetchAllApi.get_gst_list(
      country_code,
      keyword,
      this.state.logged_client_id,
      filter_id,
      (err, response) => {
        // console.log("defaultcategorylist", response);
        //alert(response.message)
        if (response.status === 1) {
          this.setState({
            gst_list: response.list,
          });
        } else {
          this.setState({
            gst_list: [],
          });
        }
      }
    );
  };

  handleCheck_get_selected_tax(
    selectednow_id,
    itemid,
    id,
    valueres,
    rate,
    type
  ) {
    // console.log("selectednow_id", selectednow_id);

    // this.setState(
    //   {
    //     sales_tax_name: e.currentTarget.dataset.name,
    //     sales_tax_rate: e.currentTarget.dataset.rate,
    //     sales_tax_type: e.currentTarget.dataset.type
    //   }
    // )

    if (selectednow_id > 0) {
      // document.getElementById(id).innerHTML = valueres;
      // document.getElementById('selectedrate_id' + itemid).value = e.currentTarget.dataset.rate
      // document.getElementById('selectedtype_id' + itemid).value = e.currentTarget.dataset.type
      // document.getElementById('selectednow_id' + itemid).innerHTML = selectednow_id

      jQuery("#" + id).html(valueres);
      // alert(rate)
      jQuery("#selectedrate_id" + itemid).val(rate);
      jQuery("#selectedtype_id" + itemid).val(type);
      jQuery("#selectednow_id" + itemid).html(selectednow_id);

      this.handleChangeItems(valueres, this.state.rows.length - 1);

      jQuery(".form-table").removeClass("ovrFlwRmve");
    } else {
      alert("sorry fault is here only");
    }

    jQuery("#gst_search").val("");
    this.get_gst_list();
  }

  tableDropDynamic = (e, id, colId) => {
    const userId = this.state.logged_user_id;
    const coulmnId = colId;
    const isPopup = e.target.value;
    jQuery("#colid").val(coulmnId);
    if (isPopup == "1e") {
      jQuery("#customer_type option").prop("selected", false).trigger("change");

      window.jQuery("#add_new_role").modal("show");
    } else {
      this.handleChangeItems(e, id);
    }
  };

  handleChangeItems = async (
    currid = "",
    itemid = "",
    is_date_changed = false
  ) => {
    this.addSerialNumber();

    // alert(itemid)
    // console.log("itemid", currid);

    var salesarray = this.state.mysalesarray;
    var estimatearray = this.state.myestimatearray;
    var result = [];
    var itemprice = [];
    var subTotal = 0;
    var tax_total = 0;
    var salesresult = [];

    for (var i = itemid; i >= 0; i--) {
      // alert(i)
      var usedquanity = 0;
      if (document.getElementById("selectednow_id" + i) != null) {
        //subTotal+=jQuery('#subtotal'+i).val();
        // console.log("itm check", jQuery("#item" + i).val())
        var item_name =
          jQuery("#item" + i).val() != "" ? jQuery("#item" + i).val() : 0;

        // richtext-description-20240410
        // var descr =
        //   jQuery("#descr" + i).val() != "" ? jQuery("#descr" + i).val() : 0;

        var descr = this.state[`descriptionEditorState${i}`]?draftToHtml(
          convertToRaw(this.state[`descriptionEditorState${i}`].getCurrentContent())
        ):"";
        ;

        var quantity_check =
          jQuery("#quantity" + i).val() != ""
            ? jQuery("#quantity" + i).val()
            : 0;
        var quantity = isNaN(quantity_check)
          ? jQuery("#quantity" + i).val("")
          : quantity_check;
        var usedquantity = quantity;
        var check =
          jQuery("#dynamic" + i).val() != "" ? jQuery("#dynamic" + i).val() : 0;
        var checkf = isNaN(check) ? jQuery("#dynamic" + i).val("") : check;
        var unit_price_check =
          jQuery("#unit_price" + i).val() != ""
            ? jQuery("#unit_price" + i)
                .val()
                .replace(/,/g, "")
            : 0;
        var unit_price = isNaN(unit_price_check)
          ? jQuery("#unit_price" + i).val("")
          : unit_price_check;
        var price = quantity * unit_price;
        var selectednow_id = document.getElementById(
          "selectednow_id" + i
        ).innerText;
        var category_id =
          selectednow_id != "NO_VALUE"
            ? selectednow_id
            : this.state.selectedindex != ""
            ? this.state.selectedindex
            : "";

        var myCat = jQuery("#categry_id" + i).val();

        // console.log("category_id", category_id);
        var id = i;
        // if(this.state.isChecked){
        // var Total =  this.state.sales_tax_type != 2 ? (price / (parseFloat(this.state.sales_tax_rate) + 100)) * 100: price - parseFloat(this.state.sales_tax_rate)
        // }else{
        //   var Total=this.state.sales_tax_type != 2 ? price:price
        // }

        var sales_tax_type =
          jQuery("#selectedtype_id" + i).val() != ""
            ? jQuery("#selectedtype_id" + i).val()
            : 0;
        var sales_tax_rate =
          jQuery("#selectedrate_id" + i).val() != ""
            ? jQuery("#selectedrate_id" + i).val()
            : 0;

        // console.log(
        //   "sales_tax_type",
        //   price + " " + sales_tax_rate + " " + sales_tax_type + " " + quantity
        // );

        if (this.state.isChecked) {
          if (
            parseFloat(sales_tax_rate) > 0 &&
            parseInt(sales_tax_type) === 1
          ) {
            var tax =
              (price * parseFloat(sales_tax_rate)) /
              (100 + parseFloat(sales_tax_rate));
            // (value / (parseFloat(this.state.sales_tax_rate) + 100)) * 100;

            // var tax = price / parseFloat(sales_tax_rate)
            // var Total = parseFloat(price) - tax
            var Total = parseFloat(price);
          } else if (parseInt(sales_tax_type) === 2) {
            var tax = parseFloat(sales_tax_rate);
            // var Total = parseFloat(price) - tax;
            var Total = parseFloat(price);
          } else {
            var tax = 0;
            var Total = parseFloat(price);
          }
        } else {
          if (
            parseFloat(sales_tax_rate) > 0 &&
            parseInt(sales_tax_type) === 1
          ) {
            var tax = price * (parseFloat(sales_tax_rate) / 100);
            var Total = parseFloat(price);
          } else if (parseInt(sales_tax_type) === 2) {
            var tax = parseFloat(sales_tax_rate);
            var Total = parseFloat(price);
          } else {
            var tax = 0;
            var Total = parseFloat(price);
          }
        }

        // console.log("Total", Total);
        if (isNaN(Total)) {
          if (currid == id) {
          } else {
            jQuery("#subtotal" + id).val(0);
          }
        } else {
          if (currid == id) {
            //added on 19-04-2023 to refresh total of first item
            try {
              jQuery("#subtotal" + id).val(parseFloat(Total).toFixed(2));
            } catch (exception) {
              console.log(exception);
            }
            //end here
          } else {
            jQuery("#subtotal" + id).val(parseFloat(Total).toFixed(2));
          }
        }

        // } else {jQuery("#subtotal" + id).val(this.formatCurrency(this.state.currency_customer)(parseFloat(Total).toFixed(2)))}

        subTotal += Total;
        tax_total += tax;

        // console.log("subTotal", subTotal);
      }
      if (!this.state.isChecked) {
        let home_item_total =
          Number(Total) *
          Number(
            this.state.cus_rate_rate != undefined ? this.state.cus_rate_rate : 1
          );
        // Number(Total.toFixed(2)) *        comment1
        // Number(
        //   this.state.cus_rate_rate != undefined ? this.state.cus_rate_rate : 1
        // );

        // alert(this.state.exchange_value_ref)
        // console.log("acacac", this.state.cus_rate_rate);
        const data =
          this.state.rows &&
          this.state.rows.map((row, ind) => {
            const obj = this.state.number_of_columns_list.reduce(
              (acc, val, idx) => {
                // console.log("namenewval", this.state.number_of_columns_list)
                acc[val.column_name] = jQuery("#addtext" + idx + ind).val();
                return acc;
              },
              {}
            );
            return obj;
          });
        //   let arr =[]

        //   this.state.rows&&  this.state.rows.map((row,idx) =>{
        //     let array = []
        //     this.state.number_of_columns_list &&  this.state.number_of_columns_list.map((val,ind)=>{
        //       let name =val.column_name
        //       array.push({[name] : jQuery("#addtext" + ind +idx).val()})

        //   })
        //   arr.push(array)
        // })

        // console.log("redFunc", obj)
        var item_list = {
          item_name: item_name,
          descr: descr,
          quantity: quantity,
          price: price,
          unit_price: unit_price,
          category_id: myCat,
          tax_name: jQuery("#selectednow" + i).text(),
          tax_rate: jQuery("#selectedrate_id" + i).val(),
          tax_type: jQuery("#selectedtype_id" + i).val(),
          item_tax: tax.toFixed(2),
          item_total: Total.toFixed(2),
          home_item_total: Number(home_item_total).toFixed(2),
          custom_column: [...data],
        };

        // console.log("item_list1111", result);
      } else {
        if (
          (jQuery("#selectedtype_id" + i).val() != undefined &&
            jQuery("#selectedtype_id" + i).val() != null &&
            jQuery("#selectedtype_id" + i).val() == "1") ||
          jQuery("#selectedtype_id" + i).val() == "2"
        ) {
          var item_total = Number(Total - tax);
        } else {
          var item_total = 0;
        }

        let home_item_total =
          Number(item_total) *
          Number(
            this.state.cus_rate_rate != undefined ? this.state.cus_rate_rate : 1
          );

        const data =
          this.state.rows &&
          this.state.rows.map((row, ind) => {
            const obj = this.state.number_of_columns_list.reduce(
              (acc, val, idx) => {
                // console.log("namenewval", this.state.number_of_columns_list)
                acc[val.column_name] = jQuery("#addtext" + idx + ind).val();
                return acc;
              },
              {}
            );
            return obj;
          });

        //   let arr =[]

        //   this.state.rows&&  this.state.rows.map((row,idx) =>{
        //     let array = []
        //     this.state.number_of_columns_list &&  this.state.number_of_columns_list.map((val,ind)=>{
        //       array.push(jQuery("#addtext" + ind +idx).val())

        //   })
        //   arr.push(array)
        // })

        // console.log('arrarrarr',arr)
        var item_list = {
          item_name: jQuery("#item" + i).val(),
          descr: descr,
          quantity: quantity,
          price: item_total,
          unit_price: unit_price,
          category_id: myCat,
          tax_name: jQuery("#selectednow" + i).text(),
          tax_rate: jQuery("#selectedrate_id" + i).val(),
          tax_type: jQuery("#selectedtype_id" + i).val(),
          item_tax: tax.toFixed(2),
          item_total: item_total,
          home_item_total: Number(home_item_total).toFixed(2),
          custom_column: [...data],
        };
      }
      result.push(item_list);
      console.log(result);
      usedquanity &&
        usedquanity.length != 0 &&
        usedquantity.push({ usedquantity: quantity });
      if (salesarray && salesarray.length > 0) {
        salesarray[i].used_quanity = quantity;
      }
      if (estimatearray && estimatearray.length > 0) {
        estimatearray[i].used_quanity = quantity;
      }
      itemprice.push(parseFloat(price));
      // this.setState({subTotal:subTotal})
    }
    if (isNaN(subTotal)) {
      jQuery("#homecurrencytyt").html("0");
    } else {
      jQuery("#homecurrencytyt").html(Number(parseFloat(subTotal.toFixed(2))));
    }
    // else { jQuery("#homecurrencytyt").html(this.formatCurrency(this.state.currency_customer)(parseFloat(subTotal.toFixed(2)))) }
    if (isNaN(tax_total)) {
      jQuery("#home_tax_total").html("0");
    } else {
      jQuery("#home_tax_total").html(parseFloat(tax_total.toFixed(2)));
    }
    

    if (!this.state.isChecked) {
      var grandTotal = parseFloat(parseFloat(subTotal) + tax_total);
      if (isNaN(grandTotal)) {
        jQuery("#home_grand_total").html("0");
        const toWords = new ToWords();

        jQuery("#amount_in_words").val(`${toWords.convert(0)} only`);
      } else {
        jQuery("#home_grand_total").html(
          this.formatCurrency("EUR")(Number(parseFloat(grandTotal).toFixed(2)))
        );

        const toWords = new ToWords();

        if (
          config.currencies_using_word_lakh.indexOf(
            this.state.CurrentSelectedCurr
          ) >= 0
        ) {
          // jQuery("#amount_in_words").val(
          //  `${ toWords.convert(Number(parseFloat(grandTotal).toFixed(2)))} only`
          // );

           jQuery("#amount_in_words").val(
            this.amountToWordsConversionBasedOnCurrency(grandTotal,this.state.CurrentSelectedCurr)
          );

          
        } else {
          // jQuery("#amount_in_words").val(
          //  `${ _.startCase(
          //     _.toLower(writtenNumber(parseFloat(grandTotal).toFixed(2)))
          //   )} only`
          // );

          jQuery("#amount_in_words").val(
            this.amountToWordsConversionBasedOnCurrency(grandTotal,this.state.CurrentSelectedCurr)
          );
        }

        this.setState({
          grandTotal: this.formatCurrency("EUR")(
            Number(parseFloat(grandTotal).toFixed(2))
          ),
        });
      }
    } else {
      // alert('hjj')
      var grandTotal = parseFloat(subTotal).toFixed(2);
      if (isNaN(grandTotal)) {
        jQuery("#home_grand_total").html("0");
        const toWords = new ToWords();

        jQuery("#amount_in_words").val(`${toWords.convert(0)} only`);
      } else {
        jQuery("#home_grand_total").html(parseFloat(grandTotal).toFixed(2));

        const toWords = new ToWords();

        if (
          config.currencies_using_word_lakh.indexOf(
            this.state.CurrentSelectedCurr
          ) >= 0
        ) {
          // jQuery("#amount_in_words").val(
          //  `${toWords.convert(parseFloat(grandTotal).toFixed(2))} only`
          // );

          jQuery("#amount_in_words").val(
            this.amountToWordsConversionBasedOnCurrency(grandTotal,this.state.CurrentSelectedCurr)
           );
        } else {
          // jQuery("#amount_in_words").val(
          // `${  _.startCase(
          //     _.toLower(writtenNumber(parseFloat(grandTotal).toFixed(2)))
          //   )} only`
          // );

          jQuery("#amount_in_words").val(
            this.amountToWordsConversionBasedOnCurrency(grandTotal,this.state.CurrentSelectedCurr)
           );
        }

        this.setState({
          grandTotal: this.formatCurrency("EUR")(
            Number(parseFloat(grandTotal).toFixed(2))
          ),
        });
      }
      let fore_grandtotal =
        jQuery("#home_grand_total").html() != undefined
          ? Number(jQuery("#home_grand_total").html())
          : 0.0;
      let fore_tax =
        jQuery("#home_tax_total").html() != undefined
          ? Number(jQuery("#home_tax_total").html())
          : 0.0;
      let fore_subtotal = (fore_grandtotal - fore_tax).toFixed(2);
      jQuery("#homecurrencytyt").html(Number(fore_subtotal));

      
      setTimeout(() => {
        let home_grandtotal =
          jQuery("#foreign_grand_total").html() != undefined
            ? Number(jQuery("#foreign_grand_total").html())
            : 0.0;
        let home_tax =
          jQuery("#foreign_tax_total").html() != undefined
            ? Number(jQuery("#foreign_tax_total").html())
            : 0.0;
        let hme_subtotal = (home_grandtotal - home_tax).toFixed(2);

      }, 500);
    }

    const add = (a, b) => a + b;
    const trial = itemprice.length > 0 ? itemprice.reduce(add) : 0;
    if (isNaN(trial)) {
      var sum = 0;
    } else {
      var sum = trial;
    }

    let foreign_currency = this.state.CurrentSelectedCurr;
    let subTotalValue = subTotal;

    // let nope;
    // var date_formated;
    // let date = jQuery("#invoice_date").val();
    // if (date != undefined && date != null && date != "") {
    //   var array = date.split("/");
    //   date_formated = array[2] + "-" + array[1] + "-" + array[0];

    //   let todayDate = new Date();
    //   todayDate.setHours(23, 59, 59, 998);
    //   if (new Date(date_formated).getTime() > todayDate.getTime()) {
    //     if (
    //       is_date_changed == true &&
    //       this.state.pre_selected_date != date_formated
    //     ) {
    //       this.setState({ pre_selected_date: date_formated });
    //       if (this.state.CurrentSelectedCurr == this.state.clientHomeCurrency) {
    //         Swal.fire("You are entering a transaction for Future Date!");
    //       } else {
    //         Swal.fire(
    //           "You are entering a transaction for Future Date!",
    //           "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction"
    //         );
    //       }
    //     }
    //     date_formated = moment().format("YYYY-MM-DD");
    //   }
    //   nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    // } else {
    //   date_formated = moment().format("YYYY-MM-DD");
    //   nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    // }

//    let res = nope.concat(foreign_currency);
  //  if (foreign_currency != "") {
      // await fetch(res)
      //   .then(async (response) => await response.json())
      //   .then(async (data) => {
      //     try {
      //       {
              let newObj = {};

              // if (data.success == true) {
              //   newObj = this.rename(
              //     data.quotes,
              //     this.state.CurrentSelectedCurr
              //   );
              //   let currencyAr = [];
              //   let first = newObj;
              //   currencyAr.push({
              //     date: data.start_date,
              //     rate:
              //       first[data.start_date] &&
              //       first[data.start_date][
              //         this.state.CurrentSelectedCurr +
              //           this.state.clientHomeCurrency
              //       ]
              //         ? first[data.start_date][
              //             this.state.CurrentSelectedCurr +
              //               this.state.clientHomeCurrency
              //           ].toFixed(4)
              //         : parseFloat(1).toFixed(4),
              //   });
              //   let value = currencyAr[0].rate;
              //   jQuery("#getMe").val(parseFloat(value))
              //   newObj[this.state.clientHomeCurrency] = parseFloat(value);
              // }
            
              // no need to call this - 20240703
              // this.findInSubAccountList();
              // no need to call this - end - 20240703


              // if (
              //   jQuery("#getMe").val() != undefined &&
              //   jQuery("#getMe").val() != null &&
              //   jQuery("#getMe").val() != ""
              // ) {
              //   this.state.cus_rate_rate = isNaN(Number(jQuery("#getMe").val()))
              //     ? 0.000001
              //     : Number(jQuery("#getMe").val());
              //   this.setState(
              //     {
              //       cus_rate_rate: isNaN(Number(jQuery("#getMe").val()))
              //         ? 0.000001
              //         : Number(jQuery("#getMe").val()),
              //     }
              //   );
              // }

              // if (
              //   this.state.cus_rate_rate != undefined &&
              //   this.state.cus_rate_rate != "" &&
              //   is_date_changed == false &&
              //   this.state.is_date_changed_once == false
              // ) {
                const val = this.state.cus_rate_rate?Number(this.state.cus_rate_rate):1;
                // var todayValue = newObj;
                // var exchange_value = val;
                var grand_total_home_currency = val * grandTotal;
                var sub_total_home_currency = val * subTotalValue;
                var tax_home_currency = val * tax_total;
             // } 
              // else if (this.state.is_exchange_rate_changed_once == true) {
              //   const val = parseFloat(jQuery("#Exchange").val());
              //   var todayValue = newObj;
              //   var exchange_value = val;
              //   var grand_total_home_currency = val * grandTotal;
              //   var sub_total_home_currency = val * subTotalValue;
              //   var tax_home_currency = val * tax_total;

              //   this.setState(
              //     {
              //       cus_rate_rate: val,
              //     },
              //     () => {
              //       jQuery("#Exchange").val(val);
              //     }
              //   );
              // } 
              // else {
                // var todayValue = newObj;
                // var exchange_value = await todayValue[
                //   this.state.clientHomeCurrency
                // ];
                // var grand_total_home_currency =
                //   (await todayValue[this.state.clientHomeCurrency]) *
                //   grandTotal;
                // var sub_total_home_currency =
                //   (await todayValue[this.state.clientHomeCurrency]) *
                //   subTotalValue;
                // var tax_home_currency =
                //   (await todayValue[this.state.clientHomeCurrency]) * tax_total;

                
                // this.setState(
                //   {
                //     cus_rate_rate: Number(
                //       todayValue[this.state.clientHomeCurrency].toFixed(4)
                //     ),
                //   },
                //   () => {
                //     jQuery("#Exchange").val(this.state.cus_rate_rate);
                //     jQuery("#getMe").val(this.state.cus_rate_rate);
                //   }
                // );
             // }

              // var todayValue = await newObj;
              // if (
              //   jQuery("#getMe").val() != undefined &&
              //   jQuery("#getMe").val() != null &&
              //   jQuery("#getMe").val() != ""
              // ) {
              //   var exchange_value_ref = isNaN(Number(jQuery("#getMe").val()))
              //     ? 0.000001
              //     : Number(jQuery("#getMe").val());
              // } else {
              //   var exchange_value_ref =
              //     todayValue[this.state.clientHomeCurrency];
              // }

              this.setState(
                {
                  myarray: result.reverse(),
                  mysalesarray: salesarray,
                  myestimatearray: estimatearray,
                  // exchange_value: Number(exchange_value.toFixed(4)),
                  // exchange_value_ref: Number(exchange_value_ref.toFixed(4)),
                  grand_total_home_currency:
                    grand_total_home_currency.toFixed(2),
                  sub_total_home_currency: this.state.isChecked
                    ? (sub_total_home_currency - tax_home_currency).toFixed(2)
                    : sub_total_home_currency.toFixed(2),
                  tax_home_currency: tax_home_currency.toFixed(2),
                },
                () => {
                  console.log("mysalesarray....", this.state.mysalesarray);
                  this.val_me();
                }
              );
            // }
          // } catch (error) {
          //   jQuery("#invoice_curr_id option")
          //     .prop("selected", false)
          //     .trigger("change");
          //   jQuery("#account_id").val("");
          // }
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
    // }
  };

  loadExchangeRate = async(is_date_changed = false) =>{
    let foreign_currency = this.state.CurrentSelectedCurr;
    let nope;
    var date_formated;
    let date = jQuery("#invoice_date").val();
    if (date != undefined && date != null && date != "") {
      var array = date.split("/");
      date_formated = array[2] + "-" + array[1] + "-" + array[0];

      let todayDate = new Date();
      todayDate.setHours(23, 59, 59, 998);
      if (new Date(date_formated).getTime() > todayDate.getTime()) {
        if (
          is_date_changed == true &&
          this.state.pre_selected_date != date_formated
        ) {
          this.setState({ pre_selected_date: date_formated });
          if (
            this.state.CurrentSelectedCurr == this.state.clientHomeCurrency
          ) {
            Swal.fire("You are entering a transaction for Future Date!");
          } else {
            Swal.fire(
              "You are entering a transaction for Future Date!",
              "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction"
            );
          }
        }
        date_formated = moment().format("YYYY-MM-DD");
      }

      nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    } else {
      date_formated = moment().format("YYYY-MM-DD");
      nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    }

    let res = nope.concat(foreign_currency);
    if (foreign_currency != "") {
 await fetch(res)
 .then(async (response) => await response.json())
 .then(async (data) => {
   try {
     if (data.success == true) {
       let newObj = this.rename(
         data.quotes,
         this.state.CurrentSelectedCurr
       );
       let currencyAr = [];
       let first = newObj;
       currencyAr.push({
         date: data.start_date,
         rate:
           first[data.start_date] &&
           first[data.start_date][
             this.state.CurrentSelectedCurr +
               this.state.clientHomeCurrency
           ]
             ? first[data.start_date][
                 this.state.CurrentSelectedCurr +
                   this.state.clientHomeCurrency
               ].toFixed(4)
             : parseFloat(1).toFixed(4),
       });
       let val = currencyAr.length > 0 && currencyAr[0].rate?currencyAr[0].rate:0;
       newObj[this.state.clientHomeCurrency] = parseFloat(val);

       let todayValue = newObj;
       let exchange_value = todayValue[
         this.state.clientHomeCurrency
       ];

       this.setState({
          cus_rate_rate: parseFloat(exchange_value.toFixed(4)),
        },()=>{
         this.handleChangeItems("", this.state.rows.length - 1);
        });
     } else {
        console.log("Failed to load exchange rate");
        let val = parseFloat(0);
        this.setState({
          cus_rate_rate: parseFloat(val.toFixed(4)),
        },()=>{ 
         this.handleChangeItems("", this.state.rows.length - 1);
        });
     }
   } catch (error) {
    console.log("Failed to get exchange rate",error);
   }
 })
 .catch((err) => {
   console.log(err);
 });
    }
  }

  control_addButton = () => {
    // let item_check = jQuery(
    //   `#coulmn${this.state.rows.length - 1}${this.state.coulmns.length - 1}`
    // ).val()

    // console.log("myarray", this.state.myarray);

    if (
      this.state.rows.length > 0
      // this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
    ) {
      return (
        <a href='javascript:;' class='add-input' onClick={this.addRow}>
          ADD ROW
        </a>
      );
    }
  };

  addRow = () => {
    if (
      this.state.myarray.length >
      this.state.rows.length - 1
      // &&
      // this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
    ) {
      var rows = this.state.rows;
      rows.push("row" + (this.state.initial_value + 1));

      this.setState({
        isAdd: false,
        initial_value: this.state.initial_value + 1,
      });

      this.setState({ rows: rows }, () => {
        this.addSerialNumber();
        jQuery("#quantity" + this.state.initial_value).val(1);
      });
    } else {
      this.setState({ isAdd: true });
    }
  };

  copyRow = (item_id) => {
    // if (
    //   this.state.myarray.length > this.state.rows.length - 1 &&
    //   this.state.myarray[this.state.myarray.length - 1].price > 0 &&
    //   this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
    //   this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
    // ) {
    //   var rows = this.state.rows
    //   rows.push('row' + (this.state.initial_value + 1))

    //   this.setState({ isAdd: false, initial_value: this.state.initial_value + 1 })

    //   this.setState({ rows: rows })
    // } else {
    //   this.setState({ isAdd: true })
    // }
    var THIS = this;
    if (
      THIS.state.myarray.length > THIS.state.rows.length - 1 &&
      THIS.state.myarray[THIS.state.myarray.length - 1].price > 0 &&
      THIS.state.myarray[THIS.state.myarray.length - 1].item_name.length > 0
      // &&THIS.state.myarray[THIS.state.myarray.length - 1].category_id.length > 0
      //&& item_check != ''
    ) {
      jQuery(".error_tbl_fld").css("display", "none");
      var rows = this.state.rows;
      rows.push("row" + (parseInt(this.state.initial_value) + 1));
      this.setState({
        isAdd: false,
        initial_value: parseInt(this.state.initial_value) + 1,
      });
      this.setState({ rows: rows, isTablefilled: false }, () => {
        this.copyRowVal(item_id);
      });
    } else {
      jQuery(".error_tbl_fld").css("display", "block");
      this.setState({ isTablefilled: true });
    }
  };

  copyRowVal(item_id) {
    var THIS = this;

    // console.log("this rows_length", jQuery("#item" + item_id).val(), jQuery("#descr" + item_id).val());
    setTimeout(function () {
      jQuery("#item" + THIS.state.initial_value).val(
        jQuery("#item" + item_id).val()
      );

      // richtext-description-20240410
      // jQuery("#descr" + THIS.state.initial_value).val(
      //   jQuery("#descr" + item_id).val()
      // );

      
      THIS.setDescriptionContent(THIS.state[`descriptionEditorState${item_id}`]?draftToHtml(
        convertToRaw(THIS.state[`descriptionEditorState${item_id}`].getCurrentContent())
      ):'',THIS.state.initial_value);

      jQuery("#quantity" + THIS.state.initial_value).val(
        jQuery("#quantity" + item_id).val()
      );
      jQuery("#unit_price" + THIS.state.initial_value).val(
        jQuery("#unit_price" + item_id).val()
      );
      jQuery("#selectednow" + THIS.state.initial_value).html(
        jQuery("#selectednow" + item_id).html()
      );
      jQuery("#selectednow_id" + THIS.state.initial_value).html(
        jQuery("#selectednow_id" + item_id).html()
      );
      jQuery("#selectedrate_id" + THIS.state.initial_value).val(
        jQuery("#selectedrate_id" + item_id).val()
      );
      jQuery("#selectedtype_id" + THIS.state.initial_value).val(
        jQuery("#selectedtype_id" + item_id).val()
      );
      jQuery("#subtotal" + THIS.state.initial_value).val(
        jQuery("#subtotal" + item_id).val()
      );

      jQuery("#categry_id" + THIS.state.initial_value).val(
        jQuery("#categry_id" + item_id).val()
      );

      jQuery("#cust_items" + THIS.state.initial_value).val(
        jQuery("#cust_items" + item_id).val()
      );

      if (THIS.state.number_of_columns_list != undefined) {
        THIS.state.number_of_columns_list.forEach((it, ind) => {
          jQuery("#dynamic" + ind + THIS.state.initial_value).val(
            jQuery("#dynamic" + ind + item_id).val()
          );
        });
      }

      THIS.addSerialNumber();

      THIS.handleChangeItems("", THIS.state.rows.length - 1);
    }, 500);
  }

  delete_Rows = () => {
    var itemid = this.state.specific_id_delete;
    var rows_actual = this.state.rows;
    var myarray = this.state.myarray;
    // console.log("jkdghkshakd", this.state.rows);
    if (this.state.rows.length > 1) {
      if (itemid > -1) {
        rows_actual.splice(itemid, 1);
      }

      this.setState({ rows: rows_actual, initial_value: parseInt(this.state.initial_value)-1 }, () => {
        this.handleChangeItems("", this.state.rows.length - 1);
      });

      // console.log('After_delete_par_row', this.state.rows)
    } else {
      this.state.number_of_columns_list.forEach((x, y) => {
        jQuery("#dynamic" + y + itemid + "" + "" + "option")
          .prop("selected", false)
          .trigger("change");
        jQuery("#dynamic" + y + itemid).val("");
      });
      jQuery("#item0").val("");

      // richtext-description-20240410
      //jQuery("#descr0").val("");
      this.setDescriptionContent('',0);

      jQuery("#quantity0").val("");
      jQuery("#unit_price0").val("");
      jQuery("#selectednow0").html("Choose");
      jQuery("#selectednow_id0").html("NO_VALUE");
      jQuery("#selectedrate_id0").val("0");
      jQuery("#selectedtype_id0").val("0");

      jQuery(".no-bg").val("");
      this.setState({ myarray: [] }, () => {
        this.handleChangeItems("", this.state.rows.length - 1);
      });
    }
    this.addSerialNumber();
    window.jQuery("#modal_delete").modal("hide");
  };
  /* =================== */

  get_invoice_list = () => {
    let input = {
      client_id: this.state.logged_client_id,
      user_id: this.state.logged_user_id,
      selected_template_type: 1,
    };
    FetchAllApi.get_edit_invoice_html(input, (err, response) => {
      // console.log("defaultcatejhwdjkjhgorylist", response);
      if (response.status === 1) {
        this.setState({
          response: response,
          template_id: response.default_template_id,
        });
        this.temChanged(response.default_template_id);
        // response.list.map((temp) => {
        //   if (temp.id == 2) {
        //     this.setState({
        //       template_id: temp.id
        //     });
        //   }
        // })
      } else {
        this.setState({});
      }
    });
  };

  pdf_generate = () => {
    // alert()
  };
  delete_invoice_list = (id) => {
    let client_id = this.state.logged_client_id;
    let template_id = id;
    FetchAllApi.delete_invoice_template(
      client_id,
      template_id,
      (err, response) => {
        // console.log("defaultcatejhwdjkjhgorylist", response);
        alert(response.message);
        if (response.status === 1) {
          this.get_invoice_list();
          this.setState({});
        } else {
          this.setState({});
        }
      }
    );
  };

  apply_template_format = (id, name, html) => {
    // console.log("html_content", html);

    this.setState({ html_content: html });

    jQuery("#" + id).addClass("active");
    this.setState({
      active: id,
      selected_templateName: name,
      // JSON.parse(this.state.response.list[id].properties).footer.footeroptionsthankmessage
    });
    if (id !== this.state.active)
      jQuery("#" + this.state.active).removeClass("active");

    let properties_values = JSON.parse(this.state.response.list[id].properties);
    // console.log("djshgfjksdh", properties_values);
    if (properties_values.items.itemoptioninvoicenostatus === "none") {
      // jQuery('#itemoptioninvoicenostatus').hide()
    } else {
      jQuery("#itemoptioninvoicenostatus").show();
    }
    if (properties_values.items.itemoptionbilltostatus === "none") {
      jQuery("#itemoptionbilltostatus").hide();
    } else {
      jQuery("#itemoptionbilltostatus").show();
    }
    if (properties_values.items.itemoptionDatestatus === "none") {
      jQuery("#itemoptionDatestatus").hide();
    } else {
      jQuery("#itemoptionDatestatus").show();
    }
    if (properties_values.items.itemoptionshippingtostatus === "none") {
      jQuery("#itemoptionshippingtostatus").hide();
    } else {
      jQuery("#itemoptionshippingtostatus").show();
    }

    if (properties_values.footer.footeroptionthanksmessagestatus === "none") {
      jQuery("#footeroptionthanksmessagestatus").hide();
    } else {
      jQuery("#footeroptionthanksmessagestatus").show();
      // jQuery("#footeroptionthanksmessages").val(
      //   properties_values.footer.footeroptionsthankmessage
      // );

      this.setThankingMessage(
        properties_values.footer.footeroptionsthankmessage
      );
    }
    if (properties_values.footer.footertermsstatus === "none") {
      // alert('block')
      jQuery("#termsandconditions").hide();
    } else {
      jQuery("#termsandconditions").show();

      // jQuery("#termcondmsg").val(properties_values.footer.footertermsText);
      this.setTermsAndConditions(properties_values.footer.footertermsText);
    }
  };

  componentDidUpdate() {
    window
      .jQuery(".input-group.date")
      .datepicker({ format: "dd/mm/yyyy", autoclose: true }); //DidUpdate
    jQuery("#mytable").on("click", ".bootstrap-select", function () {
      jQuery(".table-responsive").css("overflow", "visible");
    });
    console.log(this.state.list_of_attachments, 'res')
    jQuery(document).click(function (e) {
      var container = jQuery("#mytable");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        // alert("Outside");
        jQuery(".table-responsive").removeAttr("style");
      } else {
        // alert("Inside");
        jQuery(".table-responsive").css("overflow", "visible");
      }
    });
    jQuery("#mytable").on("click", ".bootstrap-select", function () {
      jQuery(".table-responsive").css("overflow", "visible");
    });
    window.addEventListener("click", function (e) {
      if (
        document.getElementById("mytable") &&
        document.getElementById("mytable").contains(e.target)
      ) {
        //if (document.getElementById('mytable').contains(e.target)) {
        // click inside
      } else {
        jQuery(".table-responsive").removeAttr("style");
      }
    });

    window.jQuery(".selectpicker").selectpicker("refresh");
  }
  getColList = () => {
    FetchAllApi.get_invoice_column_list(
      this.state.logged_user_id,
      (err, response) => {
        // console.log("LIST RETURNED=jjkjk>", response);
        if (response.status === 1) {
          // alert('hi')
          // this.setState({
          //   number_of_columns_list: response.list
          // })
        } else {
          // this.setState({
          //   number_of_columns_list: []
          // })
        }
      }
    );
  };
  changeDate = (fromdate) => {
    let date = jQuery("#invoice_date").val();
    if (date && date != undefined) {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      this.setState({ payment_date: date_formated });
      // alert(date_formated);
    }
  };

  custConverter = (x) => {
    let date = x;
    if (date && date != undefined) {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      // alert(date_formated)
      return date_formated;
    }
  };
  getItems = (text, id) => {
    // var client_id = localStorage.getItem("logged_client_id") ;
    var client_id = this.state.logged_client_id;
    let from_settings = 0;
    let filter_id = 2; //1
    FetchAllApi.sales_product_item_list(
      client_id,
      filter_id,
      (err, response) => {
        // console.log("vxxxendor_names", response);

        if (response.status === 1) {
          this.setState({ ItemList: response.list });
          if (response.list) {
            var opt = response.list.map((item) => {
              return (
                <>
                  <option
                    value={
                      item.rate +
                      "=" +
                      item.description +
                      "=" +
                      item.account_name_category +
                      "=" +
                      item.item_name +
                      "=" +
                      item.item_id
                    }
                    data-status={item.id}
                    disabled={item.status == 2 ? true : false}
                    style={{ display: item.status == 2 ? "none" : "block" }}
                  >
                    {item.item_name}
                  </option>
                </>
              );
            });
          }

          this.setState(
            { sales_product_item_list: response.list, opt: opt },
            () => {
              if (text !== undefined && id !== undefined) {
                response.list.map((ite, ii) => {
                  if (ite.item_name == text) {
                    var makeUse =
                      ite.rate +
                      "=" +
                      ite.description +
                      "=" +
                      ite.account_name_category +
                      "=" +
                      ite.item_name +
                      "=" +
                      ite.item_id;

                    // alert(makeUse)

                    jQuery("#cust_items" + id).val(makeUse);
                    window.jQuery("#cust_items" + id).selectpicker("refresh");

                    // richtext-description-20240410
                    // jQuery("#descr" + id).val(ite.description);

                    this.setDescriptionContent(ite.description,id);

                    jQuery("#unit_price" + id).val(ite.rate);
                    //below

                    jQuery("#categry_id" + id)
                      .val(ite.account_name_category)
                      .trigger("change");

                    window.jQuery("#categry_id" + id).selectpicker("refresh");

                    jQuery("#item" + id).val(ite.item_name);

                    jQuery("#itemsid" + id).val(ite.item_id);

                    // for table drop down
                    jQuery("#mytable").click(".bootstrap-select", function (e) {
                      jQuery(".table-responsive").css("overflow", "visible");
                    });

                    jQuery(document).click(function (e) {
                      var container = jQuery("#mytable");

                      // if (!container.is(e.target) && container.has(e.target).length === 0) {
                      //   // alert("Outside");
                      //   jQuery(".table-responsive").removeAttr("style");
                      // } else {
                      //   // alert("Inside");
                      //   jQuery(".table-responsive").css("overflow", "visible");
                      // }
                    });
                    this.handleChangeItems(
                      0,
                      this.state.rows.length - 1
                    );
                    // for table drop down
                  }
                });
              }
            }
          );
        } else {
        }
      }
    );
  };

  getColumns = () => {
    this.setState({ number_of_columns_list: [] });
    var coreData = {
      user_id: this.state.logged_user_id,
      client_id: this.state.logged_client_id,
    };
    FetchAllApi.getAllColumns(coreData, (err, response) => {
      // console.log("new document", response.message);
      if (response.status === 1) {
        this.setState({ number_of_columns_list: response.list[0].columns });
      } else {
      }
    });
  };

  // setColumn =()=>{
  //   this.state.number_of_columns_list.map((val, idx) => {

  //                                 let value = val.column_name

  //                                 this.state.rows.map((inv,i)=>{

  //                                     jQuery("#addtext" + idx+i).val(val.options[val.options-1])

  //                                   })
  //                               })
  // };

  addSerialNumber = () => {
    var i = 0;
    jQuery("#mytable  tr").each(function (index) {
      jQuery(this)
        .find("td:nth-child(2)")
        .html(index - 1 + 1);
    });
  };
  get_currencies = () => {
    this.paymentDateExchangeRate();

    // commented on 2022-07-12
    // if (this.state.clientHomeCurrency != '') {
    //   fetch(
    //     `https://api.currencylayer.com/live?access_key=${config.api_key}&source=${this.state.clientHomeCurrency}`
    //   )
    //     .then((response) => response.json())
    //     .then(async (data) => {
    //       let newObj = {}
    //       Object.keys(data.quotes).map((key) => {
    //         let newKey = key.replace(this.state.clientHomeCurrency, '')
    //         Object.assign(newObj, { [newKey]: data.quotes[key] })
    //       })

    //       const currencyAr = [];
    //       let first = newObj;
    //       for (const key in first) {
    //         currencyAr.push(key);
    //       }
    //       this.setState({ currencies: currencyAr, currency_clone: currencyAr }, () => {

    //       });
    //     });
    // }
  };
  // add_third_party_customer

  add_third_party_customer = () => {
    setInterval(() => {
      // alert('hire')

      var party_customer = localStorage.getItem("third_party_customer");
      if (
        party_customer !== undefined &&
        party_customer !== "" &&
        party_customer !== null &&
        party_customer === "yes1"
      ) {
        // alert('hi')
        jQuery("#third_account_id").val(
          localStorage.getItem("third_party_customer_id")
        );
        this.setState({
          third_party_value: localStorage.getItem("third_party_customer_id"),
        });
        this.fetchThirdPartyNames(2);
        // this.setState({ selectCustomer: newlyCreatedID });

        // localStorage.setItem("third_party_customer_id", "");
        localStorage.setItem("third_party_customer", "");
      }
    }, 3000);
  };

  add_third_party_vendor = () => {
    setInterval(() => {
      // alert('hire')

      var party_customer = localStorage.getItem("third_party_customer");
      if (
        party_customer !== undefined &&
        party_customer !== "" &&
        party_customer !== null &&
        party_customer === "yes2"
      ) {
        // alert('hi')
        jQuery("#third_account_id").val(
          localStorage.getItem("third_party_customer_id")
        );
        this.setState({
          third_party_value: localStorage.getItem("third_party_customer_id"),
        });
        this.fetchThirdPartyNames(5);
        // this.setState({ selectCustomer: newlyCreatedID });

        // localStorage.setItem("third_party_customer_id", "");
        localStorage.setItem("third_party_customer", "");
      }
    }, 3000);
  };

  watchIsCustomerAdded = () => {
    setInterval(() => {
      var checkMe = localStorage.getItem("customer_added");
      if (
        checkMe !== undefined &&
        checkMe !== "" &&
        checkMe !== null &&
        checkMe === "yes" &&
        this.state.is_need_to_change_customer == true
      ) {
        let newlyCreatedID = localStorage.getItem("customer_added_id");

        this.setState({
          selectCustomer: newlyCreatedID,
          is_need_to_change_customer: false,
        });
        this.customerjoblist(newlyCreatedID);
        this.handleChange(newlyCreatedID);

        // 18-10-2022 - Newly added - if any occur after adding customer pls check here
        this.getItems();

        // for customer list call

        var client_id = this.state.logged_client_id;
        var from_customer_receive_payment = 0;
        let filter_id = 2;
        FetchAllApi.customer_and_job_list(
          client_id,
          from_customer_receive_payment,
          filter_id,
          (err, response) => {
            if (response.status === 1) {
              let myArray = response.list;

              this.setState(
                { customer_and_job_list: response.list },

                () => {
                  myArray.map((item) => {
                    if (item.id == newlyCreatedID) {
                      jQuery("#variable_pay_type").val(item.id);
                    }
                  });

                  window.jQuery("#variable_pay_type").selectpicker("refresh");
                  localStorage.setItem("customer_added_id", null);
                  localStorage.setItem("customer_added", null);
                  localStorage.setItem("customerId", "");
                }
              );

              // for fill invoice to and shipping to

              let variable_pay_type = newlyCreatedID;
              localStorage.setItem(
                "customer",
                newlyCreatedID + "=" + variable_pay_type
              );

              this.setState(
                {
                  selectCustomer: variable_pay_type,
                  vendorName: jQuery("#variable_pay_type").val(),
                },
                () => {
                  // alert(this.state.selectCustomer)
                }
              );

              this.Basic_info(variable_pay_type);
              this.setState({ myCusVarPay: variable_pay_type });
              this.customerjoblist(variable_pay_type);

              // for fill invoice to and shipping to
            } else {
              localStorage.setItem("customer_added_id", null);
              localStorage.setItem("customer_added", null);
            }
          }
        );

        //  for customer list call

        // localStorage.setItem("customer_added", null);
        // localStorage.setItem("customer_added", "");
      }
    }, 3000);
  };

  watchJobAdded = () => {
    setInterval(() => {
      var checkMee = localStorage.getItem("job_added");
      if (
        checkMee !== undefined &&
        checkMee !== "" &&
        checkMee !== null &&
        checkMee === "yes"
      ) {
        let newlyCreatedID = localStorage.getItem("job_added_id");
        let myvar = this.state.myCusVarPay || this.state.selectCustomer;
        this.setState({ job_id: newlyCreatedID, JobId: newlyCreatedID });

        // alert("new job is added...");
        this.customerjoblist(myvar, newlyCreatedID);

        localStorage.setItem("job_added", "");
      }
    }, 3000);
  };

  watchCoulmnUpdated = () => {
    setInterval(() => {
      var checkMeee = localStorage.getItem("is_coulmn_updated");

      if (
        checkMeee !== undefined &&
        checkMeee !== "" &&
        checkMeee !== null &&
        checkMeee === "yes"
      ) {
        this.changeState();

        localStorage.setItem("is_coulmn_updated", "");
      }
    }, 3000);
  };

  paymentDateExchangeRate = () => {
    let nope;

    var date_formated;
    let date = null;
    if (date != undefined && date != null && date != "") {
      var array = date.split("/");
      date_formated = array[2] + "-" + array[1] + "-" + array[0];
      nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    } else {
      date_formated = moment().format("YYYY-MM-DD");
      nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
    }

    let res = nope.concat(this.state.CurrentSelectedCurr);
    if (this.state.CurrentSelectedCurr != "") {
      fetch(res)
        .then((response) => response.json())
        .then(async (data) => {
          if (data.success == true) {
            let newObj = this.rename(
              data.quotes,
              this.state.CurrentSelectedCurr
            );
            let currencyAr = [];
            let first = newObj;
            currencyAr.push({
              date: data.start_date,
              rate:
                first[data.start_date] &&
                first[data.start_date][
                  this.state.CurrentSelectedCurr + this.state.clientHomeCurrency
                ]
                  ? first[data.start_date][
                      this.state.CurrentSelectedCurr +
                        this.state.clientHomeCurrency
                    ].toFixed(4)
                  : parseFloat(1).toFixed(4),
            });
            let value = currencyAr[0].rate;
            jQuery("#payment_exchange_rate").val(value);
          }
        });
    }
  };

  // set default currency
  set_default_currency = async () => {
    if (jQuery("#paid_status").val() === "Partially paid--invoice") {
      alert("you cannot change currency to partially paid invoices");
    } else {
      var currency = localStorage.getItem("default_currency");

      // To update payment exchange rate
      let nope;
      var date_formated;
      let date = moment().format("DD/MM/YYYY");
      if (date != undefined && date != null && date != "") {
        var array = date.split("/");
        date_formated = array[2] + "-" + array[1] + "-" + array[0];
        nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
      } else {
        date_formated = moment().format("YYYY-MM-DD");
        nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
      }

      let res = nope.concat(currency);
      await fetch(res)
        .then(async (response) => await response.json())
        .then(async (data) => {
          if (data.success == true) {
            let newObj = this.rename(data.quotes, currency);
            let currencyAr = [];
            let first = newObj;
            currencyAr.push({
              date: data.start_date,
              rate:
                first[data.start_date] &&
                first[data.start_date][currency + this.state.clientHomeCurrency]
                  ? first[data.start_date][
                      currency + this.state.clientHomeCurrency
                    ].toFixed(4)
                  : parseFloat(1).toFixed(4),
            });
            let value = currencyAr[0].rate;
            jQuery("#payment_exchange_rate").val(value);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // To update payment exchange rate

      // jQuery("#Exchange").val("");

      this.setState(
        {
          CurrentSelectedCurr: currency,
          duplicateVar: currency,
        },
        () => {
          // this.handleChangeItems(0, this.state.rows.length - 1);
          // setTimeout(() => {
          //   this.setState(
          //     {
          //       cus_rate_rate: this.state.exchange_value_ref,
          //     },
          //     () => {
          //       this.handleChangeItems(0, this.state.rows.length - 1);
          //     }
          //   );
          // }, 2000);
          this.loadExchangeRate(false);
        }
      );
    }
  };

  async componentDidMount() {
    window
      .jQuery(".input-group.date")
      .datepicker({ format: "dd/mm/yyyy", autoclose: true }); //DidUpdate

    FetchAllApi.get_data(this.state.logged_client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ entity_details: response });
        console.log(this.state.entity_details);
      }
    });



    // window.jQuery('#asking_password_for_delete').modal('show')
    this.sales_default_due_date_terms();

    jQuery("#mytable").click(".bootstrap-select", function (e) {
      jQuery(".table-responsive").css("overflow", "visible");
    });

    jQuery(document).click(function (e) {
      var container = jQuery("#mytable");

      // if (!container.is(e.target) && container.has(e.target).length === 0) {
      //   // alert("Outside");
      //   jQuery(".table-responsive").removeAttr("style");
      // } else {
      //   // alert("Inside");
      //   jQuery(".table-responsive").css("overflow", "visible");
      // }
    });

    // window.addEventListener('click', function (e) {
    // console.log('sdhfgafha', document.getElementById('mytable'))
    //   if (document.getElementById('mytable').contains(e.target)) {
    //     // click inside
    //   } else {
    //     jQuery(".table-responsive").removeAttr("style");
    //   }
    // });

    await this.add_third_party_customer();
    await this.add_third_party_vendor();

    jQuery(document).on("keyup", ".hello .bs-searchbox input", function (e) {
      // alert(jQuery(this).val());
      let jj = jQuery(this).val();
      if (jj.length > 0) {
        jQuery(
          ".add-new.bootstrap-select .dropdown-menu ul li:first-child a "
        ).hide();
      } else {
        jQuery(
          ".add-new.bootstrap-select .dropdown-menu ul li:first-child a "
        ).show();
      }
    });

    await this.get_client_home_currency();
    await this.custConverter("12/05/2020");
    await this.watchIsCustomerAdded();
    await this.watchJobAdded();
    await this.watchCoulmnUpdated();

    //modified to fix invoice number update on group accounting payment open - if facing any issue in next invoice number check here
    //  if (!localStorage.getItem("invoice_id")) {
    if (
      !localStorage.getItem("invoice_id") &&
      !localStorage.getItem("group_payment_request")
    ) {
      this.getNextInvoiceNumber();
      if (localStorage.getItem("default_currency")) {
        this.set_default_currency();
      }
    } else {
      // set as true because no need to get next invoice number

      this.setState({ next_invoice_number_loaded: true });
    }

    jQuery(window).click(function () {
      jQuery("#changeTableProps").css("height", "auto");

      jQuery(".add-new,.cus").on("show.bs.dropdown", function () {
        jQuery("#changeTableProps").css("height", "1000px");
      });
    });

    // jQuery('#menucontainer').click(function(event){
    //   jQuery(".add-new").on("show.bs.dropdown", function () {
    //     jQuery("#changeTableProps").css("height", "1000px");
    //   });
    //   // jQuery("#changeTableProps").css("height", "1000px");
    // });

    jQuery(".tbl_drop_down").on("click", function () {
      jQuery(".table-responsive").addClass("ovrFlwRmve");
    });

    // jQuery(function () {
    //   jQuery(".add-new").on("show.bs.dropdown", function () {
    //     jQuery("#changeTableProps").css("height", "1000px");
    //   });
    // });
    // jQuery(function () {
    //   jQuery(".add-new").on("hidden.bs.dropdown", function () {
    //     jQuery("#changeTableProps").css("height", "auto");
    //   });
    // });

    // alert(localStorage.getItem('customerId'))

    // setTimeout(() => {
    //   this.setState(
    //     {
    //       cus_rate_rate: this.state.exchange_value_ref,
    //     },
    //     () => {
          this.handleChangeItems("", this.state.rows.length - 1);
          jQuery("#quantity" + 0).val(1);
    //     }
    //   );
    // }, 1000);

    // setTimeout(()=>{jQuery('#Exchange').val()},3000)

    // if(this.props.loation!=undefined&& this.props.loation.state !=undefined &&this.props.loation.state!='' &&this.props.loation.state!=null){
    //   alert(this.props.loation.state[0])
    // }

    // this.get_currencies();
    await this.getSubAccountList();
    //     if(this.props.location.state !=undefined &&this.props.location.state.length>0&& this.props.location.state[0].newCustomer !=undefined){
    //       alert(this.props.location.state[0].newCustomer)
    //     }
    //  console.log('jhjkjkjkjkjkjkjkjk',this.props.location.state)

    await this.addSerialNumber();
    // const { location, history } = this.props
    // const previousPath = location.state.from.pathname
    // alert(previousPath)
    // window.jQuery('#cust_items').selectpicker({

    // })
    // jQuery('.selectpicker').hasClass('open')

    // jQuery(".table-overflow").on(".selectpicker + .btn.dropdown-toggle", function (event) {
    //   // jQuery('#changeTableProps').css('overflow','')
    //   // jQuery('#changeTableProps').css('overflow-y','hidden')

    //   alert("Test");
    // });
    // jQuery(".cus").on("show.bs.dropdown", function (event) {
    //   // jQuery('#changeTableProps').css('overflow','initial')
    //   // jQuery('#changeTableProps').css('overflow-x','auto')
    // });

    //   jQuery('select').on('change', function() {
    //     alert('hiiiii')
    // });
    // jQuery(".dropdown-menu").click(function(){
    //   alert("The paragraph was clicked.");
    // });
    // alert(jQuery('.open').hasClass('show'))
    // jQuery('.open').addClass('show')

    await this.getColumns();
    await this.onChange_filter_balancesheet();
    // window
    //   .jQuery('.invoice-item-table tbody, .row-body')
    //   .sortable({
    //     beforeStop: function (event, ui) {
    //       setTimeout(() => {
    //         // alert('hiii')
    //         THIS.addSerialNumber()
    //       }, 500)
    //     },
    //     appendTo: 'parent',
    //     helper: 'clone'
    //   })
    //   .disableSelection()

    jQuery(".invoice-item-table")
      .on("change keyup keydown paste cut", "textarea", function () {
        jQuery(this).height(0).height(this.scrollHeight);
      })
      .find("textarea")
      .change();

    // jQuery('.invoice-item-table').on('click', '.del-row', function () {
    //   jQuery(this)
    //     .closest('tr')
    //     .remove()
    // })

    let isCustomer =
      localStorage.getItem("customerId") != undefined ? true : false;
    if (isCustomer) {
      var allString = localStorage.getItem("customerId");
      var splited = allString.split("=");
      var id = splited[1];
      this.setState(
        { selectCustomer: localStorage.getItem("customerId") },
        () => {
          //to-remove-local-storage
          localStorage.setItem("customerId", "");
        }
      );
      this.customerjoblist(id);
    }

    //Get props if so, append get selected

    await this.get_client_home_currency();
    var THIS = this;

    window
      .jQuery(".input-group.date")
      .datepicker({ format: "dd/mm/yyyy", autoclose: true });

    // var el = document.getElementById('mytable');
    // var dragger = tableDragger(el, {
    //   mode: 'row',
    //   dragHandler: '.handle',
    //   onlyBody: true,
    //   animation: 300
    // });
    // dragger.on('drop',function(){
    // THIS.forceUpdate()
    // });

    // tableDragger(document.querySelector("#mytable"), { mode: "row", onlyBody: true });

    // window.jQuery('tbody').sortable()

    // TODO: Make the API
    await this.getItems();
    await this.getColList();
    await this.callAllLocale();
    await this.handleChangeItems("", this.state.rows.length - 1);
    await this.getPaymethod();

    // await this.customer_and_job_list();

    if (localStorage.getItem("customerId")) {
      let c_id = localStorage.getItem("customerId");
      //to-remove-local-storage
      localStorage.setItem("customerId", "");
      if (
        localStorage.getItem("change_invoice_sales") == "" ||
        localStorage.getItem("change_invoice_sales") == null ||
        localStorage.getItem("change_invoice_sales") == undefined
      ) {
        this.customer_and_job_list(c_id, true);
      } else {
        this.customer_and_job_list(c_id, false);
      }
    } else if (localStorage.getItem("customer_id")) {
      let c_id = localStorage.getItem("customer_id");
      //to-remove-local-storage
      localStorage.setItem("customer_id", "");
      if (
        localStorage.getItem("change_invoice_sales") == "" ||
        localStorage.getItem("change_invoice_sales") == null ||
        localStorage.getItem("change_invoice_sales") == undefined
      ) {
        this.customer_and_job_list(c_id, true);
      } else {
        this.customer_and_job_list(c_id, false);
      }
    } else {
      if (
        localStorage.getItem("change_invoice_sales") == "" ||
        localStorage.getItem("change_invoice_sales") == null ||
        localStorage.getItem("change_invoice_sales") == undefined
      ) {
        await this.customer_and_job_list();
      } else {
        let pre_data = JSON.parse(localStorage.getItem("change_invoice_sales"));
        await this.customer_and_job_list(pre_data.customer_id);
      }
    }
    // await this.getItems();
    await this.defaultcategorylist_onchange();
    //   jQuery(document).on('shown.bs.dropdown', '.table-responsive', function (e) {
    //     // The .dropdown container
    //     var jQuerycontainer = jQuery(e.target);

    //     // Find the actual .dropdown-menu
    //     var jQuerydropdown = jQuerycontainer.find('.dropdown-menu');
    //     if (jQuerydropdown.length) {
    //         // Save a reference to it, so we can find it after we've attached it to the body
    //         jQuerycontainer.data('dropdown-menu', jQuerydropdown);
    //     } else {
    //         jQuerydropdown = jQuerycontainer.data('dropdown-menu');
    //     }

    //     jQuerydropdown.css('top', (jQuerycontainer.offset().top + jQuerycontainer.outerHeight()) + 'px');
    //     jQuerydropdown.css('left', jQuerycontainer.offset().left + 'px');
    //     jQuerydropdown.css('position', 'absolute');
    //     jQuerydropdown.css('display', 'block');
    //     jQuerydropdown.appendTo('body');
    // });

    // jQuery(document).on('hide.bs.dropdown', '.table-responsive', function (e) {
    //     // Hide the dropdown menu bound to this button
    //     jQuery(e.target).data('dropdown-menu').css('display', 'none');
    // });
    // let Total= 10000
    //  alert( this.formatCurrency('USD')(parseFloat(Total).toFixed(2)) )

    // alert(this.state.logged_company_name)
    let account_type_id = 2;
    // var client_id = this.state.logged_client_id;

    await FetchAllApi.get_accounts_dropdown(
      account_type_id,
      this.state.logged_client_id,
      (err, response) => {
        // console.log("asjhghdjgh", response);
        if (response.status === 1) {
          this.setState({
            accounts_dropdown: response.list,
          });
        }
      }
    );
    //jQuery(".select-picker").selectpicker();

    require("jquery-mousewheel");
    require("malihu-custom-scrollbar-plugin");

    jQuery(".item-listwrap").mCustomScrollbar({
      scrollEasing: "linear",
      scrollInertia: 600,
      scrollbarPosition: "outside",
    });

    jQuery(".label-enclose .label span").click(function () {
      jQuery(".label-enclose .label").removeClass("active");
      jQuery(this).parent(".label-enclose .label").addClass("active");
    });
    jQuery(".label-enclose .label a").click(function () {
      jQuery(this).parent(".label-enclose .label").removeClass("active");
    });

    jQuery(document).ready(function () {
      jQuery(".left-navmenu .has-sub").click(function () {
        jQuery(".left-navmenu li a").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(".left-navmenu li a:not(.active)")
          .siblings(".sub-menu")
          .slideUp();
        jQuery(this).siblings(".sub-menu").slideToggle();
      });
      jQuery(".left-navmenu .sub-menu li a").click(function () {
        jQuery(".left-navmenu .sub-menu li a").removeClass("active");
        jQuery(this).addClass("active");
      });
      jQuery(".search-btn").click(function () {
        jQuery(".hdr-search").addClass("active");
      });
      jQuery(".hdr-search .close-icon").click(function () {
        jQuery(".hdr-search").removeClass("active");
      });
      // jQuery(".select-picker").selectpicker();
      jQuery(".label-enclose .label").click(function () {
        jQuery(this).toggleClass("active");
      });
      jQuery(".nav-brand-res").click(function () {
        jQuery(".left-navbar").addClass("active");
      });
      jQuery(".menu-close").click(function () {
        jQuery(".left-navbar").removeClass("active");
      });
      // jQuery(".custom-select-drop .dropdown-menu a").click(function () {
      //   // alert()
      //   jQuery(".open.custom-select-drop .dropdown-menu li.active").removeClass(
      //     "active"
      //   );
      //   jQuery(this).parent("li").addClass("active");
      //   jQuery(".open #selected").text(jQuery(this).text());
      // });

      // jQuery('.input-group.date').datepicker({format: "dd/mm/yyyy"});

      // jQuery(".invoice-item-table tbody").sortable({
      // appendTo: "parent",
      // helper: "clone"
      // }).disableSelection();

      // jQuery('.invoice-item-table')
      //   .on('change keyup keydown paste cut', 'textarea', function () {
      //     jQuery(this)
      //       .height(0)
      //       .height(this.scrollHeight)
      //   })
      //   .find('textarea')
      //   .change()

      // jQuery(".tbl_drop_down").on("click", function () {
      //   jQuery(".table-responsive").addClass("ovrFlwRmve");
      // });
    });

    jQuery(".mycustextarea").css("height", "auto");
    jQuery(".mycustextarea").css("width", "auto");
    //  alert(localStorage.getItem('customerId'))
    //  jQuery('#variable_pay_type').val(7);
    // jQuery('select[name=selValue]').val(7);
    // window.jQuery('.selectpicker').selectpicker('refresh')

    //     var customerId=localStorage.getItem('customerId');
    //     if(customerId !=undefined && customerId!='' && customerId!=null){
    //       localStorage.setItem('customerId','')
    //     }

    jQuery("#account_id").val("");
    // if(this.props.location!=undefined &&this.props.location.state!=undefined&&this.props.location.state!=null){
    //   this.setState({customerID:this.props.location.state});

    // }

    var afterJobCustomerId = localStorage.getItem("job_cus_id");
    var JobId = localStorage.getItem("job_id");
    // alert("job id " + JobId);
    await this.customerjoblist(afterJobCustomerId);
    await this.setState(
      {
        customerID: afterJobCustomerId,
        JobId: JobId,
      },
      () => {
        localStorage.setItem("job_cus_id", "");
        localStorage.setItem("job_id", "");
      }
    );
    if (
      localStorage.getItem("customerId") != undefined &&
      localStorage.getItem("customerId") != null
    ) {
      this.setState(
        {
          customerID: localStorage.getItem("customerId"),
        },
        () => {
          localStorage.setItem("customerId", "");
        }
      );
    }

    await this.handleChangeItems("", this.state.rows.length - 1);

    // jQuery('#account_id').val('')

    // // alert()

    // jQuery('#variable_pay_type').val(138)
    if (this.props.location.state != undefined) {
      if (this.props.location.state.detail != undefined) {
        // from estimate details are stored in state detail object
        let data = this.props.location.state.detail;
        //  jQuery('#variable_pay_type').val(
        //     data.vendorName
        //   )

        // setTimeout(() => {
        jQuery("#template_sel").val(data.active);

        //  jQuery('#variable_pay_type').val(data.vendorName)
        this.customerjoblist(data.vendorName);
        jQuery("#invoice_date").val(data.date);
        jQuery("#invoice_no").val(data.salesno);
        jQuery("#shipping_to").val(data.salesorderto);
        jQuery("#footer_memo").val(data.memo);
        // jQuery("#footeroptionthanksmessages").val(data.thanking_message);

        this.setThankingMessage(data.thanking_message);

        //jQuery("#termcondmsg").val(data.terms_and_conditions);
        this.setTermsAndConditions(data.terms_and_conditions);
        if (data.itemlist.length > 0) {
          var res = data.itemlist;
          var useme = res.length - 1;
          for (var i = 0; i < useme; i++) {
            var rows = this.state.rows;
            rows.push("row" + (this.state.initial_value + 1));
            this.setState({
              isAdd: false,
              initial_value: this.state.initial_value + 1,
            });
            this.setState({ rows: rows }, () => {
              // jQuery('#item'+i).val(res[i])
              this.state.rows.forEach((item, i) => {
                this.addSerialNumber();
                jQuery("#item" + i).val(res[i]);
              });
            });
          }

          for (let i = 0; i < data.itemlist.length; i++) {
            // console.log('textvalue',jQuery('#cust_items'+i).val())
            //   jQuery("#cust_items0 option").filter(function() {
            //     return this.text == 'Consulting';
            // }).attr('selected', true);
            // console.log(
            //   "jsokl",
            //   jQuery("#cust_items" + i + " option:selected").data("status")
            // );
            // jQuery('#cust_items '+i+'option:selected' ).data('status')
            // jQuery('#cust_items' + i).val(
            //  data.itemlist[i].item_name
            // )
            jQuery("#cust_items" + i).val(data.itemlist[i].list);

            // richtext-description-20240410
            // jQuery("#descr" + i).val(data.itemlist[i].descr);

            this.setDescriptionContent(data.itemlist[i].descr,i);

            jQuery("#unit_price" + i).val(data.itemlist[i].unit_price);
            jQuery("#quantity" + i).val(data.itemlist[i].quantity);
            jQuery("#categry_id" + i).val(data.itemlist[i].category_id);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
            jQuery("#selectednow" + i).html(data.itemlist[i].tax_name);

            jQuery("#selectednow_id" + i).val(data.itemlist[i].tax_id);
            jQuery("#selectedrate_id" + i).val(data.itemlist[i].tax_rate);
            jQuery("#selectedtype_id" + i).val(data.itemlist[i].tax_type);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
          }
          this.handleChangeItems(data.itemlist.length);
          jQuery("#homecurrencytyt").html(data.foreignsubtotal);
          jQuery("#foreigncurrencytyt").text(data.homesubtotal);
          jQuery("#home_tax_total").html(data.fortax);
          jQuery("#foreign_tax_total").text(data.homecurenncytax);
          jQuery("#home_grand_total").html(data.forgingrandtotal);
          jQuery("#foreign_grand_total").text(data.homegrandtotal);
        }
        this.setState(
          {
            customerID: data.vendorName,
            JobId: data.jobid,
          },
          () => {}
        );
        // }, 2000);

        // this.setState({

        //   selected_templateName:data.selected_templateName,
        //   vendorName:this.props.location.state.vendorName,
        //   job_id:this.props.location.state.job_id,
        //   billing_address:this.props.location.state.billing_address,
        //   isInvoice_to:true
        // })
      }

      if (this.props.location.state.invoicessalesorder != undefined) {
        // from salesorder details are stored in state invoicesalesorder object
        let data = this.props.location.state.invoicessalesorder;
        //  jQuery('#variable_pay_type').val(
        //     data.vendorName
        //   )

        // console.log("kksllamsm", data);
        // setTimeout(() => {
        jQuery("#template_sel").val(data.active);

        //  jQuery('#variable_pay_type').val(data.vendorName)
        this.customerjoblist(data.vendorName);
        jQuery("#invoice_date").val(data.date);
        data.due_date && jQuery("due_date").val(data.due_date);
        jQuery("#invoice_no").val(data.salesno);
        jQuery("#shipping_to").val(data.salesorderto);
        jQuery("#footer_memo").val(data.memo);
        // jQuery("#footeroptionthanksmessages").val(data.thanking_message);
        this.setThankingMessage(data.thanking_message);
        // jQuery("#termcondmsg").val(data.terms_and_conditions);
        this.setTermsAndConditions(data.terms_and_conditions);
        if (data.itemlist.length > 0) {
          var res = data.itemlist;
          var useme = res.length - 1;
          for (var i = 0; i < useme; i++) {
            var rows = this.state.rows;
            rows.push("row" + (this.state.initial_value + 1));
            this.setState({
              isAdd: false,
              initial_value: this.state.initial_value + 1,
            });
            this.setState({ rows: rows }, () => {
              // jQuery('#item'+i).val(res[i])
              this.state.rows.forEach((item, i) => {
                this.addSerialNumber();
                jQuery("#item" + i).val(res[i]);
              });
            });
          }
          for (let i = 0; i < data.itemlist.length; i++) {
            //   console.log('textvalue',jQuery('#cust_items'+i).val())
            //   jQuery("#cust_items0 option").filter(function() {
            //     return this.text == 'Consulting';
            // }).attr('selected', true);
            // console.log(
            //   "jsokl",
            //   jQuery("#cust_items" + i + " option:selected").data("status")
            // );
            // jQuery('#cust_items '+i+'option:selected' ).data('status')
            // jQuery('#cust_items' + i).val(
            //  data.itemlist[i].item_name
            // )
            jQuery("#cust_items" + i).val(data.itemlist[i].list);

            // richtext-description-20240410
            // jQuery("#descr" + i).val(data.itemlist[i].descr);

            this.setDescriptionContent(data.itemlist[i].descr,i);

            jQuery("#unit_price" + i).val(data.itemlist[i].unit_price);
            jQuery("#quantity" + i).val(data.itemlist[i].quantity);
            jQuery("#categry_id" + i).val(data.itemlist[i].category_id);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
            jQuery("#selectednow" + i).html(data.itemlist[i].tax_name);

            jQuery("#selectednow_id" + i).val(data.itemlist[i].tax_id);
            jQuery("#selectedrate_id" + i).val(data.itemlist[i].tax_rate);
            jQuery("#selectedtype_id" + i).val(data.itemlist[i].tax_type);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
          }
          this.handleChangeItems(data.itemlist.length);
          jQuery("#homecurrencytyt").html(data.foreignsubtotal);
          jQuery("#foreigncurrencytyt").text(data.homesubtotal);
          jQuery("#home_tax_total").html(data.fortax);
          jQuery("#foreign_tax_total").text(data.homecurenncytax);
          jQuery("#home_grand_total").html(data.forgingrandtotal);
          jQuery("#foreign_grand_total").text(data.homegrandtotal);
        }
        this.setState(
          {
            customerID: data.vendorName,
            JobId: data.jobid,
          },
          () => {}
        );
        // }, 2000);
      }
      if (this.props.location.state.invoicecreditmemo != undefined) {
        // from creditmemo details are stored in state invoicecreditmemo object
        let data = this.props.location.state.invoicecreditmemo;
        //  jQuery('#variable_pay_type').val(
        //     data.vendorName
        //   )

        // setTimeout(() => {
        jQuery("#template_sel").val(data.active);

        //  jQuery('#variable_pay_type').val(data.vendorName)
        await this.customerjoblist(data.vendorName);
        jQuery("#invoice_date").val(data.date);
        jQuery("#invoice_no").val(data.salesno);
        jQuery("#shipping_to").val(data.salesorderto);
        jQuery("#footer_memo").val(data.memo);
        // jQuery("#footeroptionthanksmessages").val(data.thanking_message);
        this.setThankingMessage(data.thanking_message);
        // jQuery("#termcondmsg").val(data.terms_and_conditions);
        this.setTermsAndConditions(data.terms_and_conditions);
        if (data.itemlist.length > 0) {
          var res = data.itemlist;
          var useme = res.length - 1;
          for (var i = 0; i < useme; i++) {
            var rows = this.state.rows;
            rows.push("row" + (this.state.initial_value + 1));
            this.setState({
              isAdd: false,
              initial_value: this.state.initial_value + 1,
            });
            this.setState({ rows: rows }, () => {
              // jQuery('#item'+i).val(res[i])
              this.state.rows.forEach((item, i) => {
                this.addSerialNumber();
                jQuery("#item" + i).val(res[i]);
              });
            });
          }

          for (let i = 0; i < data.itemlist.length; i++) {
            //   console.log('textvalue',jQuery('#cust_items'+i).val())
            //   jQuery("#cust_items0 option").filter(function() {
            //     return this.text == 'Consulting';
            // }).attr('selected', true);
            // console.log(
            //   "jsokl",
            //   jQuery("#cust_items" + i + " option:selected").data("status")
            // );
            // jQuery('#cust_items '+i+'option:selected' ).data('status')
            // jQuery('#cust_items' + i).val(
            //  data.itemlist[i].item_name
            // )
            jQuery("#cust_items" + i).val(data.itemlist[i].list);

            // richtext-description-20240410
            // jQuery("#descr" + i).val(data.itemlist[i].descr);

            this.setDescriptionContent(data.itemlist[i].descr,i);

            jQuery("#unit_price" + i).val(data.itemlist[i].unit_price);
            jQuery("#quantity" + i).val(data.itemlist[i].quantity);
            jQuery("#categry_id" + i).val(data.itemlist[i].category_id);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
            jQuery("#selectednow" + i).html(data.itemlist[i].tax_name);
            jQuery("#selectednow" + i).val(data.itemlist[i].tax_name);
            jQuery("#selectednow_id" + i).val(data.itemlist[i].tax_id);
            jQuery("#selectedrate_id" + i).val(data.itemlist[i].tax_rate);
            jQuery("#selectedtype_id" + i).val(data.itemlist[i].tax_type);
            jQuery("#subtotal" + i).val(data.itemlist[i].price);
          }
          this.handleChangeItems(data.itemlist.length);
          jQuery("#homecurrencytyt").html(data.foreignsubtotal);
          jQuery("#foreigncurrencytyt").text(data.homesubtotal);
          jQuery("#home_tax_total").html(data.fortax);
          jQuery("#foreign_tax_total").text(data.homecurenncytax);
          jQuery("#home_grand_total").html(data.forgingrandtotal);
          jQuery("#foreign_grand_total").text(data.homegrandtotal);
        }

        this.setState(
          {
            customerID: data.vendorName,
            JobId: data.jobid,
          },
          () => {
            alert(data.vendorName);
          }
        );
        // }, 2000);

        // this.setState({

        //   selected_templateName:data.selected_templateName,
        //   vendorName:this.props.location.state.vendorName,
        //   job_id:this.props.location.state.job_id,
        //   billing_address:this.props.location.state.billing_address,
        //   isInvoice_to:true
        // })
      }
    }
    await this.appendInvoiceDetails();
    await this.groupRequestAppendInvoice();

    if (
      (localStorage.getItem("change_invoice_sales") != "" &&
        localStorage.getItem("change_invoice_sales") != undefined &&
        localStorage.getItem("change_invoice_sales") != null) ||
      (localStorage.getItem("change_invoice_estimate") != "" &&
        localStorage.getItem("change_invoice_estimate") != undefined &&
        localStorage.getItem("change_invoice_estimate") != null) ||
      (localStorage.getItem("copy_invoice") != "" &&
        localStorage.getItem("copy_invoice") != undefined &&
        localStorage.getItem("copy_invoice") != null)
    ) {
      setTimeout(() => {
        this.auto_create_invoice();
      }, 1000);
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    if (!this.state.isInvoiceEditable && this.state.customer_id != 0) {
      const custid = this.state.customer_id;
      await this.Basic_info(custid);
      if (this.state.customer_id != 0) {
        localStorage.setItem("selected_customer_id", custid);
      }
      jQuery("#variable_pay_type").val(this.state.customer_id);
      this.customerjoblist(custid);
      this.setState({ selectCustomer: custid, myCusVarPay: custid });
    }
  }

  makeDisabled = () => {
    jQuery("#changeme").attr("disabled", true);

    jQuery("#invoice_curr_id").attr("disabled", true);
    jQuery("#template_sel").attr("disabled", true);
    jQuery("#variable_pay_type").attr("disabled", true);
    jQuery("#invoice_date").attr("disabled", true);
    jQuery("#invoice_no").attr("disabled", true);

    jQuery("#due_date").attr("disabled", true);
    jQuery("#jobName").attr("disabled", true);
    jQuery("#invoice_to").attr("disabled", true);
    jQuery("#shipping_to").attr("disabled", true);
    jQuery("#footer_memo").attr("disabled", true);
    jQuery("#Exchange").attr("disabled", true);
    jQuery("#footeroptionthanksmessages").attr("disabled", true);
    jQuery("#termcondmsg").attr("disabled", true);
  };
  appendInvoiceDetails = () => {
    var setID = localStorage.getItem("invoice_id");
    if (setID !== null) {
      var localBee = setID.split("=");
      if (localBee.length > 1) {
        var invoice_id = localBee[0];
        var payment_id = localBee[1];
        this.setState({ paymentGRTR: Number(payment_id), edited: true });
      } else {
        var invoice_id = setID;
        var payment_id = 0;
      }

      if (invoice_id != undefined && invoice_id != "" && invoice_id != null) {
        var coreData = {
          client_id: this.state.logged_client_id,
          invoice_id: invoice_id,
          payment_id: payment_id,
        };
        this.setState({ loading: true, hasId: invoice_id});

        let client_id = this.state.logged_client_id;
        let filter_id = 2;

        // To make sure sales product item list loaded before the invoice details getting loaded
        FetchAllApi.sales_product_item_list(
          client_id,
          filter_id,
          (err, response) => {
            if (response.status == 1) {
              this.setState({ ItemList: response.list }, () => {
                FetchAllApi.getInvoiceDetails(coreData, (err, response) => {
                  if (response.status === 1) {
                    this.setState({
                      invoice_details: response,
                      invoice_details2: response,
                      manualLoading: false,
                      list_of_attachments: response.invoice_details.source_attachments,
                    });
                    var primaryData = response.invoice_details;
                    this.customValuesUpdate(
                      primaryData.custom_header_fields,
                      primaryData.custom_footer_fields
                    );
                    this.temChanged(primaryData.template_id);
                    // console.log("kjkkjkkjjjjj", primaryData);
                    let paidstaus = primaryData.paid_status
                      ? primaryData.paid_status
                      : "Unpaid";
                    jQuery("#paid_status").val(paidstaus + "--invoice");

                    // jQuery("#invoice_to").val(primaryData.company_address);
                    this.setInvoiceTo(primaryData.company_address);


                    jQuery("#shipping_to").val(primaryData.shipping_address);
                    jQuery("#payment_exchange_rate").val(
                      primaryData.payment_exchange_rate
                    );
                    jQuery("#reference").val(primaryData.reference);
                    console.log('res', primaryData)
                    this.setState({
                      is_deleted: primaryData.is_deleted,
                      is_void: primaryData.is_voided,
                      invoice_id_used: primaryData.id,
                      payment_desc: primaryData.descripation,
                      template_id: primaryData.template_id,
                      payement_table: response.paymentDetails,
                    });

                    //Append here....
                    // jQuery("#getMe").val(primaryData.exchange_rate);
                    if (payment_id != 0) {
                      this.makeDisabled();
                      jQuery("#balanceSheetCategeory").val(
                        primaryData.balance_sheet_category
                      );
                      var mySelValue = jQuery(
                        "#balanceSheetCategeory option:selected"
                      ).data("status");

                      if (
                        mySelValue != undefined &&
                        mySelValue != "" &&
                        mySelValue != null
                      ) {
                        this.state.balancesheetlist.forEach((item, i) => {
                          if (item.name == mySelValue) {
                            // alert(item.id)
                            const string = item.name;
                            const Payable = string.includes("ayable");
                            const Receivable = string.includes("eceivable");
                            if (Payable || Receivable) {
                              this.setState({ isThirdPartyName: true });
                              if (Payable) {
                                this.setState({ thrird_party_type: 2 });
                                this.fetchThirdPartyNames(5);
                              }
                              if (Receivable) {
                                this.setState({ thrird_party_type: 1 });

                                this.fetchThirdPartyNames(2);
                              }
                            } else {
                              this.setState({ isThirdPartyName: false });
                            }
                          }
                        });

                        // setTimeout(() => {
                        jQuery("#third_account_id").val(
                          primaryData.thrird_party_account_id
                        );

                        this.setState({
                          third_party_value:
                            primaryData.thrird_party_account_id,
                        });
                        // }, 8000);
                      }
                    }

                    this.setState(
                      {
                        isInvoiceEditable: true,
                        currInvoiceId: primaryData.invoice_number,
                        currpayment_id: primaryData.payment_id,
                      },
                      () => {
                        // jQuery("#appliedhom").html(
                        //   (isNaN(
                        //     Number(
                        //       primaryData.payments_applied_home_currency !== undefined
                        //         ? primaryData.payments_applied_home_currency
                        //         : 0
                        //     )
                        //   )
                        //     ? 0
                        //     : Number(primaryData.payments_applied_home_currency)
                        //   ).toFixed(2)
                        // );

                        if (primaryData.payments_applied_home_currency) {
                          jQuery("#appliedhom").html(
                            Number(
                              primaryData.payments_applied_home_currency
                            ).toFixed(2)
                          );
                          this.setState({
                            pmt_applied_home_currency: Number(
                              primaryData.payments_applied_home_currency
                            ).toFixed(2),
                          });
                        } else {
                          jQuery("#appliedhom").html(Number(0).toFixed(2));
                          this.setState({
                            pmt_applied_home_currency: Number(0).toFixed(2),
                          });
                        }

                        // jQuery('#forbaldue').html((primaryData.open_balance_foreign_currency).toFixed(2));
                        // jQuery('#homebaldue').html((primaryData.open_balance_home_currency).toFixed(2));

                        // jQuery('#appliedfor').html((primaryData.payments_applied_foreign_currency).toFixed(2));
                        //hey

                        //modified on 07-06-2022
                        // jQuery("#appliedfor").html(
                        //   (isNaN(
                        //     Number(
                        //       primaryData.payments_applied_foreign_currency !==
                        //         undefined
                        //         ? primaryData.payments_applied_foreign_currency
                        //         : 0
                        //     )
                        //   )
                        //     ? 0
                        //     : Number(primaryData.payments_applied_foreign_currency)
                        //   ).toFixed(2)
                        // );

                        if (primaryData.payments_applied_foreign_currency) {
                          jQuery("#appliedfor").html(
                            Number(
                              primaryData.payments_applied_foreign_currency
                            ).toFixed(2)
                          );
                          this.setState({
                            pmt_applied_foreign_currency: Number(
                              primaryData.payments_applied_foreign_currency
                            ).toFixed(2),
                          });
                        } else {
                          jQuery("#appliedfor").html(Number(0).toFixed(2));
                          this.setState({
                            pmt_applied_foreign_currency: Number(0).toFixed(2),
                          });
                        }

                        // jQuery("#forbaldue").html(
                        //   (isNaN(
                        //     Number(
                        //       primaryData.open_balance_foreign_currency !== undefined
                        //         ? primaryData.open_balance_foreign_currency
                        //         : 0
                        //     )
                        //   )
                        //     ? 0
                        //     : Number(primaryData.open_balance_foreign_currency)
                        //   ).toFixed(2)
                        // );

                        jQuery("#forbaldue").html(
                          primaryData.open_balance_foreign_currency
                        );
                        this.setState({
                          pmt_balance_due_foreign_currency:
                            primaryData.open_balance_foreign_currency,
                        });

                        // jQuery("#homebaldue").html(
                        //   (isNaN(
                        //     Number(
                        //       primaryData.open_balance_home_currency !== undefined
                        //         ? primaryData.open_balance_home_currency
                        //         : 0
                        //     )
                        //   )
                        //     ? 0
                        //     : Number(primaryData.open_balance_home_currency)
                        //   ).toFixed(2)
                        // );

                        jQuery("#homebaldue").html(
                          primaryData.open_balance_home_currency
                        );
                        this.setState({
                          pmt_balance_due_hom_curency:
                            primaryData.open_balance_home_currency,
                        });

                        // jQuery("#footeroptionthanksmessages").val(
                        //   primaryData.thanking_message
                        // );

                        this.setThankingMessage(primaryData.thanking_message);
                        //  jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
                        this.setTermsAndConditions(
                          primaryData.terms_and_conditions
                        );
                        //hey

                        // jQuery("#exchangeGain").html(
                        //   (isNaN(
                        //     Number(
                        //       primaryData.exchange_gain_or_loss !== undefined
                        //         ? primaryData.exchange_gain_or_loss
                        //         : 0
                        //     )
                        //   )
                        //     ? 0
                        //     : Number(primaryData.exchange_gain_or_loss)
                        //   ).toFixed(2)
                        // );

                        if (primaryData.exchange_gain_or_loss) {
                          jQuery("#exchangeGain").html(
                            primaryData.exchange_gain_or_loss
                          );
                          this.setState({
                            pmt_exchange_gain_or_loss:
                              primaryData.exchange_gain_or_loss,
                          });
                        } else {
                          jQuery("#exchangeGain").html(0);
                          this.setState({
                            pmt_exchange_gain_or_loss: 0,
                          });
                        }
                      }
                    );

                    jQuery("#invoice_curr_id").val(
                      primaryData.foreign_currency
                    );
                    this.setState(
                      {
                        CurrentSelectedCurr: primaryData.foreign_currency,
                        cus_rate_rate: Number(primaryData.exchange_rate),
                      },
                      () => {
                        // no need to call this - 20240703
                        // this.findInSubAccountList();
                        jQuery("#account_id").val(primaryData.account);
                      }
                    );

                    jQuery("#variable_pay_type").val(primaryData.customer_id);
                    this.setState({
                      selectCustomer: primaryData.customer_id,
                      customerID: primaryData.customer_id, //nly addd n 26-10-2022
                    });
                    this.customerjoblist(primaryData.customer_id);
                    if (primaryData.tax_inclusive == "0") {
                      document.getElementById("changeme").value = false;
                      this.setState({ isChecked: false });
                    } else {
                      document.getElementById("changeme").value = true;

                      this.setState({ isChecked: true });
                    }
                    // var list_length=primaryData.invoice_details.length;

                    // setTimeout(() => {
                    // jQuery('#invoice_to').val(primaryData.company_address)
                    // jQuery('#shipping_to').val(primaryData.shipping_address)
                    // jQuery("#Exchange").val(primaryData.exchange_rate);
                    // jQuery("#getMe").val(primaryData.exchange_rate);
                    var tempid = primaryData.template_id;

                    //  alert(tempid.toString())
                    jQuery("#template_sel").val(tempid);
                    this.setState({
                    //  billing_address: primaryData.company_address,
                      shipping_address: primaryData.shipping_address,
                      loading: false,
                    },()=>{
                      this.setInvoiceTo(primaryData.company_address);
                    });
                    // }, 8000);

                    // setTimeout(() => {
                    jQuery("#jobName").val(primaryData.job_name);
                    this.setState({
                      job_name: primaryData.job_name,
                      JobId: primaryData.job_id,
                    });

                    //..............................................................
                    var useme = primaryData.invoice_details.length;
                    // alert(useme)
                    for (var kk = 1; kk < useme; kk++) {
                      // console.log("hfhfhfhfhfh", kk);
                      var rows = this.state.rows;
                      rows.push("row" + (this.state.initial_value + 1));
                      this.setState({
                        isAdd: false,
                        initial_value: this.state.initial_value + 1,
                      });
                      this.setState({ rows: rows }, () => {});
                    }

                    for (var kk = 0; kk < useme; kk++) {
                      //useme
                      this.state.ItemList.length > 0 &&
                        this.state.ItemList.map((item, y) => {
                          // var myVal=item.rate +
                          // '=' +
                          // item.description +
                          // '=' +
                          // item.account_name_category+'='+item.item_name

                          var myVal =
                            item.rate +
                            "=" +
                            item.description +
                            "=" +
                            item.account_name_category +
                            "=" +
                            item.item_name +
                            "=" +
                            item.item_id;

                          // var another= item.rate +
                          // '=' +
                          // item.description +
                          // '=' +
                          // item.account_name_category+'='+primaryData.invoice_details[0].item_name;

                          var another =
                            item.rate +
                            "=" +
                            item.description +
                            "=" +
                            item.account_name_category +
                            "=" +
                            primaryData.invoice_details[kk].item_name +
                            "=" +
                            item.item_id;
                          if (myVal === another) {
                            // jQuery('#cust_items0').val(myVal);
                            window
                              .jQuery("#cust_items" + kk)
                              .selectpicker("val", myVal);
                            if (payment_id > 0) {
                              jQuery("#cust_items" + kk).attr("disabled", true);
                              jQuery("#descr" + kk).attr("disabled", true);
                              jQuery("#item" + kk).attr("disabled", true);
                              jQuery("#unit_price" + kk).attr("disabled", true);
                              jQuery("#quantity" + kk).attr("disabled", true);
                              jQuery("#categry_id" + kk).attr("disabled", true);
                              jQuery("#subtotal" + kk).attr("disabled", true);
                              jQuery("#selectednow" + kk).attr(
                                "disabled",
                                true
                              );
                              jQuery("#taxdisable" + kk).prop("disabled", true);
                            }
                            jQuery("#item" + kk).val(
                              primaryData.invoice_details[kk].item_name
                            );
                          }
                        });
                      this.state.number_of_columns_list.map((val, idx) => {
                        // console.log("mapf", kk)
                        let value = val.column_name;

                        primaryData.invoice_details[0].custom_column.map(
                          (inv, i) => {
                            jQuery("#addtext" + idx + i).val(inv[value]);
                          }
                        );
                      });

                      // richtext-description-20240410
                      // jQuery("#descr" + kk).val(
                      //   primaryData.invoice_details[kk].descr
                      // );

                      this.setDescriptionContent(primaryData.invoice_details[kk].descr,kk);

                      jQuery("#unit_price" + kk).val(
                        primaryData.invoice_details[kk].unit_price
                      );

                      jQuery("#quantity" + kk).val(
                        primaryData.invoice_details[kk].quantity
                      );
                      jQuery("#categry_id" + kk).val(
                        primaryData.invoice_details[kk].category_id
                      );

                      //2023-01-24
                      jQuery("#subtotal" + kk).val(
                        primaryData.invoice_details[kk].price
                      );

                      // reverted to above code on 18-04-2023
                      // jQuery("#subtotal" + kk).val(
                      //   primaryData.invoice_details[kk].unit_price
                      // );

                      jQuery("#selectedrate_id" + kk).val(
                        primaryData.invoice_details[kk].tax_rate
                      );

                      jQuery("#selectedtype_id" + kk).val(
                        primaryData.invoice_details[kk].tax_type
                      );
                      jQuery("#selectednow" + kk).html(
                        primaryData.invoice_details[kk].tax_name
                      );
                      // setTimeout(() => {
                      this.setState(
                        {
                          cus_rate_rate: primaryData.exchange_rate,
                        },
                        () => {
                          this.handleChangeItems(
                            "",
                            this.state.rows.length - 1
                          );
                        }
                      );
                      // }, 1000);
                      //useme
                    }
                    //..............................................................
                    // }, 6000);

                    //Find here

                    // setTimeout(() => {
                    jQuery("#invoice_date").val(
                      moment(primaryData.invoice_date).format("DD/MM/YYYY")
                    );

                    //for lock date check purpose
                    this.setState({old_transaction_date:primaryData.invoice_date});

                    jQuery("#due_date").val(
                      moment(primaryData.due_date).format("DD/MM/YYYY")
                    );

                    this.setState({
                      selected_due_date: primaryData.due_date_term,
                    });

                    jQuery("#invoice_no").val(primaryData.invoice_number);
                    // }, 6000);

                    jQuery("#footer_memo").val(primaryData.memo);
                    // jQuery("#footeroptionthanksmessages").val(
                    //   primaryData.thanking_message
                    // );

                    this.setThankingMessage(primaryData.thanking_message);

                    // jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
                    this.setTermsAndConditions(
                      primaryData.terms_and_conditions
                    );
                    // jQuery("#amount_in_words").val(`${primaryData.amount_in_words} only`);
                    jQuery("#amount_in_words").val(`${primaryData.amount_in_words}`);

                    if (payment_id != 0) {
                      jQuery("#payable_amount").val(primaryData.payment_amount);
                      this.setState({
                        amount_entered: primaryData.payment_amount,
                      });
                      // if(!isNaN(Number(primaryData.payment_amount))) {
                      //   this.setState({
                      //     amount_entered:primaryData.payment_amount
                      //   },
                      //   ()=>{
                      //     jQuery("#payable_amount").val(primaryData.payment_amount);
                      //     console.log("**********5016...",this.state.amount_entered);
                      //   });
                      // }

                      jQuery("#payment_method").val(primaryData.payment_method);

                      jQuery("#payment_date").val(
                        moment(primaryData.payment_date).format("DD/MM/YYYY")
                      );

                      //for lock date check purpose
                      this.setState({ old_payment_date : primaryData.payment_date});
                    }

                    // jQuery('#reference').val(primaryData.payment_date);
                  } else {
                    this.setState({ customerjoblist: [] });
                  }
                });
              });
            }
          }
        );
      }
      localStorage.setItem("invoice_id", "");
    } else {
      var payment_id = 0;
    }
  };

  groupRequestAppendInvoice = () => {
    var id = localStorage.getItem("group_payment_request");
    var invoice_id = localStorage.getItem("sales_invoice_id");
    var payment_id = 0;
    this.setState({ notification_id: id });

    if (
      invoice_id != undefined &&
      invoice_id != "" &&
      invoice_id != null &&
      id != undefined &&
      id != "" &&
      id != null
    ) {
      // alert(invoice_id)

      var coreData = {
        notification_id: id,
        sales_invoice_id: invoice_id,
      };

      // To make sure sales product item list loaded before the invoice details getting loaded
      var client_id = this.state.logged_client_id;
      FetchAllApi.sales_product_item_list(client_id, 2, (err, response) => {
        if (response.status === 1) {
          this.setState({ ItemList: response.list });

          FetchAllApi.getGroupInvoiceDetails(coreData, (err, response) => {
            console.log("vendor_names", response);

            if (response.status === 1) {
              var primaryData = response.invoice_details;
              // console.log("kjkkjkkjjjjj", primaryData);
              let paidstaus = primaryData.paid_status
                ? primaryData.paid_status
                : "Unpaid";
              jQuery("#paid_status").val(paidstaus + "--invoice");

              // jQuery("#invoice_to").val(primaryData.company_address);
              this.setInvoiceTo(primaryData.company_address);

              jQuery("#shipping_to").val(primaryData.shipping_address);
              jQuery("#payment_exchange_rate").val(
                primaryData.payment_exchange_rate
              );
              jQuery("#reference").val(primaryData.reference);
              // var client_id = this.state.logged_client_id;
              var customer_id = primaryData.customer_id;
              FetchAllApi.customerjoblist(
                client_id,
                customer_id,
                (err, response) => {
                  var myArray = response.results;
                  if (response.status === 1) {
                    this.setState({ customerjoblist: response.results }, () => {
                      myArray.map((item) => {
                        if (item.id == primaryData.job_id) {
                          this.setState({ job_name: item.job_name });
                          jQuery("#jobName").val(item.job_name);
                        }
                      });
                      window.jQuery("#jobName").selectpicker("refresh");
                    });
                  } else {
                    this.setState({ customerjoblist: [] });
                  }
                }
              );
              this.setState({
                invoice_id_used: primaryData.id,
                payment_desc: primaryData.descripation,
              });

              this.customer_and_job_list(customer_id, false);

              //Append here....
              // jQuery("#getMe").val(primaryData.exchange_rate);
              if (payment_id != 0) {
                this.makeDisabled();
                jQuery("#balanceSheetCategeory").val(
                  primaryData.balance_sheet_category
                );
                var mySelValue = jQuery(
                  "#balanceSheetCategeory option:selected"
                ).data("status");
                if (
                  mySelValue != undefined &&
                  mySelValue != "" &&
                  mySelValue != null
                ) {
                  this.state.balancesheetlist.forEach((item, i) => {
                    if (item.name == mySelValue) {
                      // alert(item.id)
                      const string = item.name;
                      const Payable = string.includes("ayable");
                      const Receivable = string.includes("eceivable");
                      if (Payable || Receivable) {
                        this.setState({ isThirdPartyName: true });
                        if (Payable) {
                          this.setState({ thrird_party_type: 2 });
                          this.fetchThirdPartyNames(5);
                        }
                        if (Receivable) {
                          this.setState({ thrird_party_type: 1 });

                          this.fetchThirdPartyNames(2);
                        }
                      } else {
                        this.setState({ isThirdPartyName: false });
                      }
                    }
                  });

                  // setTimeout(() => {
                  jQuery("#third_account_id").val(
                    primaryData.thrird_party_account_id
                  );
                  // }, 8000);
                }
              }

              this.setState(
                {
                  isInvoiceEditable: true,
                  currInvoiceId: primaryData.invoice_number,
                  currpayment_id: primaryData.payment_id,
                  selected_due_date: primaryData.due_date_term,
                },
                () => {
                  // jQuery("#appliedhom").html(
                  //   (isNaN(
                  //     Number(
                  //       primaryData.payments_applied_home_currency !== undefined
                  //         ? primaryData.payments_applied_home_currency
                  //         : 0
                  //     )
                  //   )
                  //     ? 0
                  //     : Number(primaryData.payments_applied_home_currency)
                  //   ).toFixed(2)
                  // );

                  if (primaryData.payments_applied_home_currency) {
                    jQuery("#appliedhom").html(
                      Number(
                        primaryData.payments_applied_home_currency
                      ).toFixed(2)
                    );
                    this.setState({
                      pmt_applied_home_currency: Number(
                        primaryData.payments_applied_home_currency
                      ).toFixed(2),
                    });
                  } else {
                    jQuery("#appliedhom").html(Number(0).toFixed(2));
                    this.setState({
                      pmt_applied_home_currency: Number(0).toFixed(2),
                    });
                  }

                  // jQuery('#forbaldue').html((primaryData.open_balance_foreign_currency).toFixed(2));
                  // jQuery('#homebaldue').html((primaryData.open_balance_home_currency).toFixed(2));

                  // jQuery('#appliedfor').html((primaryData.payments_applied_foreign_currency).toFixed(2));
                  //hey

                  // jQuery("#appliedfor").html(
                  //   (isNaN(
                  //     Number(
                  //       primaryData.payments_applied_foreign_currency !==
                  //         undefined
                  //         ? primaryData.payments_applied_foreign_currency
                  //         : 0
                  //     )
                  //   )
                  //     ? 0
                  //     : Number(primaryData.payments_applied_foreign_currency)
                  //   ).toFixed(2)
                  // );

                  if (primaryData.payments_applied_foreign_currency) {
                    jQuery("#appliedfor").html(
                      Number(
                        primaryData.payments_applied_foreign_currency
                      ).toFixed(2)
                    );
                    this.setState({
                      pmt_applied_foreign_currency: Number(
                        primaryData.payments_applied_foreign_currency
                      ).toFixed(2),
                    });
                  } else {
                    jQuery("#appliedfor").html(Number(0).toFixed(2));
                    this.setState({
                      pmt_applied_foreign_currency: Number(0).toFixed(2),
                    });
                  }

                  // jQuery("#forbaldue").html(
                  //   (isNaN(
                  //     Number(
                  //       primaryData.open_balance_foreign_currency !== undefined
                  //         ? primaryData.open_balance_foreign_currency
                  //         : 0
                  //     )
                  //   )
                  //     ? 0
                  //     : Number(primaryData.open_balance_foreign_currency)
                  //   ).toFixed(2)
                  // );

                  jQuery("#forbaldue").html(
                    primaryData.open_balance_foreign_currency
                  );
                  this.setState({
                    pmt_balance_due_foreign_currency:
                      primaryData.open_balance_foreign_currency,
                  });

                  // jQuery("#homebaldue").html(
                  //   (isNaN(
                  //     Number(
                  //       primaryData.open_balance_home_currency !== undefined
                  //         ? primaryData.open_balance_home_currency
                  //         : 0
                  //     )
                  //   )
                  //     ? 0
                  //     : Number(primaryData.open_balance_home_currency)
                  //   ).toFixed(2)
                  // );

                  jQuery("#homebaldue").html(
                    primaryData.open_balance_home_currency
                  );
                  this.setState({
                    pmt_balance_due_hom_curency:
                      primaryData.open_balance_home_currency,
                  });

                  // jQuery("#footeroptionthanksmessages").val(
                  //   primaryData.thanking_message
                  // );
                  this.setThankingMessage(primaryData.thanking_message);

                  // jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
                  this.setTermsAndConditions(primaryData.terms_and_conditions);
                  //hey

                  // jQuery("#exchangeGain").html(
                  //   (isNaN(
                  //     Number(
                  //       primaryData.exchange_gain_or_loss !== undefined
                  //         ? primaryData.exchange_gain_or_loss
                  //         : 0
                  //     )
                  //   )
                  //     ? 0
                  //     : Number(primaryData.exchange_gain_or_loss)
                  //   ).toFixed(2)
                  // );

                  if (primaryData.exchange_gain_or_loss) {
                    jQuery("#exchangeGain").html(
                      primaryData.exchange_gain_or_loss
                    );
                    this.setState({
                      pmt_exchange_gain_or_loss:
                        primaryData.exchange_gain_or_loss,
                    });
                  } else {
                    jQuery("#exchangeGain").html(0);
                    this.setState({
                      pmt_exchange_gain_or_loss: 0,
                    });
                  }
                }
              );

              jQuery("#invoice_curr_id").val(primaryData.foreign_currency);
              this.setState(
                {
                  CurrentSelectedCurr: primaryData.foreign_currency,
                  cus_rate_rate: Number(primaryData.exchange_rate),
                },
                () => {
                  // 20240703
                  // this.findInSubAccountList();
                   jQuery("#account_id").val(primaryData.account);
                }
              );

              jQuery("#variable_pay_type").val(primaryData.customer_id);
              this.setState({ selectCustomer: primaryData.customer_id });
              // this.customerjoblist(primaryData.customer_id);

              var client_id = this.state.logged_client_id;
              var customer_id = primaryData.customer_id;
              FetchAllApi.customerjoblist(
                client_id,
                customer_id,
                (err, response) => {
                  var myArray = response.results;
                  if (response.status === 1) {
                    this.setState({ customerjoblist: response.results }, () => {
                      myArray.map((item) => {
                        if (item.id == primaryData.job_id) {
                          this.setState({
                            job_name: item.job_name,
                            job_id: item.id,
                          });
                          jQuery("#jobName").val(item.job_name);
                        }
                      });
                      window.jQuery("#jobName").selectpicker("refresh");
                    });
                  } else {
                    this.setState({ customerjoblist: [] });
                  }
                }
              );

              if (primaryData.tax_inclusive == "0") {
                document.getElementById("changeme").value = false;
                this.setState({ isChecked: false });
              } else {
                document.getElementById("changeme").value = true;

                this.setState({ isChecked: true });
              }
              // var list_length=primaryData.invoice_details.length;

              // setTimeout(() => {
              // jQuery('#invoice_to').val(primaryData.company_address)
              // jQuery('#shipping_to').val(primaryData.shipping_address)
              // jQuery("#Exchange").val(primaryData.exchange_rate);
              // jQuery("#getMe").val(primaryData.exchange_rate);
              var tempid = primaryData.template_id;

              //  alert(tempid.toString())
              jQuery("#template_sel").val(tempid);
              this.setState({
               // billing_address: primaryData.company_address,
                shipping_address: primaryData.shipping_address,
              },()=>{
                this.setInvoiceTo(primaryData.company_address);
              });
              // }, 8000);

              // setTimeout(() => {
              // no need updated in previous lines
              // jQuery("#jobName").val(primaryData.job_name);
              // this.setState({ job_name: primaryData.job_name });

              //..............................................................
              var useme = primaryData.invoice_details.length;
              // alert(useme)
              for (var kk = 1; kk < useme; kk++) {
                // console.log("hfhfhfhfhfh", kk);
                var rows = this.state.rows;
                rows.push("row" + (this.state.initial_value + 1));
                this.setState({
                  isAdd: false,
                  initial_value: this.state.initial_value + 1,
                });
                this.setState({ rows: rows }, () => {});
              }
              console.log("this.state.ItemList...5644", this.state.ItemList);
              for (var kk = 0; kk < useme; kk++) {
                //useme
                this.state.ItemList.length > 0 &&
                  this.state.ItemList.map((item, y) => {
                    // var myVal=item.rate +
                    // '=' +
                    // item.description +
                    // '=' +
                    // item.account_name_category+'='+item.item_name

                    var myVal =
                      item.rate +
                      "=" +
                      item.description +
                      "=" +
                      item.account_name_category +
                      "=" +
                      item.item_name +
                      "=" +
                      item.item_id;

                    // var another= item.rate +
                    // '=' +
                    // item.description +
                    // '=' +
                    // item.account_name_category+'='+primaryData.invoice_details[0].item_name;

                    var another =
                      item.rate +
                      "=" +
                      item.description +
                      "=" +
                      item.account_name_category +
                      "=" +
                      primaryData.invoice_details[kk].item_name +
                      "=" +
                      item.item_id;
                    if (myVal === another) {
                      // jQuery('#cust_items0').val(myVal);
                      window
                        .jQuery("#cust_items" + kk)
                        .selectpicker("val", myVal);
                      if (payment_id > 0) {
                        jQuery("#cust_items" + kk).attr("disabled", true);
                        jQuery("#descr" + kk).attr("disabled", true);
                        jQuery("#item" + kk).attr("disabled", true);
                        jQuery("#unit_price" + kk).attr("disabled", true);
                        jQuery("#quantity" + kk).attr("disabled", true);
                        jQuery("#categry_id" + kk).attr("disabled", true);
                        jQuery("#subtotal" + kk).attr("disabled", true);
                        jQuery("#selectednow" + kk).attr("disabled", true);
                        jQuery("#taxdisable" + kk).prop("disabled", true);
                      }

                      this.state.number_of_columns_list.map((val, idx) => {
                        // console.log("mapf", kk)
                        let value = val.column_name;

                        primaryData.invoice_details[0].custom_column.map(
                          (inv, i) => {
                            jQuery("#addtext" + idx + i).val(inv[value]);
                          }
                        );
                      });

                      jQuery("#item" + kk).val(
                        primaryData.invoice_details[kk].item_name
                      );
                    }
                  });

                // richtext-description-20240410
                // jQuery("#descr" + kk).val(
                //   primaryData.invoice_details[kk].descr
                // );

                this.setDescriptionContent(primaryData.invoice_details[kk].descr,kk);


                jQuery("#unit_price" + kk).val(
                  primaryData.invoice_details[kk].unit_price
                );

                jQuery("#quantity" + kk).val(
                  primaryData.invoice_details[kk].quantity
                );
                jQuery("#categry_id" + kk).val(
                  primaryData.invoice_details[kk].category_id
                );
                jQuery("#subtotal" + kk).val(
                  primaryData.invoice_details[kk].price
                );

                jQuery("#selectedrate_id" + kk).val(
                  primaryData.invoice_details[kk].tax_rate
                );

                jQuery("#selectedtype_id" + kk).val(
                  primaryData.invoice_details[kk].tax_type
                );
                jQuery("#selectednow" + kk).html(
                  primaryData.invoice_details[kk].tax_name
                );
                // setTimeout(() => {
                // this.setState(
                //   {
                //     cus_rate_rate: this.state.exchange_value_ref,
                //   },
                //   () => {
                    this.handleChangeItems("", this.state.rows.length - 1);
                //   }
                // );
                // }, 1000);
                //useme
              }
              //..............................................................
              // }, 6000);

              //Find here

              // setTimeout(() => {
              jQuery("#invoice_date").val(
                moment(primaryData.invoice_date).format("DD/MM/YYYY")
              );

              //for lock date check purpose
              this.setState({old_transaction_date:primaryData.invoice_date});

              jQuery("#due_date").val(
                moment(primaryData.due_date).format("DD/MM/YYYY")
              );

              jQuery("#invoice_no").val(primaryData.invoice_number);
              // }, 6000);

              jQuery("#footer_memo").val(primaryData.memo);
              // jQuery("#footeroptionthanksmessages").val(
              //   primaryData.thanking_message
              // );
              this.setThankingMessage(primaryData.thanking_message);

              //jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
              this.setTermsAndConditions(primaryData.terms_and_conditions);

              // 2024-04-18
              // jQuery("#amount_in_words").val(`${primaryData.amount_in_words} only`);
              jQuery("#amount_in_words").val(`${primaryData.amount_in_words}`);

              if (payment_id == 0) {
                jQuery("#payable_amount").val(primaryData.payment_amount);
                this.setState({ amount_entered: primaryData.payment_amount });
                jQuery("#payment_method option:selected").val();
                jQuery("#payment_method").val(primaryData.payment_method);
                this.setState({ pay_method: primaryData.payment_method });
                jQuery("#payment_date").val(
                  moment(primaryData.payment_date).format("DD/MM/YYYY")
                );

                //for lock date check purpose
                this.setState({ old_payment_date : primaryData.payment_date});

                let nope;

                var date_formated;
                let date = moment(primaryData.payment_date).format(
                  "DD/MM/YYYY"
                );
                if (date != undefined && date != null && date != "") {
                  var array = date.split("/");
                  date_formated = array[2] + "-" + array[1] + "-" + array[0];
                  nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                } else {
                  date_formated = moment().format("YYYY-MM-DD");
                  nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                }

                let res = nope.concat(primaryData.foreign_currency);
                fetch(res)
                  .then(async (response) => response.json())
                  .then(async (data) => {
                    if (data.success == true) {
                      let newObj = this.rename(
                        data.quotes,
                        primaryData.foreign_currency
                      );
                      let currencyAr = [];
                      let first = newObj;
                      currencyAr.push({
                        date: data.start_date,
                        rate:
                          first[data.start_date] &&
                          first[data.start_date][
                            primaryData.foreign_currency +
                              this.state.clientHomeCurrency
                          ]
                            ? first[data.start_date][
                                primaryData.foreign_currency +
                                  this.state.clientHomeCurrency
                              ].toFixed(4)
                            : parseFloat(1).toFixed(4),
                      });
                      let value = currencyAr[0].rate;
                      jQuery("#payment_exchange_rate").val(value);
                    }
                  });
              } else {
                jQuery("#payment_method").val("");
              }

              // jQuery('#reference').val(primaryData.payment_date);
            } else {
              this.setState({ customerjoblist: [] });
            }
          });
        }
      });
    }
    localStorage.setItem("group_payment_request", "");
    localStorage.setItem("sales_invoice_id", "");
  };

  componentWillMount() {}
  onDocumentLoadSuccess = () => {
    // console.log("success");
  };

  set_accounts_selected = (text_selected) => {
    this.setState({ accounts_selected: text_selected });
  };

  logoutLink() {
    localStorage.clear();

    this.props.history.push("/");
  }

  //   dataTaggingFunc(list_id,file_id) {
  //     this.props.history.push("/data_tagging/" + list_id + "/" + file_id );
  //     window.scrollTo(0, 0);
  //   }

  routedChange(parameter) {
    this.props.history.push("/" + parameter);
    window.scrollTo(0, 0);
  }

  pageLink(page_slug) {
    this.props.history.push("/" + page_slug);
  }

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";

    // alert(amount)
    // if(amount !== 'Nan' && amount !== undefined){amount = amount.toString()}

    if (amount != undefined) {
      amount = amount.toString();
    }
    // amount=this.state.grandTotal.toString()
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  delete_or_void_sales_invoice = (val) => {
    let input = {
      client_id: this.state.logged_client_id,
      invoice_id: this.state.invoice_id_used,
      status_to_set: val,
      payment_id: this.state.currpayment_id,
    };

    FetchAllApi.delete_or_void_sales_invoice(input, (err, response) => {
    
      if (response.status === 1) {
        alert(response.message);
        window.jQuery("#modal_void_invoice").modal("hide");
        window.jQuery("#modal_delete_invoice").modal("hide");

        setTimeout(() => {
          if(val===10){
            this.setState({is_deleted: 1})} 
          else if (val===11 ){ 
            this.setState({is_void: true})} 
            else {
           console.log('Error')
          }
           }, 100);

        // window.open('/all_lists')
        // window.location.href = "/all_lists"
        // this.props.history.push('/all_lists')
      } else if (response.status === 2) {
        this.setState({ delete_alert_msg: response.message });
        setTimeout(() => {
          // window.open('/all_lists')
          if (val == 10) {
            window.jQuery("#modal_delete_invoice").modal("hide");
            window.jQuery("#paid_invoice_delete_modal").show("show");
          }
          if (val == 11) {
            window.jQuery("#modal_void_invoice").modal("hide");
            window.jQuery("#paid_invoice_void_modal").show("show");
          }
        }, 500);
      } else {
        alert(response.message);
      }
    });
  };

  delete_or_void_sales_invoice_payment = (val) => {
    let input = {
      client_id: this.state.logged_client_id,
      invoice_id: this.state.invoice_id_used,
      payment_id: this.state.currpayment_id,
      status_to_set: val,
    };

    FetchAllApi.delete_or_void_sales_invoice_payment(input, (err, response) => {
      if (response.status === 1) {
        alert(response.message);
        window.jQuery("#modal_void_invoice_payment").modal("hide");
      } else {
        alert(response.message);
      }
    });
  };

  apply_credit = () => {
    var client_id = this.state.logged_client_id;
    var customer_id = this.state.selectCustomer;
    var job_id = this.state.job_id
      ? this.state.job_id
      : this.state.JobId
      ? this.state.JobId
      : "";
    var credit_account = jQuery("#account_id").val();
    var multi_payment_applied_invoices = "";

    FetchAllApi.customer_creditlists(
      client_id,
      customer_id,
      job_id,
      credit_account,
      multi_payment_applied_invoices,
      0,
      (err, response) => {
        if (response.status === 1) {
          // for table total calc
          let total_amt = 0;
          let total_avail = 0;
          response.response &&
            response.response.map((item, i) => {
              total_amt = total_amt + (item.amount ? Number(item.amount) : 0);
              total_avail =
                total_avail +
                (item.available_credits ? Number(item.available_credits) : 0);
            });
          // for table total calc

          this.setState({
            customer_credit_list: response.response,
            credit_available: response.available_credit_foreign_currency,
            total_amt,
            total_avail,
          });

          setTimeout(() => {
            window.jQuery("#apply_credit_modal").modal("show");
          }, 500);
        } else {
          alert(response.message);
        }
      }
    );
  };

  autoChangeMemo = (val) => {
    // Check wheather this below function call  is need or not
    // modified on 23-09-2022
    //this.handleChangeItems(0, this.state.rows.length - 1);

    if (
      this.state.myarray &&
      this.state.myarray.length > this.state.rows.length - 1 &&
      this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
    ) {
      this.setState({ isTablefilled: false });
    } else {
      this.setState({ isTablefilled: true });
    }
    var btn_type = btn_type;

    let InvoiceCurrency = jQuery("#invoice_curr_id option:selected").val();
    if (InvoiceCurrency != "" && InvoiceCurrency != undefined) {
      this.setState({ isInvoiceCurrency: false });
    } else {
      this.setState({ isInvoiceCurrency: true });
    }
    let template_sel = jQuery("#template_sel option:selected").val();

    if (template_sel != "" && template_sel != undefined) {
      this.setState({ isTemplate: false });
    } else {
      this.setState({ isTemplate: true });
    }

    let customer_id = jQuery("#variable_pay_type option:selected").data(
      "status"
    );
    if (customer_id != "" && customer_id != undefined) {
      this.setState({ isCustomer: false });
    } else {
      this.setState({ isCustomer: true });
    }

    let InvoiceDate = jQuery("#invoice_date").val();
    if (InvoiceDate != "" && InvoiceDate != undefined) {
      this.setState({ isInvoiceDate: false });
    } else {
      this.setState({ isInvoiceDate: true });
    }
    let Due_date = jQuery("#due_date").val();
    if (Due_date != "" && Due_date != undefined) {
      this.setState({ isDueDate: false });
    } else {
      this.setState({ isDueDate: true });
    }

    // let Job_name = jQuery('#jobName option:selected').data('status')
    // if (Job_name != '' && Job_name != undefined) {
    //   this.setState({ isJobName: false })
    // } else {
    //   this.setState({ isJobName: true })
    // }

    var payable_amount = jQuery("#payable_amount").val();
    if (payable_amount != "" && payable_amount != undefined) {
      this.setState({ isPayable_amount: false });

      var paymentdate = jQuery("#payment_date").val();
      if (paymentdate != "" && paymentdate != undefined) {
        this.setState({ isPaymetDate: false });
      } else {
        this.setState({ isPaymetDate: true });
      }

      var payment_method = jQuery("#payment_method option:selected").val();
      if (payment_method != "" && payment_method != undefined) {
        this.setState({ isPaymentMethod: false });
      } else {
        this.setState({ isPaymentMethod: true });
      }

      var balanceCategId = jQuery(
        "#balanceSheetCategeory option:selected"
      ).val();

      if (balanceCategId != "" && balanceCategId != undefined) {
        this.setState({ isbalanceSheetcatId: false });
      } else {
        this.setState({ isbalanceSheetcatId: true });
      }

      if (
        jQuery("#third_account_id option:selected").data("status") !==
          undefined ||
        jQuery("#third_account_id option:selected").data("status") !== ""
      ) {
        this.setState({ is3partyAccRequired: true });
      } else {
      }
    } else {
      // this.setState({ isPayable_amount: true })
    }

    let account_id = jQuery("#account_id").val();
    if (account_id != "" && account_id != undefined) {
      this.setState({ isAccounId: false });
    } else {
      this.setState({ isAccounId: true });
    }

    let payment_date = this.state.payment_date;
    let amount_in_words = jQuery("#amount_in_words").val();

    var selected_date = this.convert_date_format();
    let client_id = this.state.logged_client_id;
    // let customer_id = this.state.active_selected

    let item_total_home_currency = jQuery("#foreigncurrencytyt").html();
    let tax_amount_home_currency = jQuery("#foreign_tax_total").html();
    let descripation = this.state.payment_desc;
    // let grand_total_home_currency = jQuery("#foreign_grand_total").html();
    let grand_total_home_currency =
      Number(item_total_home_currency) + Number(tax_amount_home_currency);

    let item_total_foreign_currency = jQuery("#homecurrencytyt").html();
    let tax_amount_foreign_currency = jQuery("#home_tax_total").html();
    let grand_total_foreign_currency = jQuery("#home_grand_total").html();
    let currency = this.state.CurrentSelectedCurr;
    
    let exchange_rate = this.state.cus_rate_rate;
    let invoice_date = selected_date;
    let company_name = this.state.logged_company_name;
    let type = 1;
    let invoice_number = jQuery("#invoice_no").val();
    let list_id = this.state.logged_user_id;
    let tagged_user_id = this.state.logged_user_id;
    let job_name = jQuery("#job_name").val();
    let shipping_address = jQuery("#shipping_to").val();
    let memo = jQuery("#memo").val();
    // let thanking_message = jQuery("#footeroptionthanksmessages").val();
    let thanking_message = draftToHtml(
      convertToRaw(this.state.thankingMessageEditorState.getCurrentContent())
    );
    // let terms_and_conditions = jQuery("#termcondmsg").val();
    let terms_and_conditions = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    let item_list = this.state.myarray;

    let shipping_to = jQuery("#shipping_to").val();
    let company_address = jQuery("#shipping_to").val();
    let footer_memo = jQuery("#footer_memo").val();
    // let footer_thankyou = jQuery("#footeroptionthanksmessages").val();
    let footer_thankyou = draftToHtml(
      convertToRaw(this.state.thankingMessageEditorState.getCurrentContent())
    );
    // let footer_termscondition = jQuery("#termcondmsg").val();
    let footer_termscondition = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    let mobile_no = jQuery("#mobile_no").val();

    if (this.state.isChecked) {
      var tax_type_name = "inclusive";
    } else {
      var tax_type_name = "exclusive";
    }

    // console.log("item_list_test", this.state.myarray);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var get_invoice_date = new Date(invoice_date);
    var invoice_month = get_invoice_date.getMonth();
    var invoice_day = get_invoice_date.getDate();
    var invoice_year = get_invoice_date.getFullYear();

    var change_invoice_date =
      invoice_day + " " + monthNames[invoice_month] + " " + invoice_year;

    jQuery("#invoice_no_content").html(
      '<span id="invoice_value" style="font-weight: 600;">Invoice No:</span> ' +
        invoice_number
    );
    jQuery("#billed_to_content").next("p").html(company_address);
    jQuery("#date_content").html(
      '<span id="date_value" style="font-weight: 600;">Date:</span> ' +
        change_invoice_date
    );
    jQuery("#thank_u_msg_tag").html(footer_thankyou);
    jQuery("#terms_cond_msg_tag").html(footer_termscondition);
    jQuery("#footer_address").html(company_address);
    jQuery("#footer_mobile").html("| Mob: " + mobile_no);
    console.log(item_list);
    var item_incre = "";
    item_list.map((item_list_data, index) => {
      // console.log("item_list_data", item_list_data);
      // console.log("item_list_data_item_name", item_list_data.item_name);
      var incre = parseInt(index) + 1;

      item_incre +=
        '<tr id="table_row' +
        incre +
        '"><td id="no' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        incre +
        '</td><td id="item' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.item_name +
        '</td><td id="descrip' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.descr +
        '</td><td id="priceeach' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.unit_price +
        '</td><td id="quantity' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.quantity +
        '</td><td id="tax' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.item_tax +
        '</td><td id="total' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.item_total +
        "</td></tr>";
    });
    jQuery("#data_result table tbody").html(item_incre);

    jQuery("#sub_total_container")
      .next("div")
      .html(item_total_foreign_currency);
    jQuery("#tax_total_container").html(
      'Tax<span style="font-size: 14px;font-weight: normal;font-style: italic;">(' +
        tax_type_name +
        ")</span>"
    );
    jQuery("#tax_total_container")
      .next("div")
      .html(tax_amount_foreign_currency);
    jQuery("#grand_total_container")
      .next("div")
      .html(grand_total_foreign_currency);

    // price number to words
    // var numericValue = grand_total_foreign_currency;
    var numericValue = this.state.grandTotal;

    numericValue = parseFloat(numericValue).toFixed(2);
    var amount = numericValue.toString().split(".");
    var split_price = amount[0];
    var split_paisa = amount[1];
    // console.log(
    //     "convert_words",
    //     this.convertNumberToWords(split_price) +
    //     " " +
    //     this.convertNumberToWords(split_paisa)
    // );
    jQuery("#amount_words_tag")
      .next("div")
      .html(
        this.convertNumberToWords(split_price) +
          "Rupees and " +
          this.convertNumberToWords(split_paisa) +
          "Paisa only"
      );

    if (company_address === "") {
      this.setState({ isInvoice_to: true });
    } else {
      this.setState({ isInvoice_to: false });
    }
    if (job_name === "") {
      this.setState({ isjob_name: true });
    } else {
      this.setState({ isjob_name: false });
    }
    if (invoice_number === "") {
      this.setState({ isInvoice_no: true });
    } else {
      this.setState({ isInvoice_no: false });
    }

    if (this.state.selected_customer_name === "") {
      this.setState({ isCustomername: true });
    } else {
      this.setState({ isCustomername: false });
    }
    // if (company_address === "") {
    //   this.setState({ isShippingt_to: true });
    // }
    if (mobile_no === "") {
      this.setState({ isMobileno: true });
    } else {
      this.setState({ isMobileno: false });
    }

    let invoice_to_text = draftToHtml(
      convertToRaw(this.state.invoiceToEditorState.getCurrentContent())
    );

    // if (jQuery("#invoice_to").val() === "") {
    if (invoice_to_text === "") {
      this.setState({ isInvoice_to: true });
    } else {
      this.setState({ isInvoice_to: false });
    }
    if (jQuery("#template_sel").val() === "") {
      this.setState({ isTemplate_selected: true });
    } else {
      this.setState({ isTemplate_selected: false });
    }

    // if (jQuery("#shipping_to").val() === "") {
    //   this.setState({ isShippingt_to: true });
    // } else {
    //   this.setState({ isShippingt_to: false });
    // }
    if (
      // this.state.myarray.length > this.state.rows.length - 1 &&
      // this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].category_id.length >
      //   0 &&
      // this.state.selected_templateName != 'Choose'
      client_id
    ) {
      // this.setState({ isTablefilled: false })
      let job_data = this.state.customerjoblist.find(
        (e) => e.job_name === "Others"
      );
      let job_id;
      let job_name;

      job_id = this.state.JobId;
      job_name = this.state.job_name;

      // if (this.state.job_id === "" && job_data !== undefined) {
      //   job_id = job_data.id;
      //   job_name = job_data.job_name;
      // } else {
      //   job_id = this.state.job_id;
      //   job_name = this.state.job_name;
      // }

      let data = {
        id: this.state.invoice_id_used,
        client_id: this.state.logged_client_id,
        customer_id: customer_id,
        item_total_home_currency: item_total_home_currency,
        tax_amount_home_currency: tax_amount_home_currency,
        grand_total_home_currency: grand_total_home_currency,
        item_total_foreign_currency: item_total_foreign_currency,
        tax_amount_foreign_currency: tax_amount_foreign_currency,
        grand_total_foreign_currency: grand_total_foreign_currency,
        currency: currency,
        exchange_rate: exchange_rate,
        invoice_date: this.custConverter(jQuery("#invoice_date").val()),
        company_name: company_name,
        type: type,
        invoice_number: invoice_number,
        // company_address: jQuery("#invoice_to").val(),
        company_address: draftToHtml(
          convertToRaw(this.state.invoiceToEditorState.getCurrentContent())),
        list_id: list_id,
        tagged_user_id: this.state.logged_user_id,
        item_list: item_list,
        // job_name: job_name,
        job_name: job_name,
        memo: jQuery("#footer_memo").val(),
        shipping_address: company_address,
        thanking_message: thanking_message,
        terms_and_conditions: terms_and_conditions,
        // job_id: jQuery("#jobName option:selected").data("status"),
        job_id: job_id,
        // payment_date: this.custConverter(jQuery('#payment_date').val()),
        payment_date:
          this.custConverter(jQuery("#payment_date").val()) ==
          "undefined-undefined-"
            ? ""
            : this.custConverter(jQuery("#payment_date").val()),
        payment_method: payment_method,
        descripation: this.state.payment_desc,
        amount_in_words: jQuery("#amount_in_words").val(),
        due_date: this.custConverter(jQuery("#due_date").val()),
        reference: jQuery("#reference").val(),
        payment_amount: payable_amount,
        account: jQuery("#account_id").val(),
        payment_exchange_rate: jQuery("#payment_exchange_rate").val(),
        balance_sheet_category: balanceCategId,
        tax_inclusive: this.state.isChecked ? "1" : 0,
        thrird_party_account_id: jQuery("#third_account_id").val(),
        third_party_type: this.state.thrird_party_type,
        third_party_account_name: jQuery(
          "#third_account_id option:selected"
        ).data("status"),
        descripation: this.state.payment_desc,
        template_id: this.state.template_id,
        isInvoiceEditable: this.state.isInvoiceEditable,
        estimate_id: this.state.estimate_id,
      };

      // console.log("datatnow", data);
      if (
        InvoiceCurrency &&
        template_sel &&
        customer_id &&
        InvoiceDate &&
        Due_date &&
        account_id
      ) {
        if (!this.state.isInvoiceEditable || this.state.isInvoiceEditable) {
          let payable_amount = jQuery("#payable_amount").val();
          console.log(JSON.stringify(data));
          if (payable_amount == "" || payable_amount == undefined) {
            if (val == "memo") {
              localStorage.setItem("change_memo", JSON.stringify(data));
              window.open("/create_creditmemo");
            } else {
              localStorage.setItem("copy_invoice", JSON.stringify(data));
              window.open("/create_invoice");
            }
          }
        }
      }
    }
  };

  convertHtmlToPdf(e) {
    if (this.state.selected_templateName === "Choose") {
      this.setState({ isTemplate_selected: true });
    } else {
      this.setState({ isTemplate_selected: false });
      if (
        this.state.myarray.length > this.state.rows.length - 1 &&
        this.state.myarray[this.state.myarray.length - 1].price > 0 &&
        this.state.myarray[this.state.myarray.length - 1].item_name.length >
          0 &&
        this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
      ) {
        var selected_date = this.convert_date_format();
        let client_id = this.state.logged_client_id;
        let customer_id = this.state.active_selected;
        let item_total_home_currency = jQuery("#foreigncurrencytyt").html();
        let tax_amount_home_currency = jQuery("#foreign_tax_total").html();
        let grand_total_home_currency = jQuery("#foreign_grand_total").html();
        let item_total_foreign_currency = jQuery("#homecurrencytyt").html();
        let tax_amount_foreign_currency = jQuery("#home_tax_total").html();
        let grand_total_foreign_currency = jQuery("#home_grand_total").html();
        let currency = this.state.currency_customer;
        let exchange_rate = this.state.exchange_value;
        let invoice_date = selected_date;
        let company_name = this.state.logged_company_name;
        let type = 1;
        let invoice_number = jQuery("#invoice_no").val();
        let list_id = this.state.logged_user_id;
        let tagged_user_id = this.state.tagged_user_id;
        let job_name = jQuery("#job_name").val();
        let shipping_address = jQuery("#shipping_to").val();
        let memo = jQuery("#memo").val();
        // let thanking_message = jQuery("#footeroptionthanksmessages").val();
        let thanking_message = draftToHtml(
          convertToRaw(
            this.state.thankingMessageEditorState.getCurrentContent()
          )
        );
        // let terms_and_conditions = jQuery("#termcondmsg").val();
        let terms_and_conditions = draftToHtml(
          convertToRaw(this.state.editorState.getCurrentContent())
        );
        let item_list = this.state.myarray;

        let shipping_to = jQuery("#shipping_to").val();
        let company_address = jQuery("#shipping_to").val();
        let footer_memo = jQuery("#footer_memo").val();
        // let footer_thankyou = jQuery("#footeroptionthanksmessages").val();
        let footer_thankyou = draftToHtml(
          convertToRaw(
            this.state.thankingMessageEditorState.getCurrentContent()
          )
        );
        //let footer_termscondition = jQuery("#termcondmsg").val();
        let footer_termscondition = draftToHtml(
          convertToRaw(this.state.editorState.getCurrentContent())
        );
        let mobile_no = jQuery("#mobile_no").val();

        if (this.state.isChecked) {
          var tax_type_name = "inclusive";
        } else {
          var tax_type_name = "exclusive";
        }

        // console.log("item_list_test", this.state.myarray);

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var get_invoice_date = new Date(invoice_date);
        var invoice_month = get_invoice_date.getMonth();
        var invoice_day = get_invoice_date.getDate();
        var invoice_year = get_invoice_date.getFullYear();

        var change_invoice_date =
          invoice_day + " " + monthNames[invoice_month] + " " + invoice_year;

        jQuery("#invoice_no_content").html(
          '<span id="invoice_value" style="font-weight: 600;">Invoice No:</span> ' +
            invoice_number
        );
        jQuery("#billed_to_content").next("p").html(company_address);
        jQuery("#date_content").html(
          '<span id="date_value" style="font-weight: 600;">Date:</span> ' +
            change_invoice_date
        );
        jQuery("#thank_u_msg_tag").html(footer_thankyou);
        jQuery("#terms_cond_msg_tag").html(footer_termscondition);
        jQuery("#footer_address").html(company_address);
        jQuery("#footer_mobile").html("| Mob: " + mobile_no);

        var item_incre = "";
        item_list.map((item_list_data, index) => {
          // console.log("item_list_data", item_list_data);
          // console.log("item_list_data_item_name", item_list_data.item_name);
          var incre = parseInt(index) + 1;

          item_incre +=
            '<tr id="table_row' +
            incre +
            '"><td id="no' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px;">' +
            incre +
            '</td><td id="item' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px;">' +
            item_list_data.item_name +
            '</td><td id="descrip' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px;">' +
            item_list_data.descr +
            '</td><td id="priceeach' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px;">' +
            item_list_data.unit_price +
            '</td><td id="quantity' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
            item_list_data.quantity +
            '</td><td id="tax' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
            item_list_data.item_tax +
            '</td><td id="total' +
            incre +
            '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
            item_list_data.item_total +
            "</td></tr>";
        });
        jQuery("#data_result table tbody").html(item_incre);

        jQuery("#sub_total_container")
          .next("div")
          .html(item_total_foreign_currency);
        jQuery("#tax_total_container").html(
          'Tax<span style="font-size: 14px;font-weight: normal;font-style: italic;">(' +
            tax_type_name +
            ")</span>"
        );
        jQuery("#tax_total_container")
          .next("div")
          .html(tax_amount_foreign_currency);
        jQuery("#grand_total_container")
          .next("div")
          .html(grand_total_foreign_currency);

        // price number to words
        // var numericValue = grand_total_foreign_currency;
        var numericValue = this.state.grandTotal;

        numericValue = parseFloat(numericValue).toFixed(2);
        var amount = numericValue.toString().split(".");
        var split_price = amount[0];
        var split_paisa = amount[1];
        // console.log(
        //   "convert_words",
        //   this.convertNumberToWords(split_price) +
        //   " " +
        //   this.convertNumberToWords(split_paisa)
        // );
        jQuery("#amount_words_tag")
          .next("div")
          .html(
            this.convertNumberToWords(split_price) +
              "Rupees and " +
              this.convertNumberToWords(split_paisa) +
              "Paisa only"
          );

        var getHTML = jQuery("#printing_template").html();

        fetch("https://v2018.api2pdf.com/chrome/html", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "27662ed5-3115-47b9-b6df-aec6e053edc8", //Get your API key from https://portal.api2pdf.com      11011305-f6cf-4868-b731-74c53dcf9f89
          },
          body: JSON.stringify({
            html: getHTML,
            inlinePdf: true,
            fileName: "test.pdf",
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              // console.log("test_pdf", res);
              window.open(res.pdf);
              //window.location.href = res.pdf;
            } else {
              // console.log("test_pdf", res.error);
            }
          });
      } else {
        this.setState({ isTablefilled: true });
      }
    }
  }

  desChange = (e) => {
    this.setState({ payment_desc: e.target.value });
  };

  get_customerlist = () => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.customer_list_create_invoice(client_id, (err, response) => {
      // console.log("defaultcatejhwdjkjhgorylist", response);
      // console.log("ashdlhkla", response);
      if (response.status === 1) {
        this.setState({ customer_list: response.list });
      } else {
        this.setState({});
      }
    });
  };
  selected_customer = (id, name, currency) => {
    this.setState({ currency_customer: currency, ToCurrency: currency });
    jQuery("#selected" + id).addClass("active");
    this.setState({
      active_selected: id,
      selected_customer_name: name,
    });
    if (id !== this.state.active)
      jQuery("#selected" + this.state.active).removeClass("active");
  };

  convert_date_format = () => {
    var convert = (str) => {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    };
    return convert(this.state.date);
  };
  convertDateformat = (x) => {
    var convert = (str) => {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    };
    return convert(x);
  };

  auto_create_invoice = () => {
    var primaryData;

    if (
      localStorage.getItem("change_invoice_sales") != "" &&
      localStorage.getItem("change_invoice_sales") != null &&
      localStorage.getItem("change_invoice_sales") != undefined
    ) {
      primaryData = JSON.parse(localStorage.getItem("change_invoice_sales"));
      this.setState({ mysalesarray: primaryData.item_list });
    }
    console.log("change_invoice_sales", primaryData);
    if (
      localStorage.getItem("change_invoice_estimate") != "" &&
      localStorage.getItem("change_invoice_estimate") != null &&
      localStorage.getItem("change_invoice_estimate") != undefined
    ) {
      primaryData = JSON.parse(localStorage.getItem("change_invoice_estimate"));
      this.setState({ myestimatearray: primaryData.item_list });
    }

    if (
      localStorage.getItem("copy_invoice") != "" &&
      localStorage.getItem("copy_invoice") != null &&
      localStorage.getItem("copy_invoice") != undefined
    ) {
      primaryData = JSON.parse(localStorage.getItem("copy_invoice"));
    }

    let filter_id = 2; //1
    FetchAllApi.customer_and_job_list(
      this.state.logged_client_id,
      0,
      filter_id,
      (err, response) => {
        if (response.status == 1) {
          this.setState({ customer_and_job_list: response.list }, () => {
            FetchAllApi.sales_product_item_list(
              this.state.logged_client_id,
              filter_id,
              (err, itemListRes) => {
                if (itemListRes.status === 1) {
                  this.setState({ ItemList: itemListRes.list });
                  console.log("mysalesarray", this.state.mysalesarray);
                  console.log("myestimatearray", this.state.myestimatearray);
                  let paidstaus = primaryData.paid_status
                    ? primaryData.paid_status
                    : "Unpaid";
                  jQuery("#paid_status").val(paidstaus + "--invoice");

                  // jQuery("#invoice_to").val(primaryData.company_address);
                  this.setInvoiceTo(primaryData.company_address);

                  jQuery("#shipping_to").val(primaryData.shipping_address);
                  jQuery("#payment_exchange_rate").val(
                    primaryData.payment_exchange_rate
                  );
                  jQuery("#reference").val(primaryData.reference);
                  this.setState({
                    invoice_id_used: primaryData.id,
                    payment_desc: primaryData.descripation,
                  });

                  //Append here....
                  // jQuery("#getMe").val(primaryData.exchange_rate);

                  // this.makeDisabled();
                  jQuery("#balanceSheetCategeory").val(
                    primaryData.balance_sheet_category
                  );
                  var mySelValue = jQuery(
                    "#balanceSheetCategeory option:selected"
                  ).data("status");
                  if (
                    mySelValue != undefined &&
                    mySelValue != "" &&
                    mySelValue != null
                  ) {
                    this.state.balancesheetlist.forEach((item, i) => {
                      if (item.name == mySelValue) {
                        // alert(item.id)
                        const string = item.name;
                        const Payable = string.includes("ayable");
                        const Receivable = string.includes("eceivable");
                        if (Payable || Receivable) {
                          this.setState({ isThirdPartyName: true });
                          if (Payable) {
                            this.setState({ thrird_party_type: 2 });
                            this.fetchThirdPartyNames(5);
                          }
                          if (Receivable) {
                            this.setState({ thrird_party_type: 1 });

                            this.fetchThirdPartyNames(2);
                          }
                        } else {
                          this.setState({ isThirdPartyName: false });
                        }
                      }
                    });

                    // setTimeout(() => {
                    jQuery("#third_account_id").val(
                      primaryData.thrird_party_account_id
                    );
                    // }, 8000);
                  }

                  this.setState(
                    {
                      currInvoiceId: primaryData.invoice_number,
                      currpayment_id: primaryData.payment_id,
                    },
                    () => {
                      // jQuery("#appliedhom").html(
                      //   (isNaN(
                      //     Number(
                      //       primaryData.payments_applied_home_currency !== undefined
                      //         ? primaryData.payments_applied_home_currency
                      //         : 0
                      //     )
                      //   )
                      //     ? 0
                      //     : Number(primaryData.payments_applied_home_currency)
                      //   ).toFixed(2)
                      // );

                      if (primaryData.payments_applied_home_currency) {
                        jQuery("#appliedhom").html(
                          Number(
                            primaryData.payments_applied_home_currency
                          ).toFixed(2)
                        );
                        this.setState({
                          pmt_applied_home_currency: Number(
                            primaryData.payments_applied_home_currency
                          ).toFixed(2),
                        });
                      } else {
                        jQuery("#appliedhom").html(Number(0).toFixed(2));
                        this.setState({
                          pmt_applied_home_currency: Number(0).toFixed(2),
                        });
                      }

                      // jQuery('#forbaldue').html((primaryData.open_balance_foreign_currency).toFixed(2));
                      // jQuery('#homebaldue').html((primaryData.open_balance_home_currency).toFixed(2));

                      // jQuery('#appliedfor').html((primaryData.payments_applied_foreign_currency).toFixed(2));
                      //hey

                      // jQuery("#appliedfor").html(
                      //   (isNaN(
                      //     Number(
                      //       primaryData.payments_applied_foreign_currency !==
                      //         undefined
                      //         ? primaryData.payments_applied_foreign_currency
                      //         : 0
                      //     )
                      //   )
                      //     ? 0
                      //     : Number(primaryData.payments_applied_foreign_currency)
                      //   ).toFixed(2)
                      // );

                      if (primaryData.payments_applied_foreign_currency) {
                        jQuery("#appliedfor").html(
                          Number(
                            primaryData.payments_applied_foreign_currency
                          ).toFixed(2)
                        );
                        this.setState({
                          pmt_applied_foreign_currency: Number(
                            primaryData.payments_applied_foreign_currency
                          ).toFixed(2),
                        });
                      } else {
                        jQuery("#appliedfor").html(Number(0).toFixed(2));
                        this.setState({
                          pmt_applied_foreign_currency: Number(0).toFixed(2),
                        });
                      }

                      // jQuery("#forbaldue").html(
                      //   (isNaN(
                      //     Number(
                      //       primaryData.open_balance_foreign_currency !== undefined
                      //         ? primaryData.open_balance_foreign_currency
                      //         : 0
                      //     )
                      //   )
                      //     ? 0
                      //     : Number(primaryData.open_balance_foreign_currency)
                      //   ).toFixed(2)
                      // );

                      jQuery("#forbaldue").html(
                        primaryData.open_balance_foreign_currency
                      );
                      this.setState({
                        pmt_balance_due_foreign_currency:
                          primaryData.open_balance_foreign_currency,
                      });

                      // jQuery("#homebaldue").html(
                      //   (isNaN(
                      //     Number(
                      //       primaryData.open_balance_home_currency !== undefined
                      //         ? primaryData.open_balance_home_currency
                      //         : 0
                      //     )
                      //   )
                      //     ? 0
                      //     : Number(primaryData.open_balance_home_currency)
                      //   ).toFixed(2)
                      // );

                      jQuery("#homebaldue").html(
                        primaryData.open_balance_home_currency
                      );
                      this.setState({
                        pmt_balance_due_hom_curency:
                          primaryData.open_balance_home_currency,
                      });

                      // jQuery("#footeroptionthanksmessages").val(
                      //   primaryData.thanking_message
                      // );

                      this.setTermsAndConditions(primaryData.thanking_message);

                      //jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
                      this.setTermsAndConditions(
                        primaryData.terms_and_conditions
                      );
                      //hey

                      // jQuery("#exchangeGain").html(
                      //   (isNaN(
                      //     Number(
                      //       primaryData.exchange_gain_or_loss !== undefined
                      //         ? primaryData.exchange_gain_or_loss
                      //         : 0
                      //     )
                      //   )
                      //     ? 0
                      //     : Number(primaryData.exchange_gain_or_loss)
                      //   ).toFixed(2)
                      // );

                      if (primaryData.exchange_gain_or_loss) {
                        jQuery("#exchangeGain").html(
                          primaryData.exchange_gain_or_loss
                        );
                        this.setState({
                          pmt_exchange_gain_or_loss:
                            primaryData.exchange_gain_or_loss,
                        });
                      } else {
                        jQuery("#exchangeGain").html(0);
                        this.setState({
                          pmt_exchange_gain_or_loss: 0,
                        });
                      }
                    }
                  );

                  jQuery("#invoice_curr_id").val(primaryData.currency);
                  this.setState(
                    {
                      CurrentSelectedCurr: primaryData.currency,
                      cus_rate_rate: Number(primaryData.exchange_rate),
                    },
                    () => {
                      // this.findInSubAccountList();
                      jQuery("#account_id").val(primaryData.account);
                    }
                  );

                  jQuery("#variable_pay_type").val(primaryData.customer_id);
                  this.setState(
                    { selectCustomer: primaryData.customer_id },
                    () => {
                      console.log(
                        "this.state.customer_and_job_list...7029",
                        this.state.customer_and_job_list
                      );
                    }
                  );
                  // alert(primaryData.customer_id);

                  // this.customerjoblist(primaryData.customer_id);
                  //  to choose job name

                  // this.customerjoblist(primaryData.customer_id);
                  var client_id = this.state.logged_client_id;
                  var customer_id = primaryData.customer_id;
                  FetchAllApi.customerjoblist(
                    client_id,
                    customer_id,
                    (err, response) => {
                      var myArray = response.results;
                      if (response.status === 1) {
                        this.setState(
                          { customerjoblist: response.results },
                          () => {
                            myArray.map((item) => {
                              if (item.id == primaryData.job_id) {
                                this.setState({
                                  job_name: item.job_name,
                                  job_id: item.id,
                                });
                                jQuery("#jobName").val(item.job_name);
                              }
                            });
                            window.jQuery("#jobName").selectpicker("refresh");
                          }
                        );
                      } else {
                        this.setState({ customerjoblist: [] });
                      }
                    }
                  );

                  //  to choose job name

                  if (primaryData.tax_inclusive == "0") {
                    document.getElementById("changeme").value = false;
                    this.setState({ isChecked: false });
                  } else {
                    document.getElementById("changeme").value = true;

                    this.setState({ isChecked: true });
                  }
                  // var list_length=primaryData.invoice_details.length;

                  // setTimeout(() => {
                  // jQuery('#invoice_to').val(primaryData.company_address)
                  // jQuery('#shipping_to').val(primaryData.shipping_address)
                  // jQuery("#Exchange").val(primaryData.exchange_rate);
                  // jQuery("#getMe").val(primaryData.exchange_rate);
                  var tempid = primaryData.template_id;

                  //  alert(tempid.toString())
                  // jQuery("#template_sel").val(tempid);
                  this.setState({
                 //   billing_address: primaryData.company_address,
                    shipping_address: primaryData.shipping_address,

                    // loading: false,
                  },()=>{
                    this.setInvoiceTo(primaryData.company_address);
                  });
                  // }, 8000);

                  if (
                    localStorage.getItem("change_invoice_sales") != "" &&
                    localStorage.getItem("change_invoice_sales") != null &&
                    localStorage.getItem("change_invoice_sales") != undefined
                  ) {
                    this.setState({
                      sales_order_id: primaryData.sales_order_id,
                    });
                  }
                  if (
                    localStorage.getItem("change_invoice_estimate") != "" &&
                    localStorage.getItem("change_invoice_estimate") != null &&
                    localStorage.getItem("change_invoice_estimate") != undefined
                  ) {
                    this.setState({ estimate_id: primaryData.estimate_id });
                  }

                  // setTimeout(() => {
                  jQuery("#jobName").val(primaryData.job_name);
                  this.setState({ job_name: primaryData.job_name });

                  //..............................................................
                  var useme = primaryData.item_list.length;

                  // alert(useme)
                  for (var kk = 1; kk < useme; kk++) {
                    var rows = this.state.rows;
                    rows.push("row" + (this.state.initial_value + 1));
                    this.setState({
                      isAdd: false,
                      initial_value: this.state.initial_value + 1,
                    });
                    this.setState({ rows: rows }, () => {});
                  }

                  for (var kk = 0; kk < useme; kk++) {
                    //useme

                    this.state.ItemList.length > 0 &&
                      this.state.ItemList.map((item, y) => {
                        // var myVal=item.rate +
                        // '=' +
                        // item.description +
                        // '=' +
                        // item.account_name_category+'='+item.item_name

                        var myVal =
                          item.rate +
                          "=" +
                          item.description +
                          "=" +
                          item.account_name_category +
                          "=" +
                          item.item_name +
                          "=" +
                          item.item_id;

                        // var another= item.rate +
                        // '=' +
                        // item.description +
                        // '=' +
                        // item.account_name_category+'='+primaryData.invoice_details[0].item_name;

                        var another =
                          item.rate +
                          "=" +
                          item.description +
                          "=" +
                          item.account_name_category +
                          "=" +
                          primaryData.item_list[kk].item_name +
                          "=" +
                          item.item_id;

                        if (myVal === another) {
                          // jQuery('#cust_items0').val(myVal);
                          window
                            .jQuery("#cust_items" + kk)
                            .selectpicker("val", myVal);

                          // jQuery("#cust_items" + kk).attr("disabled", true);
                          // jQuery("#descr" + kk).attr("disabled", true);
                          // jQuery("#item" + kk).attr("disabled", true);
                          // jQuery("#unit_price" + kk).attr("disabled", true);
                          // jQuery("#quantity" + kk).attr("disabled", true);
                          // jQuery("#categry_id" + kk).attr("disabled", true);
                          // jQuery("#subtotal" + kk).attr("disabled", true);
                          // jQuery("#selectednow" + kk).attr("disabled", true);
                          // jQuery("#taxdisable" + kk).prop("disabled", true);

                          jQuery("#item" + kk).val(
                            primaryData.item_list[kk].item_name
                          );

                          jQuery("#itemsid" + kk).val(
                            primaryData.item_list[kk].item_id
                          );
                        }
                      });
                    this.state.number_of_columns_list.map((val, idx) => {
                      let value = val.column_name;

                      primaryData.item_list[0].custom_column.map((inv, i) => {
                        jQuery("#addtext" + idx + i).val(inv[value]);
                      });
                    });

                    // richtext-description-20240410
                    // jQuery("#descr" + kk).val(primaryData.item_list[kk].descr);
                    this.setDescriptionContent(primaryData.item_list[kk].descr,kk);
                    

                    jQuery("#unit_price" + kk).val(
                      primaryData.item_list[kk].unit_price
                    );

                    // jQuery("#quantity" + kk).val(
                    //   primaryData.item_list[kk].quantity
                    // );

                    let remaining_qty = Number(
                      primaryData.item_list[kk].quantity
                    );
                    if (
                      primaryData.item_list[kk].temp_used_quanity &&
                      Number(primaryData.item_list[kk].temp_used_quanity) > 0
                    ) {
                      remaining_qty =
                        remaining_qty -
                        Number(primaryData.item_list[kk].temp_used_quanity);
                    }

                    if (remaining_qty < 0) {
                      remaining_qty = 0;
                    }

                    let remaining_amount = parseFloat(
                      primaryData.item_list[kk].unit_price
                    );
                    remaining_amount = remaining_amount * remaining_qty;

                    jQuery("#quantity" + kk).val(remaining_qty);

                    jQuery("#categry_id" + kk).val(
                      primaryData.item_list[kk].category_id
                    );
                    // jQuery("#subtotal" + kk).val(
                    //   primaryData.item_list[kk].price
                    // );

                    jQuery("#subtotal" + kk).val(remaining_amount);

                    jQuery("#selectedrate_id" + kk).val(
                      primaryData.item_list[kk].tax_rate
                    );

                    jQuery("#selectedtype_id" + kk).val(
                      primaryData.item_list[kk].tax_type
                    );
                    jQuery("#selectednow" + kk).html(
                      primaryData.item_list[kk].tax_name
                    );
                    // setTimeout(() => {

                    // this.setState(
                    //   {
                    //     cus_rate_rate: this.state.exchange_value_ref,
                    //   },
                    //   () => {
                        this.handleChangeItems("", this.state.rows.length - 1);
                    //   }
                    // );
                    // }, 1000);
                    //useme
                  }
                  //..............................................................
                  // }, 6000);

                  //Find here

                  // setTimeout(() => {
                  jQuery("#invoice_date").val(
                    moment(primaryData.invoice_date).format("DD/MM/YYYY")
                  );
                  // jQuery("#due_date").val(
                  //   moment(primaryData.due_date).format("DD/MM/YYYY")
                  // );

                  // jQuery("#invoice_no").val(primaryData.sales_number);
                  // }, 6000);

                  jQuery("#footer_memo").val(primaryData.memo);
                  // jQuery("#footeroptionthanksmessages").val(
                  //   primaryData.thanking_message
                  // );
                  this.setThankingMessage(primaryData.thanking_message);

                  //jQuery("#termcondmsg").val(primaryData.terms_and_conditions);
                  this.setTermsAndConditions(primaryData.terms_and_conditions);

                  // 2024-04-18
                  // jQuery("#amount_in_words").val(`${primaryData.amount_in_words} only`);
                  jQuery("#amount_in_words").val(`${primaryData.amount_in_words}`);

                  jQuery("#payable_amount").val(primaryData.payment_amount);

                  jQuery("#payment_method").val(primaryData.payment_method);

                  jQuery("#payment_date").val(
                    moment(primaryData.payment_date).format("DD/MM/YYYY")
                  );
                  // }

                  setTimeout(() => {
                    this.setState({ loading: false });
                    let date_value = jQuery("#invoice_date").val();
                    this.changeDueDate(
                      this.state.selected_due_date,
                      date_value
                    );
                  }, 1000);

                  //modified on 09-07-2022
                  // jQuery('#reference').val(primaryData.payment_date);
                  jQuery("#reference").val(primaryData.reference);
                  if (
                    localStorage.getItem("change_invoice_sales") != "" &&
                    localStorage.getItem("change_invoice_sales") != null &&
                    localStorage.getItem("change_invoice_sales") != undefined
                  ) {
                    localStorage.setItem("change_invoice_sales", "");
                  }
                  if (
                    localStorage.getItem("change_invoice_estimate") != "" &&
                    localStorage.getItem("change_invoice_estimate") != null &&
                    localStorage.getItem("change_invoice_estimate") != undefined
                  ) {
                    localStorage.setItem("change_invoice_estimate", "");
                  }
                  if (
                    localStorage.getItem("copy_invoice") != "" &&
                    localStorage.getItem("copy_invoice") != null &&
                    localStorage.getItem("copy_invoice") != undefined
                  ) {
                    localStorage.setItem("copy_invoice", "");
                  }
                } else {
                  console.log("Failed to get item List");
                }
              }
            );
          });
        }
      }
    );
  };

  save_template = (e, btn_type) => {
    this.setState({ btn_type: btn_type });
    this.handleChangeItems(0, this.state.rows.length - 1);

    // alert(jQuery('#categry_id'+0).val())
    if (
      this.state.myarray.length > this.state.rows.length - 1 &&
      this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      this.state.myarray[this.state.myarray.length - 1].category_id.length > 0
      //&& item_check != ''
    ) {
      this.setState({ isTablefilled: false });
    } else {
      this.setState({ isTablefilled: true });
    }
    var btn_type = btn_type;
    // console.log("jhsvdkjash", this.state.myarray);
    let InvoiceCurrency = jQuery("#invoice_curr_id option:selected").val();
    // alert(InvoiceCurrency)
    if (InvoiceCurrency != "" && InvoiceCurrency != undefined) {
      this.setState({ isInvoiceCurrency: false });
    } else {
      this.setState({ isInvoiceCurrency: true });
    }
    let template_sel = jQuery("#template_sel option:selected").val();

    if (template_sel != "" && template_sel != undefined) {
      this.setState({ isTemplate: false });
    } else {
      this.setState({ isTemplate: true });
    }

    let customer_id = jQuery("#variable_pay_type option:selected").data(
      "status"
    );
    if (customer_id != "" && customer_id != undefined) {
      this.setState({ isCustomer: false });
    } else {
      this.setState({ isCustomer: true });
    }

    let InvoiceDate = jQuery("#invoice_date").val();
    if (InvoiceDate != "" && InvoiceDate != undefined) {
      this.setState({ isInvoiceDate: false });
    } else {
      this.setState({ isInvoiceDate: true });
    }
    let Due_date = jQuery("#due_date").val();
    if (Due_date != "" && Due_date != undefined) {
      this.setState({ isDueDate: false });
    } else {
      this.setState({ isDueDate: true });
    }

    // let Job_name = jQuery('#jobName option:selected').data('status')
    // if (Job_name != '' && Job_name != undefined) {
    //   this.setState({ isJobName: false })
    // } else {
    //   this.setState({ isJobName: true })
    // }

    var payable_amount = jQuery("#payable_amount").val();
    if (payable_amount != "" && payable_amount != undefined) {
      this.setState({ isPayable_amount: false });

      var paymentdate = jQuery("#payment_date").val();
      if (paymentdate != "" && paymentdate != undefined) {
        this.setState({ isPaymetDate: false });
      } else {
        this.setState({ isPaymetDate: true });
      }

      var payment_method = jQuery("#payment_method").val()
        ? jQuery("#payment_method").val()
        : 18; // jQuery("#payment_method option:selected").val();

      console.log(jQuery("#payment_method option:selected").val());
      if (payment_method != "" && payment_method != undefined) {
        this.setState({ isPaymentMethod: false });
      } else {
        this.setState({ isPaymentMethod: true });
      }

      var balanceCategId = jQuery(
        "#balanceSheetCategeory option:selected"
      ).val();

      if (balanceCategId != "" && balanceCategId != undefined) {
        this.setState({ isbalanceSheetcatId: false });
      } else {
        this.setState({ isbalanceSheetcatId: true });
      }

      if (
        jQuery("#third_account_id option:selected").data("status") !==
          undefined ||
        jQuery("#third_account_id option:selected").data("status") !== ""
      ) {
        this.setState({ is3partyAccRequired: false });
      } else {
        this.setState({ is3partyAccRequired: true });
      }
    } else {
      // this.setState({ isPayable_amount: true })
    }

    let account_id = jQuery("#account_id").val();
    if (account_id != "" && account_id != undefined) {
      this.setState({ isAccounId: false });
    } else {
      this.setState({ isAccounId: true });
    }

    let payment_date = this.state.payment_date;
    let amount_in_words = jQuery("#amount_in_words").val();

    var selected_date = this.convert_date_format();
    let client_id = this.state.logged_client_id;
    // let customer_id = this.state.active_selected

    let item_total_home_currency = jQuery("#foreigncurrencytyt").html();
    let tax_amount_home_currency = jQuery("#foreign_tax_total").html();
    let descripation = this.state.payment_desc;
    // let grand_total_home_currency = jQuery("#foreign_grand_total").html();
    let grand_total_home_currency =
      Number(item_total_home_currency) + Number(tax_amount_home_currency);

    let item_total_foreign_currency = jQuery("#homecurrencytyt").html();
    let tax_amount_foreign_currency = jQuery("#home_tax_total").html();
    let grand_total_foreign_currency = jQuery("#home_grand_total").html();
    let currency = this.state.CurrentSelectedCurr;
    
    let exchange_rate = this.state.cus_rate_rate;
    let invoice_date = selected_date;
    let company_name = this.state.logged_company_name;
    let type = 1;
    let invoice_number = jQuery("#invoice_no").val();
    let list_id = this.state.logged_user_id;
    let tagged_user_id = this.state.logged_user_id;
    let job_name = jQuery("#job_name").val();
    let shipping_address = jQuery("#shipping_to").val();
    let memo = jQuery("#memo").val();
    //let thanking_message = jQuery("#footeroptionthanksmessages").val();
    let thanking_message = draftToHtml(
      convertToRaw(this.state.thankingMessageEditorState.getCurrentContent())
    );
    //let terms_and_conditions = jQuery("#termcondmsg").val();
    let terms_and_conditions = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    let item_list = this.state.myarray;
    let sales_order_item_list = this.state.mysalesarray;
    let estimate_item_list = this.state.myestimatearray;
    let shipping_to = jQuery("#shipping_to").val();
    let company_address = jQuery("#shipping_to").val();
    let footer_memo = jQuery("#footer_memo").val();
    // let footer_thankyou = jQuery("#footeroptionthanksmessages").val();
    let footer_thankyou = draftToHtml(
      convertToRaw(this.state.thankingMessageEditorState.getCurrentContent())
    );
    //let footer_termscondition = jQuery("#termcondmsg").val();
    let footer_termscondition = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    let mobile_no = jQuery("#mobile_no").val();

    if (this.state.isChecked) {
      var tax_type_name = "inclusive";
    } else {
      var tax_type_name = "exclusive";
    }

    // console.log("item_list_test", this.state.myarray);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var get_invoice_date = new Date(invoice_date);
    var invoice_month = get_invoice_date.getMonth();
    var invoice_day = get_invoice_date.getDate();
    var invoice_year = get_invoice_date.getFullYear();

    var change_invoice_date =
      invoice_day + " " + monthNames[invoice_month] + " " + invoice_year;

    jQuery("#invoice_no_content").html(
      '<span id="invoice_value" style="font-weight: 600;">Invoice No:</span> ' +
        invoice_number
    );
    jQuery("#billed_to_content").next("p").html(company_address);
    jQuery("#date_content").html(
      '<span id="date_value" style="font-weight: 600;">Date:</span> ' +
        change_invoice_date
    );
    jQuery("#thank_u_msg_tag").html(footer_thankyou);
    jQuery("#terms_cond_msg_tag").html(footer_termscondition);
    jQuery("#footer_address").html(company_address);
    jQuery("#footer_mobile").html("| Mob: " + mobile_no);

    var item_incre = "";
    item_list.map((item_list_data, index) => {
      // console.log("item_list_data", item_list_data);
      // console.log("item_list_data_item_name", item_list_data.item_name);
      var incre = parseInt(index) + 1;

      item_incre +=
        '<tr id="table_row' +
        incre +
        '"><td id="no' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        incre +
        '</td><td id="item' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.item_name +
        '</td><td id="descrip' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.descr +
        '</td><td id="priceeach' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px;">' +
        item_list_data.unit_price +
        '</td><td id="quantity' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.quantity +
        '</td><td id="tax' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.item_tax +
        '</td><td id="total' +
        incre +
        '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
        item_list_data.item_total +
        "</td></tr>";
    });
    var sales_item_incre = "";
    if (this.state.sales_order_id && this.state.sales_order_id != undefined) {
      item_list.map((item_list_data, index) => {
        // console.log("item_list_data", item_list_data);
        // console.log("item_list_data_item_name", item_list_data.item_name);
        var incre = parseInt(index) + 1;

        sales_item_incre +=
          '<tr id="table_row' +
          incre +
          '"><td id="no' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px;">' +
          incre +
          '</td><td id="item' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px;">' +
          item_list_data.item_name +
          '</td><td id="descrip' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px;">' +
          item_list_data.descr +
          '</td><td id="priceeach' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px;">' +
          item_list_data.unit_price +
          '</td><td id="quantity' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
          item_list_data.quantity +
          '</td><td id="tax' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
          item_list_data.item_tax +
          '</td><td id="total' +
          incre +
          '" style="font-size: 13px; padding: 12px 8px; text-align: center;">' +
          item_list_data.item_total +
          "</td></tr>";
      });
    }
    jQuery("#data_result table tbody").html(item_incre);

    jQuery("#sub_total_container")
      .next("div")
      .html(item_total_foreign_currency);
    jQuery("#tax_total_container").html(
      'Tax<span style="font-size: 14px;font-weight: normal;font-style: italic;">(' +
        tax_type_name +
        ")</span>"
    );
    jQuery("#tax_total_container")
      .next("div")
      .html(tax_amount_foreign_currency);
    jQuery("#grand_total_container")
      .next("div")
      .html(grand_total_foreign_currency);

    // price number to words
    // var numericValue = grand_total_foreign_currency;
    var numericValue = this.state.grandTotal;

    numericValue = parseFloat(numericValue).toFixed(2);
    var amount = numericValue.toString().split(".");
    var split_price = amount[0];
    var split_paisa = amount[1];
    // console.log(
    //   "convert_words",
    //   this.convertNumberToWords(split_price) +
    //   " " +
    //   this.convertNumberToWords(split_paisa)
    // );
    jQuery("#amount_words_tag")
      .next("div")
      .html(
        this.convertNumberToWords(split_price) +
          "Rupees and " +
          this.convertNumberToWords(split_paisa) +
          "Paisa only"
      );

    // if (company_address == "") {
    //   this.setState({ isInvoice_to: true });
    // } else {
    //   this.setState({ isInvoice_to: false });
    // }
    if (job_name === "") {
      this.setState({ isjob_name: true });
    } else {
      this.setState({ isjob_name: false });
    }
    if (invoice_number === "") {
      this.setState({ isInvoice_no: true });
    } else {
      this.setState({ isInvoice_no: false });
    }

    if (this.state.selected_customer_name === "") {
      this.setState({ isCustomername: true });
    } else {
      this.setState({ isCustomername: false });
    }
    // if (company_address === "") {
    //   this.setState({ isShippingt_to: true });
    // } else {
    //   this.setState({ isShippingt_to: false });
    // }
    if (mobile_no === "") {
      this.setState({ isMobileno: true });
    } else {
      this.setState({ isMobileno: false });
    }

    // if (jQuery("#invoice_to").val() === "") {
    //   this.setState({ isInvoice_to: true });
    // } else {
    //   this.setState({ isInvoice_to: false });
    // }
    if (jQuery("#template_sel").val() === "") {
      this.setState({ isTemplate_selected: true });
    } else {
      this.setState({ isTemplate_selected: false });
    }

    let is_invalid_shipping = false;
    // if (this.state.shipping_address) {
    //   is_invalid_shipping = false;
    //   this.setState({ isShippingt_to: false });
    // } else {
    //   is_invalid_shipping = true;
    //   this.setState({ isShippingt_to: true });
    // }

    let is_invalid_billing_address = false;
    // if (this.state.billing_address) {
    if(draftToHtml(convertToRaw(this.state.invoiceToEditorState.getCurrentContent()))) {
      is_invalid_billing_address = false;
    } else {
      is_invalid_billing_address = true;
    }

    if (
      // this.state.myarray.length > this.state.rows.length - 1 &&
      // this.state.myarray[this.state.myarray.length - 1].price > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].item_name.length > 0 &&
      // this.state.myarray[this.state.myarray.length - 1].category_id.length >
      //   0 &&
      // this.state.selected_templateName != 'Choose'
      client_id
    ) {
      // this.setState({ isTablefilled: false })
      let job_data = this.state.customerjoblist.find(
        (e) => e.job_name === "Others"
      );
      let job_id;
      let job_name;
      // if (this.state.job_id === "" && job_data !== undefined) {
      //   job_id = job_data.id;
      //   job_name = job_data.job_name;
      // } else {

      //Modified on 09-07-2022
      // job_id = this.state.job_id;
      job_id = this.state.job_id
        ? this.state.job_id
        : this.state.JobId
        ? this.state.JobId
        : "";
      job_name = this.state.job_name;
      // }

      if (!this.state.job_id && !this.state.JobId) {
        this.setState({ isJobName: true });
      } else {
        this.setState({ isJobName: false });
      }
      const source_attachment_ids = this.state.list_of_attachments.map(obj => obj.source_id);
      let data = {
        client_id: this.state.logged_client_id,
        estimate_id: this.state.estimate_id,
        sales_order_id: this.state.sales_order_id,
        customer_id: customer_id,
        item_total_home_currency: item_total_home_currency,
        tax_amount_home_currency: tax_amount_home_currency,
        grand_total_home_currency: grand_total_home_currency,
        item_total_foreign_currency: item_total_foreign_currency,
        tax_amount_foreign_currency: tax_amount_foreign_currency,
        grand_total_foreign_currency: grand_total_foreign_currency,
        currency: currency,
        exchange_rate: exchange_rate,
        invoice_date: this.custConverter(jQuery("#invoice_date").val()),
        company_name: company_name,
        type: type,
        invoice_number: invoice_number,
        // company_address: jQuery("#invoice_to").val(),
        company_address: draftToHtml(
          convertToRaw(this.state.invoiceToEditorState.getCurrentContent())),
        list_id: list_id,
        tagged_user_id: this.state.logged_user_id,
        item_list: item_list,
        sales_order_item_list: sales_order_item_list,
        estimate_item_list: estimate_item_list,
        // job_name: job_name,
        job_name: job_name,
        memo: jQuery("#footer_memo").val(),
        shipping_address: company_address,
        thanking_message: thanking_message,
        terms_and_conditions: terms_and_conditions,
        // job_id: jQuery("#jobName option:selected").data("status"),
        job_id: job_id,
        // payment_date: this.custConverter(jQuery('#payment_date').val()),
        payment_date:
          this.custConverter(jQuery("#payment_date").val()) ==
          "undefined-undefined-"
            ? ""
            : this.custConverter(jQuery("#payment_date").val()),
        payment_method: payment_method,
        descripation: this.state.payment_desc,
        amount_in_words: jQuery("#amount_in_words").val(),
        due_date: this.custConverter(jQuery("#due_date").val()),
        reference: jQuery("#reference").val(),
        payment_amount: payable_amount,
        account: jQuery("#account_id").val(),
        payment_exchange_rate: jQuery("#payment_exchange_rate").val(),
        balance_sheet_category: balanceCategId,
        tax_inclusive: this.state.isChecked ? "1" : 0,
        thrird_party_account_id: jQuery("#third_account_id").val(),
        third_party_type: this.state.thrird_party_type,
        third_party_account_name: jQuery(
          "#third_account_id option:selected"
        ).data("status"),
        descripation: this.state.payment_desc,
        template_id: this.state.template_id,
        due_date_term: this.state.selected_due_date,
        source_attachment_ids: source_attachment_ids.join(','),
        custom_header_fields: this.state.custom_header_fields,

        custom_footer_fields: this.state.custom_footer_fields,
        is_credit_limit_accepted: this.state.is_credit_limit_accepted,
        lock_date_password: this.state.old_password,
      };

      if (
        InvoiceCurrency &&
        template_sel &&
        customer_id &&
        InvoiceDate &&
        Due_date &&
        account_id &&
        is_invalid_shipping == false &&
        is_invalid_billing_address == false &&
        this.state.isJobName == false
      ) {
        if (!this.state.isInvoiceEditable) {
          let payable_amount = jQuery("#payable_amount").val();
          if (payable_amount == "" || payable_amount == undefined) {
            // console.log(object)
            // alert(payable_amount)
            // alert(typeof payable_amount)
            FetchAllApi.create_sales_invoice(data, (err, response) => {
              this.update_template_properties();
              // console.log("defaultcatejhwdjkjhgorylist", response);

              if (response.status === 1) {
                this.setState({ is_credit_limit_accepted: 0 });

                if (
                  response.group_accounting_list_id &&
                  response.group_accounting_list_id != 0
                ) {
                  // alert('please wait, pdf is generating for Manual Journal');
                  alert(
                    "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                  );
                  this.setState({ manualLoading: true });
                  this.groupAcc(
                    response.invoice_id,
                    response.group_accounting_list_id
                  );
                }

                if (btn_type === "save") {
                  // localStorage.setItem('customer','')
                  // alert(response.message)
                  // for pdf print
                  this.setState({
                    isInvoiceEditable: true,
                    invoice_id_used: response.invoice_id,
                    hasId:response.invoice_id
                  });

                  let grand_total_foreign_currency_string =
                    grand_total_foreign_currency;
                  grand_total_foreign_currency_string =
                    grand_total_foreign_currency_string.replace(/,/g, "");

                  jQuery("#forbaldue").html(
                    grand_total_foreign_currency_string
                  );
                  jQuery("#homebaldue").html(grand_total_home_currency);

                  this.setState({
                    pmt_balance_due_foreign_currency:
                      grand_total_foreign_currency_string,
                  });
                  this.setState({
                    pmt_balance_due_hom_curency: grand_total_home_currency,
                  });
                  // for pdf print

                  this.setState({ isClose: true });
                  setTimeout(() => {
                    // if (jQuery("#closeme").fadeOut(2000));
                    this.setState({ isClose: false });

                    // jQuery(".form-control").val("");
                    // jQuery("#homecurrencytyt").html("0");
                    // jQuery("#foreigncurrencytyt").html("0");
                    // jQuery("#home_grand_total").html("0");
                    // jQuery("#foreign_grand_total").html("0");
                    // jQuery("#selectednow0").html("Choose");
                    // jQuery("#home_tax_total").html("0");
                  }, 4000);
                  if (
                    response.group_accounting_list_id == undefined ||
                    response.group_accounting_list_id == 0
                  ) {
                    // localStorage.setItem('page_select','Invoice')
                    // this.props.history.push("/loading", ["/all_lists"]);
                  }
                } else {
                  localStorage.setItem("customer", "");
                  // alert("Succesfully saved, Click ok to proceed next one");
                  // this.props.history.push("/loading", ["/create_invoice"]);
                  //window.location.reload();
                  this.setState({
                    isClose: true,
                    third_party_value: "",
                  //  billing_address: "",
                    shipping_address: "",
                  },()=>{
                    this.setInvoiceTo("");
                  });
                  setTimeout(() => {
                    jQuery("#invoice_date").val("");
                    jQuery("#due_date").val("");
                    jQuery(".form-control").val("");
                    jQuery("#homecurrencytyt").html("0");
                    jQuery("#foreigncurrencytyt").html("0");
                    jQuery("#home_grand_total").html("0");
                    jQuery("#foreign_grand_total").html("0");
                    jQuery("#selectednow0").html("Choose");
                    jQuery("#home_tax_total").html("0");
                    jQuery("#third_account_id").html("");

                    // jQuery("#itemsid0").val("");
                    // jQuery("#item0").val("");
                    // jQuery("#cust_items0").val("");
                    // jQuery("#cust_items0").html("");

                    // jQuery("#cust_items0").prop("selected", false).trigger("change");
                    window.location.reload();
                    this.setState({ isClose: false }, () => {
                      this.getNextInvoiceNumber();
                    });
                  }, 3000);
                  setTimeout(() => {
                    if (jQuery("#closeme").fadeOut(2000));
                  }, 4000);
                  // alert(response.message)
                  this.setState({
                    isClose: true,
                    isCustomername: false,
                    isAdd: false,
                    isInvoice_to: false,
                    isShippingt_to: false,
                    isThanking_msg: false,
                    isTerms_cond_msg: false,
                    isInvoice_no: false,
                    isjob_name: false,
                    // isTablefilled: false,
                    isCustomername: false,
                    selected_customer_name: "",
                    tax_home_currency: "0",
                    tax_home_currency: "0",
                    rows: ["row 1"],
                  });
                  setTimeout(function () {
                    if (jQuery("#closeme").fadeOut(2000));
                  }, 8000);
                  jQuery(".form-control").val("");
                  jQuery("#homecurrencytyt").html("0");
                  jQuery("#foreigncurrencytyt").html("0");
                  jQuery("#home_grand_total").html("0");
                  jQuery("#foreign_grand_total").html("0");
                  jQuery("#selectednow0").html("Choose");
                  jQuery("#home_tax_total").html("0");

                  this.setState({});
                }
              } else if (response.status === 2) {
                this.setState({ credit_limit_msg: response.message });
                setTimeout(() => {
                  window.jQuery("#credit_limit").modal("show");
                }, 500);

                // var mymsg = response.message;
                // if (mymsg.includes("Invoice number already exists")) {
                //   this.setState({
                //     ExistMsg: response.message,
                //     isAlreadyTaken: true,
                //   });
                // }
                // this.setState({
                //   isRejected: true,
                //   reject_msg: response.message,
                // });
                // setTimeout(function () {
                //   if (jQuery("#failed_alrt").fadeOut(2000));
                // }, 4000);
              } else if (response.status === 3) {
                this.setState({lock_date_error_message:response.message});
                jQuery("#lock_date").modal("show");
              } else if (response.status === 4) {
                //  alert(response.message)
                window.jQuery("#asking_password_for_delete").modal("show");
                this.setState({ deleteMsg: true });
              } else {
                alert(response.message);
              }
            });
          } else {
            // alert(payable_amount)
            // alert(typeof payable_amount)
            // if(balanceCategId !==undefined)
            // alert('i am on the wa')
            if (
              paymentdate != undefined &&
              paymentdate != "" &&
              payment_method != undefined &&
              payment_method != "" &&
              balanceCategId != undefined &&
              balanceCategId != ""
            ) {
              if (this.state.isThirdPartyName) {
                if (
                  jQuery("#third_account_id option:selected").data("status") !==
                    undefined &&
                  jQuery("#third_account_id option:selected").data("status") !==
                    ""
                ) {
                  FetchAllApi.create_sales_invoice(data, (err, response) => {
                    this.update_template_properties();

                    if (response.status === 1) {
                      this.setState({ is_credit_limit_accepted: 0 });
                      if (
                        response.group_accounting_list_id &&
                        response.group_accounting_list_id != 0
                      ) {
                        // alert('please wait, pdf is generating for Manual Journal');
                        alert(
                          "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                        );
                        this.setState({ manualLoading: true });
                        this.groupAcc(
                          response.invoice_id,
                          response.group_accounting_list_id
                        );
                      }
                      if (btn_type === "save") {
                        // localStorage.setItem('customer','')

                        this.setState(
                          {
                            isInvoiceEditable: true,
                            invoice_id_used: response.invoice_id,
                            currpayment_id: response.payment_id,
                            hasId:response.invoice_id
                          },
                          () => {
                            this.getInvoicePaymentDetails();
                          }
                        );

                        this.setState({ isClose: true });
                        setTimeout(() => {
                          // if (jQuery("#closeme").fadeOut(2000));
                          this.setState({ isClose: false });

                          // jQuery(".form-control").val("");
                          // jQuery("#homecurrencytyt").html("0");
                          // jQuery("#foreigncurrencytyt").html("0");
                          // jQuery("#home_grand_total").html("0");
                          // jQuery("#foreign_grand_total").html("0");
                          // jQuery("#selectednow0").html("Choose");
                          // jQuery("#home_tax_total").html("0");
                        }, 4000);
                      } else {
                        this.setState({
                          isClose: true,
                          third_party_value: "",
                          amount_entered: "",
                          payment_desc: "",
                          isThirdPartyName: false,
                         // billing_address: "",
                          shipping_address: "",
                        },()=>{
                          this.setInvoiceTo("");
                        });
                        setTimeout(() => {
                          jQuery(".form-control").val("");
                          jQuery("#homecurrencytyt").html("0");
                          jQuery("#foreigncurrencytyt").html("0");
                          jQuery("#home_grand_total").html("0");
                          jQuery("#foreign_grand_total").html("0");
                          jQuery("#selectednow0").html("Choose");
                          jQuery("#home_tax_total").html("0");
                          jQuery("#third_account_id").html("");
                          jQuery("#payable_amount").val("");
                          window.location.reload();
                          this.setState({ isClose: false }, () => {
                            this.getNextInvoiceNumber();
                          });
                        }, 4000);
                        setTimeout(() => {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 4000);
                        // alert(response.message)
                        this.setState({
                          isClose: true,
                          isCustomername: false,
                          isAdd: false,
                          isInvoice_to: false,
                          isShippingt_to: false,
                          isThanking_msg: false,
                          isTerms_cond_msg: false,
                          isInvoice_no: false,
                          isjob_name: false,
                          // isTablefilled: false,
                          isCustomername: false,
                          selected_customer_name: "",
                          tax_home_currency: "0",
                          tax_home_currency: "0",
                          rows: ["row 1"],
                        });
                        setTimeout(function () {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 8000);
                        jQuery(".form-control").val("");
                        jQuery("#homecurrencytyt").html("0");
                        jQuery("#foreigncurrencytyt").html("0");
                        jQuery("#home_grand_total").html("0");
                        jQuery("#foreign_grand_total").html("0");
                        jQuery("#selectednow0").html("Choose");
                        jQuery("#home_tax_total").html("0");

                        this.setState({});
                      }
                    } else if (response.status === 2) {
                      this.setState({ credit_limit_msg: response.message });
                      setTimeout(() => {
                        window.jQuery("#credit_limit").modal("show");
                      }, 500);
                      // var mymsg = response.message;
                      // if (mymsg.includes("Invoice number already exists")) {
                      //   this.setState({
                      //     ExistMsg: response.message,
                      //     isAlreadyTaken: true,
                      //   });
                      // }
                      // this.setState({
                      //   isRejected: true,
                      //   reject_msg: response.message,
                      // });
                      // setTimeout(function () {
                      //   if (jQuery("#failed_alrt").fadeOut(2000));
                      // }, 4000);
                    } else if (response.status === 3) {
                      this.setState({lock_date_error_message:response.message});
                      jQuery("#lock_date").modal("show");
                    } else if (response.status === 4) {
                      //  alert(response.message)
                      window
                        .jQuery("#asking_password_for_delete")
                        .modal("show");
                      this.setState({ deleteMsg: true });
                    } else {
                      alert(response.message);
                    }
                  });
                } else {
                  this.setState({
                    is3partyAccRequired: true,
                    isFailed: true,
                    errormessage: "Please fill out payment details",
                  });
                  setTimeout(() => {
                    this.setState({ isFailed: false, errormessage: "" });
                  }, 3000);
                }
              } else {
                FetchAllApi.create_sales_invoice(data, (err, response) => {
                  this.update_template_properties();

                  if (response.status === 1) {
                    this.setState({ is_credit_limit_accepted: 0 });
                    if (
                      response.group_accounting_list_id &&
                      response.group_accounting_list_id != 0
                    ) {
                      // alert('please wait, pdf is generating for Manual Journal');
                      alert(
                        "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                      );
                      this.setState({ manualLoading: true });
                      this.groupAcc(
                        response.invoice_id,
                        response.group_accounting_list_id
                      );
                    }
                    if (btn_type === "save") {
                      // localStorage.setItem('customer','')
                      this.setState(
                        {
                          isInvoiceEditable: true,
                          invoice_id_used: response.invoice_id,
                          currpayment_id: response.payment_id,
                          hasId:response.invoice_id
                        },
                        () => {
                          this.getInvoicePaymentDetails();
                        }
                      );

                      this.setState({ isClose: true });
                      setTimeout(() => {
                        // if (jQuery("#closeme").fadeOut(2000));
                        this.setState({ isClose: false });

                        // jQuery(".form-control").val("");
                        // jQuery("#homecurrencytyt").html("0");
                        // jQuery("#foreigncurrencytyt").html("0");
                        // jQuery("#home_grand_total").html("0");
                        // jQuery("#foreign_grand_total").html("0");
                        // jQuery("#selectednow0").html("Choose");
                        // jQuery("#home_tax_total").html("0");
                      }, 4000);
                      // localStorage.setItem('page_select','Invoice')
                      // this.props.history.push("/loading", ["/all_lists"]);
                    } else {
                      localStorage.setItem("customer", "");
                      // alert("Succesfully saved, Click ok to proceed next one");
                      // this.props.history.push("/loading", ["/create_invoice"]);
                      // window.location.reload();
                      this.setState({
                        isClose: true,
                        amount_entered: "",
                       // billing_address: "",
                        shipping_address: "",
                      },()=>{
                        this.setInvoiceTo("");
                      });
                      setTimeout(() => {
                        jQuery(".form-control").val("");
                        jQuery("#homecurrencytyt").html("0");
                        jQuery("#foreigncurrencytyt").html("0");
                        jQuery("#home_grand_total").html("0");
                        jQuery("#foreign_grand_total").html("0");
                        jQuery("#selectednow0").html("Choose");
                        jQuery("#home_tax_total").html("0");
                        jQuery("#payable_amount").val("");

                        this.setState(
                          {
                            isClose: false,
                            payment_desc: "",
                          //  billing_address: "",
                            shipping_address: "",
                          },
                          () => {
                            this.getNextInvoiceNumber();
                            this.setInvoiceTo("");
                          }
                        );
                        window.location.reload();
                      }, 4000);

                      setTimeout(() => {
                        if (jQuery("#closeme").fadeOut(2000));
                      }, 4000);
                      // alert(response.message)
                      this.setState({
                        isClose: true,
                        isCustomername: false,
                        isAdd: false,
                        isInvoice_to: false,
                        isShippingt_to: false,
                        isThanking_msg: false,
                        isTerms_cond_msg: false,
                        isInvoice_no: false,
                        isjob_name: false,
                        // isTablefilled: false,
                        isCustomername: false,
                        selected_customer_name: "",
                        tax_home_currency: "0",
                        tax_home_currency: "0",
                        rows: ["row 1"],
                      });
                      setTimeout(function () {
                        if (jQuery("#closeme").fadeOut(2000));
                      }, 8000);
                      jQuery(".form-control").val("");
                      jQuery("#homecurrencytyt").html("0");
                      jQuery("#foreigncurrencytyt").html("0");
                      jQuery("#home_grand_total").html("0");
                      jQuery("#foreign_grand_total").html("0");
                      jQuery("#selectednow0").html("Choose");
                      jQuery("#home_tax_total").html("0");

                      this.setState({});
                    }
                  } else if (response.status === 2) {
                    this.setState({ credit_limit_msg: response.message });
                    setTimeout(() => {
                      window.jQuery("#credit_limit").modal("show");
                    }, 500);
                    // var mymsg = response.message;
                    // if (mymsg.includes("Invoice number already exists")) {
                    //   this.setState({
                    //     ExistMsg: response.message,
                    //     isAlreadyTaken: true,
                    //   });
                    // }
                    // this.setState({
                    //   isRejected: true,
                    //   reject_msg: response.message,
                    // });
                    // setTimeout(function () {
                    //   if (jQuery("#failed_alrt").fadeOut(2000));
                    // }, 4000);
                  } else if (response.status === 3) {
                    this.setState({lock_date_error_message:response.message});
                    jQuery("#lock_date").modal("show");
                  } else if (response.status === 4) {
                    window.jQuery("#asking_password_for_delete").modal("show");
                    this.setState({ deleteMsg: true });
                  } else {
                    alert(response.message);
                  }
                });
              }
            } else {
              this.setState({
                isFailed: true,
                errormessage: "Please fill out payment details",
              });
              setTimeout(() => {
                this.setState({ isFailed: false, errormessage: "" });
              }, 3000);
            }
          }
        } else {
          // alert('hiii')
          var invoice_id = this.state.currInvoiceId;
          // data['invoice_id']=invoice_id

          var payment_id = this.state.currpayment_id;
         
          let datas = {
            client_id: this.state.logged_client_id,
            customer_id: customer_id,
            item_total_home_currency: item_total_home_currency,
            tax_amount_home_currency: tax_amount_home_currency,
            grand_total_home_currency: grand_total_home_currency,
            item_total_foreign_currency: item_total_foreign_currency,
            tax_amount_foreign_currency: tax_amount_foreign_currency,
            grand_total_foreign_currency: grand_total_foreign_currency,
            currency: currency,
            descripation: descripation,
            exchange_rate: exchange_rate,
            invoice_date: this.custConverter(jQuery("#invoice_date").val()),
            company_name: company_name,
            type: type,
            invoice_number: invoice_number,
            // company_address: jQuery("#invoice_to").val(),
            company_address: draftToHtml(
              convertToRaw(this.state.invoiceToEditorState.getCurrentContent())),
            list_id: list_id,
            tagged_user_id: this.state.logged_user_id,
            item_list: item_list,
            // job_name: job_name,
            source_attachment_ids: source_attachment_ids.join(','),
            job_name: this.state.job_name,
            memo: jQuery("#footer_memo").val(),
            shipping_address: company_address,
            thanking_message: thanking_message,
            terms_and_conditions: terms_and_conditions,
            // job_id: jQuery("#jobName option:selected").data("status"),
            //modified on 09-07-2022
            // job_id: this.state.JobId,
            job_id: this.state.job_id
              ? this.state.job_id
              : this.state.JobId
              ? this.state.JobId
              : "",
            payment_date:
              this.custConverter(jQuery("#payment_date").val()) ==
              "undefined-undefined-"
                ? ""
                : this.custConverter(jQuery("#payment_date").val()),
            payment_method: payment_method,
            amount_in_words: jQuery("#amount_in_words").val(),
            due_date: this.custConverter(jQuery("#due_date").val()),
            reference: jQuery("#reference").val(),
            payment_amount: payable_amount,
            account: jQuery("#account_id").val(),
            payment_exchange_rate: jQuery("#payment_exchange_rate").val(),
            balance_sheet_category: balanceCategId,
            tax_inclusive: this.state.isChecked ? "1" : 0,
            thrird_party_account_id: jQuery("#third_account_id").val(),
            third_party_type: this.state.thrird_party_type,
            third_party_account_name: jQuery(
              "#third_account_id option:selected"
            ).data("status"),
            invoice_id: this.state.invoice_id_used,
            payment_id: payment_id,
            descripation: this.state.payment_desc,
            template_id: this.state.template_id,
            due_date_term: this.state.selected_due_date,
            notification_id: this.state.notification_id,

            custom_header_fields: this.state.custom_header_fields,

            custom_footer_fields: this.state.custom_footer_fields,
            is_credit_limit_accepted: this.state.is_credit_limit_accepted,
            lock_date_password: this.state.old_password,
          };

          if (payment_id > 0) {
            // alert(payment_id)
            if (
              paymentdate != undefined &&
              paymentdate != "" &&
              payment_method != undefined &&
              payment_method != "" &&
              balanceCategId != undefined &&
              balanceCategId != ""
            ) {
              if (this.state.isThirdPartyName) {
                if (
                  jQuery("#third_account_id option:selected").data("status") !==
                    undefined &&
                  jQuery("#third_account_id option:selected").data("status") !==
                    ""
                ) {
                  FetchAllApi.edit_sales_invoice_payment(
                    datas,
                    (err, response) => {
                      // console.log("defaultcatejhwdjkjhgorylist", response);
                      if (response.status === 1) {
                        if (btn_type === "save") {
                          alert(response.message);
                          // localStorage.setItem('customer','')

                          // this.setState({ isClose: true });
                          // setTimeout(() => {
                          //   // if (jQuery("#closeme").fadeOut(2000));
                          //   this.setState({ isClose: false });

                          //   jQuery(".form-control").val("");
                          //   jQuery("#homecurrencytyt").html("0");
                          //   jQuery("#foreigncurrencytyt").html("0");
                          //   jQuery("#home_grand_total").html("0");
                          //   jQuery("#foreign_grand_total").html("0");
                          //   jQuery("#selectednow0").html("Choose");
                          //   jQuery("#home_tax_total").html("0");
                          // }, 4000);
                          // this.props.history.push("/loading", [
                          //   "/create_invoice",
                          // ]);
                        } else {
                          localStorage.setItem("customer", "");
                          // alert(
                          //   "Succesfully saved, Click ok to proceed next one"
                          // );

                          this.getNextInvoiceNumber();

                          // this.props.history.push("/loading", [
                          //   "/create_invoice",
                          // ]);

                          this.setState({ isClose: true });
                          setTimeout(() => {
                            // if (jQuery("#closeme").fadeOut(2000));
                            this.setState({ isClose: false });
                            window.location.reload();

                            jQuery(".form-control").val("");
                            jQuery("#homecurrencytyt").html("0");
                            jQuery("#foreigncurrencytyt").html("0");
                            jQuery("#home_grand_total").html("0");
                            jQuery("#foreign_grand_total").html("0");
                            jQuery("#selectednow0").html("Choose");
                            jQuery("#home_tax_total").html("0");
                          }, 4000);
                          setTimeout(() => {
                            if (jQuery("#closeme").fadeOut(2000));
                          }, 4000);
                          // alert(response.message)
                          this.setState({
                            isClose: true,
                            isCustomername: false,
                            isAdd: false,
                            isInvoice_to: false,
                            isShippingt_to: false,
                            isThanking_msg: false,
                            isTerms_cond_msg: false,
                            isInvoice_no: false,
                            isjob_name: false,
                            // isTablefilled: false,
                            isCustomername: false,
                            selected_customer_name: "",
                            tax_home_currency: "0",
                            tax_home_currency: "0",
                            rows: ["row 1"],
                          });
                          setTimeout(function () {
                            if (jQuery("#closeme").fadeOut(2000));
                          }, 8000);
                          jQuery(".form-control").val("");
                          jQuery("#homecurrencytyt").html("0");
                          jQuery("#foreigncurrencytyt").html("0");
                          jQuery("#home_grand_total").html("0");
                          jQuery("#foreign_grand_total").html("0");
                          jQuery("#selectednow0").html("Choose");
                          jQuery("#home_tax_total").html("0");

                          this.setState({});
                        }
                        localStorage.setItem("updated", "no");

                        localStorage.setItem("updated", "yes");
                        var iframe = document.createElement("iframe");
                        iframe.style.cssText = "opacity:0;position:absolute";
                        iframe.src = "about:blank";
                        // iframe.onload = function () {
                        //   iframe.contentWindow.close.call(window);
                        //   document.body.removeChild(iframe);
                        // };
                        document.body.appendChild(iframe);
                      } else if (response.status === 2) {
                        this.setState({ credit_limit_msg: response.message });
                        setTimeout(() => {
                          window.jQuery("#credit_limit").modal("show");
                        }, 500);

                        // var mymsg = response.message;
                        // if (mymsg.includes("Invoice number already exists")) {
                        //   this.setState({
                        //     ExistMsg: response.message,
                        //     isAlreadyTaken: true,
                        //   });
                        // }
                        // this.setState({
                        //   isRejected: true,
                        //   reject_msg: response.message,
                        // });
                        // setTimeout(function () {
                        //   if (jQuery("#failed_alrt").fadeOut(2000));
                        // }, 4000);
                      } else if (response.status === 3) {
                        this.setState({lock_date_error_message:response.message});
                        jQuery("#lock_date").modal("show");
                      } else if (response.status === 4) {
                        //  alert(response.message)
                        window
                          .jQuery("#asking_password_for_delete")
                          .modal("show");
                        this.setState({ deleteMsg: true });
                      } else {
                        alert(response.message);
                      }
                    }
                  );
                } else {
                  alert("please fill out payment details");
                }
              } else {
                FetchAllApi.edit_sales_invoice_payment(
                  datas,
                  (err, response) => {
                    // console.log("defaultcatejhwdjkjhgorylist", response);
                    if (response.status === 1) {
                      alert(response.message);
                      if (btn_type === "save") {
                        // localStorage.setItem('customer','')
                        // this.setState({ isClose: true });
                        // setTimeout(() => {
                        //   // if (jQuery("#closeme").fadeOut(2000));
                        //   this.setState({ isClose: false });
                        //   jQuery(".form-control").val("");
                        //   jQuery("#homecurrencytyt").html("0");
                        //   jQuery("#foreigncurrencytyt").html("0");
                        //   jQuery("#home_grand_total").html("0");
                        //   jQuery("#foreign_grand_total").html("0");
                        //   jQuery("#selectednow0").html("Choose");
                        //   jQuery("#home_tax_total").html("0");
                        // }, 4000);
                        // this.props.history.push("/loading", [
                        //   "/create_invoice",
                        // ]);
                      } else {
                        localStorage.setItem("customer", "");
                        // alert(
                        //   "Succesfully saved, Click ok to proceed next one"
                        // );
                        this.getNextInvoiceNumber();
                        // this.props.history.push("/loading", [
                        //   "/create_invoice",
                        // ]);

                        this.setState({ isClose: true });
                        setTimeout(() => {
                          // if (jQuery("#closeme").fadeOut(2000));
                          this.setState({ isClose: false });
                          window.location.reload();

                          jQuery(".form-control").val("");
                          jQuery("#homecurrencytyt").html("0");
                          jQuery("#foreigncurrencytyt").html("0");
                          jQuery("#home_grand_total").html("0");
                          jQuery("#foreign_grand_total").html("0");
                          jQuery("#selectednow0").html("Choose");
                          jQuery("#home_tax_total").html("0");
                        }, 4000);
                        setTimeout(() => {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 4000);
                        // alert(response.message)
                        this.setState({
                          isClose: true,
                          isCustomername: false,
                          isAdd: false,
                          isInvoice_to: false,
                          isShippingt_to: false,
                          isThanking_msg: false,
                          isTerms_cond_msg: false,
                          isInvoice_no: false,
                          isjob_name: false,
                          // isTablefilled: false,
                          isCustomername: false,
                          selected_customer_name: "",
                          tax_home_currency: "0",
                          tax_home_currency: "0",
                          rows: ["row 1"],
                        });
                        setTimeout(function () {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 8000);
                        jQuery(".form-control").val("");
                        jQuery("#homecurrencytyt").html("0");
                        jQuery("#foreigncurrencytyt").html("0");
                        jQuery("#home_grand_total").html("0");
                        jQuery("#foreign_grand_total").html("0");
                        jQuery("#selectednow0").html("Choose");
                        jQuery("#home_tax_total").html("0");

                        this.setState({});
                      }
                      localStorage.setItem("updated", "no");

                      localStorage.setItem("updated", "yes");
                      var iframe = document.createElement("iframe");
                      iframe.style.cssText = "opacity:0;position:absolute";
                      iframe.src = "about:blank";
                      // iframe.onload = function () {
                      //   iframe.contentWindow.close.call(window);
                      //   document.body.removeChild(iframe);
                      // };
                      document.body.appendChild(iframe);
                    } else if (response.status === 2) {
                      this.setState({ credit_limit_msg: response.message });
                      setTimeout(() => {
                        window.jQuery("#credit_limit").modal("show");
                      }, 500);

                      // var mymsg = response.message;
                      // if (mymsg.includes("Invoice number already exists")) {
                      //   this.setState({
                      //     ExistMsg: response.message,
                      //     isAlreadyTaken: true,
                      //   });
                      // }
                      // this.setState({
                      //   isRejected: true,
                      //   reject_msg: response.message,
                      // });
                      // setTimeout(function () {
                      //   if (jQuery("#failed_alrt").fadeOut(2000));
                      // }, 4000);
                    } else if (response.status === 3) {
                      this.setState({lock_date_error_message:response.message});
                      jQuery("#lock_date").modal("show");
                    } else if (response.status === 4) {
                      //  alert(response.message)
                      window
                        .jQuery("#asking_password_for_delete")
                        .modal("show");
                      this.setState({ deleteMsg: true });
                    } else {
                      alert(response.message);
                    }
                  }
                );
              }
            } else {
              alert("Please fill out payment details");
            }
          } else {
            //   if(  payable_amount===undefined ||payable_amount===null||payable_amount==''){
            //     FetchAllApi.update_sales_invoice(datas, (err, response) => {
            //       console.log('defaultcatejhwdjkjhgorylist', response)
            //       if (response.status === 1) {
            //         alert('sales invoice updated succesfully')

            //         if (btn_type === 'save') {
            //           // localStorage.setItem('customer','')

            //           this.setState({ isClose: true })
            //           setTimeout(() => {
            //             // if (jQuery("#closeme").fadeOut(2000));
            //             this.setState({ isClose: false })

            //             jQuery('.form-control').val('')
            //             jQuery('#homecurrencytyt').html('0')
            //             jQuery('#foreigncurrencytyt').html('0')
            //             jQuery('#home_grand_total').html('0')
            //             jQuery('#foreign_grand_total').html('0')
            //             jQuery('#selectednow0').html('Choose')
            //             jQuery('#home_tax_total').html('0')
            //           }, 4000)
            //           this.props.history.push('/loading', ['/create_invoice'])
            //         } else {
            //           localStorage.setItem('customer', '')
            //           alert('Succesfully saved, Click ok to proceed next one')
            //           this.props.history.push('/loading', ['/create_invoice'])

            //           this.setState({ isClose: true })
            //           setTimeout(() => {
            //             // if (jQuery("#closeme").fadeOut(2000));
            //             this.setState({ isClose: false })

            //             jQuery('.form-control').val('')
            //             jQuery('#homecurrencytyt').html('0')
            //             jQuery('#foreigncurrencytyt').html('0')
            //             jQuery('#home_grand_total').html('0')
            //             jQuery('#foreign_grand_total').html('0')
            //             jQuery('#selectednow0').html('Choose')
            //             jQuery('#home_tax_total').html('0')
            //           }, 4000)
            //           setTimeout(() => {
            //             if (jQuery('#closeme').fadeOut(2000));
            //           }, 4000)
            //           // alert(response.message)
            //           this.setState({
            //             isClose: true,
            //             isCustomername: false,
            //             isAdd: false,
            //             isInvoice_to: false,
            //             isShippingt_to: false,
            //             isThanking_msg: false,
            //             isTerms_cond_msg: false,
            //             isInvoice_no: false,
            //             isjob_name: false,
            //             // isTablefilled: false,
            //             isCustomername: false,
            //             selected_customer_name: '',
            //             tax_home_currency: '0',
            //             tax_home_currency: '0',
            //             rows: ['row 1']
            //           })
            //           setTimeout(function () {
            //             if (jQuery('#closeme').fadeOut(2000));
            //           }, 8000)
            //           jQuery('.form-control').val('')
            //           jQuery('#homecurrencytyt').html('0')
            //           jQuery('#foreigncurrencytyt').html('0')
            //           jQuery('#home_grand_total').html('0')
            //           jQuery('#foreign_grand_total').html('0')
            //           jQuery('#selectednow0').html('Choose')
            //           jQuery('#home_tax_total').html('0')

            //           this.setState({})
            //         }
            //         localStorage.setItem('updated','no')

            //         localStorage.setItem('updated','yes')
            //         var iframe = document.createElement('iframe');
            //         iframe.style.cssText = 'opacity:0;position:absolute';
            //         iframe.src = 'about:blank';
            //         iframe.onload = function() {
            //         iframe.contentWindow.close.call(window);
            //        document.body.removeChild(iframe);
            //     };
            //     document.body.appendChild(iframe);
            //       } else {
            //         alert(response.message)

            //         var mymsg=response.message;
            //         if(mymsg.includes('Invoice number already exists')){
            //           this.setState({ExistMsg:response.message,isAlreadyTaken:true});
            //         }
            //         this.setState({ isRejected: true, reject_msg: response.message })
            //         setTimeout(function () {
            //           if (jQuery('#failed_alrt').fadeOut(2000));
            //         }, 4000)
            //       }
            //     })
            //   } else{
            //     if( payable_amount !=undefined && paymentdate!=undefined &&  paymentdate!=''&&payment_method!=undefined && payment_method!=''&&balanceCategId!=undefined&& balanceCategId!=''
            // ){
            //   if(this.state.isThirdPartyName ){
            //   if(jQuery('#third_account_id option:selected').data('status') !==undefined && jQuery('#third_account_id option:selected').data('status') !==''){
            //   FetchAllApi.update_sales_invoice(datas, (err, response) => {
            //   console.log('defaultcatejhwdjkjhgorylist', response)
            //   if (response.status === 1) {
            //     alert('Payment updated succesfully')

            //     if (btn_type === 'save') {
            //       // localStorage.setItem('customer','')

            //       this.setState({ isClose: true })
            //       setTimeout(() => {
            //         // if (jQuery("#closeme").fadeOut(2000));
            //         this.setState({ isClose: false })

            //         jQuery('.form-control').val('')
            //         jQuery('#homecurrencytyt').html('0')
            //         jQuery('#foreigncurrencytyt').html('0')
            //         jQuery('#home_grand_total').html('0')
            //         jQuery('#foreign_grand_total').html('0')
            //         jQuery('#selectednow0').html('Choose')
            //         jQuery('#home_tax_total').html('0')
            //       }, 4000)
            //       this.props.history.push('/loading', ['/create_invoice'])
            //     } else {
            //       localStorage.setItem('customer', '')
            //       alert('Succesfully saved, Click ok to proceed next one')
            //       this.props.history.push('/loading', ['/create_invoice'])

            //       this.setState({ isClose: true })
            //       setTimeout(() => {
            //         // if (jQuery("#closeme").fadeOut(2000));
            //         this.setState({ isClose: false })

            //         jQuery('.form-control').val('')
            //         jQuery('#homecurrencytyt').html('0')
            //         jQuery('#foreigncurrencytyt').html('0')
            //         jQuery('#home_grand_total').html('0')
            //         jQuery('#foreign_grand_total').html('0')
            //         jQuery('#selectednow0').html('Choose')
            //         jQuery('#home_tax_total').html('0')
            //       }, 4000)
            //       setTimeout(() => {
            //         if (jQuery('#closeme').fadeOut(2000));
            //       }, 4000)
            //       // alert(response.message)
            //       this.setState({
            //         isClose: true,
            //         isCustomername: false,
            //         isAdd: false,
            //         isInvoice_to: false,
            //         isShippingt_to: false,
            //         isThanking_msg: false,
            //         isTerms_cond_msg: false,
            //         isInvoice_no: false,
            //         isjob_name: false,
            //         // isTablefilled: false,
            //         isCustomername: false,
            //         selected_customer_name: '',
            //         tax_home_currency: '0',
            //         tax_home_currency: '0',
            //         rows: ['row 1']
            //       })
            //       setTimeout(function () {
            //         if (jQuery('#closeme').fadeOut(2000));
            //       }, 8000)
            //       jQuery('.form-control').val('')
            //       jQuery('#homecurrencytyt').html('0')
            //       jQuery('#foreigncurrencytyt').html('0')
            //       jQuery('#home_grand_total').html('0')
            //       jQuery('#foreign_grand_total').html('0')
            //       jQuery('#selectednow0').html('Choose')
            //       jQuery('#home_tax_total').html('0')

            //       this.setState({})
            //     }

            //     localStorage.setItem('updated','yes')
            //     var iframe = document.createElement('iframe');
            //     iframe.style.cssText = 'opacity:0;position:absolute';
            //     iframe.src = 'about:blank';
            //     iframe.onload = function() {
            //     iframe.contentWindow.close.call(window);
            //    document.body.removeChild(iframe);
            // };
            // document.body.appendChild(iframe);
            //   } else {
            //     alert(response.message)

            //     var mymsg=response.message;
            //     if(mymsg.includes('Invoice number already exists')){
            //       this.setState({ExistMsg:response.message,isAlreadyTaken:true});
            //     }
            //     this.setState({ isRejected: true, reject_msg: response.message })
            //     setTimeout(function () {
            //       if (jQuery('#failed_alrt').fadeOut(2000));
            //     }, 4000)
            //   }
            // })}else{
            //   FetchAllApi.update_sales_invoice(datas, (err, response) => {
            //     console.log('defaultcatejhwdjkjhgorylist', response)
            //     if (response.status === 1) {
            //       alert('Payment updated succesfully')

            //       if (btn_type === 'save') {
            //         // localStorage.setItem('customer','')

            //         this.setState({ isClose: true })
            //         setTimeout(() => {
            //           // if (jQuery("#closeme").fadeOut(2000));
            //           this.setState({ isClose: false })

            //           jQuery('.form-control').val('')
            //           jQuery('#homecurrencytyt').html('0')
            //           jQuery('#foreigncurrencytyt').html('0')
            //           jQuery('#home_grand_total').html('0')
            //           jQuery('#foreign_grand_total').html('0')
            //           jQuery('#selectednow0').html('Choose')
            //           jQuery('#home_tax_total').html('0')
            //         }, 4000)
            //         this.props.history.push('/loading', ['/create_invoice'])
            //       } else {
            //         localStorage.setItem('customer', '')
            //         alert('Succesfully saved, Click ok to proceed next one')
            //         this.props.history.push('/loading', ['/create_invoice'])

            //         this.setState({ isClose: true })
            //         setTimeout(() => {
            //           // if (jQuery("#closeme").fadeOut(2000));
            //           this.setState({ isClose: false })

            //           jQuery('.form-control').val('')
            //           jQuery('#homecurrencytyt').html('0')
            //           jQuery('#foreigncurrencytyt').html('0')
            //           jQuery('#home_grand_total').html('0')
            //           jQuery('#foreign_grand_total').html('0')
            //           jQuery('#selectednow0').html('Choose')
            //           jQuery('#home_tax_total').html('0')
            //         }, 4000)
            //         setTimeout(() => {
            //           if (jQuery('#closeme').fadeOut(2000));
            //         }, 4000)
            //         // alert(response.message)
            //         this.setState({
            //           isClose: true,
            //           isCustomername: false,
            //           isAdd: false,
            //           isInvoice_to: false,
            //           isShippingt_to: false,
            //           isThanking_msg: false,
            //           isTerms_cond_msg: false,
            //           isInvoice_no: false,
            //           isjob_name: false,
            //           // isTablefilled: false,
            //           isCustomername: false,
            //           selected_customer_name: '',
            //           tax_home_currency: '0',
            //           tax_home_currency: '0',
            //           rows: ['row 1']
            //         })
            //         setTimeout(function () {
            //           if (jQuery('#closeme').fadeOut(2000));
            //         }, 8000)
            //         jQuery('.form-control').val('')
            //         jQuery('#homecurrencytyt').html('0')
            //         jQuery('#foreigncurrencytyt').html('0')
            //         jQuery('#home_grand_total').html('0')
            //         jQuery('#foreign_grand_total').html('0')
            //         jQuery('#selectednow0').html('Choose')
            //         jQuery('#home_tax_total').html('0')

            //         this.setState({})
            //       }
            //       localStorage.setItem('updated','no')

            //       localStorage.setItem('updated','yes')
            //       var iframe = document.createElement('iframe');
            //       iframe.style.cssText = 'opacity:0;position:absolute';
            //       iframe.src = 'about:blank';
            //       iframe.onload = function() {
            //       iframe.contentWindow.close.call(window);
            //      document.body.removeChild(iframe);
            //   };
            //   document.body.appendChild(iframe);
            //     } else {
            //       alert(response.message)

            //       var mymsg=response.message;
            //       if(mymsg.includes('Invoice number already exists')){
            //         this.setState({ExistMsg:response.message,isAlreadyTaken:true});
            //       }
            //       this.setState({ isRejected: true, reject_msg: response.message })
            //       setTimeout(function () {
            //         if (jQuery('#failed_alrt').fadeOut(2000));
            //       }, 4000)
            //     }
            //   })

            // }

            // }else{
            //   FetchAllApi.update_sales_invoice(datas, (err, response) => {
            //     console.log('defaultcatejhwdjkjhgorylist', response)
            //     if (response.status === 1) {
            //       alert('Payment updated succesfully')

            //       if (btn_type === 'save') {
            //         // localStorage.setItem('customer','')

            //         this.setState({ isClose: true })
            //         setTimeout(() => {
            //           // if (jQuery("#closeme").fadeOut(2000));
            //           this.setState({ isClose: false })

            //           jQuery('.form-control').val('')
            //           jQuery('#homecurrencytyt').html('0')
            //           jQuery('#foreigncurrencytyt').html('0')
            //           jQuery('#home_grand_total').html('0')
            //           jQuery('#foreign_grand_total').html('0')
            //           jQuery('#selectednow0').html('Choose')
            //           jQuery('#home_tax_total').html('0')
            //         }, 4000)
            //         this.props.history.push('/loading', ['/create_invoice'])
            //       } else {
            //         localStorage.setItem('customer', '')
            //         alert('Succesfully saved, Click ok to proceed next one')
            //         this.props.history.push('/loading', ['/create_invoice'])

            //         this.setState({ isClose: true })
            //         setTimeout(() => {
            //           // if (jQuery("#closeme").fadeOut(2000));
            //           this.setState({ isClose: false })

            //           jQuery('.form-control').val('')
            //           jQuery('#homecurrencytyt').html('0')
            //           jQuery('#foreigncurrencytyt').html('0')
            //           jQuery('#home_grand_total').html('0')
            //           jQuery('#foreign_grand_total').html('0')
            //           jQuery('#selectednow0').html('Choose')
            //           jQuery('#home_tax_total').html('0')
            //         }, 4000)
            //         setTimeout(() => {
            //           if (jQuery('#closeme').fadeOut(2000));
            //         }, 4000)
            //         // alert(response.message)
            //         this.setState({
            //           isClose: true,
            //           isCustomername: false,
            //           isAdd: false,
            //           isInvoice_to: false,
            //           isShippingt_to: false,
            //           isThanking_msg: false,
            //           isTerms_cond_msg: false,
            //           isInvoice_no: false,
            //           isjob_name: false,
            //           // isTablefilled: false,
            //           isCustomername: false,
            //           selected_customer_name: '',
            //           tax_home_currency: '0',
            //           tax_home_currency: '0',
            //           rows: ['row 1']
            //         })
            //         setTimeout(function () {
            //           if (jQuery('#closeme').fadeOut(2000));
            //         }, 8000)
            //         jQuery('.form-control').val('')
            //         jQuery('#homecurrencytyt').html('0')
            //         jQuery('#foreigncurrencytyt').html('0')
            //         jQuery('#home_grand_total').html('0')
            //         jQuery('#foreign_grand_total').html('0')
            //         jQuery('#selectednow0').html('Choose')
            //         jQuery('#home_tax_total').html('0')

            //         this.setState({})
            //       }
            //       localStorage.setItem('updated','no')

            //       localStorage.setItem('updated','yes')
            //       var iframe = document.createElement('iframe');
            //       iframe.style.cssText = 'opacity:0;position:absolute';
            //       iframe.src = 'about:blank';
            //       iframe.onload = function() {
            //       iframe.contentWindow.close.call(window);
            //      document.body.removeChild(iframe);
            //   };
            //   document.body.appendChild(iframe);
            //     } else {
            //       alert(response.message)

            //       var mymsg=response.message;
            //       if(mymsg.includes('Invoice number already exists')){
            //         this.setState({ExistMsg:response.message,isAlreadyTaken:true});
            //       }
            //       this.setState({ isRejected: true, reject_msg: response.message })
            //       setTimeout(function () {
            //         if (jQuery('#failed_alrt').fadeOut(2000));
            //       }, 4000)
            //     }
            //   })

            //   // alert('Please fill out payment details')
            // }
            // }else{
            //   this.setState({is3partyAccRequired:true});
            //   alert('Please fill out payment details')
            // }
            //   }

            // alert('I am regarding update invoice')

            let payable_amount = jQuery("#payable_amount").val();
            if (payable_amount == "" || payable_amount == undefined) {
              // console.log(object)
              // alert(payable_amount)
              // alert(typeof payable_amount)
              FetchAllApi.update_sales_invoice(datas, (err, response) => {
                this.update_template_properties();
                // console.log("defaultcatejhwdjkjhgorylist", response);
                if (response.status === 1) {
                  if (
                    response.group_accounting_list_id &&
                    response.group_accounting_list_id != 0
                  ) {
                    alert(
                      "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                    );
                    // this.setState({ manualLoading: true })
                    this.groupAcc(
                      response.invoice_id,
                      response.group_accounting_list_id,
                      true
                    );
                  }

                  if (btn_type === "save") {
                    alert("sales invoice updated succesfully");
                    // localStorage.setItem('customer','')

                    // this.setState({ isClose: true });
                    // setTimeout(() => {
                    //   // if (jQuery("#closeme").fadeOut(2000));
                    //   this.setState({ isClose: false });

                    //   jQuery(".form-control").val("");
                    //   jQuery("#homecurrencytyt").html("0");
                    //   jQuery("#foreigncurrencytyt").html("0");
                    //   jQuery("#home_grand_total").html("0");
                    //   jQuery("#foreign_grand_total").html("0");
                    //   jQuery("#selectednow0").html("Choose");
                    //   jQuery("#home_tax_total").html("0");
                    // }, 4000);
                    // this.props.history.push("/loading", ["/create_invoice"]);
                  } else {
                    localStorage.setItem("customer", "");
                    // alert("Succesfully saved, Click ok to proceed next one");
                    this.getNextInvoiceNumber();
                    // this.props.history.push("/loading", ["/create_invoice"]);

                    this.setState({ isClose: true });
                    setTimeout(() => {
                      // if (jQuery("#closeme").fadeOut(2000));
                      this.setState({ isClose: false });
                      window.location.reload();

                      jQuery(".form-control").val("");
                      jQuery("#homecurrencytyt").html("0");
                      jQuery("#foreigncurrencytyt").html("0");
                      jQuery("#home_grand_total").html("0");
                      jQuery("#foreign_grand_total").html("0");
                      jQuery("#selectednow0").html("Choose");
                      jQuery("#home_tax_total").html("0");
                    }, 4000);
                    setTimeout(() => {
                      if (jQuery("#closeme").fadeOut(2000));
                    }, 4000);
                    // alert(response.message)
                    this.setState({
                      isClose: true,
                      isCustomername: false,
                      isAdd: false,
                      isInvoice_to: false,
                      isShippingt_to: false,
                      isThanking_msg: false,
                      isTerms_cond_msg: false,
                      isInvoice_no: false,
                      isjob_name: false,
                      // isTablefilled: false,
                      isCustomername: false,
                      selected_customer_name: "",
                      tax_home_currency: "0",
                      tax_home_currency: "0",
                      rows: ["row 1"],
                    });
                    setTimeout(function () {
                      if (jQuery("#closeme").fadeOut(2000));
                    }, 8000);
                    jQuery(".form-control").val("");
                    jQuery("#homecurrencytyt").html("0");
                    jQuery("#foreigncurrencytyt").html("0");
                    jQuery("#home_grand_total").html("0");
                    jQuery("#foreign_grand_total").html("0");
                    jQuery("#selectednow0").html("Choose");
                    jQuery("#home_tax_total").html("0");

                    this.setState({});
                  }
                  localStorage.setItem("updated", "no");

                  localStorage.setItem("updated", "yes");
                  var iframe = document.createElement("iframe");
                  iframe.style.cssText = "opacity:0;position:absolute";
                  iframe.src = "about:blank";
                  // iframe.onload = function () {
                  //   iframe.contentWindow.close.call(window);
                  //   document.body.removeChild(iframe);
                  // };
                  document.body.appendChild(iframe);
                } else if (response.status === 2) {
                  this.setState({ credit_limit_msg: response.message });
                  setTimeout(() => {
                    window.jQuery("#credit_limit").modal("show");
                  }, 500);

                  // var mymsg = response.message;
                  // if (mymsg.includes("Invoice number already exists")) {
                  //   this.setState({
                  //     ExistMsg: response.message,
                  //     isAlreadyTaken: true,
                  //   });
                  // }
                  // this.setState({
                  //   isRejected: true,
                  //   reject_msg: response.message,
                  // });
                  // setTimeout(function () {
                  //   if (jQuery("#failed_alrt").fadeOut(2000));
                  // }, 4000);
                } else if (response.status === 3) {
                  this.setState({lock_date_error_message:response.message});
                  jQuery("#lock_date").modal("show");
                } else if (response.status === 4) {
                  //  alert(response.message)
                  window.jQuery("#asking_password_for_delete").modal("show");
                  this.setState({ deleteMsg: true });
                } else {
                  alert(response.message);
                }
              });
            } else {
              // alert(payable_amount)
              // alert(typeof payable_amount)
              // if(balanceCategId !==undefined)
              // alert('i am on the wa')
              if (
                paymentdate != undefined &&
                paymentdate != "" &&
                payment_method != undefined &&
                payment_method != "" &&
                balanceCategId != undefined &&
                balanceCategId != ""
              ) {
                if (this.state.isThirdPartyName) {
                  if (
                    jQuery("#third_account_id option:selected").data(
                      "status"
                    ) !== undefined &&
                    jQuery("#third_account_id option:selected").data(
                      "status"
                    ) !== ""
                  ) {
                    FetchAllApi.update_sales_invoice(datas, (err, response) => {
                      this.update_template_properties();
                      // console.log("defaultcatejhwdjkjhgorylist", response);
                      if (response.status === 1) {
                        alert("sales invoice updated succesfully");

                        if (
                          response.group_accounting_list_id &&
                          response.group_accounting_list_id != 0
                        ) {
                          alert(
                            "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                          );
                          // this.setState({ manualLoading: true })
                          this.groupAcc(
                            response.invoice_id,
                            response.group_accounting_list_id,
                            true
                          );
                        }

                        if (btn_type === "save") {
                          alert("sales invoice updated succesfully");

                          if (response.payment_id && response.payment_id != 0) {
                            this.setState(
                              {
                                currpayment_id: response.payment_id,
                                hasId:response.invoice_id
                              },
                              () => {
                                this.getInvoicePaymentDetails();
                              }
                            );
                          }

                          // localStorage.setItem('customer','')

                          // this.setState({ isClose: true });
                          // setTimeout(() => {
                          //   // if (jQuery("#closeme").fadeOut(2000));
                          //   this.setState({ isClose: false });

                          //   jQuery(".form-control").val("");
                          //   jQuery("#homecurrencytyt").html("0");
                          //   jQuery("#foreigncurrencytyt").html("0");
                          //   jQuery("#home_grand_total").html("0");
                          //   jQuery("#foreign_grand_total").html("0");
                          //   jQuery("#selectednow0").html("Choose");
                          //   jQuery("#home_tax_total").html("0");
                          // }, 4000);
                          // this.props.history.push("/loading", [
                          //   "/create_invoice",
                          // ]);
                        } else {
                          localStorage.setItem("customer", "");
                          // alert(
                          //   "Succesfully saved, Click ok to proceed next one"
                          // );
                          this.getNextInvoiceNumber();
                          // this.props.history.push("/loading", [
                          //   "/create_invoice",
                          // ]);

                          this.setState({ isClose: true });
                          setTimeout(() => {
                            // if (jQuery("#closeme").fadeOut(2000));
                            this.setState({ isClose: false });
                            window.location.reload();

                            jQuery(".form-control").val("");
                            jQuery("#homecurrencytyt").html("0");
                            jQuery("#foreigncurrencytyt").html("0");
                            jQuery("#home_grand_total").html("0");
                            jQuery("#foreign_grand_total").html("0");
                            jQuery("#selectednow0").html("Choose");
                            jQuery("#home_tax_total").html("0");
                          }, 4000);
                          setTimeout(() => {
                            if (jQuery("#closeme").fadeOut(2000));
                          }, 4000);
                          // alert(response.message)
                          this.setState({
                            isClose: true,
                            isCustomername: false,
                            isAdd: false,
                            isInvoice_to: false,
                            isShippingt_to: false,
                            isThanking_msg: false,
                            isTerms_cond_msg: false,
                            isInvoice_no: false,
                            isjob_name: false,
                            // isTablefilled: false,
                            isCustomername: false,
                            selected_customer_name: "",
                            tax_home_currency: "0",
                            tax_home_currency: "0",
                            rows: ["row 1"],
                          });
                          setTimeout(function () {
                            if (jQuery("#closeme").fadeOut(2000));
                          }, 8000);
                          jQuery(".form-control").val("");
                          jQuery("#homecurrencytyt").html("0");
                          jQuery("#foreigncurrencytyt").html("0");
                          jQuery("#home_grand_total").html("0");
                          jQuery("#foreign_grand_total").html("0");
                          jQuery("#selectednow0").html("Choose");
                          jQuery("#home_tax_total").html("0");

                          this.setState({});
                        }
                        localStorage.setItem("updated", "no");

                        localStorage.setItem("updated", "yes");
                        var iframe = document.createElement("iframe");
                        iframe.style.cssText = "opacity:0;position:absolute";
                        iframe.src = "about:blank";
                        // iframe.onload = function () {
                        //   iframe.contentWindow.close.call(window);
                        //   document.body.removeChild(iframe);
                        // };
                        document.body.appendChild(iframe);
                      } else if (response.status === 2) {
                        this.setState({ credit_limit_msg: response.message });
                        setTimeout(() => {
                          window.jQuery("#credit_limit").modal("show");
                        }, 500);

                        // var mymsg = response.message;
                        // if (mymsg.includes("Invoice number already exists")) {
                        //   this.setState({
                        //     ExistMsg: response.message,
                        //     isAlreadyTaken: true,
                        //   });
                        // }
                        // this.setState({
                        //   isRejected: true,
                        //   reject_msg: response.message,
                        // });
                        // setTimeout(function () {
                        //   if (jQuery("#failed_alrt").fadeOut(2000));
                        // }, 4000);
                      } else if (response.status === 3) {
                        this.setState({lock_date_error_message:response.message});
                        jQuery("#lock_date").modal("show");
                      } else if (response.status === 4) {
                        //  alert(response.message)
                        window
                          .jQuery("#asking_password_for_delete")
                          .modal("show");
                        this.setState({ deleteMsg: true });
                      } else {
                        alert(response.message);
                      }
                    });
                  } else {
                    this.setState({ is3partyAccRequired: true });

                    alert("please fill out payment details");
                  }
                } else {
                  FetchAllApi.update_sales_invoice(datas, (err, response) => {
                    this.update_template_properties();
                    // console.log("defaultcatejhwdjkjhgorylist", response);
                    if (response.status === 1) {
                      if (
                        response.group_accounting_list_id &&
                        response.group_accounting_list_id != 0
                      ) {
                        alert(
                          "Please wait, PDF is generating for Group accounting Customer - Sales Invoice"
                        );
                        // this.setState({ manualLoading: true })
                        this.groupAcc(
                          response.invoice_id,
                          response.group_accounting_list_id,
                          true
                        );
                      }

                      if (btn_type === "save") {
                        alert("sales invoice updated succesfully");

                        if (response.payment_id && response.payment_id != 0) {
                          this.setState(
                            {
                              currpayment_id: response.payment_id,
                              hasId:response.invoice_id
                            },
                            () => {
                              this.getInvoicePaymentDetails();
                            }
                          );
                        }

                        // localStorage.setItem('customer','')

                        // this.setState({ isClose: true });
                        // setTimeout(() => {
                        //   // if (jQuery("#closeme").fadeOut(2000));
                        //   this.setState({ isClose: false });

                        //   jQuery(".form-control").val("");
                        //   jQuery("#homecurrencytyt").html("0");
                        //   jQuery("#foreigncurrencytyt").html("0");
                        //   jQuery("#home_grand_total").html("0");
                        //   jQuery("#foreign_grand_total").html("0");
                        //   jQuery("#selectednow0").html("Choose");
                        //   jQuery("#home_tax_total").html("0");
                        // }, 4000);
                        // this.props.history.push("/loading", [
                        //   "/create_invoice",
                        // ]);
                      } else {
                        localStorage.setItem("customer", "");
                        // alert(
                        //   "Succesfully saved, Click ok to proceed next one"
                        // );
                        this.getNextInvoiceNumber();
                        // this.props.history.push("/loading", [
                        //   "/create_invoice",
                        // ]);

                        this.setState({ isClose: true });
                        setTimeout(() => {
                          // if (jQuery("#closeme").fadeOut(2000));
                          this.setState({ isClose: false });
                          window.location.reload();

                          jQuery(".form-control").val("");
                          jQuery("#homecurrencytyt").html("0");
                          jQuery("#foreigncurrencytyt").html("0");
                          jQuery("#home_grand_total").html("0");
                          jQuery("#foreign_grand_total").html("0");
                          jQuery("#selectednow0").html("Choose");
                          jQuery("#home_tax_total").html("0");
                        }, 4000);
                        setTimeout(() => {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 4000);
                        // alert(response.message)
                        this.setState({
                          isClose: true,
                          isCustomername: false,
                          isAdd: false,
                          isInvoice_to: false,
                          isShippingt_to: false,
                          isThanking_msg: false,
                          isTerms_cond_msg: false,
                          isInvoice_no: false,
                          isjob_name: false,
                          // isTablefilled: false,
                          isCustomername: false,
                          selected_customer_name: "",
                          tax_home_currency: "0",
                          tax_home_currency: "0",
                          rows: ["row 1"],
                        });
                        setTimeout(function () {
                          if (jQuery("#closeme").fadeOut(2000));
                        }, 8000);
                        jQuery(".form-control").val("");
                        jQuery("#homecurrencytyt").html("0");
                        jQuery("#foreigncurrencytyt").html("0");
                        jQuery("#home_grand_total").html("0");
                        jQuery("#foreign_grand_total").html("0");
                        jQuery("#selectednow0").html("Choose");
                        jQuery("#home_tax_total").html("0");

                        this.setState({});
                      }
                      localStorage.setItem("updated", "no");

                      localStorage.setItem("updated", "yes");
                      var iframe = document.createElement("iframe");
                      iframe.style.cssText = "opacity:0;position:absolute";
                      iframe.src = "about:blank";
                      // iframe.onload = function () {
                      //   iframe.contentWindow.close.call(window);
                      //   document.body.removeChild(iframe);
                      // };
                      document.body.appendChild(iframe);
                    } else if (response.status === 2) {
                      this.setState({ credit_limit_msg: response.message });
                      setTimeout(() => {
                        window.jQuery("#credit_limit").modal("show");
                      }, 500);

                      // var mymsg = response.message;
                      // if (mymsg.includes("Invoice number already exists")) {
                      //   this.setState({
                      //     ExistMsg: response.message,
                      //     isAlreadyTaken: true,
                      //   });
                      // }
                      // this.setState({
                      //   isRejected: true,
                      //   reject_msg: response.message,
                      // });
                      // setTimeout(function () {
                      //   if (jQuery("#failed_alrt").fadeOut(2000));
                      // }, 4000);
                    } else if (response.status === 3) {
                      this.setState({lock_date_error_message:response.message});
                      jQuery("#lock_date").modal("show");
                    } else if (response.status === 4) {
                      //  alert(response.message)
                      window
                        .jQuery("#asking_password_for_delete")
                        .modal("show");
                      this.setState({ deleteMsg: true });
                    } else {
                      alert(response.message);
                    }
                  });
                }
              } else {
                alert("Please fill out payment details !");
              }
            }
          }
        }
      }
    } else {
      this.setState({ isTablefilled: true });
    }
  };

  callAllLocale = () => {
    FetchAllApi.locale_list((err, response) => {
      // console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState({});
      } else {
      }
    });
  };
  formatCurrency = (curr) => {
    const filtered = data.filter((item) => item.currency == curr);
    if (filtered.length > 0) {
      var useme = filtered[0].locale;
    } else {
      let trimed = curr.trim();
      let sliced = trimed.slice(0, 2);
      var useme = "en" + sliced;
    }
    return (amnt) => {
      return new Intl.NumberFormat("en-US", {
        currency: "USD",
      }).format(amnt);
    };
  };
  typeOfColumnTobeModified = (changeEvent) => {
    this.setState({
      selectedColumnType: changeEvent.target.value,
    });
  };
  add_coulmn = (colType) => {
    var user_id = parseFloat(this.state.logged_user_id);
    let type = this.state.selectedColumnType;
    type = type ? type : colType;
    if (type === "textField") {
      var type_ = 1;
    } else {
      var type_ = 2;
    }
    var myVal = type_;
    let coulmn_name = jQuery("#coulmn_name").val();

    var coulmnData = this.state.number_of_columns_list;
    var obJ = {
      column_name: coulmn_name,
      type: myVal,
      options: [],
      is_visible: 1,
    };
    // alert(coulmnData)

    coulmnData.push(obJ);
    var coreData = {
      user_id: this.state.logged_user_id,
      columns: coulmnData,
      client_id: this.state.logged_client_id,
    };

    FetchAllApi.upDateCoulmns(coreData, (err, response) => {
      // console.log("new document", response.message);
      alert(response.message);
      if (response.status === 1) {
        this.getColumns();

        //   this.setState({ items: response.list[0].columns })
      } else {
      }
    });

    // FetchAllApi.add_columns_list(
    //   user_id,
    //   type_,
    //   coulmn_name,
    //   (err, response) => {
    //     console.log('Lhouiodis', response)
    //     if (response.status === 1) {
    //       this.getColList()
    //     } else {
    //       this.setState({
    //         number_of_columns_list: []
    //       })
    //     }
    //   }
    // )

    //       FetchAllApi.get_columns_list(user_id, (err, response) => {
    //   console.log('LIST RETURNED=>', response)
    //   if (response.status === 1) {
    //     this.setState({
    //       number_of_columns_list: response.list
    //     })
    //   } else {
    //     this.setState({
    //       number_of_columns_list: []
    //     })
    //   }
    // })

    // let type = this.state.selectedColumnType
    // type = type ? type : colType
    // var user_id = parseFloat(this.state.logged_user_id)
    // if (type === 'dropDownField') {
    //   // TODO: Make the API
    //   FetchAllApi.get_columns_list(user_id, (err, response) => {
    //     console.log('LIST RETURNED=>', response)
    //     if (response.status === 1) {
    //       this.setState({
    //         number_of_columns_list: response.list
    //       })
    //     } else {
    //       this.setState({
    //         number_of_columns_list: []
    //       })
    //     }
    //   })
    // }
    // let item_check = jQuery(
    //   `#coulmn${this.state.rows.length - 1}${this.state.coulmns.length - 1}`
    // ).val()
    //   var coulmns = this.state.coulmns
    //   coulmns.push('new row')
    //   this.setState({ coulmns: coulmns })

    window.jQuery("#pop-modal-2").modal("hide");
  };
  removeOverFlow = (e, col) => {
    if (col) {
      this.setState({
        columnId: col,
      });
    }
    jQuery(".form-table").addClass("ovrFlwRmve");
  };

  getPaymethod = () => {
    FetchAllApi.getPaymethod(this.state.logged_client_id, (err, response) => {
      // console.log("vendojkdjnksnames", response);

      if (response.status === 1) {
        this.setState({
          payment_method_list: response.lists,
        });
      } else {
      }
    });
  };

  defaultcategorylist_onchange = (x, y, z) => {
    let keyy = "";
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    FetchAllApi.defaultcategorylist_onchange2(
      keyy,
      from_create_invoice,
      client_id,
      filter_id,
      (err, response) => {
        // console.log("defaultcat9999egorylist", response);
        if (response.status === 1) {
          if (x == "added") {
            this.setState({
              selectNeedIndex: response.list.length - 1,
              nameFilter: y,
            });
          }
          this.setState(
            {
              default_category_list: response.list,
            },
            () => {
              window.jQuery("#categry_id0").selectpicker("refresh");
            }
          );
        } else {
          this.setState({
            default_category_list: [],
          });
        }
      }
    );
    this.onChange_filter_balancesheet();
    this.setState({ cate_id: z });
  };
  // get_currencies = () => {
  //   FetchAllApi.currencies((err, response) => {
  //     console.log('Customer list', response)
  //     if (response.status === 1) {
  //       this.setState({ currencies: response.lists })
  //     } else {
  //       this.setState({ currencies: [] })
  //     }
  //   })
  // }

  changeState = () => {
    this.getColumns();
    this.addSerialNumber();
    this.onChange_filter_balancesheet();
  };
  onChange_filter_balancesheet = () => {
    let search_key = "";
    var client_id = this.state.logged_client_id;

    //alert(search_key)
    let bc_filter_id = 2;
    // fixed asset categories no need in payment account
    // FetchAllApi.balancesheetlist_onchange(
      FetchAllApi.paymentAccountList(
      search_key,
      client_id,
      bc_filter_id,
      (err, response) => {
        // console.log("defaultcategorylist", response);
        if (response.status === 1) {
          // alert('k')
          this.setState({
            balancesheetlist: response.list,
          });
        } else {
          this.setState({
            balancesheetlist: [],
          });
        }
      }
    );
  };
  // changeMe=()=>{
  //   this.setState({
  //     isEditCol: !this.state.isEditCol
  //   })
  // }
  //  myFunction=() =>{
  //   var x = document.getElementsByTagName("tr");
  //   var i;
  //   for (i = 0; i < x.length; i++) {
  //      alert(x[0].rowIndex)
  //   }

  // }

  getSubAccountList = () => {
    var coreData = {
      account_type_id: 2,
      client_id: this.state.logged_client_id,
    };

    FetchAllApi.getSubAccountList(coreData, (err, response) => {
      // console.log("vendor_nljfskdkdssdkfames", response);

      if (response.status === 1) {
        this.setState(
          { SubAccountList: response.list, sub_account_list_loaded: true },
          () => {
            this.findInSubAccountList();
            this.checkForInitialDataLoad();
          }
        );
      } else {
      }
    });
  };

  findInSubAccountList = () => {
    var currency = this.state.CurrentSelectedCurr;
    var result = [];
    this.state.SubAccountList.forEach((item, i) => {
      var fullString = item.name.split("-");
      var list_curr = fullString[1];

      // console.log("matched", item.name + "=" + list_curr + "=" + currency);
      var kk = "Accounts Receivable" + "-" + currency;
      if (item.name == kk) {
        result.push(item);
      }
    });

    // newly added to check custom receivable accounts
    if(result.length == 0) {
      let custom_ar_account = _.filter(this.state.SubAccountList,{currency:currency});
      if(custom_ar_account && custom_ar_account.length > 0) {
        result.push(custom_ar_account[0]);
        jQuery("#account_id").val(result[0].id);
      }
    }
    // newly added to check custom receivable accounts - end

    if (result.length === 0) {
      var coreData = {
        account_name: "Accounts Receivable" + "-" + currency,
        account_type_id: 2,
        currency: currency,
        client_id: this.state.logged_client_id,
      };

      FetchAllApi.addNewAccountName(coreData, (err, response) => {
        console.log("vendor_nljfskdkdssdkfames", response);

        if (response.status === 1) {
          this.getSubAccountList();
          jQuery("#account_id").val(response.account_type_id);
        } else {
        }
      });
    } else {
      if (
        jQuery("#invoice_curr_id option:selected").val() != "" &&
        jQuery("#invoice_curr_id option:selected").val() != undefined
      ) {
        jQuery("#account_id").val(result[0].id);
      }
    }
  };

  fetchThirdPartyNames = (payment_account_id) => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.third_party_account_list(
      client_id,
      payment_account_id,
      (err, response) => {
        // console.log("vendor_names", response);
        if (response.status === 1) {
          // console.log("hiiiii==========iiiiiii", response);
          this.setState({ third_party_account_list: response.data });

          localStorage.setItem("third_party_customer_id", "");
        } else {
        }
      }
    );
  };

  getNextInvoiceNumber = () => {
    var coreData = {
      client_id: this.state.logged_client_id,
    };
    FetchAllApi.getNextInvoiceNumber(coreData, async (err, response) => {
      // console.log("vendor_names", response);

      if (response.status === 1) {
        this.setState(
          {
            invoice_number_for_print: response.invoice_number,
            invoice_date_for_print: moment(response.invoice_date).format(
              "DD/MM/YYYY"
            ),
            selected_due_date: response.due_date_term,
            next_invoice_number_loaded: true,
          },
          () => {
            this.checkForInitialDataLoad();
          }
        );
        jQuery("#invoice_no").val(response.invoice_number);
        jQuery("#invoice_date").val(
          moment(response.invoice_date).format("DD/MM/YYYY")
        );
        jQuery("#due_date").val(
          moment(response.invoice_default_due_date).format("DD/MM/YYYY")
        );

        jQuery("#payment_date").val(moment().format("DD/MM/YYYY"));
        // jQuery("#footeroptionthanksmessages").val(response.thanking_message);
        this.setThankingMessage(response.thanking_message);
        // jQuery("#termcondmsg").val(response.terms_conditions);
        this.setTermsAndConditions(response.terms_conditions);

        // alert(response.invoice_number)   payment_date
        // this.setState({ customerjoblist: response.results })
      } else {
        this.setState({ customerjoblist: [] });
      }
    });
  };

  render() {
    // let customColumn = this.customColumn()

    const { editorState } = this.state;
    const { thankingMessageEditorState } = this.state;
    const { invoiceToEditorState } = this.state;

    // console.log('hfggsdgsdhhhhh', this.state.invoice_id_used)
    // console.log('hfggsdgsdhhhhh111', this.state.credit_list)
    var data = "";
    data = this.state.currencies;
    const toWords = new ToWords();
    // const toWords = new ToWords();
    // console.log('sdghfmg,sd', this.state.customer_list)
    // console.log('kusm', this.state.billing_address)

    let THIS = this;
    if (!this.state.isEditCol) {
      return (
        <div>
          {this.state.is_void && (
            <div
              style={{
                position: "fixed",
                display: "none",
                width: " 100%",
                height: "100%",
                top: "0",
                left: " 0",
                right: "0",
                bottom: " 0",
                backgroundColor: "rgba(0,0,0,0.2)",
                zIndex: "99999",
                cursor: "pointer",
                display: "block",
              }}
            >
              <h1 className='watermark'>Voided</h1>
            </div>
          )}
          {this.state.is_deleted && (
            <div
              style={{
                position: "fixed",
                width: " 100%",
                height: "100%",
                top: "0",
                left: " 0",
                right: "0",
                bottom: " 0",
                backgroundColor: "rgba(0,0,0,0.2)",
                zIndex: "99999",
                cursor: "pointer",
                display: "block",
              }}
            >
              <h1 className='watermark'>Deleted</h1>
            </div>
          )}
          <div id='mynode'>
            <div className='container-fluid'>
              <div className='row'>
                <LeftSidebar
                  history={this.props.history}
                  pageSubmit={(e) => this.pageLink(e)}
                />

                <div className='main-wrap col-md-12 col-xs-12 pad-r-no'>
                  <div className='top-bar col-md-12 col-xs-12 pad-r-no'>
                    <div className='nav-brand-res visible-xs'>
                      <img
                        className='img-responsive'
                        src='../images/logo-icon.png'
                        alt='LogoIcon'
                      />
                    </div>
                    <a
                      href='javascript:;'
                      onClick={() => this.props.history.push("/all_lists")}
                      className='back hidden-xs'
                      // class="back hidden-xs"
                    >
                      <img src='../../images/back-arrow-blue.svg' />
                    </a>

                    <ul className='list-unstyled breadcrumb page-title hidden-xs'>
                      <li>
                        <a href='javascript: ;'>Create Invoice</a>
                      </li>
                      <li>Accounts Receivable</li>
                    </ul>
                    <Topbar
                      history={this.props.history}
                      logoutSubmit={(e) => this.logoutLink()}
                    />
                  </div>
                  <div>
                    <div className='content-top col-md-12 col-xs-12'>
                      <form
                        className='custom-form form-inline row'
                        id='form_clear_input'
                      >
                        <div className='form-group col-md-3 col-sm-4'>
                          <label>
                            Invoice Currency<span className='astrick'>*</span>
                          </label>
                          <div>
                            <select
                              className='selectpicker form-control hh w-100 '
                              id='invoice_curr_id'
                              data-live-search='true'
                              title={`Choose`}
                              value={this.state.CurrentSelectedCurr}
                              onChange={async (e) => {
                                if (
                                  jQuery("#paid_status").val() ==
                                  "Partially paid--invoice"
                                ) {
                                  alert(
                                    "you cannot change currency to partially paid invoices"
                                  );
                                } else {
                                  var currency = e.target.value;

                                  // To update payment exchange rate
                                  let nope;
                                  var date_formated;
                                  let date = moment().format("DD/MM/YYYY");
                                  if (
                                    date != undefined &&
                                    date != null &&
                                    date != ""
                                  ) {
                                    var array = date.split("/");
                                    date_formated =
                                      array[2] +
                                      "-" +
                                      array[1] +
                                      "-" +
                                      array[0];
                                    nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                  } else {
                                    date_formated =
                                      moment().format("YYYY-MM-DD");
                                    nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                  }

                                  let res = nope.concat(currency);
                                  await fetch(res)
                                    .then(
                                      async (response) => await response.json()
                                    )
                                    .then(async (data) => {
                                      if (data.success == true) {
                                        let newObj = this.rename(
                                          data.quotes,
                                          currency
                                        );
                                        let currencyAr = [];
                                        let first = newObj;
                                        currencyAr.push({
                                          date: data.start_date,
                                          rate:
                                            first[data.start_date] &&
                                            first[data.start_date][
                                              currency +
                                                this.state.clientHomeCurrency
                                            ]
                                              ? first[data.start_date][
                                                  currency +
                                                    this.state
                                                      .clientHomeCurrency
                                                ].toFixed(4)
                                              : parseFloat(1).toFixed(4),
                                        });
                                        let value = currencyAr[0].rate;
                                        jQuery("#payment_exchange_rate").val(
                                          value
                                        );
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                  // To update payment exchange rate

                                  // jQuery("#Exchange").val("");
                                  this.setState(
                                    {
                                      CurrentSelectedCurr: currency,
                                      duplicateVar: currency,
                                    },
                                    () => {
                                      // this.handleChangeItems(
                                      //   0,
                                      //   this.state.rows.length - 1
                                      // );
                                      // setTimeout(() => {
                                      //   this.setState(
                                      //     {
                                      //       cus_rate_rate:
                                      //         this.state.exchange_value_ref,
                                      //     },
                                      //     () => {
                                      //       this.handleChangeItems(
                                      //         0,
                                      //         this.state.rows.length - 1
                                      //       );
                                      //     }
                                      //   );
                                      // }, 2000);
                                      this.loadExchangeRate(false);
                                      //20240703
                                      this.findInSubAccountList();
                                    }
                                  );
                                }
                              }}
                            >
                            
                              {this.state.currencies &&
                                this.state.currencies.map((item) => {
                                  if (item.code !== "ALL") {
                                    return (
                                      <option value={item}> {item}</option>
                                    );
                                  }
                                })}
                            </select>
                          </div>
                          {this.state.isInvoiceCurrency ? (
                            <div>
                              <small style={{ color: "red" }}>*Required.</small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className='form-group col-md-3 col-sm-4 '>
                          <label>
                            Templates<span className='astrick'>*</span>
                          </label>
                          <div>
                            <select
                              className='selectpicker form-control '
                              id='template_sel'
                              data-live-search='true'
                              title={`Choose`}
                              data-width='100%'
                              value={this.state.template_id}
                              onChange={(e) => {
                                let value = e.target.value;
                                this.setState({ template_id: value });
                                this.temChanged(value);

                                // var index = e.target.value;
                                // this.apply_template_format(
                                //   index,
                                //   this.state.response.list[
                                //     index
                                //   ].template_name.slice(0, 20),
                                //   this.state.response.list[index].html_content
                                // );
                              }}
                            >
                              {this.state.response.list != undefined &&
                                this.state.response.list.map((item, index) => {
                                  // console.log("ggggg", this.state.response);
                                  if (
                                    1
                                    // item.id == 2
                                  ) {
                                    return (
                                      // <li
                                      //   key={index}
                                      //   id={index}
                                      //   onClick={() =>
                                      //     this.apply_template_format(
                                      //       index,
                                      //       this.state.response.list[
                                      //         index
                                      //       ].template_name.slice(0, 20),
                                      //       this.state.response.list[index]
                                      //         .html_content
                                      //     )
                                      //   }
                                      // >
                                      //   <a href='javascript:;'>
                                      //     {
                                      //       this.state.response.list[index]
                                      //         .template_name
                                      //     }
                                      //   </a>
                                      // </li>

                                      <option value={item.id}>
                                        {item.template_name}
                                      </option>
                                    );
                                  }
                                })}
                            </select>
                          </div>
                          {this.state.isTemplate_selected ? (
                            <div>
                              <small style={{ color: "red" }}>*Required.</small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div className='form-group col-md-3 col-sm-4 no-edit'> */}
                        <div className='form-group col-md-3 col-sm-4'>
                          <label>Accounts{"  "}</label>
                          <div>
                            <select
                              className='selectpicker form-control '
                              id='account_id'
                              data-live-search='true'
                              title={`Choose`}
                              data-width='100%'
                              onChange={(e) => {
                                let value = e.target.value;
                                jQuery("#account_id").val(value);
                              }}
                              // disabled={true}
                            >
                              {this.state.SubAccountList != undefined &&
                                this.state.SubAccountList.map((item, index) => {
                                  // console.log("ggggg", this.state.response);
                                  if((item.currency != '' && item.currency == this.state.CurrentSelectedCurr) && item.name != 'Accounts Receivable') {
                                  return (
                                    // <li
                                    //   key={index}
                                    //   id={index}
                                    //   onClick={() =>
                                    //     this.apply_template_format(
                                    //       index,
                                    //       this.state.response.list[
                                    //         index
                                    //       ].template_name.slice(0, 20),
                                    //       this.state.response.list[index]
                                    //         .html_content
                                    //     )
                                    //   }
                                    // >
                                    //   <a href='javascript:;'>
                                    //     {
                                    //       this.state.response.list[index]
                                    //         .template_name
                                    //     }
                                    //   </a>
                                    // </li>

                                    <option value={item.id}>{item.name}</option>
                                    
                                  );
                                } else {
                                  return '';
                                }
                                })}
                            </select>
                          </div>
                          {/* {this.state.isAccounId ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>*Required.</small>
                          </div>
                        ) : (
                          ''
                        )} */}
                        </div>

                        <div className='form-group col-md-3 col-sm-4 no-edit'>
                          <label>{"Paid Status"}</label>

                          <input
                            type='text'
                            name='paid_status'
                            className='form-control w-100'
                            id='paid_status'
                            autoComplete='off'
                            placeholder=' '
                            disabled
                            defaultValue={"--"}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  {this.state.manualLoading ? (
                    <div class='loading_spinner'>Loading&#8230;</div>
                  ) : (
                    ""
                  )}

                  {/* {this.state.loading ? <div class="loading_spinner">Loading&#8230;</div> : ''} */}
                  {/* <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={this.state.loading}
                /> */}
                  <div className='main-content col-md-12 col-xs-12'>
                    <div className='content-sec col-md-12 col-xs-12 pad-no mar-t-no'>
                      <form className='custom-form invoice-form'>
                        <div className='row'>
                          <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                            <label>
                              Customer Name<span className='astrick'>*</span>
                            </label>

                            <select
                              className='selectpicker form-control add-new'
                              data-live-search='true'
                              title='Choose customer'
                              id='variable_pay_type'
                              name='selValue'
                              onChange={(e) => {
                                let value = e.target.value;

                                // to due date term type change
                                let obj =
                                  this.state.customer_and_job_list &&
                                  this.state.customer_and_job_list.filter(
                                    (i) => i.id == value
                                  );
                                let term =
                                  obj != undefined && obj.due_date_term != ""
                                    ? obj.due_date_term
                                    : 1;
                                this.setState(
                                  { selected_due_date: term },
                                  () => {
                                    setTimeout(() => {
                                      let invoice_date =
                                        jQuery("#invoice_date").val();
                                      if (invoice_date != undefined) {
                                        this.changeDueDate(term, invoice_date);
                                      }
                                    }, 500);
                                  }
                                );
                                // to due date term type change

                                this.handleChange(value);
                              }}
                            >
                              <option>Create New </option>
                              {this.state.customer_and_job_list &&
                                this.state.customer_and_job_list.map((item) => {
                                  // console.log("hshs", item);
                                  // let isCustomer =
                                  //   localStorage.getItem('customer') != undefined
                                  //     ? true
                                  //     : false
                                  // if (isCustomer) {
                                  //   var allString = localStorage.getItem(
                                  //     'customer'
                                  //   )
                                  //   var splited = allString.split('=')
                                  //   var id = splited[1]
                                  // }
                                  // if (item.id == id) {
                                  //   var selected = true
                                  // }

                                  if (
                                    this.state.customerID != undefined &&
                                    this.state.customerID != null &&
                                    this.state.customerID != "" &&
                                    item.id == this.state.customerID
                                  ) {
                                    var selected = true;
                                  } else if (
                                    this.state.customer_id != undefined &&
                                    this.state.customer_id != null &&
                                    this.state.customer_id != "" &&
                                    item.id == this.state.customer_id
                                  ) {
                                    var selected = true;
                                  } else {
                                    var selected = false;
                                  }

                                  return (
                                    <option
                                      selected={selected}
                                      value={item.id}
                                      data-status={item.id}
                                      disabled={item.status == 2 ? true : false}
                                      style={{
                                        display:
                                          item.status == 2 ? "none" : "block",
                                      }}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {this.state.isCustomer ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <div className='form-group col-md-4'>
                      <label>Customer Name</label>
                      <div className='custom-select-drop dropdown'>
                        <a
                          aria-expanded='false'
                          aria-haspopup='true'
                          role='button'
                          data-toggle='dropdown'
                          className='dropdown-toggle btn form-control'
                          href='javascript:;'
                        >
                          <span id='selected'>
                            {this.state.selected_customer_name === ''
                              ? 'Choose customer'
                              : this.state.selected_customer_name}
                          </span>
                          <span className='caret' />
                        </a>
                        <ul className='dropdown-menu'>
                          {this.state.customer_list != '' &&
                            this.state.customer_list.map((item, index) => {
                              console.log('ewrwrw', item.name)
                              return (
                                <li key={index} id={'selected' + index}>
                                  <a
                                    href='javascript:;'
                                    onClick={() =>
                                      this.selected_customer(
                                        index,
                                        item.name,
                                        item.currency
                                      )
                                    }
                                  >
                                    {item.customer_name}
                                  </a>
                                </li>
                              )
                            })}
                        </ul>{' '}
                        {this.state.isCustomername ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>
                              *Please choose customer name.
                            </small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <converter ref={this.child} />
                    </div> */}
           
                          <div
                            className='form-group col-md-4'
                            id='itemoptionDatestatus'
                          >
                            <label>
                              Invoice Date<span className='astrick'>*</span>
                            </label>
                           
                            <div className='input-group date mar-t-no'>
                              <input
                                type='text'
                                autoComplete='off'
                                name='Date'
                                id='invoice_date'
                                onBlur={(e) => {

                                  if(this.state.previous_temp_date != jQuery("#invoice_date").val()) {
                                    this.setState({
                                      previous_temp_date:jQuery("#invoice_date").val()
                                    });
                                  }

                                  setTimeout(() => {
                                  this.setState({
                                    temp_date: jQuery("#invoice_date").val(),
                                  },()=>{
                                    this.loadExchangeRate(false);
                                  });
                                  }, 500);


                                  setTimeout(() => {
                                    // lock date validation should be done on save button click
                                    // if (this.state.lock_date) {
                                    //   if (jQuery("#invoice_date").val() && (this.state.previous_temp_date != jQuery("#invoice_date").val())) {
                                    //     this.setState({
                                    //       is_date_changed_once: true,
                                    //       is_exchange_rate_changed_once: false,
                                    //     });

                                    //     let date = this.changefromDate();
                                    //     var checkedIfCreated = this.state.hasId ? (this.state.lock_date >= date || (this.state.old_transaction_date <= this.state.lock_date && date >= this.state.lock_date)): this.state.lock_date >= date;
                                    //     if (checkedIfCreated) {
                                    //       jQuery("#invoice_date").val("");
                                    //        window
                                    //         .jQuery("#lock_date")
                                    //         .modal("show");
                                    //     } else {
                                         
                                    //       let invoice_date =
                                    //         jQuery("#invoice_date").val();
                                    //       if (invoice_date != undefined) {
                                    //         this.changeDueDate(
                                    //           this.state.selected_due_date,
                                    //           invoice_date
                                    //         );
                                    //       }
                                    //       this.loadExchangeRate(true);
                                    //       window
                                    //         .jQuery("#lock_date")
                                    //         .modal("hide");
                                    //     }
                                    //   }
                                    // } else {
                                      this.setState({
                                        is_date_changed_once: true,
                                        is_exchange_rate_changed_once: false,
                                      });

                                      let invoice_date =
                                        jQuery("#invoice_date").val();
                                      if (invoice_date != undefined) {
                                        this.changeDueDate(
                                          this.state.selected_due_date,
                                          invoice_date
                                        );
                                      }
                                      this.loadExchangeRate(true);
                                   // }
                                  }, 1000);

                                  // same function for check lock date
                                }}
                                className='form-control'
                                required
                              />
                              <div
                                className='input-group-addon'
                                onClick={() => jQuery("#invoice_date").focus()}
                              >
                                <img
                                  src='images/calendar-icon.svg'
                                  alt='icon'
                                />
                              </div>
                            </div>

                            {this.state.isInvoiceDate ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div
                            className='form-group col-md-4'
                            id='itemoptioninvoicenostatus'
                          >
                            <label>Invoice No</label>
                            <input
                              type='text'
                              name='inv-no'
                              className='form-control'
                              id='invoice_no'
                              required
                              autoComplete='off'
                              onChange={() => {
                                var no = jQuery("#invoice_no").val();
                                this.setState({ invoice_number_for_print: no });
                              }}
                            />{" "}
                            {/* {this.state.isInvoice_no ? (
                            <div >
                              <small style={{ color: "red" }}>*Required.</small>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.isAlreadyTaken ? (
                            <div >
                              <small style={{ color: "red" }}>
                                {this.state.ExistMsg}
                              </small>
                            </div>
                          ) : (
                            ""
                          )} */}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label>
                              Due date type<span className='astrick'>*</span>
                            </label>
                            <div className='custom-select-drop dropdown'>
                              <select
                                className='selectpicker form-control hh '
                                data-live-search='true'
                                value={this.state.selected_due_date}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  this.setState(
                                    { selected_due_date: value },
                                    () => {
                                      setTimeout(() => {
                                        let invoice_date =
                                          jQuery("#invoice_date").val();
                                        if (invoice_date != undefined) {
                                          this.changeDueDate(
                                            value,
                                            invoice_date
                                          );
                                        }
                                      }, 500);
                                    }
                                  );
                                }}
                                title='Choose...'
                                required
                              >
                                {this.state.sales_default_due_date_terms &&
                                  this.state.sales_default_due_date_terms.map(
                                    (t, index) => {
                                      return (
                                        <option value={t.id}>{t.name}</option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className='form-group col-md-4'>
                            <label>
                              Due Date<span className='astrick'>*</span>
                            </label>
                            <div className='input-group date mar-t-no'>
                              <input
                                type='text'
                                autoComplete='off'
                                name='incorport_date'
                                id='due_date'
                                // onBlur={e => this.changeDate(e.target.value)}
                                className='form-control'
                                required
                              />

                              <div
                                className='input-group-addon'
                                onClick={() => jQuery("#due_date").focus()}
                              >
                                <img
                                  src='images/calendar-icon.svg'
                                  alt='icon'
                                />
                              </div>
                            </div>
                            {this.state.isDueDate ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}

                            <small className='text-red'></small>
                          </div>

                          <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                            <label>
                              Job Name<span className='astrick'>*</span>
                            </label>

                            <select
                              className='selectpicker form-control add-new'
                              data-live-search='true'
                              title='Choose...'
                              id='jobName'
                              onChange={(e) => {
                                this.newJob(e.target.value);
                              }}
                            >
                              <option> Create New </option>
                              {this.state.customerjoblist &&
                                this.state.customerjoblist.map((item) => {
                                  if (
                                    this.state.JobId != undefined &&
                                    item.id == this.state.JobId
                                  ) {
                                    var selected = true;
                                  } else {
                                    //  jQuery("#jobName").val("Others");
                                    var selected = false;
                                  }
                                  return (
                                    <option
                                      selected={selected}
                                      value={item.job_name}
                                      data-status={item.id}
                                    >
                                      {item.job_name}
                                    </option>
                                  );
                                })}
                            </select>
                            {this.state.isJobName ? (
                              <div style={{ float: "left" }}>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className='row'>
                          {this.state.customField &&
                            this.state.customField.map((item, i) => {
                              return (
                                <>
                                  {item.type == "text" && (
                                    <div
                                      className='form-group col-md-4'
                                      // id="itemoptioninvoicenostatus"
                                    >
                                      <label>{item.name}</label>
                                      <input
                                        type='text'
                                        name='custom_f'
                                        className='form-control'
                                        value={this.state[`customField${i}`]}
                                        required
                                        autoComplete='off'
                                        onChange={(e) => {
                                          this.setState({
                                            [`customField${i}`]: e.target.value,
                                          });
                                          setTimeout(() => {
                                            this.customValues();
                                          }, 500);
                                        }}
                                      />{" "}
                                    </div>
                                  )}
                                  {item.type == "date" && (
                                    <div className='form-group col-md-4'>
                                      <label>
                                        {item.name}
                                        <span className='astrick'>*</span>
                                      </label>
                                      <div className='input-group date mar-t-no'>
                                        <input
                                          type='text'
                                          autoComplete='off'
                                          value={this.state[`customField${i}`]}
                                          id={`customField${i}`}
                                          // value={this.state[`customField${i}`]}
                                          onBlur={() => {
                                            setTimeout(() => {
                                              //   alert(`#customField${i}`)
                                              //  alert(jQuery(`#customField${i}`).val())
                                              let a = jQuery(
                                                `#customField${i}`
                                              ).val();
                                              this.setState(
                                                { [`customField${i}`]: a },
                                                () => this.customValues()
                                              );
                                            }, 1000);
                                          }}
                                          className='form-control'
                                          required
                                        />
                                        <div
                                          className='input-group-addon'
                                          onClick={() => {
                                            jQuery(`#customField${i}`).focus();
                                          }}
                                        >
                                          <img
                                            src='images/calendar-icon.svg'
                                            alt='icon'
                                          />
                                        </div>
                                      </div>
                                      <small className='text-red'></small>
                                    </div>
                                  )}
                                  {item.type == "dropDown" && (
                                    <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                                      <label>{item.name}</label>

                                      <select
                                        className='selectpicker form-control add-new'
                                        data-live-search='true'
                                        title='Choose...'
                                        value={this.state[`customField${i}`]}
                                        id={`customField${i}`}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (value == "new") {
                                            window
                                              .jQuery("#custom_column_options")
                                              .modal("show");
                                            this.setState({ idx: i });
                                          } else {
                                            this.setState({
                                              [`customField${i}`]: value,
                                            });
                                            setTimeout(() => {
                                              this.customValues();
                                            }, 500);
                                          }
                                        }}
                                      >
                                        <option value='new'>
                                          {" "}
                                          Create New{" "}
                                        </option>
                                        {this.state.customField[i].options &&
                                          this.state.customField[i].options.map(
                                            (item) => {
                                              return (
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>

                        {/* 
                  <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                    <label>Currency</label>
                    <select
                      className='selectpicker form-control'
                      id='currency_id'
                      data-live-search='true'
                      // onChange={e => {
                      //   this.setState({
                      //     preferred_deliveryMethod: e.target.value
                      //   })
                      // }}
                      onChange={e => {
                        var result = []

                        var options = e.target.options
                        var opt
                        for (
                          var i = 0, iLen = options.length;
                          i < iLen;
                          i++
                        ) {
                          opt = options[i]

                          if (opt.selected) {
                            result.push(opt.value)
                            this.setState({
                              selected_currency: opt.value
                            })
                          } else {
                          }
                        }

                        // this.setState({
                        //   preferred_paymentMethod: e.target.value
                        // })
                      }}
                    >
                      <option selected={true}>Choose</option>
                      {Object.keys(data).map(item => {
                        return (
                          <React.Fragment>
                            <option
                              value={data[item].id}
                              data-status={data[item].id}
                              data-currency={data[item].code}
                            >
                              {data[item].code}
                            </option>
                          </React.Fragment>
                        )
                      })}
                    </select>
                
                
                  </div> */}

                        {/* <div className='form-group col-md-4'>
                    <label>Job Name</label>
                    <select
                          className="selectpicker form-control add-new"
                          data-live-search="true"
                          title="Choose"
                          id="variable_pay_type"
                                                                                
                        >
                          <option>Create New </option>
                          <option>job 1</option>
                          <option>job 2</option>
                          <option>job 3</option>
                        </select>
                        </div> */}
                        {/* <textarea
                      className='form-control'
                      defaultValue={''}
                      id='job_name'
                    />
                    {this.state.isjob_name ? (
                      <div style={{ float: 'left' }}>
                        <small style={{ color: 'red' }}>
                          *Please fill out job name.
                        </small>
                      </div>
                    ) : (
                      ''
                    )} */}
                        <div className='row'>
                          {/* <div
                    className='form-group col-md-4'
                    id='dummy'
                    style={{visibility:'hidden'}}
                  >
                    <label>Invoice to</label>
                    <textarea
                      id='dummy'
                      className='form-control'
                  
                    />
                 
                  </div> */}

                          <div
                            className='form-group col-md-4'
                            id='itemoptionbilltostatus'
                          >
                            <label>
                              Invoice to<span className='astrick'>*</span>
                            </label>
                            {/* <textarea
                              id='invoice_to'
                              className='form-control'
                              value={this.state.billing_address}
                              onChange={(e) => {
                                this.setState({
                                  billing_address: e.target.value,
                                });
                              }}
                              required
                            /> */}

                        <Editor
                            editorState={invoiceToEditorState}
                            defaultEditorState={invoiceToEditorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={
                              this.onInvoiceToEditorStateChange
                            }
                            toolbarHidden
                            toolbar={{options: [], image: {
                            alignmentEnabled: false,
                            urlEnabled: false,
                            uploadEnabled: false,
                          }}}
                            editorStyle={{
                              "border-radius": "4px",
                              border: "1px solid #e2e2e2",
                              "min-height": "100px",
                              "max-height": "100px",
                              padding: "0px 6px",
                            }}
                          />
                            
                            {this.state.isInvoice_to ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}{" "}
                          </div>

                          <div
                            className='form-group col-md-4'
                            id='itemoptionshippingtostatus'
                          >
                            <label>
                              Shipping to
                              {/* <span className='astrick'>*</span> */}
                            </label>
                            <textarea
                              id='shipping_to'
                              className='form-control'
                              value={this.state.shipping_address}
                              onChange={(e) => {
                                this.setState({
                                  shipping_address: e.target.value,
                                });
                              }}
                              // required
                            ></textarea>
                            {this.state.isShippingt_to ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className='form-group col-md-4'
                            id='footertermsstatus'
                          >
                            <label>Memo</label>
                            <textarea
                              className='form-control'
                              id='footer_memo'
                              defaultValue={""}
                              required
                            />
                          </div>
                        </div>

                        {/* <div
                    className='form-group col-md-4'
                    id='itemoptioninvoicenostatus'
                  >
                    <label>Mobile No.</label>
                    <input
                      type='text'
                      name='mobile_no'
                      className='form-control'
                      id='mobile_no'
                      required
                    />{' '}
                    {this.state.isMobileno ? (
                      <div style={{ float: 'left' }}>
                        <small style={{ color: 'red' }}>
                          *Please fill out mobile no.
                        </small>
                      </div>
                    ) : (
                      ''
                    )}
                  </div> */}
                        {/* //Jp work */}

                        <div
                          className='modal fade pop-modal'
                          id='pop-modal-1'
                          role='dialog'
                          data-backdrop='static'
                        >
                          <div className='modal-dialog modal-md custom-modal'>
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                              onClick={this.cancel_gst_modal}
                            >
                              <img
                                className='img-responsive'
                                src='../../images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Add New GST</h3>
                                <form className='custom-form row'>
                                  <div className='form-group col-md-12 col-xs-12 pad-no'>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <label>
                                        Sales Tax Code
                                        <span className='astrick'>*</span>
                                      </label>
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                      <input
                                        type='text'
                                        name='sales_tax_code'
                                        id='sales_tax_code'
                                        autoComplete='off'
                                        maxLength='6'
                                        className='form-control'
                                        onChange={(event) =>
                                          this.handleChangeTax(event)
                                        }
                                        required
                                      />

                                      <p className='input-info'>
                                        (Maximum 6 characters)
                                      </p>
                                    </div>
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 pad-no'>
                                    <div className='col-md-4 col-sm-4 col-md-12'>
                                      <label>
                                        Sales Tax Name
                                        <span className='astrick'>*</span>
                                      </label>
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                      <input
                                        type='text'
                                        name='salesTax_name_entered'
                                        autoComplete='off'
                                        id='sales_tax_name'
                                        className='form-control'
                                        onChange={(event) =>
                                          this.handleChangeTax(event)
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <label>Tax Type</label>
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                      <label className='custom-checkbox radio mar-rgt taxable'>
                                        <input
                                          type='radio'
                                          name='tax-item'
                                          value='option1'
                                          checked={
                                            THIS.state.selectedOption ===
                                            "option1"
                                          }
                                          onChange={THIS.handleOptionChange}
                                        />
                                        Taxable
                                        <span className='checkmark'></span>
                                      </label>
                                      <label className='custom-checkbox radio non-taxable'>
                                        <input
                                          type='radio'
                                          name='tax-item'
                                          value='option2'
                                          checked={
                                            THIS.state.selectedOption ===
                                            "option2"
                                          }
                                          onChange={THIS.handleOptionChange}
                                        />{" "}
                                        Non-Taxable/Exempt
                                        <span className='checkmark'></span>
                                      </label>
                                      {THIS.state.selectedOption ===
                                      "option1" ? (
                                        <div className='hidden-field col-md-12 col-xs-12'>
                                          <div className='form-group'>
                                            <label className='mar-t-no mar-btm'>
                                              Tax item for purchases & sales
                                            </label>
                                            <div className='col-md-12'>
                                              <div className='row'>
                                                <label
                                                  className='mar-rgt'
                                                  style={{ marginTop: 8 }}
                                                >
                                                  Rate
                                                </label>
                                                {}
                                                <div className='input-group rate-input'>
                                                  <input
                                                    className='form-control'
                                                    type='text'
                                                    name='tax'
                                                    id='tax'
                                                    autoComplete='off'
                                                    required
                                                    onInput={(event) =>
                                                      THIS.handleChange_gst_type(
                                                        event
                                                      )
                                                    }
                                                  />
                                                  <div className='input-group-btn'>
                                                    <div className='custom-select-drop dropdown'>
                                                      <a
                                                        aria-expanded='false'
                                                        aria-haspopup='true'
                                                        role='button'
                                                        data-toggle='dropdown'
                                                        className='dropdown-toggle btn'
                                                        href='javascript:;'
                                                      >
                                                        <span id='selected'>
                                                          {
                                                            this.state
                                                              .selected_rate_type
                                                          }
                                                        </span>
                                                        <span className='caret'></span>
                                                      </a>
                                                      <ul className='dropdown-menu'>
                                                        <li className='active'>
                                                          <a
                                                            href='javascript:;'
                                                            onClick={
                                                              this
                                                                .update_rate_type
                                                            }
                                                          >
                                                            %
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href='javascript:;'
                                                            onClick={
                                                              this
                                                                .update_rate_fixed
                                                            }
                                                          >
                                                            Fixed price
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}
                                  <small
                                    style={{ color: "red" }}
                                    className='mymsg'
                                  >
                                    {this.state.modal_info_msg}{" "}
                                  </small>

                                  <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                                    {this.state.show_succes ? (
                                      <div className='alert alert-success'>
                                        <strong>Success!</strong> Your new GST
                                        is added.
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      className='btn btn-lightgray btn-align'
                                      data-dismiss='modal'
                                      onClick={this.modal_cancel}
                                    >
                                      Cancel
                                    </button>
                                    <span>{"   "}</span>
                                    <button
                                      className='btn btn-green btn-align'
                                      type='submit'
                                      onClick={this.add_gst_details}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* for lock date modal */}
                        <div>
                          <div
                            class='modal fade in'
                            id='lock_date'
                            role='dialog'
                            style={{ paddingLeft: 15 }}
                          >
                            <div
                              className='modal-dialog modal-md'
                              style={{ width: 440 }}
                            >
                              <button
                                type='button'
                                class='close hidden-xs'
                                data-dismiss='modal'
                              >
                                <img
                                  class='img-responsive'
                                  src='../../images/close-red.svg'
                                  alt='icon'
                                />
                              </button>
                              <div class='modal-content'>
                                <div class='modal-body text-center success-modal'>
                                  <div class='pop-icon img-size'>
                                    {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                                  </div>

                                  <h3>Are you sure?</h3>

                                  <p class='fw-500'>
                                   {this.state.lock_date_error_message}
                                  </p>
                                  <button
                                    className='btn btn-lightgray btn-align'
                                    data-dismiss='modal'
                                  >
                                    Cancel
                                  </button>
                                  <span>{"   "}</span>
                                  <button
                                    class='btn btn-red btn-align'
                                    type='button'
                                    data-dismiss='modal'
                                    data-toggle='modal'
                                    data-target='#asking_password_for_delete'
                                    // onClick={this.deleteUser}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className='modal fade pop-modal'
                          id='asking_password_for_delete'
                          // data-backdrop='static'
                        >
                          <div className='modal-dialog modal-xs custom-modal'>
                            {/* Modal content onClick={jQuery('.modal-backdrop').removeClass('in')}*/}
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                            >
                              <img
                                className='img-responsive'
                                src='images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Verification</h3>
                                <form className='custom-form row column'>
                                  <div className='form-group col-md-12 col-xs-12'>
                                    <label>
                                      Enter Lock Password
                                      <span className='astrick'>*</span>
                                    </label>
                                    <div className='form-group login-eye'>
                                      <i
                                        className='pass-visible'
                                        toggle='#password-fieldc'
                                        onClick={() =>
                                          this.setState({
                                            show: !this.state.show,
                                          })
                                        }
                                      >
                                        <img
                                          className='off'
                                          src='images/visibility-off.svg'
                                          alt='hide'
                                        />
                                        <img
                                          className='on'
                                          src='images/visibility.svg'
                                          alt='show'
                                        />
                                      </i>
                                      <input
                                        type={
                                          this.state.show ? "text" : "password"
                                        }
                                        value={this.state.old_password}
                                        className='form-control'
                                        required='required'
                                        autoComplete='off'
                                        onChange={(e) =>
                                          this.setState({
                                            old_password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    {this.state.old_password == "" ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          please enter this password field.
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.old_password != "" &&
                                    this.state.deleteMsg == true ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          *Incorrect password
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no'>
                                    <button
                                      className='btn btn-lightgray mar-rgt-5'
                                      data-dismiss='modal'
                                    >
                                      Cancel
                                    </button>
                                    <input
                                      type='button'
                                      className='btn btn-green mar-rgt-5'
                                      data-dismiss='modal'
                                      value='Submit'
                                      onClick={() => {
                                        if (this.state.old_password !== "") {
                                          //validate Invoice date and lock date in frontend itself
                                          this.save_template(
                                            "",
                                            this.state.btn_type
                                              ? this.state.btn_type
                                              : "save"
                                          );
                                          // this.checkPassword();
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div
                            class='modal fade in'
                            id='lock_date_1'
                            role='dialog'
                            style={{ paddingLeft: 15 }}
                          >
                            <div
                              class='modal-dialog modal-md'
                              style={{ width: 440 }}
                            >
                              <button
                                type='button'
                                class='close hidden-xs'
                                data-dismiss='modal'
                              >
                                <img
                                  class='img-responsive'
                                  src='../../images/close-red.svg'
                                  alt='icon'
                                />
                              </button>
                              <div class='modal-content'>
                                <div class='modal-body text-center success-modal'>
                                  <div class='pop-icon img-size'>
                                    {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                                  </div>

                                  <h3>Are you sure?</h3>

                                  <p class='fw-500'>
                                    This date is beyond lock date. To apply this
                                    date , need to verify Lock Date password
                                  </p>
                                  <button
                                    className='btn btn-lightgray btn-align'
                                    data-dismiss='modal'
                                  >
                                    Cancel
                                  </button>
                                  <span>{"   "}</span>
                                  <button
                                    class='btn btn-red btn-align'
                                    type='button'
                                    data-dismiss='modal'
                                    data-toggle='modal'
                                    data-target='#asking_password_for_delete_1'
                                    //
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className='modal fade pop-modal'
                          id='asking_password_for_delete_1'
                          role='dialog'
                          ata-backdrop='static'
                        >
                          <div className='modal-dialog modal-xs custom-modal'>
                            {/* Modal content*/}
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                            >
                              <img
                                className='img-responsive'
                                src='images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Change Password</h3>
                                <form className='custom-form row column'>
                                  <div className='form-group col-md-12 col-xs-12'>
                                    <label>
                                      Enter Lock Password
                                      <span className='astrick'>*</span>
                                    </label>
                                    <div className='form-group login-eye'>
                                      <i
                                        className='pass-visible'
                                        toggle='#password-fieldc'
                                        onClick={() =>
                                          this.setState({
                                            show: !this.state.show,
                                          })
                                        }
                                      >
                                        <img
                                          className='off'
                                          src='images/visibility-off.svg'
                                          alt='hide'
                                        />
                                        <img
                                          className='on'
                                          src='images/visibility.svg'
                                          alt='show'
                                        />
                                      </i>
                                      <input
                                        type={
                                          this.state.show ? "text" : "password"
                                        }
                                        value={this.state.old_password}
                                        className='form-control'
                                        required='required'
                                        autoComplete='off'
                                        onChange={(e) =>
                                          this.setState({
                                            old_password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    {this.state.old_password == "" ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          please enter this password field.
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.old_password != "" &&
                                    this.state.deleteMsg == true ? (
                                      <div style={{ float: "left" }}>
                                        <small style={{ color: "red" }}>
                                          *Incorrect password
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className='form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no'>
                                    <button
                                      className='btn btn-lightgray mar-rgt-5'
                                      data-dismiss='modal'
                                    >
                                      Cancel
                                    </button>
                                    <input
                                      type='button'
                                      className='btn btn-green mar-rgt-5'
                                      value='Submit'
                                      onClick={() => {
                                        if (this.state.old_password !== "") {
                                          this.checkPassword_1();
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* for lock date modal */}

                        <div className=' row '>
                          <div className='form-group col-md-12'>
                            <label className='custom-checkbox'>
                              <input
                                id='changeme'
                                type='checkbox'
                                checked={this.state.isChecked}
                                onChange={this.toggleChange}
                              />{" "}
                              Including Tax
                              <span className='checkmark'></span>
                            </label>

                            {this.state.paymentGRTR == 0 && (
                              <div className='th-action-inv'>
                                <a
                                  title='Add new column'
                                  href='javascript:;'
                                  className='add-col'
                                  data-toggle='modal'
                                  data-target='#pop-modal-2'
                                >
                                  <img
                                    className='img-responsive'
                                    src='images/plus-icon.svg'
                                    alt='icon'
                                  />
                                </a>
                                <a
                                  title='Edit columns'
                                  href='javascript:;'
                                  // data-toggle='modal'
                                  // data-target='#editCol'
                                  onClick={() => {
                                    var win = window.open(
                                      "/coulmn-rearrange",
                                      "_blank"
                                    );
                                    win.focus();
                                    // this.setState({
                                    //   isEditCol: !this.state.isEditCol
                                    // })
                                  }}
                                  className='add-col edit-col'
                                >
                                  <img
                                    className='img-responsive'
                                    src='images/pen-blue.svg'
                                    alt='icon'
                                  />
                                </a>
                              </div>
                            )}

                            <div className='table-responsive col-md-12 mar-top'>
                              <table
                                className='invoice-item-table '
                                id='mytable'
                                // onClick={() => jQuery(".table-responsive").css("overflow", "visible")}
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>No</th>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th className='text-right'> Unit Price</th>
                                    <th className='text-center'>Quantity</th>
                                    <th className='text-right'>Tax</th>

                                    <th className='text-right'>Total</th>
                                    <th>Category</th>

                                    {/* {this.state.coulmns != undefined &&
                                  this.state.coulmns.map((coulmn, index) => {
                                    return (
                                      <th
                                        className='handle'
                                        key={coulmn}
                                        id={`header${index}`}
                                        contentEditable
                                        onInput={event =>
                                          THIS.handleChangeItems(
                                            event,
                                            THIS.state.rows.length - 1
                                          )
                                        }
                                        required
                                        placeholder='Editable'
                                      >
                                        Editable
                                      </th>
                                    )
                                  })} */}
                                    {Array.isArray(
                                      this.state.number_of_columns_list
                                    ) &&
                                      this.state.number_of_columns_list !=
                                        undefined &&
                                      this.state.number_of_columns_list.map(
                                        (coulmn, index) => {
                                          if (coulmn.is_visible == 1) {
                                            return (
                                              <th
                                                className='handle text-center'
                                                key={coulmn.column_name}
                                                id={`header${index}`}
                                                onInput={(event) =>
                                                  THIS.handleChangeItems(
                                                    event,
                                                    THIS.state.rows.length - 1
                                                  )
                                                }
                                                required
                                                placeholder='DropDown'
                                              >
                                                {coulmn.column_name}
                                              </th>
                                            );
                                          }
                                        }
                                      )}
                                    {/* <th
                              className='text-center'
                              style={{ paddingTop: 1 }}
                            >
                              <a
                                href='javascript:;'
                                className='add-col'
                                data-toggle='modal'
                                data-target='#pop-modal-2'
                              >
                                <img
                                  src='../../images/plus-icon.svg'
                                  alt='icon'
                                />
                              </a>
                            </th> */}
                                  </tr>
                                </thead>
                                <tbody className='ui-sortable'>
                                  {this.state.rows.map(function (row, index) {
                                    let itemid = index;
                                    let myvalue =
                                      THIS.state.myarray.length > 0 &&
                                      THIS.state.myarray[itemid] &&
                                      THIS.state.myarray[itemid].price != "" &&
                                      THIS.state.myarray[itemid].price != 0
                                        ? THIS.state.myarray[itemid].price
                                        : "";
                                    // console.log('myarray',this.state.rows);
                                    let selected_categeory =
                                      THIS.state.myarray.length > 0 &&
                                      THIS.state.myarray[itemid] &&
                                      THIS.state.myarray[itemid].category_id !=
                                        "" &&
                                      THIS.state.myarray[itemid].category_id !=
                                        0
                                        ? THIS.state.myarray[itemid].category_id
                                        : THIS.state.selected;
                                    //   alert(itemid)
                                    return (
                                      <tr key={row} className='handle'>
                                        <td>
                                          <input type='hidden' id='getMe' disabled={false} />
                                          <label htmlFor="getMe" className='drag-icon handle' >
                                            <img
                                              src='images/dots-menu.svg'
                                              alt='icon'
                                            />
                                          </label>
                                          {/* {index + 1} */}
                                          {/* {jQuery('table tr').index(tr)} */}
                                        </td>
                                        <td className='text-center'></td>

                                        <td
                                          className='Tax_width'
                                          onClick={() =>
                                            jQuery("#changeTableProps").css(
                                              "height",
                                              "1000px"
                                            )
                                          }
                                          onBlur={() =>
                                            jQuery("#changeTableProps").css(
                                              "height",
                                              "1000px"
                                            )
                                          }
                                        >
                                          <input
                                            type='hidden'
                                            className='form-control'
                                            placeholder='Enter Item'
                                            autoComplete='off'
                                            name='item_name'
                                            id={`item${itemid}`}
                                            // defaultValue={'product1'}
                                            onInput={(event) =>
                                              THIS.handleChangeItems(
                                                event,
                                                THIS.state.rows.length - 1
                                              )
                                            }
                                            required
                                          />

                                          <select
                                            className='selectpicker custom-select-drop dropdown cus add-new'
                                            id={`cust_items${itemid}`}
                                            // data-display="static"
                                            data-live-search='true'
                                            title='Choose'
                                            onChange={(e) => {
                                              if (e.target.value == "1e") {
                                                var use =
                                                  "#cust_items" + itemid;
                                                jQuery(`${use} option`)
                                                  .prop("selected", false)
                                                  .trigger("change");

                                                window
                                                  .jQuery("#add_items")
                                                  .modal("show");
                                                jQuery("#iamfrom").val(itemid);
                                              } else {
                                                // console.log(
                                                //   "hdhdhd",
                                                //   e.target.value
                                                // );
                                                var res =
                                                  e.target.value.split("=");
                                                var rate =
                                                  res[0] != "null"
                                                    ? res[0]
                                                    : "";
                                                var des =
                                                  res[1] != "null"
                                                    ? res[1]
                                                    : "";
                                                var cat_id =
                                                  res[2] != "null"
                                                    ? res[2]
                                                    : "";

                                                // richtext-description-20240410
                                                // jQuery("#descr" + itemid).val(
                                                //   des
                                                // );

                                                THIS.setDescriptionContent(des,itemid,true);

                                                jQuery(
                                                  "#unit_price" + itemid
                                                ).val(rate);
                                                jQuery("#categry_id" + itemid)
                                                  .val(cat_id)
                                                  .trigger("change");

                                                jQuery("#item" + itemid).val(
                                                  res[3]
                                                );

                                                jQuery("#itemsid" + itemid).val(
                                                  res[4]
                                                );
                                                THIS.handleChangeItems(
                                                  0,
                                                  THIS.state.rows.length - 1
                                                );
                                              }
                                            }}
                                          >
                                            <option value='1e'>
                                              {" "}
                                              Add New{" "}
                                            </option>
                                            {THIS.state.opt != "" &&
                                            THIS.state.opt != undefined
                                              ? THIS.state.opt
                                              : ""}
                                          </select>
                                          <input
                                            type='hidden'
                                            id={`itemsid${itemid}`}
                                          />
                                        </td>

                                        {/* <td>  <Select options={options} /></td> */}
                                        <td>
                                          {/* <textarea
                                            className='form-control mycustextarea'
                                            placeholder='Description'
                                            style={{
                                              height: "auto",
                                              width: "auto",
                                            }}
                                            autoComplete='off'
                                            name='description'
                                            id={`descr${itemid}`}
                                            onInput={(event) => {
                                              THIS.handleChangeItems(
                                                event,
                                                THIS.state.rows.length - 1
                                              );
                                            }}
                                          ></textarea> */}
                                           <Editor
                                            placeholder='Description'
                                            // style={{
                                            //   height: "auto",
                                            //   width: "auto",
                                            // }}
                                            // autoComplete='off'
                                            // name='description'
                                            // id={`descr${itemid}`}
                                            wrapperClassName='demo-wrapper'
                                            editorClassName='demo-editor'
                                            toolbarHidden
                                           
                                            editorState={THIS.state[`descriptionEditorState${itemid}`]}
                                            onBlur={(event, editorState) => {
                                            //  THIS.setState({[`descriptionEditorState${itemid}`]:event},()=>{
                                                THIS.handleChangeItems(
                                                0,
                                                THIS.state.rows.length - 1
                                              );
                                             // });
                                            }}
                                            onEditorStateChange={(event, editorState) => {
                                              console.log("event....13155",event);
                                                      console.log("jhhjjhjhjhj...13156",draftToHtml(
                                                    convertToRaw(event.getCurrentContent())
                                                  ));

                                              THIS.setState({[`descriptionEditorState${itemid}`]:event},()=>{
                                                  //   THIS.handleChangeItems(
                                                  //   0,
                                                  //   THIS.state.rows.length - 1
                                                  // );
                                                  })
                                            }}
                                            editorStyle={{
                                              // "min-height": "50px",
                                              // padding: "0px 6px",
                                              // "max-height":"100px",
                                              // "width":"300px"
                                            }}
                                          />
                                        </td>

                                        <td className='text-right'>
                                          {/* <NumberFormat 
                                          name="unit_price"
                                          className="form-control text-right"
                                          placeholder={"00.00"}
                                          autoComplete="off"
                                          id={`unit_price${itemid}`}
                                           thousandsGroupStyle="thousand"
                                           value={jQuery("#cust_items" + itemid).val() && jQuery("#cust_items" + itemid).val().split("=")[0]}                                                                                  
                                           decimalSeparator="."
                                           displayType="input"
                                           type="text"
                                           thousandSeparator={true}
                                           allowNegative={true}
                                           onChange={(event) => {
                                            THIS.handleChangeItems(event, THIS.state.rows.length - 1)
                                            setTimeout(() => { THIS.handleChangeItems('', THIS.state.rows.length - 1) }, 1000)
                                          }}
                                          
                                        /> */}
                                          <input
                                            // type="text"
                                            type='number'
                                            name='unit_price'
                                            className='form-control text-right'
                                            placeholder={"00.00"}
                                            autoComplete='off'
                                            id={`unit_price${itemid}`}
                                            // onKeyPress={(event) => {
                                            //   if (!/[0-9]/.test(event.key)) {
                                            //     event.preventDefault();
                                            //   }
                                            // }}
                                            onInput={(event) => {
                                              THIS.handleChangeItems(
                                                event,
                                                THIS.state.rows.length - 1
                                              );
                                              setTimeout(() => {
                                                THIS.handleChangeItems(
                                                  "",
                                                  THIS.state.rows.length - 1
                                                );
                                              }, 1000);
                                            }}
                                            required
                                          />
                                        </td>
                                        <td className='text-center'>
                                          {/* <NumberFormat
                                         name="quantity"
                                         className="form-control"
                                         placeholder={"00.00"}
                                         id={`quantity${itemid}`}                                          
                                          autoComplete="off"                                         
                                          thousandSeparator={false}                                         
                                          onValueChange={(event) => {
                                            THIS.handleChangeItems(
                                              event,
                                              THIS.state.rows.length - 1
                                            )
                                            setTimeout(() => {
                                              THIS.handleChangeItems('', THIS.state.rows.length - 1)
                                            }, 1000);
                                          }
                                          }
                                        /> */}
                                          <input
                                            autoComplete='off'
                                            // type="text"
                                            type='number'
                                            name='quantity'
                                            className='form-control'
                                            placeholder={"00.00"}
                                            id={`quantity${itemid}`}
                                            // onKeyPress={(event) => {
                                            //   if (!/[0-9]/.test(event.key)) {
                                            //     event.preventDefault();
                                            //   }
                                            // }}
                                            onInput={(event) => {
                                              THIS.handleChangeItems(
                                                event,
                                                THIS.state.rows.length - 1
                                              );
                                              setTimeout(() => {
                                                THIS.handleChangeItems(
                                                  "",
                                                  THIS.state.rows.length - 1
                                                );
                                              }, 1000);
                                            }}
                                            required
                                          />
                                        </td>

                                        <td className='Tax_width '>
                                          {/* <select
                                          className="selectpicker form-control add-new"
                                          data-live-search="true"
                                          title="Choose Tax"
                                          // id="variable_pay_typegggg"
                                          id={`taxdisable${itemid}`}
                                          name="selValue"
                                          onChange={(e) => {

                                            THIS.state.gst_list.map((item) => {
                                              if (item == e.target.value) {

                                                THIS.handleCheck_get_selected_tax.bind(
                                                  THIS,
                                                  item.id,
                                                  itemid,
                                                  "selectednow" +
                                                  itemid,
                                                  item.sales_tax_name,
                                                  item.rate,
                                                  item.rate_type
                                                )

                                              }

                                            })
                                          }}
                                        >
                                          <option>Create New </option>
                                          {
                                            THIS.state.gst_list &&
                                            THIS.state.gst_list.map((item) => {
                                              console.log("hshs", item);

                                              if (
                                                THIS.state.customerID != undefined &&
                                                THIS.state.customerID != null &&
                                                THIS.state.customerID != "" &&
                                                item.id == THIS.state.customerID
                                              ) {
                                                var selected = true;
                                              } else {
                                                var selected = false;
                                              }

                                              return (
                                                <option
                                                  // selected={selected}
                                                  value={item.name}
                                                  data-status={item.name}
                                                >
                                                  {item.sales_tax_name}
                                                </option>
                                              );
                                            })
                                          }
                                        </select>

                                        <span
                                          id={`selectednow_id${itemid}`}
                                          style={{ display: "none" }}
                                        >
                                          NO_VALUE
                                            </span>
                                        <input
                                          type="hidden"
                                          id={`selectedrate_id${itemid}`}
                                        />
                                        <input
                                          type="hidden"
                                          id={`selectedtype_id${itemid}`}
                                        /> */}

                                          <div className='custom-select-drop  dropdown cus'>
                                            <a
                                              aria-expanded='false'
                                              aria-haspopup='true'
                                              role='button'
                                              data-toggle='dropdown'
                                              className='dropdown-toggle btn useDRP'
                                              id={`taxdisable${itemid}`}
                                              href='javascript:;'
                                              value={THIS.state.selected}
                                              required
                                            >
                                              <span
                                                id={`selectednow${itemid}`}
                                                className='salesTaxName'
                                              >
                                                {THIS.state.selected != ""
                                                  ? THIS.state.selected
                                                  : "Choose"}{" "}
                                              </span>

                                              <span
                                                id={`selectednow_id${itemid}`}
                                                style={{ display: "none" }}
                                              >
                                                NO_VALUE
                                              </span>
                                              <input
                                                type='hidden'
                                                id={`selectedrate_id${itemid}`}
                                              />
                                              <input
                                                type='hidden'
                                                id={`selectedtype_id${itemid}`}
                                              />

                                              <span className='caret'></span>
                                            </a>
                                            <ul
                                              className='dropdown-menu category'
                                              // style={{
                                              //   height: 213,
                                              //   overflowY: "scroll",
                                              //   width: "auto",
                                              //   overflowX: "auto",
                                              // }}
                                            >
                                              {/* <li
                                              className="active"

                                              data-name={""}
                                              data-rate={0}
                                              data-type={""}
                                            >
                                              <a href="javascript:;" value={0}>
                                                choose
                                              </a>
                                            </li> */}

                                              <li>
                                                <input
                                                  type='text'
                                                  name='search'
                                                  id='gst_search'
                                                  autoComplete='off'
                                                  className='form-control'
                                                  onInput={(event) =>
                                                    THIS.update_search_keyword(
                                                      event
                                                    )
                                                  }
                                                  required
                                                />
                                                <button
                                                  type='button'
                                                  className='btn btn-rounded btn-blue'
                                                  data-toggle='modal'
                                                  // data-target="#pop-modal-1"
                                                  onClick={() => {
                                                    THIS.setState({
                                                      tax_adding_index: itemid,
                                                    });
                                                    window
                                                      .jQuery("#pop-modal-1")
                                                      .modal("show");
                                                  }}
                                                >
                                                  Add New
                                                  <img
                                                    className='arrow-icon'
                                                    src='../../images/right-arrow.svg'
                                                    alt='icon'
                                                  />
                                                </button>
                                              </li>
                                              <li>
                                                <ul className='list-unstyled'>
                                                  {THIS.state.gst_list.map(
                                                    (item, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          onClick={THIS.handleCheck_get_selected_tax.bind(
                                                            THIS,
                                                            item.id,
                                                            itemid,
                                                            "selectednow" +
                                                              itemid,
                                                            item.sales_tax_name,
                                                            item.rate,
                                                            item.rate_type
                                                          )}
                                                          data-name={
                                                            item.sales_tax_name
                                                          }
                                                          data-rate={item.rate}
                                                          data-type={
                                                            item.rate_type
                                                          }
                                                          style={{
                                                            display:
                                                              item.show_on_list ==
                                                              2
                                                                ? "none"
                                                                : "block",
                                                          }}
                                                        >
                                                          <a
                                                            href='javascript:;'
                                                            value={item.name}
                                                          >
                                                            {
                                                              item.sales_tax_name
                                                            }
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>

                                        <td className='text-right'>
                                          <input
                                            type='text'
                                            autoComplete='off'
                                            className='form-control text-right'
                                            placeholder={"00.00"}
                                            name='sub_total'
                                            // onFocus={()=>{
                                            //   jQuery('#subtotal'+itemid).val('')

                                            // }}
                                            onChange={(e) => {
                                              var vv = e.target.value;
                                              if (!isNaN(Number(vv))) {
                                                jQuery(
                                                  "#unit_price" + itemid
                                                ).val(Number(vv));
                                                jQuery(
                                                  "#quantity" + itemid
                                                ).val(Number(1));
                                                THIS.handleChangeItems(
                                                  itemid,
                                                  THIS.state.rows.length - 1
                                                );
                                              }
                                            }}
                                            id={`subtotal${itemid}`}
                                          />
                                        </td>
                                        <td className='catagory_width'>
                                          <select
                                            className='selectpicker form-control add-new'
                                            data-live-search='true'
                                            title='Choose'
                                            id={`categry_id${itemid}`}
                                            onChange={(e) => {
                                              if (e.target.value == "1e") {
                                                jQuery(
                                                  `#categry_id${itemid} option`
                                                )
                                                  .prop("selected", false)
                                                  .trigger("change");

                                                window
                                                  .jQuery("#pop-modal")
                                                  .modal("show");
                                              }

                                              // to update category
                                              THIS.handleChangeItems(
                                                itemid,
                                                THIS.state.rows.length - 1
                                              );
                                              // to update category
                                            }}
                                          >
                                            <option value='1e'>
                                              Create New{" "}
                                            </option>
                                            {THIS.state.default_category_list &&
                                              THIS.state.default_category_list.map(
                                                (item) => {
                                                  return (
                                                    <option
                                                      value={item.id}
                                                      data-status={item.id}
                                                      style={{
                                                        display:
                                                          item.status == 2
                                                            ? "none"
                                                            : "block",
                                                      }}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </select>
                                        </td>

                                        {Array.isArray(
                                          THIS.state.number_of_columns_list
                                        ) &&
                                          THIS.state.number_of_columns_list !=
                                            undefined &&
                                          THIS.state.number_of_columns_list.map(
                                            (column, index) => {
                                              // console.log("gdsgsdg", itemid);
                                              var colId = index;

                                              if (column.type == 2) {
                                                //newly added to display only active options
                                                let optionsObjectArray =
                                                  column.optionsObjectArray
                                                    ? column.optionsObjectArray
                                                    : [];

                                                const optionsMap =
                                                  column.options.map(
                                                    (item, ov_ind) => {
                                                      // if(optionsObjectArray[ov_ind] && optionsObjectArray[ov_ind]["status"] == 2) {
                                                      //   return '';
                                                      // } else {
                                                      return (
                                                        <option
                                                          value={item}
                                                          style={{
                                                            display:
                                                              optionsObjectArray[
                                                                ov_ind
                                                              ] &&
                                                              optionsObjectArray[
                                                                ov_ind
                                                              ]["status"] == 2
                                                                ? "none"
                                                                : "block",
                                                          }}
                                                        >
                                                          {item}
                                                        </option>
                                                      );
                                                      //}
                                                    }
                                                  );

                                                if (column.is_visible == 1) {
                                                  return (
                                                    <td>
                                                      <select
                                                        className='selectpicker form-control add-new cus'
                                                        id={`addtext${colId}${itemid}`}
                                                        data-live-search='true'
                                                        title='Choose...'
                                                        onChange={(event) =>
                                                          THIS.tableDropDynamic(
                                                            event,
                                                            THIS.state.rows
                                                              .length - 1,
                                                            colId
                                                          )
                                                        }
                                                      >
                                                        <option value='1e'>
                                                          {" "}
                                                          Add New{" "}
                                                        </option>

                                                        {optionsMap}
                                                      </select>
                                                    </td>
                                                  );
                                                }
                                              }
                                              //do here text  field
                                              if (column.type == 1) {
                                                if (column.is_visible == 1) {
                                                  return (
                                                    <td className='textwidth text-center'>
                                                      <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Type...'
                                                        id={`addtext${colId}${itemid}`}
                                                        onInput={(event) =>
                                                          THIS.handleChangeItems(
                                                            event,
                                                            THIS.state.rows
                                                              .length - 1
                                                          )
                                                        }
                                                        required
                                                      />
                                                    </td>
                                                  );
                                                }
                                              }
                                            }
                                          )}

                                        {THIS.state.coulmns != undefined &&
                                          THIS.state.coulmns.length > 0 &&
                                          THIS.state.coulmns.map((item, p) => {
                                            return (
                                              <td className='text-center'>
                                                <input
                                                  type='text'
                                                  className='form-control'
                                                  placeholder={"00.00"}
                                                  name='sub_total1'
                                                  id={`subtotal`}
                                                />
                                              </td>
                                            );
                                          })}

                                        <td>
                                          {THIS.state.paymentGRTR == 0 && (
                                            <div className='action-wrap'>
                                              <a
                                                title='copy row'
                                                href='javascript:;'
                                                className='clone-row'
                                                onClick={() =>
                                                  THIS.copyRow(itemid)
                                                }
                                              >
                                                <img
                                                  src='images/clone-icon.svg'
                                                  alt='icon'
                                                />
                                              </a>
                                              {itemid > 0 ? (
                                                <a
                                                  title='delete row'
                                                  href='javascript:;'
                                                  className='del-row'
                                                  data-toggle='modal'
                                                  data-target='#modal_delete'
                                                  onClick={() => {
                                                    THIS.setState({
                                                      specific_id_delete:
                                                        itemid,
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src='images/delete-icon.svg'
                                                    alt='icon'
                                                  />
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>

                              {/* <div className='form-group col-md-12 mar-b-no'>
                          <a href='javascript:;' className='add-input'>ADD ROW</a>
                        </div> */}
                              <div class='form-group col-md-12 mar-b-no'>
                                {" "}
                                {this.state.isTablefilled ? (
                                  <div>
                                    {/* <small style={{ color: "red" }}>
                                    *Please fill all fields.
                                  </small> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.control_addButton()}
                              </div>
                              {/* <div className='error_tbl_fld'>
                          Please fill out all table fields.
                        </div> */}
                            </div>
                          </div>
                        </div>

                        {/* {this.state.isRejected ? (
                        <div
                          id='failed_alrt'
                          class='alert alert-card danger alert-dismissible fade in'
                        >
                          <a
                            href='#'
                            class='close'
                            data-dismiss='alert'
                            aria-label='close'
                          >
                            &times;
                          </a>
                          <div class='img-wrap'>
                            <img
                              class='img-responsive'
                              src='images/alert-cross.svg'
                              alt='icon'
                            />
                          </div>
                          <div class='alert-cont'>
                            <strong class='title'>Failed!</strong>
                            {this.state.reject_msg}.
                          </div>
                        </div>
                      ) : (
                        ''
                      )} */}

                        {this.state.isClose ? (
                          <div
                            class='alert alert-card success alert-dismissible fade in'
                            id='closeme'
                          >
                            <a
                              href='#'
                              class='close'
                              data-dismiss='alert'
                              aria-label='close'
                              onClick={() => {
                                this.setState({ isClose: false });
                              }}
                            >
                              &times;
                            </a>
                            <div class='img-wrap'>
                              <img
                                class='img-responsive'
                                src='../../images/alert-success.svg'
                                alt='icon'
                              />
                            </div>
                            <div class='alert-cont'>
                              <strong class='title'>Success!</strong>
                              saved successfully.
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.isFailed && (
                          <div className='alert alert-card danger alert-dismissible fade in'>
                            <a
                              href='#'
                              className='close'
                              onClick={() => {
                                this.setState({ isFailed: false });
                              }}
                              data-dismiss='alert'
                              aria-label='close'
                            >
                              ×
                            </a>
                            <div className='img-wrap'>
                              <img
                                className='img-responsive'
                                src='images/alert-cross.svg'
                                alt='icon'
                              />
                            </div>
                            <div className='alert-cont'>
                              <strong className='title'>Failed!</strong>
                              {this.state.errormessage}
                            </div>
                          </div>
                        )}
                        <div></div>
                        <div className='row'>
                          <div className='col-md-12 total-row' id='uss'>
                            <div className='row'>
                              <div className='form-group exchange-col col-md-5 col-xs-12'>
                                <label className='fw-sbold'>
                                  Exchange Rate 1{" "}
                                  {this.state.CurrentSelectedCurr}
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    name='inv-no'
                                    className='form-control text-right'
                                    id='Exchange'
                                    required
                                    autoComplete='off'
                                    placeholder={
                                      this.state.cus_rate_rate
                                    }
                                    value={
                                      this.state.cus_rate_rate }
                                    onChange={(e) => {
                                      this.setState({
                                        is_exchange_rate_changed_once: true,
                                      });
                                      if (e.target.value.length > 0) {
                                        this.setState(
                                          {
                                            cus_rate_rate: e.target.value,
                                          },
                                          () => {
                                            // jQuery("#getMe").val(
                                            //   this.state.cus_rate_rate
                                            // );
                                            this.handleChangeItems(
                                              0,
                                              this.state.rows.length - 1
                                            );
                                          }
                                        );
                                      } else {
                                        this.setState(
                                          {
                                            cus_rate_rate:
                                              0
                                          },
                                          () => {
                                            this.handleChangeItems(
                                              0,
                                              this.state.rows.length - 1
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  />
                                  <span className='label'>
                                    {this.state.clientHomeCurrency}
                                  </span>
                                </div>
                              </div>

                              {/* for comma separation display none here and displayed below */}

                              <div
                                className='form-group col-md-7 col-xs-12 total-table'
                                style={{ display: "none" }}
                              >
                                <table className='pull-right'>
                                  <thead>
                                    <tr>
                                      <th>&nbsp;</th>
                                      <th className='text-center'>
                                        Foreign Currency
                                        <br />
                                        ({this.state.CurrentSelectedCurr})
                                      </th>
                                      <th className='text-right'>
                                        Home Currency
                                        <br />({this.state.clientHomeCurrency})
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className='text-center'>Sub Total</td>

                                      <td
                                        className='text-right'
                                        id='homecurrencytyt'
                                      >
                                        {isNaN(this.state.subTotal)
                                          ? "0.00"
                                          : this.state.subTotal.toFixed(2)}
                                      </td>
                                      <td
                                        // else { jQuery("#home_tax_total").html(this.formatCurrency(this.state.currency_customer)(parseFloat(tax_total.toFixed(2)))) }

                                        className='text-right'
                                        id='foreigncurrencytyt'
                                      >
                                        {isNaN(
                                          this.state.sub_total_home_currency
                                        )
                                          ? "0.00"
                                          : // : this.formatCurrency('SGD') (parseFloat(this.state.sub_total_home_currency))}
                                            this.state.sub_total_home_currency}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-right'>Tax</td>

                                      <td
                                        className='text-right'
                                        id='home_tax_total'
                                      >
                                        00.00
                                      </td>
                                      <td
                                        className='text-right'
                                        id='foreign_tax_total'
                                      >
                                        {
                                          isNaN(this.state.tax_home_currency)
                                            ? "0.00"
                                            : this.state.tax_home_currency
                                          //  this.formatCurrency('SGD') (parseFloat(this.state.tax_home_currency))}
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-right'>
                                        Grand Total
                                      </td>

                                      <td
                                        className='text-right'
                                        id='home_grand_total'
                                      >
                                        0.00{" "}
                                        {/* {this.formatCurrency('USD')(parseFloat(0).toFixed(2))} */}
                                      </td>
                                      <td
                                        className='text-right'
                                        id='foreign_grand_total'
                                      >
                                        {/* {
                                      isNaN(
                                        Number(this.state.sub_total_home_currency )+ Number(this.state.tax_home_currency)
                                      )
                                        ? "0"
                                        :( Number(this.state.sub_total_home_currency) +Number(this.state.tax_home_currency))
                                      // this.formatCurrency('SGD')(parseFloat(this.state.grand_total_home_currency).toFixed(2))
                                    } */}

                                        {Number(
                                          Number(
                                            isNaN(
                                              Number(
                                                this.state
                                                  .sub_total_home_currency
                                              )
                                            )
                                              ? 0.0
                                              : this.state
                                                  .sub_total_home_currency
                                          ) +
                                            Number(
                                              isNaN(
                                                Number(
                                                  this.state.tax_home_currency
                                                )
                                              )
                                                ? 0.0
                                                : this.state.tax_home_currency
                                            )
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className='form-group col-md-7 col-xs-12 total-table'>
                                <table className='pull-right'>
                                  <thead>
                                    <tr>
                                      <th>&nbsp;</th>
                                      <th className='text-right'>
                                        Foreign Currency
                                        <br />( {this.state.CurrentSelectedCurr}
                                        )
                                      </th>
                                      <th className='text-right'>
                                        Home Currency
                                        <br />({this.state.clientHomeCurrency})
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className='text-right'>Sub Total</td>

                                      <td
                                        className='text-right'
                                        // id="homecurrencytyt"
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            isNaN(
                                              jQuery("#homecurrencytyt").html()
                                            )
                                              ? "0.00"
                                              : jQuery(
                                                  "#homecurrencytyt"
                                                ).html()
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                      <td
                                        className='text-right'
                                        // id="foreigncurrencytyt"
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            isNaN(
                                              jQuery(
                                                "#foreigncurrencytyt"
                                              ).html()
                                            )
                                              ? "0.00"
                                              : jQuery(
                                                  "#foreigncurrencytyt"
                                                ).html()
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-right'>Tax</td>

                                      <td
                                        className='text-right'
                                        // id="home_tax_total"
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            isNaN(
                                              jQuery("#home_tax_total").html()
                                            )
                                              ? "0.00"
                                              : jQuery("#home_tax_total").html()
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                      <td
                                        className='text-right'
                                        id='foreign_tax_total'
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            isNaN(
                                              jQuery(
                                                "#foreign_tax_total"
                                              ).html()
                                            )
                                              ? "0.00"
                                              : jQuery(
                                                  "#foreign_tax_total"
                                                ).html()
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='text-right'>
                                        Grand Total
                                      </td>

                                      <td
                                        className='text-right'
                                        id='home_grand_total'
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            jQuery(
                                              "#home_grand_total"
                                            ).html() == undefined
                                              ? "0.00"
                                              : jQuery("#home_grand_total")
                                                  .html()
                                                  .replace(/,/g, "")
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                      <td
                                        className='text-right'
                                        id='foreign_grand_total'
                                      >
                                        {new Intl.NumberFormat(
                                          this.state.language_code +
                                            "-" +
                                            this.state.country_sortname,
                                          {
                                            style: "currency",
                                            currency: this.state.home_currency,
                                          }
                                        )
                                          .format(
                                            isNaN(
                                              jQuery(
                                                "#foreign_grand_total"
                                              ).html()
                                            )
                                              ? "0.00"
                                              : jQuery(
                                                  "#foreign_grand_total"
                                                ).html()
                                          )
                                          .replace(
                                            this.state.home_currency_symbol,
                                            ""
                                          )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              {/* for comma separation display none here and displayed below */}
                            </div>

                            {this.state.isInvoiceEditable && (
                              <div className='row'>
                                <div className='form-group exchange-col col-md-5 col-xs-12'></div>
                                <div className='form-group col-md-7 col-xs-12 total-table'>
                                  <table className='pull-right'>
                                    <thead
                                    // style={{ visibility: "hidden" }}
                                    >
                                      <tr>
                                        <th>&nbsp;</th>
                                        <th className='text-center'>
                                          Amount Paid
                                          <br />
                                          {/* ({this.state.currency_customer}) */}
                                          ({this.state.CurrentSelectedCurr})
                                        </th>
                                        <th className='text-center'>
                                          Home Currency
                                          <br />({this.state.clientHomeCurrency}
                                          )
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody style={{ display: "none" }}>
                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          {" "}
                                          Amount Paid
                                        </td>

                                        <td
                                          style={{
                                            cursor: "pointer",
                                            textDecorationColor: "#0000ff",
                                            textDecoration: "underline",
                                            color: "#0000ff",
                                          }}
                                          id='appliedfor'
                                          data-toggle='modal'
                                          data-target='#table_items'
                                          className='text-center'
                                        >
                                          {/* <input className='form-control' disabled={true} style={{width:100}} /> */}
                                        </td>
                                        <td
                                          style={{
                                            cursor: "pointer",
                                            textDecorationColor: "#0000ff",
                                            textDecoration: "underline",
                                            color: "#0000ff",
                                          }}
                                          data-toggle='modal'
                                          data-target='#table_items'
                                          // else { jQuery("#home_tax_total").html(this.formatCurrency(this.state.currency_customer)(parseFloat(tax_total.toFixed(2)))) }
                                          id='appliedhom'
                                          className='text-center'
                                        >
                                          {/* <input className='form-control' disabled={true} style={{width:100}}  id='appliedhom'/> */}
                                        </td>
                                      </tr>

                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          {" "}
                                          Exchange Gain/Loss
                                        </td>

                                        <td className='text-center'>
                                          {/* <input className='form-control' disabled={true} style={{width:100}} /> */}
                                        </td>
                                        <td
                                          // else { jQuery("#home_tax_total").html(this.formatCurrency(this.state.currency_customer)(parseFloat(tax_total.toFixed(2)))) }
                                          id='exchangeGain'
                                          className='text-center'
                                        >
                                          {/* <input className='form-control' disabled={true} style={{width:100}}  id='appliedhom'/> */}
                                        </td>
                                      </tr>

                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          Balance Due
                                        </td>

                                        <td
                                          id='forbaldue'
                                          className='text-center'
                                        >
                                          {/* <input className='form-control' disabled={true} style={{width:100}} /> */}
                                        </td>
                                        <td
                                          id='homebaldue'
                                          className='text-center'
                                        >
                                          {/* <input className='form-control' disabled={true} style={{width:100}} /> */}
                                        </td>
                                      </tr>
                                    </tbody>

                                    {/* for comma separation display none here and displayed below */}

                                    <tbody>
                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          {" "}
                                          Amount Paid/Credit Applied
                                        </td>

                                        <td
                                          style={{
                                            cursor: "pointer",
                                            textDecorationColor: "#0000ff",
                                            textDecoration: "underline",
                                            color: "#0000ff",
                                          }}
                                          data-toggle='modal'
                                          data-target='#table_items'
                                          className='text-center'
                                        >
                                          {/* <Comma value={jQuery('#appliedfor').html()} /> */}
                                          <Comma
                                            value={
                                              this.state
                                                .pmt_applied_foreign_currency
                                            }
                                          />
                                        </td>
                                        <td
                                          style={{
                                            cursor: "pointer",
                                            textDecorationColor: "#0000ff",
                                            textDecoration: "underline",
                                            color: "#0000ff",
                                          }}
                                          data-toggle='modal'
                                          data-target='#table_items'
                                          className='text-center'
                                        >
                                          {/* <Comma value={jQuery('#appliedhom').html()} /> */}
                                          <Comma
                                            value={
                                              this.state
                                                .pmt_applied_home_currency
                                            }
                                          />
                                        </td>
                                      </tr>

                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          {" "}
                                          Exchange Gain/Loss
                                        </td>

                                        <td className='text-center'></td>
                                        <td className='text-center'>
                                          {/* <Comma value={jQuery('#exchangeGain').html()} /> */}
                                          <Comma
                                            value={
                                              this.state
                                                .pmt_exchange_gain_or_loss
                                            }
                                          />
                                        </td>
                                      </tr>

                                      <tr style={{ background: "#fff" }}>
                                        <td className='text-right'>
                                          Balance Due
                                        </td>

                                        <td className='text-center'>
                                          {/* <Comma value={jQuery('#forbaldue').html()} /> */}
                                          <Comma
                                            value={
                                              this.state
                                                .pmt_balance_due_foreign_currency
                                            }
                                          />
                                        </td>
                                        <td className='text-center'>
                                          {/* <Comma value={jQuery('#homebaldue').html()} /> */}
                                          <Comma
                                            value={
                                              this.state
                                                .pmt_balance_due_hom_curency
                                            }
                                          />
                                        </td>
                                      </tr>
                                    </tbody>

                                    {/* for comma separation display none here and displayed below */}
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <div
                            class='modal fade in'
                            id='modal_delete'
                            role='dialog'
                          >
                            <div class='modal-dialog modal-md'>
                              <button
                                type='button'
                                class='close hidden-xs'
                                data-dismiss='modal'
                              >
                                <img
                                  class='img-responsive'
                                  src='../../images/close-red.svg'
                                  alt='icon'
                                />
                              </button>
                              <div class='modal-content'>
                                <div class='modal-body text-center success-modal'>
                                  <div class='pop-icon img-size'>
                                    {
                                      <img
                                        src='../../images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    }
                                  </div>

                                  <h3>Are you sure?</h3>

                                  <p class='fw-500'>
                                    Selected item will be deleted.
                                  </p>
                                  <button
                                    className='btn btn-lightgray btn-align'
                                    data-dismiss='modal'
                                  >
                                    Cancel
                                  </button>
                                  <span>{"   "}</span>
                                  <button
                                    class='btn btn-red btn-align'
                                    type='button'
                                    onClick={this.delete_Rows}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div
                        className='form-group col-md-4'
                        id='footeroptionthanksmessagestatus'
                      >
                        <label>Payment Exchange rate</label>
                        <input
                          type='text'
                          name='inv-no'
                          className='form-control'
                          id=''
                          required
                          placeholder={'0.00'}
                          autoComplete='off'
                          value={Number(this.state.exchange_value_ref).toFixed(
                            2
                          )}
                          disabled={true}
                        />{' '}
                        {this.state.isThanking_msg ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>
                              *Please fill out thanking message.
                            </small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className='form-group col-md-4'
                        id='footeroptionthanksmessagestatus'
                      >
                        <label>Amount</label>
                        <input
                          type='text'
                          name='inv-no'
                          className='form-control'
                          id='amount'
                          required
                          placeholder={'0.00'}
                          autoComplete='off'
                          onChange={e => {
                            if (!isNaN(e.target.value)) {
                              this.setState({ amount_entered: e.target.value })
                            }
                          }}
                        />{' '}
                        {this.state.isThanking_msg ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>
                              *Please fill out thanking message.
                            </small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className='form-group col-md-4'
                        id='footertermsstatus'
                      >
                        <label>Payment Method</label>
                        <select
                          className='selectpicker form-control'
                          data-live-search='true'
                          title='Choose customer'
                          id='payment_method'
                        >
                          {this.state.payment_method_list &&
                            this.state.payment_method_list.map(item => {
                              return (
                                <option value={item.name} data-status={item.id}>
                                  {item.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className='form-group col-md-4'>
                        <label>
                          Payment Date<span className='astrick'>*</span>
                        </label>
                        <div className='input-group date'>
                          <input
                            type='text'
                            autoComplete='off'
                            name='incorport_date'
                            id='fromdate'
                            onBlur={e => this.changeDate(e.target.value)}
                            className='form-control'
                            required
                          />
                          <div className='input-group-addon'>
                            <img src='images/calendar-icon.svg' alt='icon' />
                          </div>
                        </div>
                        
                        <small className='text-red'></small>
                      </div>
                      <div
                        className='form-group col-md-4'
                        id='termsandconditions'
                      >
                        <label>Reference</label>
                        <input
                          type='text'
                          name='inv-no'
                          className='form-control'
                          id='ref'
                          required
                          autoComplete='off'
                        />{' '}
                        {this.state.isTerms_cond_msg ? (
                          <div style={{ float: 'left' }}>
                            <small style={{ color: 'red' }}>
                              *Please fill out terms and conditions.
                            </small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div> */}

                        <div className='row'>
                          <div className='col-md-12 col-xs-12'>
                            <div className='invoice-pay col-md-12 col-xs-12'>
                              <p className='title'>Payment Details</p>
                              <div className='row'>
                                <div className='form-group col-md-4'>
                                  <label>Date of Payment</label>
                                  <div className='input-group date'>
                                    <input
                                      type='text'
                                      autoComplete='off'
                                      name='incorport_date'
                                      id='payment_date'
                                      className='form-control'
                                      required
                                      onBlur={() => {

                                        if(this.state.previous_temp_payment_date != jQuery("#payment_date").val()) {
                                          this.setState({
                                            previous_temp_payment_date:jQuery("#payment_date").val()
                                          });
                                        }

                                        // same function for check lock date
                                        setTimeout(() => {
                                          this.setState({
                                            temp_date_1:
                                              jQuery("#payment_date").val(),
                                          });
                                        }, 500);

                                        setTimeout(() => {
                                          // validate lock date in backend
                                          // if (this.state.lock_date) {
                                          //   if (jQuery("#payment_date").val() && (this.state.previous_temp_payment_date != jQuery("#payment_date").val())) {
                                          //     let date =
                                          //       this.changefromDate_1();
                                          //       var checkedIfCreated = this.state.currpayment_id && this.state.old_payment_date? (this.state.lock_date >= date || (this.state.old_payment_date <= this.state.lock_date && date >= this.state.lock_date)): this.state.lock_date >= date;
                                          //     if (
                                          //       checkedIfCreated
                                          //     ) {
                                          //       jQuery("#payment_date").val("");
                                          //       window
                                          //         .jQuery("#lock_date_1")
                                          //         .modal("show");
                                          //     } else {
                                          //       window
                                          //         .jQuery("#lock_date_1")
                                          //         .modal("hide");

                                          //       setTimeout(() => {
                                          //         let nope;
                                          //         var date_formated;
                                          //         let date =
                                          //           jQuery(
                                          //             "#payment_date"
                                          //           ).val();
                                          //         if (
                                          //           date != undefined &&
                                          //           date != null &&
                                          //           date != ""
                                          //         ) {
                                          //           var array = date.split("/");
                                          //           date_formated =
                                          //             array[2] +
                                          //             "-" +
                                          //             array[1] +
                                          //             "-" +
                                          //             array[0];

                                          //           if (
                                          //             this.state
                                          //               .payment_date_to_check !=
                                          //             date_formated
                                          //           ) {
                                          //             let todayDate =
                                          //               new Date();
                                          //             todayDate.setHours(
                                          //               23,
                                          //               59,
                                          //               59,
                                          //               998
                                          //             );
                                          //             if (
                                          //               new Date(
                                          //                 date_formated
                                          //               ).getTime() >
                                          //               todayDate.getTime()
                                          //             ) {
                                          //               this.setState({
                                          //                 payment_date_to_check:
                                          //                   date_formated,
                                          //               });
                                          //               if (
                                          //                 this.state
                                          //                   .CurrentSelectedCurr ==
                                          //                 this.state
                                          //                   .clientHomeCurrency
                                          //               ) {
                                          //                 Swal.fire(
                                          //                   "You are entering a transaction for Future Date!"
                                          //                 );
                                          //               } else {
                                          //                 Swal.fire(
                                          //                   "You are entering a transaction for Future Date!",
                                          //                   "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Payment"
                                          //                 );
                                          //               }
                                          //               date_formated =
                                          //                 moment().format(
                                          //                   "YYYY-MM-DD"
                                          //                 );
                                          //             }
                                          //           }

                                          //           nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                          //         } else {
                                          //           date_formated =
                                          //             moment().format(
                                          //               "YYYY-MM-DD"
                                          //             );
                                          //           nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                          //         }

                                          //         let res = nope.concat(
                                          //           this.state
                                          //             .CurrentSelectedCurr
                                          //         );
                                          //         fetch(res)
                                          //           .then((response) =>
                                          //             response.json()
                                          //           )
                                          //           .then(async (data) => {
                                          //             if (
                                          //               data.success == true
                                          //             ) {
                                          //               let newObj =
                                          //                 this.rename(
                                          //                   data.quotes,
                                          //                   this.state
                                          //                     .CurrentSelectedCurr
                                          //                 );
                                          //               let currencyAr = [];
                                          //               let first = newObj;
                                          //               currencyAr.push({
                                          //                 date: data.start_date,
                                          //                 rate:
                                          //                   first[
                                          //                     data.start_date
                                          //                   ] &&
                                          //                   first[
                                          //                     data.start_date
                                          //                   ][
                                          //                     this.state
                                          //                       .CurrentSelectedCurr +
                                          //                       this.state
                                          //                         .clientHomeCurrency
                                          //                   ]
                                          //                     ? first[
                                          //                         data
                                          //                           .start_date
                                          //                       ][
                                          //                         this.state
                                          //                           .CurrentSelectedCurr +
                                          //                           this.state
                                          //                             .clientHomeCurrency
                                          //                       ].toFixed(4)
                                          //                     : parseFloat(
                                          //                         1
                                          //                       ).toFixed(4),
                                          //               });
                                          //               let value =
                                          //                 currencyAr[0].rate;
                                          //               jQuery(
                                          //                 "#payment_exchange_rate"
                                          //               ).val(value);
                                          //             }
                                          //           })
                                          //           .catch((err) => {
                                          //             console.log(err);
                                          //           });
                                          //       }, 200);
                                          //     }
                                          //   }
                                          // } else {
                                            setTimeout(() => {
                                              let nope;
                                              var date_formated;
                                              let date =
                                                jQuery("#payment_date").val();
                                              if (
                                                date != undefined &&
                                                date != null &&
                                                date != ""
                                              ) {
                                                var array = date.split("/");
                                                date_formated =
                                                  array[2] +
                                                  "-" +
                                                  array[1] +
                                                  "-" +
                                                  array[0];

                                                if (
                                                  this.state
                                                    .payment_date_to_check !=
                                                  date_formated
                                                ) {
                                                  let todayDate = new Date();
                                                  todayDate.setHours(
                                                    23,
                                                    59,
                                                    59,
                                                    998
                                                  );
                                                  if (
                                                    new Date(
                                                      date_formated
                                                    ).getTime() >
                                                    todayDate.getTime()
                                                  ) {
                                                    this.setState({
                                                      payment_date_to_check:
                                                        date_formated,
                                                    });
                                                    if (
                                                      this.state
                                                        .CurrentSelectedCurr ==
                                                      this.state
                                                        .clientHomeCurrency
                                                    ) {
                                                      Swal.fire(
                                                        "You are entering a transaction for Future Date!"
                                                      );
                                                    } else {
                                                      Swal.fire(
                                                        "You are entering a transaction for Future Date!",
                                                        "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Payment"
                                                      );
                                                    }
                                                    date_formated =
                                                      moment().format(
                                                        "YYYY-MM-DD"
                                                      );
                                                  }
                                                }

                                                nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                              } else {
                                                date_formated =
                                                  moment().format("YYYY-MM-DD");
                                                nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
                                              }

                                              let res = nope.concat(
                                                this.state.CurrentSelectedCurr
                                              );
                                              fetch(res)
                                                .then((response) =>
                                                  response.json()
                                                )
                                                .then(async (data) => {
                                                  if (data.success == true) {
                                                    let newObj = this.rename(
                                                      data.quotes,
                                                      this.state
                                                        .CurrentSelectedCurr
                                                    );
                                                    let currencyAr = [];
                                                    let first = newObj;
                                                    currencyAr.push({
                                                      date: data.start_date,
                                                      // 'rate': isNaN(first[data.start_date][this.state.CurrentSelectedCurr + this.state.clientHomeCurrency]) ? 0.0000 : (first[data.start_date][this.state.CurrentSelectedCurr + this.state.clientHomeCurrency]).toFixed(4)
                                                      rate:
                                                        first[
                                                          data.start_date
                                                        ] &&
                                                        first[data.start_date][
                                                          this.state
                                                            .CurrentSelectedCurr +
                                                            this.state
                                                              .clientHomeCurrency
                                                        ]
                                                          ? first[
                                                              data.start_date
                                                            ][
                                                              this.state
                                                                .CurrentSelectedCurr +
                                                                this.state
                                                                  .clientHomeCurrency
                                                            ].toFixed(4)
                                                          : parseFloat(
                                                              1
                                                            ).toFixed(4),
                                                    });
                                                    let value =
                                                      currencyAr[0].rate;
                                                    jQuery(
                                                      "#payment_exchange_rate"
                                                    ).val(value);
                                                  }
                                                })
                                                .catch((err) => {
                                                  console.log(err);
                                                });
                                            }, 200);
                                          // }
                                        }, 1000);
                                        // same function for check lock date
                                      }}
                                    />
                                    <div
                                      className='input-group-addon'
                                      onClick={() =>
                                        jQuery("#payment_date").focus()
                                      }
                                    >
                                      <img
                                        src='images/calendar-icon.svg'
                                        alt='icon'
                                      />
                                    </div>
                                  </div>
                                  {this.state.isPaymetDate ? (
                                    <div>
                                      <small style={{ color: "red" }}>
                                        *Required.
                                      </small>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className='form-group exchange-col col-md-4 mar-b-no'>
                                  <label>Amount of Payment</label>
                                  <div className='w-100'>
                                    <NumberFormat
                                      name='payment_amount'
                                      // className="form-control text-right"
                                      className='form-control'
                                      id='payable_amount'
                                      required
                                      placeholder={"0.00"}
                                      autoComplete='off'
                                      value={this.state.amount_entered}
                                      thousandSeparator={true}
                                      onValueChange={(values) => {
                                        let pre_amt = jQuery("#payable_amount")
                                          .val()
                                          .replace(/,/g, "");
                                        //  let amount = e.target.value.replace(/,/g, '')
                                        // let previous_amt = jQuery('#payable_amount').value = '' ?  jQuery('#payable_amount').value = '' :
                                        // if (!isNaN(e.target.value.replace(/,/g, ''))) {

                                        if (pre_amt && pre_amt > 0) {
                                          // }
                                          // else {

                                          // console.log('33333333', pre_amt)
                                          // console.log('333333331', amount)
                                          if (this.state.isInvoiceEditable) {
                                            // to remove comma from amount
                                            let a = jQuery("#forbaldue").html();
                                            let b = a.split(",");
                                            let c = Number(b.join(""));
                                            // to remove comma from amount

                                            if (!isNaN(c)) {
                                              // if (c >= Number(amount)) {
                                              if (c >= Number(pre_amt)) {
                                                this.setState({
                                                  // amount_entered: e.target.value.replace(/,/g, ''),
                                                  amount_entered: pre_amt,
                                                });
                                              } else {
                                                alert(
                                                  "you cannot pay more than amount due..!"
                                                );
                                                jQuery("#payable_amount").val(
                                                  this.state.amount_entered
                                                );
                                              }
                                            }
                                          }
                                        }
                                        if (!this.state.isInvoiceEditable) {
                                          if (
                                            pre_amt != "" &&
                                            pre_amt &&
                                            pre_amt > 0
                                          ) {
                                            // }
                                            // else {
                                            // console.log('333333331', jQuery("#home_grand_total").html())
                                            // console.log('3333333312', Number(jQuery("#home_grand_total").html()))

                                            // to remove comma from amount
                                            let a =
                                              jQuery(
                                                "#home_grand_total"
                                              ).html();
                                            let b = a.split(",");
                                            let c = Number(b.join(""));
                                            // to remove comma from amount

                                            if (!isNaN(c)) {
                                              // if (c >= Number(amount)) {
                                              if (c >= Number(pre_amt)) {
                                                this.setState({
                                                  // amount_entered: e.target.value.replace(/,/g, ''),
                                                  amount_entered: pre_amt,
                                                });
                                              } else {
                                                alert(
                                                  "you cannot pay more than amount due...!"
                                                );
                                                jQuery("#payable_amount").val(
                                                  this.state.amount_entered
                                                );
                                              }
                                            }
                                          }
                                        }
                                        // already present code
                                        // jQuery("#payment_exchange_rate").val(
                                        //   isNaN(
                                        //     Number(this.state.exchange_value_ref)
                                        //   )
                                        //     ? ""
                                        //     : Number(
                                        //       this.state.exchange_value_ref
                                        //     )
                                        // );
                                        // already present code

                                        // my code

                                        // my code

                                        // }
                                      }}
                                    />
                                    {/* <input
                                    type="number"
                                    name="inv-no"
                                    className="form-control text-right"
                                    id="payable_amount"
                                    required
                                    placeholder={"0.00"}
                                    autoComplete="off"
                                    // max='100'
                                    // max={this.state.isInvoiceEditable ? Number(jQuery("#forbaldue").html()) : Number(jQuery("#home_grand_total").html())}
                                    onChange={async (e) => { 
                                      let pre_amt = jQuery('#payable_amount').val()
                                      let amount = e.target.value
                                      // let previous_amt = jQuery('#payable_amount').value = '' ?  jQuery('#payable_amount').value = '' : 
                                      if (!isNaN(e.target.value)) {
                                       
                                        if(pre_amt && pre_amt>0){

                                        }
                                        else{
                                        
                                        // console.log('33333333', pre_amt)
                                        // console.log('333333331', amount)
                                        if (this.state.isInvoiceEditable) {

                                          // to remove comma from amount 
                                          let a = jQuery("#forbaldue").html()
                                          let b = a.split(',')
                                          let c = Number(b.join(''))
                                          // to remove comma from amount 

                                          if (!isNaN(c)) {
                                            if (c >= Number(amount)) {
                                              this.setState({
                                                amount_entered: e.target.value,
                                              })
                                            } else {
                                              alert('you cannot pay more than amount due')
                                              jQuery('#payable_amount').val(this.state.amount_entered)
                                            }
                                          }
                                        }
                                        }
                                        if (!this.state.isInvoiceEditable) {

                                          if(pre_amt && pre_amt>0){

                                          }
                                          else{
                                          // console.log('333333331', jQuery("#home_grand_total").html())
                                          // console.log('3333333312', Number(jQuery("#home_grand_total").html()))

                                          // to remove comma from amount 
                                          let a = jQuery("#home_grand_total").html()
                                          let b = a.split(',')
                                          let c = Number(b.join(''))
                                          // to remove comma from amount 

                                          if (!isNaN(c)) {
                                            if (c >= Number(amount)) {
                                              this.setState({
                                                amount_entered: e.target.value,
                                              })
                                            } else {
                                              alert('you cannot pay more than amount due')
                                              jQuery('#payable_amount').val(this.state.amount_entered)
                                            }
                                          }
                                        }
                                      }
                                        // already present code
                                        // jQuery("#payment_exchange_rate").val(
                                        //   isNaN(
                                        //     Number(this.state.exchange_value_ref)
                                        //   )
                                        //     ? ""
                                        //     : Number(
                                        //       this.state.exchange_value_ref
                                        //     )
                                        // );
                                        // already present code

                                        // my code

                                        // my code


                                      }
                                    }}
                                  /> */}
                                    {/* label no need here */}
                                    {/* <span className="label">
                                      {this.state.clientHomeCurrency}
                                    </span> */}
                                  </div>
                                  {this.state.isPayable_amount ? (
                                    <div>
                                      <small style={{ color: "red" }}>
                                        *Required.
                                      </small>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className='form-group col-md-4'>
                                  <label>Mode of Payment</label>

                                  <select
                                    className='selectpicker form-control add-new'
                                    data-live-search='true'
                                    title='Choose...'
                                    id='payment_method'
                                    value={this.state.pay_method}
                                    onChange={(e) => {
                                      if (e.target.value == "1qw") {
                                        window
                                          .jQuery("#add_new_payment")
                                          .modal("show");
                                        jQuery("#payment_method option")
                                          .prop("selected", false)
                                          .trigger("change");
                                      } else {
                                        this.setState({
                                          pay_method: e.target.value,
                                        });
                                      }
                                    }}
                                  >
                                    <option value='1qw'>Add new</option>
                                    {this.state.payment_method_list &&
                                      this.state.payment_method_list.map(
                                        (item) => {
                                          return (
                                            <option
                                              value={item.name}
                                              data-status={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                  {this.state.isPaymentMethod ? (
                                    <div>
                                      <small style={{ color: "red" }}>
                                        *Required.
                                      </small>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group col-md-4 mar-b-no'>
                                  <label>Payment Reference</label>
                                  <input
                                    type='text'
                                    name='payment_ref'
                                    className='form-control'
                                    id='reference'
                                    required
                                    autoComplete='off'
                                  />{" "}
                                </div>
                                <div className='form-group col-md-4 mar-b-no'>
                                  <label>Description</label>
                                  <input
                                    type='text'
                                    name='payment_desc'
                                    className='form-control'
                                    value={this.state.payment_desc}
                                    id='desc_pay'
                                    required
                                    onChange={(e) => this.desChange(e)}
                                    autoComplete='off'
                                  />
                                </div>
                                <div className='form-group exchange-col col-md-4 mar-b-no'>
                                  <label>
                                    Exchange Rate 1{" "}
                                    {this.state.CurrentSelectedCurr}
                                  </label>
                                  <div className='w-100'>
                                    <input
                                      type='text'
                                      name='payment_exchange_rate'
                                      id='payment_exchange_rate'
                                      className='form-control text-right'
                                      required
                                      autoComplete='off'
                                      // defaultValue={Number(this.state.exchange_value_ref).toFixed(2)}
                                      // disabled={true}
                                    />{" "}
                                    <span className='label'>
                                      {this.state.clientHomeCurrency}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group col-md-4  '>
                                  <label>Payment Account</label>
                                  <select
                                    className='selectpicker form-control add-new'
                                    data-live-search='true'
                                    title='Choose '
                                    value={this.state.cate_id}
                                    // value={this.state.pay_acc}
                                    id='balanceSheetCategeory'
                                    onChange={(e) => {
                                      if (e.target.value == "1e") {
                                        jQuery("#balanceSheetCategeory option")
                                          .prop("selected", false)
                                          .trigger("change");
                                        jQuery("#balanceSheetCategeory").val(
                                          ""
                                        );
                                        window
                                          .jQuery("#pop-modal")
                                          .modal("show");
                                      } else {
                                        this.state.balancesheetlist.forEach(
                                          (item, i) => {
                                            if (item.id == e.target.value) {
                                              // console.log("checkingitem", item);
                                              const string = item.name;
                                              const Payable =
                                                string.includes("ayable");
                                              const Receivable =
                                                string.includes("eceivable");
                                              if (
                                                item.account_type_id == "5" ||
                                                item.account_type_id == "2"
                                              ) {
                                                this.setState({
                                                  isThirdPartyName: true,
                                                });
                                                if (
                                                  item.account_type_id == "5"
                                                ) {
                                                  this.setState({
                                                    thrird_party_type: 2,
                                                  });
                                                  this.fetchThirdPartyNames(5);
                                                }
                                                if (
                                                  item.account_type_id == "2"
                                                ) {
                                                  this.setState({
                                                    thrird_party_type: 1,
                                                  });

                                                  this.fetchThirdPartyNames(2);
                                                }
                                              } else {
                                                this.setState({
                                                  isThirdPartyName: false,
                                                });
                                              }
                                            }
                                          }
                                        );

                                        //  const matchedList= this.state.balancesheetlist.filter(item=>item[e.target.value]==e.target.value)
                                      }
                                    }}
                                  >
                                    <option value='1e'> Add New </option>
                                    {this.state.balancesheetlist &&
                                      this.state.balancesheetlist.map(
                                        (item) => {
                                          // console.log("fee", item.name);
                                          return (
                                            <option
                                              value={item.id}
                                              data-status={item.name}
                                              disabled={
                                                item.status == 2 ? true : false
                                              }
                                              style={{
                                                display:
                                                  item.status == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                  {this.state.isbalanceSheetcatId ? (
                                    <div>
                                      <small style={{ color: "red" }}>
                                        *Required.
                                      </small>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {this.state.isThirdPartyName && (
                                  <div className='form-group col-md-4'>
                                    <label>Third Party Account Name</label>
                                    <select
                                      className='selectpicker form-control add-new'
                                      data-live-search='true'
                                      title='Choose...'
                                      id='third_account_id'
                                      value={this.state.third_party_value}
                                      onChange={(e) => {
                                        this.setState({
                                          third_party_value: e.target.value,
                                        });
                                        // alert( jQuery("#third_account_id").val())
                                        if (
                                          jQuery("#third_account_id").val() ==
                                          "new"
                                        ) {
                                          // console.log(
                                          //   "1111",
                                          //   this.state.thrird_party_type
                                          // );
                                          if (
                                            this.state.thrird_party_type == 1
                                          ) {
                                            localStorage.setItem(
                                              "comes_from",
                                              "third_party"
                                            );
                                            let win = window.open(
                                              "/add-new-customer",
                                              "_blank"
                                            );
                                            win.focus();
                                          }
                                          if (
                                            this.state.thrird_party_type == 2
                                          ) {
                                            localStorage.setItem(
                                              "comes_from",
                                              "third_party"
                                            );
                                            let win = window.open(
                                              "/add_new_vendor",
                                              "_blank"
                                            );
                                            win.focus();
                                          }
                                          // alert( 'in')
                                        }
                                      }}
                                    >
                                      {/* <option>Create New </option> */}
                                      {/* <option value="new">Add New</option> */}
                                      <option value='new'>Add New</option>
                                      {this.state.third_party_account_list &&
                                        this.state.third_party_account_list.map(
                                          (item) => {
                                            return (
                                              <option
                                                value={item.id}
                                                data-status={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    {this.state.is3partyAccRequired ? (
                                      <div>
                                        <small style={{ color: "red" }}>
                                          *Required.
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>

                              {/* <div className='row'>
{this.state.isThirdPartyName && (
                          <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                            <label>Third Party Account Name</label>
                            <select
                              className='selectpicker form-control add-new'
                              data-live-search='true'
                              title='Choose...'
                              id='third_account_id'
                            >
                              <option>Create New </option>
                              {this.state.third_party_account_list &&
                                this.state.third_party_account_list.map(
                                  item => {
                                    return (
                                      <option
                                        value={item.id}
                                        data-status={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    )
                                  }
                                )}
                            </select>
                          </div>
                        )}

</div>

                      
                      
                       */}
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          {this.state.customFieldB &&
                            this.state.customFieldB.map((item, i) => {
                              return (
                                <>
                                  {item.type == "text" && (
                                    <div
                                      className='form-group col-md-4'
                                      // id="itemoptioninvoicenostatus"
                                    >
                                      <label>{item.name}</label>
                                      <input
                                        type='text'
                                        name='inv-no'
                                        className='form-control'
                                        value={this.state[`customFieldB${i}`]}
                                        required
                                        autoComplete='off'
                                        onChange={(e) => {
                                          this.setState({
                                            [`customFieldB${i}`]:
                                              e.target.value,
                                          });
                                          setTimeout(() => {
                                            this.customValues();
                                          }, 500);
                                        }}
                                      />{" "}
                                    </div>
                                  )}
                                  {item.type == "date" && (
                                    <div className='form-group col-md-4'>
                                      <label>
                                        {item.name}
                                        <span className='astrick'>*</span>
                                      </label>
                                      <div className='input-group date mar-t-no'>
                                        <input
                                          type='text'
                                          autoComplete='off'
                                          value={this.state[`customField${i}`]}
                                          id={`customFieldB${i}`}
                                          onBlur={() => {
                                            setTimeout(() => {
                                              //   alert(`#customFieldB${i}`)
                                              //  alert(jQuery(`#customFieldB${i}`).val())
                                              let a = jQuery(
                                                `#customFieldB${i}`
                                              ).val();
                                              this.setState(
                                                { [`customFieldB${i}`]: a },
                                                () => this.customValues()
                                              );
                                            }, 1000);
                                          }}
                                          // value={this.state[`customFieldB${i}`]}
                                          // onChange={e => {
                                          //   this.setState({ [`customFieldB${i}`]: e.target.value })
                                          //   setTimeout(() => {
                                          //     this.customValues()
                                          //   }, 500);
                                          // }
                                          // }
                                          className='form-control'
                                          required
                                        />
                                        <div
                                          className='input-group-addon'
                                          onClick={() =>
                                            jQuery(`#customFieldB${i}`).focus()
                                          }
                                        >
                                          <img
                                            src='images/calendar-icon.svg'
                                            alt='icon'
                                          />
                                        </div>
                                      </div>
                                      <small className='text-red'></small>
                                    </div>
                                  )}
                                  {item.type == "dropDown" && (
                                    <div className='form-group col-lg-4 col-md-6 col-sm-6 col-xs-12'>
                                      <label>{item.name}</label>

                                      <select
                                        className='selectpicker form-control add-new'
                                        data-live-search='true'
                                        title='Choose...'
                                        value={this.state[`customFieldB${i}`]}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (value == "new") {
                                            window
                                              .jQuery("#custom_column_options1")
                                              .modal("show");
                                            this.setState({ idx: i });
                                          } else {
                                            this.setState({
                                              [`customFieldB${i}`]: value,
                                            });
                                            setTimeout(() => {
                                              this.customValues();
                                            }, 500);
                                          }
                                        }}
                                      >
                                        <option value='new'>
                                          {" "}
                                          Create New{" "}
                                        </option>
                                        {this.state.customFieldB[i].options &&
                                          this.state.customFieldB[
                                            i
                                          ].options.map((item) => {
                                            return (
                                              <option value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>

                        <div className='row'>
                          <div
                            className='form-group col-md-4'
                            id='termsandconditions'
                          >
                            <label>Amount In Words</label>
                            <textarea
                              type='text'
                              name='amount_in_words'
                              className='form-control'
                              id='amount_in_words'
                              required
                              autoComplete='off'
                              disabled='true'
                              defaultValue={`${toWords.convert(0)} only`}
                            />{" "}
                            {this.state.isTerms_cond_msg ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div
                            className='form-group col-md-4'
                            id='footeroptionthanksmessagestatus'
                          >
                            <label>Thank you message and Banking details</label>
                            {/* <textarea
                              id="footeroptionthanksmessages"
                              className="form-control"
                              defaultValue={""}
                              required
                            />{" "} */}
                            <Editor
                              editorState={thankingMessageEditorState}
                              defaultEditorState={thankingMessageEditorState}
                              wrapperClassName='demo-wrapper'
                              editorClassName='demo-editor'
                              onEditorStateChange={
                                this.onThankingMessageEditorStateChange
                              }
                              toolbarHidden
                              toolbar={{options: [], image: {
                              alignmentEnabled: false,
                              urlEnabled: false,
                              uploadEnabled: false,
                            }}}
                              editorStyle={{
                                "border-radius": "4px",
                                border: "1px solid #e2e2e2",
                                "min-height": "100px",
                                padding: "0px 6px",
                              }}
                            />
                            {this.state.isThanking_msg ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className='form-group col-md-4'
                            id='termsandconditions'
                          >
                            <label>Terms &amp; Conditions</label>
                            {/* <textarea
                              id="termcondmsg"
                              className="form-control"
                              defaultValue={""}
                              required
                            /> */}

                            <Editor
                              editorState={editorState}
                              defaultEditorState={editorState}
                              wrapperClassName='demo-wrapper'
                              editorClassName='demo-editor'
                              onEditorStateChange={this.onEditorStateChange}
                              toolbarHidden
                              toolbar={{options: [], image: {
                              alignmentEnabled: false,
                              urlEnabled: false,
                              uploadEnabled: false,
                            }}}
                              editorStyle={{
                                "border-radius": "4px",
                                border: "1px solid #e2e2e2",
                                "min-height": "100px",
                                padding: "0px 6px",
                              }}
                            />

                            {this.state.isTerms_cond_msg ? (
                              <div>
                                <small style={{ color: "red" }}>
                                  *Required.
                                </small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* Modal Wrapper Starts here */}
                        <div
                          className='modal pop-modal fade'
                          id='editCol'
                          role='dialog'
                          ata-backdrop='static'
                        >
                          <div className='modal-dialog modal-md'>
                            {/* Modal content*/}
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                            >
                              <img
                                className='img-responsive'
                                src='images/close-red.svg'
                                alt='icon'
                              />
                            </button>

                            {/* <CoulmnRearrage/> */}

                            {/* <div className='modal-content'>
                        <div className='modal-body'>
                          <h3>Edit Column</h3>
                          <div className='sortable-enclose row'>
                            <div className='col-md-12'>
                              <div className='row-head col-md-12 col-xs-12'>
                                <div className='col-sm-10 col-xs-8'>
                                  Column Label
                                </div>
                                <div className='col-sm-2 col-xs-4 text-center'>
                                  Show
                                </div>
                              </div>
                              <div className='row-body col-md-12 col-xs-12'>
                                <div className='row-item row'>
                                  <div className='col-sm-10 col-xs-8'>
                                    <span className='drag-icon'>
                                      <img
                                        src='images/dots-menu.svg'
                                        alt='icon'
                                      />
                                    </span>
                                    <div className='custom-col-lab'>
                                      <span contentEditable='true'>
                                        Custom Column
                                      </span>
                                      <div className='pull-right'>
                                        <button className='btn'>
                                          <img
                                            src='images/tick-green.svg'
                                            alt='icon'
                                          />
                                        </button>
                                        <button className='btn'>
                                          <img
                                            src='images/cross-red.svg'
                                            alt='icon'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 col-xs-4 text-center'>
                                    <label className='custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        name
                                        defaultChecked='checked'
                                      />
                                      <span className='checkmark' />
                                    </label>
                                    <a
                                      href='javascript:;'
                                      className='del-row'
                                    >
                                      <img
                                        className='img-responsive'
                                        src='images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className='row-item row'>
                                  <div className='col-sm-10 col-xs-8'>
                                    <span className='drag-icon'>
                                      <img
                                        src='images/dots-menu.svg'
                                        alt='icon'
                                      />
                                    </span>
                                    <div className='custom-col-lab'>
                                      <span contentEditable='true'>
                                        Custom Column One
                                      </span>
                                      <div className='pull-right'>
                                        <button className='btn'>
                                          <img
                                            src='images/tick-green.svg'
                                            alt='icon'
                                          />
                                        </button>
                                        <button className='btn'>
                                          <img
                                            src='images/cross-red.svg'
                                            alt='icon'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 col-xs-4 text-center'>
                                    <label className='custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        name
                                        defaultChecked='checked'
                                      />
                                      <span className='checkmark' />
                                    </label>
                                    <a
                                      href='javascript:;'
                                      className='del-row'
                                    >
                                      <img
                                        className='img-responsive'
                                        src='images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className='row-item row'>
                                  <div className='col-sm-10 col-xs-8'>
                                    <span className='drag-icon'>
                                      <img
                                        src='images/dots-menu.svg'
                                        alt='icon'
                                      />
                                    </span>
                                    <div className='custom-col-lab'>
                                      <span contentEditable='true'>
                                        Custom Column Two
                                      </span>
                                      <div className='pull-right'>
                                        <button className='btn'>
                                          <img
                                            src='images/tick-green.svg'
                                            alt='icon'
                                          />
                                        </button>
                                        <button className='btn'>
                                          <img
                                            src='images/cross-red.svg'
                                            alt='icon'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 col-xs-4 text-center'>
                                    <label className='custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        name
                                        defaultChecked='checked'
                                      />
                                      <span className='checkmark' />
                                    </label>
                                    <a
                                      href='javascript:;'
                                      className='del-row'
                                    >
                                      <img
                                        className='img-responsive'
                                        src='images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className='row-item row'>
                                  <div className='col-sm-10 col-xs-8'>
                                    <span className='drag-icon'>
                                      <img
                                        src='images/dots-menu.svg'
                                        alt='icon'
                                      />
                                    </span>
                                    <div className='custom-col-lab'>
                                      <span contentEditable='true'>
                                        Custom Column Three
                                      </span>
                                      <div className='pull-right'>
                                        <button className='btn'>
                                          <img
                                            src='images/tick-green.svg'
                                            alt='icon'
                                          />
                                        </button>
                                        <button className='btn'>
                                          <img
                                            src='images/cross-red.svg'
                                            alt='icon'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 col-xs-4 text-center'>
                                    <label className='custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        name
                                        defaultChecked='checked'
                                      />
                                      <span className='checkmark' />
                                    </label>
                                    <a
                                      href='javascript:;'
                                      className='del-row'
                                    >
                                      <img
                                        className='img-responsive'
                                        src='images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className='row-item row'>
                                  <div className='col-sm-10 col-xs-8'>
                                    <span className='drag-icon'>
                                      <img
                                        src='images/dots-menu.svg'
                                        alt='icon'
                                      />
                                    </span>
                                    <div className='custom-col-lab'>
                                      <span contentEditable='true'>
                                        Custom Column Four
                                      </span>
                                      <div className='pull-right'>
                                        <button className='btn'>
                                          <img
                                            src='images/tick-green.svg'
                                            alt='icon'
                                          />
                                        </button>
                                        <button className='btn'>
                                          <img
                                            src='images/cross-red.svg'
                                            alt='icon'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 col-xs-4 text-center'>
                                    <label className='custom-checkbox'>
                                      <input
                                        type='checkbox'
                                        name
                                        defaultChecked='checked'
                                      />
                                      <span className='checkmark' />
                                    </label>
                                    <a
                                      href='javascript:;'
                                      className='del-row'
                                    >
                                      <img
                                        className='img-responsive'
                                        src='images/delete-icon.svg'
                                        alt='icon'
                                      />
                                    </a>
                                  </div>
                                </div>
                             
                             
                             
                              </div>
                            </div>
                      
                      
                          </div>
                          <div className='btn-sec pad-no mar-no text-center'>
                            <hr />
                            <button className='btn btn-lightgray'>
                              Cancel
                            </button>
                            <button className='btn btn-green'>Update</button>
                          </div>
                        </div>
                      
                      
                      </div>
                   
                    */}
                          </div>
                        </div>

                        <div
                          className='modal fade pop-modal'
                          id='pop-modal-2'
                          role='dialog'
                          ata-backdrop='static'
                        >
                          <div className='modal-dialog modal-md custom-modal'>
                            <button
                              type='button'
                              className='close hidden-xs'
                              data-dismiss='modal'
                              onClick={this.cancel_gst_modal}
                            >
                              <img
                                className='img-responsive'
                                src='../../images/close-red.svg'
                                alt='icon'
                              />
                            </button>
                            <div className='modal-content'>
                              <div className='modal-body text-center'>
                                <h3>Add New Column</h3>
                                <form className='custom-form row'>
                                  <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <label>Type Of the Editable Field</label>
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                      <label className='custom-checkbox radio mar-rgt taxable'>
                                        <input
                                          type='radio'
                                          name='editableField'
                                          value='textField'
                                          checked={
                                            this.state.selectedColumnType ===
                                            "textField"
                                          }
                                          onChange={
                                            THIS.typeOfColumnTobeModified
                                          }
                                        />
                                        Text
                                        <span className='checkmark'></span>
                                      </label>
                                      <label className='custom-checkbox radio non-taxable'>
                                        <input
                                          type='radio'
                                          name='editableField'
                                          value='dropDownField'
                                          checked={
                                            this.state.selectedColumnType ===
                                            "dropDownField"
                                          }
                                          onChange={
                                            THIS.typeOfColumnTobeModified
                                          }
                                        />{" "}
                                        Drop Down
                                        <span className='checkmark'></span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <label>Coulmn Name</label>
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                      <input
                                        autoComplete='off'
                                        type='text'
                                        className='form-control'
                                        id='coulmn_name'
                                      />
                                    </div>
                                  </div>

                                  {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}
                                  <small
                                    style={{ color: "red" }}
                                    className='mymsg'
                                  >
                                    {this.state.modal_info_msg}{" "}
                                  </small>

                                  <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                                    {this.state.show_succes ? (
                                      <div className='alert alert-success'>
                                        <strong>Success!</strong> Your new GST
                                        is added.
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      className='btn btn-lightgray btn-align'
                                      data-dismiss='modal'
                                      onClick={this.modal_cancel}
                                    >
                                      Cancel
                                    </button>
                                    <span>{"   "}</span>
                                    <button
                                      className='btn btn-green btn-align'
                                      type='button'
                                      onClick={THIS.add_coulmn}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='pf-btm-wrap'>
                          <div className='col-md-6 col-sm-6 col-xs-12 pad-no'>
                            <button
                              className='btn btn-empty ico'
                              // onClick={this.convertHtmlToPdf.bind(this)}
                              type="button"
                              onClick={() =>    window
                                .jQuery("#attachment_modal")
                                .modal("show")}
                            >
                              <img src='images/attach-icon.svg' alt='icon' />
                              Attachment <span className='blue-txt'>{this.state.list_of_attachments.length}</span>
                            </button>
                            {this.state.isInvoiceEditable && (
                              <button
                                className='btn btn-empty ico'
                                // onClick={this.convertHtmlToPdf.bind(this)}
                                onClick={() => this.saveAsPdf()}
                              >
                                <img src='images/print-icon.svg' alt='icon' />
                                Print
                              </button>
                            )}

                            {/* {this.state.isInvoiceEditable && <button
                            type="button"
                            className="btn btn-empty ico"
                            // onClick={this.convertHtmlToPdf.bind(this)}
                            onClick={() => this.saveAsPdf()}
                          >
                            <img src="images/pdf-icon.svg" alt="icon" />
                            Save as PDF
                          </button>} */}

                            {this.state.isInvoiceEditable && (
                              <button
                                type='button'
                                className='btn btn-empty ico'
                                // onClick={this.convertHtmlToPdf.bind(this)}
                                onClick={() => this.frontPrint("frontprint")}
                              >
                                <img src='images/pdf-icon.svg' alt='icon' />
                                {/* Front Print */}
                                Save as PDF
                              </button>
                            )}
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12 text-right pad-no'>
                            {this.state.isInvoiceEditable && (
                              <>
                                <button
                                  className='btn btn-green dropdown-toggle btn useDRP mar-rgt-5'
                                  type='button'
                                  data-toggle='dropdown'
                                >
                                  Action
                                  <span className='caret' />
                                </button>
                                <ul className='dropdown-menu category'>
                                  <li>
                                    <a
                                      onClick={() => {
                                        this.autoChangeMemo("copy");
                                      }}
                                    >
                                      Create a copy
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle='modal'
                                      data-target='#modal_delete_invoice'
                                      //  onClick={
                                      //   () => {

                                      //   // this.delete_or_void_sales_invoice(10)
                                      // }

                                      // }
                                    >
                                      Delete
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle='modal'
                                      data-target='#modal_void_invoice'
                                      // onClick={() => this.delete_or_void_sales_invoice(11)}
                                    >
                                      Void
                                    </a>
                                  </li>

                                  {this.state.currpayment_id ? (
                                    <li>
                                      <a
                                        data-toggle='modal'
                                        data-target='#modal_void_invoice_payment'
                                      >
                                        Void Payment
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li>
                                    <a onClick={() => this.apply_credit()}>
                                      Apply credit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() => {
                                        this.autoChangeMemo("memo");
                                      }}
                                    >
                                      Sales credit
                                    </a>
                                  </li>
                                </ul>
                                <button
                                  className='btn btn-green'
                                  type='button'
                                  onClick={() => {
                                    this.frontPrint("email");
                                    // window.close()
                                    // this.props.history.push("/invoice_templates");
                                  }}
                                >
                                  Mail
                                </button>
                              </>
                            )}
                            <button
                              className='btn btn-lightgray mar-rgt-5 btn-align'
                              type='button'
                              onClick={() => {
                                localStorage.setItem("page_select", "Invoice");
                                this.props.history.push("/loading", [
                                  "/all_lists",
                                ]);
                              }}
                            >
                              Close
                            </button>

                            <button
                              className='btn btn-yellow btn-align'
                              type='button'
                              onClick={(e) =>
                                this.save_template(e, "saveandnew")
                              }
                            >
                              Save &amp; New
                            </button>
                            {"  "}

                            {"  "}
                            <input
                              className='btn btn-green btn-align'
                              type='button'
                              value='Save'
                              onClick={(e) => this.save_template(e, "save")}
                            />
                          </div>
                        </div>
                        {/* <div id='data_result1'>
                    {HTMLReactParser(this.state.html_content)}
                  </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='modal fade pop-modal'
              id='add_new_payment'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                  onClick={() => {
                    this.setState({ roleStringLen: false });
                  }}
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Add Options</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Options</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            id='pay'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                          onClick={() => {
                            this.setState({ roleStringLen: false });
                          }}
                        >
                          Cancel
                        </button>
                        <span>{"   "}</span>
                        <input type='hidden' id='colid' />

                        <button
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={() => {
                            if (
                              jQuery("#pay").val() != "" &&
                              jQuery("#pay").val() != undefined
                            ) {
                              var coreData = {
                                name: jQuery("#pay").val(),
                                client_id: this.state.logged_client_id,
                              };

                              FetchAllApi.create_paymenttype(
                                coreData,
                                (err, response) => {
                                  // console.log("new document", response.message);
                                  // alert(response.message)
                                  if (response.status === 1) {
                                    this.setState({
                                      pay_method: response.name,
                                    });
                                    this.getPaymethod();
                                    jQuery("#pay").val("");
                                    window
                                      .jQuery("#add_new_payment")
                                      .modal("hide");
                                    //   this.setState({ items: response.list[0].columns })
                                  } else {
                                  }
                                }
                              );
                            } else {
                              alert("Please fill out....");
                            }

                            // FetchAllApi.invoiceadd_dropdown_options(
                            //   userId,
                            //   coulmnId,
                            //   optionsArray,
                            //   (err, response) => {
                            //     console.log('vendor_names', response)

                            //     if (response.status === 1) {
                            //       alert('success')
                            //       this.getColList()
                            //       window.jQuery('#add_new_role').modal('hide')
                            //     } else {
                            //     }
                            //   }
                            // )
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='modal fade pop-modal'
              id='custom_column_options'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Add Options</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Options</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            placeholder='Enter options seperate by comma'
                            value={this.state.addedOptions}
                            onChange={(e) =>
                              this.setState({ addedOptions: e.target.value })
                            }
                          />
                          <div></div>{" "}
                        </div>
                      </div>
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Cancel
                        </button>
                        <span>{"   "}</span>
                        <button
                          data-dismiss='modal'
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={(e) => {
                            let value = this.state.addedOptions;
                            let valueArray = value.split(",");
                            let customField = this.state.customField;
                            valueArray.map((item, l) => {
                              if (
                                customField[this.state.idx].options.length != 0
                              ) {
                                let duplicatecustomary = customField[
                                  this.state.idx
                                ].options.map((customname) =>
                                  customname.toLowerCase()
                                );
                                if (
                                  duplicatecustomary.indexOf(
                                    item.toLowerCase()
                                  ) >= 0
                                ) {
                                  alert(
                                    "Options " + item + " already available."
                                  );
                                } else {
                                  customField[this.state.idx].options.push(
                                    item
                                  );
                                }
                              } else {
                                customField[this.state.idx].options.push(item);
                              }
                            });
                            this.setState({ customField, addedOptions: "" });
                            jQuery(`#customField${this.state.idx}`).val("");
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

{/* attachment_modal */}
            <div
              className='modal fade pop-modal'
              id='attachment_modal'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Attachments <span className='blue-txt'>{this.state.list_of_attachments.length}</span></h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no searchscroll'>
                          {this.state.list_of_attachments.length>0 && this.state.list_of_attachments.map((item)=> <div key={item.source_id} className="attachment-preview ">
                        <a 
                        href='javascript:;'
                        onClick={() => {
                          var win = window.open(
                            `${item.source_path}`,
                            "_blank"
                          );
                          win.focus();
                        }}>{item.source_path.split('/')[5]}</a> 
                        <button type='button' className='attachment-cancel' onClick={()=>{
                          this.setState({
                            list_of_attachments: this.state.list_of_attachments.filter(x=>x.source_id!==item.source_id)
                          })
                        }}>X</button>
                        </div>)}
                      
                      </div>
                      <button
                          data-dismiss='modal'
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={()=> window.jQuery('#upload_doc_modal').modal('show')}
                        >
                          Upload attachment
                        </button>
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                       
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>


{/* upload_doc_modal */}
            <div
              className='modal fade pop-modal'
              id='upload_doc_modal'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Upload Attachments</h3>
                    <form className='custom-form row'>
                      <div className='form-group  pad-no mar-b-no'>
                         <div className=''>
                      <div className='form-group'>
                        {/* <label>Upload Attachments<span className="astrick">*</span></label> */}
                        <div className='upload-wizard'>
                          <div className='upload-thumb hide'>
                            <ul className='list-inline'>
                              {this.state.attachment_file.map(
                                (file_data, index) => {
                                  var file_url = URL.createObjectURL(file_data)
                                  var file_name = file_data.name

                                  if (file_data.type === 'application/pdf') {
                                    var blob = file_url

                                    console.log('blob_url', file_data.name)

                                    //return this.createPdfImg(file_data);

                                    return (
                                      <li className='previewImg'>
                                        <div className='thumb-img'>
                                        <a href='javascript:;' className='del-btn'
                                          onClick={() => {
                                            this.removeImage(file_data.name, index)
                                          }}
                                        >
                                          <img
                                            src='./images/close-icon-white.svg'
                                            alt='delete'
                                          />
                                        </a>
                                          <PDFtoIMG file={blob}>
                                            {({ pages }) => {
                                              console.log('check length', pages)

                                              if (!pages.length)
                                                return 'check Loading...'
                                              return pages.map((page, index) =>
                                                index == 0 ? (
                                                  <img
                                                    key={index}
                                                    src={page}
                                                    title={file_name}
                                                    alt='pdf'
                                                  />
                                                ) : (
                                                  ''
                                                )
                                              )
                                            }}
                                          </PDFtoIMG>
                                        </div>
                                        <span className='file-name'>
                                          {file_name}
                                        </span>
                                       
                                      </li>
                                    )
                                  } else {
                                    return (
                                      <li className='previewImg'>
                                        <div className='thumb-img'>
                                          <a
                                            href='javascript:;'
                                            className='del-btn'
                                            onClick={() => {
                                              this.removeImage(file_data.name)
                                            }}
                                          >
                                            <img
                                              src='./images/close-icon-white.svg'
                                              alt='delete'
                                            />
                                          </a>

                                          <img
                                            src={file_data.type==='application/vnd.ms-excel'? '/images/file-icon.svg':file_data.type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? '/images/file-icon.svg' :file_url}
                                            title={file_data.name}
                                            alt={file_data.name}
                                          />
                                        </div>
                                        <span className='file-name'>
                                          {file_data.name}
                                        </span>

                                      </li>
                                    )
                                  }
                                }
                              )}

                              <li className='addMore'>
                                <input
                                  type='file'
                                  name='imgInp[]'
                                  id='imgInp2'
                                  className='add_img'
                                  multiple
                                  onChange={this.loadFile.bind(this)}
                                  accept='image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                />
                                <a href='javascript:;' className='add-more'>
                                  <div>
                                    <img
                                      src='images/upload-icon.svg'
                                      alt='icon'
                                    />
                                    Add or Drag here
                                  </div>
                                </a>
                              </li>

                              {/* <li className="addMore">
                                <a href="javascript:;"  className="add-more" primary={false} onClick={() => {this.upload.click();}}>
                                  <div >
                                  <input id="myInput" type="file" ref={ref => (this.upload = ref)} style={{ display: "none" }}
                                      onChange={this.onChangeFile.bind(this)}/>
                                    <label htmlFor="file">{file}</label>

                                    {this.state.file ? (
                                      <PDFtoIMG file={this.state.file}>
                                        {({ pages }) => {
                                          console.log("length", pages);

                                          if (!pages.length)
                                            return "Loading...";
                                          return pages.map((page, index) => (
                                            (index==0)?
                                            <img   key={index} src={page} style={{height:104.9,width:105.9,marginTop: 13,}}  />
                                            
                                            :""
                                          ));
                                        }}
                                      </PDFtoIMG>
                                    ) : (
                                      <div>Add more</div>
                                    )}
                                    <span className="file-name">{fileName}</span>                              
                                  </div>                                  
                                </a>
                              </li> */}
                            </ul>
                          </div>
                          <div className='upload-space'>
                            <input
                              type='file'
                              name='imgInp[]'
                              id='imgInp'
                              className='add_img'
                              multiple
                              onChange={this.loadFile.bind(this)}
                              accept='image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            />
                            {/* <img id="output" src="#" alt="your image" width="70" height="50" /> */}
                            <div>
                              <img
                                className='icon'
                                src='images/upload-icon.svg'
                                alt='icon'
                              />
                              <span>.pdf .png .jpg .xls .xlsx</span>
                              <span className='note-txt'>
                                Drag and drop your files here
                                <br />
                                or
                                <br />
                                <a href='javascript:;'>click here</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.is_files_not_selected ? (
                          <div >
                            <small style={{ color: "red" }}>
                              Please select atleast one file
                            </small>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                      </div>
                      
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                        <span>{"   "}</span>
                        <button
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={()=>this.save_attachment()}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>


            <div
              className='modal fade pop-modal'
              id='custom_column_options1'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Add Options</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Options</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            placeholder='Enter options seperate by comma'
                            value={this.state.addedOptions1}
                            onChange={(e) =>
                              this.setState({ addedOptions1: e.target.value })
                            }
                          />
                          <div></div>{" "}
                        </div>
                      </div>
                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                        >
                          Cancel
                        </button>
                        <span>{"   "}</span>
                        <button
                          data-dismiss='modal'
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={(e) => {
                            let value = this.state.addedOptions1;
                            let valueArray = value.split(",");
                            let customFieldB = this.state.customFieldB;
                            valueArray.map((item, l) => {
                              customFieldB[this.state.idx].options.push(item);
                            });
                            this.setState({ customFieldB, addedOptions1: "" });
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='modal fade pop-modal'
              id='add_new_role'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                  onClick={() => {
                    this.setState({ roleStringLen: false });
                  }}
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Add Options</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no mar-b-no'>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Options</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            id='options'
                            placeholder='Enter options seperate by comma'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                          onClick={() => {
                            this.setState({ roleStringLen: false });
                          }}
                        >
                          Cancel
                        </button>
                        <span>{"   "}</span>
                        <input type='hidden' id='colid' />

                        <button
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={() => {
                            const userId = Number(THIS.state.logged_user_id);
                            const coulmnId = Number(jQuery("#colid").val());
                            const localString = jQuery("#options").val();
                            const optionsArray = localString.split(",");
                            var items = this.state.number_of_columns_list;
                            var exist = items[coulmnId].options;
                            var options = [...exist, ...optionsArray];
                            items[coulmnId]["options"] = options;

                            var coreData = {
                              user_id: this.state.logged_user_id,
                              columns: items,
                              client_id: this.state.logged_client_id,
                            };

                            FetchAllApi.upDateCoulmns(
                              coreData,
                              (err, response) => {
                                // console.log("new document", response.message);
                                alert(response.message);
                                if (response.status === 1) {
                                  this.getColumns();

                                  jQuery("#options").val("");
                                  window.jQuery("#add_new_role").modal("hide");
                                  //   this.setState({ items: response.list[0].columns })
                                  this.setState({});
                                } else {
                                  let co_items =
                                    this.state.number_of_columns_list;
                                  let co_exist = co_items[coulmnId].options;
                                  let co_options = [...co_exist];
                                  co_options.pop();
                                  co_items[coulmnId]["options"] = co_options;
                                }
                              }
                            );
                            // FetchAllApi.invoiceadd_dropdown_options(
                            //   userId,
                            //   coulmnId,
                            //   optionsArray,
                            //   (err, response) => {
                            //     console.log('vendor_names', response)

                            //     if (response.status === 1) {
                            //       alert('success')
                            //       this.getColList()
                            //       window.jQuery('#add_new_role').modal('hide')
                            //     } else {
                            //     }
                            //   }
                            // )
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='modal fade pop-modal'
              id='add_items'
              role='dialog'
              data-backdrop='static'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                  onClick={() => {
                    jQuery("#item_categeory option")
                      .prop("selected", false)
                      .trigger("change");
                    jQuery("#item_text").val("");
                    // jQuery("#item_rate").val("");
                    jQuery("#hiddenCategID").val("");
                    jQuery("#item_descrption").val("");
                    this.setState({
                      product_item_rate: "",
                    });
                  }}
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Add New Items</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no '>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Item Name</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            id='item_text'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 pad-no '>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Rate</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <NumberFormat
                            value={this.state.product_item_rate}
                            thousandSeparator={true}
                            autoComplete='off'
                            className='form-control'
                            id='item_rate'
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({
                                product_item_rate: formattedValue,
                              });
                            }}
                          />
                          <input
                            type='hidden'
                            className='form-control'
                            id='iamfrom'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 pad-no '>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Description</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <input
                            autoComplete='off'
                            type='text'
                            className='form-control'
                            id='item_descrption'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 pad-no '>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <label>Category</label>
                        </div>
                        <div className='col-md-8 col-sm-8 col-xs-12'>
                          <select
                            className='selectpicker form-control add-new kk'
                            data-live-search='true'
                            title='Choose Category'
                            id='item_categeory'
                            onChange={(e) => {
                              if (e.target.value == "1e") {
                                window.jQuery("#pop-modal").modal("show");
                              } else {
                                jQuery("#hiddenCategID").val(e.target.value);
                              }
                            }}
                          >
                            <option value='1e'>Add New </option>
                            {THIS.state.default_category_list &&
                              THIS.state.default_category_list.map(
                                (item, k) => {
                                  var usee = item.name;
                                  if (usee.includes(this.state.nameFilter)) {
                                    // alert(this.state.nameFilter)
                                    var selected = true;
                                    jQuery("#hiddenCategID").val(item.id);

                                    // jQuery('.kk').val(item.id)
                                  }

                                  // if(this.state.selectNeedIndex==k){
                                  //   console.log('sdhjisdiuso',this.state.selectNeedIndex+'='+k)
                                  //    var usee=item.name

                                  //   // jQuery('.kk').val(item.id)
                                  //   // setTimeout(()=>{
                                  //     // jQuery('#item_categeory').val(item.id)
                                  //   // },3000)

                                  //   if(this.state.selectNeedIndex !='empty'){
                                  //     this.setState({selectNeedIndex:'empty'})

                                  //   }
                                  // }else{
                                  //   var selected=false

                                  // }
                                  return (
                                    <option
                                      selected={selected}
                                      value={item.id}
                                      data-status={item.id}
                                      disabled={item.status == 2 ? true : false}
                                      style={{
                                        display:
                                          item.status == 2 ? "none" : "block",
                                      }}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                          </select>
                          <input
                            autoComplete='off'
                            type='hidden'
                            className='form-control'
                            id='hiddenCategID'
                            placeholder='Enter new item'
                          />
                          <div>
                            {this.state.roleStringLen && (
                              <small style={{ color: "red" }}>*Required.</small>
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      <div className='form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no'>
                        <button
                          className='btn btn-lightgray btn-align'
                          data-dismiss='modal'
                          onClick={() => {
                            this.setState({ roleStringLen: false });
                            jQuery("#item_categeory option")
                              .prop("selected", false)
                              .trigger("change");

                            jQuery("#item_text").val("");
                            // jQuery("#item_rate").val("");
                            jQuery("#hiddenCategID").val("");
                            jQuery("#item_descrption").val("");
                            this.setState({
                              product_item_rate: "",
                            });
                          }}
                        >
                          Cancel
                        </button>
                        <span>{"   "}</span>
                        <input type='hidden' id='colid' />

                        <button
                          className='btn btn-green btn-align'
                          type='button'
                          onClick={() => {
                            const item = jQuery("#item_text").val();
                            const item_descrption =
                              jQuery("#item_descrption").val();

                            // const item_rate = jQuery("#item_rate").val();
                            const item_rate = this.state.product_item_rate;
                            const item_categeory =
                              jQuery("#hiddenCategID").val();

                            var coreData = {
                              client_id: this.state.logged_client_id,
                              item_name: item,
                              category_id: item_categeory,
                              rate: item_rate,
                              description: item_descrption,
                            };

                            FetchAllApi.addItems(coreData, (err, response) => {
                              // console.log("vendor_names", response);

                              if (response.status === 1) {
                                this.getItems(
                                  jQuery("#item_text").val(),
                                  jQuery("#iamfrom").val()
                                );

                                alert("success");
                                this.setState({ roleStringLen: false });
                                jQuery("#item_categeory option")
                                  .prop("selected", false)
                                  .trigger("change");

                                jQuery("#item_text").val("");
                                // jQuery("#item_rate").val("");
                                jQuery("#hiddenCategID").val("");
                                jQuery("#item_descrption").val("");
                                window.jQuery("#add_items").modal("hide");

                                this.setState({
                                  product_item_rate: "",
                                });
                              } else if (response.status === 0) {
                                alert(response.message);
                              }
                            });
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='modal fade pop-modal'
              id='table_items'
              role='dialog'
              ata-backdrop='static'
            >
              <div className='modal-dialog modal-md custom-modal'>
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <h3>Payment Details</h3>
                    <form className='custom-form row'>
                      <div className='form-group col-md-12 col-xs-12 pad-no '>
                        <table className='table detail-report'>
                          <thead>
                            <tr>
                              <th className='text-left'>
                                Type
                                <i className='th-sort'>
                                  <img
                                    src='images/sort-icon.svg'
                                    alt='SortIcon'
                                  />
                                </i>
                              </th>
                              <th>
                                Date
                                <i className='th-sort'>
                                  <img
                                    src='images/sort-icon.svg'
                                    alt='SortIcon'
                                  />
                                </i>
                              </th>
                              <th className='text-right'>
                                Foreign currency
                                <i className='th-sort'>
                                  <img
                                    src='images/sort-icon.svg'
                                    alt='SortIcon'
                                  />
                                </i>
                              </th>
                              <th className='text-right'>
                                Home currency
                                <i className='th-sort'>
                                  <img
                                    src='images/sort-icon.svg'
                                    alt='SortIcon'
                                  />
                                </i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.payement_table &&
                            this.state.payement_table.length > 0
                              ? this.state.payement_table.map((pay, idx) => {
                                  return (
                                    <tr>
                                      <td
                                        className='text-left-imp'
                                        onClick={() => {
                                          if (
                                            pay.payment_type == "SinglePayment"
                                          ) {
                                            var setID =
                                              pay.invoice_id + "=" + pay.id;
                                            localStorage.setItem(
                                              "invoice_id",
                                              setID
                                            );

                                            localStorage.setItem(
                                              "job_id",
                                              pay.job_id
                                            );
                                            // alert("job id" + e1.job_id);

                                            var win = window.open(
                                              "/create_invoice",
                                              "_blank"
                                            );
                                            win.focus();
                                          } else {
                                            let arr = [
                                              pay.customer_id,
                                              pay.multi_payment_applied_invoices,
                                            ];

                                            localStorage.setItem(
                                              "edit_customer_receive_payment",
                                              JSON.stringify(arr)
                                            );

                                            var win = window.open(
                                              "/Customer_receive_payment",
                                              "_blank"
                                            );
                                            win.focus();
                                          }
                                        }}
                                      >
                                        <a>{pay.payment_type}</a>
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(
                                          pay.payment_date,
                                          "YYYY-MM-DD"
                                        ).format("DD-MM-YYYY")}
                                      </td>
                                      <td className='text-right'>
                                        <Comma
                                          value={
                                            pay.total_payment_foreign_currency
                                          }
                                        />
                                      </td>
                                      <td className='text-right'>
                                        <Comma
                                          value={
                                            pay.total_payment_home_currency
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* apply credit modal*/}

            <div
              className='modal fade pop-modal'
              id='apply_credit_modal'
              role='dialog'
              ata-backdrop='static'
            >
              <div
                className='modal-dialog modal-md custom-modal'
                style={{ width: "955px" }}
              >
                <button
                  type='button'
                  className='close hidden-xs'
                  data-dismiss='modal'
                >
                  <img
                    className='img-responsive'
                    src='../../images/close-red.svg'
                    alt='icon'
                  />
                </button>
                <div className='modal-content'>
                  <div className='modal-body text-center'>
                    <div className='clearfix'>
                      <p className='fw-med'>Available Credits</p>
                      <div
                        className='table-responsive'
                        style={{ paddingright: "1px" }}
                      >
                        <table className='dis-table'>
                          <thead>
                            <tr>
                              {/* <th className="text-center"> */}
                              {/* <img
                                className="tick-white"
                                src="images/tick-big.svg"
                                alt="icon"
                              /> */}
                              {/* </th> */}
                              <th>Date</th>
                              <th>Credit No.</th>
                              <th className='text-center'>
                                {/* text-right */}
                                Org Credit Amount
                              </th>
                              <th className='text-center'>
                                Credit amount to Use
                              </th>
                              <th className='text-center'>Amount to Use</th>
                              <th className='text-center'>Credit Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.customer_credit_list &&
                              this.state.customer_credit_list.map((item, i) => {
                                return (
                                  <tr>
                                    {/* <td className='text-center'> */}
                                    {/* <label className='custom-checkbox mar-b-no'>
                                        <input
                                          type='checkbox'
                                          name=''
                                          value={i}
                                        // checked="checked"
                                        // onChange={e => this.handleCreditChange(e.target.checked, i, item.available_credits)}


                                        />
                                        <span className='checkmark'></span>
                                      </label> */}
                                    {/* </td> */}
                                    <td className='text-center'>
                                      {item.credit_date}
                                    </td>
                                    <td
                                      style={{ display: "none" }}
                                      id={`credit_id${i}`}
                                    >
                                      {item.credit_id}
                                    </td>

                                    <td className='text-center'>
                                      {item.credit_number}
                                    </td>
                                    <td className='text-right'>
                                      {/* {item.amount} */}
                                      <Comma value={item.amount} />
                                    </td>
                                    <td className='text-right'>
                                      {/* {item.available_credits} */}
                                      <Comma value={item.available_credits} />
                                    </td>
                                    <td className='text-right'>
                                      <input
                                        type={Number}
                                        id={`credit_to_be_use${i}`}
                                        name={`credit_to_be_use${i}`}
                                        value={
                                          this.state[`credit_to_be_use${i}`]
                                        }
                                        onChange={(e) => {
                                          if (!isNaN(e.target.value)) {
                                            let pre_amt = jQuery(
                                              "#credit_to_be_use" + i
                                            ).val();
                                            let amount = e.target.value;

                                            if (this.state.isInvoiceEditable) {
                                              // to remove comma from amount
                                              let a =
                                                jQuery("#forbaldue").html();
                                              let b = a.split(",");
                                              let c = Number(b.join(""));
                                              // to remove comma from amount

                                              if (!isNaN(c)) {
                                                if (c >= Number(amount)) {
                                                  // from cus recei pay

                                                  if (
                                                    Number(amount) >
                                                    Number(
                                                      item.available_credits
                                                    )
                                                  ) {
                                                    alert(
                                                      "You may not pay more than Credit Amount"
                                                    );
                                                  } else {
                                                    this.setState({
                                                      [`credit_to_be_use${i}`]:
                                                        amount,
                                                    });
                                                    setTimeout(
                                                      () =>
                                                        this.credit_onchange(
                                                          i,
                                                          item.available_credits
                                                        ),
                                                      500
                                                    );
                                                  }
                                                  // from cus recei pay

                                                  this.setState({
                                                    [`amount_entered_for_credit${i}`]:
                                                      amount,
                                                  });
                                                } else {
                                                  alert(
                                                    "you cannot pay more than amount due"
                                                  );
                                                  jQuery("#payable_amount").val(
                                                    this.state[
                                                      `amount_entered_for_credit${i}`
                                                    ]
                                                  );
                                                }
                                              }
                                            }
                                            if (!this.state.isInvoiceEditable) {
                                              // to remove comma from amount
                                              let a =
                                                jQuery(
                                                  "#home_grand_total"
                                                ).html();
                                              let b = a.split(",");
                                              let c = Number(b.join(""));
                                              // to remove comma from amount

                                              if (!isNaN(c)) {
                                                if (c >= Number(amount)) {
                                                  // from cus recei pay

                                                  if (
                                                    Number(amount) >
                                                    Number(
                                                      item.available_credits
                                                    )
                                                  ) {
                                                    alert(
                                                      "You may not pay more than Credit Amount"
                                                    );
                                                  } else {
                                                    this.setState({
                                                      [`credit_to_be_use${i}`]:
                                                        amount,
                                                    });
                                                    setTimeout(
                                                      () =>
                                                        this.credit_onchange(
                                                          i,
                                                          item.available_credits
                                                        ),
                                                      500
                                                    );
                                                  }
                                                  // from cus recei pay

                                                  this.setState({
                                                    [`amount_entered_for_credit${i}`]:
                                                      e.target.value,
                                                  });
                                                } else {
                                                  alert(
                                                    "you cannot pay more than amount due"
                                                  );
                                                  jQuery(
                                                    "#amount_entered_for_credit" +
                                                      i
                                                  ).val(
                                                    this.state[
                                                      `amount_entered_for_credit${i}`
                                                    ]
                                                  );
                                                }
                                              }
                                            }
                                          }
                                        }}
                                      />
                                    </td>
                                    <td className='text-right'>
                                      <span id={`creditBal${i}`}>
                                        {this.state[`usedCreditsRowTotal${i}`]}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr>
                              {/* <td colSpan='3' className='text-right'> */}
                              <td colSpan='2' className='text-right'>
                                Total
                              </td>
                              <td className='text-right'>
                                {/* {this.state.total_amt} */}
                                <Comma value={this.state.total_amt} />
                              </td>
                              <td className='text-right'>
                                {/* {this.state.total_avail} */}
                                <Comma value={this.state.total_avail} />
                              </td>
                              <td className='text-right'>
                                {/* {this.state.total_used_credit} */}
                                <Comma value={this.state.total_used_credit} />
                              </td>
                              <td className='text-right'>
                                {/* {this.state.total_avail - this.state.total_used_credit} */}
                                <Comma
                                  value={
                                    this.state.total_avail -
                                    this.state.total_used_credit
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-md-6 pad-no'>
                        {/* <em className="info-em">
                        Of all the credits issued to this Customer, only
                        unused credits applicable to this specific
                        invoice are displayed above
                              </em> */}
                      </div>
                      <div className='col-md-6 text-right pad-no'>
                        <button
                          type='button'
                          className='btn btn-lightgray'
                          onClick={() => this.apply_credit_to_invoice()}
                        >
                          Apply Credit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* apply credit modal*/}

            {/* modal for asking delete invoice */}

            <div>
              <div
                class='modal fade in'
                id='modal_delete_invoice'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'>
                        {<img src='../../images/delete-icon.svg' alt='icon' />}
                      </div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'>This invoice will be deleted </p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        // data-dismiss="modal"
                        // data-toggle="modal" data-target="#asking_password_for_delete"

                        onClick={() => this.delete_or_void_sales_invoice(10)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* modal for asking delete invoice    */}

            {/* modal for paid_invoice_delete_modal  */}

            <div>
              <div
                class='modal fade in'
                id='paid_invoice_delete_modal'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'>
                        {<img src='../../images/delete-icon.svg' alt='icon' />}
                      </div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'> {this.state.delete_alert_msg} </p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                        onClick={() => {
                          window
                            .jQuery("#paid_invoice_delete_modal")
                            .modal("hide");
                          window
                            .jQuery("#paid_invoice_delete_modal")
                            .css("display", "none");
                          // setTimeout(() => {
                          //   window.jQuery('#modal_delete_invoice').modal('hide')
                          // }, 500);
                        }}
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        // data-dismiss="modal"
                        // data-toggle="modal" data-target="#asking_password_for_delete"
                        onClick={() => {
                          let input = {
                            client_id: this.state.logged_client_id,
                            invoice_id: this.state.invoice_id_used,
                            status_to_set: 10,
                            confirm_action: 1,
                            payment_id: this.state.currpayment_id,
                          };

                          FetchAllApi.delete_or_void_sales_invoice(
                            input,
                            (err, response) => {
                              if (response.status === 1) {
                                alert(response.message);
                                // window.open('/all_lists')
                                // localStorage.setItem('page_select','Invoice')
                                // window.location.href = "/all_lists"
                                // window.jQuery('#paid_invoice_delete_modal').modal('hide')
                                window
                                  .jQuery("#modal_void_invoice")
                                  .modal("hide");
                                window
                                  .jQuery("#modal_delete_invoice")
                                  .modal("hide");

                                window
                                  .jQuery("#paid_invoice_delete_modal")
                                  .css("display", "none");

                                // this.props.history.push('/all_lists')
                              } else {
                                alert(response.message);
                              }
                            }
                          );
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* modal for asking paid_invoice_delete_modal*/}

            {/* modal for asking delete invoice */}

            <div>
              <div
                class='modal fade in'
                id='modal_void_invoice'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'>
                        {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                      </div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'>This invoice will be voided </p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        // data-dismiss="modal"
                        // data-toggle="modal" data-target="#asking_password_for_delete"

                        onClick={() => this.delete_or_void_sales_invoice(11)}
                      >
                        Make Void
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class='modal fade in'
                id='modal_void_invoice_payment'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'></div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'>This Payment will be voided </p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        onClick={() =>
                          this.delete_or_void_sales_invoice_payment(11)
                        }
                      >
                        Make Void
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* modal for asking delete invoice    */}

            {/* modal for paid_invoice_delete_modal  */}

            <div>
              <div
                class='modal fade in'
                id='paid_invoice_void_modal'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'>
                        {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                      </div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'> {this.state.delete_alert_msg} </p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                        onClick={() => {
                          window
                            .jQuery("#paid_invoice_void_modal")
                            .modal("hide");
                          window
                            .jQuery("#paid_invoice_void_modal")
                            .css("display", "none");
                          // setTimeout(() => {
                          //   window.jQuery('#modal_delete_invoice').modal('hide')
                          // }, 500);
                        }}
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        // data-dismiss="modal"
                        // data-toggle="modal" data-target="#asking_password_for_delete"
                        onClick={() => {
                          let input = {
                            client_id: this.state.logged_client_id,
                            invoice_id: this.state.invoice_id_used,
                            status_to_set: 11,
                            confirm_action: 1,
                            payment_id: this.state.currpayment_id,
                          };

                          FetchAllApi.delete_or_void_sales_invoice(
                            input,
                            (err, response) => {
                              if (response.status === 1) {
                                alert(response.message);
                                // window.open('/all_lists')
                                // localStorage.setItem('page_select','Invoice')
                                // window.location.href = "/all_lists"
                                // window.jQuery('#paid_invoice_void_modal').modal('hide')
                                window
                                  .jQuery("#modal_void_invoice")
                                  .modal("hide");
                                window
                                  .jQuery("#modal_delete_invoice")
                                  .modal("hide");

                                window
                                  .jQuery("#paid_invoice_void_modal")
                                  .modal("hide");
                                window
                                  .jQuery("#paid_invoice_void_modal")
                                  .css("display", "none");

                                // this.props.history.push('/all_lists')
                              } else {
                                alert(response.message);
                              }
                            }
                          );
                        }}
                      >
                        Make Void
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* modal for asking paid_invoice_void_modal*/}

            {/* credit limit alert modal */}
            <div>
              <div
                class='modal fade in'
                id='credit_limit'
                role='dialog'
                style={{ paddingLeft: 15 }}
              >
                <div class='modal-dialog modal-md' style={{ width: 440 }}>
                  <button
                    type='button'
                    class='close hidden-xs'
                    data-dismiss='modal'
                  >
                    <img
                      class='img-responsive'
                      src='../../images/close-red.svg'
                      alt='icon'
                    />
                  </button>
                  <div class='modal-content'>
                    <div class='modal-body text-center success-modal'>
                      <div class='pop-icon img-size'>
                        {/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}
                      </div>

                      <h3>Are you sure?</h3>

                      <p class='fw-500'>{this.state.credit_limit_msg}</p>
                      <button
                        className='btn btn-lightgray btn-align'
                        data-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <span>{"   "}</span>
                      <button
                        class='btn btn-red btn-align'
                        type='button'
                        data-dismiss='modal'
                        onClick={() =>
                          this.setState({ is_credit_limit_accepted: 1 }, (e) =>
                            this.save_template(e, this.state.btn_type)
                          )
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* credit limit alert modal */}

            <div className='builder-right' style={{ display: "none" }}>
              <div id='whole_template' className='template-item' >
                <div id='#whole_template'>
                  {this.state.html_contents && parse(this.state.html_contents)}
                </div>
              </div>
            </div>

            <Footer
              defaultcategorylist_onchange={this.defaultcategorylist_onchange}
              logoutSubmit={(e) => this.logoutLink(e)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <CoulmnRearrage createInvoice={"1"} changeState={this.changeState} />
      );
    }
  }
}
export default invoice_template_listing;
