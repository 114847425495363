// import React from 'react';
import React, { useState, useEffect } from "react";
import { socket,startService } from './socket/socket';

//import './App.css';
import 'bootstrap';
import Loader from 'react-loader-spinner'

import Commonlinks from './commonlinks';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'


library.add(fas)

function App() {

  useEffect(() => {
    if(socket) {
    // socket.on("general_ledger_mismatch", data => {
    //   alert("GL mismatch");
    // });

    // socket.on("balance_sheet_mismatch", data => {
    //   alert(data);
    //   alert("Balance sheet mismatch:"+data);
    // });
  }
  }, []);

  return (
    <div className="App">
      <Commonlinks />
    </div>
  );
}

export default App;
