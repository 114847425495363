import React from "react";
import UserTopbar from "../components/first_user_module/header";
import FetchAllApi from "../api_links/fetch_all_api";
import { Link } from "react-router-dom";
import Sidebar from './preferenceSide';
import jQuery from 'jquery'
import "./preference.css"
import Comma from './../components/comma'
import moment from "moment";



export default class repeatingjournal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),     
      repeatStartDate: '',
      repeatEndDate: "",    
      sort_by_repeat:'asc',
      sort_by_column_key_repeat:'',    
      repeatingText: "",
      clientHomeCurrency: '',    
      repeatArr: [],
     
    }
  };


  componentWillMount() {
    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push("/");
    }
  };

  logoutLink() {
    localStorage.clear();

    this.props.history.push("/");
  };


  componentDidMount() {
    if (this.props.location.state != '' && this.props.location.state != null && this.props.location.state != undefined) {
        this.get_journal_details(this.props.location.state)
      }
    this.get_client_home_currency();

    window.jQuery(".mscroll-y").mCustomScrollbar({
      axis: "y",
      scrollEasing: "linear",
      scrollInertia: 600,
      autoHideScrollbar: "true",
      autoExpandScrollbar: "true"
    });
    window.jQuery(".mscroll-x").mCustomScrollbar({
      axis: "x",
      scrollEasing: "linear",
      scrollInertia: 600,
      autoHideScrollbar: "true",
      autoExpandScrollbar: "true"
    });
  };
  get_journal_details = (journalid) => {
    let input = {
      client_id: this.state.logged_client_id,
      journal_id: journalid
    }
    FetchAllApi.get_repeating_journal(input.client_id,input.journal_id, (err, response) => {
      if (response.status === 1) {
        let a = response.details
        this.setState({ repeatArr: response.list })
      } else {
        alert(response.message)
      }
  
    });
  };
  componentDidUpdate() {

    window.jQuery(".selectpicker").selectpicker("refresh");
    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy" });

  };  
  
  

  savevoid = (val) => {
      console.log(val);
    let client_id = this.state.logged_client_id; 
    let journal_id = val.actual_journal_id;  
  //  FetchAllApi.stop_repeating_journal(client_id,this.props.location.state,val.repeat_date, (err, response) => {
  //         if (response.status === 1) {
  //           alert(response.message)

  //         }
  //         else{
  //             alert(response.message)
  //         }
  //       })

  FetchAllApi.make_voidmanualjournal(client_id, journal_id, (err, response) => {
    if (response.status === 1) {
      alert(response.message);
      if (this.props.location.state != '' && this.props.location.state != null && this.props.location.state != undefined) {
        this.get_journal_details(this.props.location.state)
      }
    } else{
      alert(response.message);
    }
  });
    
  };


  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        console.log("Basio state", response);
        this.setState({
          clientHomeCurrency: response.currency,
        });
      } else {
      }
    });
  };

 
  
  pageLink(page_slug) {
    this.props.history.push('/' + page_slug)
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          {/* header Starts here */}
          <UserTopbar logoutSubmit={(e) => this.logoutLink()} />
          {/* header Ends here */}
          {/* <div class="title-sec col-md-12 col-xs-12">
        <h3>Subscribers</h3>
    </div> */}
          {/* user-content Starts here */}
          <section className="user-content row pad-b-no">
            <Sidebar pageSubmit={e => this.pageLink(e)} />
            <div className="user-cont-right">
              <div className="title-sec col-md-12 col-xs-12 mar-btm">
                <h3>Repeating Journals</h3>
                <div>
                  <div className="dib">
                    <div className="dropdown menu-item new-cus  mar-rgt-5">
                      <button className="btn btn-blue dropdown-toggle btn-arrow" data-toggle="dropdown" aria-expanded="false">New Journal<span className="caret" /></button>
                      <ul className="dropdown-menu align-right">
                        <li><a href="/new_journal">Journal</a></li>
                        <li><a href="/new_repeting_journal">Repeating Journals</a></li>
                      </ul>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className="col-md-12 col-xs-12">                
                
                    <div id="repeating" className="col-md-12 tab-pane fade in pad-no">
                   

                      <div className="report-table reconcile-table pad-td-rgt col-md-12 col-xs-12 mar-t-no pad-no">
                        <div className="table-responsive">
                          <table className="table detail-report">
                            <thead>
                              <tr>   
                                <th className="text-left-imp">
                                  Journal Number
                               
                                </th>
                                <th className="text-left-imp">
                                    Transaction Date
                                </th>
                                <th className="text-left-imp">
                                    Repeat Date
                                </th>
                                <th className="text-right">
                                  Debit {this.state.clientHomeCurrency}
                                
                                </th>
                                <th className="text-right">
                                  Credit {this.state.clientHomeCurrency}
                               
                                </th>
                               
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.repeatArr.map((lis, idx) => {
                                return (
                                  <tr>                                    
                                   
                                    <td className="text-left-imp">{lis.reference_number}</td>
                                    <td>{lis.transaction_date}</td>
                                    <td>{lis.repeat_date}</td>
                                    <td className="text-right"><Comma value={lis.grand_total_debit_home_currency} /></td>
                                    <td className="text-right"><Comma value={lis.grand_total_credit_home_currency} /></td>
                                    {(() => {
                                      if (lis.status == "Posted") {
                                        return (
                                          <td><span className="badge green dib">{lis.status}</span></td>
                                        )
                                      } else if (lis.status == "Pending") {
                                        return (
                                          <td><span className="badge orange dib">{lis.status}</span></td>
                                        )                                     
                                      } else if (lis.status == "Voided") {
                                        return (
                                          <td><span className="badge red dib">{lis.status}</span></td>
                                        )
                                      }
                                    })()}
                                    <td className="text-right">
                                    <div className="dropdown menu-item new-cus">
                                    <button className="btn btn-green dropdown-toggle" type="button" data-toggle="dropdown">Action
                                <span className="caret" /></button>
                                    <ul className="dropdown-menu align-right">
                                      <li><a data-toggle="modal" data-target="#pop-modal-2"   onClick={() => this.savevoid(lis)} >Void</a></li>
                                     
                                    </ul>
                                  </div>                                    
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>                
              </div>
            </div>
          </section>
       
        </div>   
      </React.Fragment>
    )
  }
}