import { Link } from "react-router-dom";

import FetchAllApi from '../../api_links/fetch_all_api';
import React from "react";
import jQuery from "jquery";
import moment from "moment";
import parse from 'html-react-parser'
class Verifyemail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: "",
      email_address: "",
      id: "",     
      language_code:'ta',
      country_sortname:'SG',
      home_currency:'SGD',
      home_currency_symbol :'SGD'
    };
  };

  componentWillMount() {
    localStorage.clear();
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    let approvaldata = params.get("data");
    approvaldata = Buffer.from(approvaldata, 'base64').toString('ascii');
    let approvalData = JSON.parse(approvaldata);
    this.setState({
        client_id:approvalData.client_id,
        email_address:approvalData.email_address,
        id:approvalData.id
    })
  
  };
 
  UNSAFE_componentWillMount() {
    jQuery("title").html("Email Approval | GBSC");
  }

  loginFormSubmit(e) {
    e.preventDefault();
    alert("submitted");    
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-sm-5 login-left hidden-sm hidden-xs">
            <h1>
              Welcome to <strong>GENIE</strong>
            </h1>
            <p>
              Cloud based accounting software with OCR, AI automation, Instant payment notification of Accounts receivable for all business contacts within genie platform.
            </p>

            <p>
              meaning NO hassle when it comes to Accounting anymore
            </p>
            <div className="img-wrap">
              <img
                className="img-responsive"
                src="../images/login-img.png"
                alt=""
              />
            </div>
          </div>
       
          <div className="col-md-7 col-sm-12 offset-md-4 login-right">
            <div className="login-wrap">
              <div className="nav-brand">
                <img src="../images/logo-genie.png" alt="Genie" />
              </div>
              {/* <p className="lead">View auto generated Invoice</p> */}

              <form
                className="login-form"
                onSubmit={()=>{                   
                    FetchAllApi.verifyemailforapproval(this.state.email_address,this.state.client_id,this.state.id,(err, response) => {
                        if (response.status === 1) {
                          alert(response.message)
                        }
                      })   
                      this.props.history.push('/')
                }}
              >
                <div className="form-group">
                  <button type="submit" className="btn login-btn">
                    Verify
                  </button>
                
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Verifyemail;