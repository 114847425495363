import React from "react";
import LeftSidebar from "../left_sidebar";
import Footer from "../footer";
import FetchAllApi from "../../api_links/fetch_all_api";
import config from "../../api_links/api_links";
import Topbar from "../topbar";
import { jsPDF, specialElementHandlers, margins } from "jspdf";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from "moment";
import { connect } from "react-redux";

import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jQuery from "jquery";
import { split } from "lodash";

//excel export with formula - starts
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
let excelColumnName = require('excel-column-name');
let row_starts_from = 3;

const containerStyle = { width: '100%', height: '100%',display:'none' };
const gridStyle = { height: '100%', width: '100%' };
//excel export with formula - end

// adds subtotals
const groupIncludeFooter = true;
// includes grand total
const groupIncludeTotalFooter = true;

var _ = require("lodash");

class GeneralLedger extends React.Component {
  constructor(props) {
    super(props);

    this.gridRef = React.createRef();

    //const { history } = this.props;
    this.state = {
      show_coulmns_filter: [],
      filtervalue: [],
      loading: true,
      logged_user_id: localStorage.getItem("logged_user_id"),
      logged_client_id: localStorage.getItem("logged_client_id"),
      logged_role_id: localStorage.getItem("logged_role_id"),
      logged_user_name: localStorage.getItem("logged_user_name"),
      logged_user_email: localStorage.getItem("logged_user_email"),
      logged_user_phone: localStorage.getItem("logged_user_phone"),
      logged_user_image: localStorage.getItem("logged_user_image"),
      logged_company_name: localStorage.getItem("logged_company_name"),
      client_date_format:localStorage.getItem("date_format"),

      country_sortname: localStorage.getItem("country_sortname"),
      language_code: localStorage.getItem("language_code"),
      home_currency: localStorage.getItem("home_currency"),

      incorporation_date: localStorage.getItem("incorporation_date"),
      home_currency_symbol: localStorage.getItem("home_currency_symbol"),

      // home_currency: 'SGD',

      total_revenue: "",
      cost_of_goods_sold: "",
      gross_profit: "",
      net_income: "",
      selectedCurrencies: "",
      selectedAccountIds: '',
      multiSelectedTypeIds: '',
      multiplejobSelected: '',
      reportObject: [],
      numberOfColumns: [],
      dateList: [],
      endDate: "2020-01-31",
      startDate: "2020-01-01",
      start_date: localStorage.getItem("incorporation_date"),
      end_date: moment().add(10, 'years').format("YYYY-MM-DD"),
      dropdown: "",
      show_column: "",
      inbox_list: [],
      response_stus: 0,
      response_msg: "No data found",
      item_details: "",
      item_file_path: "",
      waiting_re: [],
      re_assigned: [],
      Subcat_array: [],
      response: "",
      coulmns_head: [],
      final_total: "",
      options: "",
      From: "",
      To: "",
      filter_options: { condition: "", value: "", from: "", to: "" },
      valueAmount: "",
      valueAmount_type: "",
      disable: false,
      selectedFil: 0,
      is_selected_filter_default_column:1,
      selected_filter_column_name:'',
      selected_custom_filter_object_array:[],
     // currencies: [],
      currencies: config.all_currency_list,
      vendorNames: [],
      ColscountArray: [],
      source_name: '',

      selected_vendor_ids: [],
      changefromDate_duedate: "",
      todate_duedate: "",
      tableData: [],
      tableSize: 28,
      myArray: [],
      resData: [],
      data: [
        {
          id: 1,
          heading_name: "Type",
          clsname: "Type",
        },

        {
          id: 3,
          heading_name: "Customer Id",
          clsname: "customer_id",
        },
        {
          id: 4,
          heading_name: "Vendor email",
          clsname: "Customeremail",
        },
        {
          id: 5,
          heading_name: "Vendor address",
          clsname: "Companyaddress",
        },
        {
          id: 6,
          heading_name: "Vendor name",
          clsname: "Companyname",
        },
        {
          id: 7,
          heading_name: "Invoice date",
          clsname: "Invoicedate",
        },
        // {
        //   id: 8,
        //   heading_name: 'Customer name',
        //   clsname: 'Customername'
        // },
        // {
        //   id: 9,
        //   heading_name: 'Job name',
        //   clsname: 'jobname'
        // },
        // {
        //   id: 10,
        //   heading_name: 'Customer address',
        //   clsname: 'customer_address'
        // },
        {
          id: 11,
          heading_name: "Vendor phone",
          clsname: "customer_phone",
        },
        {
          id: 12,
          heading_name: "Memo",
          clsname: "Memo",
        },
        // {
        //   id: 13,
        //   heading_name: 'Item total home currency',
        //   clsname: 'Itemtotalhomecurrency'
        // },
        // {
        //   id: 14,
        //   heading_name: 'Tax amount home currency',
        //   clsname: 'Taxamounthomecurrency'
        // },
        // {
        //   id: 15,
        //   heading_name: 'Grand total home currency',
        //   clsname: 'Grandtotalhomecurrency'
        // },
        {
          id: 16,
          heading_name: "Foreign currency",
          clsname: "Foreigncurrency",
        },
        {
          id: 17,
          heading_name: "Exchange rate",
          clsname: "Exchangerate",
        },
        {
          id: 18,
          heading_name: "Due date",
          clsname: "Duedate",
        },
        {
          id: 19,
          heading_name: "Terms",
          clsname: "Termsconditions",
        },
        {
          id: 20,
          heading_name: "Invoice number",
          clsname: "Invoicenumber",
        },
        {
          id: 21,
          heading_name: "Shipping address",
          clsname: "Shippingaddress",
        },
        {
          id: 22,
          heading_name: "Credit",
          clsname: "credit",
        },
        {
          id: 23,
          heading_name: "Debit",
          clsname: "debit",
        },
        {
          id: 24,
          heading_name: "Open Balance Home currency",
          clsname: "Grandforeigncurrency",
        },
      ],

      valueAmount_type1: "",
      valueAmount1: "",
      date_start: "",
      date_end: "",
      number_from: "",
      number_to: "",
      memo: "",
      result_array: [],
      result_array_duplicate:[],
      last_selectedFil:0,
      all_report_name_id: "",
      open_balance: "",
      Exchange_rate: "",
      F_open: "",
      Address: "",
      website: "",
      mail: "",
      accnum: "",
      phone_number: "",
      vendor_type: [],
      customer_type: [],
      paymentTerms: [],
      selectedTerms: [],
      selectedCustomer_type: [],
      selectedVendor_type: [],
      valueAmount_type2: "",
      valueAmount_type3: "",
      valueAmount2: "",
      valueAmount3: "",
      specificRowIndex: "",
      scrolledPixel: 0,
      scrolledPixelIN_X: 0,
      scrolledPixelIN_Y: 0,
      hh: "",
      filter_key_names: [],

      type: false,
      sort_type: "asc",
      sortBynames: [],
      selectedName: "",
      filter_key_names: [],
      date_range: 'Custom',
      view: true,
      report_type: 1,
      export_file_name_date_range:'All',

    //excel export with formula - starts
     export_formula_column_defs:[
      
    ],
    defaultColDef:{
      flex: 1,
      minWidth: 100,
      resizable: true,
    },
    defaultExcelExportParams:{
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const rowIndex = params.accumulatedRowIndex;
          const valueGetter = params.column.getColDef().valueGetter;
          return !!valueGetter
            ? `=CONCATENATE(A${rowIndex}, " ", B${rowIndex})`
            : params.value;
        },
        fileName:"General_ledger.xls",
        sheetName:"General_Ledger"
      },
      parent_category:{
        // '39914': 0,
        // '39915': 0,
        // '39916': 0,
        // '39917': 0,
        // '39918': 0,
        // '39919': 0,
        // '39920': 0,
        // '39921': 0,
        // '39922': 0,
        // '39923': 0,
        // '39924': 0,
        // '39925': 39924,
        // '39926': 39925,
        // '39927': 39925,
        // '39928': 39924,
        // '39929': 39928,
        // '39930': 39928,
        // '39931': 39924,
        // '39932': 39931,
        // '39933': 39931,
        // '39934': 0,
        // '39935': 0,
        // '39936': 0,
        // '39937': 0,
        // '39938': 0,
        // '39939': 0,
        // '39940': 0,
        // '39941': 0,
        // '39942': 0,
        // '39943': 0,
        // '39944': 0,
        // '39945': 0,
        // '39946': 0,
        // '39947': 0,
        // '39948': 0,
        // '39949': 0,
        // '39950': 39949,
        // '39951': 39949,
        // '39952': 0,
        // '39953': 0,
        // '39954': 0,
        // '39955': 39954,
        // '39956': 0,
        // '39957': 0,
        // '39958': 39957,
        // '39959': 39958,
        // '39960': 0,
        // '39961': 0,
        // '39962': 0,
        // '39963': 0,
        // '39964': 39963,
        // '39965': 39963,
        // '39966': 0,
        // '39967': 39966,
        // '39968': 39966,
        // '39969': 0,
        // '39970': 39969,
        // '39971': 39969,
        // '39972': 0,
        // '39973': 39972,
        // '39974': 39972,
        // '39975': 0,
        // '39976': 0,
        // '39979': 0,
        // '39980': 0,
        // '39981': 0,
        // '39982': 0,
        // '39983': 0,
        // '40054': 39945,
        // '40055': 40054
      }
    //excel export with formula - end
    };
  }


  rename = (obj, curr) => {
    let a = {}
    Object.keys(obj).map((key) => {
      let newKey = key.replace(curr, '')
      Object.assign(a, { [newKey]: obj[key] })
    })
    return a
  }
  generate = () => {
    debugger
    // var doc = new jsPDF('p', 'pt', 'letter');
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      // format: [11.7,16.5]
      format:[16.5,23.3]
    });

    var htmlstring = '';
    var tempVarToCheckPageHeight = 0;
    var pageHeight = 0;
    pageHeight = doc.internal.pageSize.height;
    // specialElementHandlers = {
    //     // element with id of "bypass" - jQuery style selector  
    //     '#bypassme': function (element, renderer) {
    //         // true = "handled elsewhere, bypass text extraction"  
    //         return true
    //     }
    // };
    // margins = {
    //     top: 150,
    //     bottom: 60,
    //     left: 40,
    //     right: 40,
    //     width: 600
    // };

    //Commented on 06-01-2022
    // var y = 20;
    // doc.setLineWidth(2);
    // doc.text(200, y = y + 30, "General Ledger");
    // doc.addPage('a3', 'landscape');
    // doc.autoTable({
    //   html: '#mytable',
    //   startY: 120,
    //   theme: 'grid',
    //   columnStyles: {
    //     0: {
    //       cellWidth: 70,
    //     },
    //     1: {
    //       cellWidth: 70,
    //     },
    //     2: {
    //       cellWidth: 70,
    //     }
    //   },
    //   styles: {
    //     minCellHeight: 40
    //   }
    // })

    var y = 20;
    doc.setLineWidth(2);
    doc.text(10, 0.5, "General Ledger");

    
  let date_text = this.state.date_range == "All" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY");
  if(this.state.date_range == "All") {
     doc.text(10, 0.8,date_text);
  } else {
     doc.text(9.5, 0.8,date_text);
  }

    //doc.addPage('a3', 'landscape');
    doc.autoTable({
      html: '#mytable',
      includeHiddenHtml: false,
      // startY: 0.6
      startY: 0.9
    })
    doc.save('GeneralLedger-' + this.state.export_file_name_date_range +'.pdf');
  }

   //excel export with formula - starts
 onBtExport = () => {
  this.gridRef.current.api.exportDataAsExcel();
}

htmlToJson=(html)=>{
  FetchAllApi.html_to_json_converter({html_content:html}, (err, response) => {


    this.setState(prevState => ({
      defaultExcelExportParams: {
        ...prevState.defaultExcelExportParams,
        fileName: `General-Ledger`+this.state.export_file_name_date_range+`.xls`,
        sheetName:this.state.export_file_name_date_range
      }
    }))

    if (response.status === 1) {

      let columnDefs = [];

      let total_data_object = {
        'Trans#':'Total'
      };

      let excel_column_of_table_heading_obj = {};

      let data = response.data;
      if(this.state.coulmns_head && this.state.coulmns_head.length > 0) {
        let col_number = 0;

        let currently_selected_options = jQuery('#myselect option');
        let active_columns_for_export = [];
     for (var i = 0, iLen = currently_selected_options.length; i < iLen; i++) {
       let opt = currently_selected_options[i];
 
       if (opt.selected) {
         let value = JSON.parse(opt.value);
         active_columns_for_export.push(value.b);
       }
      }

      // if expand view is enabled means add split columns to excel export
      if(this.state.view == false) {
        active_columns_for_export = active_columns_for_export.concat([13,18,26]);
      }

        this.state.coulmns_head.forEach((date,i)=>{
          let actual_col = i + 1;

        if((active_columns_for_export.indexOf(actual_col) >= 0) || (actual_col == 1)) {
          col_number = col_number + 1;

          columnDefs.push({
            field: date.heading_name
          });

        }

          // Generate excel sheet column names like A, B, C....AA,AB,AC...etc
          // let current_column = excelColumnName.intToExcelCol(i+1);
          let current_column = excelColumnName.intToExcelCol(col_number);
          excel_column_of_table_heading_obj[date.heading_name] = current_column;

          //no need here grand total calculated at last
          // if(date.heading_name == 'Debit' || date.heading_name == 'Credit' || date.heading_name == 'Foreign Debit' || date.heading_name == 'Foreign Credit' || date.heading_name == 'Open Balance' || date.heading_name == 'Foreign Open Balance') {
          //   total_data_object[date.heading_name] = `=SUM(${current_column}${row_starts_from}:${current_column}${data.length + 1})`
          // }

        });
      }
  
      if(data.length > 1) {
        data.pop();

        let current_categories_and_sub_categories = {
          names:[],
          names_to_check:[]
        };
        let current_row = row_starts_from -1;
        let current_category_start_index = -1;
        let current_category_end_index = -1;
        let current_category_name = '';
        let current_category_total_name = '';
        let current_category_formula = '';
        let is_transactions_starts = 0;
        let current_category_title_index = -1;

        let category_object = {};
        let grand_total_categories = [];
        data.forEach((d,i)=>{
          current_row = current_row + 1;
          // category title rows
          if(d['Trans#'] && d['Trans#'] != '' && isNaN(d['Trans#'])) {
            // current_categories_and_sub_categories["names"].splice(0,0,d['Trans#']);
            
           // current_categories_and_sub_categories["names"].push(d['Trans#']);
          //  if(current_categories_and_sub_categories["names_to_check"].length > 0 && d['Trans#'] != current_categories_and_sub_categories["names_to_check"][0]) {

          //   if(current_categories_and_sub_categories["names_to_check"].length > 0) {
          //   if((!d['Trans#'].toLowerCase().includes("total")) && (!d['Trans#'].toLowerCase().includes("others"))) {
          //   current_category_name = d['Trans#'];
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`Total ${d['Trans#']}`);
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`Total ${d['Trans#']}- others`);
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`${d['Trans#']} - others`);
          //   }
          //  } else if(current_categories_and_sub_categories["names_to_check"].length == 0) {
          //   if((!d['Trans#'].toLowerCase().includes("total")) && (!d['Trans#'].toLowerCase().includes("others"))) {
          //   current_category_name = d['Trans#'];
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`Total ${d['Trans#']}`);
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`Total ${d['Trans#']}- others`);
          //   current_categories_and_sub_categories["names_to_check"].splice(0,0,`${d['Trans#']} - others`);
          //   }
          //  }

        
          if((!d['Trans#'].toLowerCase().includes("total"))) {
            // cateogry title starts here
            current_category_name = d['Trans#'];
            current_category_total_name = `Total  ${d['Trans#']}`;

            //20240614-start
            current_category_title_index = current_row;
            //20240614-end
          // } else if(d['Trans#'] == current_category_total_name) {
          } else {

            let c_name = d['Trans#'].replace(/ /g, '').toLowerCase();
            let c_total_name = current_category_total_name.replace(/ /g, '').toLowerCase();

            if(c_name == c_total_name) {
            current_category_end_index = current_row - 1;

            //20240614-start
            // if there is no transaction in a category but have open balance means set start index as previous row
              if(current_category_title_index == (current_row - 1)) {
                current_category_start_index = current_row - 1;
              }
            //20240614-end

            d['Debit'] = `=SUM(${excel_column_of_table_heading_obj['Debit']}${current_category_start_index}:${excel_column_of_table_heading_obj['Debit']}${current_category_end_index})`;
            d['Credit'] = `=SUM(${excel_column_of_table_heading_obj['Credit']}${current_category_start_index}:${excel_column_of_table_heading_obj['Credit']}${current_category_end_index})`;
            d['Amount'] = `=SUM(${excel_column_of_table_heading_obj['Amount']}${current_category_start_index}:${excel_column_of_table_heading_obj['Amount']}${current_category_end_index})`;

            d['Open Balance'] = `=SUM(${excel_column_of_table_heading_obj['Open Balance']}${current_category_start_index}:${excel_column_of_table_heading_obj['Open Balance']}${current_category_end_index})`;
            d['Balance'] = this.commaSeperatedValueToNumber(d["Balance"]);

            d['Foreign Debit'] = `=SUM(${excel_column_of_table_heading_obj['Foreign Debit']}${current_category_start_index}:${excel_column_of_table_heading_obj['Foreign Debit']}${current_category_end_index})`;
            d['Foreign Credit'] = `=SUM(${excel_column_of_table_heading_obj['Foreign Credit']}${current_category_start_index}:${excel_column_of_table_heading_obj['Foreign Credit']}${current_category_end_index})`;
            d['Foreign Amount'] = `=SUM(${excel_column_of_table_heading_obj['Foreign Amount']}${current_category_start_index}:${excel_column_of_table_heading_obj['Foreign Amount']}${current_category_end_index})`;
            d['Foreign Open Balance'] = `=SUM(${excel_column_of_table_heading_obj['Foreign Open Balance']}${current_category_start_index}:${excel_column_of_table_heading_obj['Foreign Open Balance']}${current_category_end_index})`;
            d['Foreign Balance'] = this.commaSeperatedValueToNumber(d["Foreign Balance"]);

            if(d['category id'] && this.state.parent_category[d['category id']] && (!d['Trans#'].toLowerCase().includes("others"))) {
              let parnt_category = this.state.parent_category[d['category id']];
              if(category_object[parnt_category] == undefined) {
                category_object[parnt_category] = {
                id:parnt_category,
                sub_category_ids:[],
                sub_category_total_rows:[]
                }
              }
              category_object[parnt_category]["sub_category_ids"].push(d['category id']);
              category_object[parnt_category]["sub_category_total_rows"].push(current_row);
              
            } else if(d['Trans#'].toLowerCase().includes("others")) {
              if(category_object[d['category id']] == undefined) {
                category_object[d['category id']] = {
                id:d['category id'],
                sub_category_ids:[],
                sub_category_total_rows:[]
                }
              }
              category_object[d['category id']]["sub_category_total_rows"].push(current_row);
            } //added to calculate final row
            else if(d['category id'] && this.state.parent_category[d['category id']] == 0 && (!d['Trans#'].toLowerCase().includes("others"))) {
              grand_total_categories.push(current_row);
            }
             
            } else {

              if(d['Trans#'].toLowerCase().includes("others")) {
                if(category_object[d['category id']] == undefined) {
                  category_object[d['category id']] = {
                  id:d['category id'],
                  sub_category_ids:[],
                  sub_category_total_rows:[]
                  }
                }
                category_object[d['category id']]["sub_category_total_rows"].push(current_row);
              } else if(d['category id'] && this.state.parent_category[d['category id']]) {
                let parnt_category = this.state.parent_category[d['category id']];
                if(category_object[parnt_category] == undefined) {
                  category_object[parnt_category] = {
                  id:parnt_category,
                  sub_category_ids:[],
                  sub_category_total_rows:[]
                  }
                }
                category_object[parnt_category]["sub_category_ids"].push(d['category id']);
                category_object[parnt_category]["sub_category_total_rows"].push(current_row);
                
              }
              //added to calculate final row
              else if(d['category id'] && this.state.parent_category[d['category id']] == 0 && (!d['Trans#'].toLowerCase().includes("others"))) {
                grand_total_categories.push(current_row);
              }

              if(category_object[d['category id']] != undefined) {
                  let sub_rows = category_object[d['category id']]["sub_category_total_rows"];
                  if(sub_rows.length > 0) {
                  let debit_string = `=SUM(`;
                  let credit_string = `=SUM(`;
                  let amount_string = `=SUM(`;
                  let open_balance_string = `=SUM(`;
                  let balance_string = `=SUM(`;

                  let foreign_debit_string = `=SUM(`;
                  let foreign_credit_string = `=SUM(`;
                  let foreign_amount_string = `=SUM(`;
                  let foreign_open_balance_string = `=SUM(`;
                  let foreign_balance_string = `=SUM(`;
                  
                  sub_rows.forEach((sr,l)=>{
                    debit_string = debit_string + `${excel_column_of_table_heading_obj['Debit']}${sr},`;
                    credit_string = credit_string + `${excel_column_of_table_heading_obj['Credit']}${sr},`;
                    amount_string = amount_string + `${excel_column_of_table_heading_obj['Amount']}${sr},`;
                    open_balance_string = open_balance_string + `${excel_column_of_table_heading_obj['Open Balance']}${sr},`;
                    balance_string = balance_string + `${excel_column_of_table_heading_obj['Balance']}${sr},`;

                    foreign_debit_string = foreign_debit_string + `${excel_column_of_table_heading_obj['Foreign Debit']}${sr},`;
                    foreign_credit_string = foreign_credit_string + `${excel_column_of_table_heading_obj['Foreign Credit']}${sr},`;
                    foreign_amount_string = foreign_amount_string + `${excel_column_of_table_heading_obj['Foreign Amount']}${sr},`;
                    foreign_open_balance_string = foreign_open_balance_string + `${excel_column_of_table_heading_obj['Foreign Open Balance']}${sr},`;
                    foreign_balance_string = foreign_balance_string + `${excel_column_of_table_heading_obj['Foreign Balance']}${sr},`;
                  });
                  debit_string = debit_string.substring(0, debit_string.length - 1) + `)`;
                  credit_string = credit_string.substring(0, credit_string.length - 1) + `)`;
                  amount_string = amount_string.substring(0, amount_string.length - 1) + `)`;
                  open_balance_string = open_balance_string.substring(0, open_balance_string.length - 1) + `)`;
                  balance_string = balance_string.substring(0, balance_string.length - 1) + `)`;

                  foreign_debit_string = foreign_debit_string.substring(0, foreign_debit_string.length - 1) + `)`;
                  foreign_credit_string = foreign_credit_string.substring(0, foreign_credit_string.length - 1) + `)`;
                  foreign_amount_string = foreign_amount_string.substring(0, foreign_amount_string.length - 1) + `)`;
                  foreign_open_balance_string = foreign_open_balance_string.substring(0, foreign_open_balance_string.length - 1) + `)`;
                  foreign_balance_string = foreign_balance_string.substring(0, foreign_balance_string.length - 1) + `)`;
                  
                  d['Debit'] = debit_string;
                  d['Credit'] = credit_string;
                  d['Amount'] = amount_string;
                  d['Open Balance'] = open_balance_string;
                //  d['Balance'] = balance_string;
                  d['Balance'] = this.commaSeperatedValueToNumber(d["Balance"]);

                  d['Foreign Debit'] = foreign_debit_string;
                  d['Foreign Credit'] = foreign_credit_string;
                  d['Foreign Amount'] = foreign_amount_string;
                  d['Foreign Open Balance'] = foreign_open_balance_string;
                 // d['Foreign Balance'] = foreign_balance_string;
                  d['Foreign Balance'] = this.commaSeperatedValueToNumber(d["Foreign Balance"]);
                }
              }
            }
          }

           if(is_transactions_starts == 1) {
            is_transactions_starts = 0;
           }
            
          } else {

            if(d['Trans#'] && d['Trans#'] != '') {
            if(is_transactions_starts == 0) {
              current_category_start_index = current_row;
              is_transactions_starts = 1;
            }
      
          d.Debit = this.commaSeperatedValueToNumber(d.Debit);
          d.Credit = this.commaSeperatedValueToNumber(d.Credit);
          d.Amount = this.commaSeperatedValueToNumber(d.Amount);
          d["Open Balance"] = this.commaSeperatedValueToNumber(d["Open Balance"]);
          d["Balance"] = this.commaSeperatedValueToNumber(d["Balance"]);

          d["Foreign Debit"] = this.commaSeperatedValueToNumber(d["Foreign Debit"]);
          d["Foreign Credit"] = this.commaSeperatedValueToNumber(d["Foreign Credit"]);
          d["Foreign Amount"] = this.commaSeperatedValueToNumber(d["Foreign Amount"]);
          d["Foreign Open Balance"] = this.commaSeperatedValueToNumber(d["Foreign Open Balance"]);
          d["Foreign Balance"] = this.commaSeperatedValueToNumber(d["Foreign Balance"]);
          }
      }
      });

     if(grand_total_categories.length > 0) {

        let debit_string = `=SUM(`;
        let credit_string = `=SUM(`;
        let amount_string = `=SUM(`;
        let open_balance_string = `=SUM(`;
        let balance_string = `=SUM(`;

        let foreign_debit_string = `=SUM(`;
        let foreign_credit_string = `=SUM(`;
        let foreign_amount_string = `=SUM(`;
        let foreign_open_balance_string = `=SUM(`;
        let foreign_balance_string = `=SUM(`;
        
        grand_total_categories.forEach((sr,l)=>{
          debit_string = debit_string + `${excel_column_of_table_heading_obj['Debit']}${sr},`;
          credit_string = credit_string + `${excel_column_of_table_heading_obj['Credit']}${sr},`;
          amount_string = amount_string + `${excel_column_of_table_heading_obj['Amount']}${sr},`;
          open_balance_string = open_balance_string + `${excel_column_of_table_heading_obj['Open Balance']}${sr},`;
          balance_string = balance_string + `${excel_column_of_table_heading_obj['Balance']}${sr},`;

          foreign_debit_string = foreign_debit_string + `${excel_column_of_table_heading_obj['Foreign Debit']}${sr},`;
          foreign_credit_string = foreign_credit_string + `${excel_column_of_table_heading_obj['Foreign Credit']}${sr},`;
          foreign_amount_string = foreign_amount_string + `${excel_column_of_table_heading_obj['Foreign Amount']}${sr},`;
          foreign_open_balance_string = foreign_open_balance_string + `${excel_column_of_table_heading_obj['Foreign Open Balance']}${sr},`;
          foreign_balance_string = foreign_balance_string + `${excel_column_of_table_heading_obj['Foreign Balance']}${sr},`;
        });
        debit_string = debit_string.substring(0, debit_string.length - 1) + `)`;
        credit_string = credit_string.substring(0, credit_string.length - 1) + `)`;
        amount_string = amount_string.substring(0, amount_string.length - 1) + `)`;
        open_balance_string = open_balance_string.substring(0, open_balance_string.length - 1) + `)`;
        balance_string = balance_string.substring(0, balance_string.length - 1) + `)`;

        foreign_debit_string = foreign_debit_string.substring(0, foreign_debit_string.length - 1) + `)`;
        foreign_credit_string = foreign_credit_string.substring(0, foreign_credit_string.length - 1) + `)`;
        foreign_amount_string = foreign_amount_string.substring(0, foreign_amount_string.length - 1) + `)`;
        foreign_open_balance_string = foreign_open_balance_string.substring(0, foreign_open_balance_string.length - 1) + `)`;
        foreign_balance_string = foreign_balance_string.substring(0, foreign_balance_string.length - 1) + `)`;
        
        total_data_object['Debit'] = debit_string;
        total_data_object['Credit'] = credit_string;
        total_data_object['Amount'] = amount_string;
        total_data_object['Open Balance'] = open_balance_string;
        total_data_object['Balance'] = balance_string;

        total_data_object['Foreign Debit'] = foreign_debit_string;
        total_data_object['Foreign Credit'] = foreign_credit_string;
        total_data_object['Foreign Amount'] = foreign_amount_string;
        total_data_object['Foreign Open Balance']= foreign_open_balance_string;
        total_data_object['Foreign Balance']= foreign_balance_string;
     }
      data.push(total_data_object);
      }

       let header_data = [{ 'Trans#': this.state.date_range == "All" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY") }];
       header_data = header_data.concat(data);
  
      this.setState({
        export_formula_column_defs:columnDefs,
        export_formula_row_data:header_data
      },()=>{
        this.onBtExport();
      });
    } else {
      alert('Failed to export excel');
    }
  });

}

commaSeperatedValueToNumber = (val)=> {
  if(val == '') {
    return val;
  } else {
  if(typeof val == 'string') {
    val = val.replace(/\s/g, '');
  }
  return parseFloat(val.replace(/,/g, ''));
}
}
//excel export with formula - end


// expand or collapse view
  showHide = () => {
    this.setState({ view: !this.state.view });

    setTimeout(() => {
      if (this.state.view) {
        jQuery("td:nth-child(" + 13 + "),th:nth-child(" + 13 + ")").hide();
        jQuery("td:nth-child(" + 18 + "),th:nth-child(" + 18 + ")").hide();
        jQuery("td:nth-child(" + 26 + "),th:nth-child(" + 26 + ")").hide();
      } else {
        jQuery("td:nth-child(" + 13 + "),th:nth-child(" + 13 + ")").show();
        jQuery("td:nth-child(" + 18 + "),th:nth-child(" + 18 + ")").show();
        jQuery("td:nth-child(" + 26 + "),th:nth-child(" + 26 + ")").show();
      }
    }, 2000);

     //2023-05-06-newly added to hide some columns in split breakdown rows
     try{
     setTimeout(() => {
     let currently_selected_options = jQuery('#myselect option');

     for (var i = 0, iLen = currently_selected_options.length; i < iLen; i++) {
       let opt = currently_selected_options[i];
 
       if (opt.selected) {
         let value = JSON.parse(opt.value);

       

      // result.push(value.b || opt.text);
      // names.push(value.a || opt.text);
         jQuery(
           "td:nth-child(" + value.b + "),th:nth-child(" + value.b + ")"
         ).show();
       } else {
         let value = JSON.parse(opt.value);
         jQuery(
           "td:nth-child(" + value.b + "),th:nth-child(" + value.b + ")"
         ).hide();
       }
     }
    // this.setState({ ColscountArray: result }, this.filterFilters(names));
  }, 2000);
} catch(exception) {
console.log(exception);
}
  //newly added end here

  };

  sortByNames = () => {
    let report_id = this.state.all_report_name_id;
    // alert('hjgh')
    FetchAllApi.reportSortbyOptions(report_id, (err, response) => {
      if (response.status === 1) {
        console.log("rty", response);
        this.setState({ sortBynames: response.list });
      } else {
      }
    });
  };

  repeat = (sub_categories, paddingLeft) => {
    console.log("july", paddingLeft);

    if (sub_categories) {
      return (
        <React.Fragment>
          {sub_categories &&
            sub_categories.length > 0 &&
            Object.keys(sub_categories[0]).map((itm, i) => {
              // console.log('july',sub_categories[0][itm])
              return (
                <React.Fragment key={i}>
                  {/* invoice heading others */}
                  {sub_categories[0][itm].invoices &&
                    !isNaN(Number(sub_categories[0][itm].total_amount)) &&
                    sub_categories[0][itm].is_transactions_available == 1
                    // Number(sub_categories[0][itm].total_amount) != 0 

                    &&

                    (
                      <React.Fragment>
                        <tr class="item-step1 sub-title">
                          <td
                            style={{
                              paddingLeft: `${paddingLeft}px`,
                              // position: "sticky",
                               backgroundColor: "#EFEFFF",
                              left: "0.25rem",
                            }}
                          >
                            <div> {sub_categories[0][itm].category_name} </div>
                          </td>

                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>

                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>

                          <td>
                            <div> </div>
                          </td>

                        {/* excel export */}
                          <td>
                            <div> </div>
                          </td>
                          {/* excel export end*/}


                          <td class="trans" style={{ paddingLeft: 1 }}>
                            <div>
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].prevoius_closing_balance)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].prevoius_closing_balance
                                )
                              )
                                ? "0.00"
                                : Number(
                                  sub_categories[0][itm]
                                    .prevoius_closing_balance
                                ).toFixed(2)} */}
                            </div>{" "}
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td class="trans" style={{ paddingLeft: 1 }}>
                            <div></div>
                          </td>
                          <div>
                            {" "}
                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                              { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].prevoius_closing_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                            {/* {isNaN(
                              Number(
                                sub_categories[0][itm]
                                  .prevoius_closing_foreign_balance
                              )
                            )
                              ? "0.00"
                              : Number(
                                sub_categories[0][itm]
                                  .prevoius_closing_foreign_balance
                              ).toFixed(2)} */}
                          </div>
                          <td>
                            <div> </div>
                          </td>
                          {/* 2023-05-02 formula export*/}
                          {/* <td>
                            <div> </div>
                          </td> */}
                            <td>
                            <div>{sub_categories[0][itm].category_id}</div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                          <td>
                            <div> </div>
                          </td>
                        </tr>

                        {sub_categories[0][itm].is_child_data_available == 1 &&
                          this.repeat(
                            sub_categories[0][itm].sub_categories,
                            paddingLeft + 45
                          )}
                        {sub_categories[0][itm].is_child_data_available == 1 &&
                          sub_categories[0][itm].invoices &&
                          sub_categories[0][itm].invoices.length > 0 && (
                            <tr class="item-step1 sub-title">
                              <td
                                style={{
                                  paddingLeft: `${paddingLeft}px`,
                                  // position: "sticky",
                                  left: "0.25rem", backgroundColor: "#EFEFFF"
                                }}
                              >
                                <div>
                                  {sub_categories[0][itm].category_name} {""}-
                                  {""} others{" "}
                                </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>

                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>

                              <td>
                                <div> </div>
                              </td>

                            {/* excel export */}
                              <td>
                                <div> </div>
                              </td>
                            {/* excel export */}

                              <td class="trans" style={{ paddingLeft: 1 }}>
                                <div>
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].prevoius_closing_others_balance)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .prevoius_closing_others_balance
                                    )
                                  )
                                    ? "0.00"
                                    : Number(
                                      sub_categories[0][itm]
                                        .prevoius_closing_others_balance
                                    ).toFixed(2)} */}
                                </div>{" "}
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td class="trans" style={{ paddingLeft: 1 }}>
                                <div></div>
                              </td>
                              <div>
                                {" "}
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].prevoius_closing_others_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(
                                  Number(
                                    sub_categories[0][itm]
                                      .prevoius_closing_others_foreign_balance
                                  )
                                )
                                  ? "0.00"
                                  : Number(
                                    sub_categories[0][itm]
                                      .prevoius_closing_others_foreign_balance
                                  ).toFixed(2)} */}
                              </div>
                              <td>
                                <div> </div>
                              </td>
                              {/* 2023-05-02 formula export */}
                              {/* <td>
                                <div> </div>
                              </td> */}
                                <td>
                                <div>{sub_categories[0][itm].category_id}</div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                              <td>
                                <div> </div>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    )}

                  {/* others total */}

                  {/* others map */}
                  {sub_categories[0][itm].invoices &&
                    !isNaN(Number(sub_categories[0][itm].total_amount)) &&
                    // Number(sub_categories[0][itm].total_amount) != 0 
                    sub_categories[0][itm].is_transactions_available == 1
                    &&
                    sub_categories[0][itm].invoices.map((e1) => {
                      return (
                        <>
                          <tr
                            className="item-step1"
                            onClick={() => {
                              localStorage.setItem('comingFrom', 'General Ledger')
                              var elmnt = document.getElementById(
                                "sticky-tb-hdr"
                              );
                              var x = elmnt.scrollLeft;
                              var y = elmnt.scrollTop;
                              var value = x + "=" + y;
                              localStorage.setItem("scrollposition", value);

                              // alert(jQuery(this).parents("tr").get(0).rowIndex)
                              if (
                                e1.type == "Sales Invoice" ||
                                e1.type == "Customer Single Payment"
                              ) {
                                if (e1.type == "Sales Invoice") {
                                  var setID = e1.trans;
                                } else if (e1.type == "Customer Single Payment") {
                                  var setID = e1.invoice_id + "=" + e1.trans;
                                }

                                localStorage.setItem("invoice_id", setID);
                                localStorage.setItem("job_id", e1.job_id);
                                // alert("job id" + e1.job_id);

                                var win = window.open(
                                  "/create_invoice",
                                  "_blank"
                                );
                                win.focus();
                              }

                              if (e1.type == "Bill") {
                                if (e1.type == "Bill") {
                                  let arr = [e1.type, e1.invoice_id];
                                  console.log("hy", e1);
                                  localStorage.setItem(
                                    "vendor_bill",
                                    JSON.stringify(arr)
                                  );
                                }

                                var win = window.open(
                                  "/data_tagging/" +
                                  e1.list_id +
                                  "/" +
                                  e1.file_id,
                                  "_blank"
                                );
                                win.focus();
                              }

                              if (e1.type == "Vendor Single Payment") {
                                let arr = [
                                  "Bill payment",// e1.type,
                                  e1.invoice_id,
                                  e1.payment_id,
                                ];

                                localStorage.setItem(
                                  "vendor_bill",
                                  JSON.stringify(arr)
                                );

                                // alert(e1.payment_id)
                                // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
                                var win = window.open(
                                  "/data_tagging/" +
                                  e1.list_id +
                                  "/" +
                                  e1.file_id,
                                  "_blank"
                                );
                                win.focus();
                              }

                              if (e1.type == "Customer credit note") {
                                var setID = e1.credit_memo_id;

                                localStorage.setItem("credit_id", setID);
                                window.open("/create_creditmemo?memo_id=" + setID)
                                // alert(e1.credit_memo_id);

                                // var win = window.open(
                                //   "/create_creditmemo",
                                //   "_blank"
                                // );
                                // win.focus();
                              }
                              // if (
                              //   e1.type == "Bill" ||
                              //   e1.type == "Bill payment" ||  e1.type == "Credit memo"
                              // ) {
                              //   if (e1.type == "Bill") {
                              //     var setID = e1.trans;
                              //   } else if (e1.type == "Bill payment") {
                              //     var setID = e1.invoice_id + "=" + e1.trans;
                              //   } else if (e1.type == "Credit memo") {
                              //     var setID = e1.trans;
                              //   }

                              // localStorage.setItem("invoice_id", setID);

                              //   var win = window.open(
                              //     "/create_invoice",
                              //     "_blank"
                              //   );
                              //   win.focus();
                              // }

                              // if (
                              //   e1.type == "Bill" ||
                              //   e1.type == "Bill payment" ||  e1.type == "Credit"
                              // ) {

                              //   if( e1.type == "Bill"  || e1.type == "Credit"){
                              //     let arr = [ e1.type,e1.invoice_id]
                              //     localStorage.setItem("vendor_bill", JSON.stringify(arr)
                              //     );
                              //   }

                              //   var win = window.open(
                              //     '/data_tagging/' +  e1.list_id + '/' +  e1.file_id,
                              //         "_blank"
                              //       );
                              //       win.focus();
                              //   }
                              // if (
                              //   e1.type == "Bill" ||
                              //   e1.type == "Bill payment" ||  e1.type == "Credit memo"
                              // ) {

                              //   // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)

                              //   var win = window.open(
                              //     '/data_tagging/' +  e1.list_id + '/' +  e1.file_id,
                              //         "_blank"
                              //       );
                              //       win.focus();
                              // }


                              if (
                                e1.type == "Vendor credit note"
                              ) {
                                let arr = [
                                  e1.type,
                                  e1.credit_memo_id,
                                ];
                                console.log(
                                  "hy",
                                  e1.credit_memo_id
                                );
                                localStorage.setItem(
                                  "vendor_bill",
                                  JSON.stringify(arr)
                                );


                                var win = window.open(
                                  "/data_tagging/" +
                                  e1.list_id +
                                  "/" +
                                  e1.file_id,
                                  "_blank"
                                );
                                win.focus();
                              }


                              if (
                                e1.type == "Customer Multipayment"
                              ) {

                                let arr = [
                                  e1.customer_id,
                                  e1.multi_payment_applied_invoices,
                                ];

                                localStorage.setItem(
                                  "edit_customer_receive_payment",
                                  JSON.stringify(arr)
                                );


                                var win = window.open('/Customer_receive_payment', "_blank");
                                win.focus();
                              }

                              if (
                                e1.type == "Customer Multipayment - Refund"
                              ) {

                                let arr = [
                                  e1.customer_id,
                                  e1.multi_payment_applied_invoices,
                                ];

                                localStorage.setItem(
                                  "edit_customer_receive_payment",
                                  JSON.stringify(arr)
                                );


                                var win = window.open('/Customer_receive_payment', "_blank");
                                win.focus();
                              }

                              if (
                                e1.type == "Vendor Multipayment"
                              ) {
                                let arr = [
                                  e1.vendor_id,
                                  e1.multi_payment_applied_invoices,
                                ];

                                localStorage.setItem(
                                  "edit_customer_receive_payment",
                                  JSON.stringify(arr)
                                );


                                var win = window.open('/vendor_bill_payment', "_blank");
                                win.focus();
                              }

                            }}
                          >
                            <td
                              className=".headcol "
                              style={{ paddingLeft: `${paddingLeft}px` }}
                            >
                              <span>{e1.transaction_number}</span>
                            </td>
                            <td className="">
                              <span>{e1.type}</span>
                            </td>
                            <td className="">
                              <span>{e1.last_modified}</span>
                            </td>
                            <td className="">
                              <span>{e1.last_modified_by}</span>
                            </td>
                            <td className="">
                              <span>{e1.transaction_date}</span>
                            </td>
                            <td className="">
                              <span>{e1.num}</span>
                            </td>
                            <td className="">
                              <span>{e1.name}</span>
                            </td>

                            <td className="">
                              <span>{e1.source_name}</span>
                            </td>
                            <td className="">
                              <span>{e1.job_name}</span>
                            </td>
                            <td className="">
                              <span>{e1.memo}</span>
                            </td>
                            <td className="">
                              <span>{e1.account}</span>
                            </td>
                            <td className="">
                              <span>{e1.split != "" ? e1.split : ""}</span>
                            </td>
                            <td>
                              <span>{""} </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.open_balance_home_currency}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_home_currency)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(
                                  Number(e1.open_balance_home_currency).toFixed(
                                    2
                                  )
                                )
                                  ? "0"
                                  : Number(
                                    e1.open_balance_home_currency
                                  ).toFixed(2)} */}
                              </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.debit}</span>
                  </td>
                  <td className=''>
                    <span>{e1.credit}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.debit)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.debit).toFixed(2))
                                  ? "0"
                                  : Number(e1.debit).toFixed(2)} */}
                              </span>
                            </td>

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.credit)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.credit).toFixed(2))
                                  ? "0"
                                  : Number(e1.credit).toFixed(2)} */}
                              </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.amount}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.amount)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.amount).toFixed(2))
                                  ? "0"
                                  : Number(e1.amount).toFixed(2)} */}
                              </span>
                            </td>
                            <td>
                              <span>{""} </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.balance}</span>
                  </td> */}
                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.balance)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.balance).toFixed(2))
                                  ? "0"
                                  : Number(e1.balance).toFixed(2)} */}
                              </span>
                            </td>
                            <td className="">
                              <span>{e1.currency}</span>
                            </td>
                            <td className="">
                              <span>{e1.exchange_rate}</span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.open_balance_foreign_currency}</span>
                  </td> */}
                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_foreign_currency)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(
                                  Number(
                                    e1.open_balance_foreign_currency
                                  ).toFixed(2)
                                )
                                  ? "0"
                                  : Number(
                                    e1.open_balance_foreign_currency
                                  ).toFixed(2)} */}
                              </span>
                            </td>

                            {/* <td className=''>
                    <span>{e1.foreign_debit}</span>
                  </td> */}
                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.foreign_debit).toFixed(2))
                                  ? "0"
                                  : Number(e1.foreign_debit).toFixed(2)} */}
                              </span>
                            </td>

                            {/* <td className=''>
                    <span>{e1.foreign_credit}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.foreign_credit).toFixed(2))
                                  ? "0"
                                  : Number(e1.foreign_credit).toFixed(2)} */}
                              </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.foreign_amount}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.foreign_amount).toFixed(2))
                                  ? "0"
                                  : Number(e1.foreign_amount).toFixed(2)} */}
                              </span>
                            </td>
                            <td>
                              <span>{""} </span>
                            </td>

                            {/* <td className=''>
                    <span>{e1.split != '' ? e1.split : ''}</span>
                  </td> */}

                            <td className="">
                              <span>
                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                {/* {isNaN(Number(e1.foreign_balance).toFixed(2))
                                  ? "0"
                                  : Number(e1.foreign_balance).toFixed(2)} */}
                              </span>
                            </td>
                            {/* <td className=''>
                    <span>{e1.foreign_balance}</span>
                  </td> */}
                            <td className="">
                              <span>{e1.aging}</span>
                            </td>
                            {/* <td className="">
                              <span>{e1.terms != null ? e1.terms : ""}</span>
                            </td> */}
                             <td className="">
                             <span>{sub_categories[0][itm].category_id}</span>
                            </td>
                            <td className="">
                              <span>{e1.contact}</span>
                            </td>
                            <td className="">
                              <span>{e1.postal_code}</span>
                            </td>
                            <td className="">
                              <span>{e1.province}</span>
                            </td>
                            <td className="">
                              <span>{e1.city}</span>
                            </td>
                            <td className="">
                              <span>{e1.address}</span>
                            </td>
                            <td className="">
                              <span>{e1.email}</span>
                            </td>
                            <td className="">
                              <span>{e1.phone}</span>
                            </td>
                            <td className="">
                              <span>{e1.fax}</span>
                            </td>
                          </tr>
                          {this.state.view == false &&
                            e1.split_breakdown_string &&
                            e1.split_breakdown_string.map((item, i) => {
                              return (
                                <tr className="item-step1 istep-2 title1">
                                  <td>
                                    <span> </span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>

                                  {/* added for formula export purpose */}
                                  <td>
                                    <span className=""></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  {/* added for formula export purpose - end*/}

                                  <td>
                                    <span className="">
                                      {e1.split_breakdown_string[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span className=""></span>
                                  </td>
                                  <td>
                                    <span className=""></span>
                                  </td>{" "}
                                  <td>
                                    <span className=""></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className="">
                                      {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                        { style: 'currency', currency: this.state.home_currency }).format(e1.split_breakdown_amount[i])).replace(this.state.home_currency_symbol, '')}
                                      {/* {e1.split_breakdown_amount[i]} */}
                                    </span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className="">
                                      {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                        { style: 'currency', currency: this.state.home_currency }).format(e1.split_breakdown_foreign_amount[i])).replace(this.state.home_currency_symbol, '')}
                                      {/* {e1.split_breakdown_foreign_amount[i]} */}
                                    </span>
                                  </td>
                                  <td>
                                    <span className=" "></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}

                                  {/* 2023-05-02 formula export*/}
                                  <td>
                                    <span className="text-right">
                                    {sub_categories[0][itm].category_id}
                                    </span>
                                  </td>
                                    <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                  <td>
                                    <span className="text-right"></span>
                                  </td>{" "}
                                </tr>
                              );
                            })}
                        </>
                      );
                    })}
                  {sub_categories[0][itm].invoices &&
                    !isNaN(Number(sub_categories[0][itm].total_amount)) &&
                    sub_categories[0][itm].is_transactions_available == 1
                    // Number(sub_categories[0][itm].total_amount) != 0 

                    && (
                      <React.Fragment>
                        {sub_categories[0][itm].is_child_data_available == 1 &&
                          sub_categories[0][itm].invoices &&
                          sub_categories[0][itm].invoices.length > 0 && (
                            <tr className="item-step1 istep-2 title1">
                              <td style={{ paddingLeft: `${paddingLeft}px` }}>
                                <span>
                                  {/* 2023-05-02 */}
                                  {/* Total {""}{" "}
                                  {sub_categories[0][itm].category_name} {""}-
                                  {""} others{" "} */}
                                  Total {""}{" "}
                                  {sub_categories[0][itm].category_name}-
                                  {""} others{" "}
                                </span>
                              </td>
                              {/* 
                                  <td>
                                    <span className='text-right'>
                                      {Number(invoices.total_amount).toFixed(
                                        2
                                      )}
                                    </span>
                                  </td> */}
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_open_balance)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .total_others_open_balance
                                    ).toFixed(2)
                                  )
                                    ? "0.00"
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_open_balance
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className="">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_debit)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm].total_others_debit
                                    ).toFixed(2)
                                  )
                                    ? "0.00"
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_debit
                                    ).toFixed(2)} */}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_credit)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm].total_others_credit
                                    ).toFixed(2)
                                  )
                                    ? "0.00"
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_credit
                                    ).toFixed(2)}{" "} */}
                                </span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_amount)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm].total_others_amount
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_amount
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].current_closing_others_balance)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .current_closing_others_balance
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .current_closing_others_balance
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .total_others_open_foreign_balance
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_open_foreign_balance
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_debit
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_debit
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_credit
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_credit
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_others_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_amount
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .total_others_foreign_amount
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className=" ">
                                  {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                    { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].current_closing_others_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                  {/* {isNaN(
                                    Number(
                                      sub_categories[0][itm]
                                        .current_closing_others_foreign_balance
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : Number(
                                      sub_categories[0][itm]
                                        .current_closing_others_foreign_balance
                                    ).toFixed(2)} */}
                                </span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}

                              {/* 2023-05-02 formula export */}
                              {/* <td>
                                <span className="text-right"></span>
                              </td>{" "} */}

                              <td><span className="text-right">{sub_categories[0][itm].category_id}</span></td>

                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>{" "}
                              <td>
                                <span className="text-right"></span>
                              </td>
                            </tr>
                          )}
                        <tr className="item-step1 istep-2 title1">
                          <td style={{ paddingLeft: `${paddingLeft}px` }}>
                            <span>
                              {/* 2023-04-29 */}
                              {/* Total {sub_categories[0][itm].category_name}{" "} */}
                              Total {" "}{sub_categories[0][itm].category_name}{" "}
                            </span>
                          </td>
                          {/* 
                                  <td>
                                    <span className='text-right'>
                                      {Number(invoices.total_amount).toFixed(
                                        2
                                      )}
                                    </span>
                                  </td> */}
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_open_balance)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_open_balance
                                ).toFixed(2)
                              )
                                ? "0.00"
                                : Number(
                                  sub_categories[0][itm].total_open_balance
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className="">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_debit)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_debit
                                ).toFixed(2)
                              )
                                ? "0.00"
                                : Number(
                                  sub_categories[0][itm].total_debit
                                ).toFixed(2)} */}
                            </span>
                          </td>{" "}
                          <td>
                            <span className="">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_credit)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_credit
                                ).toFixed(2)
                              )
                                ? "0.00"
                                : Number(
                                  sub_categories[0][itm].total_credit
                                ).toFixed(2)}{" "} */}
                            </span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_amount)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_amount
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm].total_amount
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].current_closing_balance)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].current_closing_balance
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm]
                                    .current_closing_balance
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm]
                                    .total_open_foreign_balance
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm]
                                    .total_open_foreign_balance
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_foreign_debit
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm].total_foreign_debit
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_foreign_credit
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm].total_foreign_credit
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].total_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm].total_foreign_amount
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm].total_foreign_amount
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className=" ">
                              {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                { style: 'currency', currency: this.state.home_currency }).format(sub_categories[0][itm].current_closing_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                              {/* {isNaN(
                                Number(
                                  sub_categories[0][itm]
                                    .current_closing_foreign_balance
                                ).toFixed(2)
                              )
                                ? 0
                                : Number(
                                  sub_categories[0][itm]
                                    .current_closing_foreign_balance
                                ).toFixed(2)} */}
                            </span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}

                          {/* 2023-05-03 formula export */}
                          {/* <td>
                            <span className="text-right"></span>
                          </td>{" "} */}

                          <td>
                            <span className="text-right">{ sub_categories[0][itm].category_id}</span>
                          </td>

                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                          <td>
                            <span className="text-right"></span>
                          </td>{" "}
                        </tr>
                      </React.Fragment>
                    )}
                  {/* others  ends */}
                </React.Fragment>
              );
            })}
        </React.Fragment>
      );
    }
  };

  all_report_name = () => {
    FetchAllApi.all_report_name((err, response) => {
      if (response.status === 1) {
        console.log("nvnvnv", response);
        let report_ids = response.response;
        for (var i = 0; i < report_ids.length; i++) {
          if (report_ids[i].column_name == "General_ledger") {
            this.setState(
              { all_report_name_id: report_ids[i].report_id },
              () => {
                //commented on 21-06-2022
                // this.callAPIDATA();
                this.sortByNames();
              }
            );
          }
        }
      } else {
      }
    });
  };

  vendor_type = () => {
    var client_id = this.state.logged_client_id;

    FetchAllApi.vendorTypes(client_id, (err, response) => {
      console.log("Customer list", response);

      if (response.status === 1) {
        this.setState({ vendor_type: response.list });
      } else {
        this.setState({ vendor_type: [] });
      }
    });
  };

  customer_type = () => {
    FetchAllApi.customerTypes((err, response) => {
      console.log("Customer list", response);
      if (response.status === 1) {
        this.setState({ customer_type: response.lists });
      } else {
        this.setState({ customer_type: [] });
      }
    });
  };

  paymentTerms = () => {
    FetchAllApi.payment_terms((err, response) => {
      console.log("Customer list", response);
      if (response.status === 1) {
        this.setState({ paymentTerms: response.lists });
      } else {
        this.setState({ paymentTerms: [] });
      }
    });
  };

  get_vendorNames = () => {
    let client_id = this.state.logged_client_id;
    let filter_id = 2;
    // alert('hjgh')
    FetchAllApi.getVendorNames(client_id,filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ vendorNames: response.list });
      } else {
      }
    });
  };
  get_currencies = () => {
    // fetch("https://api.exchangerate-api.com/v4/latest/SGD")
    fetch(`https://api.currencylayer.com/live?access_key=${config.api_key}&source=SGD`)

      .then((response) => response.json())
      .then((data) => {
        let newObj = this.rename(data.quotes, 'SGD')

        const currencyAr = [];
        let first = newObj;
        for (const key in first) {
          currencyAr.push(key);
        }
        // no need to update here
      //  this.setState({ currencies: currencyAr, currency_clone: currencyAr });
      });
  };

  componentWillMount() {

    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push("/");
    }


    this.get_currencies();
    this.get_col();
    this.all_report_name();
    if (
      this.state.logged_user_id === "" ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push("/");
    }



  }

  comma = (w) => {
    var amt = w;
    var x = amt.toLocaleString();
    // alert(x);
    var y = Number(w).toFixed(2);
    //  alert(y);
  };

  componentDidUpdate() {
    setTimeout(() => {
      this.kks();
    }, 4000);
    window.jQuery(".selectpicker").selectpicker("refresh");
    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy", autoclose: true });
    this.setColCount();
  }

  setColCount = () => {
    var foo = document.getElementById("mytable");
    if (foo) {
      let count = foo.tBodies["0"].firstElementChild.children.length;
      if (this.state.tableSize === count) {
      } else {
        this.setState({ tableSize: count });
      }
    }
  };
  kks = () => {
    //   var THIS=this;
    //   jQuery("#mytable td").click(function() {
    //     // alert(jQuery(this).parents("tr").get(0).rowIndex)
    // var myval=jQuery(this).parents("tr").get(0).rowIndex
    //     // this.setState({specificRowIndex:myval});
    //     THIS.setState({specificRowIndex:myval},()=>{
    //       // alert(THIS.state.specificRowIndex)
    //     });
    // });
  };



  all_account_list = () => {
    let input = {
      client_id: this.state.logged_client_id
    }
    // alert('hjgh')
    FetchAllApi.all_account_list(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ all_account_list: response.list });
      } else {
      }
    });
  };


  all_transaction_type_list = () => {
    let input = {
      client_id: this.state.logged_client_id
    }
    // alert('hjgh')
    FetchAllApi.all_transaction_type_list(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ all_transaction_type_list: response.list });
      } else {
      }
    });
  };

  job_name_list = () => {
    let input = {
      client_id: this.state.logged_client_id
    }
    // alert('hjgh')
    FetchAllApi.job_name_list(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ job_name_list: response.list });
      } else {
      }
    });
  };



  componentDidMount() {
    // window.jQuery(document).ready(function () {
    // window.jQuery('#mytable').sticky({
    //  'top': " tr:first-child",
    //  'left': " tr td:first-child",
    // 'left': " #oneSticky",
    // 'left': " #two",
    //  "right": " tr td:last-child",
    //  "bottom": " tr:last-child"
    // });

    // setTimeout(function () {
    // - $('.table-base').unstick();
    // }, 1000);
    // })



    this.all_account_list()
    this.all_transaction_type_list()
    this.job_name_list()

    this.watchMe();
    document
      .getElementById("sticky-tb-hdr")
      .addEventListener("scroll", function () {
        var translate = "translate(0," + this.scrollTop + "px)";
        if (
          this.querySelector("thead") != null &&
          this.querySelector("thead") != undefined &&
          this.querySelector("thead").style != null
        ) {
          this.querySelector("thead").style.transform = translate;
        }
      });

    // alert(this.state.logged_client_id)
    this.vendor_type();
    this.customer_type();
    this.paymentTerms();
    this.show_columnslist();
    this.get_vendorNames();

    const urlParams = new URLSearchParams(window.location.search);
    let start = urlParams.get("start");
    let end = urlParams.get("end");
    let range = urlParams.get("range");
    let is_export = urlParams.get("is_export");
    let export_type = urlParams.get("export_type");

    //newly added for all reports open
    let is_from_all_report = urlParams.get("is_from_all_report");
    if(is_from_all_report == 1 && urlParams != null && urlParams != undefined ) {
      this.setState({ 
        start_date: start, 
        end_date: end,
        is_export:is_export?is_export:0,
        export_type:export_type?export_type:''
      },()=>{
        this.callAPIDATA();
      });
      document.getElementById("fromdate").value = moment(start).format("DD-MM-YYYY");
      document.getElementById("todate").value = moment(end).format("DD-MM-YYYY");;

    } else 
    if (localStorage.getItem('fiscal_start_year') != '' && localStorage.getItem('fiscal_start_year') != null && localStorage.getItem('fiscal_start_year') != undefined) {
      let start = moment(localStorage.getItem('fiscal_start_year')).format("DD-MM-YYYY");
      let end = moment(localStorage.getItem('fiscal_end_year')).format("DD-MM-YYYY");
      this.setState({ start_date: localStorage.getItem('fiscal_start_year'), end_date: localStorage.getItem('fiscal_end_year') },()=>{
        this.callAPIDATA();
      });
      document.getElementById("fromdate").value = start;
      document.getElementById("todate").value = end;
    } else {
      let start = moment('1970-01-01').format("YYYY-MM-DD");
      this.setState({ 
        start_date: start, 
        end_date: moment().format("YYYY-MM-DD"),
        date_range: 'All'
        },()=>{
          this.callAPIDATA();
        });
      // this.setState({ date_range: "All" },()=>{
      //   this.callAPIDATA();
      // })
      this.changedatevalue("All")
    };
    // jQuery(".custom-select-drop .dropdown-menu a").click(function () {
    //   jQuery(".open.custom-select-drop .dropdown-menu li.active").removeClass(
    //     "active"
    //   );
    //   jQuery(this).parent("li").addClass("active");
    //   jQuery(".open #selected").text(jQuery(this).text());
    // });
    window.jQuery(".input-group.date").datepicker({ format: "dd/mm/yyyy", autoclose: true });

    jQuery(".snippet").mouseenter(function () {
      jQuery(".snippet").removeClass("active");
      jQuery(this).addClass("active");
    });

    jQuery(".filter-btn").click(function () {
      jQuery(this).css("visibility", "hidden");
      jQuery(".report-filter").slideDown();
    });

    jQuery(".report-filter .close-btn").click(function () {
      jQuery(".filter-btn").css("visibility", "visible");
      jQuery(".report-filter").slideUp();
    });
    // this.callAPIDATA(); 
    setTimeout(() => {
      this.kks();
    }, 4000);
  }

  customRadioChange = (x) => {
    this.setState({ valueAmount_type: x });
  };

  customRadioChange1 = (x) => {
    this.setState({ valueAmount_type1: x });
  };
  customRadioChange2 = (x) => {
    this.setState({ valueAmount_type2: x });
  };
  customRadioChange3 = (x) => {
    this.setState({ valueAmount_type3: x });
  };
  customRadioChange4 = (x) => {
    this.setState({ valueAmount_type4: x });
  };

  selected_item = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    let show_columns = optionElement.getAttribute("data-id");
    this.setState({ show_columns: show_columns }, () => {
      this.callAPIDATA();
    });
  };
  pageLink(page_slug) {
    this.props.history.push("/" + page_slug);
  }

  show_coulmn_filter = (e) => {
    var names = [];
    var result = [];
    console.log("jskdghjkg", e.target.options);

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        let value = JSON.parse(opt.value);
        result.push(value.b || opt.text);
        names.push(value.a || opt.text);
        // alert(opt.text)
        jQuery(
          "td:nth-child(" + value.b + "),th:nth-child(" + value.b + ")"
        ).show();
      } else {
        let value = JSON.parse(opt.value);
        jQuery(
          "td:nth-child(" + value.b + "),th:nth-child(" + value.b + ")"
        ).hide();
      }
    }
    this.setState({ ColscountArray: result }, this.filterFilters(names));
  };

  filterFilters = (result) => {
    let filter_key_names = [];
    this.state.coulmns_head &&
      this.state.coulmns_head !== undefined &&
      this.state.coulmns_head.map((item, i) => {
        console.log("filter_key_names", item);
        console.log("filter_key_w", result);

        if (result.includes(item.id))
          filter_key_names.push(`'${item.filter_key_name}'`);
      });
    console.log("filter_key_wq", filter_key_names);
    this.setState({ filter_key_names: filter_key_names });
    // this.callAPIDATA()
    FetchAllApi.filter_column(
      this.state.all_report_name_id, this.state.logged_client_id,
      filter_key_names,
      (errResponse, filtervalue) => {
        console.log("Fijkjlter Result", filtervalue);
        this.setState({ filtervalue: filtervalue });
      }
    );
  };

  selectedVendorIds = (e) => {
    var result = [];

    this.setState({ selected_vendor_ids: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };
  selectedTerms = (e) => {
    var result = [];

    this.setState({ selectedTerms: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(Number(opt.value) || Number(opt.text));
      } else {
      }
    }
  };
  selectedCustomer_type = (e) => {
    var result = [];

    this.setState({ selectedCustomer_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };
  selectedVendor_type = (e) => {
    var result = [];

    this.setState({ selectedVendor_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };

  // filters before custom column filter
  // selected_filters = (e) => {
  //   var result = [];
  //   var options = e.target.options;
  //   var opt;
  //   var j = 0;
  //   var array = [];
  //   for (var i = 0, iLen = options.length; i < iLen; i++) {
  //     opt = options[i];

  //     if (opt.selected) {
  //       result[j] = Number(opt.value);
  //       j++;
  //     }
  //   }
  //   this.setState({ result_array: result }
  //     , () => {
  //       this.callAPIDATA();
  //     }
  //   );
  //   if (result.length > 0) {
  //     if (result.includes(1)) {
  //       this.setState({ selectedFil: 1 });
  //     }
  //     if (result.includes(2)) {
  //       this.setState({ selectedFil: 2 });
  //     }
  //     if (result.includes(3)) {
  //       this.setState({ selectedFil: 3 });
  //     }
  //     if (result.includes(5)) {
  //       this.setState({ selectedFil: 5 });
  //     }
  //     if (result.includes(6)) {
  //       this.setState({ selectedFil: 6 });
  //     }
  //     if (result.includes(7)) {
  //       this.setState({ selectedFil: 7 });
  //     }
  //     if (result.includes(8)) {
  //       this.setState({ selectedFil: 8 });
  //     }
  //     if (result.includes(10)) {
  //       this.setState({ selectedFil: 10 });
  //     }
  //     if (result.includes(11)) {
  //       this.setState({ selectedFil: 11 });
  //     }

  //     if (result.includes(13)) {
  //       this.setState({ selectedFil: 13 });
  //     }
  //     if (result.includes(17)) {
  //       this.setState({ selectedFil: 17 });
  //     }
  //     if (result.includes(18)) {
  //       this.setState({ selectedFil: 18 });
  //     }
  //     if (result.includes(24)) {
  //       this.setState({ selectedFil: 24 });
  //     }
  //     if (result.includes(25)) {
  //       this.setState({ selectedFil: 25 });
  //     }
  //     if (result.includes(26)) {
  //       this.setState({ selectedFil: 26 });
  //     }
  //     if (result.includes(27)) {
  //       this.setState({ selectedFil: 27 });
  //     }
  //     if (result.includes(28)) {
  //       this.setState({ selectedFil: 28 });
  //     }

  //     if (result.includes(22)) {
  //       this.setState({ selectedFil: 22 });
  //     }
  //     if (result.includes(23)) {
  //       this.setState({ selectedFil: 23 });
  //     }
  //     if (result.includes(29)) {
  //       this.setState({ selectedFil: 29 });
  //     }

  //     if (result.includes(32)) {
  //       this.setState({ selectedFil: 32 });
  //     }

  //     if (result.includes(50)) {
  //       this.setState({ selectedFil: 50 });
  //     }

  //     if (result.includes(33)) {
  //       this.setState({ selectedFil: 33 });
  //     }

  //     if (result.includes(49)) {
  //       this.setState({ selectedFil: 49 });
  //     }


  //     if (result.includes(35)) {
  //       this.setState({ selectedFil: 35 });
  //     }

  //     if (result.includes(48)) {
  //       this.setState({ selectedFil: 48 });
  //     }

  //   } else {
  //     this.setState({ selectedFil: 0 });
  //   }
  // };

  selected_filters = (e) => {
    var result = [];
    var options = e.target.options;
    var opt;
    var j = 0;
    var array = [];
    let result_is_default_column_filter = [];
    let selected_filter_column_name_array = [];
    let selected_filter_column_id_array = [];
    let selected_custom_filter_object_array = [];
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];
      let dataset = opt.dataset;
      if (opt.selected) {
        result[j] = Number(opt.value);
        // result_is_default_column_filter[j] = Number(dataset.is_default_column_filter);
        // selected_filter_column_name_array[j] = opt.id;
        // selected_filter_column_id_array[j] = Number(opt.value);

        if(Number(dataset.is_default_column_filter) != 1) {
          let obj = {
            id:Number(opt.value),
            value:opt.id,
            filter_key:dataset.filter_key
          };
          selected_custom_filter_object_array.push(obj);
        }
        j++;
      }
    }

    this.setState({ result_array: result }
      , () => {
        let differene = _.difference(result,this.state.result_array_duplicate);
        this.setState({ result_array_duplicate:result });
        if(differene && differene.length > 0) {
          let selected_id = differene[0];
          let element_index = _.indexOf(result,selected_id);
          let is_selected_filter_default_column = result_is_default_column_filter[element_index];
          let selected_filter_column_name = selected_filter_column_name_array[element_index];
          this.setState({ 
            selectedFil: selected_id, 
            last_selectedFil:selected_id,
            // is_selected_filter_default_column:is_selected_filter_default_column,
            // selected_filter_column_name:selected_filter_column_name
            selected_custom_filter_object_array:selected_custom_filter_object_array
          },()=>{
            console.log("2993....selectedFil",this.state.selectedFil);
            console.log("2994....last_selectedFil",this.state.last_selectedFil);
            // console.log("2995....is_selected_filter_default_column",this.state.is_selected_filter_default_column);
            // console.log("2996....selected_filter_column_name",this.state.selected_filter_column_name);
            console.log("this.state.selected_custom_filter_object_array......****&&&&",this.state.selected_custom_filter_object_array);
          });
        } else {
          if(result.length > 0) {
            let rev_diff = _.difference(this.state.result_array_duplicate,result);
            let filtered_array = [];
            if(rev_diff && rev_diff.length > 0) {
                filtered_array = _.filter(
                this.state.selected_custom_filter_object_array, function(o) {
                   return rev_diff.indexOf(o.id) == -1;
                }
            );
            console.log("rev_diff.....3022",rev_diff);
            console.log("filtered_array.....3023",filtered_array);
            }
            this.setState({ 
              selectedFil: 0, 
              // is_selected_filter_default_column:1,
              // selected_filter_column_name:''
              selected_custom_filter_object_array:filtered_array
             },()=>{
              console.log("this.state.selected_custom_filter_object_array......****after remove item",this.state.selected_custom_filter_object_array);
              this.callAPIDATA();
            });
          } else {
            this.setState({ 
              selectedFil: 0, 
              // is_selected_filter_default_column:1,
              // selected_filter_column_name:'' 
              selected_custom_filter_object_array:[]
            },()=>{
              this.callAPIDATA();
            });
          }
        }
      });
  };

  changeText = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
          filter_options1: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText1 = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type1,
            value: this.state.valueAmount1,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText2 = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type2,
            value: this.state.valueAmount2,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText3 = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From4,
            to: this.state.To4,
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText4 = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From4,
            to: this.state.To4,
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_Num = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.number_from,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_memo = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.memo,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_open = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.open_balance,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_Exchange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.Exchange_rate,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_Fopen = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.F_open,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_addr = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.Address,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_web = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.website,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_mail = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.mail,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };
  changeText_acc = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.accnum,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_custom_column = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
        this.callAPIDATA();
    });
  };

  changeText_phone = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.phone_number,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_split = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.split_filter,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };

  changeText_Source_name = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: "",
            value: this.state.source_name,
            from: "",
            to: "",
          },
        },
        () => {
          this.callAPIDATA();
        }
      );
    });
  };




  logoutLink() {
    localStorage.clear();
    this.props.history.push("/");
  }
  multiSelectedCurrency = () => {
    //alert(jQuery('#slectedCurrency').val())
    this.setState(
      { selectedCurrencies: jQuery("#slectedCurrency").val() },
      () => {
        this.callAPIDATA();
      }
    );
  };


  multiSelectedAccount = () => {
    this.setState(
      { selectedAccountIds: jQuery("#selectedAccountIds").val() },
      () => {
        this.callAPIDATA();
      }
    );
  };


  multiSelectedType = () => {
    this.setState(
      { multiSelectedTypeIds: jQuery("#multiSelectedTypeIds").val() },
      () => {
        this.callAPIDATA();
      }
    );
  };


  multiplejobSelected = () => {
    this.setState(
      { multiplejobSelected: jQuery("#multiplejobSelected").val() },
      () => {
        this.callAPIDATA();
      }
    );
  };


  changedatevalue(seleteddateformat) {
    var dateresult = moment();
    let from_date, to_date;
    this.setState({ date_range: seleteddateformat },()=>{

    if (seleteddateformat === "This Month-to-date") {
      from_date = dateresult.startOf("month");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(new Date()).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = moment(new Date()).format("YYYY-MM-DD");
      this.callAPIDATA();
    } else if (seleteddateformat === "This Week") {
      from_date = dateresult.startOf("week");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      console.log("startdate", this.state.start_date);
      to_date = dateresult.endOf("week");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.callAPIDATA();
    } else if (seleteddateformat === "This Month") {
      from_date = dateresult.startOf("month");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = dateresult.endOf("month");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.callAPIDATA();
    } else if (seleteddateformat === "This Week-to-date") {
      from_date = dateresult.startOf("week");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(new Date()).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = moment(new Date()).format("YYYY-MM-DD");
      this.callAPIDATA();
    } else if (seleteddateformat === "This Year") {
      from_date = dateresult.startOf("year");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = dateresult.endOf("year");
      document.getElementById("todate").value = to_date.format("DD-MM-YYYY");
      this.state.end_date = to_date.format("YYYY-MM-DD");
      this.callAPIDATA();
    } else if (seleteddateformat === "This Year-to-date") {
      from_date = dateresult.startOf("year");
      document.getElementById("fromdate").value = from_date.format(
        "DD-MM-YYYY"
      );
      this.state.start_date = from_date.format("YYYY-MM-DD");
      to_date = moment(new Date()).format("YYYY-MM-DD");
      document.getElementById("todate").value = moment(to_date).format(
        "DD-MM-YYYY"
      );
      this.state.end_date = to_date;
      this.callAPIDATA();
    }

    // let startDate = jQuery('#fromdate').val()
    // let end_date = jQuery('#todate').val()
    // this.setState({ start_date: startDate, end_date: end_date }, () => {
    //   this.callAPIDATA();
    // })

    if (seleteddateformat == "All") {
      this.setState(
        {
          start_date: "1970-01-01",
          end_date: moment().add(10, 'years').format("YYYY-MM-DD"),
        },
        () => {
          this.callAPIDATA();
        }
      );
      document.getElementById("fromdate").value = "";
      document.getElementById("todate").value = "";
    }
  });
  }
  changefromDate(fromdate) {
    let date = jQuery("#fromdate").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      this.setState({ start_date: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }
  changefromDate1(fromdate) {
    let date = jQuery("#fromdate1").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      this.setState({ date_start: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }
  changefromDate_duedate(fromdate) {
    let date = jQuery("#fromdate_duedate").val();
    console.log("fromdate RTEdsadaasdadasdadad", date);
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      console.log("fromdate RTERE", date_formated);
      this.setState({ changefromDate_duedate: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }

  get_col = () => {
    let report_id = 11;
    FetchAllApi.get_col(this.state.logged_client_id, report_id, (err, response) => {
      if (response.status === 1) {
        var active = [];
        let active_headings = response.response.map((item) => {
          if (item.status === 1) {
            active.push(item.heading_name);
          }
        });

        let coulmns_head = response.response;

        // custom_column_display_change - 2023-09-12
        coulmns_head.forEach((col)=>{
          switch(col.heading_name) {
            case 'Trans#':
              col.clsname = 'transaction_number';
            break;

            case 'Type':
              col.clsname = 'type';
            break;

            case 'Last Modified':
              col.clsname = 'last_modified';
            break;

            case 'Last Modified By':
              col.clsname = 'last_modified_by';
            break;

            case 'Transaction Date':
              col.clsname = 'transaction_date';
            break;

            case 'Num':
              col.clsname = 'num';
            break;

            case 'Name':
              col.clsname = 'name';
            break;

            case 'Related invoice number':
              col.clsname = 'related_invoice_number';
            break;

            case 'Source Name':
              col.clsname = 'source_name';
            break;

            case 'Job Name':
              col.clsname = 'job_name';
            break;

            case 'Memo':
              col.clsname = 'memo';
            break;

            case 'Account':
              col.clsname = 'account';
            break;

            case 'Split':
              col.clsname = 'split';
            break;

            case 'Split- Breakdown String':
              col.clsname = 'split_breakdown_string';
            break;

            case 'Open Balance':
              col.clsname = 'open_balance_home_currency';
            break;

            case 'Debit':
              col.clsname = 'debit';
            break;

            case 'Credit':
              col.clsname = 'credit';
            break;

            case 'Amount':
              col.clsname = 'amount';
            break;

            case 'Split- Breakdown Amount':
              col.clsname = 'split_breakdown_amount';
            break;

            case 'Balance':
              col.clsname = 'balance';
            break;

            case 'Currency':
              col.clsname = 'currency';
            break;

            case 'Exchange Rate':
              col.clsname = 'exchange_rate';
            break;

            case 'Foreign Open Balance':
              col.clsname = 'open_balance_foreign_currency';
            break;

            case 'Foreign Debit':
              col.clsname = 'foreign_debit';
            break;

            case 'Foreign Credit':
              col.clsname = 'foreign_credit';
            break;

            case 'Foreign Amount':
              col.clsname = 'foreign_amount';
            break;

            case 'Split- Breakdown Foreign Amount':
              col.clsname = 'split_breakdown_foreign_amount';
            break;

            case 'Foreign Balance':
              col.clsname = 'foreign_balance';
            break;

            case 'Aging':
              col.clsname = 'aging';
            break;

            case 'category id':
              col.clsname = 'category_id';
            break;

            default:
            break;

          }
        });
        // custom_column_display_change_end

        //must remove 2023-05-02 - formula export - need to add category id from data base as report columns
        // coulmns_head.push({
        //   clsname:"category_id",
        //   filter_key_name: "category_id",
        //   heading_name: "category id",
        //   id:117,
        //   report_id: "11",
        //   sorting_order:30,
        //   status: 2,
        //   status_range: "Deactive"
        // });
        

        // let coulmns_head=[];
        // for(let i=0;i<coulmns.length;i++){

        //   if(i==6){
        //     var obj=coulmns[i];
        //     coulmns_head.push(obj);
        //     coulmns_head.push(coulmns[coulmns.length-1]);

        //   }else{
        //     if(i!==32){   var objj=coulmns[i];
        //       coulmns_head.push(objj);}

        //   }
        // }

        // for(let i=0;i<coulmns.length;i++){
        //   var myObj=coulmns[i]
        //   if(i>21){
        //     myObj['status']=2
        //   }else{
        //     myObj['status']=1
        //   }
        //   coulmns_head.push(myObj)

        // }

        let optionList = "";
        if (coulmns_head) {
          var options = coulmns_head.map((item, i) => {
            return <option>{item.heading_name}</option>;
          });
        }

        console.log("jhjkhjkhj", coulmns_head);

        this.setState(
          {
            selected_vals: active,
            coulmns_head: coulmns_head,
            options: options,
          },
          () => {
            setTimeout(() => {
              // for expand or collapse view
              for (let k = 0; k <= coulmns_head.length; k++) {
            // 2023-05-05 - to display only active columns
            try {
              if((coulmns_head[k].status == 2 || coulmns_head[k].clsname == 'source_name')) {
                let h = k + 1;
                  jQuery(
                    "td:nth-child(" + h + "),th:nth-child(" + h + ")"
                  ).hide();
              }
            } catch(exception) {
              console.log('Error while column hiding....',exception);
            }
               // 2023-05-05 - end here

                // if (k > 23) {
                //   jQuery(
                //     "td:nth-child(" + k + "),th:nth-child(" + k + ")"
                //   ).hide();
                // }
                // if (k == 8) {
                //   jQuery(
                //     "td:nth-child(" + 8 + "),th:nth-child(" + 8 + ")"
                //   ).hide();
                // }

                // if (k == 9) {
                //   jQuery(
                //     "td:nth-child(" + 9 + "),th:nth-child(" + 9 + ")"
                //   ).hide();
                // }
                if (k == 13) {
                  jQuery(
                    "td:nth-child(" + 13 + "),th:nth-child(" + 13 + ")"
                  ).hide();
                }
                if (k == 18) {
                  jQuery(
                    "td:nth-child(" + 18 + "),th:nth-child(" + 18 + ")"
                  ).hide();
                }
                if (k == 26) {
                  jQuery(
                    "td:nth-child(" + 26 + "),th:nth-child(" + 26 + ")"
                  ).hide();
                }
                // if (k == 28) {
                //   jQuery(
                //     "td:nth-child(" + 28 + "),th:nth-child(" + 28 + ")"
                //   ).hide();
                // }
              }
            }, 1000);
          }
        );
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  show_columnslist = () => {
    let report_name = "balance_sheet";
    FetchAllApi.get_coulmnlist(report_name, (err, response) => {
      if (response.status === 1) {
        this.setState({
          show_coulmns_filter: response.details,
        });
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  onChange_filterbysubvalue = (val) => {
    var sub_columns;
    if (val === 2 || val === 3) {
      sub_columns = [1];
      if (val === 2) {
        if (jQuery("#cadchanges2").prop("checked") == true)
          this.setState({ cadchange: true });
        else this.setState({ cadchange: false });
      } else {
        if (jQuery("#cadpercentage2").prop("checked") == true)
          this.setState({ cadpercentage: true });
        else this.setState({ cadpercentage: false });
      }
    } else {
      sub_columns = [4];
      if (val === 5) {
        if (jQuery("#cadchanges1").prop("checked") == true)
          this.setState({ cadchange: true });
        else this.setState({ cadchange: false });
      } else {
        if (jQuery("#cadpercentage1").prop("checked") == true)
          this.setState({ cadpercentage: true });
        else this.setState({ cadpercentage: false });
      }
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
      // alert(this.state.cadchange)
    });
  };

  onChange_filterby = (val) => {
    var sub_columns = [val];
    if (val === 1) {
      this.setState({ isChecked2: false, isChecked: true });
    } else {
      this.setState({ isChecked: false, isChecked2: true });
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
    });

    // FetchAllApi.profit_and_loss_sub_columns(sub_columns, (err, response) => {
    //   if (response.status === 1) {
    //     console.log('jhasgjkghasjk',response)
    //     this.setState({
    //     })
    //   } else {
    //     this.setState({
    //       gst_list: []
    //     })
    //   }
    // })
  };
  changetoDate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery("#todate").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      if (array != '' && array != undefined) {
        this.setState({ end_date: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
    // this.state.end_date = moment(date).format('YYYY-MM-DD')
    //  alert(moment(todate).format('YYYY-MM-DD'))
  }
  changetoDate1(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery("#todate1").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      if (array != '' && array != undefined) {
        this.setState({ date_end: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
    // this.state.end_date = moment(date).format('YYYY-MM-DD')
    //  alert(moment(todate).format('YYYY-MM-DD'))
  }
  changetoDate_duedate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery("#todate_duedate").val();
    if (date != undefined && date != '') {
      var array = date.split("/");
      var date_formated = array[2] + "-" + array[1] + "-" + array[0];
      if (array != '' && array != undefined) {
        this.setState({ todate_duedate: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }

  callAPIDATA = (x) => {
    let filter_id = this.state.result_array;
    let filter_options = {
      1: {
        condition: this.state.valueAmount_type,
        value: this.state.valueAmount,
        from: this.state.From,
        to: this.state.To,
      },
      32: {
        condition: this.state.valueAmount_type4,
        value: this.state.valueAmount4,
        from: this.state.From4,
        to: this.state.To4,
      },
      2: {
        condition: this.state.valueAmount_type1,
        value: this.state.valueAmount1,
        from: "",
        to: "",
      },

      3: {
        condition: "",
        value: "",
        from: this.state.changefromDate_duedate,
        to: this.state.todate_duedate,
      },
      5: {
        condition: "",
        value: [...this.state.selected_vendor_ids],
        from: "",
        to: "",
      },
      6: {
        condition: "",
        value: [...this.state.selectedCurrencies],
        from: "",
        to: "",
      },
      50: {
        condition: "",
        value: [...this.state.selectedAccountIds],
        from: "",
        to: "",
      },
      33: {
        condition: "",
        value: [...this.state.multiSelectedTypeIds],
        from: "",
        to: "",
      },
      49: {
        condition: "",
        value: [...this.state.multiplejobSelected],
        from: "",
        to: "",
      },
      8: {
        condition: "",
        value: "",
        from: this.state.date_start,
        to: this.state.date_end,
      },
      10: {
        condition: "",
        value: this.state.number_from,
        from: '',
        to: '',
      },
      11: {
        condition: "",
        value: this.state.memo,
        from: "",
        to: "",
      },

      35: {
        condition: "",
        value: this.state.split_filter,
        from: "",
        to: "",
      },
      48: {
        condition: "",
        value: this.state.source_name,
        from: "",
        to: "",
      },
      13: {
        condition: this.state.valueAmount_type2,
        value: this.state.valueAmount2,
        from: "",
        to: "",
      },
      17: {
        condition: "",
        value: this.state.Exchange_rate,
        from: "",
        to: "",
      },
      18: {
        condition: this.state.valueAmount_type3,
        value: this.state.valueAmount3,
        from: "",
        to: "",
      },
      22: {
        condition: "",
        value: [...this.state.selectedTerms],
        from: "",
        to: "",
      },
      23: {
        condition: "",
        value: [...this.state.selectedVendor_type],
        from: "",
        to: "",
      },
      24: {
        condition: "",
        value: this.state.Address,
        from: "",
        to: "",
      },
      25: {
        condition: "",
        value: this.state.website,
        from: "",
        to: "",
      },
      26: {
        condition: "",
        value: this.state.mail,
        from: "",
        to: "",
      },
      27: {
        condition: "",
        value: this.state.accnum,
        from: "",
        to: "",
      },
      28: {
        condition: "",
        value: this.state.phone_number,
        from: "",
        to: "",
      },
      29: {
        condition: "",
        value: [...this.state.selectedCustomer_type],
        from: "",
        to: "",
      },
    };

    if(this.state.selected_custom_filter_object_array) {
      let temp_object = {};
      this.state.selected_custom_filter_object_array.forEach((fil,j)=>{
        let val = typeof this.state[`custom_column_filter_name${fil.value}`] == 'string'?this.state[`custom_column_filter_name${fil.value}`].toLowerCase():this.state[`custom_column_filter_name${fil.value}`];
        temp_object[fil.id] = {
          condition: "=",
          value: val,
          from: "",
          to:"",
          name:fil.filter_key
        }
      });
      _.assign(filter_options,temp_object);
    }

    this.setState({ loading: true });
    let { start_date, end_date, show_columns, sub_columns } = this.state;
    // console.log("start date", start_date.toString());
    // console.log("End date", end_date.toString());
    FetchAllApi.filter_column(
      this.state.all_report_name_id, this.state.logged_client_id,
      this.state.filter_key_names,
      (errResponse, filtervalue) => {
        console.log("Fijkjlter Result", filtervalue);
        this.setState({ filtervalue: filtervalue });
      }
    );
    let startDate = moment(start_date).format("YYYY-MM-DD");
    let endDate = moment(end_date).format("YYYY-MM-DD");
    
    if(!this.state.date_range || this.state.date_range == 'All') {
      this.setState({
        export_file_name_date_range:'All'
      });
    } else if(this.state.start_date && this.state.end_date) {
      let s_date_str = moment(this.state.start_date).format(this.state.client_date_format);
      let e_date_str = moment(this.state.end_date).format(this.state.client_date_format);
      let name = s_date_str + "-TO-"+e_date_str;
      this.setState({
        export_file_name_date_range:name
      });
    }

    const urlParams = new URLSearchParams(window.location.search);
    // this condition newly added for PL/balance sheet breakdown
    if(urlParams.get('breakdown_by') || urlParams.get("category_id_array") || urlParams.get('category_id') || urlParams.get('account_type_id_array')) {

      if (urlParams.get('breakdown_by')) {
        console.log("first")
        let account_type_id_array = [];
        let category_id_array = [];
        let is_others = 0;
        let breakdown_by = urlParams.get("breakdown_by")
        FetchAllApi.genearl_ledger_break(
          startDate,
          endDate,
          show_columns,
          '',
          this.state.logged_client_id,
          sub_columns,
          filter_id,
          filter_options,
          this.state.selectedName,
          this.state.sort_type,
          account_type_id_array,
          category_id_array,
          breakdown_by,
          is_others,
          this.state.report_type,
  
          (err, response) => {
            if (response.status === 1) {
              // chars = _.orderBy(chars, ['name'],['asc']); // Use Lodash to sort array by 'name'
              this.get_col();
              console.log(
                "Object.values(response.details)",
                Object.values(response.details)
              );
    
              this.setState(
                {
                  resData: response,
                  loading: false,
                  // tableData: _.orderBy(Object.values(response.details), ['category_name'], ['asc'])
                  tableData: Object.values(response.details),
                  parent_category:response.parent_category_object
                },
                () => {
                  setTimeout(() => {
                    if (x !== undefined) {
                      var val = localStorage.getItem("scrollposition");
                      var brek = val && val.split("=");
                      var elmnt = document.getElementById("sticky-tb-hdr");
                      elmnt.scrollLeft = Number(brek[0]);
                      elmnt.scrollTop = Number(brek[1]);
                      var val = localStorage.setItem("scrollposition", "");
                    }
                  }, 1000);
    
                  if(this.state.is_export == 1) {
                    setTimeout(()=>{
                      this.setState({
                        is_export:false
                      });
                      if(this.state.export_type == 'pdf') {
                        this.generate();
                      } else if(this.state.export_type == 'excel') {
                        jQuery("#test-table-xls-button").trigger('click');
                      }
                    },500);
                  }
    
                }
              );
            } else {
              this.setState({ response: [], loading: false, resData: '', tableData: [],  is_export:false, parent_category:{} });
            }
          }
        )
      } else if (urlParams.get("category_id_array")) {
        console.log("second")
        let account_type_id_array = [];
        let number = Number(urlParams.get("category_id_array"))
        let category_id_array = [number];
        let is_others = 0;
        let breakdown_by = "";
  
  
        FetchAllApi.genearl_ledger_break(
          startDate,
          endDate,
          show_columns,
          '',
          this.state.logged_client_id,
          sub_columns,
          filter_id,
          filter_options,
          this.state.selectedName,
          this.state.sort_type,
          account_type_id_array,
          category_id_array,
          breakdown_by,
          is_others,
          this.state.report_type,
  
          (err, response) => {
            if (response.status === 1) {
              // chars = _.orderBy(chars, ['name'],['asc']); // Use Lodash to sort array by 'name'
              this.get_col();
              console.log(
                "Object.values(response.details)",
                Object.values(response.details)
              );
    
              this.setState(
                {
                  resData: response,
                  loading: false,
                  // tableData: _.orderBy(Object.values(response.details), ['category_name'], ['asc'])
                  tableData: Object.values(response.details),
                  parent_category:response.parent_category_object
                },
                () => {
                  setTimeout(() => {
                    if (x !== undefined) {
                      var val = localStorage.getItem("scrollposition");
                      var brek = val && val.split("=");
                      var elmnt = document.getElementById("sticky-tb-hdr");
                      elmnt.scrollLeft = Number(brek[0]);
                      elmnt.scrollTop = Number(brek[1]);
                      var val = localStorage.setItem("scrollposition", "");
                    }
                  }, 1000);
    
                  if(this.state.is_export == 1) {
                    setTimeout(()=>{
                      this.setState({
                        is_export:false
                      });
                      if(this.state.export_type == 'pdf') {
                        this.generate();
                      } else if(this.state.export_type == 'excel') {
                        jQuery("#test-table-xls-button").trigger('click');
                      }
                    },500);
                  }
    
                }
              );
            } else {
              this.setState({ response: [], loading: false, resData: '', tableData: [],  is_export:false, parent_category:{} });
            }
          }
        )
      } else if (urlParams.get('category_id')) {
        console.log("third")
        let account_type_id_array = [];
        let number = Number(urlParams.get('category_id'))
        let category_id_array = [number]
        let is_others = 1;
        let breakdown_by = "";
  
        FetchAllApi.genearl_ledger_break(
          startDate,
          endDate,
          show_columns,
          '',
          this.state.logged_client_id,
          sub_columns,
          filter_id,
          filter_options,
          this.state.selectedName,
          this.state.sort_type,
          account_type_id_array,
          category_id_array,
          breakdown_by,
          is_others,
          this.state.report_type,
  
          (err, response) => {
            if (response.status === 1) {
              // chars = _.orderBy(chars, ['name'],['asc']); // Use Lodash to sort array by 'name'
              this.get_col();
              console.log(
                "Object.values(response.details)",
                Object.values(response.details)
              );
    
              this.setState(
                {
                  resData: response,
                  loading: false,
                  // tableData: _.orderBy(Object.values(response.details), ['category_name'], ['asc'])
                  tableData: Object.values(response.details),
                  parent_category:response.parent_category_object
                },
                () => {
                  setTimeout(() => {
                    if (x !== undefined) {
                      var val = localStorage.getItem("scrollposition");
                      var brek = val && val.split("=");
                      var elmnt = document.getElementById("sticky-tb-hdr");
                      elmnt.scrollLeft = Number(brek[0]);
                      elmnt.scrollTop = Number(brek[1]);
                      var val = localStorage.setItem("scrollposition", "");
                    }
                  }, 1000);
    
                  if(this.state.is_export == 1) {
                    setTimeout(()=>{
                      this.setState({
                        is_export:false
                      });
                      if(this.state.export_type == 'pdf') {
                        this.generate();
                      } else if(this.state.export_type == 'excel') {
                        jQuery("#test-table-xls-button").trigger('click');
                      }
                    },500);
                  }
    
                }
              );
            } else {
              this.setState({ response: [], loading: false, resData: '', tableData: [],  is_export:false, parent_category:{} });
            }
          })
      } else if (urlParams.get('account_type_id_array')) {
        console.log("four")
        let number = Number(urlParams.get('account_type_id_array'))
        let account_type_id_array = [number]
        let category_id_array = [];
        let is_others = 0;
        let breakdown_by = "";
  
  
        FetchAllApi.genearl_ledger_break(
          startDate,
          endDate,
          show_columns,
          '',
          this.state.logged_client_id,
          sub_columns,
          filter_id,
          filter_options,
          this.state.selectedName,
          this.state.sort_type,
          account_type_id_array,
          category_id_array,
          breakdown_by,
          is_others,
          this.state.report_type,
  
          (err, response) => {
            if (response.status === 1) {
              // chars = _.orderBy(chars, ['name'],['asc']); // Use Lodash to sort array by 'name'
              this.get_col();
              console.log(
                "Object.values(response.details)",
                Object.values(response.details)
              );
    
              this.setState(
                {
                  resData: response,
                  loading: false,
                  // tableData: _.orderBy(Object.values(response.details), ['category_name'], ['asc'])
                  tableData: Object.values(response.details),
                  parent_category:response.parent_category_object
                },
                () => {
                  setTimeout(() => {
                    if (x !== undefined) {
                      var val = localStorage.getItem("scrollposition");
                      var brek = val && val.split("=");
                      var elmnt = document.getElementById("sticky-tb-hdr");
                      elmnt.scrollLeft = Number(brek[0]);
                      elmnt.scrollTop = Number(brek[1]);
                      var val = localStorage.setItem("scrollposition", "");
                    }
                  }, 1000);
    
                  if(this.state.is_export == 1) {
                    setTimeout(()=>{
                      this.setState({
                        is_export:false
                      });
                      if(this.state.export_type == 'pdf') {
                        this.generate();
                      } else if(this.state.export_type == 'excel') {
                        jQuery("#test-table-xls-button").trigger('click');
                      }
                    },500);
                  }
    
                }
              );
            } else {
              this.setState({ response: [], loading: false, resData: '', tableData: [],  is_export:false, parent_category:{} });
            }
          }
        )
      }

    } else {

    FetchAllApi.genearl_ledger(
      startDate,
      endDate,
      show_columns,
      this.state.logged_client_id,
      sub_columns,
      filter_id,
      filter_options,
      this.state.selectedName,
      this.state.sort_type,
      this.state.report_type,

      (err, response) => {
        if (response.status === 1) {
          // chars = _.orderBy(chars, ['name'],['asc']); // Use Lodash to sort array by 'name'
          this.get_col();
          console.log(
            "Object.values(response.details)",
            Object.values(response.details)
          );

          this.setState(
            {
              resData: response,
              loading: false,
              // tableData: _.orderBy(Object.values(response.details), ['category_name'], ['asc'])
              tableData: Object.values(response.details),
              parent_category:response.parent_category_object
            },
            () => {
              setTimeout(() => {
                if (x !== undefined) {
                  var val = localStorage.getItem("scrollposition");
                  var brek = val && val.split("=");
                  var elmnt = document.getElementById("sticky-tb-hdr");
                  elmnt.scrollLeft = Number(brek[0]);
                  elmnt.scrollTop = Number(brek[1]);
                  var val = localStorage.setItem("scrollposition", "");
                }
              }, 1000);

              if(this.state.is_export == 1) {
                setTimeout(()=>{
                  this.setState({
                    is_export:false
                  });
                  if(this.state.export_type == 'pdf') {
                    this.generate();
                  } else if(this.state.export_type == 'excel') {
                    jQuery("#test-table-xls-button").trigger('click');
                  }
                },500);
              }

            }
          );
        } else {
          this.setState({ response: [], loading: false, resData: '', tableData: [],  is_export:false, parent_category:{} });
        }
      }
    );
    }
  };
  changevaluetotals() {
    this.state.changetotal1 = this.state.changetotal;
    this.state.changetotal = 0;
  }
  changevalueper() {
    this.state.changetotal3 = this.state.changetotal2;
    this.state.changetotal2 = 0;
  }
  changevalueperx(value) {
    let x =
      (parseFloat(this.state.changetotal) - parseFloat(value)) / value / 100;
    if (x || isNaN(x)) x = 0;
    this.state.changetotal2 = x.toFixed(2) + " %";
  }
  changevaluetotalsx(value) {
    this.state.changetotal = parseInt(this.state.changetotal) - parseInt(value);
  }

  watchMe = () => {
    // isUpdated=
    // console.log('dskjhskj',isUpdated)
    // if(isUpdated !==undefined && isUpdated !=='' && isUpdated !==null){
    //  this.callAPIDATA()
    //   localStorage.setItem('updated','')
    // }

    setInterval(() => {
      var updated = localStorage.getItem("updated");
      if (
        updated !== undefined &&
        updated !== "" &&
        updated !== null &&
        updated === "yes"
      ) {
        this.callAPIDATA("jii");

        localStorage.setItem("updated", "");
        console.log("i am here2", updated);
      }
    }, 5000);
  };

  render() {
    var amnt = 0;
    var finalArray = [];
    console.log("jjjjjjjjjjjj", this.state.coulmns_head);
    if (this.state.coulmns_head) {
      var list = this.state.coulmns_head.map((item, i) => {
        return <option key={i}>{item.heading_name}</option>;
      });
      // window.jQuery('#myselect').selectpicker().val(list)
    }

    let get_file_path,
      dis_file_path = [],
      item_file_path = [],
      attach_file_path,
      options = [],
      page_no = 1,
      items_limit = 10,
      no_items;

    console.log("response_stus", this.state.options);

    if (
      this.state.item_details.user_image !== "" &&
      this.state.item_details.user_image !== "null"
    ) {
      var item_user_image = this.state.item_details.user_image;
    } else {
      var item_user_image = "images/user-img-1.png";
    }

    if (
      this.state.item_file_path !== "" &&
      this.state.item_file_path !== "null"
    ) {
      item_file_path = [];
      var split_file_path = this.state.item_file_path.toString().split(",");
      var split_file_id = this.state.item_file_id.toString().split(",");
      if (split_file_path.length >= 1) {
        for (var i = 0; i < split_file_path.length; i++) {
          var get_file_url = split_file_path[i];
          var split_file_name = split_file_path[i].toString().split("/");
          var arr_reverse = split_file_name.reverse();

          var get_file_name = arr_reverse[0].substring(
            arr_reverse[0].length - 15,
            arr_reverse[0].length
          );

          var get_file_ext = arr_reverse[0].substring(
            arr_reverse[0].lastIndexOf(".") + 1,
            arr_reverse[0].length
          );
          if (get_file_ext === "pdf") {
            var file_icon = "images/pdf-icon.png";
          } else {
            var file_icon = "images/img-icon.png";
          }

          //console.log('pdf_file_link',get_file_url);

          if (get_file_ext === "pdf") {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(
                    this,
                    this.state.list_id,
                    split_file_id[i]
                  )}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <iframe
                    src={get_file_url}
                    id="pdf_thumb_viewer"
                    frameborder="0"
                    scrolling="no"
                    width="190"
                    height="190"
                  ></iframe>
                  <span className="go">
                    <img
                      src="../images/next-arrow-white.svg"
                      className="mCS_img_loaded"
                    />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(
                    this,
                    this.state.list_id,
                    split_file_id[i]
                  )}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <img
                    src="../images/download-icon.svg"
                    alt="Icon"
                    className="mCS_img_loaded"
                  />
                </a>
              </div>
            );
          } else {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(
                    this,
                    this.state.list_id,
                    split_file_id[i]
                  )}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <img
                    className="img-responsive mCS_img_loaded"
                    src={get_file_url}
                    alt={get_file_ext}
                  />
                  <span className="go">
                    <img
                      src="../images/next-arrow-white.svg"
                      className="mCS_img_loaded"
                    />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(
                    this,
                    this.state.list_id,
                    split_file_id[i]
                  )}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <a href={get_file_url} download={get_file_name}>
                    {get_file_name}
                    <img
                      src="../images/download-icon.svg"
                      alt="Icon"
                      className="mCS_img_loaded"
                    />
                  </a>
                </a>
              </div>
            );
          }
        }
      }
    }

    options.push(<option>ORG-250</option>);

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <LeftSidebar history={this.props.history} pageSubmit={(e) => this.pageLink(e)} />

            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <div className="nav-brand-res visible-xs">
                  <img
                    className="img-responsive"
                    src="../images/logo-icon.png"
                    alt="LogoIcon"
                  />
                </div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="../images/back-arrow-blue.svg" onClick={() => this.props.history.goBack()} />
                </a>
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript:;">Report</a>
                  </li>
                  <li>General Ledger</li>
                </ul>

                <Topbar history={this.props.history} logoutSubmit={(e) => this.logoutLink()} />
              </div>
              {/* 
              <div className='content-top col-md-12 col-xs-12'>
                <h4 className='fw-sbold mar-t-no'>General Ledger</h4>
                <h5 className='fw-sbold'>
                  {moment(new Date()).format('MMM YYYY')}
                </h5>
              </div>
 */}
              <div>
                {/* Top bar Ends here */}
                <div className="col-md-12 col-xs-12 mar-top visible-xs">
                  <a href="javascript:;" className="back">
                    <img src="images/back-arrow-blue.svg" />
                  </a>
                  <span className="page-title">General Ledger</span>
                </div>
                {/* content-top Starts here */}
                <div className="content-top col-md-12 col-xs-12">
                  <h4 className="fw-sbold mar-t-no">General Ledger</h4>
                  <h5 className="fw-sbold"></h5>
                  <div className="row">
                    <div className="report-setting col-md-12 col-xs-12">
                      <form className="custom-form form-inline col-md-12 col-xs-12 pad-no">
                        <div className="row">
                          <div className="form-group col-md-3 col-sm-6 col-xs-12">
                            <label>Date Range</label>
                            <div className="form-cont w-100"  >
                              <select
                                id="custom"
                                className="selectpicker form-control hh "
                                data-live-search="true"
                                value={this.state.date_range}
                                onChange={(e) =>
                                  this.changedatevalue(e.target.value)
                                }
                              >
                                <option value="All">All</option>
                                <option value="Custom">Custom</option>
                                <option value="This Month-to-date">This Month-to-date</option>
                                <option value="This Week">This Week</option>
                                <option value="This Month">This Month</option>
                                <option value="This Week-to-date">This Week-to-date</option>
                                <option value="This Year">This Year</option>
                                <option value="This Year-to-date">This Year-to-date</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group col-md-3 col-sm-6 col-xs-12">
                            <label>From</label>
                            <div
                              className="input-group date mar-t-no"
                              data-date-format="dd/mm/yyyy"
                            >
                              <input
                                type="text"
                                id="fromdate"
                                onBlur={(e) => {
                                  let value = e.target.value
                                  this.setState({ date_range: "Custom" })
                                  setTimeout(() => {
                                    jQuery("#custom").val("Custom")
                                    this.changefromDate(value)
                                  }, 500)
                                }}
                                className="form-control"
                                autoComplete="off"
                              />
                              <div className="input-group-addon" onClick={() => jQuery('#fromdate').focus()}>
                                <img
                                  src="images/calendar-icon.svg"
                                  alt="icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-3 col-sm-6 col-xs-12">
                            <label>To</label>
                            <div
                              className="input-group date mar-t-no"
                              data-date-format="dd/mm/yyyy"
                            >
                              <input
                                type="text"
                                id="todate"
                                onBlur={(e) => {
                                  let value = e.target.value
                                  this.setState({ date_range: "Custom" })
                                  setTimeout(() => {
                                    jQuery("#custom").val("Custom");
                                    this.changetoDate(value);
                                  }, 500)
                                }}

                                className="form-control"
                                autoComplete="off"
                              />
                              <div className="input-group-addon" onClick={() => jQuery('#todate').focus()} >
                                <img
                                  src="images/calendar-icon.svg"
                                  alt="icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-3 col-sm-6 col-xs-12">
                            {/* <a href="javascript:;" className="text-link filter-btn">Advanced</a> */}
                            <a
                              href="javascript:;"
                              className="text-link filter-btn mar-rgt"
                            >
                              Advanced
                            </a>
                            <a
                              href="javascript:;"
                              className="fa fa-refresh"
                              onClick={() => {
                                this.callAPIDATA();
                              }}
                            >
                              <img
                                src="images/refresh.svg"
                                className=" mar-rgt-5 "
                                style={{ width: "20px" }}
                              />
                            </a>

                            <a
                              href="javascript:;"
                              className="text-link mar-rgt-5 "
                              onClick={() => this.showHide()}
                            >
                              {this.state.view
                                ? "expand view"
                                : "collapse view"}
                            </a>
                          </div>
                        </div>
                      </form>
                      <div className="pull-right">
                        <div className="dropdown menu-item new-cus">
                          <button
                            className="btn btn-green dropdown-toggle btn-arrow"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Export
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu align-right">
                            <li><a href="javascript:void(0);" onClick={this.generate}>Export as PDF</a></li>
                            
                            {/* dont delete in any case working fine before excel export with formula */}
                            {/* <li><ReactHTMLTableToExcel id="test-table-xls-button"
                              className="excel-button"
                              table="mytable"
                             filename={`General-ledger-`+this.state.export_file_name_date_range}
                              sheet={`General-ledger-`+this.state.export_file_name_date_range}
                              buttonText="Export as Excel" /></li> */}

                        {/* excel export with formula - starts */}
                        <li>
                        <a href="javascript:void(0);">
                        <button  class="btn btn-success"
                        id="test-table-xls-button"
                        onClick={()=>{
                        let table = document.getElementById("mytable");
                        let html = table.outerHTML;
                          this.htmlToJson(html);
                        }}>Export as Excel
                        </button>
                        </a>
                        </li>
                        {/* excel export with formula - end */}
                          </ul>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                {/* content-top Starts here */}
              </div>

              <div className="main-content col-md-12 col-xs-12">
                <div className="report-setting">
                  <div className="col-md-12 col-xs-12 report-filter">
                    <a href="javascript:;" className="close-btn">
                      <img src="images/icons8-minus.png" />
                    </a>
                    <form className="custom-form">
                      <div className="col-lg-4 col-md-12 pad-l-no">
                        <div className="row">


                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">
                                  Report Basics
                                </label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <label className="custom-checkbox radio mar-t-no mar-rgt">
                                  <input
                                    type="radio"
                                    name="tax-item"
                                    checked={this.state.report_type == 1 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({ report_type: 1 }, () => this.callAPIDATA())
                                      }
                                    }}
                                  />{" "}
                                  Accrual
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox radio">
                                  <input type="radio" name="tax-item"
                                    checked={this.state.report_type == 2 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({ report_type: 2 }, () => this.callAPIDATA())
                                      }
                                    }}
                                  /> Cash
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>


                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Show Columns</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div className="custom-select-drop dropdown">
                                  {this.state.coulmns_head &&
                                    this.state.coulmns_head !== undefined && (
                                      <select
                                        className="selectpicker form-control "
                                        id="myselect"
                                        multiple
                                        data-live-search="true"
                                        onChange={(e) =>
                                          this.show_coulmn_filter(e)
                                        }
                                      >
                                        {this.state.coulmns_head &&
                                          this.state.coulmns_head !==
                                          undefined &&
                                          this.state.coulmns_head &&
                                          this.state.coulmns_head.map(
                                            (item, i) => {
                                              let statusSelected = "";
                                              if (item.status === 1 && i !== 7)
                                                statusSelected = "selected";
                                              let object = {
                                                a: item.id,
                                                b: i + 1,
                                              };

                                               // condition for formula export
                                              if(object.b == 13 || object.b == 18 || object.b == 26) {
                                                return '';
                                              } else {
                                              return (
                                                <option
                                                  value={JSON.stringify(object)}
                                                  // value={i + 1}
                                                  selected={statusSelected}
                                                >
                                                  {item.heading_name}
                                                </option>
                                              );
                                              }
                                            }
                                          )}
                                      </select>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Filter by</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div className="custom-select-drop dropdown">
                                  <select
                                    className="selectpicker form-control"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) => {
                                      this.selected_filters(e);
                                    }}
                                  >
                                    {this.state.filtervalue &&
                                      this.state.filtervalue.name &&
                                      this.state.filtervalue.name.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                              data-is_default_column_filter={item.is_default_column}
                                              data-filter_key={item.filter_key}
                                            >
                                              {item.filter_name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-xs-12 mar-b-no">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Sort By</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div id="currency_selected">
                                  <select
                                    className="selectpicker form-control"
                                    id="customer_type"
                                    data-live-search="true"
                                    onChange={(e) => {
                                      this.setState({ selectedName: e.target.value })
                                      setTimeout(() => { this.callAPIDATA() }, 500)
                                    }}
                                  >
                                    <option selected={true}>Choose</option>
                                    {this.state.sortBynames &&
                                      this.state.sortBynames.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <option value={item.column_key}>
                                              {item.name}
                                            </option>
                                          </React.Fragment>
                                        );
                                      })}
                                  </select>
                                </div>

                                <div className="sort-by-icon-margin-pl" style={{ float: "right" }}>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      let a = this.state.sort_type == "desc" ? "asc" : "desc";
                                      this.setState({ sort_type: a,  });
                                      setTimeout(() => {
                                        if (this.state.selectedName != "") {
                                          this.callAPIDATA();
                                        }
                                      }, 500)
                                    }}
                                  >
                                    {/* {this.state.sort_type == "desc" ? "asc" : "desc"} */}
                                    {this.state.sort_type == "desc" ? <FontAwesomeIcon icon="fas fa-sort-amount-up"/> : <FontAwesomeIcon icon="fas fa-sort-amount-down"/>}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-12 pad-r-no">
                        <div className="row"></div>
                      </div>

                      {this.state.selectedFil == 5 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Name</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) => this.selectedVendorIds(e)}
                                  >
                                    {this.state.vendorNames &&
                                      this.state.vendorNames.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.vendor_name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 3 && (
                        <div className="col-lg-4 col-md-12 pad-r-no">
                          <div className="row">
                            <div className="form-group mar-rgt">
                              <label>From</label>
                              <div
                                className="input-group date mar-t-no"
                                data-date-format="dd/mm/yyyy"
                              >
                                <input
                                  type="text"
                                  id="fromdate_duedate"
                                  onBlur={(e) => {
                                    let value = e.target.value
                                    setTimeout(() => {
                                      this.changefromDate_duedate(value)
                                    }, 500)
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: "43px" }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#fromdate_duedate').focus()} >
                                  <img
                                    src="images/calendar-icon.svg"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mar-rgt">
                              <label>To</label>
                              <div
                                className="input-group date mar-t-no"
                                data-date-format="dd/mm/yyyy"
                              >
                                <input
                                  type="text"
                                  id="todate_duedate"
                                  onBlur={(e) => {
                                    let value = e.target.value
                                    setTimeout(() => {
                                      this.changetoDate_duedate(value)
                                    }, 500)
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: "43px" }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#todate_duedate').focus()}>
                                  <img
                                    src="images/calendar-icon.svg"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      )}

                      {this.state.selectedFil === 8 && (
                        <div className="col-lg-4 col-md-12 pad-r-no">
                          <div className="row">
                            <div className="form-group mar-rgt">
                              <label>From</label>
                              <div
                                className="input-group date mar-t-no"
                                data-date-format="dd/mm/yyyy"
                              >
                                <input
                                  type="text"
                                  id="fromdate1"
                                  onBlur={(e) => {
                                    let value = e.target.value
                                    setTimeout(() => {
                                      this.changefromDate1(value)
                                    }, 500)
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: "43px" }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#fromdate1').focus()}>
                                  <img
                                    src="images/calendar-icon.svg"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mar-rgt">
                              <label>To</label>
                              <div
                                className="input-group date mar-t-no"
                                data-date-format="dd/mm/yyyy"
                              >
                                <input
                                  type="text"
                                  id="todate1"
                                  onBlur={(e) => {
                                    let value = e.target.value
                                    setTimeout(() => {
                                      this.changetoDate1(value)
                                    }, 500)
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: "43px" }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#todate1').focus()}>
                                  <img
                                    src="images/calendar-icon.svg"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                      {this.state.selectedFil == 6 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Currency</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id="slectedCurrency"
                                    onChange={(e) => {
                                      this.multiSelectedCurrency(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {this.state.currencies &&
                                      this.state.currencies.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil == 50 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Accounts</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id='selectedAccountIds'
                                    onChange={(e) => {
                                      this.multiSelectedAccount(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {this.state.all_account_list &&
                                      this.state.all_account_list.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}


                      {this.state.selectedFil == 33 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Types</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id='multiSelectedTypeIds'
                                    onChange={(e) => {
                                      this.multiSelectedType(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {this.state.all_transaction_type_list &&
                                      this.state.all_transaction_type_list.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}


                      {this.state.selectedFil == 49 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Job Name</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id='multiplejobSelected'
                                    onChange={(e) => {
                                      this.multiplejobSelected(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {this.state.job_name_list &&
                                      this.state.job_name_list.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.job_name}
                                              data-id={item.job_id}
                                              value={item.job_id}
                                            >
                                              {item.job_name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 2 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"="}
                                    onChange={(e) => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"<="}
                                    onChange={(e) => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={">="}
                                    onChange={(e) => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                {/* <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label> */}
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount1"
                                    className="form-control"
                                    onChange={this.changeText1}
                                    style={{ width: "128px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 1 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"="}
                                    onChange={(e) => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"<="}
                                    onChange={(e) => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={">="}
                                    onChange={(e) => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                {/* <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label> */}
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount"
                                    className="form-control"
                                    onChange={this.changeText}
                                    style={{ width: "128px" }}
                                  />
                                </div>

                                <div>
                                  <div>
                                    <label>From</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="From"
                                      className="form-control"
                                      style={{ width: "128px" }}
                                      onChange={this.changeText}
                                    />
                                  </div>
                                  <div>
                                    <label>To</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="To"
                                      onChange={this.changeText}
                                      className="form-control"
                                      style={{ width: "128px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 32 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"="}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"<="}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={">="}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                {/* <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label> */}
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount4"
                                    className="form-control"
                                    onChange={this.changeText4}
                                    style={{ width: "128px" }}
                                  />
                                </div>

                                <div>
                                  <div>
                                    <label>From</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="From4"
                                      className="form-control"
                                      style={{ width: "128px" }}
                                      onChange={this.changeText4}
                                    />
                                  </div>
                                  <div>
                                    <label>To</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="To4"
                                      onChange={this.changeText4}
                                      className="form-control"
                                      style={{ width: "128px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 10 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <div>
                                  <label>Number</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="number_from"
                                    className="form-control"
                                    style={{ width: "128px" }}
                                    onChange={this.changeText_Num}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 11 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Memo</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="memo"
                                  className="form-control"
                                  onChange={this.changeText_memo}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 35 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Split</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="split_filter"
                                  className="form-control"
                                  onChange={this.changeText_split}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 48 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Source Name</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="source_name"
                                  className="form-control"
                                  onChange={this.changeText_Source_name}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 13 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"="}
                                    onChange={(e) => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"<="}
                                    onChange={(e) => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={">="}
                                    onChange={(e) => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount2"
                                    className="form-control"
                                    onChange={this.changeText2}
                                    style={{ width: "128px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 17 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Exchange_rate</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="Exchange_rate"
                                  className="form-control"
                                  onChange={this.changeText_Exchange}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 18 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"="}
                                    onChange={(e) => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"<="}
                                    onChange={(e) => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={">="}
                                    onChange={(e) => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount3"
                                    className="form-control"
                                    onChange={this.changeText3}
                                    style={{ width: "128px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 24 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Address</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="Address"
                                  className="form-control"
                                  onChange={this.changeText_addr}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 25 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>website</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="website"
                                  className="form-control"
                                  onChange={this.changeText_web}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 26 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>E-mail</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="mail"
                                  className="form-control"
                                  onChange={this.changeText_mail}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 27 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Acc No</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="accnum"
                                  className="form-control"
                                  onChange={this.changeText_acc}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 28 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>phone number</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="phone_number"
                                  className="form-control"
                                  onChange={this.changeText_phone}
                                  style={{ width: "128px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 22 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>payment-terms</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) => this.selectedTerms(e)}
                                  >
                                    {this.state.paymentTerms &&
                                      this.state.paymentTerms.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.terms}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 23 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>vendor type</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) =>
                                      this.selectedVendor_type(e)
                                    }
                                  >
                                    {this.state.vendor_type &&
                                      this.state.vendor_type.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 29 && (
                        <div
                          className="col-lg-4 col-md-12 pad-r-no"
                          style={{ paddingLeft: 55 }}
                          id="hideme"
                        >
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: "block" }}>
                                <div className="custom-select-drop dropdown">
                                  <label>customer type</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={(e) =>
                                      this.selectedCustomer_type(e)
                                    }
                                  >
                                    {this.state.customer_type &&
                                      this.state.customer_type.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              id={item.filter_name}
                                              data-id={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

              {this.state.selected_custom_filter_object_array && this.state.selected_custom_filter_object_array.map(
              (item, index) => {
                if(item.id == this.state.selectedFil) {
                  return (
                    <div
                      className="col-lg-4 col-md-12 pad-r-no"
                      style={{ paddingLeft: 55 }}
                      id="hideme"
                    >
                      <div className="row">
                        <div className="form-group col-md-12 col-xs-12">
                          <div>
                            <label>{item.value}</label>
                            <input
                              type="text"
                              id={`custom_column_filter${item.id}`}
                              name={`custom_column_filter_name${item.value}`}
                              className="form-control"
                              onChange={this.changeText_custom_column}
                              style={{ width: "128px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    );
                } else {
                  return '';
              }
                      }
                    )}

                    </form>
                  </div>
                </div>

                <div className="report-table col-md-12 col-xs-12 pad-no">
                  {/* <p >  <span id='current'>fdfd</span> </p> */}
                  <div
                    className="table-responsive"
                    id="sticky-tb-hdr"
                    onScroll={() => {
                      // // jQuery("#sticky-tb-hdr").scroll(function () {
                      //   if (oldScrollTop == jQuery("#sticky-tb-hdr").scrollTop()) {
                      //     jQuery('#current').html('Horizontal');

                      //     if (jQuery(".transs").visible(true)) {
                      //       jQuery(".transs").css({"position": "fixed"});
                      //       // The element is visible, do something
                      //   } else {
                      //     jQuery(".transs").css({"position": "relative"});
                      //       // The element is NOT visible, do something else
                      //   }

                      //   }
                      //   else {
                      //     jQuery('#current').html('Vertical');

                      //   }
                      //   oldScrollTop = jQuery("#sticky-tb-hdr").scrollTop();
                      //   oldScrollLeft = jQuery("#sticky-tb-hdr").scrollLeft();
                      // // });

                      var elmnt = document.getElementById("sticky-tb-hdr");
                      var x = elmnt.scrollLeft;

                      //  this.setState({scrolledPixelIN_X:x, scrolledPixelIN_Y:elmnt.scrollTop},()=>{
                      // console.log(object)                      })
                      // elmnt.scrollLeft=250;
                      console.log("runinng", x);
                      // this.setState({hh:x})
                    }}
                  >
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      visible={this.state.loading}
                    />
                    {!this.state.loading && (
                      <table className="table custom table-base" id="mytable">
                        <thead style={{ zIndex: 3 }}>

                        <th>
                          {/* {this.state.date_range == "All" ? "Date Range: All":moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY")} */}
                          {this.state.start_date==='1970-01-01' ? `As of ${moment(this.state.end_date).format("DD MMM YYYY")}` :moment(this.state.start_date).format("DD MMM YYYY") + " TO "+ moment(this.state.end_date).format("DD MMM YYYY")}
                        </th>
                        {this.state.coulmns_head &&
                              this.state.coulmns_head.map((i) =>
                               <th key={i} style={{width:'100%', background:"white"}}></th>)}
                       

                          <tr style={{ backgroundColor: "#fff" }}>
                            {this.state.coulmns_head &&
                              this.state.coulmns_head.map((x, y) => {
                                return (
                                  <th
                                    className="text-right"
                                    className={x.clsname}
                                  >
                                    {x.heading_name}
                                    <i className="th-sort">
                                      <img
                                        src={config.sort_icon_url}
                                        alt="SortIcon"
                                      />
                                    </i>
                                  </th>
                                );
                              })}
                            {/* <th ></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableData &&
                            this.state.tableData.map((primary) => {
                              var x =
                                primary.totalpaymanent != undefined
                                  ? primary.totalpaymanent
                                  : 0;
                              var y =
                                primary.totalcredit != undefined
                                  ? primary.totalcredit
                                  : 0;
                              amnt = amnt + Number(x + y);

                              return (
                                <React.Fragment>
                                  {/* main heading */}
                                  {isNaN(Number(primary.total_amount))
                                    ? 0
                                    :
                                    primary.is_transactions_available == 1
                                    && (
                                      <tr class="title-1">
                                {this.state.coulmns_head.map((x, y) => {
                                switch(x.clsname) {
                                    case 'transaction_number':
                                            return <td
                                            class="trans"
                                            id="oneSticky"
                                            style={{
                                              // position: "sticky",
                                              left: "0.25rem", backgroundColor: "#EFEFFF"
                                            }}
                                          >
                                            {primary.category_name}
                                          </td>
                                    
                                    case 'balance':
                                          return <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          {isNaN(
                                            Number(
                                              primary.prevoius_closing_balance
                                            )
                                          )
                                            ? "0.00"
                                            : Number(
                                              primary.prevoius_closing_balance
                                            ).toFixed(2)}
                                        </td>;

                                    case 'foreign_balance':
                                          return <td class="trans">
                                          {isNaN(
                                            Number(
                                              primary.prevoius_closing_foreign_balance
                                            )
                                          )
                                            ? "0.00"
                                            : Number(
                                              primary.prevoius_closing_foreign_balance
                                            ).toFixed(2)}
                                        </td>;

                                    case 'category_id':
                                      return <td class="trans">{primary.category_id}</td>

                                    default:
                                      return <td><span className="text-right"></span></td>
                                }
                              })}

                                        {/* <td
                                          class="trans"
                                          id="oneSticky"
                                          style={{
                                            position: "sticky",
                                            left: "0.25rem", backgroundColor: "#EFEFFF"
                                          }}
                                        >
                                          {primary.category_name}
                                        </td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>

                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>

                                        <td class="trans"></td>
                                        <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          {isNaN(
                                            Number(
                                              primary.prevoius_closing_balance
                                            )
                                          )
                                            ? "0.00"
                                            : Number(
                                              primary.prevoius_closing_balance
                                            ).toFixed(2)}
                                        </td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        ></td>
                                        <td class="trans">
                                          {isNaN(
                                            Number(
                                              primary.prevoius_closing_foreign_balance
                                            )
                                          )
                                            ? "0.00"
                                            : Number(
                                              primary.prevoius_closing_foreign_balance
                                            ).toFixed(2)}
                                        </td>
                                        <td class="trans"></td>
                                        <td class="trans">{primary.category_id}</td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td>
                                        <td class="trans"></td> */}
                                      </tr>
                                    )}
                                  {/* main heading  ends */}

                                  {/* recursive logic starts */}
                                  {!isNaN(Number(primary.total_amount)) &&
                                    primary.is_child_data_available == 1 &&
                                    primary.is_transactions_available == 1

                                    &&
                                    this.repeat(primary.sub_categories, 45)}
                                  {/* recursive logic ends */}

                                  {/* invoice heading others */}
                                  {primary.invoices &&
                                    primary.invoices.length > 0 &&
                                    primary.is_child_data_available == 1 &&
                                    !isNaN(Number(primary.total_amount)) &&
                                    primary.is_transactions_available == 1
                                    && (
                                      <tr class="item-step1 sub-title">

                            {this.state.coulmns_head.map((x, y) => {
                                switch(x.clsname) {
                                    case 'transaction_number':
                                          return <td
                                          style={{
                                            // position: "sticky",
                                            left: "0.25rem", backgroundColor: "#EFEFFF"
                                          }}
                                        >
                                          <div>
                                            {primary.category_name}
                                            {""}- {""} Others{" "}
                                          </div>
                                        </td>

                                    case 'balance':
                                          return <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          <div>
                                            {isNaN(
                                              Number(
                                                primary.prevoius_closing_others_balance
                                              )
                                            )
                                              ? "0"
                                              : Number(
                                                primary.prevoius_closing_others_balance
                                              ).toFixed(2)}
                                          </div>{" "}
                                        </td>
                                    
                                    case 'foreign_balance':
                                          return <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          <div>
                                            {isNaN(
                                              Number(
                                                primary.prevoius_closing_others_foreign_balance
                                              )
                                            )
                                              ? "0"
                                              : Number(
                                                primary.prevoius_closing_others_foreign_balance
                                              ).toFixed(2)}
                                          </div>
                                        </td>

                                    case 'category id':
                                      return <div>{primary.category_id}</div>

                                    default:
                                      return <td><div></div></td>
                                }
                              })}

                                        {/* <td
                                          style={{
                                            position: "sticky",
                                            left: "0.25rem", backgroundColor: "#EFEFFF"
                                          }}
                                        >
                                          <div>
                                            {primary.category_name}
                                            {""}- {""} Others{" "}
                                          </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>

                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>

                                        <td>
                                          <div> </div>
                                        </td>

                                        <td>
                                          <div> </div>
                                        </td>

                                        <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          <div>
                                            {isNaN(
                                              Number(
                                                primary.prevoius_closing_others_balance
                                              )
                                            )
                                              ? "0"
                                              : Number(
                                                primary.prevoius_closing_others_balance
                                              ).toFixed(2)}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        
                                      <td>
                                        <div> </div>
                                      </td>

                                        <td
                                          class="trans"
                                          style={{ paddingLeft: 1 }}
                                        >
                                          <div>
                                            {isNaN(
                                              Number(
                                                primary.prevoius_closing_others_foreign_balance
                                              )
                                            )
                                              ? "0"
                                              : Number(
                                                primary.prevoius_closing_others_foreign_balance
                                              ).toFixed(2)}
                                          </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div>{primary.category_id}</div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td>
                                        <td>
                                          <div> </div>
                                        </td> */}
                                      </tr>
                                    )}
                                  {/* others total */}

                                  {/* others map */}
                                  {primary.invoices &&
                                    !isNaN(Number(primary.total_amount)) &&
                                    primary.is_transactions_available == 1
                                    &&
                                    primary.invoices.map((e1) => {
                                      return (
                                        <>
                                          <tr
                                            className="item-step1"
                                            // onClick={() => {
                                              onDoubleClick={() => {
                                              localStorage.setItem('comingFrom', 'General Ledger')
                                              var elmnt = document.getElementById(
                                                "sticky-tb-hdr"
                                              );
                                              var x = elmnt.scrollLeft;
                                              var y = elmnt.scrollTop;
                                              var value = x + "=" + y;
                                              localStorage.setItem(
                                                "scrollposition",
                                                value
                                              );

                                              // alert(jQuery(this).parents("tr").get(0).rowIndex)
                                              if (
                                                e1.type == "Sales Invoice" ||
                                                e1.type == "Customer Single Payment"
                                              ) {
                                                if (
                                                  e1.type == "Sales Invoice"
                                                ) {
                                                  var setID = e1.trans;
                                                } else if (
                                                  e1.type == "Customer Single Payment"
                                                ) {
                                                  var setID =
                                                    e1.invoice_id +
                                                    "=" +
                                                    e1.trans;
                                                }

                                                localStorage.setItem(
                                                  "invoice_id",
                                                  setID
                                                );

                                                localStorage.setItem(
                                                  "job_id",
                                                  e1.job_id
                                                );
                                                // alert("job id" + e1.job_id);

                                                var win = window.open(
                                                  "/create_invoice",
                                                  "_blank"
                                                );
                                                win.focus();
                                              }

                                              if (
                                                e1.type == "Bill"
                                              ) {
                                                if (
                                                  e1.type == "Bill"
                                                ) {
                                                  let arr = [
                                                    e1.type,
                                                    e1.invoice_id,
                                                  ];
                                                  console.log(
                                                    "hy",
                                                    e1.invoice_id
                                                  );
                                                  localStorage.setItem(
                                                    "vendor_bill",
                                                    JSON.stringify(arr)
                                                  );
                                                }

                                                var win = window.open(
                                                  "/data_tagging/" +
                                                  e1.list_id +
                                                  "/" +
                                                  e1.file_id,
                                                  "_blank"
                                                );
                                                win.focus();
                                              }

                                              if (e1.type == "Vendor Single Payment") {
                                                let arr = [
                                                  "Bill payment",// e1.type,
                                                  e1.invoice_id,
                                                  e1.payment_id,
                                                ];

                                                localStorage.setItem(
                                                  "vendor_bill",
                                                  JSON.stringify(arr)
                                                );

                                                // alert(e1.payment_id)
                                                // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
                                                var win = window.open(
                                                  "/data_tagging/" +
                                                  e1.list_id +
                                                  "/" +
                                                  e1.file_id,
                                                  "_blank"
                                                );
                                                win.focus();
                                              }

                                              if (
                                                e1.type ==
                                                "Customer credit note"
                                              ) {
                                                var setID = e1.credit_memo_id;

                                                localStorage.setItem(
                                                  "credit_id",
                                                  setID
                                                );
                                                window.open("/create_creditmemo?memo_id=" + setID)
                                                // alert(e1.credit_memo_id);

                                                // var win = window.open(
                                                //   "/create_creditmemo",
                                                //   "_blank"
                                                // );
                                                // win.focus();
                                              }


                                              if (
                                                e1.type == "Vendor credit note"
                                              ) {
                                                let arr = [
                                                  e1.type,
                                                  e1.credit_memo_id,
                                                ];
                                                console.log(
                                                  "hy",
                                                  e1.credit_memo_id
                                                );
                                                localStorage.setItem(
                                                  "vendor_bill",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open(
                                                  "/data_tagging/" +
                                                  e1.list_id +
                                                  "/" +
                                                  e1.file_id,
                                                  "_blank"
                                                );
                                                win.focus();
                                              }

                                              if (
                                                e1.type == "Customer Multipayment"
                                              ) {

                                                let arr = [
                                                  e1.customer_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/Customer_receive_payment', "_blank");
                                                win.focus();
                                              }

                                              if (
                                                e1.type == "Customer Multipayment - Refund"
                                              ) {

                                                let arr = [
                                                  e1.customer_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/Customer_receive_payment', "_blank");
                                                win.focus();
                                              }

                                              console.log(e1,'break')
                                              if (
                                                e1.type == "Customer Discount"
                                              ) {

                                                let arr = [
                                                  e1.customer_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/Customer_receive_payment', "_blank");
                                                win.focus();
                                              }
                                            
                                              if (
                                                e1.type == "Customer multipayment unapplied amount"
                                              ) {

                                                let arr = [
                                                  e1.customer_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/Customer_receive_payment', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Vendor Multipayment"
                                              ) {
                                                let arr = [
                                                  e1.vendor_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/vendor_bill_payment', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Vendor multipayment unapplied amount"
                                              ) {

                                                let arr = [
                                                  e1.vendor_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/vendor_bill_payment', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Vendor Discount"
                                              ) {
                                                let arr = [
                                                  e1.vendor_id,
                                                  e1.multi_payment_applied_invoices,
                                                ];

                                                localStorage.setItem(
                                                  "edit_customer_receive_payment",
                                                  JSON.stringify(arr)
                                                );


                                                var win = window.open('/vendor_bill_payment', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Journal entry" || e1.type == "Journal entry - Output Tax" || e1.type == "Journal entry - Input Tax"
                                              ) {

                                                localStorage.setItem("journal_id", e1.journal_id);
                                                // this.props.history.push("/new_journal") 
                                                var win = window.open('/new_journal', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Home currency adjustment"
                                              ) {

                                                localStorage.setItem("adjustment_id", e1.id);
                                                // this.props.history.push("/new_journal") 
                                                var win = window.open('/home_currency_adjustment', "_blank");
                                                win.focus();
                                              }

                                              if (
                                                e1.type == "Filed Tax Return"
                                              ) {

                                                localStorage.setItem("tax_filed_entry_id", e1.id);
                                                var win = window.open('/filed_tax_entry_details', "_blank");
                                                win.focus();
                                              }

                                              if (
                                                e1.type == "Cheque"
                                              ) {

                                                localStorage.setItem("cheque_id", e1.id);
                                                // this.props.history.push("/new_journal") 
                                                var win = window.open('/write_cheque', "_blank");
                                                win.focus();
                                              }
                                              if (
                                                e1.type == "Deposit"
                                              ) {
                                                localStorage.setItem("deposit_id", e1.id);
                                                // this.props.history.push("/new_journal") 
                                                var win = window.open('/make_deposit', "_blank");
                                                win.focus();
                                              }

                                              if(e1.type == "Batch - Bill" || e1.type == "Batch - Vendor credit note" || e1.type == "Batch - Cheque" || e1.type == "Batch - Deposit" || e1.type == "Batch - Sales Invoice" || e1.type == "Batch - Customer credit note" || e1.type == "Batch-Cheque" || e1.type == "Batch-Deposit") {
                                                localStorage.setItem('batchid', e1.batch_transaction_id);
                                                localStorage.setItem('batch_transaction_row_index', e1.batch_transaction_row_index);
                                                  var win = window.open('/enter_batch_transaction', "_blank");
                                                  win.focus();
                                                }

                                                if (
                                                  e1.type == "Transfer Funds"
                                                ) {
                                                  localStorage.setItem("transfer_fund_transaction_number", e1.transaction_number);
                                                  var win = window.open('/transfer_funds', "_blank");
                                                  win.focus();
                                                }

                                                
                              if(e1.type == "Customer Multipayment - Exchange Gain" || e1.type == "Customer Multipayment - Exchange Loss" || e1.type == "Customer Discount - Exchange Gain" || e1.type == "Customer Discount - Exchange Loss" || e1.type == "Customer Applied Credit - Exchange Gain" || e1.type == "Customer Applied Credit - Exchange Loss") {
                                
                                if(e1.multi_payment_applied_invoices) {
                                  let arr = [
                                    e1.customer_id,
                                    e1.multi_payment_applied_invoices,
                                  ];
                                  localStorage.setItem(
                                    "edit_customer_receive_payment",
                                    JSON.stringify(arr)
                                  );
                                  var win = window.open('/Customer_receive_payment', "_blank");
                                  win.focus();
                                } 
                            } else if((e1.type == 'Customer Single Payment - Exchange Gain' || e1.type == 'Customer Single Payment - Exchange Loss') && e1.invoice_id && e1.payment_id) { // && e1.job_id
                              var setID = e1.invoice_id + "=" + e1.payment_id;
                              localStorage.setItem("invoice_id", setID);
                              localStorage.setItem("job_id", e1.job_id);
                              var win = window.open(
                                "/create_invoice",
                                "_blank"
                              );
                              win.focus();
                            }

                            if(e1.type == "Vendor Multipayment - Exchange Gain" || e1.type == "Vendor Multipayment - Exchange Loss" || e1.type == "Vendor Discount - Exchange Gain" || e1.type == "Vendor Discount - Exchange Loss" || e1.type == "Vendor Applied Credit - Exchange Gain" || e1.type == "Vendor Applied Credit - Exchange Loss" || e1.type == "Vendor unapplied payment amount") {
                              if(e1.multi_payment_applied_invoices) {
                              let arr = [
                                e1.vendor_id,
                                e1.multi_payment_applied_invoices,
                              ];
                              localStorage.setItem("edit_customer_receive_payment",JSON.stringify(arr));
                              var win = window.open('/vendor_bill_payment', "_blank");
                              win.focus();
                            }
                              }
                      
                              if((e1.type == "Vendor Single Payment - Exchange Gain" || e1.type == "Vendor Single Payment - Exchange Loss") && e1.invoice_id && e1.payment_id && e1.list_id && e1.file_id) {
                                let arr = [
                                  "Bill payment",
                                  e1.invoice_id,
                                  e1.payment_id,
                                ];
                                
                          
                                localStorage.setItem(
                                  "vendor_bill",
                                  JSON.stringify(arr)
                                );
                          
                                var win = window.open(
                                  "/data_tagging/" +
                                  e1.list_id +
                                  "/" +
                                  e1.file_id,
                                  "_blank"
                                );
                                win.focus();
                              }
                                            }
                                            }
                                          >

                          {this.state.coulmns_head.map((x, y) => {
                                switch(x.clsname) {
                                    case 'transaction_number':
                                          return <td className=".headcol ">
                                          <span>
                                            {e1.transaction_number}
                                          </span>
                                          </td>

                                    case 'open_balance_home_currency':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_home_currency)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'debit':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'credit':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'amount':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'balance':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'open_balance_foreign_currency':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_foreign_currency)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_debit':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_credit':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_amount':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_balance':
                                          return <td className="">
                                          <span>
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'split_breakdown_string':
                                    case 'split_breakdown_amount':
                                    case 'split_breakdown_foreign_amount':
                                          return <td className=''><span>{''}</span></td>

                                    default:
                                      return <td className=""><span>{e1[x.clsname]? e1[x.clsname] : ''}</span>
                                    </td>
                                }
                              })}
                                            {/* <td className=".headcol ">
                                              <span>
                                                {e1.transaction_number}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>{e1.type}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.last_modified}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.last_modified_by}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.transaction_date}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.num}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.name}</span>
                                            </td>

                                            <td className="">
                                              <span>{e1.source_name}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.job_name}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.memo}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.account}</span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {e1.split != "" ? e1.split : ""}
                                              </span>
                                            </td>
                                            <td>
                                              <span>{""} </span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_home_currency)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>

                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.debit)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>

                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.credit)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>

                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.amount)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td>
                                              <span>{""} </span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.balance)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>{e1.currency}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.exchange_rate}</span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.open_balance_foreign_currency)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>

                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td>
                                              <span>{""} </span>
                                            </td>
                                            <td className="">
                                              <span>
                                                {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency }).format(e1.foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>{e1.aging}</span>
                                            </td>
                                            
                                               <td className="">
                                              <span>
                                                {primary.category_id}
                                              </span>
                                            </td>
                                            <td className="">
                                              <span>{e1.contact}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.postal_code}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.province}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.city}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.address}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.email}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.phone}</span>
                                            </td>
                                            <td className="">
                                              <span>{e1.fax}</span>
                                            </td> */}
                                          </tr>

                                          {this.state.view == false &&
                                            e1.split_breakdown_string &&
                                            e1.split_breakdown_string.map(
                                              (item, i) => {
                                                return (
                                                  <tr className="item-step1 istep-2 title1">

                                                  {this.state.coulmns_head.map((x, y) => {
                                                      switch(x.clsname) {
                                                          case 'split_breakdown_string':
                                                                  return <td>
                                                                  <span className="">
                                                                    {
                                                                      e1
                                                                        .split_breakdown_string[
                                                                      i
                                                                      ]
                                                                    }
                                                                  </span>
                                                                </td>

                                                          case 'split_breakdown_amount':
                                                            return <td>
                                                            <span className="">
                                                              {
                                                                e1
                                                                  .split_breakdown_amount[
                                                                i
                                                                ]
                                                              }
                                                            </span>
                                                          </td>

                                                            case 'split_breakdown_foreign_amount':
                                                              return <td>
                                                              <span className="">
                                                                {
                                                                  e1
                                                                    .split_breakdown_foreign_amount[
                                                                  i
                                                                  ]
                                                                }
                                                              </span>
                                                            </td>

                                                          case 'category_id':
                                                            // return <td class="trans">{primary.category_id}</td>
                                                              return <td class="trans">{e1.category_id}</td>

                                                          default:
                                                            return <td><span className="text-right"></span></td>
                                                      }
                                                    })}
                                                    {/* <td>
                                                      <span> </span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>

                                                    <td>
                                                      <span className="">
                                                        {
                                                          e1
                                                            .split_breakdown_string[
                                                          i
                                                          ]
                                                        }
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className=""></span>
                                                    </td>
                                                    <td>
                                                      <span className=""></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className=""></span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className="">
                                                        {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency }).format(e1.split_breakdown_amount[i])).replace(this.state.home_currency_symbol, '')}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className="">
                                                        {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                          { style: 'currency', currency: this.state.home_currency }).format(e1.split_breakdown_foreign_amount[i])).replace(this.state.home_currency_symbol, '')}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className=" "></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td><span className=" ">
                                                      {primary.category_id}
                                                    </span>
                                                    </td>

                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "}
                                                    <td>
                                                      <span className="text-right"></span>
                                                    </td>{" "} */}
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </>
                                      );
                                    })}
                                  {primary.invoices &&
                                    primary.invoices.length > 0 &&
                                    primary.is_child_data_available == 1 &&
                                    !isNaN(Number(primary.total_amount)) &&
                                    // Number(primary.total_amount) != 0 
                                    primary.is_transactions_available == 1
                                    && (
                                      <tr className="item-step1 istep-2 title1">

                            {this.state.coulmns_head.map((x, y) => {
                                switch(x.clsname) {
                                    case 'transaction_number':
                                          return <td>
                                          <span>
                                            Total {""} {primary.category_name}
                                            {""}- {""} others{" "}
                                          </span>
                                        </td>

                                    case 'open_balance_home_currency':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_open_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'debit':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'credit':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'amount':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'balance':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_others_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'open_balance_foreign_currency':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_debit':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_credit':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_amount':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_balance':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_others_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'category_id':
                                        return <td>
                                          <span className="text-right">{primary.category_id}</span>
                                        </td>

                                    default:
                                      return <td><span className="text-right"></span></td>
                                }
                              })}

                                        {/* <td>
                                          <span>
                                            Total {""} {primary.category_name}
                                            {""}- {""} others{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_open_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>{" "}
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_others_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_others_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_others_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right">{primary.category_id}</span>
                                        </td>

                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "} */}
                                      </tr>
                                    )}
                                  {/* others  ends */}

                                  {/* main total starts  */}
                                  {isNaN(Number(primary.total_amount))
                                    ? 0
                                    :
                                    primary.is_transactions_available == 1
                                    && (
                                      <tr className="item-step1 title1 bdr-no">

{this.state.coulmns_head.map((x, y) => {
                                switch(x.clsname) {
                                    case 'transaction_number':
                                          return <td>
                                          <span>
                                            Total{" "}
                                            {" " + primary.category_name}{" "}
                                          </span>
                                        </td>

                                    case 'open_balance_home_currency':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_open_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'debit':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'credit':
                                          return <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'amount':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'balance':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'open_balance_foreign_currency':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_debit':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_credit':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_amount':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'foreign_balance':
                                          return <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>

                                    case 'category_id':
                                        return <td><span className="text-right">{primary.category_id}</span></td>

                                    default:
                                      return <td><span className="text-right"></span></td>
                                }
                              })}

                                        {/* <td>
                                          <span>
                                            Total{" "}
                                            {" " + primary.category_name}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className=""></span>
                                        </td>
                                        <td></td>
                                        <td>
                                          <span className=""></span>
                                        </td>
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_open_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>{" "}
                                        <td>
                                          <span className="">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_open_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_debit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_credit)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.total_foreign_amount)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className=" ">
                                            {(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                              { style: 'currency', currency: this.state.home_currency }).format(primary.current_closing_foreign_balance)).replace(this.state.home_currency_symbol, '')}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}

                                        <td><span className="text-right">{primary.category_id}</span>
                                        </td>

                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td>{" "}
                                        <td>
                                          <span className="text-right"></span>
                                        </td> */}
                                      </tr>
                                    )}
                                  {/* main total ends  */}
                                </React.Fragment>
                              );
                            })}

                          {/* final Total */}

                          <tr
                            className="item-step1 title1 bdr-no"
                            style={{ backgroundColor: "lightgrey" }}
                          >

                            
                                {this.state.coulmns_head.map((x, y) => {
                                                                switch(x.clsname) {
                                                                    case 'transaction_number':
                                                                          return <td>
                                                                          <span>Total </span>
                                                                        </td>

                                                                    case 'open_balance_home_currency':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_home_open_balance) && this.state.resData.overall_total_home_open_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_open_balance)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'debit':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_home_debit) && this.state.resData.overall_total_home_debit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_debit)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'credit':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_home_credit) && this.state.resData.overall_total_home_credit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_credit)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'amount':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_home_amount) && this.state.resData.overall_total_home_amount ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_amount)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'balance':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_home_balance) && this.state.resData.overall_total_home_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_balance)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'open_balance_foreign_currency':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_foreign_open_balance) && this.state.resData.overall_total_foreign_open_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_open_balance)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'foreign_debit':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_foreign_debit) && this.state.resData.overall_total_foreign_debit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_debit)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'foreign_credit':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_foreign_credit) && this.state.resData.overall_total_foreign_credit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_credit)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'foreign_amount':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_foreign_amount) && this.state.resData.overall_total_foreign_amount ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_amount)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    case 'foreign_balance':
                                                                          return <td>
                                                                          <span className="">
                                                                            {!isNaN(this.state.resData.overall_total_foreign_balance) && this.state.resData.overall_total_foreign_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                                                              { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_balance)).replace(this.state.home_currency_symbol, '') : 0}
                                                                          </span>
                                                                        </td>

                                                                    default:
                                                                      return <td><span className="text-right"></span></td>
                                                                }
                                                              })}

                            {/* <td>
                              <span>Total </span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_home_open_balance) && this.state.resData.overall_total_home_open_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_open_balance)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_home_debit) && this.state.resData.overall_total_home_debit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_debit)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_home_credit) && this.state.resData.overall_total_home_credit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_credit)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_home_amount) && this.state.resData.overall_total_home_amount ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_amount)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_home_balance) && this.state.resData.overall_total_home_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_home_balance)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_foreign_open_balance) && this.state.resData.overall_total_foreign_open_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_open_balance)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>{" "}
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_foreign_debit) && this.state.resData.overall_total_foreign_debit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_debit)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>{" "}
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_foreign_credit) && this.state.resData.overall_total_foreign_credit ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_credit)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>{" "}
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_foreign_amount) && this.state.resData.overall_total_foreign_amount ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_amount)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className="">
                                {!isNaN(this.state.resData.overall_total_foreign_balance) && this.state.resData.overall_total_foreign_balance ? (new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.resData.overall_total_foreign_balance)).replace(this.state.home_currency_symbol, '') : 0}
                              </span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td>
                            <td>
                              <span className=""></span>
                            </td>{" "}
                            <td>
                              <span className=""></span>
                            </td> */}
                          </tr>
                          {/* final total ends */}
                        </tbody>
                      </table>
                    )}
                    {/* <button onClick={this.props.increment}>jdhsjjksdhk</button> */}

                     {/* excel export with formula - starts */}
                     <div style={containerStyle}>
                      <div className="container">
                      <div className="grid-wrapper">
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                        ref={this.gridRef}
                        groupIncludeFooter={groupIncludeFooter}
                        rowData={this.state.export_formula_row_data}
                        columnDefs={this.state.export_formula_column_defs}
                        defaultColDef={this.state.defaultColDef}
                        defaultExcelExportParams={this.state.defaultExcelExportParams}
                        ></AgGridReact>
                      </div>
                      </div>
                      </div>
                      </div>
                      {/* excel export with formula - end */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer logoutSubmit={(e) => this.logoutLink()} />
      </div>
    );
  }
}
// export default GeneralLedger
const mapStateToProps = (state) => {
  return {
    userAuthdetails: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    increment: () =>
      dispatch({ type: "ADD_LOGGED_USER_DETAILS", payload: "hiiiiiii" }),
    decrement: () => dispatch({ type: "DECREMENT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedger);
