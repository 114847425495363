import { Link } from "react-router-dom";

import FetchAllApi from "../api_links/fetch_all_api";
import React from "react";
import jQuery from "jquery";
import moment from "moment";
import parse from 'html-react-parser'

import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import Comma from "./comma";

class CustomerStatementMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_id:"",
      client_id: "",
      fromdate:"",
      todate:"",
      show_id:"",
      user_id: "",
      email_id: "",
      password: "",
      conformPassword: "",
      subscriber_id: "",
      show1: false,
      show2: false,
      html_contents:'',
      language_code:'ta',
      country_sortname:'SG',
      home_currency:'SGD',
      home_currency_symbol :'SGD',
      statement: [],
      response_statement: '',
      totalbalance:[],
      date_format: "DD-MM-YYYY"
    };
  };

  // componentWillMount() {
  //   localStorage.clear();
  // }
  saveAsPdf = () => {
    let html_content = document.getElementById('whole_template').innerHTML //jQuery('#whole_template').html()
 
    let Input = {
      client_id: this.state.client_id,
      customer_id: this.state.customer_id,
      fromDate: this.state.fromdate,
      show_id: this.state.show_id,
      toDate: this.state.todate,
      html_content
    }

    FetchAllApi.print_pdf(Input, 'customer_statement', (err, response) => {
      if (response.status === 1) {
        //alert(response.message)
        window.open(response.file_path);
      } else {
      }      
    });

  };
  statement = () => {
    let client_id = this.state.client_id
    let customer_id = this.state.customer_id
    let from_date = this.state.fromdate
    let to_date = this.state.todate
    let show_id= this.state.show_id
   

    FetchAllApi.Statement(
      client_id,
      customer_id,
      from_date,
      to_date,
      show_id,
      (err, response) => {
        // alert(response.response.length)
        if (response.status === 1) {
          this.setState({
            statement: response.list,
            totalBal: response.totalamount,
            response_statement: response,
            totalbalance:response.total_balance_by_currency
          },()=>{console.log('state',this.state.response_statement)
          jQuery("#isEntityName").html(this.state.response_statement.company_details && this.state.response_statement.company_details.entityname)
          jQuery("#isAddress").html(this.state.response_statement.company_details && this.state.response_statement.company_details && this.state.response_statement.company_details.entityaddress)
          jQuery("#isPhoneNumber").html(this.state.response_statement.company_details && this.state.response_statement.company_details.entityphno)
          jQuery("#isEmail").html(this.state.response_statement.email_id)
          jQuery("#isUenNumber").html(this.state.response_statement.unique_entity_number)})
        } else {
          this.setState({
            statement: [],
            totalBal: '',
            response_statement: '',
          })
        }
      }
    )
    console.log(this.state.response_statement);

        // get customer statement
        var body = {
          client_id,
        };
        FetchAllApi.customer_statement_settings_view(body, (err, response) => {
          if (response.status === 1) {
            if (response.data.length >= 1) {
              const thankshtml = response.data[0].thanking_message
                ? response.data[0].thanking_message
                : "";
              const thankscontentBlock = htmlToDraft(thankshtml);
              
              let customerstatement = {};
              if (thankscontentBlock) {
                const thankscontentState = ContentState.createFromBlockArray(
                  thankscontentBlock.contentBlocks
                );
                customerstatement =
                  EditorState.createWithContent(thankscontentState);
              }
              
              this.setState({
                customerStatement: thankshtml,
              });
            } else if (response.data.length == 0) {
              this.setState({
                customerStatement: '',
              });
            }
          }
        });
  }
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    let cust_data = params.get("data");
    cust_data = Buffer.from(cust_data, 'base64').toString('ascii');
    let customerData = JSON.parse(cust_data);
    console.log(customerData)
    this.setState({
      customer_id: customerData.customer_id,
      client_id: customerData.client_id,
      fromdate:customerData.fromDate,
      todate:customerData.toDate,
      show_id:customerData.show_id
    },()=>{
    this.statement()
  });
    
    // let input = { client_id: invoiceData.client_id, user_id: invoiceData.user_id, selected_template_type: 1 }
    // let entity_details=[]
    // FetchAllApi.get_data(invoiceData.client_id, (err, response) => {
    //   if (response.status === 1) {
    //     entity_details= response;
    //   }
    // })
    // var coreData = {
    //   invoice_id: invoiceData.invoice_id,
    //   client_id: invoiceData.client_id,
    //   payment_id: 0,
    // };
    // let invoice_details=[];
    // FetchAllApi.getInvoiceDetails(coreData, (err, response) => {

    //   if (response.status === 1) {
    //     invoice_details = response.invoice_details;
    //   }
    // });
    // FetchAllApi.get_edit_invoice_html(input, (err, response) => {
    //   if (response.status === 1) {
      
    //     this.temChanged(response.default_template_id,response,invoice_details,entity_details,invoiceData)
    //   }
    // });
  };
  
  UNSAFE_componentWillMount() {
    jQuery("title").html("View Customer Statement | GBSC");
  }

  loginFormSubmit(e) {
    e.preventDefault();
    alert("submitted");
  }

  render() {
   
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-sm-5 login-left hidden-sm hidden-xs">
            <h1>
              Welcome to <strong>GENIE</strong>
            </h1>
            <p>
              Cloud based accounting software with OCR, AI automation, Instant payment notification of Accounts receivable for all business contacts within genie platform.
            </p>

            <p>
              meaning NO hassle when it comes to Accounting anymore
            </p>
            <div className="img-wrap">
              <img
                className="img-responsive"
                src="../images/login-img.png"
                alt=""
              />
            </div>
          </div>
          <div className="builder-right" style={{ display: 'none' }}>
            <div id='whole_template' className="template-item">

            {/* <div id="whole_template" > */}
                      {/* <div className='statement-wrap col-md-12 pad-hor-no'>
                        <div
                          style={{
                            color: '#6A6A6A',
                            background: '#fff',
                            minHeight: '230px',
                            border: '1px solid #eee',
                            width: '97%',
                            margin: '0 auto',
                            fontSize: '14px',
                            display: 'block',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
                            borderRadius: '4px'
                          }}
                        > */}
                          {/* <div
                            style={{                             
                              padding: '10px'
                            }}
                          > */}
                            {/* <div style={{'float':'left','width':'100%','box-sizing': 'border-box'}}>
            <div id="isLogo" style={{'float':'left','display':'flex','align-items':'center'}}>
                <img id="image" style={{'width':'100px'}} src={this.state.response_statement.company_details && this.state.response_statement.company_details.logo_path} alt="logo"/>
            </div>
              <div id="mobDiv" style={{'float': 'right', 'text-align': 'right'}}> 
              <div style={{display: 'inline-block', width: '100%', float: 'left',marginBottom: '10px'}}>
                <p id="isEntityName" style={{float: 'right', margin: 'auto'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityname}</p>
                <lable style={{float: 'right', fontWeight: '600'}}>Company Name:</lable>
            </div>
            <div style={{display: 'inline-block', width: '100%',float: 'left', marginBottom: '10px'}}>
                <p id="isAddress" style={{float: 'right', margin: 'auto'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details && this.state.response_statement.company_details.entityaddress}</p>
                <lable style={{float: 'right', fontWeight: '600'}}>Address:</lable>
            </div>
            <div style={{display: 'inline-block', width: '100%', float: 'left', marginBottom: '10px'}}>
                <p id="isPhoneNumber" style={{float: 'right', margin: 'auto'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityphno}</p>
                <lable style={{float: 'right', fontWeight: '600'}}>Phone No:</lable>
            </div>
            <div style={{display: 'inline-block', width: '100%', float: 'left',marginBottom: '10px'}}>
                <p id="isEmail" style={{float: 'right', margin: 'auto'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityemail}</p>
                <lable style={{float: 'right', fontWeight: '600'}}>E-mail:</lable>
            </div>
            <div style={{display:'inline-block', width: '100%', float: 'left', marginBottom: '10px'}}>
                <p id="isUenNumber" style={{'float': 'right', margin: 'auto'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityuen}</p>
                <lable style={{float: 'right', fontWeight: '600'}}>UEN NO:</lable>
            </div>
                 
              </div>
        </div> */}
                            {/* Template Content Head Starts here */}
                            {/* <div style={{ float: 'left', width: '100%' }}>
                              <div style={{ float: 'left', width: '45%' }}>
                                <p
                                  style={{
                                    marginBottom: '8px',
                                    fontWeight: 600
                                  }}
                                >
                                  To
                                </p>
                                <span>
                                  {this.state.response_statement != '' &&
                                    this.state.response_statement != undefined &&
                                    this.state.response_statement.customer_name
                                  }
                                </span>
                                <p>
                                  {this.state.response_statement != '' &&
                                    this.state.response_statement != undefined &&
                                    this.state.response_statement.billing_address
                                  }
                                </p>
                              </div>
                              <div
                                style={{
                                  float: 'right',
                                  width: '38%',
                                  textAlign: 'right'
                                }}
                              >
                                <p style={{ float: 'left', width: '50%' }}>
                                
                                </p>
                                <p style={{ float: 'left', width: '50%' }}>
                                  <span style={{ fontWeight: 600 }}>
                                    As of
                                  </span>
                                  <br />
                                  {moment(this.state.todate).format('DD-MM-YYYY')}
                                </p>
                              </div>
                            </div> */}
                            {/* Template Content Head Ends here */}
                            {/* Table Starts here */}
                            {/* <table
                              style={{
                                width: '100%',
                                float: 'left',
                                marginTop: '25px'
                              }}
                            >
                              <thead
                                style={{
                                  background: '#5e5e5e',
                                  border: '1px solid #5e5e5e'
                                }}
                              >
                                <tr style={{ color: '#fff', fontWeight: 500 }}>
                                  <td style={{ padding: '15px 10px' }}>Date</td>
                                  <td style={{ padding: '15px 10px' }}>
                                    Reference
                                  </td>
                                  <td style={{ padding: '15px 10px' }}>
                                    Details
                                  </td>
                                  <td style={{ padding: '15px 10px', textAlign:"center" }}>
                                    Due Date
                                  </td>
                                  <td style={{ padding: '15px 10px' }}>
                                    Currency
                                  </td>
                                  <td style={{ padding: '15px 10px' }}>
                                   Invoice Amount
                                  </td>
                                  <td style={{ padding: '15px 10px' }}>
                                    Payments/Credits
                                  </td>
                                  <td
                                    style={{
                                      padding: '15px 10px',
                                      textAlign: 'center'
                                    }}
                                  >
                                    Balance Due
                                  </td>
                                </tr>
                              </thead>
                              <tbody style={{ border: '1px solid #BABABA' }}>
                                {this.state.statement &&
                                  this.state.statement.map((item, i) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                            {item.invoice_date === "NA"
                                              ? "NA"
                                              : moment(
                                                  item.invoice_date,
                                                  "MMM Do YYYY"
                                                ).format(this.state.date_format)}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                          {item.reference}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                          {item.details}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                          {item.due_date === "NA"
                                              ? "NA"
                                              : moment(
                                                  item.due_date,
                                                  "MMM Do YYYY"
                                                ).format(this.state.date_format)}
                                        </td>
                                        <td
                                            style={{
                                              fontSize: "13px",
                                              padding: "15px 10px",
                                            }}
                                          >
                                            {item.foreign_currency}
                                          </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                           <Comma
                                              value={item.foreign_amount}
                                            />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px'
                                          }}
                                        >
                                           <Comma
                                              value={
                                                item.payment_amount_foreign_currency
                                              }
                                            />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '13px',
                                            padding: '15px 10px',
                                            textAlign: 'right'
                                          }}
                                        >
                                          <Comma
                                              value={
                                                item.open_balance_foreign_currency
                                              }
                                            />
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </table> */}
                            {/* Table Ends here */}
                            {/* Total Sec Starts here */}
                          
                            {/* <div
                                      style={{
                                        float: 'left',
                                        width: '80%',
                                        paddingLeft: '20px',
                                        marginTop: '20px'
                                      }}
                                    ></div>  <b> Total Balance Due</b>
                                    <div
                                        style={{
                                          float: 'right',
                                          width: '50%',
                                          padding: '15px 10px',
                                          background: '#EFEFEF',
                                        }}
                                      > 
                                    
                                      {this.state.totalbalance &&
                              this.state.totalbalance.map((item, i) => {
                                return (
                                  <>
                                   
                                     
                                        <div
                                          style={{
                                            float: 'left',
                                            width: '50%',
                                            fontWeight: 600,
                                            textAlign: 'right',
                                            paddingRight: '10px'
                                          }}
                                        >
                                         {item.currency} :
                                        </div>

                                       
                                        <div
                                          style={{
                                            textAlign: 'right',
                                            padding: '0 10px',
                                            float: 'right',
                                            width: '30%'
                                          }}
                                        >
                                          <Comma value={item.total_balance} />
                                        </div>

                                    
                                    
                                  </>
                                )
                              })                                
                                } 
                                </div> */}
                                {/* <div  style={{'float':'left','width':'100%','margin-top': '0','padding': '25px', 'box-sizing': 'border-box'}}>
        <div style={{'float':'left','width':'100%'}}>
        <p style={{'margin-bottom': '5px', 'font-weight': 'bold'}}>Thank you message and Banking details</p> 
        <p style={{'margin-top': '0', 'margin-bottom': '15px'}}>
          
          <div
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.customerStatement
                                        }}
                                      />
          </p>
         </div>         
        </div>   */}
                                
                                 {/* Total Sec Ends here */}
                            
                          {/* </div> */}
                        
                          {/* Template Content Ends here */}
                        {/* </div>
                      </div> */}
                      {/* </div> */}


                      <div id='whole_template_to_print'>
                      <div className='statement-wrap col-md-12 pad-hor-no'>
                        <div
                           style={{
                            color: '#6A6A6A',
                            background: '#fff',
                            minHeight: '230px',
                            border: '1px solid #eee',
                            width: '97%',
                            margin: '0 auto',
                            // fontSize: '14px',
                            fontSize: '16px',
                            fontFamily: 'cursive',
                            display: 'block',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
                            borderRadius: '4px'
                          }}
                        >
                            {/* Template Header Starts here */}
                            {/* <div
                            style={{
                              float: 'left',
                              width: '100%',
                              padding: '35px'
                            }}
                          >
                            <div
                              style={{
                                float: 'left',
                                width: '35%',
                                height: '73px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {/* <img
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                src='images/sample-logo-horz.png'
                                alt='logo'
                              /> */}
                            {/* </div>
                            <div
                              style={{
                                float: 'right',
                                textAlign: 'right',
                                width: '42%'
                              }}
                            >
                              <p style={{ marginBottom: 0 }}>
                                <span style={{ fontWeight: 600 }}>Mob:</span>{' '}
                                {this.state.response_statement != '' &&
                                  this.state.response_statement != undefined &&
                                  this.state.response_statement.mobilenumber
                                }
                              </p>
                              <p style={{ marginBottom: 0 }}>
                                {this.state.response_statement != '' &&
                                  this.state.response_statement != undefined &&
                                  this.state.response_statement.company_address
                                }
                              </p>
                            </div>
                          </div> */}
                            {/* Template Header Ends here */}
                            {/* Template Content Starts here */}
                            <div
                              style={{
                                // float: 'left',
                                // width: '100%',
                                padding: "10px",
                              }}
                            >
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  id='isLogo'
                                  style={{
                                    float: "left",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    id='image'
                                    style={{ width: "100px" }}
                                    // src={this.state.statement_logo}
                                    src={this.state.response_statement.company_details && this.state.response_statement.company_details.logo_path}
                                    alt='logo'
                                  />
                                </div>
                                <div
                                  id='mobDiv'
                                  style={{ float: "right", textAlign: "right" }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <p
                                      id='isEntityName'
                                      style={{ float: "right", margin: "auto" }}
                                    >
                                      {this.state.response_statement
                                        .company_details &&
                                        this.state.response_statement
                                          .company_details.entityname}
                                    </p>
                                    <lable
                                      style={{
                                        float: "right",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Company Name:
                                    </lable>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <p
                                      id='isAddress'
                                      style={{ float: "right", margin: "auto" }}
                                    >
                                      {this.state.response_statement
                                        .company_details &&
                                        this.state.response_statement
                                          .company_details &&
                                        this.state.response_statement
                                          .company_details.entityaddress}
                                    </p>
                                    <lable
                                      style={{
                                        float: "right",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Address:
                                    </lable>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <p
                                      id='isPhoneNumber'
                                      style={{ float: "right", margin: "auto" }}
                                    >
                                      {this.state.response_statement
                                        .company_details &&
                                        this.state.response_statement
                                          .company_details.entityphno}
                                    </p>
                                    <lable
                                      style={{
                                        float: "right",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Phone No:
                                    </lable>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <p
                                      id='isEmail'
                                      style={{ float: "right", margin: "auto" }}
                                    >
                                      {this.state.response_statement
                                        .company_details &&
                                        this.state.response_statement
                                          .company_details.entityemail}
                                    </p>
                                    <lable
                                      style={{
                                        float: "right",
                                        fontWeight: "600",
                                      }}
                                    >
                                      E-mail:
                                    </lable>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <p
                                      id='isUenNumber'
                                      style={{ float: "right", margin: "auto" }}
                                    >
                                      {this.state.response_statement
                                        .company_details &&
                                        this.state.response_statement
                                          .company_details.entityuen}
                                    </p>
                                    <lable
                                      style={{
                                        float: "right",
                                        fontWeight: "600",
                                      }}
                                    >
                                      UEN NO:
                                    </lable>
                                  </div>
                                  {/* <p id="isEntityName" style={{'margin-bottom': '0' }}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityname}</p>
                 <p id="isAddress" style={{'margin-bottom': '0' }}>{this.state.response_statement.company_details && this.state.response_statement.company_details && this.state.response_statement.company_details.entityaddress}</p>  
                 <p id="isPhoneNumber" style={{'margin-bottom': '0'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityphno}</p>
                 <p id="isEmail" style={{'margin-bottom':'0' ,'display': 'block'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityemail}</p>
                 <p id="isUenNumber" style={{'margin-bottom': '0' ,'display': 'block'}}>{this.state.response_statement.company_details && this.state.response_statement.company_details.entityuen}</p>
                 <p id="isGstNumber" style={{'margin-bottom': '0' ,'display': 'block'}}></p> */}
                                </div>
                              </div>
                              {/* Template Content Head Starts here */}
                              <div style={{ float: "left", width: "100%" }}>
                                <div style={{ float: "left", width: "45%" }}>
                                  <p
                                    style={{
                                      marginBottom: "8px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    To
                                  </p>
                                  <span>
                                    {this.state.response_statement != "" &&
                                      this.state.response_statement !=
                                        undefined &&
                                      this.state.response_statement
                                        .customer_name}
                                  </span>
                                  <p>
                                    {this.state.response_statement != "" &&
                                      this.state.response_statement !=
                                        undefined &&
                                      this.state.response_statement
                                        .billing_address}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "38%",
                                    textAlign: "right",
                                  }}
                                >
                                  <p style={{ float: "left", width: "50%" }}>
                                    {/* <span style={{ fontWeight: '600' }}>
                                    From Date
                                  </span>
                                  <br />
                                  {moment(this.state.fromDate).format('DD-MM-YYYY')} */}
                                  </p>
                                  <p style={{ float: "left", width: "50%" }}>
                                    <span style={{ fontWeight: "600" }}>
                                      As of
                                    </span>
                                    <br />
                                    {moment(this.state.toDate).format(
                                     this.state.date_format
                                    )}
                                  </p>
                                </div>
                              </div>
                              {/* Template Content Head Ends here */}
                              {/* Table Starts here */}
                              <table
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "25px",
                                }}
                              >
                                <thead
                                  // style={{
                                  //   background: '#5e5e5e',
                                  //   border: '1px solid #5e5e5e'
                                  // }}
                                  style={{
                                    background: '#1d869a',
                                    border: '1px solid rgb(94, 94, 94)'
                                  }}
                                >
                                  <tr
                                    style={{
                                      color: "#fff",
                                      fontWeight: "500",
                                      fontSize: "16px"
                                    }}
                                  >
                                    <td style={{ padding: "15px 10px" }}>
                                      Date
                                    </td>
                                    <td style={{ padding: "15px 10px" }}>
                                      Reference
                                    </td>
                                    <td style={{ padding: "15px 10px" }}>
                                      Details
                                    </td>
                                    <td
                                      style={{
                                        padding: "15px 10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Due Date
                                    </td>
                                    <td style={{ padding: "15px 10px" }}>
                                      Currency
                                    </td>
                                    <td style={{ padding: "15px 10px" }}>
                                      Invoice Amount
                                    </td>
                                    <td style={{ padding: "15px 10px" }}>
                                      Payments/Credits
                                    </td>
                                    <td
                                      style={{
                                        padding: "15px 10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Balance Due
                                    </td>
                                  </tr>
                                </thead>
                                <tbody
                                  style={{
                                    border: '1px solid #BABABA'
                                  }}
                                >
                                  {this.state.statement &&
                                    this.state.statement.map((item, i) => {
                                      return (
                                        <tr>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {item.invoice_date === "NA"
                                              ? "NA"
                                              : moment(
                                                  item.invoice_date,
                                                  "MMM Do YYYY"
                                                ).format(this.state.date_format)}
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                            }}
                                          >
                                            {item.reference}
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              minWidth: "100px",
                                            }}
                                          >
                                            {item.details}
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {item.due_date === "NA"
                                              ? "NA"
                                              : moment(
                                                  item.due_date,
                                                  "MMM Do YYYY"
                                                ).format(this.state.date_format)}
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                            }}
                                          >
                                            {item.foreign_currency}
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              textAlign: "right"
                                            }}
                                          >
                                            <Comma
                                              value={item.foreign_amount}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              textAlign: "right"
                                            }}
                                          >
                                            <Comma
                                              value={
                                                item.payment_amount_foreign_currency
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              // fontSize: "13px",
                                              fontSize: "16px",
                                              padding: "15px 10px",
                                              textAlign: "right"
                                            }}
                                          >
                                            <Comma
                                              value={
                                                item.open_balance_foreign_currency
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              {/* Table Ends here */}
                              {/* Total Sec Starts here */}
                              <div
                                style={{
                                  float: "left",
                                  width: "80%",
                                  paddingLeft: "20px",
                                  marginTop: "20px",
                                  fontSize:"16px"
                                }}
                              ></div>{" "}
                              <b> Total Balance Due</b>
                              <div
                                style={{
                                  float: "right",
                                  width: "50%",
                                  padding: "15px 10px",
                                  background: "rgb(239, 239, 239)",
                                  // borderRadius: '3px'
                                  fontSize:"16px"
                                }}
                              >
                                {this.state.totalbalance &&
                                  this.state.totalbalance.map((item, i) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            float: "left",
                                            width: "50%",
                                            fontWeight: 600,
                                            textAlign: "right",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          {item.currency} :
                                        </div>

                                        {/* <div
                                          style={{
                                            textAlign: 'right',
                                            padding: '0 10px',
                                            float: 'right',
                                            width: '50%'
                                          }}
                                        >
                                         
                                        </div> */}
                                        <div
                                          style={{
                                            textAlign: "right",
                                            padding: "0px 10px",
                                            float: "right",
                                            width: "30%",
                                          }}
                                        >
                                          <Comma value={item.total_balance} />
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                  "margin-top": "0px",
                                  padding: "25px",
                                  "box-sizing": "border-box",
                                }}
                              >
                                <div style={{ float: "left", width: "100%", fontSize:"16px" }}>
                                  <p
                                    style={{
                                      "margin-bottom": "5px",
                                      "font-weight": "bold",
                                    }}
                                  >
                                    Thank you message and Banking details
                                  </p>
                                  <p
                                    style={{
                                      "margin-top": "0",
                                      "margin-bottom": "15px",
                                    }}
                                  >
                                    <div
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.customerStatement
                                        }}
                                      />
                                    {/* {this.state.customerStatement} */}
                                  </p>
                                </div>
                              </div>
                              {/* Total Sec Ends here */}
                            </div>

                            {/* Template Content Ends here */}
                          </div>
                        </div>
                      </div>

            </div>
          </div>
          <div className="col-md-7 col-sm-12 offset-md-4 login-right">
            <div className="login-wrap">
              <div className="nav-brand">
                <img src="../images/logo-genie.png" alt="Genie" />
              </div>
              {/* <p className="lead">View auto generated Invoice</p> */}

             <form
                className="login-form"
              
              >
                <div className="form-group">
                  <button type="button" onClick={() => this.saveAsPdf()}  className="btn login-btn">
                    View Statement
                  </button>
                  <a href="/" className="forgot-pass">
                    cancel
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomerStatementMail;