import io from "socket.io-client";
export let socket = null;

// export const socket = io('http://localhost:9002/',   {
//    transports: ['websocket'],
//    query: {
//        token:"O5mGIP3VNia0JvPH2IBiwA==",
// EIO:3
//    },
// });

export function startService(user_id) {
   console.log("Socket service called..");
   // socket = io('https://api.genie.com.sg/',   {
   //    transports: ['websocket'],
   //    query: {
   //        token:"O5mGIP3VNia0JvPH2IBiwA==",
   //        EIO:3,
   //        user_id:user_id
   //    },
   // });

   // socket.on("general_ledger_mismatch", data => {
   //    alert("gl mismatch...."+data);
   //  });

   //  socket.on("balance_sheet_mismatch", data => {
   //    alert("Balance sheet mismatch.."+data);
   //  });
}